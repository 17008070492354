import axios from './api';

export enum SessionStatus {
  FINALIZADO = 'FINALIZADO',
  INICIADO = 'INICIADO',
  AGUARDANDO = 'AGUARDANDO',
  ATENDENDO = 'ATENDENDO',
}

export interface ISessionInfo {
  id?: string;
  idSession?: string;
  nomeCliente: string;
  status: SessionStatus;
  tokenIdCliente: string;
  numeroCartao: string;
  cpf: string;
}

interface IDefaultReturn<T> {
  status: number;
  data: T;
}

export const getAllSessions = async (): Promise<
  IDefaultReturn<ISessionInfo[]>
> => {
  const response = await axios.get<ISessionInfo[]>(
    `${process.env.REACT_APP_MS_CHAT}/chat/aberto`,
  );
  return {
    status: response.status,
    data: response.data,
  };
};
