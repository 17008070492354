/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';

import { FiMenu } from 'react-icons/fi';

import { routesConfig } from './routesConfig';
import * as St from './style';
import SubMenu from './subMenu';
import { AuthContext } from '~/contexts/authentication';

const Sidebar: React.FC = () => {
  const { sidebarOpen, handleSideBar } = useContext(AuthContext);

  return (
    <St.Sidebar isOpen={sidebarOpen}>
      <St.Menu isOpen={sidebarOpen}>
        <FiMenu onClick={handleSideBar} />
      </St.Menu>

      <St.Navbar>
        {routesConfig.map((route, index) => {
          return sidebarOpen ? (
            <SubMenu fullWidth item={route} key={index} />
          ) : (
            <SubMenu fullWidth={false} item={route} key={index} />
          );
        })}
      </St.Navbar>
    </St.Sidebar>
  );
};

export default Sidebar;
