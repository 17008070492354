export const EVENTS_TYPES = [
  {
    id: 'CANCELAMENTO_PENDENTE',
    name: 'Cancelamento de conta pendente',
    value: 'Cancelamento de conta pendente',
  },
];

export interface FormState {
  tipoEvento: { name: string; value: string };
  idOperador: string;
  dataInicial: string;
  dataFinal: string;
}

export interface IEventReport {
  form: FormState;
  setForm: React.Dispatch<React.SetStateAction<FormState>>;
  getOperator: Function;
  getReportEventsCancellation: Function;
  clearForm: () => React.SetStateAction<any>;
  EVENTS_TYPES: Array<{ id: string; name: string; value: string }>;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  operador: any[];
  loading: boolean;
  items: any;
  header: any;
  headerItems: any;
}

export interface ReportCancellationAccountEventResponse {
  celular: string;
  cpf: string;
  dataInclusaoEvento: string;
  dataValidacao: string;
  email: string;
  id: string;
  idEvento: string;
  nome: string;
  operador: {
    idOperador: string;
    loja: {
      id: string;
      nome: string;
      uf: string;
    };
    nome: string;
    perfil: string;
  };
  tipoCancelamento: string[];
}
