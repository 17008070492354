import styled from 'styled-components';

type TypeAvatar = 'small' | 'medium' | 'large';

interface IAvatar {
  size: TypeAvatar;
}

export const Avatar = styled.img`
  height: 40px;
  width: 40px;

  border-radius: 100px;

  overflow: hidden;
  object-fit: contain;
  background-color: #fff;
`;
