import styled from 'styled-components';

export interface ITitleCategory {
  paddingLeft?: number;
  paddingRight?: number;
}

export const TitleCategory = styled.span<ITitleCategory>`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  margin-top: 8px;
  background-color: #fbfbfb;

  padding-top: 22px;
  padding-bottom: 22px;
  padding-right: ${(p) => (p.paddingRight ? `${p.paddingRight}px` : '0px')};
  padding-left: ${(p) => (p.paddingLeft ? `${p.paddingLeft}px` : '0px')};
`;

export const Subtitle = styled.span<ITitleCategory>`
  font-size: 0.9rem;
  margin-bottom: 20px;
  width: -webkit-fill-available;
`;

export const ContainerRadio = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 20px;
`;

export const TitleParecer = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-top: 22px;
  padding-bottom: 22px;
`;

export const TitleNotice = styled.span`
  color: red;
  font-weight: 600;
  font-size: 14px;
`;

export const IconCard = styled.img`
  height: 25px;
`;

export const TitleAbbr = styled.abbr`
  color: red;
  text-decoration: none;
`;

export const TitleAbbrFooter = styled.span`
  color: red;
  font-weight: 600;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;
