import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 80%;
  margin: auto;
`;

export const ViewButton = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 15%;
`;

export const Title = styled.h3`
  font-size: 20px;
  color: #4a4b4a;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15%;

  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

export const Text = styled.span`
  font-size: 12px;
  color: #4a4b4a;
  font-weight: 400;
  margin-bottom: 7%;
  align-self: flex-start;
  margin-left: 10%;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
