import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, phoneMask } from '~/utils/masks';

export const HeaderDisapprovedP1 = [
  {
    id: 1,
    value: 'Nome completo',
    maxChar: 20,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Reprovação',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Operador(a) de Venda',
    maxChar: 20,
  },
  {
    id: 5,
    value: 'Celular',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Origem',
    maxChar: 8,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Motivo',
    maxChar: 25,
  },
];

export const BodyDisapprovedP1 = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        id: 1,
        value: i.nome,
        maxChar: 20,
      },
      {
        id: 2,
        value: cpfMask(i.cpf).masked,
        maxChar: 13,
        textAlign: 'center',
      },
      {
        id: 3,
        value: i.dataReprovacao
          ? moment(i.dataReprovacao).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 20,
        textAlign: 'center',
      },
      {
        id: 4,
        value: i.operador,
        maxChar: 20,
        textAlign: 'center',
      },
      {
        id: 5,
        value: phoneMask(i.celular).masked,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 6,
        value: i.origem,
        maxChar: 8,
        textAlign: 'center',
      },
      {
        id: 7,
        value: i.motivoReprovacao,
        maxChar: 25,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
