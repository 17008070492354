import styled from 'styled-components';

interface FadeAndSlideContainerProps {
  active: boolean;
}

export const FadeAndSlideContainer = styled.div<FadeAndSlideContainerProps>`
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 0.8s ease-out;
`;

export const PhoneDiv = styled.div`
  display: flex;
  gap: 20px;
`;
