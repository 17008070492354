/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import Select from '~/components/select';
import { setFormState } from '~/utils/form';
import { tipoBandeira, tipoStatus, UFs } from '../../../constants';
import { useReportAccessionSale } from '../../context/useAccessionSale';
import { REPORT_TYPES } from '../../models';

import * as S from '../../styles';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';

interface IAccessionSale {
  onClick?: (any: any) => void;
}

const AccessionSale: React.FC<IAccessionSale> = ({ onClick }) => {
  const [callFunction, setCallFunction] = useState(false);
  const [form, setForm] = useState({
    tipoRelatorio: { name: 'tipoRelatorio', value: 'ADESOES' },
    dataInicial: moment().format('DD/MM/YYYY'),
    dataFinal: moment().format('DD/MM/YYYY'),
    uf: 'TODOS',
    tipoPeriodo: 'TODOS',
    operador: 'TODOS',
    estabelecimento: 'TODOS',
    tipoCard: 'TODOS',
    statusTransacao: 'APROVADO',
  });

  const {
    getOperador,
    getEstablishment,
    operador,
    establishment,
    loading,
  } = useReportAccessionSale();

  useEffect(() => {
    async function handleFilter() {
      switch (form.tipoRelatorio?.value) {
        case 'ADESOES':
          // await getOperador();
          break;
        case 'LEADS_WALLET_ATIVADA':
          await getOperador();
          break;
        case 'TRANSACAO':
          await getEstablishment();
          break;
        default:
          break;
      }
    }

    handleFilter();
  }, [form.tipoRelatorio]);

  const handleChange = (el): void => {
    setCallFunction(true);
    onClick(el);
  };

  return (
    <>
      <Loading status={loading} />
      <S.BoxCard>
        <ContainerFlex row space>
          <ContainerFlex marginRight={16}>
            <Select
              options={REPORT_TYPES}
              label="Tipo Relatório"
              name={form.tipoRelatorio.name}
              value={form.tipoRelatorio.value}
              onChange={(e) => {
                setFormState(
                  { target: { name: form.tipoRelatorio.name, value: e } },
                  form,
                  setForm,
                );
              }}
            />
          </ContainerFlex>

          {form.tipoRelatorio.value !== 'ADESOES' &&
            form.tipoRelatorio.value !== 'TRANSACAO' && (
              <ContainerFlex marginRight={16}>
                <Select
                  options={[
                    { id: 'TODOS', value: 'Todos' },
                    { id: '6041102c1b705c0007d62e81', value: 'Web' },
                    ...operador,
                  ]}
                  label="Operador(a) de Venda"
                  value={form.operador}
                  onChange={(e) => {
                    setForm({ ...form, operador: e });
                  }}
                />
              </ContainerFlex>
            )}

          {form.tipoRelatorio.value === 'ADESOES' && (
            <ContainerFlex marginRight={16}>
              <Select
                options={UFs}
                label="UF"
                value={form.uf}
                onChange={(e) => {
                  setForm({ ...form, uf: e });
                }}
              />
            </ContainerFlex>
          )}

          {form.tipoRelatorio.value === 'LEADS_WALLET_ATIVADA' && (
            <ContainerFlex marginRight={16}>
              <Select
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'DATA_LEAD', value: 'Data Lead' },
                  { id: 'DATA_TRANSACAO', value: 'Data Transação' },
                ]}
                label="Tipo de período"
                value={form.tipoPeriodo}
                onChange={(e) => {
                  setForm({ ...form, tipoPeriodo: e });
                }}
              />
            </ContainerFlex>
          )}

          {form.tipoRelatorio.value === 'TRANSACAO' && (
            <>
              <ContainerFlex marginRight={16}>
                <AutoCompleteSelect
                  options={[{ id: 'TODOS', value: 'Todos' }, ...establishment]}
                  label="Estabelecimento"
                  value={form.estabelecimento}
                  onChange={(e) => {
                    return setForm({ ...form, estabelecimento: e?.id });
                  }}
                />
              </ContainerFlex>
              <ContainerFlex marginRight={16}>
                <Select
                  options={tipoBandeira}
                  label="Tipo"
                  value={form.tipoCard}
                  onChange={(e) => {
                    return setForm({ ...form, tipoCard: e });
                  }}
                />
              </ContainerFlex>
              <ContainerFlex marginRight={16}>
                <Select
                  options={tipoStatus}
                  label="Status da transação"
                  value={form.statusTransacao}
                  onChange={(e) => {
                    return setForm({ ...form, statusTransacao: e });
                  }}
                />
              </ContainerFlex>
            </>
          )}

          <ContainerFlex marginRight={16}>
            <Input
              label="De"
              placeholder="DD/MM/YYYY"
              maxLength={10}
              value={form.dataInicial}
              onChange={(e) => {
                return setForm({
                  ...form,
                  dataInicial: e.target.value.masked,
                });
              }}
              mask={MaskTypes.DATE}
            />
          </ContainerFlex>

          <ContainerFlex>
            <Input
              label="Até"
              placeholder="DD/MM/YYYY"
              maxLength={10}
              value={form.dataFinal}
              onChange={(e) => {
                return setForm({
                  ...form,
                  dataFinal: e.target.value.masked,
                });
              }}
              mask={MaskTypes.DATE}
            />
          </ContainerFlex>
        </ContainerFlex>

        <ContainerFlex>
          <ContainerFlex flex="0.2" marginTop={24} maxWidth={14}>
            <Button
              onClick={() => {
                handleChange(form);
              }}
            >
              <span>Consultar</span>
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </S.BoxCard>
    </>
  );
};

export default AccessionSale;
