/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import { icons } from '~/assets';
import Dialog from '~/components/dialog';

import useApi, { ApiMethod } from '~/hooks/useApi';

import Endpoints from '~/services/endpoints';
import ConsultUserInvoiceSearchResponse from '~/services/consult/user/invoice/response';

import * as Masks from '~/utils/masks';
import { TitleCategory } from './styles';
import TableList from '~/components/tableList';

import * as St from './styles';
import Container from '~/components/container';

const Invoice = (): JSX.Element => {
  const history = useHistory<any>();
  const locationState = history.location.state;

  const [list, setList] = useState([]);

  const [cpf, setCpf] = useState('');
  const [numeroCartao, setNumeroCartao] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dialogIsFail, setDialogIsFail] = useState(false);

  const handleGenerateURL = useMemo(() => {
    if (cpf && numeroCartao) {
      return `${Endpoints.consult.searchUserListInvoice}?cpf=${cpf}`;
    }

    if (cpf && (!numeroCartao || numeroCartao === '')) {
      return `${Endpoints.consult.searchUserListInvoice}?cpf=${cpf}`;
    }

    if (numeroCartao && (!cpf || cpf === '')) {
      return `${Endpoints.consult.searchUserListInvoice}?numeroCartao=${numeroCartao}`;
    }

    return `${Endpoints.consult.searchUserListInvoice}`;
  }, [cpf, numeroCartao, history]);

  const api = useApi<ConsultUserInvoiceSearchResponse>(
    handleGenerateURL,
    ApiMethod.GET,
  );
  useEffect(() => {
    if (locationState?.allData?.length && locationState?.from) {
      loadDataFromState();
    } else {
      loadData();
    }
  }, [api.loading, locationState]);

  const searchUserListInvoice = async () => {
    if (locationState?.allData) locationState.allData = null;
    if (locationState?.from) locationState.from = false;
    const response = await api.callApi();
    if (response.status >= 400) {
      setErrorMessage(response.mensagem);
    }
  };
  const customerHeader = [
    {
      id: 1,
      value: 'Nome completo',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'CPF',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Número do cartão',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Mês',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Ano',
      maxChar: 18,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Status',
      maxChar: 30,
      textAlign: 'center',
    },
    {
      id: 7,
      value: 'Descrição',
      maxChar: 30,
      textAlign: 'center',
    },
    {
      id: 8,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];
  const loadDataFromState = () => {
    const newList = [];
    // let id = 0;
    locationState.allData.forEach((i: any) => {
      const item = [];
      item.push(
        // {
        //   type: 'id',
        //   value: id++,
        //   maxChar: 5,
        // },
        {
          border: true,
          title: 'Nome completo',
          value: i.cliente?.nome,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'CPF',
          value: Masks.cpfMask(i.cliente?.cpf).masked,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Número do cartão',
          value: Masks.creditCardMask(i.numeroCartao).masked,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Mês',
          value: i.mesReferencia,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Ano',
          value: i.anoReferencia,
          textAlign: 'center',
        },
        { border: true, title: 'Status', value: i.status, textAlign: 'center' },
        {
          item: api.data,
          searchAction: () => {
            history.push('/invoice/detail', {
              cpf: i.cliente?.cpf,
              numeroCartao: i.numeroCartao,
              dataVencimento: i.dataVencimento,
              idFatura: i.id,
              faturaId: i.id_fatura,
              mesReferencia: i.mesReferencia,
              anoReferencia: i.anoReferencia,
              status: i.status,
              linhaDigitavel: i.linhaDigitavel,
              allData: locationState?.allData,
            });
          },
        },
      );
      newList.push(item);
    });
    setList(newList);
  };
  const loadData = () => {
    if (api.status !== 200) {
      setList([]);
      return;
    }

    const dataRes = api.data as ConsultUserInvoiceSearchResponse;
    const newList = [];
    // let id = 0;
    if (dataRes.listaFaturas?.length) {
      dataRes.listaFaturas.forEach((i: any) => {
        const item = [];
        item.push(
          // {
          //   type: 'id',
          //   value: id++,
          //   maxChar: 5,
          // },
          {
            border: true,
            title: 'Nome completo',
            value: i.cliente?.nome,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'CPF',
            value: Masks.cpfMask(i.cliente?.cpf).masked,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Número do cartão',
            value: Masks.creditCardMask(i.numeroCartao).masked,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Mês',
            value: i.mesReferencia,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Ano',
            value: i.anoReferencia,
            textAlign: 'center',
          },
          {
            title: 'Status',
            value: i.status,
            textAlign: 'center',
          },
          {
            title: 'Descricao',
            value: i.statusDescricao,
            textAlign: 'center',
          },
          {
            textAlign: 'center',
            item: dataRes,
            searchAction: () => {
              history.push('/invoice/detail', {
                cpf: i.cliente?.cpf,
                numeroCartao: i.numeroCartao,
                dataVencimento: i.dataVencimento,
                idFatura: i.id,
                faturaId: i.id_fatura,
                mesReferencia: i.mesReferencia,
                anoReferencia: i.anoReferencia,
                status: i.status,
                statusDescricao: i.statusDescricao,
                linhaDigitavel: i.linhaDigitavel,
                valorMaximoRecebimento: i.valorMaximoRecebimento,
                valorMinimoRecebido: i.valorMinimoRecebido,
                dataPagamento: i.dataPagamento,
                valorPagamento: i.valorPagamento,
                saldoFaturaAtual: i.saldoFaturaAtual,
                allData: dataRes.listaFaturas,
              });
            },
          },
        );
        newList.push(item);
      });
      setList(newList);
    } else {
      setErrorMessage(api.mensagem);
      setList([]);
    }
  };

  const handleDisableButton = () =>
    (cpf?.length !== 11 && numeroCartao?.length !== 16) || api.loading;

  return (
    <>
      <Loading status={api.loading} />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name="cpf"
                value={Masks.cpfMask(cpf).masked}
                onChange={(e) => {
                  setCpf(e.target.value.unmasked);
                }}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Número do cartão"
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                mask={MaskTypes.CREDIT_CARD}
                onChange={(e) => {
                  setNumeroCartao(e.target.value.unmasked);
                }}
                name="numeroCartao"
                value={Masks.creditCardMask(numeroCartao).masked}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={searchUserListInvoice}
                loading={api.loading}
                disabled={handleDisableButton()}
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {list.length <= 0 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <TitleCategory>Nenhuma transaçao encontrada</TitleCategory>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex row>
              {errorMessage && list.length === 0 && (
                <ContainerFlex center marginTop={30}>
                  <TitleCategory>{errorMessage}</TitleCategory>
                </ContainerFlex>
              )}
            </ContainerFlex>

            <ContainerFlex row>
              {list.length > 0 && (
                <TableList customerHeader={customerHeader} list={list} />
              )}
            </ContainerFlex>
          </St.BoxCard>
        )}
        <Dialog
          icon={icons.warning}
          open={dialogIsFail}
          message={errorMessage}
          positiveLabel="Entendi"
          positiveAction={() => {
            setDialogIsFail(false);
          }}
        />
      </Container>
    </>
  );
};

export default Invoice;
