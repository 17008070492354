import styled from 'styled-components';

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 30px;
  padding-left: 16px;
  padding-top: 30px;
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  background-color: #ffffff;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;
