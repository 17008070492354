import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderSignedContracts = [
  {
    id: 1,
    value: 'Nome do cliente',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data de aprovação',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Contrato assinado',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Data de assinatura',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Status cartão',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodySignedContracts = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nome,
        maxChar: 25,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.dataAprovacao
          ? moment(i.dataAprovacao).format('DD/MM/YY')
          : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.contratoAssinado === 'SIM' ? 'SIM' : 'NÃO',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.dataAssinatura
          ? moment(i.dataAssinatura).format('DD/MM/YY')
          : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.statusCartao,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
