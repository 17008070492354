/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Collapse from '~/components/collapse';
import Container from '~/components/container';
import ClientData from '../Components/ClientData';
import * as St from '../Services/styles';
import CardTableList from '../Components/CardTableList';
import { headerListPlan, headerUpgrade } from '../Headers/cardHeaders';
import { SharedCardServicesContext } from '../Card/Services/sharedCardServiceContext';
import { IEvent, IProduct } from '~/contexts/leads';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import SharedServiceContext from '../Services/sharedServiceContext';
import { UpgradeSolicitation } from '../Card/UpgradeSolicitation';
import { SolicitationAccount } from '../Account/Components/Solicitation';
import Constants from '~/utils/constants';

export const SolicitationDetails = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const {
    cpfGlobal,
    setCpfGlobal,
    cardInfos,
    availableProducts,
    getInfoCards,
    getClientInformation,
    getPhoto,
    getAvailableProducts,
    postCloseLead,
    setEventId,
    setProduct,
    setEvent,
    product,
    loadingGlobal,
    opinion,
    eventId,
    getProfiles,
    openForwardLead,
    executeEvent,
    executeEventNew,
    setPhoneZeuss,
    disabledButton,
    disabledByProfile,
    phoneZeuss,
    cardModelSelected,
    setModalDescriptionOpen,
    resetContext,
    listArrayCancelType,
  } = useContext(SharedServiceContext);
  const {
    cpf,
    setCpf,
    getNotClientCards,
    phoneZeussCard,
    validatePhone,
    errorPhoneZeuss,
    resetContextCard,
  } = useContext(SharedCardServicesContext);

  const [disabledUpgrade, setDisabledUpgrade] = useState(false);
  const [typeButton, setTypeButton] = useState<string>('');

  useEffect(() => {
    return () => {
      resetContext();
      resetContextCard();
    };
  }, []);

  useEffect(() => {
    if (product?.id === 'CARTAO') {
      if (
        errorPhoneZeuss ||
        opinion?.length <= 0 ||
        loadingGlobal.postExecuteEvent.loading ||
        disabledByProfile ||
        disabledButton ||
        cardModelSelected.length <= 0 ||
        phoneZeussCard?.unmasked?.length !== 11
      ) {
        setDisabledUpgrade(true);
      } else {
        setDisabledUpgrade(false);
      }
    } else if (product?.id === 'CONTA') {
      if (listArrayCancelType.length < 1 || opinion?.length <= 0) {
        setDisabledUpgrade(true);
      } else {
        setDisabledUpgrade(false);
      }
    }
  }, [
    errorPhoneZeuss,
    opinion,
    loadingGlobal.postExecuteEvent.loading,
    disabledByProfile,
    disabledButton,
    cardModelSelected,
    phoneZeussCard,
    product,
    listArrayCancelType,
  ]);

  useEffect(() => {
    if (state) {
      setCpf(state?.cpfGlobal || state?.item?.cpf);
      setCpfGlobal(state?.cpfGlobal || state?.item?.cpf);
      setEvent(state?.event || state?.item?.tipoEvento);
      setEventId(state?.eventId || state?.item?.id);
      const aux = state?.item?.produto.value as string;
      setProduct(
        state?.product || {
          id: aux
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase(),
          value: state?.item?.produto.value,
        },
      );
    }
  }, [state]);

  useEffect(() => {
    if (cpf && cpfGlobal) {
      getClientInformation();
      getAvailableProducts();
      getInfoCards();
      getPhoto();
      getNotClientCards();
    }
  }, [cpf, cpfGlobal]);

  const finishEvent = async () => {
    setModalDescriptionOpen(true);
  };

  useEffect(() => {
    if (opinion && eventId && typeButton === 'CLOSE_EVENT') {
      postCloseLead();
      setTypeButton('');
    }
  }, [opinion, eventId, typeButton]);

  const toFoward = () => {
    getProfiles();
  };

  const nextContact = () => {
    setTypeButton('NEXT_CONTACT');
    setEventId(state?.eventId || state?.item?.id);
  };

  useEffect(() => {
    if (eventId && typeButton === 'NEXT_CONTACT') {
      openForwardLead();
      setTypeButton('');
    }
  }, [eventId, typeButton]);

  const handleStartUpgrade = () => {
    setTypeButton('UPGRADE');
    setEventId(state?.eventId || state?.item?.id);
    setPhoneZeuss(phoneZeussCard);
    setTypeButton('');
    executeEventNew({ hasFile: false, payload: {}, websocket: false });
    // executeEvent(false, false);
  };

  const handleStartCancellation = () => {
    setTypeButton('');
    const operator = JSON.parse(localStorage.getItem('@NovaEra:user'));

    // executeEvent(false, false);
    const payload = {
      conta: {
        parecer: opinion,
        tipoCancelamento: listArrayCancelType,
        desistir: false,
        operador: {
          idOperador: localStorage.getItem(Constants.USER_ID),
          nome: operator?.nomeTitular,
        },
      },
    };

    executeEventNew({ hasFile: false, websocket: false, payload });
  };

  useEffect(() => {
    if (phoneZeussCard?.unmasked?.length === 11) {
      validatePhone();
    }
  }, [phoneZeussCard]);

  return (
    <Container>
      <St.BoxCard>
        <Collapse collapseHeight={70} initialState="open" title="1. Cliente">
          <ClientData
            solicitation
            cardInfos={cardInfos}
            availableProducts={availableProducts}
            eventStart
            product={
              state?.product || {
                id: product?.id,
                value: state?.item?.produto.value,
              }
            }
            event={state?.event || state?.item?.tipoEvento}
          />
        </Collapse>
        {product?.id === 'CARTAO' && <UpgradeSolicitation />}
        {product?.id === 'CONTA' && <SolicitationAccount />}
        <ContainerFlex row gap="10" end marginTop={20}>
          <Button
            outlined
            disabled={loadingGlobal.closeLead.loading}
            loading={loadingGlobal.closeLead.loading}
            onClick={() => {
              finishEvent();
            }}
          >
            Encerrar
          </Button>
          <Button
            outlined
            disabled={disabledButton || loadingGlobal.postForwardLead.loading}
            loading={loadingGlobal.postForwardLead.loading}
            onClick={() => {
              toFoward();
            }}
          >
            Encaminhar
          </Button>
          <Button
            outlined
            disabled={disabledButton || loadingGlobal.postNewContact.loading}
            loading={loadingGlobal.getContactHistory.loading}
            onClick={() => {
              nextContact();
            }}
          >
            Próximo Contato
          </Button>
          <Button
            outlined
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={disabledUpgrade}
            loading={loadingGlobal.postExecuteEvent.loading}
            onClick={() => {
              product?.id === 'CARTAO'
                ? handleStartUpgrade()
                : handleStartCancellation();
            }}
          >
            {product?.id === 'CARTAO'
              ? 'Solicitar Upgrade'
              : product?.id === 'CONTA'
              ? 'Cancelar conta'
              : 'Cancelar'}
          </Button>
        </ContainerFlex>
      </St.BoxCard>
    </Container>
  );
};
