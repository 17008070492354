/* eslint-disable radix */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import fileSize from 'filesize';

import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import TitleApp from '~/components/title';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import TextArea from '~/components/textarea';
import Loading from '~/components/loading';
import CardView from '~/components/cartView';
import PictureCard from '~/components/pictureCard';
import { icons } from '~/assets';
import * as St from './styles';
import { RELATION_TYPES } from '~/models/proposta-contatos-ref';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { IProposal } from '~/models/proposal';
import {
  cepMask,
  cpfMask,
  currencyMask,
  currencyUnmasked,
  IDefaultReturn,
  phoneMask,
} from '~/utils/masks';
import { transformInGlobalDate, transformInNormalDate } from '~/utils/date';
import IResponseGetUserDocuments from '~/services/files/getUserDocuments/response';
import ConsultUserDetailResponse from '~/services/consult/user/detail/response';
import Container from '~/components/container';

export interface IRegisterData {
  cpf?: IDefaultReturn;
  nome?: string;
  telefone?: IDefaultReturn;
  dataNascimento?: IDefaultReturn;
  cep?: IDefaultReturn;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  cidade?: string;
  bairro?: string;
  profissao?: string;
  rendaInformada?: string;
}

interface Reference {
  id?: string;
  nomeRefI: string;
  telefoneRefI: IDefaultReturn;
  relacaoRefI: string;
  comentarioRefI: string;

  idII?: string;
  nomeRefII: string;
  telefoneRefII: IDefaultReturn;
  relacaoRefII: string;
  comentarioRefII: string;
}

interface IDocumentsImage {
  income: {
    front: string;
    tipo?: string;
  };
  address: {
    front: string;
    tipo?: string;
  };
}

const DetailProposalCT = (): JSX.Element => {
  const [goBackDialog, setGoBackDialog] = useState<boolean>(false);
  const [dialogDecision, setDialogDecision] = useState(false);
  const [dialogDecisionResponse, setDialogDecisionResponse] = useState(false);
  const [dialogDecisionMessage, setDialogDecisionMessage] = useState('');
  const [reference, setReference] = useState<Reference>({} as Reference);
  const [description, setDescripton] = useState<string>('');
  const [proposal, setProposal] = useState<IProposal>({} as IProposal);
  const [userInfo, setUserInfo] = useState({} as ConsultUserDetailResponse);
  const [documentsImage, setDocumentsImage] = useState({} as IDocumentsImage);
  const [isCompRenda, setIsCompRenda] = useState<boolean>(false);
  const [sizeFile, setsizeFile] = useState<number>(0);
  const [dialogUploadError, setDialogUploadError] = useState<boolean>(false);
  const [dialogUploadMessage, setDialogUploadMessage] = useState<string>('');
  const [
    dialogUploadErrorFormat,
    setDialogUploadErrorFormat,
  ] = useState<boolean>(false);
  const { state } = useLocation<{ cpf: string; idProposal: string }>();
  const [registerData, setRegisterData] = useState<IRegisterData>(
    {} as IRegisterData,
  );
  const [dialogSizeFileMessage, setDialogSizeFileMessage] = useState<boolean>(
    false,
  );
  const [uploadDocumentIsLoading, setUploadDocumentIsLoading] = useState(
    ([] as unknown) as [
      keyof IDocumentsImage,
      keyof IDocumentsImage[keyof IDocumentsImage],
    ],
  );
  const inputFileIncomeFrontRef = useRef(null);
  const inputFileAddressFrontRef = useRef(null);
  const history = useHistory();

  const RequestUserInfo = useApi<ConsultUserDetailResponse>(
    Endpoints.consult.detailUser.replace('PARAM_CPF', state?.cpf),
    ApiMethod.GET,
  );

  const RequestProposalSPC = useApi<IProposal>(
    Endpoints.consult.searchProposalSPC.replace(
      'PARAM_ID_PROPOSTA',
      state?.idProposal,
    ),
    ApiMethod.GET,
  );

  const RequestUploadUserDocuments = useApi(
    Endpoints.files.uploadUserDocuments,
    ApiMethod.POST,
  );

  const RequestUserDocuments = useApi<IResponseGetUserDocuments>(
    `${Endpoints.consult.searchUserDocuments}${state?.idProposal}`,
    ApiMethod.GET,
  );

  const RequestUpdateUser = useApi(
    Endpoints.consult.updateClient,
    ApiMethod.PUT,
  );

  const UpdateReference = useApi<IProposal>(
    `${Endpoints.register.proposalReference}/${proposal?.idProposta}`,
    ApiMethod.PUT,
  );

  const SendProposalDecision = useApi<any>(
    Endpoints.update.sendDecision.replace('PARAM_CPF', state?.cpf),
    ApiMethod.POST,
  );

  useEffect(() => {
    (async () => {
      RequestUserDocuments.callApi().then((response) => {
        if (response.data?.documentoRenda) {
          setIsCompRenda(response.data?.documentoRenda !== '');
        } else {
          setIsCompRenda(false);
        }

        isImageBase64(
          `data:image/png;base64, ${response.data?.documentoEndereco}`,
        ).then((data) => {
          setDocumentsImage((prev) => ({
            ...prev,
            address: {
              front:
                !!response.data?.documentoEndereco &&
                `${data ? 'data:image/png;base64' : ''}, ${
                  response.data?.documentoEndereco
                }`,
              tipo: data ? 'image' : 'pdf',
            },
          }));
        });

        isImageBase64(
          `data:image/png;base64, ${response.data?.documentoRenda}`,
        ).then((data) => {
          setDocumentsImage((prev) => ({
            ...prev,
            income: {
              front:
                !!response.data?.documentoRenda &&
                `${data ? 'data:image/png;base64' : ''}, ${
                  response.data?.documentoRenda
                }`,
              tipo: data ? 'image' : 'pdf',
            },
          }));
        });
      });

      const response = await RequestUserInfo.callApi();
      const userInfo = response.data;

      setUserInfo(userInfo);
      const objRegisterData: IRegisterData = {
        dataNascimento: transformInNormalDate(userInfo?.dataNascimento),
        bairro: userInfo?.endereco?.bairro,
        cep: cepMask(userInfo?.endereco?.cep),
        cidade: userInfo?.endereco?.cidade,
        complemento: userInfo?.endereco?.complemento,
        logradouro: userInfo?.endereco?.logradouro,
        numero: userInfo?.endereco?.numero,
        profissao: userInfo?.profissao,
        telefone: phoneMask(userInfo?.celular),
        rendaInformada: currencyMask(userInfo?.rendaInformada),
      };

      setRegisterData(objRegisterData);

      const objReference = {
        id: userInfo?.proposta?.contatos[0]?.id
          ? userInfo?.proposta?.contatos[0]?.id
          : undefined,
        nomeRefI: userInfo?.proposta?.contatos[0]?.nome,
        telefoneRefI: phoneMask(userInfo?.proposta?.contatos[0]?.telefone),
        relacaoRefI: userInfo?.proposta?.contatos[0]?.relacao,
        comentarioRefI: userInfo?.proposta?.contatos[0]?.comentario,

        idII: userInfo?.proposta?.contatos[1]?.id
          ? userInfo?.proposta?.contatos[1]?.id
          : undefined,
        nomeRefII: userInfo?.proposta?.contatos[1]?.nome,
        telefoneRefII: phoneMask(userInfo?.proposta?.contatos[1]?.telefone),
        relacaoRefII: userInfo?.proposta?.contatos[1]?.relacao,
        comentarioRefII: userInfo?.proposta?.contatos[1]?.comentario,
      };

      setReference(objReference);

      const proposalInfo = await RequestProposalSPC.callApi();
      setProposal(proposalInfo.data);
    })();
  }, []);

  const goBack = () => history.goBack();

  const sendProposalDecision = async () => {
    try {
      const payload = {
        celular: registerData.telefone.unmasked,
        contatos: [
          {
            idContato: reference?.id ? reference.id : undefined,
            comentario: reference.comentarioRefI,
            nome: reference.nomeRefI,
            relacao: reference.relacaoRefI,
            telefone: reference.telefoneRefI?.masked,
          },
          {
            idContato: reference?.idII ? reference.idII : undefined,
            comentario: reference?.comentarioRefII
              ? reference?.comentarioRefII
              : undefined,
            nome: reference?.nomeRefII ? reference?.nomeRefII : undefined,
            relacao: reference?.relacaoRefII
              ? reference?.relacaoRefII
              : undefined,
            telefone: reference?.telefoneRefII?.masked
              ? reference?.telefoneRefII?.masked
              : undefined,
          },
        ],
        endereco: {
          bairro: registerData.bairro,
          cep: registerData.cep.unmasked,
          cidade: registerData.cidade,
          complemento: registerData.complemento,
          logradouro: registerData.logradouro,
          numero: registerData.numero,
          pais: userInfo.endereco.pais,
          uf: userInfo.endereco.uf,
        },
        idOperador: localStorage.getItem('USER_ID'),
        idProposta: state?.idProposal,
        idCliente: userInfo.id,
        justificativa: description,
        dataNascimento: transformInGlobalDate(
          registerData.dataNascimento?.masked,
        ),
        profissao: registerData.profissao,
        rendaInformada: currencyUnmasked(registerData.rendaInformada),
      };

      const response = await SendProposalDecision.callApi(payload);

      setDialogDecision(false);

      if (response.status === 404) {
        return setDialogDecisionMessage(response.data.mensagem);
      }

      setDialogDecisionMessage('Proposta enviada com sucesso para decisão');
      setGoBackDialog(true);
    } catch (err) {
      console.log(err);
    } finally {
      setDialogDecisionResponse(true);
    }
  };

  const handleChangeUserInfo = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeUserReference = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setReference((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectReference = (value: any, name: string) => {
    setReference((prev) => ({ ...prev, [name]: value }));
  };

  const fileToBase64 = async (f: Blob): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const fileSelected = async <
    T extends keyof IDocumentsImage,
    U extends keyof IDocumentsImage[T]
  >(
    e: React.ChangeEvent<HTMLInputElement>,
    key: T,
    side: U,
  ) => {
    const fileValue = e.target.files[0];

    if (
      !/^image\/(jpg|jpe?g|png)$|^application\/(pdf)$/i.test(fileValue.type)
    ) {
      setDialogUploadErrorFormat(true);
      return;
    }

    if (fileValue.size > 1000000) {
      setDialogSizeFileMessage(true);
      setsizeFile(fileValue.size);
      setUploadDocumentIsLoading([null, null]);
    } else if (fileValue) {
      const base64 = (await fileToBase64(fileValue)) as string;
      try {
        if (key === 'address') {
          const response = await RequestUploadUserDocuments.callApi({
            file: base64.split(',')[1],
            idProposta: state?.idProposal,
            tipo: 'ENDERECO',
            cpf: cpfMask(proposal.proposta?.cpf).unmasked,
            extensionFile: `.${fileValue.type.split('/')[1]}`,
          });
          
          if (response.status >= 300) {
            setDialogUploadError(true);
            setDialogUploadMessage(response.mensagem);
          }
        }
        if (key === 'income') {
          const response = await RequestUploadUserDocuments.callApi({
            file: base64.split(',')[1],
            idProposta: state?.idProposal,
            tipo: 'RENDA',
            cpf: cpfMask(proposal.proposta?.cpf).unmasked,
            extensionFile: `.${fileValue.type.split('/')[1]}`,
          });

          if (response.status >= 200 && response.status < 300) {
            setIsCompRenda(true);
          }
          if (response.status >= 300) {
            setDialogUploadError(true);
            setDialogUploadMessage(response.mensagem);
          }
        }

        isImageBase64(base64).then((data) => {
          setDocumentsImage((prev) => ({
            ...prev,
            [key]: {
              ...prev[key],
              [side]: base64,
              tipo: data ? 'image' : 'pdf',
            },
          }));
        });
      } catch (err) {
        console.error(err.message);
      } finally {
        setUploadDocumentIsLoading([null, null]);
      }
    }
  };

  const handleDeleteFile = async <
    T extends keyof IDocumentsImage,
    U extends keyof IDocumentsImage[T]
  >(
    state: T,
    side: U,
  ) => {
    if (state === 'income') {
      inputFileIncomeFrontRef.current.value = '';
    }

    if (state === 'address') {
      inputFileAddressFrontRef.current.value = '';
    }

    setDocumentsImage((prev) => ({
      ...prev,
      [state]: { ...prev[state], [side]: null, tipo: null },
    }));
  };

  const triggerFileIncomeFrontPicker = () =>
    inputFileIncomeFrontRef.current?.click();

  const triggerFileAddressFrontPicker = () =>
    inputFileAddressFrontRef.current?.click();

  const isImageBase64 = (str: string) =>
    new Promise((resolve) => {
      const img = new Image();
      img.addEventListener('error', () => resolve(false));
      img.addEventListener('load', () => resolve(/^data:.*;base64,/.test(str)));
      img.src = str;
    });

  return (
    <>
      <St.InputFileHidden
        ref={inputFileIncomeFrontRef}
        type="file"
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        onChange={(e) => fileSelected(e, 'income', 'front')}
      />
      <St.InputFileHidden
        ref={inputFileAddressFrontRef}
        type="file"
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        onChange={(e) => fileSelected(e, 'address', 'front')}
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogDecision}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogDecision(false)}
        positiveLabel="Enviar para decisão"
        positiveAction={sendProposalDecision}
        message="Você tem certeza que deseja salvar os dados alterados e enviar a proposta para decisão?"
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogUploadError}
        positiveLabel="Entendi"
        positiveAction={() => setDialogUploadError(false)}
        message={dialogUploadMessage}
      />
      <Dialog
        icon={icons.warning}
        open={dialogUploadErrorFormat}
        positiveLabel="Entendi"
        positiveAction={() => setDialogUploadErrorFormat(false)}
        message="Formato de arquivo não permitido. Formatos válidos: JPG, JPEG, PNG, PDF "
      />
      <Dialog
        icon={icons.warning}
        open={dialogSizeFileMessage}
        positiveLabel="Entendi"
        positiveAction={() => setDialogSizeFileMessage(false)}
        message={`O tamanho do arquivo enviado é de ${fileSize(
          sizeFile,
        )}, o tamanho máximo permitido é de 1 MB.`}
      />

      <Dialog
        icon={
          SendProposalDecision.status === 200
            ? icons.confirmation
            : icons.warning
        }
        open={dialogDecisionResponse}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogDecisionResponse(false);
          if (goBackDialog) {
            goBack();
          }
        }}
        message={dialogDecisionMessage}
      />

      <Loading
        status={
          RequestProposalSPC.loading ||
          UpdateReference.loading ||
          RequestUserInfo.loading ||
          RequestUpdateUser.loading ||
          SendProposalDecision.loading
        }
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <ContainerFlex>
              <TitleApp>Revisão Cadastral</TitleApp>
            </ContainerFlex>

            <ContainerFlex>
              <Grid xs={1} lg={2}>
                <ContainerFlex>
                  <ContainerFlex color="#fbfbfb" marginBottom={20}>
                    <St.TitleCategory paddingLeft={26}>
                      1. Dados do Cadastro
                    </St.TitleCategory>
                  </ContainerFlex>

                  <ContainerFlex marginLeft={26} marginRight={16}>
                    <Input
                      label="CPF"
                      value={cpfMask(proposal.proposta?.cpf).masked}
                      readOnly
                    />
                    <Input
                      label="Nome completo"
                      value={proposal.proposta?.nome}
                      readOnly
                    />
                    <Input
                      label="Telefone"
                      name="telefone"
                      value={registerData.telefone?.masked}
                      edit
                      maxLength={15}
                      mask={MaskTypes.PHONE}
                      onChange={handleChangeUserInfo}
                    />
                    <Input
                      label="Data de nascimento"
                      name="dataNascimento"
                      value={registerData.dataNascimento?.masked}
                      maxLength={10}
                      mask={MaskTypes.DATE}
                    />
                    <Input
                      label="CEP"
                      name="cep"
                      onChange={handleChangeUserInfo}
                      value={registerData.cep?.masked}
                      mask={MaskTypes.CEP}
                      maxLength={9}
                      edit
                    />
                    <Input
                      label="Endereço"
                      name="logradouro"
                      value={registerData.logradouro}
                      edit
                      onChange={handleChangeUserInfo}
                    />
                    <div>
                      <Grid xs={1} sm={2} gap={10}>
                        <ContainerFlex>
                          <Input
                            label="Número"
                            name="numero"
                            value={registerData.numero}
                            edit
                            onChange={handleChangeUserInfo}
                          />
                        </ContainerFlex>

                        <ContainerFlex>
                          <Input
                            label="Complemento"
                            name="complemento"
                            value={registerData.complemento}
                            edit
                            onChange={handleChangeUserInfo}
                          />
                        </ContainerFlex>
                      </Grid>

                      <Grid xs={1} sm={2} gap={10}>
                        <ContainerFlex>
                          <Input
                            label="Cidade"
                            name="cidade"
                            value={registerData.cidade}
                            edit
                            onChange={handleChangeUserInfo}
                          />
                        </ContainerFlex>
                        <ContainerFlex>
                          <Input
                            label="Bairro"
                            name="bairro"
                            value={registerData.bairro}
                            edit
                            onChange={handleChangeUserInfo}
                          />
                        </ContainerFlex>
                      </Grid>
                    </div>
                    <Input
                      label="Renda estimada"
                      name="rendaInformada"
                      value={registerData.rendaInformada}
                      edit
                      mask={MaskTypes.CURRENCY}
                      onChange={handleChangeUserInfo}
                    />
                    <Input
                      label="Informação profissional"
                      name="profissao"
                      value={registerData.profissao}
                      edit
                      onChange={handleChangeUserInfo}
                    />
                  </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex>
                  <ContainerFlex color="#fbfbfb" flex={0.01}>
                    <St.TitleCategory paddingLeft={26}>
                      2. Proposta de Crédito
                    </St.TitleCategory>
                  </ContainerFlex>
                  <br />

                  <ContainerFlex marginLeft={16} marginRight={26}>
                    <Input
                      label="ID da proposta"
                      value={proposal.idProposta}
                      readOnly
                    />
                    <Input
                      label="Restrição"
                      value={String(proposal.spc?.valorRestricao)}
                      readOnly
                    />
                    <Input
                      label="Score"
                      value={String(proposal.spc?.score)}
                      readOnly
                    />
                    <Input
                      label="Limite concedido pelo motor"
                      value={currencyMask(
                        userInfo?.cartoes
                          ? userInfo?.cartoes[userInfo?.cartoes?.length - 1]
                              ?.limiteCredito
                          : 0,
                      )}
                      readOnly
                    />
                    <Input
                      label="Dia vencimento da fatura"
                      value={moment(
                        userInfo.diaVencimento,
                        'DD/MM/YYYY',
                      ).format('DD')}
                      readOnly
                    />
                    <Input
                      label="Valor da restrição"
                      value={currencyMask(proposal.spc?.valorRestricao)}
                      readOnly
                    />

                    <div>
                      <Grid xs={1} sm={2} gap={10}>
                        <ContainerFlex marginRight={16}>
                          <Input
                            label="Loja"
                            value={
                              proposal?.spc
                                ? proposal?.proposta.origem !== 'LOJA'
                                  ? proposal?.proposta.origem
                                  : proposal?.proposta.empresa
                                : ''
                            }
                            readOnly
                          />
                        </ContainerFlex>

                        <ContainerFlex>
                          <Input
                            label="Operador(a) de Venda"
                            value={
                              proposal?.spc
                                ? proposal?.proposta.origem !== 'LOJA'
                                  ? proposal?.proposta.origem
                                  : proposal.proposta?.atendente?.nomeOperador
                                : ''
                            }
                            readOnly
                          />
                        </ContainerFlex>
                      </Grid>

                      <Grid xs={1} sm={2} gap={10}>
                        <ContainerFlex>
                          <Input
                            label="Data"
                            value={
                              transformInNormalDate(
                                proposal.proposta?.dataInclusao.split('T')[0],
                              ).masked
                            }
                            readOnly
                          />
                        </ContainerFlex>

                        <ContainerFlex>
                          <Input
                            label="Horário"
                            value={
                              proposal.proposta?.dataInclusao
                                .split('T')[1]
                                .split('.')[0]
                            }
                            readOnly
                          />
                        </ContainerFlex>
                      </Grid>
                    </div>
                  </ContainerFlex>
                </ContainerFlex>
              </Grid>
            </ContainerFlex>

            <ContainerFlex>
              <ContainerFlex color="#fbfbfb">
                <St.TitleCategory paddingLeft={26}>
                  3. Referências Pessoais
                </St.TitleCategory>
              </ContainerFlex>

              <ContainerFlex marginTop={15}>
                <Grid xs={1} lg={2}>
                  <ContainerFlex>
                    <ContainerFlex color="#fbfbfb">
                      <St.TitleCategory paddingLeft={26}>
                        Referência I
                      </St.TitleCategory>
                    </ContainerFlex>
                    <ContainerFlex marginLeft={26} marginRight={16}>
                      <Input
                        label="Nome completo"
                        name="nomeRefI"
                        onChange={handleChangeUserReference}
                        value={reference.nomeRefI}
                      />
                      <Input
                        label="Telefone"
                        mask={MaskTypes.PHONE}
                        name="telefoneRefI"
                        onChange={handleChangeUserReference}
                        value={reference.telefoneRefI?.masked}
                        maxLength={15}
                      />
                      <Select
                        label="Relação com o cliente"
                        onChange={(val: any) => {
                          handleSelectReference(val, 'relacaoRefI');
                        }}
                        name="relacaoRefI"
                        options={RELATION_TYPES}
                        value={reference.relacaoRefI}
                      />
                      <Input
                        label="Comentário"
                        name="comentarioRefI"
                        onChange={handleChangeUserReference}
                        value={reference.comentarioRefI}
                      />
                    </ContainerFlex>
                  </ContainerFlex>
                  <ContainerFlex>
                    <ContainerFlex color="#fbfbfb">
                      <St.TitleCategory paddingLeft={26}>
                        Referência II
                      </St.TitleCategory>
                    </ContainerFlex>
                    <ContainerFlex marginLeft={26} marginRight={16}>
                      <Input
                        label="Nome completo"
                        name="nomeRefII"
                        onChange={handleChangeUserReference}
                        value={reference.nomeRefII}
                      />
                      <Input
                        label="Telefone"
                        mask={MaskTypes.PHONE}
                        name="telefoneRefII"
                        onChange={handleChangeUserReference}
                        value={reference.telefoneRefII?.masked}
                        maxLength={15}
                      />
                      <Select
                        label="Relação com o cliente"
                        onChange={(val: any) => {
                          handleSelectReference(val, 'relacaoRefII');
                        }}
                        name="relacaoRefII"
                        options={RELATION_TYPES}
                        value={reference.relacaoRefII}
                      />
                      <Input
                        label="Comentário"
                        name="comentarioRefII"
                        onChange={handleChangeUserReference}
                        value={reference.comentarioRefII}
                      />
                    </ContainerFlex>
                  </ContainerFlex>
                </Grid>
              </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex>
              <ContainerFlex color="#fbfbfb">
                <St.TitleCategory paddingLeft={26}>
                  4. Parecer da solicitação
                </St.TitleCategory>
              </ContainerFlex>

              <ContainerFlex padding={26}>
                <TextArea
                  placeholder="Digitar parecer.."
                  rows={10}
                  value={description}
                  onChange={(e) => setDescripton(e.target.value)}
                />
              </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex row spaceAround wrap>
              <ContainerFlex center horizontal>
                <ContainerFlex row center horizontal>
                  <St.TitleFileChoose>CNH ou RG</St.TitleFileChoose>
                  <St.TitleFileChooseSmall>
                    (Frente e verso)
                  </St.TitleFileChooseSmall>
                </ContainerFlex>

                <ContainerFlex row center horizontal>
                  <PictureCard
                    width="150"
                    height="100"
                    showOptions={!!RequestUserDocuments.data?.documentoFrente}
                    src={
                      RequestUserDocuments.data?.documentoFrente
                        ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoFrente}`
                        : icons.tableDocumentFront
                    }
                  />
                  <PictureCard
                    width="150"
                    height="100"
                    showOptions={!!RequestUserDocuments.data?.documentoVerso}
                    src={
                      RequestUserDocuments.data?.documentoVerso
                        ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoVerso}`
                        : icons.tableDocumentBack
                    }
                  />
                </ContainerFlex>

                <St.TitleFileChooseSmall>
                  Clique no ícone para selecionar a imagem
                </St.TitleFileChooseSmall>
              </ContainerFlex>

              <ContainerFlex center horizontal>
                <ContainerFlex center horizontal>
                  <St.TitleFileChoose>Comprovante de Renda</St.TitleFileChoose>
                </ContainerFlex>

                <ContainerFlex row center horizontal>
                  <PictureCard
                    width="150"
                    height="100"
                    tipo={documentsImage.income?.tipo}
                    expand={!!documentsImage.income?.front}
                    onClickExpandPDF={() => {
                      const pdfWindow = window.open('');
                      pdfWindow.document.write(
                        `<iframe width='100%' height='100%' frameBorder='0' marginwidth='0' marginheight='0' src='data:application/pdf;base64 ${documentsImage.income?.front}'></iframe>`,
                      );
                    }}
                    showOptions
                    loading={
                      uploadDocumentIsLoading[0] === 'income' &&
                      uploadDocumentIsLoading[1] === 'front'
                    }
                    onClickCamera={triggerFileIncomeFrontPicker}
                    onClickDelete={() => {
                      handleDeleteFile('income', 'front') &&
                        setIsCompRenda(false);
                    }}
                    src={
                      documentsImage.income?.tipo === 'image'
                        ? documentsImage.income?.front || icons.document
                        : icons.contract
                    }
                  />
                </ContainerFlex>

                <St.TitleFileChooseSmall>
                  Clique no ícone para selecionar a imagem
                  <br />
                  Formatos válidos: JPG, JPEG, PNG, PDF
                </St.TitleFileChooseSmall>
              </ContainerFlex>

              <ContainerFlex center horizontal>
                <ContainerFlex center horizontal>
                  <St.TitleFileChoose>
                    Comprovante de Endereço
                  </St.TitleFileChoose>
                </ContainerFlex>

                <ContainerFlex row center horizontal>
                  <PictureCard
                    width="150"
                    height="100"
                    tipo={documentsImage.address?.tipo}
                    expand={!!documentsImage.address?.front}
                    onClickExpandPDF={() => {
                      const pdfWindow = window.open('');
                      pdfWindow.document.write(
                        `<iframe width='100%' height='100%' frameBorder='0' marginwidth='0' marginheight='0' src='data:application/pdf;base64 ${documentsImage.address?.front}'></iframe>`,
                      );
                    }}
                    showOptions
                    loading={
                      uploadDocumentIsLoading[0] === 'address' &&
                      uploadDocumentIsLoading[1] === 'front'
                    }
                    onClickCamera={triggerFileAddressFrontPicker}
                    onClickDelete={() => {
                      handleDeleteFile('address', 'front');
                    }}
                    src={
                      documentsImage.address?.tipo === 'image'
                        ? documentsImage.address?.front || icons.document
                        : icons.contract
                    }
                  />
                </ContainerFlex>

                <St.TitleFileChooseSmall>
                  Clique no ícone para selecionar a imagem
                  <br />
                  Formatos válidos: JPG, JPEG, PNG, PDF
                </St.TitleFileChooseSmall>
              </ContainerFlex>
            </ContainerFlex>

            <br />

            <CardView marginBottom={22}>
              <ContainerFlex row end padding={26}>
                <Button outlined onClick={goBack}>
                  Voltar
                </Button>
                <br />
                <Button
                  marginLeft={16}
                  onClick={() => setDialogDecision(true)}
                  disabled={!isCompRenda || description.trim().length === 0}
                >
                  Enviar para decisão
                </Button>
              </ContainerFlex>
            </CardView>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default DetailProposalCT;
