import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { creditCardMask, currencyMask } from '~/utils/masks';

export const HeaderPositiveRegistration = [
  {
    id: 1,
    value: 'Nome completo',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'Número Cartão',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Última Compra',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Valor Última Compra',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Limite Disponível',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodyPositiveRegistration = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nome,
        maxChar: 25,
      },
      {
        value: i.numeroCartao ? creditCardMask(i.numeroCartao).masked : '',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.dataUltimaCompra
          ? moment(i.dataUltimaCompra).format('DD/MM/YY')
          : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.valorUltimaCompra)}`,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.limiteDisponivel)}`,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
