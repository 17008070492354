/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { FiArrowLeft } from 'react-icons/fi';
import { MdOutlineRefresh, MdOutlineFileDownload } from 'react-icons/md';
import { IconContext } from 'react-icons';
import moment from 'moment';
import { CSV_HEADERS } from '../../Report/constants';
import ContainerFlex from '~/components/containerFlex';
import BoxOperators from '../components/boxOperators';
import BoxStore from '../components/boxStore';
import BoxOrigin from '../components/boxOrigin';
import {
  ReportProposalsRegisteredResponse,
  ReportResponse,
} from '~/services/report/requestReport/response';

import * as S from '../styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Container from '~/components/container';

import Models from '../../Report/models';

interface IProps {
  type: string;
  dataFinal: string;
  dataInicial: string;
  subTitle: string;
  range: string;
  campoOrdenado: string;
  tipoOrdenacao: string;
}

interface IBoxGraphic {
  loja: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  operador: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  origem: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
}

const DashDetailsProposalRealTime: React.FC = () => {
  const history = useHistory();
  const [dataResponse, setDataResponse] = useState<IBoxGraphic>({
    loja: {
      categories: [],
      series: [],
    },
    operador: {
      categories: [],
      series: [],
    },
    origem: {
      categories: [],
      series: [
        {
          name: '',
          data: [],
        },
      ],
    },
  });
  const [form, setForm] = useState({
    idAtendente: 'TODOS',
    origem: 'TODOS',
    limitePorPagina: 10,
    pagina: 1,
    primeiraCompra: 'string',
    uf: 'TODOS',
  });
  const [updateHour, setUpdateHour] = useState('');
  const [header, setHeader] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [headerItems, setHeaderItems] = useState([]);
  const [items, setItems] = useState([]);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [relatorioData, setRelatorioData] = useState([]);
  const { state } = useLocation<IProps>();

  const RequestGetStatusProposal = useApi<any>(
    Endpoints.graphc.getProposalDetailsRealTime
      .replace('PARAM_DATA_FINAL', state?.dataFinal)
      .replace('PARAM_DATA_INICIAL', state?.dataInicial)
      .replace('PARAM_STATUS', state?.type.toUpperCase() || 'CADASTRADAS'),
    ApiMethod.POST,
  );

  const refCSV = useRef<any>(null);

  const RequestReportProposalsRegistered = useApi<ReportProposalsRegisteredResponse>(
    Endpoints.report.getReportProposalsRegistered,
    ApiMethod.POST,
  );

  const RequestReportApproved = useApi<ReportResponse>(
    Endpoints.report.getReportApproved,
    ApiMethod.POST,
  );

  const RequestReportProposalP1 = useApi<ReportResponse>(
    Endpoints.report.getReportProposalP1,
    ApiMethod.POST,
  );

  const RequestReportDisapproved = useApi<ReportResponse>(
    Endpoints.report.getReportDisapprove,
    ApiMethod.POST,
  );

  useEffect(() => {
    populaGraphc();
  }, []);

  const populaGraphc = async () => {
    try {
      const payload = {
        ordenacaoGraficoLoja: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'DESC',
        },
        ordenacaoGraficoMotivoBloqueio: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'DESC',
        },
        ordenacaoGraficoOperador: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'DESC',
        },
        ordenacaoGraficoOrigem: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'DESC',
        },
      };
      const response = await RequestGetStatusProposal.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        const { data } = response;
        setDataResponse(data as IBoxGraphic);
        setUpdateHour(
          `Atualizado: hoje às ${moment(data.dataHoraColeta).format('HH:mm')}`,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportProposalsRegistered = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: state.dataInicial,
      periodoFinal: state.dataFinal,
      limitePorPagina: limit,
      pagina: page,
      uf: form.uf,
    };

    const response = await RequestReportProposalsRegistered.callApi(payload);

    setHeader(CSV_HEADERS.PROPOSALS_REGISTERED);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderProposalsRegistered);
      setItems(Models.BodyProposalsRegistered(data));
    }
  };

  const getReportApproved = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: state.dataInicial,
      periodoFinal: state.dataFinal,
      pagina: page,
      limitePorPagina: limit,
      status: 'APROVADA',
      uf: form.uf,
    };

    const response = await RequestReportApproved.callApi(payload);

    setHeader(CSV_HEADERS.REPORT_APPROVED);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportAproved);
      setItems(Models.BodyReportApproved(data));
    }
  };

  const getApprovedP1 = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      origem: form.origem,
      idAtendente: form.idAtendente,
      pagina: page,
      limitePorPagina: limit,
      status: 'APROVADA',
      uf: form.uf,
    };

    const response = await RequestReportProposalP1.callApi(payload);

    setHeader(CSV_HEADERS.APPROVED_P1);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportProposalP1);
      setItems(Models.BodyReportProposalP1(data));
    }
  };

  const getReportDisapprove = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: state.dataInicial,
      periodoFinal: state.dataFinal,
      pagina: page,
      limitePorPagina: limit,
      status: 'REPROVADA',
    };

    const response = await RequestReportDisapproved.callApi(payload);

    setHeader(CSV_HEADERS.REPORT_DISAPPROVED);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportDisapproved);
      setItems(Models.BodyReportDisapproved(data));
    }
  };

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    switch (state.type) {
      case 'CADASTRADAS':
        response = await getReportProposalsRegistered(currentPage, 150, true);
        break;
      case 'APROVADAS':
        response = await getReportApproved(currentPage, 150, true);
        break;
      case 'PRE_APROVADAS':
        response = await getApprovedP1(currentPage, 150, true);
        break;
      case 'REPROVADAS':
        response = await getReportDisapprove(currentPage, 150, true);
        break;
      default:
        break;
    }

    if (response) {
      const data = response.totalDePaginas ? response : response.data;
      totalPages = data.totalDePaginas;

      const porcent = (currentPage / totalPages) * 100;
      const roundPorcent = Math.ceil(porcent);
      setDownloadCVSProgress(`${roundPorcent}%`);

      if (data.pagina.length) {
        setRelatorioData((prev) => [...prev, ...data.pagina]);
      }
      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }

      setDownloadCVSProgress('');
    }
  };

  const labelTitle = () => {
    if (state?.type) {
      switch (state?.type) {
        case 'CADASTRADAS':
          return 'Cadastradas';
        case 'PRE_APROVADAS':
          return 'Pré Aprovadas';
        case 'APROVADAS':
          return 'Aprovadas';
        case 'REPROVADAS':
          return 'Reprovadas';
        case 'EM_ANALISE':
          return 'Em Análise';
        default:
          break;
      }
    }
    return 'CADASTRADAS';
  };

  return (
    <Container>
      <Loading
        status={
          RequestGetStatusProposal.loading ||
          RequestReportProposalsRegistered.loading ||
          RequestReportApproved.loading ||
          RequestReportProposalP1.loading ||
          RequestReportDisapproved.loading
        }
        text={downloadCVSProgress}
      />

      <S.Header>
        <S.LeftSide>
          <FiArrowLeft onClick={() => history.goBack()} />
          <S.HeaderTitle>
            <S.Title onClick={() => history.goBack()}>
              Detalhes tempo real: {labelTitle()}
            </S.Title>
          </S.HeaderTitle>
        </S.LeftSide>

        <S.RightSide>
          <S.BtnDownload onClick={populaGraphc}>
            <IconContext.Provider value={{ className: 'react-icons' }}>
              <MdOutlineRefresh />
            </IconContext.Provider>
          </S.BtnDownload>
        </S.RightSide>
      </S.Header>
      {state.type !== 'EM_ANALISE' && (
        <ContainerFlex row end marginRight={12}>
          <S.BtnDownload
            onClick={() => {
              onClickDownloadCSV().then(() => refCSV.current.link.click());
            }}
          >
            <MdOutlineFileDownload />
            Download
          </S.BtnDownload>
          <CSVLink
            ref={refCSV}
            data={relatorioData}
            style={{
              color: 'inherit',
              textDecoration: 'none',
            }}
            headers={header}
            filename="relatorio.csv"
            target="_blank"
          />
        </ContainerFlex>
      )}

      <ContainerFlex padding={12} marginBottom={-12}>
        <BoxOperators
          categories={dataResponse.operador?.categories}
          series={dataResponse.operador?.series}
          subTitle={updateHour}
        />
      </ContainerFlex>

      <ContainerFlex row marginTop={10}>
        <ContainerFlex padding={12}>
          <BoxOrigin
            labels={dataResponse.origem?.categories}
            series={dataResponse.origem?.series}
            subTitle={updateHour}
          />
        </ContainerFlex>

        <ContainerFlex padding={12}>
          <BoxStore
            categories={
              dataResponse.loja?.categories.filter(
                (categorie) => categorie !== null,
              )
                ? dataResponse.loja?.categories.filter(
                    (categorie) => categorie !== null,
                  )
                : dataResponse.loja.categories
            }
            series={
              dataResponse.loja?.series.filter((serie) => serie !== null)
                ? dataResponse.loja?.series.filter((serie) => serie !== null)
                : dataResponse?.loja.series
            }
            heightGraphic={254}
            widthGraphic={500}
            subTitle={updateHour}
          />
        </ContainerFlex>
      </ContainerFlex>
    </Container>
  );
};

export default DashDetailsProposalRealTime;
