import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';

import * as St from './styles';

export interface IDialog {
  open?: boolean;
  icon?: string;
  message?: string;
  negativeLabel?: string;
  negativeAction?: Function;
  positiveLabel?: string;
  positiveAction?: () => void;
  widthInPercent?: number;
  closeButton?: boolean;
  buttonDisabledPositive?: boolean;
  title?: string | JSX.Element;
  widthInPixel?: number;
}

const Dialog: React.FC<IDialog> = ({
  children,
  icon,
  open,
  message,
  positiveLabel,
  positiveAction,
  negativeLabel,
  negativeAction,
  widthInPercent,
  widthInPixel,
  title,
  closeButton = false,
  buttonDisabledPositive = false,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) setIsOpened(true);
    else setIsOpened((prev) => (prev === true ? false : null));
  }, [open]);

  const close = () => {
    if (negativeAction) negativeAction();
    setIsOpened(false);
  };

  const confirm = async () => {
    if (positiveAction) {
      setIsLoading(true);
      await positiveAction();
      setIsLoading(false);
    }
  };

  return (
    <St.Container show={isOpened}>
      <CardView space widthPixel={widthInPixel} width={widthInPercent}>
        <ContainerFlex center>
          {/* Header */}
          {icon && (
            <ContainerFlex>
              <ContainerFlex row align="center">
                <St.Icon src={icon} />
                <St.TitleHeader>{title}</St.TitleHeader>
              </ContainerFlex>
            </ContainerFlex>
          )}

          {/* Content */}
          <ContainerFlex padding={16}>
            {closeButton ? (
              <St.CloseIcon onClick={close}>
                <MdClose />
              </St.CloseIcon>
            ) : (
              ''
            )}
            {message && (
              <St.Message alignCenter={closeButton}>{message}</St.Message>
            )}
            {children}
          </ContainerFlex>
        </ContainerFlex>
      </CardView>
    </St.Container>
  );
};

export default Dialog;
