/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ContainerFlex from '~/components/containerFlex';

import * as St from './styles';

export interface ISubTitleCT {
  icon?: any;
  color?: string;
  value?: string;
  onClick?: () => void;
}

const SubTitleCT: React.FC<ISubTitleCT> = ({ icon, color, value, onClick }) => {
  return (
    <St.Container
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'none' }}
    >
      <ContainerFlex center flex={0.01}>
        {icon && <St.SubTitleIcon src={icon} />}
        {!icon && <St.SubTitleBullet color={color} />}
        <St.SubTitleValue>{value}</St.SubTitleValue>
      </ContainerFlex>
    </St.Container>
  );
};

export default SubTitleCT;
