import styled from 'styled-components';

export interface IContainer {
  show?: boolean;
}

export interface IAlign {
  alignCenter?: boolean;
}

export const Container = styled.div<IContainer>`
  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  position: fixed;
  overflow: overlay;
  z-index: 999;
  display: ${(p) => (p.show ? 'flex' : 'none')};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.span<IAlign>`
  font-size: 1em;
  font-weight: 300;
  text-align: ${(p) => (p.alignCenter ? 'center' : 'left')};
`;

export const Divider = styled.div<IContainer>`
  height: 1px;
  flex: 1;
  width: 100%;
`;

export const DividerLine = styled.div<IContainer>`
  height: 1px;
  width: 100%;
  margin-top: 35px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const Icon = styled.img`
  align-self: start;
  height: 40px;
  object-fit: contain;
  margin-top: 16px;
  margin-left: 16px;
`;

export const CloseIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
  background-color: #e71037;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
`;

export const TitleHeader = styled.span`
  flex: 1;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 15px 0 0 10px;
`;
