/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */
export interface IDefaultReturn {
  masked: string;
  unmasked: string;
}

const defaultReturn = (masked: string, unmasked: string): IDefaultReturn => ({
  masked,
  unmasked,
});

export const verificationCodeMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{3})(\d{1,3})/g, '$1 $2');
};

export const verificationCodeUnmask = (value: string) => value.replace(' ', '');

export const currencyMask = (param: number | string): string => {
  if (!param) return '0,00';

  let value = '';

  if (typeof param === 'number') {
    param = param.toFixed(2);
  }

  if (String(param).includes('.') || String(param).includes(',')) {
    value = String(param);
  } else {
    value = `${String(param)}.00`;
  }

  value = String(value).replace('.', '').replace(',', '');

  const v = `${(Number(value) / 100).toFixed(2)}`.split('.');

  const m = v[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g);

  for (let i = 0; i < m.length; i += 1)
    m[i] = `${m[i].split('').reverse().join('')}.`;

  const r = m.reverse().join('');

  const masked = `${r.substring(0, r.lastIndexOf('.'))},${v[1]}`;

  return masked;
};

export const creditCardUnmask = (value = ''): string =>
  value.replace('.', '').replace('.', '').replace('.', '').replace('.', '');

export const dateUnmask = (value = ''): string => value.replace(/\D/gim, '');

export const cepUnmask = (value = ''): string => value.replace(/\D/gim, '');

export const cpfUnmask = (value = ''): string => value.replace(/\D/gim, '');

export const cnpjUnmask = (value = ''): string => value.replace(/\D/g, '');

export const currencyUnmasked = (value = ''): number =>
  Number(value.replace('.', '').replace(',', '.'));

export const phoneUnmask = (value = ''): string =>
  value.replace(/\D/gim, '').replace(/\D/g, '');

export const cpfMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return defaultReturn(maskedValue, cpfUnmask(value));
};

export const cnpjMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return defaultReturn(maskedValue, cnpjUnmask(value));
};

export const creditCardMask = (value = ''): IDefaultReturn => {
  const newValue = value.replace(/\D/g, '');
  const sizeValue = value.length;

  if (sizeValue <= 9)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{1,4})/g, '$1.$2'),
      creditCardUnmask(value),
    );

  if (sizeValue <= 14)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{4})(\d{1,4})/g, '$1.$2.$3'),
      creditCardUnmask(value),
    );

  return defaultReturn(
    newValue.replace(/(\d{4})(\d{4})(\d{4})(\d{1,4})/g, '$1.$2.$3.$4'),
    creditCardUnmask(value),
  );
};

export const dateMask = (value = ''): IDefaultReturn => {
  const newValue = value.replace(/\D/g, '').replace(/^(\d{2})(\d)/g, '$1/$2');

  if (newValue.length > 4)
    return defaultReturn(
      newValue.replace(/(\d{2})(\d{1,2})/, '$1/$2'),
      dateUnmask(value),
    );
  return defaultReturn(newValue, dateUnmask(value));
};

export const phoneMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');

  return defaultReturn(maskedValue, phoneUnmask(value));
};

export const cepMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, '$1$2-$3');

  return defaultReturn(maskedValue, cepUnmask(value));
};

export const hourMinSecMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/(\d{2})(\d{1,2})/, '$1:$2')
    .replace(/(:\d{2})\d+?$/, '$1');

  return defaultReturn(maskedValue, cepUnmask(value));
};

export const numericMask = (value = ''): string => value.replace(/\D/g, '');

export const hideEmailMask = (value = ''): string => {
  const [prefix] = value.split('@');

  const prefixToHide = prefix.slice(2);

  return value.replace(prefixToHide, '*'.repeat(prefixToHide.length));
};

export const cpfCnpjMask = (value = ''): IDefaultReturn => {
  const onlyNumbers = value.replace(/\D/g, '');

  let maskedValue;

  if (onlyNumbers.length <= 11) {
    maskedValue = onlyNumbers
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  } else {
    maskedValue = onlyNumbers
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{4})/, '$1/$2')
      .replace(/(\d{4})(\d{2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  return defaultReturn(maskedValue, onlyNumbers);
};

export const emailMask = (value = ''): string =>
  value
    .replace(/[^a-zA-Z0-9-_.@]/g, '')
    .trim()
    .toLowerCase();

export const nameMask = (value = '') => {
  return value
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]/gi, '')
    .replace(/[0-9]/g, '')
    .replace(/ +(?= )/g, '');
};

export function abbreviateNumber(value: number): string {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(2) + 'MM';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + 'k';
  } else {
    return value.toFixed(2);
  }
}
