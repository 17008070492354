import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask, phoneMask } from '~/utils/masks';

export const HeaderReportInvoiceIssued = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 17,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Celular',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Email',
    maxChar: 17,
  },
  {
    id: 5,
    value: 'Vencimento',
    maxChar: 12,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Valor Fatura',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Pagamento',
    maxChar: 12,
    textAlign: 'center',
  },
  {
    id: 8,
    value: 'Valor Pago',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 9,
    value: 'Status',
    maxChar: 10,
  },
];

export const BodyReportInvoiceIssued = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        id: 1,
        value: i.nome,
        maxChar: 17,
      },
      {
        id: 2,
        value: i.cpf ? cpfMask(i.cpf).masked : '-',
        maxChar: 13,
        textAlign: 'center',
      },
      {
        id: 3,
        value: i.celular ? phoneMask(i.celular).masked : '-',
        maxChar: 14,
        textAlign: 'center',
      },
      {
        id: 4,
        value: i.email,
        maxChar: 17,
      },
      {
        id: 5,
        value: i.dataVencimento
          ? moment(i.dataVencimento).format('DD/MM/YYYY')
          : '-',
        maxChar: 12,
        textAlign: 'center',
      },
      {
        id: 6,
        value: `R$ ${currencyMask(i.saldoFaturaAtual)}`,
        maxChar: 13,
        textAlign: 'center',
      },
      {
        id: 7,
        value: i.dataPagamento
          ? moment(i.dataPagamento).format('DD/MM/YYYY')
          : '-',
        maxChar: 12,
        textAlign: 'center',
      },
      {
        id: 8,
        value: `R$ ${currencyMask(i.valorPagamento)}`,
        maxChar: 13,
        textAlign: 'center',
      },
      {
        id: 9,
        value: i.status,
        maxChar: 10,
      },
    ];
    items.push(item);
  });
  return items;
};
