import styled from 'styled-components';

export interface IContainer {
  imageUrl: string;
}
export interface IButton {
  principal?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 150px;
  height: 330px;
  padding: 5px;
  background-size: cover;
  background-image: url('${(p) => p.imageUrl}');
  margin: 10px;
  input {
    margin-top: 300px;
    margin-left: 50px;
  }
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  float: right;
  color: white;
  weight: bold;
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const Button = styled.button<IButton>`
  background-color: ${(p) => (p.principal ? 'red' : '#a52a2a')};
  color: white;
  margin: 8px;
  margin-left: 5px;
  width: 75px;
  height: 27px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
`;

export const ContainerDropButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  text-align: center;
`;

export const SpanDimensoes = styled.span`
  font-size: 15px;
`;
