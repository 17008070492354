import React, { useState } from 'react';
import { icons } from '~/assets';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';

import * as St from './styles';
import Dialog from '~/components/dialog';

interface IFileItem {
  id: any;
  name: string;
  content: any;
  onDeleteClick?: Function;
}

const FileItem: React.FC<IFileItem> = ({
  id,
  content,
  name,
  onDeleteClick,
}) => {
  const [modal, setModal] = useState(false);

  const onDelete = () => {
    if (onDeleteClick) onDeleteClick(id);
  };

  return (
    <>
      <Dialog
        open={modal}
        message={name}
        positiveAction={() => setModal(false)}
        positiveLabel="Fechar"
      >
        <br />
        <St.BoxFileContent src={content} alt="teste" />
      </Dialog>

      <ContainerFlex>
        <St.BoxFileDelete src={icons.fileDelete} onClick={onDelete} />

        <St.BoxFileContainer>
          <ContainerFlex row space center>
            <St.BoxFileLabel>{name}</St.BoxFileLabel>

            <CardView
              widthPixel={26}
              padding={4}
              onClick={() => setModal(true)}
            >
              <St.BoxFileIcon src={icons.searchIcon} alt="search" />
            </CardView>
          </ContainerFlex>
        </St.BoxFileContainer>
      </ContainerFlex>
    </>
  );
};

export default FileItem;
