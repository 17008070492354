import styled from 'styled-components';

export const Table = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  border-spacing: 0;
  text-align: center;

  th {
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid gray;
  }

  th {
    border-top: 1px solid gray;
    color: #222;
  }

  td a {
    text-decoration: underline;
    color: inherit;
  }

  .icon-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LegendLabel = styled.span`
  font-size: 0.875rem;
  margin-left: 5px;
  line-height: 24px;
`;

export const UploadImage = styled.span`
  font-size: 0.75rem;
`;

export const Divider = styled.hr`
  border: 1px solid #ddd;
`;

export const TableHistory = styled.table`
  width: 100%;
  border-spacing: 0;

  td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: grey;
    padding: 10px;
  }

  th {
    padding: 10px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: grey;
  }
`;
export const BtnPlus = styled.button`
  border: none;
  background-color: #fff;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;
  cursor: pointer;

  &:after,
  &:before {
    content: '';
    display: block;
    background-color: green;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;

export const BtnCancel = styled.button`
  border: none;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;
  rotate: 135deg;
  background-color: transparent;
  cursor: pointer;
  &:after,
  &:before {
    content: '';
    display: block;
    background-color: #e71037;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;
export const BtnPlusGray = styled.button`
  border: none;
  background-color: #fff;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;
  cursor: not-allowed;

  &:after,
  &:before {
    content: '';
    display: block;
    background-color: gray;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;

export const BtnTrash = styled.div`
  width: 30px;
  height: 30px;

  background: transparent;
`;

export const TableTitle = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 5px;
`;

export const GreyText = styled.span`
  font-weight: 600;
  color: grey;
`;

export const iconPointer = styled.div`
  img {
    display: block;
    cursor: pointer;
  }
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;
