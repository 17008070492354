import Constants from '~/utils/constants';

export const routesConfig = [
  // Home/Dashboard
  {
    route: '/homeDash',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_OPERATOR,
      Constants.ROLE_ASSESSORIA,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_MESA_CREDITO,
    ],
  },

  // Consulta Transação
  {
    route: '/consult/transaction',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_MESA_CREDITO,
    ],
  },
  {
    route: '/consult-transaction/digital-wallet',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },

  // Consulta
  {
    route: '/consult/user',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },

  {
    route: '/consult/dispute',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },

  // Fechamento Fatura
  {
    route: '/creditosDisponiveis',
    roles: [Constants.ROLE_ADMIN],
  },

  // Mesa de crédito
  {
    route: '/credit-table/proposal',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_OPERATOR,
    ],
  },
  {
    route: '/credit-table/report',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/credit-table/limit/solicitation',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_MESA_CREDITO,
    ],
  },
  {
    route: '/credit-table/limit/decision',
    roles: [Constants.ROLE_ADMIN],
  },
  {
    route: '/credit-table/reactivate/decision',
    roles: [Constants.ROLE_ADMIN],
  },
  // Fatura
  {
    route: '/invoice',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_ASSESSORIA,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_OPERATOR,
      Constants.ROLE_MESA_CREDITO,
    ],
  },
  {
    route: '/invoice/antecipation',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_MESA_CREDITO,
    ],
  },
  {
    route: '/invoice/antecipation/purchase',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_MESA_CREDITO,
    ],
  },
  {
    route: '/invoice/antecipation/purchase/history',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_MESA_CREDITO,
    ],
  },
  {
    route: '/invoice/purchase-inclusion',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
    ],
  },
  {
    route: '/invoice/purchase-decision',
    roles: [Constants.ROLE_ADMIN],
  },

  // Vendas
  {
    route: '/sales',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },
  {
    route: '/sales/eventCenter',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },
  {
    route: '/sales/eventCenter/requestEvent',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },
  {
    route: '/sales/eventCenter/leadSolicitation',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },
  {
    route: '/sales/eventCenter/upgradeSolicitation',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },
  // Usuário
  {
    route: '/consult/employee',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  // Relatório
  {
    route: '/report',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/report/wallet',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/report/proposal',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/report/financial',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  // Arquivos
  {
    route: '/file/upload',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/file/history',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/file/charge',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/file/positive',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/file/proposal',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/file/activeLimits',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    route: '/file/customer-base',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    routes: '/file/invoice-file',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  {
    routes: '/file/cancel-inactivity',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
  },
  // Recursos
  {
    route: '/campanha',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_OPERATOR,
      Constants.ROLE_ASSESSORIA,
    ],
  },
  {
    route: '/lista-cliente-campanha',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_OPERATOR,
      Constants.ROLE_ASSESSORIA,
    ],
  },
  {
    route: '/management-tools/products',
    roles: [Constants.ROLE_ADMIN],
  },
  {
    route: '/management-tools/personalize-app-home',
    roles: [Constants.ROLE_ADMIN],
  },
];
