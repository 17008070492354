/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState, InputHTMLAttributes } from 'react';
import ContainerFlex from '../containerFlex';

import * as St from './styles';

export interface IOptionDate {
  id?: string | number;
  value?: string;
}

export type ISelectDate = {
  months?: IOptionDate[];
  days?: IOptionDate[];
  years?: IOptionDate[];
  label?: string;
  value?: string;
  valueDay?: string;
  valueYear?: string;
  disabled?: boolean;
  onChange?: (any) => void;
  onChangeDays?: (any) => void;
  onChangeYears?: (any) => void;
} & InputHTMLAttributes<HTMLDivElement>;

const SelectDate = ({
  months,
  value,
  label,
  valueDay,
  valueYear,
  years,
  onChange,
  onChangeDays,
  onChangeYears,
  days,
  readOnly,
  disabled,
}: ISelectDate): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedDays, setIsFocusedDays] = useState(false);
  const [isFocusedYears, setIsFocusedYears] = useState(false);
  const selectRef = useRef<HTMLDivElement>();
  const selectRefDays = useRef<HTMLDivElement>();
  const selectRefYears = useRef<HTMLDivElement>();

  const valueSelected = months.find((el) => el.id === value);
  const valueDaySelected = days.find((el) => el.id === valueDay);
  const valueYearSelected = years.find((el) => el.id === valueYear);

  const handleSelect = (el: IOptionDate): void => {
    onChange(el.id);
    setIsFocused(false);
  };

  const handleSelectDays = (el: IOptionDate): void => {
    onChangeDays(el.id);
    setIsFocused(false);
  };

  const handleSelectYears = (el: IOptionDate): void => {
    onChangeYears(el.id);
    setIsFocused(false);
  };

  const handleToggleFocus = () => {
    if (!readOnly) setIsFocused((prev) => !prev);
    setIsFocusedDays(false);
  };

  const handleToggleFocusDays = () => {
    if (!readOnly) setIsFocusedDays((prev) => !prev);
    setIsFocused(false);
  };

  const handleToggleFocusYears = () => {
    if (!readOnly) setIsFocusedYears((prev) => !prev);
    setIsFocused(false);
  };

  useEffect(() => {
    setIsFocused(false);
    setIsFocusedDays(false);
    setIsFocusedYears(false);
  }, [value]);

  return (
    <ContainerFlex>
      <St.Container isFocused={isFocused} disabled={disabled}>
        {label && (
          <St.Label isFocused={isFocused} disabled>
            {label}
          </St.Label>
        )}
        <St.Content>
          <St.LeftSide ref={selectRef} onClick={handleToggleFocus}>
            <St.Value>{valueSelected?.value}</St.Value>
            <St.ContainerOptions isFocused={isFocused}>
              <div>
                {months.map((month) => (
                  <div className="option" onClick={() => handleSelect(month)}>
                    {month.value}
                  </div>
                ))}
              </div>
            </St.ContainerOptions>
          </St.LeftSide>

          <St.Line />

          <St.MidSide ref={selectRefDays} onClick={handleToggleFocusDays}>
            <St.Value>{valueDaySelected?.value}</St.Value>
            <St.ContainerOptions isFocused={isFocusedDays}>
              <div>
                {days.map((day) => (
                  <div className="option" onClick={() => handleSelectDays(day)}>
                    {day.value}
                  </div>
                ))}
              </div>
            </St.ContainerOptions>
          </St.MidSide>

          <St.Line />

          <St.RightSide ref={selectRefYears} onClick={handleToggleFocusYears}>
            <St.Value>{valueYearSelected?.value}</St.Value>
            <St.ContainerOptions isFocused={isFocusedYears}>
              <div>
                {years.map((year) => (
                  <div
                    className="option"
                    onClick={() => handleSelectYears(year)}
                  >
                    {year.value}
                  </div>
                ))}
              </div>
            </St.ContainerOptions>
          </St.RightSide>
        </St.Content>
      </St.Container>
    </ContainerFlex>
  );
};

export default SelectDate;
