/* eslint-disable no-param-reassign */
import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderTeucardProtege = [
  {
    id: 1,
    value: 'Nome cliente',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
  },
  {
    id: 3,
    value: 'Data Venda',
    maxChar: 15,
  },
  {
    id: 4,
    value: 'Operador(a) da venda',
    maxChar: 25,
  },
  {
    id: 5,
    value: 'Status beneficio',
    maxChar: 15,
  },
  {
    id: 6,
    value: 'Celular',
    maxChar: 15,
  },
  {
    id: 7,
    value: 'Tipo de Seguro',
    maxChar: 15,
  },
  {
    id: 8,
    value: 'UF',
    maxChar: 15,
  },
];

export const BodyTeucardProtege = (data: any) => {
  // let x = 0;

  const items = [];

  data.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nomeCliente,
        maxChar: 25,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 15,
      },
      {
        value: i.dataVenda ? moment(i.dataVenda).format('DD/MM/YY') : '-',
        maxChar: 15,
      },
      {
        value: i.operador,
        maxChar: 25,
      },
      {
        value: i.statusBeneficio,
        maxChar: 15,
      },
      {
        value: i.celular,
        maxChar: 15,
      },
      {
        value: i.tipoSeguro,
        maxChar: 15,
      },
      {
        value: i.uf,
        maxChar: 15,
      },
    ];
    items.push(item);
  });
  return items;
};

export const BodyTeucardProtegeCSV = (data: any) => {
  const dataCSV = [];

  data.forEach((csv) => {
    dataCSV.push({
      nomeCliente: csv.nomeCliente,
      cpf: csv.cpf,
      dataVenda: csv.dataVenda,
      operador: csv.operador,
      loja: csv.loja,
      email: csv.email,
      celular: csv.celular,
      celular2: csv.celular2,
      uf: csv.uf,
      statusCartao: csv.statusCartao,
      statusBeneficio: csv.statusBeneficio,
      tipoSeguro: csv.tipoSeguro,
      numeroBilhete: csv.numeroBilhete,
      numeroSorte: csv.numeroSorte,
      idSegurado: csv.idSeguradoApi,
      idSolicitacao: csv.idSolicitacaoApi,
      dataEnvioApi: csv.dataEnvioApi,
      ufLoja: csv.ufLoja,
    });
  });
  return dataCSV;
};

export const BodyTotalTeucardProtege = (data: any) => {
  const total = data.reduce((acc, cur) => {
    return acc + 1;
  }, 0);
  return total;
};
