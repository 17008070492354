import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';
import { FormField, setFormState } from '~/utils/form';
import Button from '~/components/button';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Endpoints from '~/services/endpoints';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Loading from '~/components/loading';

import * as St from './styles';
import Container from '~/components/container';

interface Form {
  statusCartao: FormField;
}

interface IDownloadFileCharge {
  base64: string;
  filename: string;
}

const FileCustomerBase: React.FC = () => {
  const [form, setForm] = useState<Form>({
    statusCartao: { name: 'statusCartao', value: '' },
  });

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const getReportFileCustomerBase = useApi<IDownloadFileCharge>(
    Endpoints.report.getReportFileCustomerBase.replace(
      'PARAM_STATUS',
      `${form.statusCartao.value}`,
    ),
    ApiMethod.GET,
  );

  const STATUS_CARTAO = [
    {
      id: 'ATIVO',
      value: 'Ativo',
    },
    {
      id: 'CANCELADO',
      value: 'Cancelado',
    },
    {
      id: 'INATIVO',
      value: 'Inativo',
    },
    {
      id: 'INADIMPLENTE',
      value: 'Inadimplente',
    },
    {
      id: 'COBRANCA',
      value: 'Cobrança',
    },
  ];

  const getCustomerBaseReportDownload = async () => {
    try {
      const response = await getReportFileCustomerBase.callApi();
      if (response.status >= 200 && response.status < 300) {
        const binary = atob(response.data.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        const contentType = 'text/csv;charset=UTF-8';

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        const url = window.URL.createObjectURL(
          new Blob(['\ufeff', view], { type: contentType }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.data.filename);
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        setDialogIsOpen(true);
      }
    } catch (error) {
      setDialogIsOpen(true);
    }
  };

  return (
    <>
      <Loading status={getReportFileCustomerBase.loading} />
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message="Não foi possível baixar o arquivo."
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16} flex={0.2}>
              <Select
                label="Status do cartão"
                name={form.statusCartao.name}
                value={form.statusCartao.value}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.statusCartao.name, value: e } },
                    form,
                    setForm,
                  );
                }}
                options={STATUS_CARTAO}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                disabled={!form.statusCartao.value}
                onClick={getCustomerBaseReportDownload}
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default FileCustomerBase;
