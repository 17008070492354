import styled from 'styled-components';

interface IPictureCard {
  width?: number | string;
  height?: number | string;
}

export const WrapperSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div<IPictureCard>`
  position: relative;

  :hover #options {
    display: flex;
  }

  > img {
    border: 2px solid;
    width: ${({ width }) => width || 100}px;
    height: ${({ height }) => height || 100}px;
    border: 2px solid
      ${({
        theme: {
          colors: { primary },
        },
      }) => primary};
  }

  #icon {
    border-radius: 100px;
    padding: 5px;
    background-color: #ffff;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: #d3d3d3;
    }
  }

  #options {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(255, 240, 255, 0.3);
    width: 100%;
    height: 100%;
    display: none;
  }
`;

export const ModalImage = styled.img``;
