import React from 'react';
import Chart from 'react-apexcharts';
import { abbreviateNumber, currencyMask } from '~/utils/masks';

import * as S from '../../styles';

interface IDataItem {
  x: string;
  y: number;
  goals: {
    name: string;
    value: number;
    strokeWidth: number;
    strokeHeight: number;
    strokeColor: string;
  }[];
}

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  graphicType: string;
  month: string;
  data: IDataItem[];
  type?: string;
}

const BoxProjection: React.FC<IProps> = ({
  widthGraphic,
  heightGraphic,
  graphicType,
  month,
  data,
  type,
}) => {
  const series = [
    {
      name: 'Atual',
      data: data.map((item) => item.y),
    },
    {
      name: 'Esperado',
      data: data.map((item) => item.goals[0].value),
    },
  ];

  const categories = data.map((item) => item.x);

  const options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    tooltip: {
      y: {
        formatter(val) {
          return type === 'PAGAMENTO_FATURAS' || type === 'FATURAMENTO_COMPRAS'
            ? `R$ ${currencyMask(val)}`
            : val;
        },
      },
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      position: 'bottom',
      labels: {
        formatter(val) {
          return type === 'PAGAMENTO_FATURAS' || type === 'FATURAMENTO_COMPRAS'
            ? `R$ ${abbreviateNumber(val)}`
            : val;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        dataLabels: {
          orientation: 'horizontal',
          position: 'center',
        },
      },
    },
    colors: ['#A186BD', '#cccccc'], // Green for 'Atual', Purple for 'Esperado'
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Atual', 'Esperado'],
      markers: {
        fillColors: ['#A186BD', '#cccccc'],
      },
    },
  };

  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>
            {`Projeção
            ${month || ''}
            ${graphicType ? `por ${graphicType}` : ''}
            `}
          </h2>
        </header>
        <S.ItemGraphic>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxProjection;
