import React, { useContext } from 'react';
import { AuthContext } from '~/contexts/authentication';
import Header from '../header';
import Sidebar from '../sidebar';
import * as St from './styles';

const Container: React.FC = ({ children }) => {
  const { sidebarOpen } = useContext(AuthContext);
  return (
    <>
      <St.Container>
        <Sidebar />
        <St.Content isOpen={sidebarOpen}>
          <Header />
          <main>{children}</main>
        </St.Content>
      </St.Container>
    </>
  );
};

export default Container;
