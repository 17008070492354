// ForgotNewPass
/* eslint @typescript-eslint/no-var-requires: "off" */
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import Layout from '~/components/layoutSignIn';
import Input from '~/components/input';
import Button from '~/components/button';
import Dialog from '~/components/dialog';

import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';

import {
  confPasswordValidator,
  passwordValidator,
  verifySequencialLetters,
} from '~/utils/validators';

import * as St from './styles';
import { icons } from '~/assets';

const queryString = require('query-string');

interface IForgotNewPass {
  location: any;
}

interface IResponse {
  mensagem: string;
}

interface IMSGError {
  password?: string;
  passwordConfirmation?: string;
}

const ForgotNewPass: React.FC<IForgotNewPass> = ({ location }) => {
  const parsed = queryString.parse(location.search);

  const [password, setPassword] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [errorsInputs, setErrorsInputs] = useState<IMSGError>({});
  const [buttonIsEnable, setButtonIsEnable] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogFeed, setOpenDialogFeed] = useState(false);
  const [testDialog, setTextDialog] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const activeButton = useMemo(
    () => password === passwordNew && passwordNew.length === 6,
    [password, passwordNew],
  );

  function handleValidatePassword(event: React.MouseEvent<HTMLElement>) {
    const validator = passwordValidator(password);
    const confValidator = confPasswordValidator(password, passwordNew);

    if (validator.password) {
      setErrorsInputs({
        ...errorsInputs,
        password: String(validator.password),
      });
    } else {
      setErrorsInputs({
        ...errorsInputs,
        password: '',
      });
    }

    if (confValidator.confPassword) {
      setErrorsInputs({
        ...errorsInputs,
        passwordConfirmation: String(confValidator.confPassword),
      });
    } else {
      setErrorsInputs({
        ...errorsInputs,
        passwordConfirmation: '',
      });
    }

    if (!validator.password && !confValidator.confPassword) {
      handleSubmit(event);
    }
  }

  const checkFormSequencialNumber = useMemo(
    () => (password.length ? !verifySequencialLetters(password) : false),
    [password],
  );

  const RequestNewPassword = useApi<IResponse>(
    `${endpoints.register.forgotNewPassword}/${parsed.idUser}`,
    ApiMethod.POST,
    {
      novaSenha: password,
    },
  );

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    setLoading(true);
    setButtonIsEnable(true);

    try {
      event.preventDefault();
      const response = await RequestNewPassword.callApi();

      if (response.status === 200 || response.status === 201) {
        setOpenDialog(true);
      } else {
        setOpenDialogFeed(true);
        setTextDialog(
          response.mensagem ||
            'Erro ao redefinir a tua senha. Tente novamente mais tarde.',
        );
        setPassword('');
        setPasswordNew('');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setButtonIsEnable(false);
    }

    setLoading(false);
  }

  const confirm = () => {
    history.push('/');
    setOpenDialog(false);
  };

  const confirmFeed = () => {
    setOpenDialogFeed(false);
  };

  return (
    <>
      <Dialog
        icon={icons.IconTick}
        message="Nova senha atualizada sucesso!"
        open={openDialog}
        positiveLabel="Entendi"
        positiveAction={confirm}
      />
      <Dialog
        icon={icons.IconTick}
        message={testDialog}
        open={openDialogFeed}
        positiveLabel="Entendi"
        positiveAction={confirmFeed}
      />
      <Layout>
        <St.Container>
          <St.Title>Esqueci minha senha</St.Title>
          <St.Text>Por favor, informe sua nova senha.</St.Text>
          <St.Form>
            <Input
              name="password"
              id="password"
              type="password"
              label="Nova senha"
              maxLength={6}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              errorMessage={errorsInputs.password}
            />
            <Input
              name="passwordNew"
              id="passwordNew"
              type="password"
              label="Confirme nova senha"
              maxLength={6}
              value={passwordNew}
              onChange={(e) => setPasswordNew(e.target.value)}
              errorMessage={errorsInputs.passwordConfirmation}
            />

            <St.CheckForm active={password.length === 6}>
              <div>
                <FaCheck />
              </div>
              <p>Senha de até 6 dígitos</p>
            </St.CheckForm>

            <St.CheckForm active={checkFormSequencialNumber}>
              <div>
                <FaCheck />
              </div>
              <p>Números não sequenciais</p>
            </St.CheckForm>
          </St.Form>
          <St.ViewButton>
            <Button
              size="large"
              type="submit"
              fullWidth
              onClick={handleValidatePassword}
              disabled={buttonIsEnable}
              active={activeButton}
              loading={isLoading}
            >
              Continuar
            </Button>
          </St.ViewButton>
        </St.Container>
      </Layout>
    </>
  );
};

export default ForgotNewPass;
