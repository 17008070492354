import axios from 'axios';
import { logout } from './auth';

const api = axios.create({
  validateStatus: (status) => {
    return status >= 200 && status <= 600;
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function error(error) {
    if (error.response.status === 401 || error.response.status === 403) {
      logout();
    } else {
      return Promise.reject(error);
    }
  },
);

export default api;
