import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  width: 100%;
  margin-bottom: 24px;
  max-width: 200px;
`;

export const Text = styled.h1`
  font-size: 16px;
  color: #4a4b4a;
  font-weight: 400;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 22px;
  }
`;

export const Form = styled.form`
  width: 80%;
  margin: auto;
`;

export const Link = styled.span`
  border: 0;
  background: transparent;
  float: right;
  > span {
    cursor: pointer;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    color: #9d9d9d;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 40px;
    margin-top: -10px;
    text-decoration: none;

    &:hover {
      color: #e71037;
    }
  }
`;

export const Version = styled.span`
  position: fixed;
  bottom: 5px;
  left: 50%;
  color: red;
  transform: translate(-50%);
  font-size: 12px;
  transform: translate(-50%);
`;
