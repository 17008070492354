import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`  
  * {
    margin:0;
    padding:0;
    box-sizing: border-box;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;

    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: antialiased;
  }

  html,body, #root {
    height: 100%;
    width: 100vw;
    max-width:100%; 
    overflow-x: hidden !important;
    position: sticky;
    background-color: #fafbfe;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fafbfe;
    margin-top: 60px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e4003a;
    border-radius: 20px;
    border: 3px solid #fafbfe;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e4003a;
  }
`;
