import styled from 'styled-components';

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-left: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const IconCard = styled.img`
  height: 25px;
`;

export const SectionTitle = styled.span`
  font-size: 1.2em;
  font-weight: 700;
  color: #2e3958;
  height: min-content;
  margin-bottom: 30px;
`;

export const TitleFileChoose = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 400;
  padding-bottom: 12px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const TitleFileChooseSmall = styled.span`
  text-align: center;
  font-size: 0.8em;
  font-weight: 300;
  padding-bottom: 12px;
  margin-bottom: 8px;
  margin-right: 16px;
  white-space: nowrap;
`;

export const TableHistory = styled.table`
  width: 100%;
  border-spacing: 0;
  /* overflow: scroll;
  max-height: 400px; */
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    position: sticky;
    top: 0px;
    background-color: white;
  }
  tbody {
    display: block;
    /* overflow: scroll;
    width: calc(100% - 14px); */
  }
  td {
    text-align: center;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: grey;
    padding: 10px;
  }

  th {
    padding: 10px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: grey;
  }
`;

export const BlackListCards = styled.table`
  width: 100%;
  border-spacing: 0;
  align-items: center;
  margin-left: 150px;
  .card {
    width: 100%
    align-items: center
    justify-content: space-between;
    display: flex;
  }

  .card-number {
    font-size: 16px;
  }
`;

export const IconCardDesign = styled.img`
  width: 80px;
  border-radius: 4px;
  filter: drop-shadow(0 1px 0.1rem);
  margin: 0 10px;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;

export const ViewTerms = styled.span`
  color: #000;
  margin-right: 3px;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
`;

export const InputFileHidden = styled.input.attrs({ type: 'file' })`
  display: none;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;
