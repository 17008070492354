/* eslint-disable no-useless-concat */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import moment from 'moment';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Container from '~/components/container';
import TitleApp from '~/components/title';
import Select from '~/components/select';
import { INVOICE_TYPES } from './constants';
import * as St from './styles';
import { icons } from '~/assets';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Button from '~/components/button';
import Dialog from '~/components/dialog';

const InvoiceFile = (): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState('0');
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [msgDialog, setMsgDialog] = useState('');

  const currentDate = new Date();

  const RequestReportDelay = useApi(
    Endpoints.invoices.getFaturasAtraso,
    ApiMethod.GET,
  );

  const RequestReportFuturesInvoice = useApi(
    Endpoints.report.postReportFuturesInvoices,
    ApiMethod.POST,
  );

  const RequestReportUnpaidOpenInvoices = useApi(
    Endpoints.invoices.postReportUnpaidOpenInvoices,
    ApiMethod.POST,
  );

  const RequestReportClosingInvoicesCycles = useApi(
    Endpoints.report.postReportClosingInvoicesCycles.replace(
      'PARAM_YEAR',
      String(currentDate.getFullYear()),
    ),
    ApiMethod.POST,
  );

  const downloadInvoices = async () => {
    let response;

    try {
      switch (selectedValue) {
        case 'ATRASADO':
          response = await RequestReportDelay.callApi();
          break;
        case 'FUTURAS':
          response = await RequestReportFuturesInvoice.callApi();
          break;
        case 'EMITIDAS':
          response = await RequestReportUnpaidOpenInvoices.callApi();
          break;
        case 'FECHAMENTO':
          response = await RequestReportClosingInvoicesCycles.callApi();
          break;
        default:
          break;
      }

      if (response.status >= 200 && response.status < 300) {
        const { data }: any = response;

        let binaryString = '';
        for (let i = 0; i < data.base64.length; i += 4) {
          const chunk = data.base64.substr(i, 4);
          const binary = window.atob(chunk);
          binaryString += binary;
        }

        const decoder = new TextDecoder('utf-8');
        const csvText = decoder.decode(
          new Uint8Array([...binaryString].map((char) => char.charCodeAt(0))),
        );

        const rows = csvText.split('\n').map((row) => row.split(','));

        let csvContent = '';
        rows.forEach((row) => {
          csvContent +=
            row
              .map((cell) => {
                return cell;
              })
              .join(',') + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv' });

        const fileName = data?.fileName
          ? data?.fileName
          : `${moment().format('YYYY-MM-DD')}-${selectedValue}-clientes.csv`;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setMsgDialog(response.mensagem || 'Erro ao baixar');
        setDialogIsOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeForm = (e: string) => {
    setSelectedValue(e);
  };

  return (
    <>
      <Container>
        <Loading
          status={
            RequestReportDelay.loading ||
            RequestReportFuturesInvoice.loading ||
            RequestReportUnpaidOpenInvoices.loading ||
            RequestReportClosingInvoicesCycles.loading
          }
        />
        <Dialog
          icon={icons.exclamation}
          open={dialogIsOpen}
          positiveLabel="Entendi"
          positiveAction={() => setDialogIsOpen(false)}
          message={msgDialog}
        />
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16} flex={0.2}>
              <TitleApp marginBottom={12}>Contas a receber</TitleApp>
              <Select
                label="Faturas"
                options={INVOICE_TYPES}
                value={selectedValue}
                onChange={(e) => {
                  handleChangeForm(e);
                }}
              />
            </ContainerFlex>
          </ContainerFlex>
          {selectedValue && selectedValue !== '0' && (
            <Button
              onClick={() => {
                downloadInvoices();
              }}
            >
              <FaDownload />
              <span style={{ marginLeft: '8px' }}>Download</span>
            </Button>
          )}
        </St.BoxCard>
      </Container>
    </>
  );
};

export default InvoiceFile;
