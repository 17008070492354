/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  MdCropFree,
  MdPhotoCamera,
  MdClose,
  MdFileDownload,
} from 'react-icons/md';

import PictureModal from '~/components/pictureModal';
import { ImageZoomRotate } from '~/components/ImageZoomRotate';
import Spinner from '~/components/spinner';
import * as St from './styles';

interface IPictureCard {
  src: string;
  tipo?: string;
  width?: number | string;
  height?: number | string;
  expand?: boolean;
  loading?: boolean;
  showOptions?: boolean;
  onClickCamera?: () => void;
  onClickDelete?: () => void;
  onClickExpand?: () => void;
  onClickExpandPDF?: () => void;
  onClickDownload?: () => void;
}

const PictureCard: React.FC<IPictureCard> = ({
  src,
  expand = true,
  tipo = 'image',
  showOptions = false,
  loading,
  onClickDelete,
  onClickCamera,
  onClickExpand,
  onClickExpandPDF,
  onClickDownload,
  ...others
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpansion = () => setIsExpanded((prev) => !prev);

  return (
    <St.Container {...others}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <img src={src} alt="user" />

          {showOptions && (
            <div id="options">
              {onClickDelete && (
                <span id="icon" onClick={onClickDelete}>
                  <MdClose size={20} />
                </span>
              )}

              {expand && (
                <span
                  id="icon"
                  onClick={
                    tipo === 'image'
                      ? onClickExpand || handleToggleExpansion
                      : onClickExpandPDF
                  }
                >
                  <MdCropFree size={20} />
                </span>
              )}

              {onClickCamera && (
                <span id="icon" onClick={onClickCamera}>
                  <MdPhotoCamera size={20} />
                </span>
              )}

              {onClickDownload && (
                <span id="icon" onClick={onClickDownload}>
                  <MdFileDownload size={20} />
                </span>
              )}
            </div>
          )}
        </>
      )}
      {isExpanded && (
        <PictureModal handleClose={handleToggleExpansion}>
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              background: '#fff',
            }}
          >
            <ImageZoomRotate image={src} />
          </div>
        </PictureModal>
      )}
    </St.Container>
  );
};

export default PictureCard;
