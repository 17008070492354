import styled from 'styled-components';

export const Container = styled.button`
  padding: 2px 8px;
  background-color: #008000;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  right: 0;
  display: flex;
  align-items: center;
  span {
    font-weight: 700;
    color: #fff;
    font-size: 14px;
  }
  :hover {
    transform: scale(1.1);
    transition: all 200ms ease-in;
  }
`;
