/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import Spinner from '~/components/spinner';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input from '~/components/input';
import Button from '~/components/button';
import Loading from '~/components/loading';
import TextArea from '~/components/textarea';
import Dialog from '~/components/dialog';

import Endpoints from '~/services/endpoints';
import ConsultTransactionDetailResponse from '~/services/consult/transaction/detail/response';
import ConsultTransactionDetailPayload from '~/services/consult/transaction/detail/payload';

import { FormField, setFormState } from '~/utils/form';
import * as Masks from '~/utils/masks';

import useApi, { ApiMethod } from '~/hooks/useApi';

import { icons } from '~/assets';
import * as St from './styles';
import { transformInGlobalDate } from '~/utils/date';
import InstallmentsTransactionsResponse from '~/services/consult/transaction/installments/response';
import Container from '~/components/container';
import { ITransactionsWithdrawResponse } from '~/services/consult/transaction/withdraw/response';

interface LocationState {
  cpf?: FormField;
  numeroCartao?: FormField;
  idTransacao?: string;
  idPagamentoBackoffice?: string;
  localPagamento?: string;
}

interface Form {
  cIdOperador?: string;
  cCelular?: FormField;
  cCpf?: FormField;
  cDataVencimentoFatura?: FormField;
  cNome?: FormField;
  cNumeroCartao?: FormField;
  cDisputaPendente?: boolean;
  cDisputaEstornada?: boolean;
  tDataTransacao?: FormField;
  tDebitoCredito?: FormField;
  tDescricao?: FormField;
  tEstabelecimento?: FormField;
  tLocalPagamento?: FormField;
  tOrigemPagamento?: FormField;
  tHoraTransacao?: FormField;
  tIdTransacao?: FormField;
  tNumeroParcelas?: FormField;
  tTipo?: FormField;
  tValor?: FormField;
  tValorTotal?: FormField;
  tValorTotalSemJuros?: FormField;
}

interface FormWithdraw {
  tIdEmprestimo?: FormField;
  tValorSaque?: FormField;
  contratoBase64?: FormField;
  comprovanteBase64?: FormField;
}

const ConsultTransactionDetail = (): JSX.Element => {
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialoagInstallments, setDialoagInstallments] = useState(false);
  const [dialogInformativeIsOpen, setDialogInformativeIsOpen] = useState(false);
  const [dialogInformativetText, setInformativetText] = useState('');
  const [form, setForm] = useState<Form>({
    cCelular: { name: 'cCelular', value: '' },
    cCpf: { name: 'cCpf', value: '' },
    cDataVencimentoFatura: { name: 'cDataVencimentoFatura', value: '' },
    cNome: { name: 'cNome', value: '' },
    cNumeroCartao: { name: 'cNumeroCartao', value: '' },

    tDataTransacao: { name: 'tDataTransacao', value: '' },
    tDebitoCredito: { name: 'tDebitoCredito', value: '' },
    tDescricao: { name: 'tDescricao', value: '' },
    tEstabelecimento: { name: 'tEstabelecimento', value: '' },
    tLocalPagamento: { name: 'localPagamento', value: '' },
    tOrigemPagamento: { name: 'origemPagamento', value: '' },
    tHoraTransacao: { name: 'tHoraTransacao', value: '' },
    tIdTransacao: { name: 'tIdTransacao', value: '' },
    tNumeroParcelas: { name: 'tNumeroParcelas', value: '' },
    tTipo: { name: 'tTipo', value: '' },
    tValor: { name: 'tValor', value: '' },
    tValorTotalSemJuros: { name: 'tValorTotalSemJuros', value: '' },
  });

  const [formWithdraw, setFormWithdraw] = useState<FormWithdraw>({
    tIdEmprestimo: { name: 'tIdEmprestimo', value: '' },
    tValorSaque: { name: 'tValorSaque', value: '' },
    contratoBase64: { name: 'linkContrato', value: '' },
    comprovanteBase64: { name: 'linkComprovante', value: '' },
  });
  const location = useLocation<LocationState>();
  const history = useHistory();
  const isTotemPayment = location?.state?.localPagamento === 'TOTEM';

  const api = useApi<ConsultTransactionDetailResponse>(
    Endpoints.consult.detailTransaction,
    ApiMethod.POST,
  );

  const RequestInstallmentsTransactions = useApi<InstallmentsTransactionsResponse>(
    Endpoints.consult.transactionInstallments,
    ApiMethod.POST,
  );

  const RequestDetailsWithdraw = useApi<ITransactionsWithdrawResponse>(
    Endpoints.loan.getDetailsLoanTransaction.replace(
      'PARAM_ID_TRANSACAO',
      location.state.idTransacao,
    ),
    ApiMethod.GET,
  );

  const ResquestIncludeDispute = useApi(
    `${Endpoints.create.dispute}`,
    ApiMethod.POST,
  );

  const GetTotemPaymentTedailts = useApi(
    Endpoints.consult.getTotemPaymentDetails.replace(
      'PARAM_ID',
      location?.state?.idPagamentoBackoffice,
    ),
    ApiMethod.GET,
  );

  const GetTotemPaymentComprovant = useApi<{ comprovanteBase64: string }>(
    Endpoints.consult.getTotemPaymentComprovant.replace(
      'PARAM_ID',
      location?.state?.idPagamentoBackoffice,
    ),
    ApiMethod.GET,
  );

  const handlePaymentComprovant = async () => {
    const response = await GetTotemPaymentComprovant.callApi();
    if (response.data?.comprovanteBase64) {
      handlePdfNewTab(response.data?.comprovanteBase64);
    }
  };
  useEffect(() => {
    searchDetail();
  }, []);

  useEffect(() => {
    if (api.data) {
      loadData();
    }
  }, [api.loading]);

  useEffect(() => {
    if (location.state.idTransacao) {
      loadTransactionsWithdraw();
    }
  }, [location.state.idTransacao]);

  const loadTransactionsWithdraw = async () => {
    try {
      const response = await RequestDetailsWithdraw.callApi();
      const { data } = response;
      const obj: FormWithdraw = {
        tIdEmprestimo: { ...formWithdraw.tIdEmprestimo, value: data.id },
        tValorSaque: {
          ...formWithdraw.tValorSaque,
          value: String(data.valorLiberado),
        },
        contratoBase64: {
          ...formWithdraw.contratoBase64,
          value: data.contratoBase64,
        },
        comprovanteBase64: {
          ...formWithdraw.comprovanteBase64,
          value: data.comprovanteBase64,
        },
      };
      setFormWithdraw(obj);
    } catch (error) {
      console.log(error);
    }
  };
  const searchDetail = () => {
    const payload: ConsultTransactionDetailPayload = {
      idTransacao: location.state?.idTransacao,
      cpf: location.state?.cpf?.unmasked,
      numeroCartao: location.state?.numeroCartao?.unmasked,
    };

    api.callApi(payload);
    if (isTotemPayment) {
      GetTotemPaymentTedailts.callApi();
    }
  };

  const loadData = () => {
    const data = api.data as ConsultTransactionDetailResponse;

    const telefone = Masks.phoneMask(Masks.phoneUnmask(data.cliente?.celular));
    const dtVenc = Masks.dateMask(
      Masks.dateUnmask(String(data?.cliente?.dataVencimentoFatura)),
    );

    if (data.disputaPendente) setReason('Já foi salvo');

    const obj: Form = {
      cIdOperador: localStorage.getItem('USER_ID'),
      cCelular: {
        ...form.cCelular,
        value: telefone.masked,
        unmasked: telefone.unmasked,
      },
      cDisputaPendente: data.disputaPendente || false,
      cDisputaEstornada: data.disputaEstornada || false,
      cCpf: {
        ...form.cCpf,
        value: Masks.cpfMask(data?.cliente?.cpf).masked,
        unmasked: Masks.cpfMask(data?.cliente?.cpf).unmasked,
      },
      cDataVencimentoFatura: {
        ...form.cDataVencimentoFatura,
        value: dtVenc.masked,
        unmasked: dtVenc.unmasked,
      },
      cNome: { ...form.cNome, value: data?.cliente?.nome },
      cNumeroCartao: {
        ...form.cNumeroCartao,
        value: data?.cliente?.numeroCartao,
      },

      tDataTransacao: {
        ...form.tDataTransacao,
        value: data?.transacao?.dataTransacao?.split(' ')[0],
      },
      tDebitoCredito: {
        ...form.tDebitoCredito,
        value: data?.transacao?.debitoCredito,
      },
      tDescricao: { ...form.tDescricao, value: data?.transacao?.descricao },
      tEstabelecimento: {
        ...form.tEstabelecimento,
        value: data?.transacao?.estabelecimento,
      },
      tLocalPagamento: {
        ...form.tLocalPagamento,
        value: data?.transacao?.localPagamento,
      },
      tOrigemPagamento: {
        ...form.tOrigemPagamento,
        value: data?.transacao?.origemPagamento,
      },
      tHoraTransacao: {
        ...form.tHoraTransacao,
        value: data?.transacao?.horaTransacao,
      },
      tIdTransacao: {
        ...form.tIdTransacao,
        value: data?.transacao?.idTransacao,
      },
      tNumeroParcelas: {
        ...form.tNumeroParcelas,
        value: data?.transacao?.numeroParcelas,
      },
      tTipo: { ...form.tTipo, value: data?.transacao?.tipo },
      tValor: { ...form.tValor, value: data?.transacao?.valor },
      tValorTotal: {
        ...form.tValorTotal,
        value: data?.transacao?.valorTotalComJuros,
      },
      tValorTotalSemJuros: {
        ...form.tValorTotalSemJuros,
        value: data?.transacao?.valorTotalSemJuros,
      },
    };

    setForm(obj);
  };

  const onClickOpenInstallmentPouUp = () => {
    setDialoagInstallments(true);
    RequestInstallmentsTransactions.callApi({
      idTransacao: location.state?.idTransacao,
      numeroCartao: location.state?.numeroCartao?.unmasked,
    });
  };

  const goBack = () => history.goBack();

  const onClickIncludeDispute = (): void => {
    if (!reason.trim().length)
      return setReasonError('Prencha o campo corretamente.');

    setReasonError(null);
    setDialogIsOpen(true);
  };

  function base64ToBlob(base64, type = 'application/octet-stream') {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type });
  }

  const handlePdfNewTab = (base64: string) => {
    const blob = base64ToBlob(base64, 'application/pdf');
    const url = URL.createObjectURL(blob);
    const pdfWindow = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${url}'></iframe>`,
    );
  };

  const handleIncludeDispute = async () => {
    setDialogIsOpen(false);
    const response = await ResquestIncludeDispute.callApi({
      idOperador: form.cIdOperador,
      motivoDisputa: reason,
      numeroCartao: form.cNumeroCartao.value,
      transacao: {
        idTransacao: location.state?.idTransacao,
        valor: form.tValor.value,
        data: transformInGlobalDate(form.tDataTransacao.value),
        horario: form.tHoraTransacao.value,
        loja: form.tEstabelecimento.value,
        quantidadeParcelas: form.tNumeroParcelas.value,
        tipoTransacao: form.tTipo.value,
      },
    });

    if (response.status === 201 || response.status === 200) {
      setInformativetText('Transação incluida com sucesso!');
    } else {
      setInformativetText('Erro ao incluir transação.');
    }

    setDialogInformativeIsOpen(true);
  };

  return (
    <>
      <Loading status={api.loading || ResquestIncludeDispute.loading} />
      <Dialog
        icon={icons.confirmation}
        open={dialogIsOpen}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogIsOpen(false)}
        positiveLabel="Confirmar"
        positiveAction={handleIncludeDispute}
        message="Deseja incluir essa proposta para disputa ?"
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogInformativeIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => history.push('/consult/transaction')}
        message={dialogInformativetText}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialoagInstallments}
        title={<span style={{ color: 'red' }}> Parcelas </span>}
        positiveLabel="Voltar"
        positiveAction={() => setDialoagInstallments(false)}
      >
        {RequestInstallmentsTransactions.loading ? (
          <ContainerFlex center>
            <Spinner />
          </ContainerFlex>
        ) : (
          <St.Table>
            <tr>
              <th>N° Parcela </th>
              <th>Valor</th>
              <th style={{ borderRight: '1px solid grey' }}>Data vencimento</th>
            </tr>
            {RequestInstallmentsTransactions.data?.data?.map((installment) => (
              <tr>
                <td>{installment.parcela}</td>
                <td>
                  R$
                  {Masks.currencyMask(Number(installment.valor))}
                </td>
                <td style={{ borderRight: '1px solid grey' }}>
                  {moment(installment.vencimento).format('DD/MM/YYYY')}
                </td>
              </tr>
            ))}
          </St.Table>
        )}
      </Dialog>

      <Container>
        <St.BoxCard>
          <ContainerFlex color="#fbfbfb">
            <St.TitleCategory>1. Cliente</St.TitleCategory>
          </ContainerFlex>

          <ContainerFlex padding={26}>
            <Grid xs={1} lg={2} gap={30}>
              <ContainerFlex>
                <Input
                  label="Número do cartão"
                  value={
                    Masks.creditCardMask(form?.cNumeroCartao?.value).masked
                  }
                  name={form.cNumeroCartao?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Nome completo"
                  value={form.cNome?.value}
                  name={form.cNome?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="CPF"
                  value={form.cCpf?.value}
                  name={form.cCpf?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="Data de vencimento da fatura do cartão"
                  value={form.cDataVencimentoFatura?.value}
                  name={form.cDataVencimentoFatura?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Telefone"
                  value={form.cCelular?.value}
                  name={form.cCelular?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
              </ContainerFlex>
            </Grid>
          </ContainerFlex>

          <ContainerFlex row center color="#fbfbfb">
            <St.TitleCategory>2. Transação</St.TitleCategory>
          </ContainerFlex>

          <ContainerFlex padding={26}>
            <Grid xs={1} lg={2} gap={30}>
              <ContainerFlex>
                <Input
                  label="ID da transação"
                  value={form.tIdTransacao?.value}
                  name={form.tIdTransacao?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Valor da Parcela"
                  value={`R$${Masks.currencyMask(form.tValor?.value)}`}
                  name={form.tValor?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />

                <div>
                  <ContainerFlex row>
                    <ContainerFlex marginRight={16}>
                      <Input
                        label="Data da transação"
                        value={form.tDataTransacao?.value}
                        name={form.tDataTransacao?.name}
                        onChange={(e) => setFormState(e, form, setForm)}
                        readOnly
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Horário da transação"
                        value={form.tHoraTransacao?.value}
                        name={form.tHoraTransacao?.name}
                        onChange={(e) => setFormState(e, form, setForm)}
                        readOnly
                      />
                    </ContainerFlex>
                  </ContainerFlex>
                  <ContainerFlex row>
                    <ContainerFlex marginRight={16}>
                      <Input
                        label="Valor da Compra"
                        value={`R$${Masks.currencyMask(
                          Number(form.tValorTotalSemJuros?.value),
                        )}`}
                        name={form.tValorTotalSemJuros?.name}
                        onChange={(e) => setFormState(e, form, setForm)}
                        readOnly
                      />
                    </ContainerFlex>
                    {form.tTipo.value === 'COMPRA' && (
                      <ContainerFlex>
                        <Input
                          label="Valor Total"
                          value={`R$${Masks.currencyMask(
                            Number(form.tValorTotal?.value),
                          )}`}
                          name={form.tValorTotal?.name}
                          onChange={(e) => setFormState(e, form, setForm)}
                          readOnly
                        />
                      </ContainerFlex>
                    )}
                  </ContainerFlex>
                </div>

                {/* <Input label="Empresa" value="" readOnly /> */}
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="Local da transação"
                  value={
                    isTotemPayment
                      ? 'LOJA'
                      : form.tTipo?.value === 'PAGAMENTO_FATURA'
                      ? form.tLocalPagamento?.value
                      : form.tEstabelecimento?.value
                  }
                  name={form.tEstabelecimento.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                {/* <Input label="Lojas cadastradas" value="" readOnly /> */}
                <Input
                  label="Tipo de transação"
                  value={form.tTipo?.value}
                  name={form.tTipo?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Quantidade de parcelas"
                  value={form.tNumeroParcelas?.value}
                  name={form.tNumeroParcelas?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Descrição"
                  value={form.tDescricao?.value}
                  name={form.tDescricao?.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
              </ContainerFlex>
            </Grid>
          </ContainerFlex>

          <ContainerFlex marginLeft={26}>
            <Button fitContent onClick={onClickOpenInstallmentPouUp}>
              Ver parcelas
            </Button>
          </ContainerFlex>

          {form?.tLocalPagamento?.value === 'TOTEM' ? (
            <>
              <ContainerFlex row center color="#fbfbfb">
                <St.TitleCategory>
                  3. Comprovantes da Transação
                </St.TitleCategory>
              </ContainerFlex>

              <ContainerFlex padding={26}>
                <Grid xs={1} lg={2} gap={30}>
                  <ContainerFlex>
                    <Input
                      label="Forma de pagamento"
                      value={GetTotemPaymentTedailts.data?.formaPagamento}
                      name={form.tIdTransacao.name}
                      readOnly
                    />
                    <Input
                      label="Valor da transação de pagamento"
                      value={`R$${Masks.currencyMask(
                        GetTotemPaymentTedailts.data?.valorTransacaoPagamento,
                      )}`}
                      name={form.tValor.name}
                      readOnly
                    />
                  </ContainerFlex>

                  <ContainerFlex>
                    <Input
                      label="Final do cartão"
                      value={GetTotemPaymentTedailts.data?.numeroFinalCartao}
                      name={form.tEstabelecimento.name}
                      readOnly
                    />

                    <ContainerFlex
                      row
                      align="center"
                      style={{ maxHeight: '52px' }}
                    >
                      <Input
                        label="Comprovante"
                        value={
                          GetTotemPaymentTedailts.data?.nomeArquivoComprovante
                        }
                        name={form.tTipo.name}
                        readOnly
                      />
                      <Button
                        style={{ width: 25, height: 37, marginLeft: 10 }}
                        onClick={() => {
                          handlePaymentComprovant();
                        }}
                      >
                        Visualizar
                      </Button>
                    </ContainerFlex>
                  </ContainerFlex>
                </Grid>
              </ContainerFlex>
            </>
          ) : null}

          {form.tTipo.value === 'SAQUE' ? (
            <>
              <ContainerFlex row center color="#fbfbfb" marginTop={10}>
                <St.TitleCategory>
                  3. Comprovantes da transação
                </St.TitleCategory>
              </ContainerFlex>

              <ContainerFlex padding={26}>
                <Grid xs={1} lg={2} gap={30}>
                  <ContainerFlex>
                    <Input
                      label="ID da solicitação do saque"
                      value={formWithdraw?.tIdEmprestimo?.value}
                      name={formWithdraw?.tIdEmprestimo?.name}
                      onChange={(e) => setFormState(e, form, setForm)}
                      readOnly
                    />

                    <ContainerFlex
                      row
                      align="center"
                      style={{ maxHeight: '52px' }}
                    >
                      <Input label="Termo" value="CONTRATO ASSINADO" />
                      <Button
                        style={{ width: 25, height: 37, marginLeft: 10 }}
                        onClick={() => {
                          handlePdfNewTab(formWithdraw?.contratoBase64.value);
                        }}
                      >
                        Visualizar
                      </Button>
                    </ContainerFlex>
                  </ContainerFlex>
                  <ContainerFlex>
                    <Input
                      label="Valor do saque"
                      value={`R$${Masks.currencyMask(
                        formWithdraw?.tValorSaque?.value,
                      )}`}
                      name={formWithdraw?.tValorSaque?.name}
                      onChange={(e) => setFormState(e, form, setForm)}
                      readOnly
                    />
                    <ContainerFlex
                      row
                      align="center"
                      style={{ maxHeight: '52px' }}
                    >
                      <Input label="Comprovante" value="TRANSFERÊNCIA" />
                      <Button
                        style={{ width: 25, height: 37, marginLeft: 10 }}
                        disabled={!formWithdraw?.comprovanteBase64?.value}
                        onClick={() => {
                          handlePdfNewTab(
                            formWithdraw?.comprovanteBase64?.value,
                          );
                        }}
                      >
                        Visualizar
                      </Button>
                    </ContainerFlex>
                  </ContainerFlex>
                </Grid>
              </ContainerFlex>
            </>
          ) : null}

          <ContainerFlex padding={26}>
            <TextArea
              placeholder="Informe o motivo.."
              rows={10}
              onChange={(e) => setReason(e.target.value)}
              errorMessage={reasonError}
              readOnly={form.cDisputaPendente}
              value={reason}
            />
          </ContainerFlex>

          <ContainerFlex row end padding={24}>
            <Button onClick={goBack} outlined>
              Voltar
            </Button>
            {form?.tTipo?.value === 'COMPRA' ? (
              <Button
                marginLeft={16}
                onClick={onClickIncludeDispute}
                disabled={form.cDisputaPendente || form.cDisputaEstornada}
              >
                Incluir Disputa
              </Button>
            ) : null}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ConsultTransactionDetail;
