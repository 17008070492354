import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';

import Loading from '~/components/loading';
import TableList from '~/components/tableList';

import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { cpfMask, IDefaultReturn } from '~/utils/masks';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import { transformInNormalDate } from '~/utils/date';
import SearchLimitSolicitation from '~/services/credit-table/limitSolicitation/response';
import { IListColCampaing } from '~/views/LimitIncrease/model';
import Container from '~/components/container';

import * as St from './styles';

interface IForm {
  cpf: IDefaultReturn;
  nome: string;
}

const ConsultLimitCT = (): JSX.Element => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({} as IForm);
  const [list, setList] = useState([]);

  const history = useHistory();

  const handleGenerateURL = useMemo(() => {
    if (form.cpf?.unmasked && form.nome) {
      return `${Endpoints.consult.searchLimitSolicitation}?CPF=${form.cpf?.unmasked}&nome=${form.nome}`;
    }
    if (form.cpf?.unmasked && (!form.nome || form.nome === '')) {
      return `${Endpoints.consult.searchLimitSolicitation}?CPF=${form.cpf?.unmasked}`;
    }
    if (form.nome && (!form.cpf?.unmasked || form.cpf?.unmasked === '')) {
      return `${Endpoints.consult.searchLimitSolicitation}?CPF=&nome=${form.nome}`;
    }
    return `${Endpoints.consult.searchLimitSolicitation}`;
  }, [form.cpf, form.nome]);

  const RequestSearchLimitSolicitation = useApi<SearchLimitSolicitation>(
    handleGenerateURL,
    ApiMethod.GET,
  );

  useEffect(() => {
    loadData();
  }, [RequestSearchLimitSolicitation.loading]);

  const searchUser = () => RequestSearchLimitSolicitation.callApi();

  const goToDetail = (cpf, idProposal) =>
    history.push('/credit-table/limit/solicitation/detail', {
      cpf,
      idProposal,
    });

  const customerHeader = [
    {
      id: 1,
      value: 'ID da proposta',
      maxChar: 30,
    },
    {
      id: 2,
      value: 'Nome completo',
      maxChar: 35,
    },
    {
      id: 3,
      value: 'CPF',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Status',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Data e Horário',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const loadData = () => {
    if (RequestSearchLimitSolicitation.status !== 200) {
      setUserList([]);
      return;
    }

    const dataRes = RequestSearchLimitSolicitation.data as SearchLimitSolicitation;

    // let i = 0;

    if (RequestSearchLimitSolicitation.status === 200) {
      const data = RequestSearchLimitSolicitation.data as SearchLimitSolicitation[];

      const item: IListColCampaing[] = [
        // {
        //   type: 'id',
        //   value: i++,
        //   maxChar: 5,
        //   textAlign: 'center',
        // },
        {
          border: true,
          title: 'ID da proposta',
          value: dataRes.idProposta,
          maxChar: 30,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Nome completo',
          value: dataRes.cliente?.nome,
          maxChar: 35,
        },
        {
          border: true,
          title: 'CPF',
          value: cpfMask(dataRes.cpf).masked,
          maxChar: 25,
          textAlign: 'center',
        },
        {
          title: 'Status',
          value: dataRes.status,
          maxChar: 25,
          textAlign: 'center',
        },
        {
          title: 'Data e Horário',
          value: `${
            transformInNormalDate(dataRes.dataAprovacao.split('T')[0]).masked
          } ${dataRes.dataAprovacao.split('T')[1].split('.')[0]}`,
          maxChar: 20,
          textAlign: 'center',
        },
        {
          item: data,
          searchAction: () => goToDetail(dataRes.cpf, dataRes.idProposta),
        },
      ];

      setList([item]);
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <Loading status={RequestSearchLimitSolicitation.loading} />
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input label="Nome" name="nome" onChange={handleChange} />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                value={form.cpf?.masked}
                name="cpf"
                onChange={handleChange}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                loading={isLoading}
                disabled={isLoading}
                onClick={searchUser}
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row padding={16}>
            {RequestSearchLimitSolicitation.status !== 200 && (
              <ContainerFlex center marginTop={30}>
                <TitleCategory>Cliente não encontrado</TitleCategory>
              </ContainerFlex>
            )}

            {list.length > 0 &&
              !(RequestSearchLimitSolicitation.status !== 200) && (
                <TableList customerHeader={customerHeader} list={list} />
              )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ConsultLimitCT;
