const THEMES = {
  colors: {
    primary: '#E71037',
    secundary: '#f3879b',
    tertiary: '#FFFFFF',
    gray: '#828282',
    error: '#ff1a1a',
    hover: '#C91031',
  },
  fonts: ['Open Sans', 'Open Sans SemiBold'],
  fontSizes: {
    small: '1rem',
    xsmall: '1.2rem',
    medium: '2rem',
    large: '3rem',
  },
};

export default THEMES;
