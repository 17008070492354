import { useState } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import * as St from './styles';

export enum PERFIS {
  // USUARIO = 'USUARIO',
  // PARCEIRO = 'PARCEIRO',
  // VISITANTE = 'VISITANTE',
  ADM = 'ADMINISTRAÇÃO',
  OPR = 'OPERADOR',
  RET = 'RETAGUARDA',
  MDC = 'MESA DE CRÉDITO',
  SUP = 'SUPERVISOR',
  CXA = 'CAIXA',
  ASSESSORIA = 'ASSESSORIA',
}
const ButtonTransferLeft = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.4 15L22 11.4L25.6 15L27 13.6L23.4 10L27 6.4L25.6 5L22 8.6L18.4 5L17 6.4L20.6 10L17 13.6L18.4 15ZM22 20C20.6167 20 19.3167 19.7375 18.1 19.2125C16.8833 18.6875 15.825 17.975 14.925 17.075C14.025 16.175 13.3125 15.1167 12.7875 13.9C12.2625 12.6833 12 11.3833 12 10C12 8.61667 12.2625 7.31667 12.7875 6.1C13.3125 4.88333 14.025 3.825 14.925 2.925C15.825 2.025 16.8833 1.3125 18.1 0.7875C19.3167 0.2625 20.6167 0 22 0C23.3833 0 24.6833 0.2625 25.9 0.7875C27.1167 1.3125 28.175 2.025 29.075 2.925C29.975 3.825 30.6875 4.88333 31.2125 6.1C31.7375 7.31667 32 8.61667 32 10C32 11.3833 31.7375 12.6833 31.2125 13.9C30.6875 15.1167 29.975 16.175 29.075 17.075C28.175 17.975 27.1167 18.6875 25.9 19.2125C24.6833 19.7375 23.3833 20 22 20ZM22 18C24.2333 18 26.125 17.225 27.675 15.675C29.225 14.125 30 12.2333 30 10C30 7.76667 29.225 5.875 27.675 4.325C26.125 2.775 24.2333 2 22 2C19.7667 2 17.875 2.775 16.325 4.325C14.775 5.875 14 7.76667 14 10C14 12.2333 14.775 14.125 16.325 15.675C17.875 17.225 19.7667 18 22 18Z"
      fill="#E71037"
    />
    <path d="M6 17L0 11L6 5L7.4 6.4L2.8 11L7.4 15.6L6 17Z" fill="#E71037" />
  </svg>
);
const ButtonTransferRight = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 15H11V11H15V9H11V5H9V9H5V11H9V15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
      fill="#08AD0F"
    />
    <path
      d="M27.6 10L23 5.4L24.4 4L30.4 10L24.4 16L23 14.6L27.6 10Z"
      fill="#08AD0F"
    />
  </svg>
);

export const allProfiles2 = Object.entries(PERFIS).map(([key, label]) => ({
  label,
  key,
}));
type Profile = {
  value: string;
  name: string;
};
type ITransferProps = {
  open: boolean;
  onClose: () => void;
  currentProfile: Profile;
  onSubmit: (data: Profile) => void;
  allProfiles: Profile[];
};

const CF = ContainerFlex;
const TransferList: React.FC<ITransferProps> = (props) => {
  const { open, onClose, onSubmit, currentProfile, allProfiles } = props;

  // const [atuais, setAtuais] = useState(() => perfil);

  const [atuais, setAtuais] = useState<Profile>(currentProfile);

  const [disponiveis, setDisponiveis] = useState(
    allProfiles.filter((e) => e !== currentProfile),
  );

  console.log('disponiveis', disponiveis);
  console.log('atuais', atuais);
  const handleTransferProfile = (item, origem: 'disponiveis' | 'atuais') => {
    if (origem === 'atuais') {
      setDisponiveis((prev) => [...prev, item]);
      setAtuais({});
      return;
    }

    if (origem === 'disponiveis') {
      setAtuais(item);
      setDisponiveis(allProfiles.filter((e) => e !== item));
    }
  };

  return (
    <Dialog
      widthInPercent={50}
      icon
      title="Perfil de funcionário"
      negativeLabel="Voltar"
      negativeAction={() => onClose()}
      positiveAction={() => onSubmit(atuais)}
      positiveLabel="Gravar"
      open={open}
    >
      <CF row gap="16">
        <CF aria-describedby="perfilDisponivel">
          <CF>
            <span style={{ textAlign: 'center', marginBottom: 4 }}>
              Perfil disponível
            </span>
            <CF
              style={{
                border: '1px solid gray',
                borderRadius: '10px',
                boxShadow: '1px 5px 5px 0px rgba(0,0,0,0.75)',
              }}
            >
              <St.List>
                {disponiveis.map((e) => (
                  <li onClick={() => handleTransferProfile(e, 'disponiveis')}>
                    {e.name}
                  </li>
                ))}
              </St.List>
            </CF>
          </CF>
        </CF>

        <CF aria-describedby="perfilAtual">
          <CF>
            <span style={{ textAlign: 'center', marginBottom: 4 }}>
              Perfil Atual
            </span>
            <CF
              style={{
                border: '1px solid gray',
                borderRadius: '10px',
                boxShadow: '1px 5px 5px 0px rgba(0,0,0,0.75)',
              }}
            >
              <St.List>
                <li onClick={() => handleTransferProfile(atuais, 'atuais')}>
                  {atuais.name}
                </li>
              </St.List>
            </CF>
          </CF>
        </CF>
      </CF>
    </Dialog>
  );
};
export default TransferList;
