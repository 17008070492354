import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask } from '~/utils/masks';

export const HeaderReportWithdrawBilling = [
  {
    id: 1,
    value: 'Nome do cliente',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 17,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Valor do saque',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Valor do Juros',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Valor TAC',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Valor IOF',
    maxChar: 15,
    textAlign: 'center',
  },
  { id: 7, value: 'Data do desembolso', maxChar: 15, textAlign: 'center' },
];

export const BodyReportWithdrawBilling = (data: any) => {
  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        id: 1,
        value: i.cliente.nome,
        maxChar: 25,
      },
      {
        id: 2,
        value: i.cliente.cpf ? cpfMask(i.cliente.cpf).masked : '',
        maxChar: 17,
        textAlign: 'center',
      },
      {
        id: 3,
        value: `R$ ${currencyMask(i.valorLiberado)}`,
        textAlign: 'center',
      },
      { id: 4, value: `R$ ${currencyMask(i.valorJuros)}`, textAlign: 'center' },
      { id: 5, value: `R$ ${currencyMask(i.tacAmount)}`, textAlign: 'center' },
      { id: 6, value: `R$ ${currencyMask(i.valorIOF)}`, textAlign: 'center' },
      {
        id: 7,
        value: moment(i.dataPagamento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportWithdrawBillingCSV = (data: any) => {
  const dataCSV = [];

  data.forEach((csv) => {
    dataCSV.push({
      nomeCliente: csv.cliente.nome,
      cpf: csv.cliente.cpf,
      email: csv.cliente.email,
      celular: csv.cliente.celular,
      valorLiberado: csv.valorLiberado,
      valorJuros: csv.valorJuros,
      tacAmount: csv.tacAmount,
      valorIOF: csv.valorIOF,
      dataPagamento: csv.dataPagamento,
      dataPrimeiroPago: csv.dataPrimeiroPagamento,
      dataPagamentoFatura: csv.dataPagamentoFatura,
    });
  });

  return dataCSV;
};
