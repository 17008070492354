/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { CSV_HEADERS } from '../../constants';
import Models from '../../models';
import {
  FINANCIAL_TYPES,
  IFinancialReport,
  ReportIOFResponse,
  ReportFinancialIncomeResponse,
  ReportRecipeAgreementResponse,
  ReportCashBackZeussResponse,
} from '../models';

export const FinancialReportContext = createContext<IFinancialReport>(
  {} as IFinancialReport,
);

const FinancialReportProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [relatorioData, setRelatorioData] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [header, setHeader] = useState([]);

  const RequestReportIOF = useApi<ReportIOFResponse>(
    Endpoints.report.getReportIOF,
    ApiMethod.POST,
  );

  const RequestReportFinancialIncome = useApi<ReportFinancialIncomeResponse>(
    Endpoints.report.getReportFinancialIncome,
    ApiMethod.POST,
  );

  const RequestReportRecipeAgreement = useApi<ReportRecipeAgreementResponse>(
    Endpoints.report.getReportRecipeAgreement,
    ApiMethod.POST,
  );

  const RequestReportCashBackZeuss = useApi<ReportCashBackZeussResponse>(
    Endpoints.report.getReportCashBackZeuss,
    ApiMethod.POST,
  );

  const clearForm = () => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
  };

  useEffect(() => {
    if (
      RequestReportIOF.loading ||
      RequestReportFinancialIncome.loading ||
      RequestReportRecipeAgreement.loading ||
      RequestReportCashBackZeuss.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    RequestReportIOF.loading,
    RequestReportFinancialIncome.loading,
    RequestReportRecipeAgreement.loading,
    RequestReportCashBackZeuss.loading,
  ]);

  const checkSuccess = () => {
    if (
      RequestReportIOF.error ||
      RequestReportIOF.status === 404 ||
      RequestReportIOF.status === 500 ||
      RequestReportFinancialIncome.error ||
      RequestReportFinancialIncome.status === 404 ||
      RequestReportFinancialIncome.status === 500 ||
      RequestReportRecipeAgreement.error ||
      RequestReportRecipeAgreement.status === 404 ||
      RequestReportRecipeAgreement.status === 500 ||
      RequestReportCashBackZeuss.error ||
      RequestReportCashBackZeuss.status === 404 ||
      RequestReportCashBackZeuss.status === 500
    ) {
      return true;
    }
    return false;
  };

  const postReportIOF = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
    };

    const response = await RequestReportIOF.callApi(newPayload);

    setHeader(CSV_HEADERS.IOF);

    if (returnResponse) return response.data;

    const { data } = response.data;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderRepostIOF);
      setItems(Models.BodyRepostIOF(data));
      setRelatorioData(data);
      setTotalItems(data.length);
    }
  };

  const postReportFinancialIncome = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportFinancialIncome.callApi(newPayload);

    setHeader(CSV_HEADERS.FINANCIAL_INCOME);

    if (returnResponse) return response.data;

    const { data } = response.data;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderFinancialIncome);
      setItems(Models.BodyFinancialIncome(data));
      setTotalItems(data.totalItens);
      setTotalPages(data.totalDePaginas);
    }
  };

  const postReportRecipeAgreement = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportRecipeAgreement.callApi(newPayload);

    setHeader(CSV_HEADERS.RECIPE_AGREEMENT);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderRecipeAgreement);
      setItems(Models.BodyRecipeAgreement(data));
      setTotalItems(data.totalItens);
      setTotalPages(data.totalDePaginas);
    }
  };

  const postReportCashBackZeuss = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      qtdRegistros: limit,
      pagina: page,
    };

    const response = await RequestReportCashBackZeuss.callApi(newPayload);

    setHeader(CSV_HEADERS.CASHBACK_ZEUSS);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status >= 200 && response.status < 300) {
      setHeaderItems(Models.HeaderCashBackZeuss);
      setItems(Models.BodyCashBackZeuss(data));
      setTotalItems(data.totalItens);
      setTotalPages(data.totalPaginas);
    }
  };

  return (
    <FinancialReportContext.Provider
      value={{
        postReportIOF,
        postReportFinancialIncome,
        postReportRecipeAgreement,
        postReportCashBackZeuss,
        checkSuccess,
        loading,
        items,
        headerItems,
        header,
        totalItems,
        totalPages,
        relatorioData,
        setRelatorioData,
        clearForm,
        FINANCIAL_TYPES,
      }}
    >
      {children}
    </FinancialReportContext.Provider>
  );
};
export default FinancialReportProvider;
