import { createContext, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  IEventReport,
  ReportCancellationAccountEventResponse,
  EVENTS_TYPES,
} from '../models';

import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { GetOperatorsResponse } from '~/services/report/requestReport/response';
import { CSV_HEADERS } from '../../constants';
import Models from '../../models';

export const EventReportContext = createContext<IEventReport>(
  {} as IEventReport,
);

const EventReportProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    tipoEvento: { name: 'tipoEvento', value: 'CANCELAMENTO_PENDENTE' },
    idOperador: 'TODOS',
    dataInicial: moment().format('DD/MM/YYYY'),
    dataFinal: moment().format('DD/MM/YYYY'),
  });
  const [operador, setOperador] = useState([]);
  const [items, setItems] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [relatorioData, setRelatorioData] = useState([]);
  const [header, setHeader] = useState([]);

  const RequestGetOperators = useApi(
    Endpoints.report.getOperators,
    ApiMethod.GET,
  );

  const RequestValidationCancellationEvent = useApi<ReportCancellationAccountEventResponse>(
    Endpoints.events.postReportEventCancelation,
    ApiMethod.POST,
  );

  const getOperators = useCallback(async () => {
    const { data, status, mensagem } = await RequestGetOperators.callApi();
    return { data, status, mensagem };
  }, [RequestGetOperators]);

  const getReportEventsCancellation = async (payload) => {
    try {
      const response = await RequestValidationCancellationEvent.callApi(
        payload,
      );

      setHeader(CSV_HEADERS.CANCELLATION_VALIDATION);

      if (response.status === 200) {
        setHeaderItems(Models.HeaderReportEventAccountCancellation);
        setItems(Models.BodyReportEventAccountCancellation(response.data));
        setRelatorioData(
          Models.BodyReportEventAccountCancellationCSV(response.data),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log('FORM', form);

  const getOperator = async () => {
    const response = await getOperators();

    if (response.status === 200) {
      const data = response.data as GetOperatorsResponse[];

      const getOperators = data.map(({ nome, id }) => ({ id, value: nome }));

      setOperador(getOperators);
    }
  };

  const clearForm = () => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
  };

  useEffect(() => {
    if (
      RequestGetOperators.loading ||
      RequestValidationCancellationEvent.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [RequestGetOperators.loading, RequestValidationCancellationEvent.loading]);

  return (
    <EventReportContext.Provider
      value={{
        form,
        setForm,
        getOperator,
        operador,
        loading,
        getReportEventsCancellation,
        clearForm,
        EVENTS_TYPES,
        items,
        headerItems,
        header,
        relatorioData,
        setRelatorioData,
      }}
    >
      {children}
    </EventReportContext.Provider>
  );
};

export default EventReportProvider;
