import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, phoneMask } from '~/utils/masks';

export const HeaderProposalsRegistered = [
  {
    id: 1,
    value: 'Nome completo',
    maxChar: 20,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Celular',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Operador(a) de Venda',
    maxChar: 20,
  },
  {
    id: 5,
    value: 'Origem',
    maxChar: 12,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Data Proposta',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Data Aprovação',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 8,
    value: 'UF',
    maxChar: 8,
    textAlign: 'center',
  },
];

export const BodyProposalsRegistered = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: cpfMask(i.cpf).masked,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: phoneMask(i.celular).masked,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.operador,
        maxChar: 20,
      },
      {
        value: i.origem,
        maxChar: 12,
        textAlign: 'center',
      },
      {
        value: i.dataProposta
          ? moment(i.dataProposta).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.dataAprovacao
          ? moment(i.dataAprovacao).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.uf,
        maxChar: 8,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
