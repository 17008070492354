import styled from 'styled-components';

interface IListRowContainer {
  marginLeft?: boolean;
}

export const Container = styled.div<IListRowContainer>`
  margin-bottom: 12px;
  margin-left: ${(p) => (p.marginLeft ? `18px` : '0px')};
`;

export const ListTitle = styled.h3``;
