import styled from 'styled-components';

export const TitleCategory = styled.span`
  width: 100%;
  padding: 24px;
  font-size: 1rem;
  font-weight: 600;
  color: #2e3958;
  background-color: #fbfbfb;
`;

export const TitleFileChoose = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const TitleFileChooseSmall = styled.span`
  justify-content: center;
  font-size: 0.8em;
  font-weight: 300;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const ImgDocument = styled.img`
  height: 80px;
  margin-right: 16px;
`;

export const TableHistory = styled.table`
  width: 100%;
  border-spacing: 0;

  td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: grey;
    padding: 10px;
  }

  th {
    padding: 10px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: grey;
  }
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;
