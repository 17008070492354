import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';
import Button from '~/components/button';
import endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import { icons } from '~/assets';
import Dialog from '~/components/dialog';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Loading from '~/components/loading';

import * as St from './styles';
import Container from '~/components/container';

interface Form {
  tipoRelatorio: FormField;
}

const FilePositive: React.FC = () => {
  const [form, setForm] = useState<Form>({
    tipoRelatorio: { name: 'tipoRelatorio', value: '' },
  });

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [error, setError] = useState<string | null>();

  const getReportFileDownloadPositive = useApi<Blob>(
    endpoints.report.getReportFileDownloadPositive.replace(
      'PARAM_STATUS/PARAM_DIAS',
      `${form.tipoRelatorio.value}`,
    ),
    ApiMethod.GET,
  );

  const TIPO_RELATORIO = [
    {
      id: 'SIM/30',
      value: 'Positivados - SIM - 30 dias',
      fileName: 'POSITIVADO_SIM_30DIAS.csv',
    },
    {
      id: 'NAO/30',
      value: 'Positivados - NÃO - 30 dias',
      fileName: 'POSITIVADO_NAO_30DIAS.csv',
    },
    {
      id: 'SIM/60',
      value: 'Positivados - SIM - 60 dias',
      fileName: 'POSITIVADO_SIM_60DIAS.csv',
    },
    {
      id: 'NAO/60',
      value: 'Positivados - NÃO - 60 dias',
      fileName: 'POSITIVADO_NAO_60DIAS.csv',
    },
    {
      id: 'SIM/90',
      value: 'Positivados - SIM - 90 dias',
      fileName: 'POSITIVADO_SIM_90DIAS.csv',
    },
    {
      id: 'NAO/90',
      value: 'Positivados - NÃO - 90 dias',
      fileName: 'POSITIVADO_NAO_90DIAS.csv',
    },
  ];

  const getPositiveReportDownload = async () => {
    const search = (obj) => obj.id === form.tipoRelatorio.value;
    const index = TIPO_RELATORIO.findIndex(search);

    try {
      const response = await getReportFileDownloadPositive.callApi();

      if (response.status >= 200 && response.status < 300) {
        const contentType = 'text/csv;charset=UTF-8';
        const url = window.URL.createObjectURL(
          new Blob(['\ufeff', response.data], { type: contentType }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', TIPO_RELATORIO[index].fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        setDialogIsOpen(true);
        setError(response.mensagem || 'Não há arquivos.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Loading status={getReportFileDownloadPositive.loading} />
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message="Não foi possível baixar o arquivo."
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16} flex={0.3}>
              <Select
                label="Relatório"
                name={form.tipoRelatorio.name}
                value={form.tipoRelatorio.value}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.tipoRelatorio.name, value: e } },
                    form,
                    setForm,
                  );
                }}
                options={TIPO_RELATORIO}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={getPositiveReportDownload}
                disabled={!form.tipoRelatorio.value}
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default FilePositive;
