/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { FaRegFileAlt } from 'react-icons/fa';
import Button from '~/components/button';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';
import TitleApp from '~/components/title';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Dialog from '~/components/dialog';
import Pagination from '~/components/pagination';
import { icons } from '~/assets';

import Endpoints from '~/services/endpoints';
import ConsultInvoiceUserDetailResponse from '~/services/consult/user/invoice/detail/response';
import SendInvoiceEmail from '~/services/consult/user/invoice/sendInvoice/response';
import UserGetInvoiceResponse from '~/services/consult/user/invoice/getInvoice/response';
import useApi, { ApiMethod } from '~/hooks/useApi';
import * as Masks from '~/utils/masks';
import IUserInvoiceInfo from '~/models/userInvoiceInfo';
import * as St from './styles';
import Loading from '~/components/loading';
import Container from '~/components/container';
import Popover from '~/components/Popover';

interface LocationState {
  cpf: string;
  numeroCartao: string;
  dataVencimento: string;
  dataPagamento: string;
  idFatura: string;
  faturaId: string;
  mesReferencia: string;
  anoReferencia: string;
  status: string;
  linhaDigitavel: string;
  valorMaximoRecebimento: number;
  valorMinimoRecebido: number;
  valorPagamento: number;
  saldoFaturaAtual: number;
  allData: [];
}

interface InvoiceData {
  cpf: string;
  dataEnvioEmail: string;
  dataVencimento: string;
  dataVencimentoFaturaCartao: string;
  codigoPix: string;
  email: string;
  idFatura: string;
  nomeCliente: string;
  numeroCartao: string;
  status: string;
  statusDescricao: string;
  statusEmail: string;
  telefone: string;
}

interface InvoicePaymentDetail {
  data: {
    limitePorPagina: number;
    pagina: {
      dataPagamento: string;
      dataVencimento: string;
      localPagamento: string;
      origemPagamento: string;
      valorPagamento: number;
      valorTotal: number;
    }[];
    paginaAtual: number;
    totalDePaginas: number;
    totalItens: number;
  };
}

const InvoiceDetail = (): JSX.Element => {
  const location = useLocation<LocationState>();

  const history = useHistory();
  const [dialogAskEmailShow, setDialogAskEmailShow] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogPrintIsOpen, setDialogPrintIsOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [emailToSendInvoice1, setEmailToSendInvoice1] = useState(false);
  const [emailToSendInvoice2, setEmailToSendInvoice2] = useState(false);
  const [emailInvoice, setEmailInvoice] = useState('');
  const [getEmailInvoice, setGetEmailInvoice] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [popoverLineIsOpen, setPopoverLineIsOpen] = useState(false);
  const [popoverPixIsOpen, setPopoverPixIsOpen] = useState(false);
  // const [chronometer, setChronometer] = useState(5);
  // const [chronometerInterval, setChronometerInterval] = useState(0);
  const [disablePrintButton, setDisablePrintButton] = useState(false);
  const [invoiceData, setInvoiceData] = useState<InvoiceData>();
  const [dialogInvoicePaymentDetail, setDialogInvoicePaymentDetail] = useState(
    false,
  );
  const [invoicePaymentDetailData, setInvoicePaymentDetailData] = useState([]);
  const [
    totalPagesInvoicePaymentDetail,
    setTotalPagesInvoicePaymentDetail,
  ] = useState(0);
  const [
    currentPageInvoicePaymentDetail,
    setCurrentPageInvoicePaymentDetail,
  ] = useState(1);

  const [infos, setInfos] = useState<IUserInvoiceInfo>({
    nome: '',
    celular: '',
    diaVencimento: '',
    email: '',
  });

  const api = useApi<ConsultInvoiceUserDetailResponse>(
    Endpoints.consult.detailUser.replace('PARAM_CPF', location.state?.cpf),
    ApiMethod.GET,
  );
  const apiSendInvoiceEmail = useApi<SendInvoiceEmail>(
    Endpoints.consult.sendInvoiceEmail
      .replace('PARAM_CPF', location.state?.cpf)
      .replace('PARAM_MES', location.state?.mesReferencia)
      .replace('PARAM_ANO', location.state?.anoReferencia)
      .replace('PARAM_EMAIL', getEmailInvoice),
    ApiMethod.POST,
  );

  const apiInvoice = useApi<UserGetInvoiceResponse>(
    Endpoints.consult.searchInvoice
      .replace('PARAM_CPF', location.state?.cpf)
      .replace('PARAM_MES', location.state?.mesReferencia)
      .replace('PARAM_ANO', location.state?.anoReferencia),
    ApiMethod.GET,
  );

  const apiInvoicePaymentDetail = useApi<InvoicePaymentDetail>(
    Endpoints.consult.detailInvoice
      .replace('PARAM_ID_FATURA', location?.state?.faturaId)
      .replace('PARAM_LIMIT_PAGE', '10')
      .replace('PARAM_PAGE', String(currentPageInvoicePaymentDetail)),
    ApiMethod.GET,
  );

  const getInvoice = useApi<InvoiceData>(
    Endpoints.consult.getInvoice.replace(
      'PARAM_ID_FATURA',
      location?.state?.idFatura,
    ),
    ApiMethod.GET,
  );

  useEffect(() => {
    getInvoice.callApi().then((res) => setInvoiceData(res?.data));
  }, []);

  useEffect(() => {
    api.callApi();
  }, []);

  // useEffect(() => {
  //   if (chronometer === 0) {
  //     clearInterval(chronometerInterval);
  //     setChronometer(60);
  //   }
  // }, [chronometer, chronometerInterval]);

  const copyPixToClipBoard = (line) => {
    navigator.clipboard.writeText(line);
    setPopoverPixIsOpen(true);
    setInterval(() => setPopoverPixIsOpen(false), 10000);
  };

  const copyLineToClipBoard = (line) => {
    navigator.clipboard.writeText(line);
    setPopoverLineIsOpen(true);
    setInterval(() => setPopoverLineIsOpen(false), 10000);
  };

  const loadData = () => {
    const data = api.data as ConsultInvoiceUserDetailResponse;

    setInfos({
      nome: data.nome,
      celular: data.celular,
      diaVencimento: data.diaVencimento,
      email: data.email,
    });
  };

  useEffect(() => {
    if (api.data) {
      loadData();
    }
  }, [api.loading]);

  const sendInvoice = async () => {
    if (!emailToSendInvoice1 && !emailToSendInvoice2) {
      setErrorMessage('Selecione uma opção');
      return;
    }

    if (emailToSendInvoice2 && !emailInvoice) {
      setErrorMessage('Digite o outro email');
      return;
    }

    try {
      await apiSendInvoiceEmail.callApi();
      setDialogAskEmailShow(false);
      setDialogIsOpen(true);
      setDialogText('E-mail enviado com sucesso!');
    } catch (err) {
      setErrorMessage(err.data.mensagem);
    } finally {
      setEmailInvoice('');
      setGetEmailInvoice('');
    }
  };

  const checkEmailToSendInvoice = (type) => {
    if (type === 1) {
      setGetEmailInvoice(infos.email);
      setEmailToSendInvoice1(!emailToSendInvoice1);
      setEmailToSendInvoice2(false);
      return;
    }

    setGetEmailInvoice('');
    setEmailToSendInvoice1(false);
    setEmailToSendInvoice2(!emailToSendInvoice2);
  };

  const printInvoice = async () => {
    setDisablePrintButton(true);
    try {
      const response = await apiInvoice.callApi();

      const binary = atob(response.data.documentFatura.replace(/\s/g, ''));
      const buffer = new ArrayBuffer(binary.length);
      const view = new Uint8Array(buffer);

      for (let i = 0; i < binary.length; i += 1) {
        view[i] = binary.charCodeAt(i);
      }

      const file = new Blob([view], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, '_blank');
    } catch (e) {
      console.log(e);
      setDisablePrintButton(false);
    }

    setDialogPrintIsOpen(false);
    setDialogIsOpen(true);
    setDialogText('Impressão feita com sucesso!');
    setDisablePrintButton(false);
  };

  const onClickCloseAskEmailDialog = () => {
    setEmailInvoice('');
    setGetEmailInvoice('');
    setDialogAskEmailShow(false);
  };

  const getInvoicePaymentDetail = async () => {
    const response = await apiInvoicePaymentDetail.callApi();
    const { data } = response.data;

    setInvoicePaymentDetailData(data?.pagina);
    setTotalPagesInvoicePaymentDetail(data?.totalDePaginas);
  };

  useEffect(() => {
    if (currentPageInvoicePaymentDetail) getInvoicePaymentDetail();
  }, [currentPageInvoicePaymentDetail]);

  return (
    <>
      <Loading
        status={
          api.loading ||
          apiInvoicePaymentDetail.loading ||
          getInvoice.loading ||
          apiSendInvoiceEmail.loading
        }
      />

      <Dialog
        open={dialogAskEmailShow}
        positiveLabel="Enviar"
        positiveAction={sendInvoice}
        closeButton
        negativeAction={onClickCloseAskEmailDialog}
        message="Envio por e-mail"
      >
        <St.EmailContainer>
          {errorMessage ? (
            <St.ErrorMessage>{errorMessage}</St.ErrorMessage>
          ) : (
            ''
          )}
          <St.Hr />
          <St.InputRadioLabel>
            <St.InputRadioButton onClick={() => checkEmailToSendInvoice(1)}>
              {emailToSendInvoice1 ? (
                <MdRadioButtonChecked />
              ) : (
                <MdRadioButtonUnchecked />
              )}
            </St.InputRadioButton>
            {infos.email}
          </St.InputRadioLabel>
          <St.Hr />
          <St.InputRadioLabel>
            <St.InputRadioButton onClick={() => checkEmailToSendInvoice(2)}>
              {emailToSendInvoice2 ? (
                <MdRadioButtonChecked />
              ) : (
                <MdRadioButtonUnchecked />
              )}
            </St.InputRadioButton>
            Outro e-mail
          </St.InputRadioLabel>
          <St.InputEmailContainer>
            <Input
              label="E-mail"
              type="email"
              value={emailInvoice}
              onChange={(e) => {
                setEmailInvoice(e.target.value);
                setGetEmailInvoice(e.target.value);
              }}
            />
          </St.InputEmailContainer>
          <St.Hr />
        </St.EmailContainer>
      </Dialog>

      <Dialog
        icon={icons.confirmation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={dialogText}
      />

      <Dialog
        icon={icons.printer}
        open={dialogPrintIsOpen}
        negativeAction={() => setDialogPrintIsOpen(false)}
        negativeLabel="Não"
        positiveLabel="Sim"
        positiveAction={printInvoice}
        message="Você deseja imprimir este arquivo?"
        buttonDisabledPositive={disablePrintButton}
      />

      <Dialog
        icon={icons.exclamation}
        open={dialogInvoicePaymentDetail}
        title={<span style={{ color: 'red' }}> Detalhe do pagamento </span>}
        positiveLabel="Voltar"
        positiveAction={() => setDialogInvoicePaymentDetail(false)}
      >
        <St.TableHistory>
          <tr>
            <th>Valor total</th>
            <th>Valor mínimo</th>
            <th>Data do vencimento</th>
            <th>Data do pagamento</th>
            <th style={{ borderRight: '1px solid grey' }}>Valor pago</th>
          </tr>

          {invoicePaymentDetailData?.map((item) => (
            <tr key={item.id}>
              <td>
                {item.valorTotal
                  ? `R$ ${Masks.currencyMask(item.valorTotal)}`
                  : '-'}
              </td>
              <td>
                {item.valorMinimo
                  ? `R$ ${Masks.currencyMask(item.valorMinimo)}`
                  : '-'}
              </td>
              <td>
                {item.dataVencimento
                  ? moment(item.dataVencimento).format('DD/MM/YYYY')
                  : '-'}
              </td>
              <td>
                {item.dataPagamento
                  ? moment(item.dataPagamento).format('DD/MM/YYYY')
                  : '-'}
              </td>
              <td style={{ borderRight: '1px solid grey' }}>
                {item.valorPagamento
                  ? `R$ ${Masks.currencyMask(item.valorPagamento)}`
                  : '-'}
              </td>
            </tr>
          ))}
        </St.TableHistory>
        {totalPagesInvoicePaymentDetail > 1 && (
          <ContainerFlex row horizontal center marginTop={20}>
            <Pagination
              totalPages={totalPagesInvoicePaymentDetail}
              onCurrentPageChange={(e) => setCurrentPageInvoicePaymentDetail(e)}
            />
          </ContainerFlex>
        )}
      </Dialog>

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <ContainerFlex padding={26}>
              <TitleApp>Fatura</TitleApp>
            </ContainerFlex>

            <ContainerFlex color="#fbfbfb">
              <St.TitleCategory>1. Cliente</St.TitleCategory>
            </ContainerFlex>

            <ContainerFlex padding={26}>
              <Grid xs={1} md={2} gap={30}>
                <ContainerFlex>
                  <Input
                    label="Nome completo"
                    value={invoiceData?.nomeCliente}
                    readOnly
                  />

                  <Input
                    label="Número do cartão"
                    value={
                      Masks.creditCardMask(invoiceData?.numeroCartao).masked
                    }
                    placeholder="0000.0000.0000.0000"
                    mask={MaskTypes.CREDIT_CARD}
                    readOnly
                  />

                  <Input
                    label="CPF"
                    value={Masks.cpfMask(invoiceData?.cpf).masked}
                    placeholder="000.000.000-00"
                    mask={MaskTypes.CPF}
                    readOnly
                  />
                </ContainerFlex>

                <ContainerFlex>
                  <Input
                    label="Data de vencimento da fatura do cartão"
                    placeholder="DD/MM/YYYY"
                    value={moment(
                      invoiceData?.dataVencimentoFaturaCartao,
                      'DD/MM/YYYY',
                    ).format('DD')}
                    mask={MaskTypes.DATE}
                    readOnly
                  />

                  <Input
                    label="Telefone"
                    placeholder="(00) 00000-0000"
                    value={Masks.phoneMask(invoiceData?.telefone).masked}
                    mask={MaskTypes.PHONE}
                    readOnly
                  />
                </ContainerFlex>
              </Grid>
            </ContainerFlex>

            <ContainerFlex color="#fbfbfb">
              <St.TitleCategory>2. Fatura</St.TitleCategory>
            </ContainerFlex>

            <ContainerFlex padding={26}>
              <Grid xs={1} md={2} gap={30}>
                <ContainerFlex>
                  <Input
                    label="ID da fatura"
                    value={invoiceData?.idFatura}
                    readOnly
                  />
                  <Input
                    label="Data"
                    placeholder="DD/MM/YYYY"
                    value={moment(invoiceData?.dataVencimento).format(
                      'DD/MM/YYYY',
                    )}
                    mask={MaskTypes.DATE}
                    readOnly
                  />
                  <ContainerFlex row center marginTop={15}>
                    <Input
                      label="Linha digitável do boleto"
                      value={location.state?.linhaDigitavel}
                      readOnly
                    />

                    <Button
                      style={{ width: 25, height: 37, marginLeft: 10 }}
                      onClick={() => {
                        copyLineToClipBoard(location.state?.linhaDigitavel);
                        if (popoverLineIsOpen) setPopoverLineIsOpen(false);
                      }}
                      disabled={!location.state?.linhaDigitavel}
                    >
                      Copiar
                    </Button>
                    <St.WrapperPopover>
                      <Popover open={popoverLineIsOpen}>
                        <p>Copiado!</p>
                      </Popover>
                    </St.WrapperPopover>
                  </ContainerFlex>
                  <ContainerFlex row center marginTop={15}>
                    <Input
                      label="Chave pix do boleto"
                      value={invoiceData?.codigoPix}
                      readOnly
                    />
                    <Button
                      style={{ width: 25, height: 37, marginLeft: 10 }}
                      onClick={() => {
                        copyPixToClipBoard(invoiceData?.codigoPix);
                        if (popoverPixIsOpen) setPopoverPixIsOpen(false);
                      }}
                      disabled={!invoiceData?.codigoPix}
                    >
                      Copiar
                    </Button>
                    <St.WrapperPopover>
                      <Popover open={popoverPixIsOpen}>
                        <p>Copiado!</p>
                      </Popover>
                    </St.WrapperPopover>
                  </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex>
                  <Input label="Empresa" readOnly />
                  <ContainerFlex row center marginTop={-36}>
                    <Input
                      label="Status"
                      value={invoiceData?.status}
                      readOnly
                    />
                    <Button
                      style={{ width: 25, height: 37, marginLeft: 10 }}
                      onClick={() => setDialogInvoicePaymentDetail(true)}
                      disabled={!invoicePaymentDetailData?.length}
                    >
                      Detalhar
                    </Button>
                  </ContainerFlex>
                  <ContainerFlex center marginTop={-25}>
                    <Input
                      label="Descrição"
                      value={invoiceData?.statusDescricao}
                    />
                  </ContainerFlex>
                </ContainerFlex>
              </Grid>
            </ContainerFlex>

            <ContainerFlex padding={26}>
              <Grid xs={1} md={2} gap={30}>
                <Input
                  label="E-mail cadastrado para envio da fatura"
                  value={invoiceData?.email}
                  readOnly
                />
                <Input
                  label="Fatura digital enviada por e-mail?"
                  value={invoiceData?.statusEmail === 'OK' ? 'SIM' : 'NÃO'}
                  readOnly
                />
                <Input
                  label="Data do envio"
                  value={
                    invoiceData?.dataEnvioEmail
                      ? moment(invoiceData?.dataEnvioEmail).format('DD/MM/YYYY')
                      : ''
                  }
                  readOnly
                />
              </Grid>
            </ContainerFlex>

            <ContainerFlex padding={26}>
              <ContainerFlex
                color="#FAFAFA"
                row
                space
                style={{
                  padding: '0 25px',
                  border: '1px solid #E8E8E8',
                }}
              >
                <St.InvoiceCheckBoxTitle>Nome</St.InvoiceCheckBoxTitle>

                <St.ContainerTitleInvoiceButton>
                  <St.TitleInvoiceButton
                    onClick={() => setDialogAskEmailShow(true)}
                  >
                    Enviar no e-mail
                  </St.TitleInvoiceButton>
                  <St.TitleInvoiceButton
                    onClick={() => setDialogPrintIsOpen(true)}
                  >
                    Imprimir
                  </St.TitleInvoiceButton>
                </St.ContainerTitleInvoiceButton>
              </ContainerFlex>

              <ContainerFlex
                color="#fff"
                style={{
                  padding: '0 25px',
                  border: '1px solid #E8E8E8',
                }}
              >
                <St.InvoiceCheckBox>
                  <St.InvoiceCheckBoxTitle>
                    <FaRegFileAlt />
                    &nbsp;
                    {`Fatura_${location.state?.mesReferencia}_${location.state?.anoReferencia}.pdf`}
                  </St.InvoiceCheckBoxTitle>
                </St.InvoiceCheckBox>
              </ContainerFlex>

              <ContainerFlex row marginTop={24} end>
                <Button
                  marginLeft={16}
                  onClick={() =>
                    history.push('/invoice', {
                      allData: location.state.allData,
                      from: true,
                    })
                  }
                >
                  Voltar
                </Button>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default InvoiceDetail;
