import { useContext } from 'react';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';

import * as St from './styles';
import { EVENTS_TYPES } from '../models';
import { EventReportContext } from '../context/EventReportContext';
import { setFormState } from '~/utils/form';
import Input, { MaskTypes } from '~/components/input';
import Button from '~/components/button';
import { useReportEvent } from '../context/useReportEvent';

interface IFilterEvent {
  operador: any[];
  onClick?: (any: any) => void;
}

const FilterEvents: React.FC<IFilterEvent> = ({ operador, onClick }) => {
  const { form, setForm } = useReportEvent();

  const handleChange = (el): void => {
    onClick(el);
  };

  return (
    <St.BoxCard>
      <ContainerFlex row space>
        <ContainerFlex marginRight={16}>
          <Select
            options={EVENTS_TYPES}
            label="Tipo Evento"
            name={form.tipoEvento.name}
            value={form.tipoEvento.value}
            onChange={(e) => {
              setFormState(
                { target: { name: form.tipoEvento.name, value: e } },
                form,
                setForm,
              );
            }}
          />
        </ContainerFlex>
        <ContainerFlex marginRight={16}>
          <Select
            label="Operador(a)"
            options={[
              { id: 'TODOS', value: 'Todos' },
              { id: '6041102c1b705c0007d62e81', value: 'Web' },
              ...operador,
            ]}
            value={form.idOperador}
            onChange={(e) => setForm({ ...form, idOperador: e })}
          />
        </ContainerFlex>
        <ContainerFlex marginRight={16}>
          <Input
            label="Data inicial"
            placeholder="DD/MM/YYYY"
            maxLength={10}
            value={form?.dataInicial}
            onChange={(e) => {
              return setForm({
                ...form,
                dataInicial: e.target.value.masked,
              });
            }}
            mask={MaskTypes.DATE}
          />
        </ContainerFlex>
        <ContainerFlex marginRight={16}>
          <Input
            label="Data final"
            placeholder="DD/MM/YYYY"
            maxLength={10}
            value={form?.dataFinal}
            onChange={(e) => {
              return setForm({
                ...form,
                dataFinal: e.target.value.masked,
              });
            }}
            mask={MaskTypes.DATE}
          />
        </ContainerFlex>
      </ContainerFlex>
      <ContainerFlex flex="0.2" marginTop={24} maxWidth={14}>
        <Button
          fitContent
          onClick={() => {
            handleChange(form);
          }}
        >
          <span>Consultar</span>
        </Button>
      </ContainerFlex>
    </St.BoxCard>
  );
};

export default FilterEvents;
