import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Button from '../button';
import CardView from '../cartView';
import ContainerFlex from '../containerFlex';

import * as St from './styles';

export interface IDialog {
  open?: boolean;
  icon?: string;
  message?: string;
  oneLabel?: string;
  oneAction?: Function;
  twoLabel?: string;
  twoAction?: () => void;
  widthInPercent?: number;
  closeButton?: boolean;
  title?: string | JSX.Element;
  widthInPixel?: number;
  Loading?: boolean;
  closeAction?: () => void;
}

const ButtonsDialog: React.FC<IDialog> = ({
  children,
  icon,
  open,
  message,
  twoLabel,
  twoAction,
  oneLabel,
  oneAction,
  widthInPercent,
  widthInPixel,
  Loading,
  title,
  closeButton = false,
  closeAction,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) setIsOpened(true);
    else setIsOpened((prev) => (prev === true ? false : null));
  }, [open]);

  const one = () => {
    if (oneAction) oneAction();
    setIsOpened(false);
  };

  const two = () => {
    if (twoAction) twoAction();
    setIsOpened(false);
  };

  const close = () => {
    closeAction();
  };

  return (
    <St.Container show={isOpened}>
      <CardView space widthPixel={widthInPixel} width={widthInPercent}>
        <ContainerFlex center>
          {/* Header */}
          {icon && (
            <ContainerFlex>
              <ContainerFlex row align="center">
                <St.Icon src={icon} />
                <St.TitleHeader>{title}</St.TitleHeader>
                <St.CloseIcon onClick={close}>
                  <MdClose />
                </St.CloseIcon>
              </ContainerFlex>
            </ContainerFlex>
          )}

          {/* Content */}
          <ContainerFlex padding={16}>
            {message && (
              <St.Message alignCenter={closeButton}>{message}</St.Message>
            )}
            {children}
          </ContainerFlex>

          <>
            <St.DividerLine />

            {/* Buttons */}
            <ContainerFlex row spaceAround wrap padding={16}>
              {oneLabel && (
                <Button onClick={one} marginBottom={8}>
                  <span>{oneLabel}</span>
                </Button>
              )}
              {twoAction && (
                <Button
                  fitContent={!!oneLabel || widthInPercent != null}
                  fullWidth={!oneLabel}
                  onClick={two}
                  loading={Loading}
                >
                  <span>{twoLabel}</span>
                </Button>
              )}
            </ContainerFlex>
          </>
        </ContainerFlex>
      </CardView>
    </St.Container>
  );
};

export default ButtonsDialog;
