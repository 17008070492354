/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabHeader = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  justify-content: center;
`;

interface TabProps {
  active: boolean;
  disabled?: boolean;
}

export const TabButton = styled.div<TabProps>`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${({ active, disabled }) =>
    disabled ? '#aaa' : active ? '#e71037' : '#333'};
  border-bottom: ${({ active, disabled }) =>
    active && !disabled ? '2px solid #e71037' : '2px solid transparent'};
  transition: color 0.3s, border-bottom 0.3s;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ disabled }) => (disabled ? '#aaa' : '#e71037')};
  }
`;

export const TabContent = styled.div`
  padding: 20px;
  font-size: 14px;
`;
