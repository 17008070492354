import { useContext } from 'react';
import { InvoiceContext, IInvoiceReport } from './InvoiceContext';

export function useInvoiceReport(): IInvoiceReport {
  const context = useContext(InvoiceContext);

  if (!context) {
    throw new Error('useInvoiceReport must be used within an ReportContext');
  }

  return context;
}
