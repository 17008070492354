import styled from 'styled-components';

export interface IContainer {
  imageUrl: string;
}

export const Container = styled.div`
  width: 250px;
  margin-right: 10px;
`;

export const ImageDive = styled.div<IContainer>`
  display: flex;
  width: 250px;
  height: 120px;
  background-size: cover;
  background-image: url('${(p) => p.imageUrl}');
  flex-direction: column;
  box-shadow: 0 1.5px 5px #dcdcdc;
  :hover {
    cursor: pointer;
    opacity: 0.65;
  }
  :hover div {
    opacity: 1;
  }
`;

export const DiveFile = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  width: 280px;
  height: 70px;
  display: flex;
  flex-direction: column;
  margin-left: 160px;
  margin-top: 60px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const InputFile = styled.input`
  background-color: '#a52a2a';
  color: transparent;
  width: 100px;
  display: none;
  margin-left: 65px;
  font-size: 16px;
  :hover {
    cursor: pointer;
  }
`;

export const FileSpan = styled.span`
  color: black;
  width: 180px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Icon = styled.img`
  padding-bottom: 10px;
  width: 20px;
  height: 25px;
  font-weight: bold;
`;

export const SpanStatus = styled.span`
  font-size: 14px;
  margin: 5px;
`;

export const SpanDimensoes = styled.span`
  font-size: 15px;
`;
