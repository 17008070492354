export const FINANCIAL_TYPES = [
  {
    id: 'RELATORIO_IOF',
    name: 'Relatório IOF',
    value: 'Relatório IOF',
  },
  {
    id: 'RECEITAS_FINANCEIRAS_RECEBIDAS',
    name: 'Receitas Financeiras Recebidas',
    value: 'Receitas Financeiras Recebidas',
  },
  {
    id: 'RECEITAS_ACORDO',
    name: 'Receitas Acordo',
    value: 'Receitas Acordo',
  },
  {
    id: 'CASHBACK_ZEUSS',
    name: 'Cashback Zeuss',
    value: 'Cashback Zeuss',
  },
];

export interface IFinancialReport {
  postReportIOF: Function;
  postReportFinancialIncome: Function;
  postReportRecipeAgreement: Function;
  postReportCashBackZeuss: Function;
  checkSuccess: Function;
  loading: boolean;
  items: any;
  headerItems: any;
  header: any;
  totalPages: number;
  totalItems: number | null;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  clearForm: () => React.SetStateAction<any>;
  FINANCIAL_TYPES: Array<{ id: string; name: string; value: string }>;
}

export interface ReportIOFResponse {
  data: [
    {
      cpf?: string;
      iofCobrado?: number;
      nome?: string;
      pagamento?: string;
      prazo?: string;
      valorBase?: number;
    },
  ];
}

export interface ReportFinancialIncomeResponse {
  data: {
    limitePorPagina: number;
    pagina: {
      cartao: string;
      cpf: string;
      nome: string;
      pagamento: string;
      tipo: string;
      valor: number;
      vencimento: string;
    }[];
    paginaAtual: number;
    totalDePaginas: number;
    totalItens: number;
  };
}

export interface ReportRecipeAgreementResponse {
  limitePorPagina: number;
  pagina: {
    cpf: string;
    nome: string;
    resultado: number;
    valorDivida: number;
    valorPago: number;
    dataInclusao: string;
    dataDecisao: string;
  }[];
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}

export interface ReportCashBackZeussResponse {
  data: {
    celularZeuss?: string;
    cpf?: string;
    dataPagamento?: string;
    dataVencimentoFatura?: string;
    email?: string;
    nome?: string;
    valorCashBack?: number;
    valorCompras?: number;
  }[];
  paginaAtual: number;
  totalPaginas: number;
  totalItens: number;
}
