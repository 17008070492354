/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '../input';
import PictureCard from '~/components/pictureCard';
import { cpfMask, creditCardMask, emailMask, phoneMask } from '~/utils/masks';
import Select from '../select';
import { LeadContext, LeadDetail } from '~/contexts/leads';
import { icons } from '~/assets';
import Loading from '~/components/loading';
import * as St from './styles';
import Dialog from '~/components/dialog';
import { IProps } from '../../Interfaces/sharedInterfaces';

const Cf = ContainerFlex;

const ClientData: React.FC<IProps> = ({
  solicitation = false,
  cardInfos,
  availableProducts,
  loading,
  eventStart,
  onTypeProduct,
  onTypeEvent,
  product,
  event,
  typeFlux,
}) => {
  const { leads, addLeadData } = useContext(LeadContext);
  const { leadDetail } = leads;
  const [blockSelectEvent, setBlockSelectEvent] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [form, setForm] = useState<Partial<LeadDetail>>({
    produto: { id: 'BENEFICIO', value: 'Benefício' },
    tipoEvento: {
      id: '',
      value: '',
    },
  });

  useEffect(() => {
    setForm((prev) => ({ ...prev, ...leads?.leadDetail }));
  }, [leads]);

  useEffect(() => {
    if (form.produto.value === 'Benefício')
      setForm((prev) => ({
        ...prev,
        tipoEvento: { id: 'LEAD', value: 'Lead' },
      }));
  }, []);

  useEffect(() => {
    if (form) {
      if (product && event) {
        setForm((prev) => ({ ...prev, produto: product }));
        setForm((prev) => ({ ...prev, tipoEvento: event }));
      }
    }
  }, [product, event]);

  useEffect(() => {
    if (typeFlux === 'SOLICITATION' && form.produto?.id !== 'BENEFICIO') {
      if (
        cardInfos?.data[0]?.categoria === 'Platinum' &&
        form.produto.value === 'Cartão'
      ) {
        onTypeEvent({ id: 'DOWNGRADE', value: 'Downgrade' });
        setForm((prev) => ({
          ...prev,
          tipoEvento: { id: 'DOWNGRADE', value: 'Downgrade' },
        }));
      } else if (form.produto?.value === 'Conta') {
        onTypeEvent({ id: 'CANCELAMENTO', value: 'Cancelamento' });
        setForm((prev) => ({
          ...prev,
          tipoEvento: { id: 'CANCELAMENTO', value: 'Cancelamento' },
        }));
      } else {
        onTypeEvent({ id: 'UPGRADE', value: 'Upgrade' });
        setForm((prev) => ({
          ...prev,
          tipoEvento: { id: 'UPGRADE', value: 'Upgrade' },
        }));
      }
    }
  }, [cardInfos, form.produto]);

  useEffect(() => {
    if (typeFlux === 'STANDARD') onTypeEvent({ id: 'LEAD', value: 'Lead' });
  }, []);

  const calculateTimeAsClient = () => {
    let result = '';
    const inputDate: any = new Date(leads?.leadDetail?.dataCadastro);

    const currentDate: any = new Date();

    const timeDifferenceMs = currentDate - inputDate;

    const years = Math.floor(timeDifferenceMs / (365 * 24 * 60 * 60 * 1000));
    const months = Math.floor(
      (timeDifferenceMs % (365 * 24 * 60 * 60 * 1000)) /
        (30 * 24 * 60 * 60 * 1000),
    );
    const days = Math.floor(
      (timeDifferenceMs % (30 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000),
    );

    if (years <= 0 && months <= 0 && days > 0) {
      result = `${days} dias`;
    } else if (years <= 0 && months > 0 && days <= 0) {
      result = `${months} meses`;
    } else if (years <= 0 && months > 0 && days > 0) {
      result = `${months} meses e ${days} dias`;
    } else if (years > 0 && months <= 0 && days <= 0) {
      result = `${years} anos`;
    } else if (years > 0 && months <= 0 && days > 0) {
      result = `${years} anos e ${days} dias`;
    } else if (years > 0 && months > 0 && days <= 0) {
      result = `${years} anos e ${months} meses`;
    } else if (years > 0 && months > 0 && days > 0) {
      result = `${years} anos, ${months} meses e ${days} dias`;
    }

    return result;
  };

  const handleEventType = () => {
    let values = { types: [{ id: '', value: '' }] };

    if (form.produto.id === 'CARTAO') {
      values = {
        types: [
          { id: 'UPGRADE', value: 'Upgrade' },
          { id: 'DOWNGRADE', value: 'Downgrade' },
        ],
      };
    } else if (form.produto.id === 'BENEFICIO') {
      values = { types: [{ id: 'LEAD', value: 'Lead' }] };
    } else if (form.produto.id === 'CONTA') {
      values = { types: [{ id: 'CANCELAMENTO', value: 'Cancelamento' }] };
    }

    return values?.types;
  };

  return (
    <Cf>
      <Dialog
        open={blockSelectEvent}
        title="Falha ao selecionar evento!"
        positiveAction={() => {
          setBlockSelectEvent(false);
        }}
        message={
          isUpgrade
            ? 'Este cliente já está no nível Platinum!'
            : 'Este cliente já está no nível Padrão!'
        }
        positiveLabel="Entendi"
        icon={icons.warning}
        widthInPercent={50}
      />
      <Loading status={loading} />
      <Cf gap="20" row>
        <Cf maxWidth={200}>
          <Cf center>
            <PictureCard
              width={200}
              height={200}
              showOptions
              src={
                leads?.photo
                  ? `data:image/jpeg;base64, ${leads?.photo}`
                  : icons.userAvatar
              }
            />
          </Cf>
          {solicitation && availableProducts && (
            <Select
              label="Tipo de produto"
              options={availableProducts?.map((e) => ({
                value: e.nome,
                id: e.id,
              }))}
              value={form.produto.value} // Use o primeiro nome como valor inicial
              onChange={(e) => {
                addLeadData('produto', { e, ...leadDetail });
                if (e.value === 'Benefício') {
                  setForm((prev) => ({
                    ...prev,
                    produto: { id: 'BENEFICIO', value: e.value },
                    tipoEvento: { id: 'LEAD', value: 'Lead' },
                  }));
                  onTypeProduct({ id: 'BENEFICIO', value: e.value });
                  onTypeEvent({ id: 'LEAD', value: 'Lead' });
                }
                if (e.value === 'Cartão') {
                  setForm((prev) => ({
                    ...prev,
                    produto: { id: 'CARTAO', value: e.value },
                  }));
                  onTypeProduct({ id: 'CARTAO', value: e.value });
                }
                // setErrors((prev) => ({ ...prev, produto: '' }));
                if (e.value === 'Conta') {
                  setForm((prev) => ({
                    ...prev,
                    produto: { id: 'CONTA', value: e.value },
                    tipoEvento: { id: 'CANCELAMENTO', value: 'Cancelamento' },
                  }));
                  onTypeProduct({ id: 'CONTA', value: e.value });
                  onTypeEvent({ id: 'CANCELAMENTO', value: 'Cancelamento' });
                }
              }}
              typeSelect="EVENTS_PRODUCT"
              disabled={eventStart}
              mediumSize
            />
          )}
          <Select
            label="Tipo de evento"
            options={handleEventType()}
            value={form.tipoEvento.value} // Use o primeiro nome como valor inicial
            onChange={(e) => {
              if (
                cardInfos.data[0].categoria === 'Platinum' &&
                e.id === 'UPGRADE'
              ) {
                setBlockSelectEvent(true);
                setIsUpgrade(true);
              } else if (
                cardInfos.data[0].categoria === 'Padrão' &&
                e.id === 'DOWNGRADE'
              ) {
                setBlockSelectEvent(true);
              } else {
                addLeadData('tipoEvento', { e, ...leadDetail });
                setForm((prev) => ({ ...prev, tipoEvento: e }));
                onTypeEvent(e);
              }
              // setErrors((prev) => ({ ...prev, produto: '' }));
            }}
            typeSelect="EVENTS_TYPE"
            disabled={eventStart}
            mediumSize
          />
          <St.PhoneDiv>
            <Input
              label="Telefone"
              name="telefone"
              readOnly
              mask={MaskTypes.PHONE}
              value={phoneMask(form?.celular).masked}
              disabled
            />
            {form?.celular2 && (
              <Input
                label="Telefone 2"
                name="telefone2"
                readOnly
                mask={MaskTypes.PHONE}
                value={phoneMask(form?.celular2).masked}
                disabled
              />
            )}
          </St.PhoneDiv>
        </Cf>
        <Cf maxWidth={200}>
          <Input
            label="Nome Completo"
            name="nome"
            readOnly
            value={form?.cliente}
            disabled
          />
          <Input
            label="CPF"
            name="cpf"
            readOnly
            mask={MaskTypes.CPF}
            placeholder="000.000.000-00"
            value={cpfMask(form?.cpf).masked}
            disabled
          />
          <ContainerFlex row align="center" style={{ gap: 40 }}>
            <Input
              labelStyle={{ fontWeight: 'bold' }}
              style={{ fontWeight: 'bold' }}
              label="Status do cartão"
              name="statusCartao"
              readOnly
              value={form?.statusCartao}
              disabled
            />

            <Input
              label="Categoria e Tema"
              value={`${cardInfos?.data[0]?.categoria?.toString() || ''} - ${
                cardInfos?.data[0]?.modeloTema?.toString() || ''
              }`}
              readOnly
              disabled
            />
          </ContainerFlex>
          <Cf row gap="20">
            <Cf row center>
              <Input
                label="Classificação"
                name="class"
                readOnly
                value={form?.nivelAtual ?? 'Sem classificação'}
                disabled
              />
              <img
                style={{
                  paddingLeft: '8px',
                  position: 'absolute',
                  right: '50%',
                  top: 25,
                }}
                alt=""
                height={20}
                src={
                  form?.nivelAtual === 'BRONZE'
                    ? icons.bronzeMedal
                    : form?.nivelAtual === 'PRATA'
                    ? icons.silverMedal
                    : form?.nivelAtual === 'OURO'
                    ? icons.goldMedal
                    : null
                }
              />
            </Cf>
            <Cf row gap="20">
              <Input
                label="Número do cartão"
                name="nrCartao"
                readOnly
                mask={MaskTypes.CREDIT_CARD}
                value={creditCardMask(form?.numeroCartaoAtual).masked}
                disabled
              />
            </Cf>
          </Cf>
          <Cf row gap="20">
            <Cf row center>
              <Input
                label="Cliente desde"
                name="dataCadastro"
                readOnly
                mask={MaskTypes.DATE}
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={
                  form?.dataCadastro
                    ? new Date(form?.dataCadastro)
                        .toISOString()
                        .split('T')[0]
                        .split('-')
                        .reverse()
                        .join('/')
                    : null
                }
                disabled
              />
            </Cf>
            <Cf row gap="20">
              <Cf center row>
                <Input
                  label="Tempo"
                  name="tempoCadastro"
                  readOnly
                  value={calculateTimeAsClient()}
                  disabled
                />
              </Cf>
            </Cf>
          </Cf>
          <St.FadeAndSlideContainer active>
            <Cf>
              <Input
                label="E-mail"
                name="email"
                readOnly
                mask={MaskTypes.EMAIL}
                value={emailMask(form?.email)}
                disabled
              />
            </Cf>
          </St.FadeAndSlideContainer>
        </Cf>
      </Cf>
    </Cf>
  );
};

export default ClientData;
