import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;

  padding: 0 10px;

  @media (max-width: 550px) {
    flex-flow: column nowrap;
  }
`;

export const Chat = styled.div`
  display: flex;

  padding: 0 20px;
  width: min(500px, 100%);
  flex-flow: column nowrap;
  height: 60vh;

  flex: 1;
  h4 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
  }
`;

export const Messages = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding: 0 20px;

  > div {
    margin-bottom: 25px;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #333;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 10px;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  width: 256px;
  height: 100%;

  border-left: 1px solid #efefef;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-flow: column;
  font-size: 1rem !important;
  color: #4a4b4a;

  padding: 20px 25px;

  word-wrap: break-word;

  @media (max-width: 550px) {
    flex-flow: row wrap;
  }

  h5 {
    margin: 0 0 10px 0;
    font-size: 1rem !important;
    font-weight: 600;
  }

  span {
    margin-bottom: 20px;
  }
`;

export const ContainerButtons = styled.div`
  padding: 20px 25px;
  display: flex;
  flex-flow: column;

  @media (max-width: 550px) {
    flex-flow: row wrap;
    justify-content: space-between;
    button {
      width: 100px;
      display: block;
    }
  }

  > button {
    margin-bottom: 10px;
  }
`;

export const ReceiveContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;

  img {
    margin-right: 10px;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  width: min(500px, 100%);

  padding: 10px 0;
  justify-content: center;
  align-items: center;

  input {
    margin-right: 15px;
  }
`;

export const Footer = styled.footer`
  border-top: 2px solid #efefef;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const WrapperChat = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`;
