/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';

import useApi, { ApiMethod } from '~/hooks/useApi';

import Endpoints from '~/services/endpoints';

import * as St from '../styles';
import { IDefaultReturn, cpfMask, emailMask, phoneMask } from '~/utils/masks';
import { emailValidator } from '~/utils/validators';
import RadioButton from '~/components/radioButton';
import Dialog from '~/components/dialog';

interface Form {
  cpf: IDefaultReturn;
  email: string;
  nome: string;
  celular: IDefaultReturn;
  funcionarioNovaEra: boolean;
  consulted: boolean;
}

interface ValidateAnfitriaoResponse {
  perfil: 'EXTERNO' | 'FUNCIONARIO';
  cpf: string;
  email: string;
  nome: string;
  celular: string;
}
const IndicationLinkCreate = (): React.ReactNode => {
  const history = useHistory();
  const [disabledInputs, setDisabledInputs] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const [form, setForm] = useState<Form>({
    cpf: cpfMask(),
    nome: '',
    email: emailMask(''),
    celular: phoneMask(''),
    funcionarioNovaEra: null,
    consulted: false,
  });

  const GetValidateAnfitriaoCpf = useApi<ValidateAnfitriaoResponse>(
    Endpoints.consult.getValidateAnfitriaoCpf.replace(
      'PARAM_CPF',
      form?.cpf.unmasked,
    ),
    ApiMethod.GET,
  );
  const Cf = ContainerFlex;

  const validateCpf = async () => {
    const res = await GetValidateAnfitriaoCpf.callApi();
    const { data, status } = res;
    if (status === 404) {
      setForm({
        cpf: cpfMask(),
        nome: '',
        email: emailMask(''),
        celular: phoneMask(''),
        funcionarioNovaEra: null,
        consulted: false,
      });
      return;
    }
    if (res.data?.perfil === 'EXTERNO') {
      setDisabledInputs(false);
      setForm((prev) => ({
        ...prev,
        celular: phoneMask(''),
        email: '',
        nome: '',
        funcionarioNovaEra: null,
        consulted: true,
      }));
      return;
    }
    setForm({
      celular: phoneMask(data.celular),
      cpf: cpfMask(data.cpf),
      email: data.email,
      nome: data.nome,
      funcionarioNovaEra: null,
      consulted: true,
    });
    setDisabledInputs(true);
  };
  console.log(form);
  console.log(emailValidator(form.email));
  const isValidForm = () => {
    const { celular, cpf, email, nome } = form;
    return (
      celular.unmasked.length !== 11 ||
      cpf.unmasked.length !== 11 ||
      !!emailValidator(form.email)?.email ||
      !form.nome ||
      (GetValidateAnfitriaoCpf?.data?.perfil !== 'FUNCIONARIO' &&
        form.funcionarioNovaEra === null)
    );
  };

  useEffect(() => {
    if (form.cpf.unmasked?.length === 11 && !form.consulted) {
      validateCpf();
    }
  }, [form.cpf, form.consulted]);
  const PostAnfitriao = useApi(
    Endpoints.consult.postCreateAnfitriao,
    ApiMethod.POST,
  );
  const handleSubmit = async () => {
    const response = await PostAnfitriao.callApi({
      celular: form.celular.unmasked,
      cpf: form.cpf.unmasked,
      email: form.email,
      nome: form.nome?.toUpperCase(),
      novaEra:
        GetValidateAnfitriaoCpf?.data?.perfil === 'FUNCIONARIO'
          ? false
          : form.funcionarioNovaEra,
      perfil: GetValidateAnfitriaoCpf?.data?.perfil,
    });
    if (response.status === 201) {
      setModalOpen(true);
      setModalMessage('Anfitrião cadastrado com sucesso!');
    } else {
      setModalOpen(true);
      setModalMessage('Houve um erro ao cadastrar anfitrião');
    }
  };
  return (
    <>
      <Container>
        <Loading status={GetValidateAnfitriaoCpf.loading} />
        <Dialog
          open={modalOpen}
          message={modalMessage}
          positiveAction={() => history.push('/sales/indicationLink')}
          positiveLabel="Ok"
        />
        <St.BoxCard>
          <Cf space>
            <Cf row space>
              <Cf padding={16}>
                <Input
                  label="CPF"
                  name="cpf"
                  value={form.cpf.masked}
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      cpf: cpfMask(e.target.value.unmasked),
                      consulted: false,
                    }));
                  }}
                  placeholder="000.000.000-00"
                  autoComplete="none"
                  maxLength={14}
                  mask={MaskTypes.CPF}
                  errorMessage={GetValidateAnfitriaoCpf.mensagem}
                />
              </Cf>
              <Cf padding={16}>
                <Input
                  label="Nome"
                  name="Nome"
                  value={form.nome?.toUpperCase()}
                  onChange={(e) =>
                    setForm((prev) => ({ ...prev, nome: e.target.value }))
                  }
                  disabled={disabledInputs}
                />
              </Cf>
            </Cf>

            <Cf row space>
              <Cf padding={16}>
                <Input
                  label="E-mail"
                  name="email"
                  value={form.email}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  placeholder="exemplo@exemplo.com"
                  mask={MaskTypes.EMAIL}
                  disabled={disabledInputs}
                  errorMessage={
                    !!emailValidator(form.email)?.email &&
                    form.email !== '' &&
                    'E-mail inválido.'
                  }
                />
              </Cf>

              <Cf padding={16}>
                <Input
                  label="Celular"
                  name="celular"
                  value={form.celular.masked}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      celular: phoneMask(e.target.value.unmasked),
                    }))
                  }
                  placeholder="(99) 99999-9999"
                  maxLength={15}
                  mask={MaskTypes.PHONE}
                  disabled={disabledInputs}
                />
              </Cf>
            </Cf>
            <Cf row space>
              {GetValidateAnfitriaoCpf?.data?.perfil !== 'FUNCIONARIO' ? (
                <>
                  <Cf padding={16}>
                    <span>Funcionário nova era?</span>
                  </Cf>
                  <Cf row end padding={16}>
                    <Cf row horizontal align="flex-end">
                      <RadioButton
                        id="usuarioNovaEra"
                        onClick={() =>
                          setForm((prev) => ({
                            ...prev,
                            funcionarioNovaEra: true,
                          }))
                        }
                        checked={form.funcionarioNovaEra === true}
                      />
                      Sim
                    </Cf>
                    <Cf row horizontal align="flex-end">
                      <RadioButton
                        id="usuarioNovaEra"
                        onClick={() =>
                          setForm((prev) => ({
                            ...prev,
                            funcionarioNovaEra: false,
                          }))
                        }
                        checked={form.funcionarioNovaEra === false}
                      />
                      Não
                    </Cf>
                  </Cf>
                </>
              ) : null}

              <Cf
                gap="20"
                end
                row
                justifyContent="flex-end"
                align="flex-end"
                style={{ padding: '0px 20px' }}
                flex={1.5}
              >
                <Button
                  fitContent
                  onClick={() => handleSubmit()}
                  disabled={isValidForm() || PostAnfitriao.loading}
                  loading={PostAnfitriao.loading}
                >
                  <span>Gravar</span>
                </Button>
              </Cf>
            </Cf>
          </Cf>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default IndicationLinkCreate;
