import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import axios from 'axios';
import AppProvider from './contexts';
import GlobalStyles from '~/themes/global';
import Themes from '~/themes/themes';

import Routes from './routes';
import Constants from './utils/constants';

function App(): JSX.Element {
  useEffect(() => {
    const getData = async () => {
      const res = await axios.get('https://api.ipify.org/?format=json');
      localStorage.setItem(Constants.IP_ADRESS, res.data.ip);
    };
    getData();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={Themes}>
        <GlobalStyles />
        <AppProvider>
          <Routes />
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
