import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { icons } from '~/assets';
import * as St from './styles';

export interface IDrop {
  onFileUploaded: (file: File) => void;
  clearFileStatus?: boolean;
}

const LocalDropzone: React.FC<IDrop> = ({
  onFileUploaded,
  clearFileStatus = false,
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileUrl = URL.createObjectURL(file);
      setSelectedFileUrl(fileUrl);
      setSelectedFileName(file.name);
      onFileUploaded(file);
    },
    [onFileUploaded],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  useEffect(() => {
    if (clearFileStatus) {
      onFileUploaded(null);
      setSelectedFileUrl('');
    }
  }, [clearFileStatus]);

  return (
    <St.Container {...getRootProps()}>
      <St.DropInput {...getInputProps()} accept="image/*" />
      {clearFileStatus}
      {selectedFileUrl ? (
        <St.PreLoadImage src={selectedFileUrl} alt={selectedFileName} />
      ) : (
        <St.DropSpan>
          <St.Icon src={icons.uploadFileIcon} />
          Faça upload
        </St.DropSpan>
      )}
    </St.Container>
  );
};

export default LocalDropzone;
