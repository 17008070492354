/* eslint-disable camelcase */

export const LOAN_TYPES = [
  // {
  //   id: 'VENDAS_EMPRESTIMOS',
  //   name: 'Empréstimo cartão de credito',
  //   value: 'Empréstimo cartão de credito',
  // },
  {
    id: 'VENDAS_SAQUE',
    name: 'Empréstimo TeuCard saque a vista',
    value: 'Empréstimo TeuCard saque a vista',
  },
  {
    id: 'PROPOSTAS_BUSCADOR',
    name: 'Propostas do Buscador',
    value: 'Propostas do Buscador',
  },
  {
    id: 'FATURAMENTO_SAQUE',
    name: 'Faturamento saque á vista',
    value: 'Faturamento saque á vista',
  },
  {
    id: 'RECEITA_INADIMPLENCIA',
    name: 'Receita inad. saque a vista',
    value: 'Receita inad. saque a vista',
  },
];

export const STATUS_WITHDRAW = [
  {
    id: 'TODOS',
    name: 'Todos',
    value: 'Todos',
  },
  {
    id: 'PENDENTE PAGAMENTO',
    name: 'Pendente Pagamento',
    value: 'Pendente Pagamento',
  },
  {
    id: 'PENDENTE ASSINATURA',
    name: 'Pendente Assinatura',
    value: 'Pendente Assinatura',
  },
  { id: 'CANCELADO', name: 'Cancelado', value: 'Cancelado' },
  {
    id: 'PENDENTE CONTRATO',
    name: 'Pendente Contrato',
    value: 'Pendente Contrato',
  },
  { id: 'DESEMBOLSADO', name: 'Desembolsado', value: 'Desembolsado' },
];

export const STATUS_LOAN_SEEKER = [
  {
    id: 'TODOS',
    name: 'Todos',
    value: 'Todos',
  },
  {
    id: 'PRE_PROCESSAMENTO',
    name: 'Pré-processamento',
    value: 'Pre-processamento',
  },
  {
    id: 'AGUARDANDO_DOCUMENTOS',
    name: 'Aguardando Documentos',
    value: 'Aguardando Documentos',
  },
  {
    id: 'PENDENTE',
    name: 'Pendente',
    value: 'Pendente',
  },
  {
    id: 'PRE_APROVADO',
    name: 'Pré-aprovado',
    value: 'Pré-aprovado',
  },
  {
    id: 'ANALISE',
    name: 'Análise',
    value: 'Análise',
  },
  { id: 'EXPIRADO', name: 'Expirado', value: 'Expirado' },
  { id: 'LIBERADO', name: 'Liberado', value: 'Liberado' },
  { id: 'REPROVADO', name: 'Reprovado', value: 'Reprovado' },
];

export const PERIOD_WITHDRAW_BILLING = [
  {
    id: 'DESEMBOLSO',
    name: 'Desembolso',
    value: 'Desembolso',
  },
  { id: 'VENCIMENTO', name: 'Vencimiento', value: 'Vencimento' },
  { id: 'PAGAMENTO', name: 'Pagamento', value: 'Pagamento' },
];

export interface FormState {
  tipoEmprestimo: { name: string; value: string };
  dataSimulacaoInicial: string;
  dataSimulacaoFinal: string;
  tipoPeriodo: { name: string; value: string };
  status: { name: string; value: string };
  idOperador: string;
  mes: { name: string; value: string };
  ano: { name: string; value: string };
}
export interface ILoanReport {
  getReportLeadsSales: Function;
  getReportWithDraw: Function;
  getReportLoanSeeker: Function;
  getReportWithdrawBilling: Function;
  getReportIncomeDefaultWithdraw: Function;
  checkSuccess: Function;
  getOperador: Function;
  loading: boolean;
  items: any;
  headerItems: any;
  header: any;
  totalPages: number;
  totalItems: number | null;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  operador: any[];
  clearForm: () => React.SetStateAction<any>;
  LOAN_TYPES: Array<{ id: string; name: string; value: string }>;
  form: FormState;
  setForm: React.Dispatch<React.SetStateAction<FormState>>;
}

export interface ReportResponseLeadsSales {
  limitePorPagina: number;
  pagina: {
    celular: string;
    cliente: string;
    cpf: string;
    dataContratacao: string;
    dataPagamento: string;
    email: string;
    endereco: {
      bairro: string;
      cep: string;
      cidade: string;
      complemento: string;
      logradouro: string;
      numero: string;
      pais: string;
      uf: string;
    };
    numeroParcelas: string;
    operador: {
      nome: string;
    };
    status: string;
    statusNovoSaque: string;
    taxajuros: number;
    valorParcela: number;
    valorLiberado: number;
    valorTransacao: number;
    statusNovoSaque_i18n: string;
  }[];
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}

export interface ReportResponseWithDraw {
  limitePorPagina: number;
  pagina: {
    cliente: {
      celular: string;
      cpf: string;
      email: string;
      endereco: {
        uf: string;
      };
      nome: string;
    };
    dataEnvio: string;
    dataPagamento: string;
    dataPrimeiroPagamento: string;
    id: string;
    motivoCancelamentoCelcoin: string;
    operador: {
      nome: string;
    };
    statusCelcoin: string;
    tacAmount: number;
    taxaJuros: number;
    valorIOF: number;
    valorLiberado: number;
    valorParcela: number;
    descricaoStatusEmprestimo: string;
  }[];
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}

export interface ReportResponseLoanSeeker {
  celular: string;
  cpf: string;
  dataCriacao: string;
  email: string;
  idEasyCredito: string;
  idEmprestimo: string;
  nome: string;
  operador: string;
  produto: string;
  qtdParcela: number;
  status: string;
  taxaMensal: number;
  valor: number;
}

export interface ReportDefaultWithdrawReceiptsResponse {
  base64: string;
  filename: string;
}
export interface ReportResponseWithdrawBilling {
  limitePorPagina: number;
  pagina: {
    cliente: {
      celular: string;
      cpf: string;
      email: string;
      nome: string;
    }[];
    dataPagamento: string;
    dataPagamentoFatura: string;
    dataPrimeiroPagamento: string;
    tacAmount: number;
    valorIOF: number;
    valorJuros: number;
    valorLiberado: number;
  };
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}

export interface IPayloadReport {
  dataSimulacaoFinal: string;
  dataSimulacaoInicial: string;
  idOperador: string;
}
