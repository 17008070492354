/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';

import { cpfMask, creditCardMask } from '~/utils/masks';

import * as St from './styles';
import TableList from '~/components/tableList';
import Container from '~/components/container';

const AntecipationHistory: React.FC = () => {
  const history = useHistory<any>();
  const locationState = history.location.state;

  const historyHeader = [
    {
      id: 1,
      value: 'Status',
      maxChar: 20,
    },
    {
      id: 2,
      value: 'Parcelas antecipadas',
      maxChar: 20,
    },
    {
      id: 3,
      value: 'Valor antecipado',
      maxChar: 20,
    },
    {
      id: 4,
      value: 'Data do pagamento',
      maxChar: 20,
    },
  ];

  const handleHistory = () => {
    history.push('/invoice/antecipation/purchase', {
      cpf: cpfMask(locationState.dataClient?.cpf).masked,
    });
  };

  return (
    <>
      <Container>
        <ContainerFlex padding={16}>
          <ContainerFlex row space padding={22} wrap>
            <ContainerFlex row padding={22} style={{ flex: 1 }}>
              <div style={{ margin: '0 42px 0 0' }}>
                <h4>Nome do cliente</h4>
                <p>{locationState.dataClient?.nomeCliente}</p>
              </div>
              <div style={{ margin: '0 42px 0 0' }}>
                <h4>CPF</h4>
                <p>{cpfMask(locationState.dataClient?.cpf).masked}</p>
              </div>
              <div>
                <h4>Número do cartão</h4>
                <p>{creditCardMask(locationState.dataClient?.cartao).masked}</p>
              </div>
            </ContainerFlex>

            <ContainerFlex row center padding={30} style={{ flex: 0 }}>
              <Button fitContent onClick={handleHistory}>
                <span>Voltar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex padding={16}>
            <St.MainHeader>Histórico de antecipação de compra</St.MainHeader>
            <TableList
              customerHeader={historyHeader}
              list={locationState.data}
            />
          </ContainerFlex>
        </ContainerFlex>
      </Container>
    </>
  );
};

export default AntecipationHistory;
