/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Layout from '~/components/layout';
import TitleApp from '~/components/title';
import Grid from '~/components/grid';
import Input from '~/components/input';

import * as St from './styles';
import CardView from '~/components/cartView';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import { IProposal } from '~/models/proposal';
import {
  cepMask,
  cpfMask,
  currencyMask,
  dateMask,
  phoneMask,
} from '~/utils/masks';
import ProposalReason from '~/models/proposal-reason';
import Dialog from '~/components/dialog';
import ReasonDialogItem from '../../Proposal/Detail/components/ReasonDialogItem';
import { ItemDialogReasonDivider } from '../../Proposal/Detail/components/DialogReasonComponents';

interface LocationState {
  idProposta?: string;
  proposalIni?: string;
  proposalEnd?: string;
  status?: string;
}

const DetailReportCT = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const [detail, setDetail] = useState<IProposal>(null);
  const [dialogReasonShow, setDialogReasonShow] = useState(false);
  const [reasons, setReasons] = useState<ProposalReason[]>([]);
  const [diaVenc, setDiaVenc] = useState('');

  const apiDetail = useApi<IProposal>(
    Endpoints.consult.searchProposalSPC.replace(
      'PARAM_ID_PROPOSTA',
      location.state?.idProposta,
    ),
    ApiMethod.GET,
  );

  useEffect(() => {
    apiDetail.callApi();
  }, []);

  const goBack = () => {
    history.push('/credit-table/report', {
      proposalIni: location.state?.proposalIni,
      proposalEnd: location.state?.proposalEnd,
      status: location.state?.status,
    });
  };

  if (apiDetail.success && !detail) {
    setDetail(apiDetail.data);
    setReasons(
      apiDetail.data?.proposta?.motivos
        ? apiDetail.data?.proposta?.motivos.reverse()
        : [],
    );
    setDiaVenc(apiDetail.data?.proposta?.diaVencimento);
  }

  const getGenreDescription = (value) => {
    switch (value) {
      case 'M':
        return 'Masculino';
      default:
        return 'Feminino';
    }
  };

  return (
    <>
      <Loading status={apiDetail.loading} />

      <Dialog
        open={dialogReasonShow}
        positiveLabel="Fechar"
        widthInPercent={60}
        positiveAction={() => setDialogReasonShow(false)}
      >
        {reasons && (
          <ContainerFlex center>
            <h2>Motivos</h2>
            <br />
            {reasons.reverse().map((item: ProposalReason) => (
              <ContainerFlex row marginBottom={18}>
                <div>
                  <strong>CPF: </strong>
                  {cpfMask(item.cpf).masked.trim()}
                </div>

                <ItemDialogReasonDivider />

                <div>
                  <strong>STATUS: </strong>
                  {item.status?.trim()}
                </div>

                <ItemDialogReasonDivider />

                <div>
                  <strong>DATA: </strong>
                  {String(
                    moment(item.dataInclusao).format('DD/MM/YYYY HH:mm'),
                  ).trim()}
                </div>

                <ItemDialogReasonDivider />

                <div>
                  <strong>MOTIVO: </strong>
                  {item.motivoStatus?.trim()}
                </div>
              </ContainerFlex>
            ))}
          </ContainerFlex>
        )}
      </Dialog>

      <Layout>
        <ContainerFlex>
          <ContainerFlex padding={26}>
            <TitleApp>Relatório</TitleApp>
          </ContainerFlex>

          <ContainerFlex color="#fbfbfb">
            <St.TitleCategory>1. Dados do Relatório</St.TitleCategory>
          </ContainerFlex>

          <ContainerFlex padding={26}>
            <Grid xs={1} lg={2} gap={30}>
              <ContainerFlex>
                <Input
                  label="CPF"
                  value={cpfMask(detail?.proposta?.cpf).masked}
                />
                <Input label="Nome completo" value={detail?.proposta?.nome} />
                <Input
                  label="Telefone"
                  value={phoneMask(detail?.proposta?.celular).masked}
                />
                <Input
                  label="Data de nascimento"
                  value={dateMask(detail?.proposta?.dataNascimento).masked}
                />
                <Input
                  label="Sexo"
                  value={getGenreDescription(detail?.proposta?.cliente.genero)}
                />
                <Input
                  label="Informação profissional"
                  value={detail?.proposta?.profissao}
                />
                <Input label="E-mail" value={detail?.proposta?.cliente.email} />
                <Input label="Status" value={detail?.status} />
                <Input
                  label="Login do atendente"
                  value={detail?.proposta?.atendente?.nomeOperador}
                />
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="CEP"
                  value={cepMask(detail?.proposta?.endereco?.cep).masked}
                />
                <Input
                  label="Endereço"
                  value={detail?.proposta?.endereco?.logradouro}
                />

                <div>
                  <Grid xs={1} lg={2} gap={10}>
                    <ContainerFlex marginRight={16}>
                      <Input
                        label="Número"
                        value={detail?.proposta?.endereco?.numero}
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Complemento"
                        value={detail?.proposta?.endereco?.complemento}
                      />
                    </ContainerFlex>
                  </Grid>

                  <Grid xs={1} md={2} lg={2} gap={10}>
                    <ContainerFlex>
                      <Input
                        label="Cidade"
                        value={detail?.proposta?.endereco?.cidade}
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Bairro"
                        value={detail?.proposta?.endereco?.bairro}
                      />
                    </ContainerFlex>
                  </Grid>
                </div>

                <Input
                  label="Renda estimada"
                  value={currencyMask(detail?.proposta?.rendaInformada)}
                />
                {/* <Input label="Número do cartão" value="" /> */}
                <Input label="Data vencimento da fatura" value={diaVenc} />

                <div>
                  <Grid xs={1} md={2} lg={2} gap={10}>
                    <ContainerFlex>
                      <Input
                        label="Data"
                        value={String(
                          moment(detail?.proposta?.dataAnalise).format(
                            'DD/MM/YYYY',
                          ),
                        )}
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Horário"
                        value={String(
                          moment(detail?.proposta?.dataAnalise).format('HH:ss'),
                        )}
                      />
                    </ContainerFlex>
                  </Grid>
                </div>
              </ContainerFlex>
            </Grid>
          </ContainerFlex>

          {reasons && reasons.length > 0 && (
            <ContainerFlex row center color="#fbfbfb">
              <St.TitleCategory>2. Comentários</St.TitleCategory>
            </ContainerFlex>
          )}

          {reasons && reasons.length > 0 && (
            <ContainerFlex padding={22}>
              {reasons.length > 1 ? (
                <ContainerFlex row center>
                  <ReasonDialogItem item={reasons[0]} />

                  <Button
                    marginLeft={22}
                    fitContent
                    onClick={() => setDialogReasonShow(true)}
                  >
                    Ver mais
                  </Button>
                </ContainerFlex>
              ) : reasons.length === 1 ? (
                <ReasonDialogItem item={reasons[reasons.length - 1]} />
              ) : null}
            </ContainerFlex>
          )}

          <br />

          <CardView marginBottom={22}>
            <ContainerFlex row end padding={26}>
              <Button onClick={goBack} outlined>
                Voltar
              </Button>
              {/* <Button onClick={print} marginLeft={16}>
                Imprimir
              </Button> */}
            </ContainerFlex>
          </CardView>
        </ContainerFlex>
      </Layout>
    </>
  );
};

export default DetailReportCT;
