export const matchProducts = async (
  arrayProduct: any[],
  valueToMatch: string,
) => {
  return arrayProduct?.find((item) => item?.tipoProduto === valueToMatch);
};

export const matchEvents = async (
  arrayProduct: any[],
  valueToMatch: string,
) => {
  return arrayProduct?.find((item) => item?.id === valueToMatch);
};

export const isDatePastDue = (dateString) => {
  const providedDate = new Date(dateString);

  const currentDate = new Date();

  providedDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  return providedDate < currentDate;
};

export const validateTime = (input: string): boolean => {
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  if (!regex.test(input)) {
    return true;
  }

  const [hour, minute] = input.split(':');
  const parsedHour = parseInt(hour, 10);
  const parsedMinute = parseInt(minute, 10);

  if (
    parsedHour < 0 ||
    parsedHour > 23 ||
    parsedMinute < 0 ||
    parsedMinute > 59
  ) {
    return true;
  }

  return false;
};

export const formatTime = (event: string | undefined): string => {
  let input = event || '';

  input = input.replace(/\D/g, '');

  input = input.replace(/^(\d{2})?(\d{0,2})$/, (_, first, second) => {
    if (!first && !second) return '';
    return first ? `${first}${second ? `:${second}` : ''}` : second || '';
  });

  return input;
};
