/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FiChevronRight } from 'react-icons/fi';
import { FaHome } from 'react-icons/fa';
import { routesConfig } from '../sidebar/routesConfig';

const FirstLink = styled(NavLink)`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: #949fb1;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.2rem;
    font-weight: 400;
    margin-right: 10px;
    color: #d3dae3;
  }
`;

const SecondLink = styled(NavLink)`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: #949fb1;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.2rem;
    font-weight: 400;
    margin-right: 10px;
    color: #d3dae3;
  }
`;

type LastLinkProps = {
  isDetailRoute: boolean;
};
const LastLink = styled(NavLink)<LastLinkProps>`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: #949fb1;
  pointer-events: ${({ isDetailRoute }) => (isDetailRoute ? '' : 'none')};
  display: flex;
  align-items: center;

  svg {
    font-size: 1.2rem;
    font-weight: 400;
    margin-right: 10px;
    color: #d3dae3;
  }
`;

const BreadcrumbContainer = styled.nav`
  display: flex;
  align-items: center;
  margin: 0 12px 20px 0px;
`;

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const itemLocation = location?.pathname;
  const history = useHistory();
  // pipeline
  const firstLocation = routesConfig?.find((e) => {
    return e?.subMenu
      ? e.subMenu.find((i) => i.route === itemLocation)
      : e?.route === itemLocation || e.route?.includes(itemLocation);
  });
  const hasBreadConfig = !!firstLocation?.bread;

  const secondLocation =
    firstLocation &&
    firstLocation?.subMenu?.find((e) => e?.route?.includes(itemLocation));

  const isDetailRoute =
    itemLocation?.includes('detail') || itemLocation?.includes('/table');

  if (hasBreadConfig) {
    return (
      <BreadcrumbContainer>
        <FirstLink to="/homeDash">
          <FaHome />
        </FirstLink>
        {firstLocation?.bread?.map((e) => {
          const { disabled, label, to, goBack } = e;
          if (disabled) {
            return (
              <>
                <FiChevronRight />

                <span
                  style={{
                    fontSize: '0.875rem',
                    marginLeft: '8px',
                    marginRight: '8px',
                    color: '#2e3958',
                  }}
                >
                  {label}
                </span>
              </>
            );
          }
          if (goBack) {
            return (
              <>
                <FiChevronRight />
                <span
                  onClick={() => history.goBack()}
                  style={{
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    marginLeft: '8px',
                    marginRight: '8px',
                    color: '#2e3958',
                  }}
                >
                  {label}
                </span>
              </>
            );
          }
          return (
            <FirstLink
              style={{ color: '#2e3958', marginRight: '8px' }}
              activeStyle={{ color: '#2e3958' }}
              to={to}
            >
              <FiChevronRight />
              {label}
            </FirstLink>
          );
        })}
      </BreadcrumbContainer>
    );
  }
  return (
    <BreadcrumbContainer>
      <FirstLink to="/homeDash">
        <FaHome />
      </FirstLink>
      {/* Rotas com submenu tem a rota principal como '' */}

      {firstLocation && firstLocation?.route === '' ? (
        <>
          <FiChevronRight style={{ fontSize: '1.2rem', color: '#d3dae3' }} />
          <span
            style={{
              fontSize: '0.875rem',
              color: '#949fb1',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            {firstLocation?.label}
          </span>
        </>
      ) : (
        <FirstLink
          style={{ color: !secondLocation && '#2e3958' }}
          activeStyle={{ color: !secondLocation && '#2e3958' }}
          to={
            isDetailRoute
              ? firstLocation?.route
                  .replace('/detail', '')
                  .replace('/table', '')
                  .replace('/faturasPagas', '')
                  .replace('/faturasEmAtraso', '')
              : firstLocation?.route
          }
        >
          <FiChevronRight />
          {firstLocation?.label}
        </FirstLink>
      )}
      {secondLocation && (
        <>
          {isDetailRoute ? (
            <LastLink
              isDetailRoute
              activeStyle={{ color: '#2e3958' }}
              to={secondLocation?.route
                .replace('/detail', '')
                .replace('/table', '')
                .replace('/faturasPagas', '')
                .replace('/faturasEmAtraso', '')}
            >
              <FiChevronRight />
              {secondLocation?.label}
            </LastLink>
          ) : (
            <LastLink
              isDetailRoute={false}
              activeStyle={{ color: '#2e3958' }}
              to={secondLocation?.route}
            >
              <FiChevronRight />
              {secondLocation?.label}
            </LastLink>
          )}
        </>
      )}

      {isDetailRoute && (
        <>
          <FiChevronRight
            style={{ fontSize: '1.2rem', color: '#d3dae3', marginLeft: '10px' }}
          />
          <span
            style={{
              fontSize: '0.875rem',
              marginLeft: '10px',
              marginRight: '10px',
              color: '#2e3958',
            }}
          >
            Detalhes
          </span>
        </>
      )}
    </BreadcrumbContainer>
  );
};
export default Breadcrumbs;
