/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Dialog from '~/components/dialog';
import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';
import { PAYMENT_METHODS, TIPO_TRANSACAO } from '~/utils/payment-methods';
import Select from '~/components/select';
import Loading from '~/components/loading';
import { icons } from '~/assets';
import {
  currencyUnmasked,
  creditCardMask,
  currencyMask,
  IDefaultReturn,
  cpfMask,
} from '~/utils/masks';

import { creditCardValidation } from '~/utils/validators';

import * as St from './styles';
import Container from '~/components/container';
import Constants from '~/utils/constants';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';

interface IForm {
  nome?: string;
  cpf?: string;
  paymentMethod?: string;
  checkout?: string;
  listCompany?: any[];
  loja?: {
    estabelecimento?: string;
    id?: string;
    nome?: string;
  };
  numeroCartao?: string;
  valor?: string;
  valorParcela?: string;
  valorTotal?: string;
  tipoTransacao?: string;
  descricao?: null | string;
  categoria?: string;
}

const PurchaseInclusion = () => {
  const [form, setForm] = useState<IForm>({
    nome: '',
    cpf: '',
    numeroCartao: '',
    paymentMethod: null,
    checkout: null,
    listCompany: [],
    loja: {
      estabelecimento: '',
      id: '',
      nome: '',
    },
    valor: '0.00',
    tipoTransacao: '',
    descricao: null,
  });
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [dialogApproveMessageOpen, setApproveDialogMessageOpen] = useState(
    false,
  );
  const [popUpLoginModal, setPopUpLoginModal] = useState(false);
  const [cpf, setCpf] = useState<IDefaultReturn>({} as IDefaultReturn);
  const [senha, setSenha] = useState('');
  const user = JSON.parse(localStorage.getItem(Constants.USER));

  const history = useHistory();
  const location = useLocation<IForm>();

  const RequestSetTransaction = useApi<any[]>(
    `${endpoints.register.postSetTransaction}`,
    ApiMethod.POST,
  );

  const RequestGetBusiness = useApi<any[]>(
    `${endpoints.consult.searchCompany}`,
    ApiMethod.GET,
  );
  const GetPaymentRules = useApi<any[]>(
    `${endpoints.consult.getPaymentRules}`,
    ApiMethod.GET,
    {},
    true,
  );
  console.log(location.state);
  useEffect(() => {
    if (form.checkout) {
      form.listCompany.forEach((data) => {
        if (data.id === form.checkout) {
          setForm({
            ...form,
            loja: {
              id: data?.id,
              estabelecimento: data?.estabelecimento,
              nome: data?.value,
            },
          });
        }
      });
    }
  }, [form.checkout]);

  const AuthValidatePassword = useApi<any>(
    endpoints.account.validateUserPassword,
    ApiMethod.POST,
  );

  useEffect(() => {
    const getBusiness = async () => {
      const response = await RequestGetBusiness.callApi();
      if (response.status >= 200 && response.status < 300) {
        setForm({
          ...form,
          listCompany: response.data.map((item: any) => ({
            value: item.nome,
            id: item.id,
            estabelecimento: item.estabelecimento,
          })),
          numeroCartao: location.state?.numeroCartao,
          nome: location.state?.nome,
          cpf: location.state?.cpf,
        });
      }
    };
    getBusiness();
  }, []);

  const handleValidation = () => {
    let isValid = true;

    if (form.tipoTransacao === 'TRANSACAO_CARTAO_DE_CREDITO') {
      if (!creditCardValidation(form.numeroCartao)) {
        setPopUpErrorMessage('Cartão de crédito inválido');
        setPopUpError(true);
        isValid = false;
      }
    }

    if (!form.valor || Number(form.valor.replace(',', '.')) <= 0) {
      setPopUpErrorMessage('O valor da transação deve ser maior do que zero');
      setPopUpError(true);
      isValid = false;
    }

    if (
      form.tipoTransacao === 'TRANSACAO_CARTAO_DE_CREDITO' &&
      !form.paymentMethod
    ) {
      setPopUpErrorMessage('Selecione a quantidade de parcelas');
      setPopUpError(true);
      isValid = false;
    }

    if (!form.checkout) {
      setPopUpErrorMessage('Selecione um caixa');
      setPopUpError(true);
      isValid = false;
    }

    return isValid;
  };

  const handlePasswordValidate = async () => {
    try {
      const { status, mensagem } = await AuthValidatePassword.callApi({
        cpf: cpf.unmasked,
        senha,
      });
      if (status >= 200 && status <= 300) {
        handleClick();
        setPopUpLoginModal(false);
      } else {
        setPopUpLoginModal(false);
        setPopUpErrorMessage(mensagem);
        setPopUpError(true);
      }
    } catch (err) {
      setPopUpMessage(err.mensagem);
      console.log(err);
    }
  };

  const handleClick = async () => {
    const valorMask = currencyMask(form.valor);

    const payload = {
      cliente: {
        nome: form.nome,
        cpf: form.cpf,
      },
      descricao: form.descricao,
      loja: {
        estabelecimento: form.loja.estabelecimento,
        id: form.loja.id,
        nome: form.loja.nome,
      },
      numeroCartao: form.numeroCartao,
      parcelas: form.paymentMethod,
      tipoTransacao: form.tipoTransacao,
      usuarioSolicitacao: {
        id: user.id,
        nome: user.nomeTitular,
      },
      valor: currencyUnmasked(valorMask),
    };
    if (form.tipoTransacao === 'TRANSACAO_CARTAO_DE_CREDITO') {
      payload.valorParcela = currencyUnmasked(form.valorParcela);
      payload.valorTotal = currencyUnmasked(form.valorTotal);
    }
    const response = await RequestSetTransaction.callApi(payload);

    setPopUpMessage(response.mensagem);
    setOpenDialog(true);
  };
  return (
    <>
      <Loading
        status={
          RequestSetTransaction.loading ||
          AuthValidatePassword.loading ||
          GetPaymentRules.loading
        }
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogApproveMessageOpen}
        positiveLabel="Sim"
        negativeLabel="Não"
        negativeAction={() => {
          setApproveDialogMessageOpen(false);
          history.push('/homeDash');
        }}
        positiveAction={() => setPopUpLoginModal(true)}
        message="Você já realizou e confirmou o estorno desta transação?"
      />
      <Dialog
        open={openDialog}
        message={popUpMessage}
        positiveAction={() => {
          setOpenDialog(false);
          history.push('/homeDash');
        }}
        positiveLabel="Entendi"
        icon={icons.check}
      />
      <Dialog
        open={popUpError}
        message={popUpErrorMessage}
        positiveAction={() => {
          setPopUpError(false);
          setApproveDialogMessageOpen(false);
        }}
        positiveLabel="Entendi"
        icon={icons.check}
      />
      <Dialog
        open={popUpLoginModal}
        positiveLabel="Sim"
        negativeLabel="Não"
        negativeAction={() => {
          setApproveDialogMessageOpen(false);
          history.push('/homeDash');
        }}
        positiveAction={handlePasswordValidate}
        message="Insira sua senha"
        buttonDisabledPositive={
          RequestSetTransaction.loading || AuthValidatePassword.loading
        }
      >
        <Input
          name="cpf"
          id="cpf"
          type="text"
          label="CPF"
          maxLength={14}
          value={cpf.masked}
          onChange={(e) => setCpf(cpfMask(e.target.value))}
        />
        <Input
          name="senha"
          id="senha"
          type="password"
          label="Senha"
          maxLength={6}
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
      </Dialog>

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Select
                label="Escolha como será realizada a transação"
                value={form.tipoTransacao}
                options={TIPO_TRANSACAO}
                onChange={(e) => setForm({ ...form, tipoTransacao: e })}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Valor"
                placeholder="0,00"
                name="valor"
                onBlur={async () => {
                  await GetPaymentRules.callApi({
                    bandeira: 'TEUCARD',
                    valorTotal: currencyUnmasked(form.valor),
                    categoria: location.state.categoria,
                  });
                }}
                mask={MaskTypes.CURRENCY}
                value={form.valor}
                onChange={(e) => {
                  console.log(e.target.value);
                  if (GetPaymentRules.data) {
                    setForm({
                      ...form,
                      valor: e.target.value,
                      paymentMethod: '',
                      valorParcela: '',
                      valorTotal: '',
                    });
                  } else {
                    setForm({ ...form, valor: e.target.value });
                  }
                }}
              />
            </ContainerFlex>

            {form.tipoTransacao === 'TRANSACAO_CARTAO_DE_CREDITO' && (
              <ContainerFlex padding={16}>
                <Select
                  label="Parcela"
                  value={form.paymentMethod}
                  options={
                    GetPaymentRules?.data?.length > 0
                      ? GetPaymentRules?.data?.map((e) => {
                          if (e.parcela === 1)
                            return { id: e.parcela, value: 'À Vista' };
                          return {
                            id: e.parcela,
                            value: `${e.parcela}x`,
                          };
                        })
                      : []
                  }
                  onChange={(e) => {
                    const found = GetPaymentRules.data.find(
                      (i) => i.parcela === e,
                    );
                    setForm({
                      ...form,
                      paymentMethod: e,
                      valorParcela: currencyMask(found.valorParcela),
                      valorTotal: currencyMask(found.valorTotal),
                    });
                  }}
                />
              </ContainerFlex>
            )}
          </ContainerFlex>

          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="Número do cartão"
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                mask={MaskTypes.CREDIT_CARD}
                onChange={(e) =>
                  setForm({ ...form, numeroCartao: e.target.value.unmasked })
                }
                name="numeroCartao"
                value={creditCardMask(form.numeroCartao).masked}
                disabled={
                  form.tipoTransacao === 'REEMBOLSO' ||
                  form.tipoTransacao === 'VOUCHER' ||
                  form.tipoTransacao === 'TRANSACAO_CARTAO_DE_CREDITO'
                }
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <AutoCompleteSelect
                label="Loja"
                value={form.checkout}
                options={form.listCompany}
                onChange={(e) => setForm({ ...form, checkout: e.id })}
              />
            </ContainerFlex>
            {form.tipoTransacao === 'TRANSACAO_CARTAO_DE_CREDITO' && (
              <>
                <ContainerFlex padding={16}>
                  <Input
                    label="Valor Parcela"
                    placeholder="0,00"
                    name="valorParcela"
                    mask={MaskTypes.CURRENCY}
                    value={form.valorParcela}
                  />
                </ContainerFlex>
                <ContainerFlex padding={16}>
                  <Input
                    label="Valor Total"
                    placeholder="0,00"
                    name="valorTotal"
                    mask={MaskTypes.CURRENCY}
                    value={form.valorTotal}
                  />
                </ContainerFlex>
              </>
            )}

            <ContainerFlex padding={16}>
              <Button
                fitContent
                disabled={RequestSetTransaction.loading}
                onClick={() => {
                  if (handleValidation()) {
                    handleClick();
                  }
                }}
              >
                Solicitar Transação
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default PurchaseInclusion;
