/* eslint-disable default-case */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import MultiRangeSlider from '~/components/multiRangeSlider/MultiRangeSlider';

import * as St from './styles';
import { getSettings } from '../../model';
import RadioButton from '~/components/radioButton';
import { currencyMask } from '~/utils/masks';
import { CreateCamapignForm } from '../../Create';

interface IProps {
  readonly settings: getSettings;
  min: number;
  max: number;
  icon: string;
  onChange: React.Dispatch<React.SetStateAction<CreateCamapignForm>>;
  type: 'platinum' | 'padrao';
}

const RangePicker: React.FC<IProps> = ({
  settings,
  min,
  max,
  onChange,
  icon,
  type,
}) => {
  const [form, setForm] = useState<getSettings>(settings);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formatted = parseFloat(value.replace(/\./g, '').replace(',', '.'));

    if (form.tipoAumento === 'PERCENTUAL') {
      setForm((prev) => ({
        ...prev,
        [name]: value > 100 ? 100 : formatted,
      }));
      return;
    }
    setForm((prev) => ({
      ...prev,
      [name]: formatted,
    }));
  };

  useEffect(() => {
    onChange((prev) => ({
      ...prev,
      [type]: prev[type].map((p) => {
        if (p.nivel === settings.nivel) {
          return form;
        }
        return p;
      }),
    }));
  }, [form, onChange]);
  const generateTitle = () => {
    const [title, number] = form.nivel.split('_');
    let str = '';
    str += title?.toLowerCase();
    for (let i = 0; i < Number(number); i++) {
      str += ' +';
    }
    return str;
  };
  return (
    <ContainerFlex>
      <Grid xs={1} lg={2} gap={30}>
        {/* Bronze */}
        <ContainerFlex key={form?.id && form?.id}>
          <St.TitleLevel>
            Nível {generateTitle()}
            <img style={{ paddingLeft: '8px' }} alt="" height={24} src={icon} />
          </St.TitleLevel>
          <St.TitleLevelSmall>
            Selecione a quantidade de pontos de fatura para esse nível
          </St.TitleLevelSmall>

          <MultiRangeSlider
            min={min}
            max={max}
            defaultValues={{
              min: form.pontuacaoInicial,
              max: form.pontuacaoFinal,
            }}
            editValue={(min, max) =>
              setForm((prev) => ({
                ...prev,

                pontuacaoFinal: max,
                pontuacaoInicial: min,
              }))
            }
          />
          <ContainerFlex row align="center" center gap="12">
            <St.TitleLevelSmall style={{ padding: 0, marginBottom: 0 }}>
              Selecione a forma de aumento:
            </St.TitleLevelSmall>
            <ContainerFlex row horizontal align="flex-end">
              <RadioButton
                id="tipoAumento"
                onClick={() =>
                  setForm((prev) => ({
                    ...prev,
                    tipoAumento: 'PERCENTUAL',
                  }))
                }
                checked={form.tipoAumento === 'PERCENTUAL'}
              />
              Percentual
            </ContainerFlex>
            <ContainerFlex row horizontal align="flex-end">
              <RadioButton
                id="tipoAumento"
                onClick={() =>
                  setForm((prev) => ({
                    ...prev,
                    tipoAumento: 'VALOR',
                  }))
                }
                checked={form.tipoAumento === 'VALOR'}
              />
              Valor
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex marginTop={12} row center gap="12">
            <Input
              disabled={form.tipoAumento !== 'PERCENTUAL'}
              label="% de aumento compras"
              value={form.tipoAumento !== 'PERCENTUAL' ? '' : form.aumento}
              name="aumento"
              max={100}
              inputMode="numeric"
              onChange={(e) => {
                handleChange(e);
              }}
              edit
            />
            <Input
              disabled={form.tipoAumento !== 'VALOR'}
              label="Valor de aumento compras"
              value={
                form.tipoAumento !== 'VALOR' ? '' : currencyMask(form.aumento)
              }
              name="aumento"
              mask={MaskTypes.CURRENCY}
              onChange={(e) => {
                handleChange(e);
              }}
              edit
            />
          </ContainerFlex>
          <ContainerFlex marginTop={12} row center gap="12">
            <Input
              disabled={form.tipoAumento !== 'PERCENTUAL'}
              label="% de aumento empréstimo"
              value={
                form.tipoAumento !== 'PERCENTUAL' ? '' : form.aumentoEmprestimo
              }
              name="aumentoEmprestimo"
              max={100}
              inputMode="numeric"
              onChange={(e) => {
                handleChange(e);
              }}
              edit
            />
            <Input
              disabled={form.tipoAumento !== 'VALOR'}
              label="Valor de aumento empréstimo"
              value={
                form.tipoAumento !== 'VALOR'
                  ? ''
                  : currencyMask(form.aumentoEmprestimo)
              }
              name="aumentoEmprestimo"
              onChange={(e) => {
                handleChange(e);
              }}
              mask={MaskTypes.CURRENCY}
              edit
            />
          </ContainerFlex>
          <ContainerFlex marginTop={12} row center gap="12">
            <Input
              label="Limite mínimo empréstimo"
              value={currencyMask(form.limiteMinimoEmprestimo)}
              name="limiteMinimoEmprestimo"
              inputMode="numeric"
              mask={MaskTypes.CURRENCY}
              onChange={(e) => {
                handleChange(e);
              }}
              edit
            />
            <Input
              label="Limite máximo empréstimo"
              value={currencyMask(form.limiteMaximoEmprestimo)}
              name="limiteMaximoEmprestimo"
              inputMode="numeric"
              mask={MaskTypes.CURRENCY}
              onChange={(e) => {
                handleChange(e);
              }}
              edit
            />
          </ContainerFlex>
          <ContainerFlex marginTop={12} row center gap="12">
            <Input
              label="Limite mínimo compras"
              value={currencyMask(form.limiteMinimoCompras)}
              name="limiteMinimoCompras"
              inputMode="numeric"
              mask={MaskTypes.CURRENCY}
              onChange={(e) => {
                handleChange(e);
              }}
              edit
            />
            <Input
              label="Limite máximo compras"
              value={currencyMask(form.limiteMaximoCompras)}
              name="limiteMaximoCompras"
              inputMode="numeric"
              mask={MaskTypes.CURRENCY}
              onChange={(e) => {
                handleChange(e);
              }}
              edit
            />
          </ContainerFlex>
        </ContainerFlex>
      </Grid>
    </ContainerFlex>
  );
};

export default RangePicker;
