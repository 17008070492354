/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import moment from 'moment';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import * as St from './styles';
import { icons } from '~/assets';
import { currencyMask } from '~/utils/masks';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';

const SelectParcelsQuantity: React.FC<any> = ({
  data,
  qtdParcelasAPagar,
  searchFinancing,
}) => {
  const [parcelsQuantity, setParcelsQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogIsSuccess, setDialogIsSuccess] = useState(false);
  const [dialogIsFail, setDialogIsFail] = useState(false);
  const [parcelsData, setParcelsData] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [requestCalculation, setRequestCalculation] = useState(false);

  const RequestCreateAntecipation = useApi(
    Endpoints.invoices.postAntecipation,
    ApiMethod.POST,
  );

  const getCalculationParcels = useApi(
    Endpoints.invoices.getAntecipationPurchaseCalculation
      .replace('PARAM_ID_COMPRA', data.idCompra)
      .replace('PARAM_QTD_PARCELAS', String(parcelsQuantity)),
    ApiMethod.GET,
  );

  const calculationParcels = async () => {
    setLoading(true);

    const response: any = await getCalculationParcels.callApi();

    if (response.status >= 200 && response.status < 300) {
      setParcelsData(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
      setDialogIsFail(true);
      setErrorMessage('Não foi possível calcular as parcelas!');
      setParcelsQuantity(0);
    }
  };

  useEffect(() => {
    if (requestCalculation) {
      calculationParcels();
    }
  }, [parcelsQuantity, requestCalculation]);

  const handleParcelsQuantity = (operation: string) => {
    if (operation === '+') {
      setParcelsQuantity((prev) => prev + 1);
      setRequestCalculation(true);
    }
    if (operation === '-') {
      if (parcelsQuantity === 0) return;
      setParcelsQuantity((prev) => prev - 1);
      setRequestCalculation(true);
    }
  };
  const modalIsOpen = () => {
    setModalOpen(true);
  };

  const requestFinancialParcel = async () => {
    const payload = {
      cartao: data?.cartao,
      cpf: data?.cpf,
      dataFechamento: String(moment(data?.dataCorte).format('YYYY-MM-DD')),
      email: data?.email,
      idParcelamento: data.idCompra,
      nomeCliente: data?.nomeCompleto,
      parcelasAntecipadas: parcelsData.parcelasAAntecipar,
      valorAntecipacao: parcelsData.valorAntecipacao,
      tipoAntecipacao: 'COMPRA',
    };

    try {
      const response: any = await RequestCreateAntecipation.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setModalOpen(false);
        setDialogIsSuccess(true);
      } else {
        setErrorMessage(response.data?.mensagem);
        setModalOpen(false);
        setDialogIsFail(true);
      }
    } catch (err) {
      setModalOpen(false);
      setDialogIsFail(true);
    }
  };

  return (
    <>
      <Loading status={loading || RequestCreateAntecipation.loading} />
      <ContainerFlex width={50} center style={{ minHeight: '180px' }}>
        <St.Title>Antecipar quantas parcelas?</St.Title>
        <ContainerFlex row horizontal center style={{ gap: '20px' }}>
          <St.ActionsButton
            onClick={() => handleParcelsQuantity('-')}
            type="button"
            disabled={parcelsQuantity === 0}
          >
            <AiOutlineMinus />
          </St.ActionsButton>
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {`${String(parcelsQuantity).padStart(2, '0')} de ${String(
              qtdParcelasAPagar,
            ).padStart(2, '0')}`}
          </span>
          <St.ActionsButton
            onClick={() => handleParcelsQuantity('+')}
            type="button"
            disabled={parcelsQuantity >= qtdParcelasAPagar}
          >
            <AiOutlinePlus />
          </St.ActionsButton>
        </ContainerFlex>
        <St.Value>
          Valor: R$
          {` ${currencyMask(parcelsData?.valorAntecipacao)}`}
        </St.Value>
        <ContainerFlex center horizontal style={{ gap: '20px' }} row>
          <St.ButtonMain
            onClick={() => modalIsOpen()}
            fitContent
            type="button"
            disabled={parcelsQuantity === 0}
          >
            Solicitar Antecipação
          </St.ButtonMain>
        </ContainerFlex>
        <Dialog
          icon={icons.exclamation}
          open={modalOpen}
          title={<span style={{ color: 'red' }}> Antecipar </span>}
          positiveLabel="SIM"
          positiveAction={requestFinancialParcel}
          negativeLabel="NÃO"
          negativeAction={() => setModalOpen(false)}
        >
          <St.DescriptionDialog>
            {`Comunique o cliente que deverá pagar no caixa o quanto antes. Caso
            não seja realizado o pagamento, este valor será cobrado na próxima fatura
            que vence dia ${moment(data?.dataVencimento).format('DD/MM/YYYY')}.
            Deseja antecipar o valor de R$ ${currencyMask(
              parcelsData?.valorAntecipacao,
            )} Reais?`}
          </St.DescriptionDialog>
        </Dialog>
        <Dialog
          icon={icons.confirmation}
          open={dialogIsSuccess}
          message="Solicitação enviada com sucesso!"
          positiveLabel="Entendi"
          positiveAction={() => {
            setDialogIsSuccess(false);
            searchFinancing();
          }}
        />
        <Dialog
          icon={icons.warning}
          open={dialogIsFail}
          message={errorMessage}
          positiveLabel="Entendi"
          positiveAction={() => {
            setDialogIsFail(false);
            searchFinancing();
          }}
        />
      </ContainerFlex>
    </>
  );
};

export default SelectParcelsQuantity;
