import styled from 'styled-components';
import { BsArrowLeft } from 'react-icons/bs';
import { FiDownload } from 'react-icons/fi';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;

  svg {
    font-size: 32px;
    color: #4f525a;
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const BtnDownload = styled.button`
  border: none;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  background: #e4003a;
  cursor: pointer;
  -webkit-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;

  .react-icons {
    font-size: 22px;
    color: #fff;
    margin-right: 7px;
  }

  &:hover {
    background: #a9002b;
    box-shadow: 0 1px 4px -2px black,
      inset 0 2px 1px -1px rgb(255 255 255 / 10%);
  }
`;

export const HeaderTitle = styled.h1`
  width: 100%;
  font-size: 1.2rem;
  font-weight: 700;
  color: #2e3958;
`;

export const HeaderDates = styled.div`
  flex: 3;
  background: red;
`;

export const HeaderRight = styled.div`
  flex: 1;
  background: yellow;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 10px;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #2e3958;
  padding: 6px;
  background: #ecf1ff;
  border-radius: 5px;
`;

export const MonthCards = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 920px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MonthClosingCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 24px;

  @media (max-width: 1180px) {
    display: grid;
    grid-template-columns: minmax(830px, auto);
    gap: 24px;
  }
`;

export const Content = styled.div`
  transition: height 0.7s linear;
  overflow: hidden;
`;

export const MonthCardItem = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h2 {
      font-size: 14px;
      font-weight: 600;
      color: #677383;
      margin-bottom: 5px;
    }

    span {
      font-size: 0.7rem;
      font-weight: 700;
      color: #2e3958;
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 600;
    color: #e4003a;
    margin-top: 1%;
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;

    &.red {
      color: #e4003a;
    }

    &.green {
      color: #00b285;
    }

    &.blue {
      color: #3c77ff;
    }

    &.orange {
      color: #fab73b;
    }

    &.gray {
      color: #b4b4b4;
    }
  }

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const MonthCardItemCarousel = styled.div`
  width: 267px;
  max-width: 267px;

  @media (max-width: 1200px) {
    width: 290px;
    max-width: 290px;
  }

  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h2 {
      font-size: 14px;
      font-weight: 600;
      color: #677383;
      margin-bottom: 5px;
    }

    span {
      font-size: 0.7rem;
      font-weight: 700;
      color: #2e3958;
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 600;
    color: #e4003a;
    margin-top: 1%;
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;

    &.red {
      color: #e4003a;
    }

    &.green {
      color: #00b285;
    }

    &.blue {
      color: #3c77ff;
    }

    &.orange {
      color: #fab73b;
    }

    &.gray {
      color: #b4b4b4;
    }
  }
`;

export const Data = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #2e3958;
`;

export const MonthCardItemNoHover = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h2 {
      font-size: 14px;
      font-weight: 600;
      color: #677383;
      margin-bottom: 5px;
    }

    span {
      font-size: 0.7rem;
      font-weight: 700;
      color: #2e3958;
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 600;
    color: #e4003a;
    margin-top: 1%;
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;

    &.red {
      color: #e4003a;
    }

    &.green {
      color: #00b285;
    }

    &.blue {
      color: #3c77ff;
    }

    &.orange {
      color: #fab73b;
    }

    &.gray {
      color: #b4b4b4;
    }
  }
`;

export const MonthCardItemNoHoverHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BoxGraphics = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 1180px) {
    display: grid;
    grid-template-columns: 506px auto;
    gap: 24px;
  }

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: minmax(830px, auto);
    gap: 24px;
  }

  .item {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
    position: relative;
    transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;

    header {
      padding: 24px;
      border-bottom: 1px solid #f6f6f6;

      h2 {
        font-size: 1em;
        font-weight: 700;
        color: #2e3958;
        margin-bottom: 8px;
      }

      span {
        font-size: 0.7rem;
        font-weight: 600;
        color: #2e3958;
        padding: 6px;
        background: #ecf1ff;
        border-radius: 5px;
      }
    }

    &__graphic-bar {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &__graphic {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const OnlyOneBoxGraphics = styled.div`
  display: grid;
  grid-template-columns: 620px;
  gap: 24px;
  margin-bottom: 24px;
  min-width: 500px;

  .item {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
    position: relative;
    transition: 0.2s all;
    -moz-transition: 0.2s all;
    -webkit-transition: 0.2s all;

    header {
      padding: 24px;
      border-bottom: 1px solid #f6f6f6;

      h2 {
        font-size: 1em;
        font-weight: 700;
        color: #2e3958;
        margin-bottom: 8px;
      }

      span {
        font-size: 0.7rem;
        font-weight: 600;
        color: #2e3958;
        padding: 6px;
        background: #ecf1ff;
        border-radius: 5px;
      }
    }

    &__graphic-bar {
      width: 100%;
      display: flex;
      margin-top: 16px;
    }

    &__graphic {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const EndCards = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 1180px) {
    display: grid;
    grid-template-columns: minmax(830px, auto);
    gap: 24px;
  }
`;

export const EndCardsItems = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .title {
      h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 5px;
        color: #2e3958;
      }

      &__update {
        font-size: 0.7rem;
        font-weight: 600;
        color: #2e3958;
        padding: 6px;
        background: #ecf1ff;
        border-radius: 5px;
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 40px;
        color: #c9ced6;
        margin-right: 10px;
      }
    }
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    color: #c9ced6;
    cursor: pointer;

    &:hover {
      color: #828282;
    }
  }

  section {
    display: flex;
    justify-content: space-around;
    align-items: center;

    article {
      text-align: center;

      h2 {
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-weight: 700;
        color: #2e3958;
      }

      span {
        font-size: 1rem;
        font-weight: 700;
        color: #aeaeae;
      }
    }
  }

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const EndCardsItemsCarousel = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #ecf1ff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .title {
      h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 5px;
        color: #2e3958;
      }

      &__update {
        font-size: 0.7rem;
        font-weight: 600;
        color: #2e3958;
        padding: 6px;
        background: #ffffff;
        border-radius: 5px;
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 40px;
        color: #c9ced6;
        margin-right: 10px;
      }
    }
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    color: #c9ced6;
    cursor: pointer;

    &:hover {
      color: #828282;
    }
  }

  section {
    display: flex;
    justify-content: space-around;
    align-items: center;

    article {
      text-align: center;

      h2 {
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-weight: 700;
        color: #2e3958;
      }

      span {
        font-size: 1rem;
        font-weight: 700;
        color: #2e3958;
      }
    }
  }

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const EndCardsItemsNoHover = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;

  header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .title {
      h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 5px;
        color: #2e3958;
      }

      &__update {
        font-size: 0.7rem;
        font-weight: 600;
        color: #2e3958;
        padding: 6px;
        background: #ecf1ff;
        border-radius: 5px;
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 40px;
        color: #c9ced6;
        margin-right: 10px;
      }
    }
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    color: #c9ced6;
    cursor: pointer;

    &:hover {
      color: #828282;
    }
  }

  section {
    display: flex;
    justify-content: space-around;
    align-items: center;

    article {
      text-align: center;

      h2 {
        font-size: 2rem;
        font-weight: 700;
        color: #2e3958;
      }

      span {
        font-size: 1rem;
        font-weight: 700;
        color: #aeaeae;
      }
    }
  }
`;

export const Items = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;

  article {
    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: #2e3958;
    }

    span {
      font-size: 1rem;
      font-weight: 700;
      color: #aeaeae;
    }
  }
`;

export const ItemsDetails = styled.div`
  cursor: pointer;
  article {
    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: #2e3958;
    }

    span {
      font-size: 1rem;
      font-weight: 700;
      color: #aeaeae;
    }
  }
`;

export const GraphicGrid = styled.div`
  width: calc(100% - 24px);
  display: flex;
  flex-wrap: wrap;
`;

export const BoxDetails = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;

  header {
    padding: 24px;
    border-bottom: 1px solid #f6f6f6;

    h2 {
      font-size: 1em;
      font-weight: bold;
      color: #2e3958;
      margin-bottom: 3px;
    }

    span {
      font-size: 0.7rem;
      font-weight: 700;
      color: #677383;
    }
  }
`;

export const BoxHeader = styled.header`
  padding: 24px;
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.2rem;
    color: #677383;
    cursor: pointer;
    margin-top: -2px;
    margin-right: 10px;
  }
`;

export const BoxHeaderTitle = styled.h2`
  font-size: 1em;
  font-weight: bold;
  color: #2e3958;
  margin-bottom: 3px;
`;

export const BoxHeaderSpan = styled.span`
  font-size: 0.7rem;
  font-weight: 700;
  color: #677383;
`;

export const ItemGraphic = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
`;

export const TitleCard = styled.h1`
  font-size: 14px;
  font-weight: 700;
  color: #2e3958;
`;

export const DialogSection = styled.section`
  /* border: 1px solid #eee;
  border-radius: 5px;
  padding: 16px; */
  /* margin-bottom: 8px; */
`;

export const DialogTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  color: #2e3958;
`;

export const DialogSubTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #2e3958;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 3px 0;
`;

export const TextBoxMonth = styled.span`
  margin-top: 13%;
  font-weight: 600;
  font-size: 24px;
  color: #2e3958;
`;

export const TextBoxEnd = styled.span`
  margin-top: 2%;
  font-weight: 650;
  font-size: 30px;
  color: #2e3958;
`;

export const SubTextBoxMonth = styled.span`
  margin-top: 5%;
  margin-bottom: 5%;
  font-weight: 550;
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #aeaeae;
`;

export const IconReload = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-top: 15px;
`;

export const IconReloadCard = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-top: 10px;
`;

export const IconLeft = styled(BsArrowLeft)`
  width: 20px;
  height: 20px;
  margin-top: 12px;
  margin-right: 5px;
`;

export const IconDownLoad = styled(FiDownload)`
  width: 23px;
  height: 23px;
  margin-top: 1.5%;
  margin-right: 8%;
`;

export const InputDateDisabled = styled.span`
  display: flex;
  background-color: #e9ecef;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  margin-right: 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
