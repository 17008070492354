/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { usePagination } from '~/hooks/usePagination';

import * as St from './styles';

interface IPaginationProps {
  totalPages: number;
  onCurrentPageChange: (page: number) => void;
  initialCurrentPage?: number;
}

const Pagination: React.FC<IPaginationProps> = ({
  totalPages,
  onCurrentPageChange,
  initialCurrentPage,
}) => {
  const {
    currentPage,
    pages,
    onClickPage,
    goBack,
    goNext,
    goToFirstPage,
    goToLastPage,
    firstRender,
  } = usePagination(totalPages, initialCurrentPage);

  // Utilizar o initialCurrentPage se deseja persistir a pagina atual, no caso de manter a pesquisa

  useEffect(() => {
    if (!firstRender) onCurrentPageChange(currentPage);
  }, [currentPage]);

  return (
    <div style={{ display: 'flex' }}>
      <St.FirstTab onClick={goToFirstPage}>Início</St.FirstTab>
      <St.CustomTabs onClick={goBack}>
        <FiChevronLeft />
      </St.CustomTabs>

      {pages.map((page) => (
        <St.Tabs
          onClick={() => onClickPage(page)}
          selected={currentPage === page}
        >
          {page}
        </St.Tabs>
      ))}

      <St.CustomTabs onClick={goNext}>
        <FiChevronRight />
      </St.CustomTabs>
      <St.LastTab onClick={goToLastPage}>Último</St.LastTab>
    </div>
  );
};

export default memo(Pagination);
