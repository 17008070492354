/* eslint-disable react/jsx-one-expression-per-line */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import TableList from '~/components/tableList';
import { useReportAccessionSale } from './context/useAccessionSale';

import * as S from './styles';
import AccessionSale from './components/AccessionSales';
import ResultNotFound from '~/components/resultNotFound';

const ReportWallet: React.FC = () => {
  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [form, setForm] = useState({
    tipoRelatorio: { name: 'tipoRelatorio', value: '' },
    dataInicial: moment().format('DD/MM/YYYY'),
    dataFinal: moment().format('DD/MM/YYYY'),
    operador: 'TODOS',
    uf: 'TODOS',
    estabelecimento: 'TODOS',
    tipoPeriodo: 'TODOS',
    tipoCard: 'TODOS',
    statusTransacao: 'APROVADO',
  });

  const {
    postReportAccession,
    postReportSale,
    postReportTransactions,
    items,
    totalItems,
    totalPages,
    headerItems,
    header,
    relatorioData,
    setRelatorioData,
    clearForm,
    checkSuccess,
    loading,
  } = useReportAccessionSale();

  const payloadType = {
    ADESOES: {
      dataFinal: moment(form.dataFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.dataInicial).format('YYYY-MM-DD'),
      tipoRelatorio: form.tipoRelatorio,
      uf: form.uf,
    },
    LEADS_WALLET_ATIVADA: {
      dataFinal: moment(form.dataFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.dataInicial).format('YYYY-MM-DD'),
      operador: form.operador,
      tipoPeriodo: form.tipoPeriodo,
    },
    TRANSACAO: {
      dataFinal: moment(form.dataFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.dataInicial).format('YYYY-MM-DD'),
      estabelecimento: form.estabelecimento,
      tipo: form.tipoCard,
      status: form.statusTransacao,
    },
  };

  const getReport = async (page = 1) => {
    const payload = payloadType[form.tipoRelatorio.value];

    switch (form.tipoRelatorio?.value) {
      case 'ADESOES':
        await postReportAccession(page, 10, false, payload);
        break;
      case 'LEADS_WALLET_ATIVADA':
        await postReportSale(page, 10, false, payload);
        break;
      case 'TRANSACAO':
        await postReportTransactions(page, 10, false, payload);
        break;
      default:
        break;
    }
  };

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload = payloadType[form?.tipoRelatorio?.value];

    switch (form.tipoRelatorio?.value) {
      case 'ADESOES':
        response = await postReportAccession(currentPage, 150, true, payload);
        break;
      case 'LEADS_WALLET_ATIVADA':
        response = await postReportSale(currentPage, 150, true, payload);
        break;
      case 'TRANSACAO':
        response = await postReportTransactions(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      default:
        break;
    }

    if (response) {
      const { data } = response;

      totalPages = response.totalPaginas;

      const percentage = (currentPage / totalPages) * 100;
      const roundPercentage = Math.ceil(percentage);
      setDownloadCVSProgress(`${roundPercentage || 0}%`);

      if (data?.length) {
        const formattedData = data.map((e) => ({
          ...e,
          cartaoTeuCard: e.cartaoTeuCard ? 'SIM' : 'NÃO',
        }));

        setRelatorioData((prev) => [...prev, ...formattedData]);
      }

      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
      setDownloadCVSProgress('');
    }
  };

  useEffect(() => {
    if (form.tipoRelatorio?.value !== '') {
      getReport();
    }
    clearForm();
  }, [form]);

  return (
    <>
      <Loading status={loading} text={downloadCVSProgress} />
      <Container>
        <AccessionSale
          onClick={(e) => {
            setForm(e);
            setForm({
              ...e,
              dataInicial: moment(e.dataInicial, 'DD-MM-YYYY').format(
                'YYYY-MM-DD',
              ),
              dataFinal: moment(e.dataFinal, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            });
          }}
        />

        <CSVLink
          ref={refCSV}
          data={relatorioData}
          style={{
            color: 'inherit',
            textDecoration: 'none',
          }}
          headers={header}
          filename={`RELATORIO_${form.tipoRelatorio.value}.csv`}
          target="_blank"
        />

        {items?.length > 0 ? (
          <S.BoxCard>
            <ContainerFlex flex="0.2">
              <ContainerFlex row space center marginBottom={24}>
                <S.BoxCardTitle>Relatório</S.BoxCardTitle>
                <Button
                  onClick={() => {
                    setRelatorioData([]);

                    if (refCSV.current) {
                      onClickDownloadCSV().then(() =>
                        refCSV.current.link.click(),
                      );
                    }
                  }}
                  disabled={loading}
                >
                  Download
                </Button>
              </ContainerFlex>
              <ContainerFlex>
                <TableList customerHeader={headerItems} list={items} />
                <ContainerFlex row space center marginTop={20}>
                  <ContainerFlex>
                    <S.ShowResults>
                      Total de resultados: <strong>{totalItems}</strong>
                    </S.ShowResults>
                  </ContainerFlex>
                  <Pagination
                    totalPages={totalPages}
                    onCurrentPageChange={getReport}
                  />
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </S.BoxCard>
        ) : null}

        {checkSuccess() && items.length <= 0 && (
          <ContainerFlex center>
            <ResultNotFound />
          </ContainerFlex>
        )}
      </Container>
    </>
  );
};

export default ReportWallet;
