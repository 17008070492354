import DDDs from '~/assets/mocks/ddd.json';

export enum ValidatorsNames {
  cpf = 'cpf',
  name = 'name',
  socialName = 'socialName',
  phone = 'phone',
  phone2 = 'phone2',
  dateBirth = 'dateBirth',
  email = 'email',
  status = 'status',
  login = 'login',
  cep = 'cep',
  address = 'address',
  number = 'number',
  state = 'state',
  complement = 'complement',
  city = 'city',
  neighborhood = 'neighborhood',
  date = 'date',
  hour = 'hour',
  password = 'password',
  confPassword = 'confPassword',
  idCompany = 'idCompany',
  profile = 'profile',
}

export interface IValidators {
  [ValidatorsNames.cpf]?: string;
  [ValidatorsNames.name]?: string;
  [ValidatorsNames.socialName]?: string;
  [ValidatorsNames.phone]?: string;
  [ValidatorsNames.phone2]?: string;
  [ValidatorsNames.dateBirth]?: string;
  [ValidatorsNames.date]?: string;
  [ValidatorsNames.state]?: string;
  [ValidatorsNames.email]?: string;
  [ValidatorsNames.status]?: string;
  [ValidatorsNames.login]?: string;
  [ValidatorsNames.cep]?: string;
  [ValidatorsNames.address]?: string;
  [ValidatorsNames.number]?: string;
  [ValidatorsNames.complement]?: string;
  [ValidatorsNames.city]?: string;
  [ValidatorsNames.neighborhood]?: string;
  [ValidatorsNames.name]?: string;
  [ValidatorsNames.hour]?: string;
  [ValidatorsNames.password]?: string;
  [ValidatorsNames.confPassword]?: string;
  [ValidatorsNames.idCompany]?: string;
  [ValidatorsNames.profile]?: string;
}

const GLOBAL_ERRORS_MESSAGES = {
  fillField: 'Preencha este campo.',
  invalid: 'Valor inválido',
};

const NAME_ERRORS_MESSAGES = {
  enterName: 'Informe seu nome completo.',
};

export const EMAIL_ERRORS_MESSAGES = {
  invalid: 'E-mail inválido.',
  different: 'E-mails diferentes, os e-mails devem ser iguais.',
};

const PHONE_ERRORS_MESSAGES = {
  invalidDDD: 'DDD informado inválido.',
  invalid: 'Número inválido',
};

const CPF_ERRORS_MESSAGES = {
  invalid: 'CPF inválido.',
};

const CC_ERRORS_MESSAGES = {
  invalid: 'Cartão de crédito inválido.',
};

const DATE_BIRTH_ERRORS_MESSAGES = {
  minor: 'Idade abaixo do necessário.',
  invalid: 'Data inválida.',
};

export const PASSWORD_ERRORS_MESSAGES = {
  length: 'A senha deverá conter 6 caracteres.',
  invalid:
    'Senha inválida, a senha deverá conter: letras maiúsculas e minusculas e caracteres alfanúmericos como @,#,?,_.',
  sequential: 'Não é permitido valores sequenciais.',
  different: 'As senhas não são iguais, por favor informe senhas idênticas.',
};

const VERIFICATION_CODE_MESSAGES = {
  length: 'O código deverá conter 7 caracteres.',
  invalid: 'Código inválido.',
};

const SOCIAL_NAME_MESSAGES = {
  invalid: 'O Nome social não pode ser igual ao nome',
};

const verifyWordExistBySearch = (value) => value !== -1;

const defaultReturn = (key: string, value: string | number) => ({
  [key]: value,
});

export const verifySequencialLetters = (value) => {
  const verifyAscending = (array, cont = 0) => {
    if (cont === 6 && cont === array.length) {
      return true;
    }

    if (cont === 0 || Number(array[cont]) === Number(array[cont - 1]) + 1) {
      return verifyAscending(array, cont + 1);
    }
    return false;
  };

  const verifyDescending = (array, cont = 0) => {
    if (cont === 6 && cont === array.length) {
      return true;
    }

    if (cont === 0 || Number(array[cont]) + 1 === Number(array[cont - 1])) {
      return verifyDescending(array, cont + 1);
    }
    return false;
  };

  const arrayNumbers = value.split('');
  return verifyDescending(arrayNumbers) || verifyAscending(arrayNumbers);
};

export const defaultValidator = (
  value = '',
  keyName: string,
  length?: number,
) => {
  if (!value.length)
    return defaultReturn(keyName, GLOBAL_ERRORS_MESSAGES.fillField);

  if (length && value.length !== length) {
    return defaultReturn(keyName, GLOBAL_ERRORS_MESSAGES.invalid);
  }
  return defaultReturn(keyName, null);
};

export const emailValidator = (value = '') => {
  const isValid = /^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([a-zA-Z0-9]+)(([.-]?[a-zA-Z0-9]+)*)\.([A-Za-z]){2,}$/.test(
    value,
  );

  if (isValid) return defaultReturn(ValidatorsNames.email, null);
  return defaultReturn(ValidatorsNames.email, EMAIL_ERRORS_MESSAGES.invalid);
};

export const cpfValidator = (value = '') => {
  if (!value.length)
    return defaultReturn(ValidatorsNames.cpf, GLOBAL_ERRORS_MESSAGES.fillField);
  if (value.length === 14) {
    return defaultReturn(ValidatorsNames.cpf, null);
  }
  return defaultReturn(ValidatorsNames.cpf, CPF_ERRORS_MESSAGES.invalid);
};

export const dateValidator = (value = '', keyName?: string) => {
  if (!value.length)
    return defaultReturn(keyName, GLOBAL_ERRORS_MESSAGES.fillField);

  if (value.length === 10) return defaultReturn(keyName, null);

  return defaultReturn(keyName, DATE_BIRTH_ERRORS_MESSAGES.invalid);
};

export const phoneValidator = (value = '', keyName?: string) => {
  const KEY = keyName || ValidatorsNames.phone;
  if (!value.length)
    return defaultReturn(KEY, GLOBAL_ERRORS_MESSAGES.fillField);
  if (value.length === 15 || value.length === 14) {
    const ddd = Number(value.slice(1, 3));
    const dddExist = DDDs.some((el) => el === ddd);

    if (dddExist) return defaultReturn(KEY, null);
    return defaultReturn(KEY, PHONE_ERRORS_MESSAGES.invalidDDD);
  }
  return defaultReturn(KEY, PHONE_ERRORS_MESSAGES.invalid);
};

export const passwordValidator = (value = '') => {
  if (!value.length)
    return defaultReturn(
      ValidatorsNames.password,
      GLOBAL_ERRORS_MESSAGES.fillField,
    );

  if (value.length === 6) {
    if (verifySequencialLetters(value)) {
      return defaultReturn(
        ValidatorsNames.password,
        PASSWORD_ERRORS_MESSAGES.sequential,
      );
    }
    return defaultReturn(ValidatorsNames.password, null);
  }
  return defaultReturn(
    ValidatorsNames.password,
    PASSWORD_ERRORS_MESSAGES.length,
  );
};

export const confPasswordValidator = (
  password: string,
  confPassword: string,
) => {
  if (password === confPassword)
    return defaultReturn(ValidatorsNames.confPassword, null);

  return defaultReturn(
    ValidatorsNames.confPassword,
    PASSWORD_ERRORS_MESSAGES.different,
  );
};

export const socialNameValidator = (socialName: string, name: string) => {
  if (!socialName || !name) {
    return defaultReturn(ValidatorsNames.socialName, null);
  }

  if (socialName.trim().toUpperCase() === name.trim().toUpperCase()) {
    return defaultReturn(
      ValidatorsNames.socialName,
      SOCIAL_NAME_MESSAGES.invalid,
    );
  }

  return defaultReturn(ValidatorsNames.socialName, null);
};

export const nameValidator = (value = '') => {
  if (!value.length)
    return defaultReturn(
      ValidatorsNames.name,
      GLOBAL_ERRORS_MESSAGES.fillField,
    );

  const word = value.split(' ');
  if (word.length > 1 && word[0].length && word[1].length)
    return defaultReturn(ValidatorsNames.name, null);

  return defaultReturn(ValidatorsNames.name, NAME_ERRORS_MESSAGES.enterName);
};

export const verifyHasLength = (value) => value.length > 0;

export const verifyLengthIsBigger = (value, length) => value.length > length;

export const verifyLengthIsSmaller = (value, length) => value.length < length;

export const verifyLengthIsEqual = (value = '', length) =>
  value.length === length;

export const cpfValidation = (value = '') => value.length === 11;

export const passwordValidation = (value = '') => value.length === 6;

export const creditCardValidation = (value = '') => value.length === 16;

export const cvvValidation = (value = '') => value.length === 3;

export const expirationDateValidation = (value = '') => value.length === 4;

export const isDateValid = (dateString = '') => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const [day, month, year] = dateString.split('/').map(Number);

  if (month < 1 || month > 12) {
    return false;
  }

  const daysInMonth = new Date(year, month, 0).getDate();
  if (day < 1 || day > daysInMonth) {
    return false;
  }

  if (year < 0) {
    return false;
  }

  return true;
};
