import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Button from '~/components/button';
import endpoints from '~/services/endpoints';
import * as St from './styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Container from '~/components/container';
import Select from '~/components/select';

const TIPO_RELATORIO = [
  {
    value: 'Cancelamento - Previsão',
    id: 'CANCELAMENTO_PREVISAO',
  },
  {
    value: 'Cancelamento - Execução',
    id: 'CANCELAMENTO_EXECUCAO',
  },
];

const ActiveLimits: React.FC = () => {
  const [error, setError] = useState<string | null>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [form, setForm] = useState({
    tipoRelatorio: '',
  });

  const RequestExecutionFileDownload = useApi<Blob>(
    endpoints.files.getCancelInactivityReport,
    ApiMethod.GET,
  );
  const RequestPrevisionFileDownload = useApi<Blob>(
    endpoints.files.getPrevisionCancelInactivityReport,
    ApiMethod.GET,
  );

  const onClickDownloadExcel = async () => {
    try {
      let responseReport;
      switch (form.tipoRelatorio) {
        case 'CANCELAMENTO_EXECUCAO':
          responseReport = await RequestExecutionFileDownload.callApi();
          break;
        case 'CANCELAMENTO_PREVISAO':
          responseReport = await RequestPrevisionFileDownload.callApi();
          break;
        default:
          null;
      }

      if (responseReport.status >= 200 && responseReport.status < 300) {
        const binary = atob(responseReport.data.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        const contentType = 'text/csv;charset=UTF-8';

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        const url = window.URL.createObjectURL(
          new Blob(['\ufeff', view], { type: contentType }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', responseReport.data.filename);
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        setDialogIsOpen(true);
        setError('Não foi possível baixar o arquivo..');
      }
    } catch (error) {
      setDialogIsOpen(true);
      setError('Não há arquivos.');
    }
  };

  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={error}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Select
                label="Tipo relatório"
                value={form.tipoRelatorio}
                options={TIPO_RELATORIO}
                onChange={(e) => setForm({ ...form, tipoRelatorio: e })}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Button
                fitContent
                loading={
                  RequestExecutionFileDownload.loading ||
                  RequestPrevisionFileDownload.loading
                }
                onClick={onClickDownloadExcel}
                disabled={!form.tipoRelatorio}
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row space>
            {error && (
              <ContainerFlex center marginTop={30}>
                <St.TitleCategory>{error}</St.TitleCategory>
              </ContainerFlex>
            )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ActiveLimits;
