import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as St from '../../styles';
import Container from '~/components/container';
import Collapse from '~/components/collapse';
import ClientData from '../Components/ClientData';
import ContainerFlex from '~/components/containerFlex';
import Button from '~/components/button';
import { IEvent, IProduct } from '~/contexts/leads';
import SharedServiceContext from '../Services/sharedServiceContext';
import { SharedBenefitServiceContext } from '../Benefits/Services/sharedBenefitServiceContext';
import Loading from '~/components/loading';

export const RequestEvent = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const {
    cpfGlobal,
    setCpfGlobal,
    nameGlobal,
    setNameGlobal,
    setProduct,
    setEvent,
    loadingGlobal,
    availableProducts,
    cardInfos,
    getClientInformation,
    getAvailableProducts,
    getInfoCards,
    getPhoto,
    postIncludeEvent,
    product,
    event,
    resetContext,
  } = useContext(SharedServiceContext);
  const { searchUser } = useContext(SharedBenefitServiceContext);
  const [typeProduct, setTypeProduct] = useState<IProduct>({
    id: '',
    value: '',
  });
  const [typeEvent, setTypeEvent] = useState<IEvent>({ id: '', value: '' });
  const [typeButton, setTypeButton] = useState('');

  useEffect(() => {
    return () => {
      resetContext();
    };
  }, []);

  useEffect(() => {
    handleSetTypeProduct({ id: 'BENEFICIO', value: 'Benefício' });
    handleSetTypeEvent({ id: 'LEAD', value: 'Lead' });
  }, []);

  useEffect(() => {
    if (state) {
      setCpfGlobal(state?.cpf);
    }
  }, [state]);

  useEffect(() => {
    if (state) {
      setNameGlobal(state?.nome);
    }
  }, [state]);

  useEffect(() => {
    if (cpfGlobal || nameGlobal) {
      getClientInformation();
      getAvailableProducts();
      getInfoCards();
      getPhoto();
    }
  }, [cpfGlobal, nameGlobal]);

  useEffect(() => {
    if (typeProduct && typeEvent) {
      setProduct(typeProduct);
      setEvent(typeEvent);
    }
  }, [typeProduct, typeEvent]);

  const handleSetTypeProduct = (typeProduct: IProduct) => {
    setTypeProduct(typeProduct);
  };

  const handleSetTypeEvent = (typeEvent: IEvent) => {
    setTypeEvent(typeEvent);
  };

  useEffect(() => {
    if (product && event && typeButton === 'START_EVENT') {
      if (product.id === 'BENEFICIO') {
        searchUser();
      } else {
        postIncludeEvent();
      }
    }
  }, [product, event, typeButton]);

  return (
    <>
      <Loading status={loadingGlobal.getUserInfo.loading} />
      <Container>
        <St.BoxCard>
          <Collapse collapseHeight={70} initialState="open" title="1. Cliente">
            <ClientData
              solicitation
              cardInfos={cardInfos}
              availableProducts={availableProducts}
              onTypeProduct={handleSetTypeProduct}
              onTypeEvent={handleSetTypeEvent}
              typeFlux="SOLICITATION"
            />
          </Collapse>
          <ContainerFlex row gap="10" end marginTop={20}>
            <Button
              outlined
              onClick={() => {
                history.goBack();
              }}
            >
              Voltar
            </Button>
            <Button
              loading={loadingGlobal.includeEvent.loading}
              onClick={() => setTypeButton('START_EVENT')}
            >
              Continuar
            </Button>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};
