import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';
import Button from '~/components/button';
import endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import * as St from './styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { icons } from '~/assets';
import Dialog from '~/components/dialog';
import Container from '~/components/container';

interface Form {
  dataVencimento: FormField;
  mesReferencia: FormField;
  ano: FormField;
}

interface IDownloadFileCharge {
  base64: string;
  filename: string;
}

const FileCharge: React.FC = () => {
  const [form, setForm] = useState<Form>({
    dataVencimento: { name: 'dataVencimento', value: '' },
    mesReferencia: { name: 'mesReferencia', value: '' },
    ano: { name: 'ano', value: '' },
  });

  const [error, setError] = useState<string | null>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const DATAS_VENCIMENTO = [
    { id: '01', value: '01' },
    { id: '05', value: '05' },
    { id: '10', value: '10' },
    { id: '15', value: '15' },
    { id: '20', value: '20' },
    { id: '25', value: '25' },
  ];

  const MES_REFERENCIA = [
    { id: '01', value: '01' },
    { id: '02', value: '02' },
    { id: '03', value: '03' },
    { id: '04', value: '04' },
    { id: '05', value: '05' },
    { id: '06', value: '06' },
    { id: '07', value: '07' },
    { id: '08', value: '08' },
    { id: '09', value: '09' },
    { id: '10', value: '10' },
    { id: '11', value: '11' },
    { id: '12', value: '12' },
  ];

  const ANOS = [
    { id: '2021', value: '2021' },
    { id: '2022', value: '2022' },
    { id: '2023', value: '2023' },
  ];
  // Request para API chamando o endpoint da criação do relatório
  const RequestFileDownload = useApi<IDownloadFileCharge>(
    endpoints.report.getReportFileChargeDownload.replace(
      'PARAM_DAY',
      `${form.dataVencimento.value}`,
    ),
    ApiMethod.GET,
  );

  // Método para consultar o retorno do relatório e fazer
  // o download automático do arquivo em XLS
  const onClickDownloadExcel = async () => {
    try {
      const responseReport = await RequestFileDownload.callApi();

      if (responseReport.status >= 200 && responseReport.status <= 300) {
        const binary = atob(responseReport.data.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        const contentType = 'application/vnd.ms-excel';

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }
        const url = window.URL.createObjectURL(
          new Blob([view], { type: contentType }),
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', responseReport.data.filename);
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        setDialogIsOpen(true);
        setError(responseReport.mensagem || 'Não há arquivos para esta data.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={error}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Select
                label="Data de vencimento"
                name={form.dataVencimento.name}
                value={form.dataVencimento.value}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.dataVencimento.name, value: e } },
                    form,
                    setForm,
                  );
                }}
                options={DATAS_VENCIMENTO}
              />
            </ContainerFlex>

            {/* <ContainerFlex padding={16}>
              <Select
                label="Mês de referência"
                name={form.mesReferencia.name}
                value={form.mesReferencia.value}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.mesReferencia.name, value: e } },
                    form,
                    setForm,
                  );
                }}
                options={MES_REFERENCIA}
              />
            </ContainerFlex> */}

            {/* <ContainerFlex padding={16}>
              <Select
                label="Ano"
                name={form.ano.name}
                value={form.ano.value}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.ano.name, value: e } },
                    form,
                    setForm,
                  );
                }}
                options={ANOS}
              />
            </ContainerFlex> */}

            <ContainerFlex padding={16}>
              <Button
                fitContent
                loading={RequestFileDownload.loading}
                onClick={onClickDownloadExcel}
                disabled={
                  !form.dataVencimento.value &&
                  !form.mesReferencia.value &&
                  !form.ano.value
                }
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <br />

          {error && (
            <ContainerFlex center marginTop={30}>
              <St.TitleCategory>{error}</St.TitleCategory>
            </ContainerFlex>
          )}
        </St.BoxCard>
      </Container>
    </>
  );
};

export default FileCharge;
