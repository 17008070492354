import React, { useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import { IReducers } from '~/contexts/proposal';
import { useProposal } from '~/hooks/contexts/useProposal';
import { IReferenceII } from '~/models/proposal';
import { RELATION_TYPES } from '~/models/proposta-contatos-ref';

const ProposalReferenceII = ({ hasReference }) => {
  const [form, setForm] = useState<IReferenceII>({
    comentarioRefII: '',
    nomeRefeII: '',
    phoneRefeII: { masked: '', unmasked: '' },
    relacaoCliII: '',
  });

  const { referenceII, addReferenceII } = useProposal();

  useEffect(() => {
    if (referenceII) {
      const r = referenceII as IReducers;
      setForm(r?.referenceII);
    } else {
      setForm(getEmptyObj());
    }
  }, [referenceII]);

  const getEmptyObj = () => ({
    comentarioRefII: '',
    nomeRefeII: '',
    phoneRefeII: { masked: '', unmasked: '' },
    relacaoCliII: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
    addReferenceII({ ...referenceII, referenceII: { ...form, [name]: value } });
  };

  const handleSelect = (value, name) => {
    setForm({ ...form, [name]: value });
    addReferenceII({ ...referenceII, referenceII: { ...form, [name]: value } });
  };

  return (
    <ContainerFlex marginLeft={10}>
      <Input
        label="Nome completo"
        name="nomeRefeII"
        value={form?.nomeRefeII}
        onChange={handleChange}
      />
      <Input
        label="Telefone"
        name="phoneRefeII"
        value={form?.phoneRefeII?.masked}
        maxLength={15}
        mask={MaskTypes.PHONE}
        onChange={handleChange}
      />
      <Select
        label="Relação com o cliente"
        value={form?.relacaoCliII}
        name="relacaoCliII"
        onChange={(val) => handleSelect(val, 'relacaoCliII')}
        options={RELATION_TYPES}
      />
      <Input
        label="Comentário"
        name="comentarioRefII"
        value={form?.comentarioRefII}
        onChange={handleChange}
      />
    </ContainerFlex>
  );
};

export default ProposalReferenceII;
