/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prettier/prettier */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiUsers } from 'react-icons/fi';
import { BsPatchCheck } from 'react-icons/bs';
import { MdOutlinePriceCheck } from 'react-icons/md';
import { TbReportMoney } from 'react-icons/tb';
import { IconContext } from 'react-icons';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { returnMonth } from '~/utils/date';
import { abbreviateNumber, currencyMask } from '~/utils/masks';
import Carousel from '../carouselMonth';

import * as S from '../../styles';
import ContainerFlex from '~/components/containerFlex';
import Badge from '~/components/Badge';
import LoadingComponents from '~/components/loadingInComponents';

interface IBoxGraphcic {
  adesoesOdonto: number;
  adesoesPrestamista: number;
  adesoesBolsaProtegida: number;
  adesoesPerdaRenda: number;
  adesoesTeucardCuida: number;
  aprovadas: number;
  ativos: number;
  faturaPaga: number;
  faturamentoCompras: number;
  adesoesSeguros: number;
  dataHoraColeta: string;
  adesoesPlatinum: number;
}

interface IEvolution {
  clientes: {
    evolucao: string;
    percentual: number;
  };
  faturamentoCompras: {
    evolucao: string;
    percentual: number;
  };
  pagamentoFaturas: {
    evolucao: string;
    percentual: number;
  };
  propostas: {
    evolucao: string;
    percentual: number;
  };
  vendasOdonto: {
    evolucao: string;
    percentual: number;
  };
}

const BoxMonth: React.FC = () => {
  const [data, setData] = useState<IBoxGraphcic>({
    adesoesOdonto: 0,
    adesoesPrestamista: 0,
    adesoesBolsaProtegida: 0,
    adesoesPerdaRenda: 0,
    adesoesTeucardCuida: 0,
    aprovadas: 0,
    ativos: 0,
    faturaPaga: 0,
    faturamentoCompras: 0,
    dataHoraColeta: '',
    adesoesSeguros: 0,
    adesoesPlatinum: 0,
  });
  const [evolution, setEvolution] = useState<IEvolution>({
    clientes: {
      evolucao: '',
      percentual: 0,
    },
    faturamentoCompras: {
      evolucao: '',
      percentual: 0,
    },
    pagamentoFaturas: {
      evolucao: '',
      percentual: 0,
    },
    propostas: {
      evolucao: '',
      percentual: 0,
    },
    vendasOdonto: {
      evolucao: '',
      percentual: 0,
    },
  });

  const history = useHistory();

  const RequestEvolutionMonth = useApi(
    Endpoints.graphc.getEvolutionMonth,
    ApiMethod.GET,
  );

  useEffect(() => {
    loadingData();
    loadingEvolution();
  }, []);

  const buildDateInit = () => {
    if (moment().format('DD') === '01') {
      return moment().subtract(1, 'month').format('YYYY-MM-DD');
    }
    return moment().startOf('month').format('YYYY-MM-DD');
  };

  const ResquestPosBoxMonthly = useApi(
    Endpoints.graphc.postBoxMonthly,
    ApiMethod.POST,
  );

  const loadingData = async () => {
    const payload = {
      dataFinal: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      dataInicial: buildDateInit(),
    };

    try {
      const response = await ResquestPosBoxMonthly.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setData(response.data as IBoxGraphcic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadingEvolution = async () => {
    try {
      const response = await RequestEvolutionMonth.callApi();
      if (response.status === 200 && response.status < 300) {
        setEvolution(response.data as IEvolution);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dateLoading = `${returnMonth(moment().month())} até ${moment().format(
    'DD/MM/YYYY',
  )}`;

  return (
    <S.MonthCards>
      <S.MonthCardItem
        onClick={() => {
          history.push('/dashDetailsProposal', {
            dataFinal: moment().format('YYYY-MM-DD'),
            dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
            type: 'Aprovadas',
            subTitle: `Mês de ${returnMonth(
              moment(data?.dataHoraColeta).month(),
            )}. Até: ${moment(data?.dataHoraColeta)
              .subtract(1, 'days')
              .format('DD/MM/YYYY')}`,
            range: 'mensal',
          });
        }}
      >
        <LoadingComponents
          status={
            ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
          }
        />
        <header>
          <h2>Propostas Aprovadas</h2>
          <span>
            {ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
              ? dateLoading
              : `${returnMonth(
                  moment(data?.dataHoraColeta)
                    .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                    .month(),
                )} até ${moment(data?.dataHoraColeta)
                  .subtract(1, 'days')
                  .format('DD/MM/YYYY')}`}
          </span>
        </header>
        <ContainerFlex row space end>
          {data.aprovadas <= 0 ? (
            <p>* Dados não encontrados</p>
          ) : (
            <>
              <S.Data>{data.aprovadas}</S.Data>
              <Badge
                percentage={evolution.propostas.percentual}
                evolution={evolution.propostas.evolucao}
                onClick={(e) => {
                  e?.stopPropagation();

                  history.push('/dashProjection', {
                    title: 'Propostas Aprovadas',
                    dataAprovacao: moment(
                      data.dataHoraColeta,
                      'YYYY-MM-DD',
                    ).format('YYYY-MM-DD'),
                    type: 'APROVADA',
                  });
                }}
              />
            </>
          )}
        </ContainerFlex>
        <IconContext.Provider value={{ className: 'react-icons green' }}>
          <BsPatchCheck />
        </IconContext.Provider>
      </S.MonthCardItem>

      <S.MonthCardItem
        onClick={() => {
          history.push('/dashDetailsClientBase', {
            type: 'Aprovadas',
            subTitle: `Mês de ${returnMonth(
              moment(data?.dataHoraColeta)
                .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                .month(),
            )} até ${moment(data?.dataHoraColeta)
              .subtract(1, 'days')
              .format('DD/MM/YYYY')}`,
            range: 'mensal',
          });
        }}
        data-type="clientesAtivos"
      >
        <LoadingComponents
          status={
            ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
          }
        />
        <header>
          <h2>Clientes Ativos</h2>
        </header>

        <ContainerFlex row space end>
          {data.ativos <= 0 ? (
            <p>* Dados não encontrados</p>
          ) : (
            <>
              <S.Data>{data.ativos}</S.Data>
              <Badge
                percentage={evolution.clientes.percentual}
                evolution={evolution.clientes.evolucao}
                onClick={(e) => {
                  e?.stopPropagation();
                  history.push('/dashProjection', {
                    title: 'Clientes Ativos',
                    dataAprovacao: moment(
                      data.dataHoraColeta,
                      'YYYY-MM-DD',
                    ).format('YYYY-MM-DD'),
                    type: 'ATIVOS',
                  });
                }}
              />
            </>
          )}
        </ContainerFlex>
        <IconContext.Provider value={{ className: 'react-icons blue' }}>
          <FiUsers />
        </IconContext.Provider>
      </S.MonthCardItem>

      <S.MonthCardItemNoHover>
        <LoadingComponents
          status={
            ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
          }
        />
        <header>
          <h2>Pagamentos de Fatura</h2>
          <span>
            {ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
              ? dateLoading
              : `${returnMonth(
                  moment(data?.dataHoraColeta)
                    .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                    .month(),
                )} até ${moment(data?.dataHoraColeta)
                  .subtract(1, 'days')
                  .format('DD/MM/YYYY')}`}
          </span>
        </header>

        <ContainerFlex row space end>
          {data.faturaPaga <= 0 ? (
            <p>* Dados não encontrados</p>
          ) : (
            <>
              <S.Data>{`R$ ${abbreviateNumber(data.faturaPaga)}`}</S.Data>
              <Badge
                percentage={evolution.pagamentoFaturas.percentual}
                evolution={evolution.pagamentoFaturas.evolucao}
                onClick={() => {
                  history.push('/dashProjection', {
                    title: 'Pagamentos de Fatura',
                    dataAprovacao: moment(
                      data.dataHoraColeta,
                      'YYYY-MM-DD',
                    ).format('YYYY-MM-DD'),
                    type: 'PAGAMENTO_FATURAS',
                  });
                }}
              />
            </>
          )}
        </ContainerFlex>
        <IconContext.Provider value={{ className: 'react-icons orange' }}>
          <MdOutlinePriceCheck />
        </IconContext.Provider>
      </S.MonthCardItemNoHover>

      <S.MonthCardItemNoHover>
        <LoadingComponents
          status={
            ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
          }
        />
        <header>
          <h2>Faturamento de Compras</h2>
          <span>
            {ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
              ? dateLoading
              : `${returnMonth(
                  moment(data?.dataHoraColeta)
                    .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                    .month(),
                )} até ${moment(data?.dataHoraColeta)
                  .subtract(1, 'days')
                  .format('DD/MM/YYYY')}`}
          </span>
        </header>

        <ContainerFlex row space end>
          {data.faturamentoCompras <= 0 ? (
            <p>* Dados não encontrados</p>
          ) : (
            <>
              <S.Data>
                {`R$ ${abbreviateNumber(data.faturamentoCompras)}`}
              </S.Data>
              <Badge
                percentage={evolution.faturamentoCompras.percentual}
                evolution={evolution.faturamentoCompras.evolucao}
                onClick={() => {
                  history.push('/dashProjection', {
                    title: 'Faturamento de Compras',
                    dataAprovacao: moment(
                      data.dataHoraColeta,
                      'YYYY-MM-DD',
                    ).format('YYYY-MM-DD'),
                    type: 'FATURAMENTO_COMPRAS',
                  });
                }}
              />
            </>
          )}
        </ContainerFlex>
        <IconContext.Provider value={{ className: 'react-icons red' }}>
          <TbReportMoney />
        </IconContext.Provider>
      </S.MonthCardItemNoHover>

      <Carousel>
        <S.MonthCardItemCarousel
          onClick={() => {
            history.push('/dashDetailsAdhesions', {
              dataFinal: moment().format('YYYY-MM-DD'),
              dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
              subTitle: `Mês de ${returnMonth(
                moment(data?.dataHoraColeta).month(),
              )}. Até: ${moment(data?.dataHoraColeta)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')}`,
              range: 'mensal',
              title: 'Detalhes mensal: Odonto',
              idBeneficio: ['60e79f33140dc50008243302'],
            });
          }}
        >
          <LoadingComponents
            containerColor="236, 241, 255"
            status={
              ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
            }
          />
          <header>
            <h2>Odonto</h2>
            <span>
              {ResquestPosBoxMonthly.loading || RequestEvolutionMonth.loading
                ? dateLoading
                : `${returnMonth(
                    moment(data?.dataHoraColeta)
                      .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                      .month(),
                  )} até ${moment(data?.dataHoraColeta)
                    .subtract(1, 'days')
                    .format('DD/MM/YYYY')}`}
            </span>
          </header>

          <ContainerFlex row space marginBottom={24}>
            {data.adesoesOdonto <= 0 ? (
              <p>* Dados não encontrados</p>
            ) : (
              <>
                <S.Data>{data.adesoesOdonto}</S.Data>
                <Badge
                  percentage={evolution.vendasOdonto.percentual}
                  evolution={evolution.vendasOdonto.evolucao}
                  onClick={() => {
                    history.push('/dashProjection', {
                      title: 'Vendas Odonto',
                      dataAprovacao: moment(
                        data.dataHoraColeta,
                        'YYYY-MM-DD',
                      ).format('YYYY-MM-DD'),
                      type: 'ODONTO',
                    });
                  }}
                />
              </>
            )}
          </ContainerFlex>
        </S.MonthCardItemCarousel>

        <S.MonthCardItemCarousel
          onClick={() => {
            history.push('/dashDetailsAdhesions', {
              dataFinal: moment().format('YYYY-MM-DD'),
              dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
              subTitle: `Mês de ${returnMonth(
                moment(data?.dataHoraColeta).month(),
              )}. Até: ${moment(data?.dataHoraColeta)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')}`,
              range: 'mensal',
              title: 'Detalhes mensal: Protege',
              idBeneficio: [
                '633f11e39cda3f1b65227d44',
                '633f11e39cda3f1b65227d45',
                '633f11e39cda3f1b65227d46',
              ],
            });
          }}
        >
          <header>
            <h2>Protege</h2>
            <span>
              {`${returnMonth(
                moment(data?.dataHoraColeta)
                  .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                  .month(),
              )} até ${moment(data?.dataHoraColeta)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')}`}
            </span>
          </header>

          <ContainerFlex row space marginBottom={24}>
            {data.adesoesSeguros <= 0 ? (
              <p>* Dados não encontrados</p>
            ) : (
              <>
                <S.Data>{data.adesoesSeguros}</S.Data>
              </>
            )}
          </ContainerFlex>
        </S.MonthCardItemCarousel>

        <S.MonthCardItemCarousel
          onClick={() => {
            history.push('/dashDetailsAdhesions', {
              dataFinal: moment().format('YYYY-MM-DD'),
              dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
              subTitle: `Mês de ${returnMonth(
                moment(data?.dataHoraColeta).month(),
              )}. Até: ${moment(data?.dataHoraColeta)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')}`,
              range: 'mensal',
              title: 'Detalhes mensal: Cuida',
              idBeneficio: ['6478d1c891d2c1eb00dd3b7f'],
            });
          }}
        >
          <header>
            <h2>Cuida</h2>
            <span>
              {`${returnMonth(
                moment(data?.dataHoraColeta)
                  .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                  .month(),
              )} até ${moment(data?.dataHoraColeta)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')}`}
            </span>
          </header>

          <ContainerFlex row space marginBottom={24}>
            {data.adesoesTeucardCuida <= 0 ? (
              <p>* Dados não encontrados</p>
            ) : (
              <>
                <S.Data>{data.adesoesTeucardCuida}</S.Data>
              </>
            )}
          </ContainerFlex>
        </S.MonthCardItemCarousel>

        <S.MonthCardItemCarousel
          onClick={() => {
            history.push('/dashDetailsAdhesions', {
              dataFinal: moment().format('YYYY-MM-DD'),
              dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
              subTitle: `Mês de ${returnMonth(
                moment(data?.dataHoraColeta).month(),
              )}. Até: ${moment(data?.dataHoraColeta)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')}`,
              range: 'mensal',
              title: 'Platinum',
            });
          }}
        >
          <header>
            <h2>Platinum</h2>
            <span>
              {`${returnMonth(
                moment(data?.dataHoraColeta)
                  .subtract(1, 'days') // Subtrai um dia antes de obter o mês
                  .month(),
              )} até ${moment(data?.dataHoraColeta)
                .subtract(1, 'days')
                .format('DD/MM/YYYY')}`}
            </span>
          </header>

          <ContainerFlex row space marginBottom={24}>
            {data.adesoesPlatinum <= 0 ? (
              <p>* Dados não encontrados</p>
            ) : (
              <>
                <S.Data>{data.adesoesPlatinum}</S.Data>
              </>
            )}
          </ContainerFlex>
        </S.MonthCardItemCarousel>
      </Carousel>
    </S.MonthCards>
  );
};

export default BoxMonth;
