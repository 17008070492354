import React from 'react';
import { MdClose, MdPhotoCamera } from 'react-icons/md';

import * as St from './styles';

interface IPictureModal {
  handleClose: () => void;
}

const PictureModal: React.FC<IPictureModal> = ({ handleClose, children }) => {
  return (
    <St.Container onClick={handleClose}>
      <St.Modal onClick={(e) => e.stopPropagation()}>
        <span id="close">
          <MdClose size={30} onClick={handleClose} />
        </span>
        {children}
      </St.Modal>
    </St.Container>
  );
};

export default PictureModal;
