/* eslint-disable import/export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';

interface IData {
  marginLeft?: number;
  maxChar?: number;
  textAlign?: string;
  percentOne?: string;
  percentTwo?: string;
}

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;

export const TitleLevel = styled.span`
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 700;
  color: #4f545c;
  margin-bottom: 8px;
`;

export const TitleLevelSmall = styled.span`
  text-align: left;
  font-size: 1em;
  color: #4f545c;
  font-weight: 300;
  padding-bottom: 12px;
  margin-bottom: 8px;
  margin-right: 16px;
  white-space: nowrap;
`;

export const ContainerSlider = styled.div`
  width: 100%;
  height: 8px;
  background-color: #4f545c;
  border-radius: 3px;
  margin-top: 32px;
  margin-bottom: 30px;
  position: relative;

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
  }
  input[type='range']::-moz-range-track {
    -moz-appearance: none;
    height: 5px;
  }
  input[type='range']::-ms-track {
    appearance: none;
    height: 5px;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 24px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    cursor: pointer;
    margin-top: -9px;
    pointer-events: auto;
  }
  input[type='range']::-moz-range-thumb {
    -moz-appearance: none;
    width: 10px;
    height: 24px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    cursor: pointer;
    pointer-events: auto;
  }
  input[type='range']::-ms-thumb {
    appearance: none;
    width: 10px;
    height: 24px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    cursor: pointer;
    pointer-events: auto;
  }
  input[type='range']:active::-webkit-slider-thumb {
    background-color: #e71037;
  }
`;

export const SliderTrack = styled.div<IData>`
  width: 100%;
  height: 5px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
`;

export const SliderTrackMetric = styled.div`
  width: 100%;

  .wrapper {
    position: relative;

    .container {
      width: 97%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: -8px;
      margin: 0 1.5%;

      .marker {
        width: 2px;
        height: 24px;
        background: #4f545c;
        display: flex;
        justify-content: space-around;
        position: relative;

        sup {
          position: absolute;
          top: -15px;
          font-size: 10px;
          font-weight: 700;
          color: #72767d;
        }
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 40px;
`;
