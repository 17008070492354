import styled from 'styled-components';

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  margin-bottom: 24px;
`;
