import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

import * as S from '../../styles';

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
  subTitle?: string;
}

const BoxBlockingReasons: React.FC<IProps> = ({
  categories,
  series,
  widthGraphic = 1000,
  heightGraphic = 300,
  subTitle = '',
}) => {
  const options = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        dataLabels: {
          orientation: 'horizontal',
          position: 'center', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 10,
      offsetY: 1,
      style: {
        fontSize: '12px',
        colors: ['#434343'],
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories || [0],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: 'light',
        color: '#5f81d1',
        shadeIntensity: 0.6,
      },
    },
  } as any;

  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>Motivos de Bloqueio</h2>
        </header>
        <S.ItemGraphic>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxBlockingReasons;
