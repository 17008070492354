import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

import * as S from '../../styles';

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
  subTitle?: string;
}

const BoxStatus: React.FC<IProps> = ({
  categories,
  series,
  widthGraphic = 1000,
  heightGraphic = 300,
  subTitle = '',
}) => {
  const options = {
    chart: {
      type: 'bar',
    },

    plotOptions: {
      bar: {
        columnWidth: '50%',
        distributed: true,
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -25,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories,
      labels: {
        show: true,
        rotate: -90,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: 100,
        maxHeight: 120,
        style: {
          colors: ['#304758'],
          fontSize: '12px',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        formatter(val) {
          return val;
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: 'light',
        color: '#1b45a7',
        shadeIntensity: 0.6,
      },
    },
  } as any;

  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>Status</h2>
        </header>
        <S.ItemGraphic>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxStatus;
