/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { AuthContext } from '~/contexts/authentication';
import Constants from '~/utils/constants';

interface SidebarIconInterface {
  isActiveMenu: boolean;
}
const SidebarLink = styled(NavLink).attrs({ activeClassName: 'active' })`
  display: flex;
  font-size: 0.875rem;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;

  svg {
    font-size: 12px;
    margin-right: 10px;
    fill: #fff;
    width: 24px;
    height: 24px;

    path {
      transform: scale(1.2);
      transform-origin: center;
    }
  }

  &.active {
    color: #c80033;

    svg {
      fill: #c80033;
      width: 24px;
      height: 24px;
    }
  }
  div {
    display: flex;
    align-items: center;

    svg {
      font-size: 24px;
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    background: #c80033;
    color: #fff;
    cursor: pointer;
  }
`;
interface IsActiveSidebarLabel {
  isActive?: boolean;
}
const SidebarLabel = styled.div<IsActiveSidebarLabel>`
  display: flex;
  font-size: 0.875rem;
  color: ${({ isActive }) => (isActive ? '#e4003a' : '#fff')};
  background: ${({ isActive }) => (isActive ? '#FFF' : '#e4003a')};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;

  svg {
    font-size: 24px;
    margin-right: 10px;
    fill: ${({ isActive }) => (isActive ? '#e4003a' : '#fff')};
    width: 24px;
    height: 24px;
  }

  &.active {
    color: #c80033;

    svg {
      fill: #c80033;
      width: 24px;
      height: 24px;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: #c80033;
    color: #fff;
    cursor: pointer;
  }
`;

const SidebarIcon = styled.div<SidebarIconInterface>`
  display: flex;
  font-size: 1rem;
  color: ${({ isActiveMenu }) => (isActiveMenu ? '#e4003a' : '#fff')};
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  background: ${({ isActiveMenu }) => isActiveMenu && '#fff'};

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 24px;
      margin-right: 7px;
      width: 24px;
      height: 24px;
      fill: ${({ isActiveMenu }) => (isActiveMenu ? '#e4003a' : '#fff')};
    }
  }

  &.active {
    color: #c80033;

    svg {
      fill: #c80033;
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    background: #c80033;
    color: #fff;
    cursor: pointer;
  }
`;

const Label = styled.span``;

const DropdownLink = styled(NavLink)`
  width: 100%;
  height: 60px;
  display: block;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  padding-left: 2.4rem;
  transition: all 0.5s;

  &:hover {
    color: #e4003a;
    cursor: pointer;
  }
`;
const SubMenu = ({ fullWidth, item }) => {
  const [subnav, setSubnav] = useState(false);
  const { handleSideBar } = useContext(AuthContext);
  const showSubnav = () => setSubnav(!subnav);
  const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));

  const location = useLocation();
  const itemLocation = location.pathname;

  const allLocations =
    item.subMenu &&
    item.subMenu.find((e) => {
      if (e.route === itemLocation) return true;
    });
  const isActiveRoute =
    (item.subMenu && allLocations) || item.route === itemLocation;

  const userHasAccess = item.roles.some((route) => {
    return userRoles.some((access) => item.roles.includes(access));
  });

  const handleUserPermissions = () => {
    item.subMenu && showSubnav();
  };

  useEffect(() => {
    if (isActiveRoute) {
      showSubnav();
    }
  }, [isActiveRoute]);

  if (fullWidth) {
    return (
      <>
        {userHasAccess && (
          <SidebarIcon isActiveMenu={isActiveRoute} onClick={handleSideBar}>
            <div>{item.icon}</div>
          </SidebarIcon>
        )}
      </>
    );
  }

  return (
    <>
      {userHasAccess ? (
        <>
          {item.subMenu ? (
            <SidebarLabel onClick={handleUserPermissions}>
              <div>
                {item.icon}
                <Label>{item.label}</Label>
              </div>
              <div>
                {item.subMenu && subnav ? (
                  <MdKeyboardArrowUp />
                ) : item.subMenu ? (
                  <MdKeyboardArrowDown />
                ) : null}
              </div>
            </SidebarLabel>
          ) : (
            <SidebarLink
              activeStyle={{ background: '#fff', color: '#e4003a' }}
              exact
              to={item.route}
              onClick={handleUserPermissions}
            >
              <div>
                {item.icon}
                <Label>{item.label}</Label>
              </div>
              <div>
                {item.subMenu && subnav ? (
                  <MdKeyboardArrowUp />
                ) : item.subMenu ? (
                  <MdKeyboardArrowDown />
                ) : null}
              </div>
            </SidebarLink>
          )}
          {subnav &&
            item.subMenu &&
            item.subMenu.map((item, index) => {
              const accessSubMenu = item.roles.some((route) => {
                return userRoles.some((access) => item.roles.includes(access));
              });
              return (
                accessSubMenu && (
                  <DropdownLink
                    exact
                    to={item.route}
                    key={index}
                    onClick={handleUserPermissions}
                  >
                    {item.icon}
                    <SidebarLabel isActive={item.route === itemLocation}>
                      <div>{item.label}</div>
                    </SidebarLabel>
                  </DropdownLink>
                )
              );
            })}
        </>
      ) : null}
    </>
  );
};

export default SubMenu;
