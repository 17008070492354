/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import ButtonSearch from '../buttonSearch';
import { components } from '~/assets';
import * as St from './styles';
import Checkbox from './Checkbox';

interface IProps {
  readonly customerHeader: any;
  list: Array<Array<Object>>;
  readonly checkbox?: boolean;
  readonly checkboxNotHeader?: boolean;
  readonly orderBy?: string;
  onSubmitOrder?: Function;
  onSubmitSelectedRows?: Function;
  ordenationField?: string;
  onListCheckBox?: (value: any[]) => void;
  defaultChecked?: Number[];
  disableCheckbox?: boolean;
}

interface ICheckbox {
  id?: number | string;
  name?: string;
  index: number;
  selected: boolean;
}

const TableListCheckbox: React.FC<IProps> = ({
  customerHeader,
  list,
  checkbox = false,
  checkboxNotHeader = true,
  onSubmitOrder,
  orderBy,
  onSubmitSelectedRows,
  ordenationField,
  onListCheckBox,
  defaultChecked,
  disableCheckbox = false,
}) => {
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [lista, setList] = useState([] as ICheckbox[]);

  useEffect(() => {
    if (!defaultChecked) return;
    setIsCheck(defaultChecked);
  }, [defaultChecked]);

  const optionCheckBoxRef = useRef<ICheckbox[]>();

  const handleSubmitOrder = (column: String) => {
    if (orderBy === 'ASC') {
      onSubmitOrder({
        order: 'DESC',
        column,
      });
    } else {
      onSubmitOrder({
        order: 'ASC',
        column,
      });
    }
  };

  useEffect(() => {
    if (customerHeader.includes((e) => e.type === 'id')) return;
    customerHeader.unshift({ id: 0, value: '', hidden: true });
    setHeader(customerHeader);
  }, [customerHeader]);

  useEffect(() => {
    let id = 0;
    list.forEach((subArray) => {
      if (subArray[0]?.type === 'id') return;
      subArray.unshift({ type: 'id', value: id++ });
    });
    setData(list);

    if (list.length >= 1 && !optionCheckBoxRef.current) {
      const optionCheckBox = [] as ICheckbox[];

      list?.forEach((i, index) => {
        const item = {
          index,
          id: index,
          selected: false,
        };

        optionCheckBox.push(item);
        optionCheckBoxRef.current = optionCheckBox;
      });
    }
  }, [list]);
  useEffect(() => {
    setList(optionCheckBoxRef.current);
  }, [lista]);

  const handleSelectAll = () => {
    if (disableCheckbox) return;

    setIsCheckAll(!isCheckAll);
    setIsCheck(lista.map((li) => li.id));
    onListCheckBox(lista.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      onListCheckBox([]);
    }
  };

  const handleClick = (id: any, checked: any) => {
    if (disableCheckbox) return;
    setIsCheck([...isCheck, id]);
    onListCheckBox([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      onListCheckBox(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <ContainerFlex>
      <St.Table>
        <St.headerTable>
          <tr>
            {/* Caso o checkboxNotHeader for true nao mostra o checkbox no cabeçalho. */}
            <St.hederTitle maxChar={2} marginLeft={5}>
              {checkbox && checkboxNotHeader === false ? (
                <Checkbox
                  name="selectAll"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                />
              ) : null}
            </St.hederTitle>
            {header.map((item) => {
              if (item.hidden) return;
              return (
                <St.hederTitle
                  key={item.id}
                  maxChar={item.maxChar}
                  textAlign={item.textAlign}
                  onClick={() => item.column && handleSubmitOrder(item.column)}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: item.textAlign
                        ? item.textAlign
                        : 'center',
                    }}
                  >
                    {item.value}
                    {ordenationField && (
                      <div style={{ marginLeft: '10px' }}>
                        {item.column === ordenationField &&
                          orderBy === 'ASC' && (
                            <components.ArrowDown
                              style={{ transform: 'rotate(180deg)' }}
                            />
                          )}
                        {item.column === ordenationField &&
                          orderBy === 'DESC' && <components.ArrowDown />}
                      </div>
                    )}
                  </div>
                </St.hederTitle>
              );
            })}
          </tr>
        </St.headerTable>
        <St.bodyTable>
          {data.map((item, index) => (
            <St.bodyRow key={item[0].value}>
              {checkbox && lista ? (
                <St.bodyData maxChar={1} marginLeft={5}>
                  {/* Intendesse que quando estiver marcado como true o "checkboxNotHeader" e tbm o "checkbox" o primeiro item do checkbox e um selectAll */}
                  {index === 0 && checkboxNotHeader ? (
                    <Checkbox
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  ) : (
                    <Checkbox
                      key={lista[index]?.id}
                      name={lista[index]?.name}
                      id={lista[index]?.id}
                      handleClick={(e) => handleClick(index, e.target.checked)}
                      isChecked={isCheck.includes(lista[index]?.id)}
                    />
                  )}
                </St.bodyData>
              ) : null}
              {item.map((item) => {
                if (item.type === 'id') return;
                return (
                  <>
                    {item.type === 'icon' ? (
                      <St.bodyData
                        key={item.id}
                        maxChar={item.maxChar}
                        marginLeft={item.marginLeft}
                        textAlign={item.textAlign}
                      >
                        {item.value ? (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <St.bodyDataValue maxChar={item.maxChar}>
                                {item.value}
                              </St.bodyDataValue>
                              {item.valueColor && (
                                <St.ListRowBullet color={item.valueColor} />
                              )}
                              <St.IconRow src={item.icon} alt="icon" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              {item.valueColor && (
                                <St.ListRowBullet color={item.valueColor} />
                              )}
                              <St.IconRow src={item.icon} alt="icon" />
                            </div>
                          </>
                        )}
                      </St.bodyData>
                    ) : item.searchAction ? (
                      <St.bodyData
                        key={item.id}
                        maxChar={item.maxChar}
                        marginLeft={item.marginLeft}
                        textAlign={item.textAlign}
                      >
                        <St.ButtonContainer>
                          <ButtonSearch
                            onClick={() => {
                              item.searchAction(item.id, item.item);
                            }}
                          />
                        </St.ButtonContainer>
                      </St.bodyData>
                    ) : (
                      <St.bodyData
                        key={item.id}
                        maxChar={item.maxChar}
                        marginLeft={item.marginLeft}
                        textAlign={item.textAlign}
                        title={item.value}
                      >
                        {item.value}
                      </St.bodyData>
                    )}
                  </>
                );
              })}
            </St.bodyRow>
          ))}
        </St.bodyTable>
      </St.Table>
    </ContainerFlex>
  );
};

export default TableListCheckbox;
