/* eslint-disable default-case */
/* eslint-disable react/jsx-curly-newline */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import TableList from '~/components/tableList';
import FilterProposal from './components/FilterProposal';
import { useReportProposal } from './context/useProposal';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';

import * as S from './styles';

const ReportProposal: React.FC = () => {
  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');

  const [form, setForm] = useState({
    tipoProposta: { name: 'tipoProposta', value: '' },
    origem: 'TODOS',
    uf: 'TODOS',
    idAtendente: 'TODOS',
    primeiraCompra: 'string',
  });

  const {
    getReportProposalsRegistered,
    getReportProposalP1Approved,
    getReportProposalsDisapproved,
    getReportProposalsApproved,
    getReportProposalP1Disapproved,
    getOperador,
    items,
    totalItems,
    totalPages,
    headerItems,
    header,
    relatorioData,
    setRelatorioData,
    clearForm,
    checkSuccess,
    loading,
    operador,
  } = useReportProposal();

  useEffect(() => {
    getOperador();
  }, []);

  const payloadType = {
    CADASTRADA: {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: moment().format('YYYY-MM-DD'),
      periodoFinal: moment().format('YYYY-MM-DD'),
      uf: form.uf,
    },
    APROVADA: {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: moment().format('YYYY-MM-DD'),
      periodoFinal: moment().format('YYYY-MM-DD'),
      status: 'APROVADA',
      uf: form.uf,
    },
    REPROVADA: {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: moment().format('YYYY-MM-DD'),
      periodoFinal: moment().format('YYYY-MM-DD'),
      status: 'REPROVADA',
    },
    PROPOSTA_P1: {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: moment().format('YYYY-MM-DD'),
      periodoFinal: moment().format('YYYY-MM-DD'),
      status: 'APROVADA',
      uf: form.uf,
    },
    REPROVADA_P1: {
      origem: form.origem,
      idAtendente: form.idAtendente,
      primeiraCompra: form.primeiraCompra,
      periodoInicial: moment().format('YYYY-MM-DD'),
      periodoFinal: moment().format('YYYY-MM-DD'),
      status: 'REPROVADA',
    },
  };

  const getReport = async (page = 1) => {
    const payload = payloadType[form.tipoProposta.value];

    switch (form.tipoProposta?.value) {
      case 'CADASTRADA':
        await getReportProposalsRegistered(page, 100, false, payload);
        break;
      case 'APROVADA':
        await getReportProposalsApproved(page, 100, false, payload);
        break;
      case 'REPROVADA':
        await getReportProposalsDisapproved(page, 100, false, payload);
        break;
      case 'PROPOSTA_P1':
        await getReportProposalP1Approved(page, 100, false, payload);
        break;
      case 'REPROVADA_P1':
        await getReportProposalP1Disapproved(page, 100, false, payload);
        break;
      default:
        break;
    }
  };

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload = payloadType[form?.tipoProposta?.value];

    switch (form.tipoProposta?.value) {
      case 'CADASTRADA':
        response = await getReportProposalsRegistered(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'APROVADA':
        response = await getReportProposalsApproved(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'REPROVADA':
        response = await getReportProposalsDisapproved(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'PROPOSTA_P1':
        response = await getReportProposalP1Approved(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'REPROVADA_P1':
        response = await getReportProposalP1Disapproved(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      default:
        break;
    }

    if (response) {
      const data = response.totalDePaginas ? response : response.data;
      totalPages = data.totalDePaginas;

      const percentage = (currentPage / totalPages) * 100;
      const roundPercentage = Math.ceil(percentage);
      setDownloadCVSProgress(`${roundPercentage}%`);

      if (data?.pagina?.length) {
        setRelatorioData((prev) => [...prev, ...data?.pagina]);
      }

      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    if (form.tipoProposta.value !== '') {
      getReport();
    }
    clearForm();
  }, [form]);

  return (
    <>
      <Loading status={loading} text={downloadCVSProgress} />
      <Container>
        <FilterProposal
          operador={operador}
          onClick={(e) => {
            setForm(e);
          }}
        />

        <CSVLink
          ref={refCSV}
          data={relatorioData}
          style={{
            color: 'inherit',
            textDecoration: 'none',
          }}
          headers={header}
          filename={`relatorio_propostas_${form.tipoProposta.value}.csv`}
          target="_blank"
        />

        {items?.length > 0 ? (
          <S.BoxCard>
            <ContainerFlex flex="0.2" padding={16} marginTop={10}>
              <ContainerFlex row space center padding={24}>
                <ContainerFlex>
                  <p>
                    Resultados retornados:
                    {totalItems}
                  </p>
                </ContainerFlex>
                <Button
                  onClick={() => {
                    if (refCSV.current) {
                      onClickDownloadCSV().then(() =>
                        refCSV.current.link.click(),
                      );
                    }
                  }}
                  disabled={loading}
                >
                  Download relatório
                </Button>
              </ContainerFlex>
              <ContainerFlex>
                <TableList customerHeader={headerItems} list={items} />
                <ContainerFlex row horizontal center marginTop={20}>
                  <Pagination
                    totalPages={totalPages}
                    onCurrentPageChange={getReport}
                  />
                </ContainerFlex>
              </ContainerFlex>
              {checkSuccess() ||
                (items.length <= 0 && (
                  <ContainerFlex center>
                    <TitleCategory>Nenhum resultado encontrado</TitleCategory>
                  </ContainerFlex>
                ))}
            </ContainerFlex>
          </S.BoxCard>
        ) : null}
      </Container>
    </>
  );
};

export default ReportProposal;
