import moment from 'moment';
import { useState } from 'react';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';

interface IFilterDate {
  onClick?: (any: any) => void;
}

const FilterDate: React.FC<IFilterDate> = ({ onClick }) => {
  const [form, setForm] = useState({
    periodoInicial: '',
    periodoFinal: '',
  });

  const handleChange = (el): void => {
    onClick(el);
  };

  return (
    <ContainerFlex row space gap="20">
      <ContainerFlex>
        <Input
          label="De:"
          placeholder="DD/MM/YYYY"
          maxLength={10}
          value={form.periodoInicial}
          mask={MaskTypes.DATE}
          onChange={(e) => {
            return setForm({ ...form, periodoInicial: e.target.value.masked });
          }}
        />
      </ContainerFlex>
      <ContainerFlex marginRight={16}>
        <Input
          label="Até:"
          placeholder="DD/MM/YYYY"
          maxLength={10}
          value={form.periodoFinal}
          mask={MaskTypes.DATE}
          onChange={(e) => {
            return setForm({ ...form, periodoFinal: e.target.value.masked });
          }}
        />
      </ContainerFlex>
      <ContainerFlex flex="0.2" maxWidth={34}>
        <Button
          onClick={() => {
            handleChange(form);
          }}
        >
          <span>Consultar</span>
        </Button>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default FilterDate;
