/* eslint-disable react/jsx-no-undef */
import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import TableList from '~/components/tableList';

import useApi, { ApiMethod } from '~/hooks/useApi';

import { cpfMask, IDefaultReturn } from '~/utils/masks';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import ConsultProposalResponse from '~/services/credit-table/proposal/consult/response';
import Endpoints from '~/services/endpoints';
import { IListColCampaing } from '~/views/LimitIncrease/model';

import * as St from './styles';
import Container from '~/components/container';

interface IForm {
  nome: string;
  cpf: IDefaultReturn;
}

const ConsultLimitCT = () => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const history = useHistory();
  const [list, setList] = useState([]);

  const handleGenerateURL = useMemo(() => {
    if (form.cpf?.unmasked && form.nome) {
      return `${Endpoints.consult.searchProposalReanaliseNameCPF}?cpf=${form.cpf?.unmasked}&nome=${form.nome}`;
    }
    if (form.cpf?.unmasked && (!form.nome || form.nome === '')) {
      return `${Endpoints.consult.searchProposalReanaliseNameCPF}?cpf=${form.cpf?.unmasked}`;
    }
    if (form.nome && (!form.cpf?.unmasked || form.cpf?.unmasked === '')) {
      return `${Endpoints.consult.searchProposalReanaliseNameCPF}?nome=${form.nome}`;
    }
    return `${Endpoints.consult.searchProposalReanaliseNameCPF}`;
  }, [form.cpf, form.nome]);

  const RequestProposal = useApi<ConsultProposalResponse>(
    `${handleGenerateURL}`,
    ApiMethod.GET,
  );

  useEffect(() => {
    search(true);
  }, []);

  const customerHeader = [
    {
      id: 1,
      value: 'ID da proposta',
      maxChar: 30,
    },
    {
      id: 2,
      value: 'Nome completo',
      maxChar: 35,
    },
    {
      id: 3,
      value: 'CPF',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Status',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Data e Horário',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const search = async (isStart?: boolean) => {
    const newList = [];
    const resp = await RequestProposal.callApi();

    const dataProposal: ConsultProposalResponse = {
      buscaPropostaResponses: !isStart
        ? resp.data.buscaPropostaResponses || []
        : resp.data.buscaPropostaResponses.filter(
            (e) => e.status === 'PENDENTE - REANALISE',
          ) || [],
    };

    // let i = 0;

    if (dataProposal.buscaPropostaResponses.length > 0) {
      dataProposal.buscaPropostaResponses.forEach((data) => {
        const item: IListColCampaing[] = [
          // {
          //   type: 'id',
          //   value: i++,
          //   maxChar: 5,
          //   textAlign: 'center',
          // },
          {
            border: true,
            title: 'ID da proposta',
            value: data.id,
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Nome completo',
            value: data.nomeCliente,
            maxChar: 35,
          },
          {
            border: true,
            title: 'CPF',
            value: data.cpf ? cpfMask(data.cpf).masked : '',
            maxChar: 25,
            textAlign: 'center',
          },
          {
            title: 'Status',
            value: data.status,
            maxChar: 25,
            textAlign: 'center',
          },
          {
            title: 'Data e Horário',
            value: data.dataInclusao
              ? moment(data.dataInclusao).format('DD/MM/YYYY HH:ss')
              : '',
            maxChar: 20,
            textAlign: 'center',
          },
          {
            item: data,
            searchAction: () => goToDetail(data.id, data.cpf),
          },
        ];

        newList.push(item);
      });
    }

    setList(newList);
  };

  const goToDetail = (id, cpf) =>
    history.push('/credit-table/limit/decision/detail', { id, cpf });

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="Nome"
                name="nome"
                value={form.nome}
                onChange={handleChange}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name="cpf"
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
                value={form.cpf?.masked}
                onChange={handleChange}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                loading={RequestProposal.loading}
                fitContent
                onClick={() => search(false)}
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row space>
            {RequestProposal.data && !list.length && (
              <ContainerFlex center marginTop={30}>
                <TitleCategory>Nenhuma proposta encontrada.</TitleCategory>
              </ContainerFlex>
            )}

            {RequestProposal.status === 404 && (
              <ContainerFlex center marginTop={30}>
                <TitleCategory>{RequestProposal.data.mensagem}</TitleCategory>
              </ContainerFlex>
            )}

            {RequestProposal.status !== 404 && list.length > 0 && (
              <TableList customerHeader={customerHeader} list={list} />
            )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ConsultLimitCT;
