export const month = [
  {
    id: '01',
    value: 'Janeiro',
  },
  {
    id: '02',
    value: 'Fevereiro',
  },
  {
    id: '03',
    value: 'Março',
  },
  {
    id: '04',
    value: 'Abril',
  },
  {
    id: '05',
    value: 'Maio',
  },
  {
    id: '06',
    value: 'Junho',
  },
  {
    id: '07',
    value: 'Julho',
  },
  {
    id: '08',
    value: 'Agosto',
  },
  {
    id: '09',
    value: 'Setembro',
  },
  {
    id: '10',
    value: 'Outubro',
  },
  {
    id: '11',
    value: 'Novembro',
  },
  {
    id: '12',
    value: 'Dezembro',
  },
];

export const days = [
  {
    id: '01',
    value: '1',
  },
  {
    id: '04',
    value: '4',
  },
  {
    id: '09',
    value: '9',
  },
  {
    id: '14',
    value: '14',
  },
  {
    id: '21',
    value: '21',
  },
  {
    id: '25',
    value: '25',
  },
];

export const year = [
  {
    id: '2019',
    value: '2019',
  },
  {
    id: '2020',
    value: '2020',
  },
  {
    id: '2021',
    value: '2021',
  },
  {
    id: '2022',
    value: '2022',
  },
  {
    id: '2023',
    value: '2023',
  },
];
