import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

import * as S from '../../styles';

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  labels: string[];
  series: {
    name: string;
    data: number[];
  }[];
  subTitle?: string;
}

const BoxOrigin: React.FC<IProps> = ({
  labels,
  series,
  widthGraphic = 300,
  heightGraphic = 300,
  subTitle = '',
}) => {
  const buildCategories = () => {
    let response = null;
    if (labels.length < 0) {
      response = {
        chart: {
          width: '100%',
          height: 390,
          type: 'radialBar',
        },
        labels: [0],
        legend: {
          show: true,
          floating: true,
          position: 'bottom',
          fontSize: '12px',
          labels: {
            colors: '#000',
            useSeriesColors: false,
          },
          markers: {
            size: 0,
          },
        },
        colors: ['#1ddd8d', '#165baa', '#17bfd6', '#f765a3'],
        fill: {
          opacity: 0.8,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
          lineCap: 'round',
        },
        yaxis: {
          show: false,
        },
        plotOptions: {
          radialBar: {
            offsetY: -20,
            hollow: {
              margin: 5,
              size: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: '16px',
                formatter(val) {
                  return val.toFixed(2);
                },
              },
            },
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      } as any;
    } else {
      response = {
        chart: {
          type: 'radialBar',
        },
        labels,
        fill: {
          opacity: 0.8,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        yaxis: {
          show: true,
        },
        legend: {
          position: 'bottom',
          itemMargin: {
            vertical: 3,
          },
          labels: {
            useSeriesColors: true,
          },
        },
        plotOptions: {
          radialBar: {
            rings: {
              strokeWidth: 0,
            },
            dataLabels: {
              value: {
                show: true,
                fontSize: '16px',
                formatter(val) {
                  return val;
                },
              },
            },
          },
        },
      } as any;
    }
    return response;
  };

  const buildSeries = () => {
    let response = null;
    if (series.length <= 0) {
      response = [0];
    } else {
      response = series[0]?.data;
    }
    return response;
  };
  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>Origem</h2>
          <span>
            {subTitle !== ''
              ? subTitle
              : `Última atualização: hoje às ${moment().format(
                  'hh:mm',
                )}pm. Referente a data de ontem`}
          </span>
        </header>
        <S.ItemGraphic>
          <Chart
            options={buildCategories()}
            series={buildSeries()}
            type="radialBar"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxOrigin;
