export const PAYMENT_METHODS = [
  {
    value: 'À vista',
    id: '1',
  },
  {
    value: '2x',
    id: '2',
  },
  {
    value: '3x',
    id: '3',
  },
  {
    value: '4x',
    id: '4',
  },
  {
    value: '5x',
    id: '5',
  },
  {
    value: '6x',
    id: '6',
  },
  {
    value: '7x',
    id: '7',
  },
  {
    value: '8x',
    id: '8',
  },
  {
    value: '9x',
    id: '9',
  },
  {
    value: '10x',
    id: '10',
  },
  {
    value: '11x',
    id: '11',
  },
  {
    value: '12x',
    id: '12',
  },
];

export const TIPO_TRANSACAO = [
  {
    value: 'Transação de Cartão de Crédito',
    id: 'TRANSACAO_CARTAO_DE_CREDITO',
  },
  {
    value: 'Voucher',
    id: 'VOUCHER',
  },
  {
    value: 'Reembolso',
    id: 'REEMBOLSO',
  },
];
