import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask } from '~/utils/masks';

export const HeaderInvoiceReceived = [
  {
    id: 1,
    value: 'Nome completo',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Pagamento',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Valor',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Origem',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Unidade',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodyInvoiceReceived = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nome,
        maxChar: 25,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.dataPagamento
          ? moment(i.dataPagamento).format('DD/MM/YY')
          : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.valorPagamento)}`,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.origem ? i.origem : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.unidade ? i.unidade : '-',
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
