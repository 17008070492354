import React, { useEffect, useRef, useState } from 'react';
import { icons } from '~/assets';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Loading from '~/components/loading';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import FileItem from './components/FileItem/FileItem';
import * as St from './styles';

interface IFiles {
  id: number;
  name: string;
  content: string;
}
interface IResponse {
  mensagem?: string;
  filename?: string;
  processamento?: string;
  baixasTotal?: string;
  baixasCobranca?: string;
  baixasAutorizador?: string;
}

const FileUpload: React.FC = () => {
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailure, setModalFailure] = useState(false);
  const [items, setItems] = useState<IFiles[]>([]);
  const [conciliaData, setConciliaData] = useState<IResponse>({});
  const [menssagem, setMenssagem] = useState('');
  const [openFeed, setOpenFeed] = useState(false);
  const [openFeedConcilia, setOpenFeedConcilia] = useState(false);

  const apiUpload = useApi(Endpoints.files.upload, ApiMethod.PUT);

  const apiConciliarArquivo = useApi<IResponse>(
    Endpoints.consult.conciliarArquivo,
    ApiMethod.GET,
  );

  useEffect(() => {
    if (apiUpload.status) {
      modalDisplay();
    }
  }, [apiUpload.status]);

  const inputFileRef = useRef(null);

  const fileToBase64 = async (f) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const fileSelected = async (e) => {
    const fileValue = e.target.files[0];

    if (fileValue) {
      const { name } = fileValue;
      const base64 = (await fileToBase64(fileValue)) as string;

      const newFile = { id: Math.random() / 2, name, content: base64 };

      setItems((prev) => [...prev, newFile]);
    }
  };

  const deleteItem = (id) => {
    const newItems = [];

    items.forEach((i) => {
      if (i.id !== id) newItems.push(i);
    });

    setItems(newItems);
  };

  const triggerFilePicker = () => inputFileRef.current.click();

  const send = async () => {
    await Promise.all(
      items.map((i) => {
        if (modalFailure) return;

        const payload = {
          arquivo: i.content.split(',')[1],
          nomeArquivo: i.name,
        };

        return apiUpload.callApi(payload);
      }),
    );

    setItems([]);
  };

  async function handleClickProcessar() {
    const response = await apiConciliarArquivo.callApi();

    const data = response.data as IResponse;

    if (data[0]) {
      setConciliaData({
        baixasTotal: data[0].baixasTotal,
        baixasAutorizador: data[0].baixasAutorizador,
        baixasCobranca: data[0].baixasCobranca,
        filename: data[0].filename,
        processamento: data[0].processamento,
      });
      setOpenFeedConcilia(true);
    } else {
      setMenssagem('Não há linhas a ser processadas!');
      setOpenFeed(true);
    }
  }

  const modalDisplay = () => {
    if (apiUpload.status === 200) {
      setModalFailure(false);
      setModalSuccess(true);
    } else {
      setModalSuccess(false);
      setModalFailure(true);
    }
  };

  return (
    <>
      <St.InputFileHidden
        ref={inputFileRef}
        type="file"
        onChange={fileSelected}
      />

      <Dialog
        open={modalSuccess}
        message="Arquivos enviados com sucesso"
        positiveAction={() => setModalSuccess(false)}
        positiveLabel="Fechar"
      />

      <Dialog
        open={modalFailure}
        message="Erro ao enviar os arquivos"
        positiveAction={() => setModalFailure(false)}
        positiveLabel="Fechar"
      />

      <Dialog
        open={openFeed}
        message={menssagem}
        positiveAction={() => setOpenFeed(false)}
        positiveLabel="Fechar"
      />

      <Dialog
        open={openFeedConcilia}
        positiveAction={() => setOpenFeedConcilia(false)}
        positiveLabel="Fechar"
      >
        <span>{`Linhas processadas: ${conciliaData.baixasTotal}`}</span>
        <span>{`Linhas baixadas: ${conciliaData.baixasCobranca}`}</span>
        <span>
          {`Linhas baixadas no autorizador: ${conciliaData.baixasAutorizador}`}
        </span>
      </Dialog>

      <Loading status={apiConciliarArquivo.loading || apiUpload.loading} />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <St.BoxDrag>
                <ContainerFlex center padding={40}>
                  <St.BoxDragIcon src={icons.filesIcon} />

                  <ContainerFlex row center horizontal>
                    <span>Insira seu arquivo ou</span>

                    <St.SelectLabel onClick={triggerFilePicker}>
                      Selecione
                    </St.SelectLabel>
                  </ContainerFlex>
                </ContainerFlex>
              </St.BoxDrag>

              <ContainerFlex row>
                <Button disabled={items.length === 0} onClick={send} fitContent>
                  Enviar
                </Button>
                <Button
                  marginLeft={16}
                  onClick={handleClickProcessar}
                  fitContent
                  loading={apiConciliarArquivo.loading}
                  disabled={apiConciliarArquivo.loading}
                >
                  Processar
                </Button>
              </ContainerFlex>

              <ContainerFlex row space>
                {items.map((f) => (
                  <FileItem
                    key={f.id}
                    id={f.id}
                    name={f.name}
                    content={f.content}
                    onDeleteClick={deleteItem}
                  />
                ))}
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default FileUpload;
