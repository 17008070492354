import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  border-radius: 10px;
  position: relative;
  align-items: stretch;
  max-width: 173px;

  color: #333333;
  font-size: 1rem !important;
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

export const Label = styled.label<ContainerProps>`
  font-size: 0.875rem;
  color: #212529;
  margin-bottom: 10px;
  ${(props) =>
    props.isFocused &&
    css`
      color: #e71037;
    `}
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  height: 50%;
  background-color: #cccc;
  border-right: 1px solid #bdbdbd;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  width: 100%;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  height: 35px;
`;
export const LeftSide = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const MidSide = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const RightSide = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Value = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;

  line-height: 16px;
  color: #333333;
`;

export const ContainerOptions = styled.div<ContainerProps>`
  position: absolute;
  z-index: 100;

  width: ${({ isFocused }) => (isFocused ? '100px' : '0px')};
  height: ${({ isFocused }) => (isFocused ? '160px' : '0px')};
  text-align: left;
  transition: height 0.6s;

  overflow-y: hidden;
  top: 35px;
  font-size: 0.875rem;

  background: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 1px 7px 0px rgba(201, 201, 201, 0.5);

  > div {
    height: 100%;
    overflow-y: scroll;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background: #e4003a;
      border: 3px solid #e4003a;
    }
  }

  .option {
    padding: 10px 10px;

    :hover {
      background: #f1f1f1;
    }
  }
`;
