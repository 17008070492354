/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import moment from 'moment';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';

import useApi, { ApiMethod } from '~/hooks/useApi';

import Endpoints from '~/services/endpoints';

import { icons } from '~/assets';
import * as St from '../styles';
import {
  IDefaultReturn,
  cpfMask,
  dateMask,
  emailMask,
  phoneMask,
} from '~/utils/masks';
import RadioButton from '~/components/radioButton';
import { AnfitriaoResponse } from '..';
import Dialog from '~/components/dialog';
import ButtonsDialog from '~/components/buttonsDialog';

export interface AnfitriaoDetailsResponse {
  celular: string;
  codigo: string;
  cpf: string;
  dataCadastro: string;
  dataStatus: string;
  dataUltimaUtilizacao: string;
  email: string;
  id: string;
  link: string;
  nome: string;
  novaEra: boolean;
  perfil: string;
  status: string;
  urlDisplay: string;
  urlQRCode: string;
}

interface Form extends Omit<AnfitriaoDetailsResponse, 'celular' | 'cpf'> {
  cpf: IDefaultReturn;
  email: string;
  nome: string;
  celular: IDefaultReturn;
  novaEra: boolean;
}

const IndicationLinkDetails = (): React.ReactNode => {
  const { state } = useLocation<AnfitriaoResponse>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalFiles, setModalFiles] = useState(false);
  const [form, setForm] = useState<Form>({
    cpf: cpfMask(),
    nome: '',
    email: emailMask(''),
    celular: phoneMask(''),
    novaEra: false,
    codigo: '',
    dataCadastro: '',
    dataUltimaUtilizacao: '',
    dataStatus: '',
    id: '',
    link: '',
    perfil: '',
    status: '',
    urlDisplay: '',
    urlQRCode: '',
  });

  const GetAnfitriaoDetails = useApi<AnfitriaoDetailsResponse>(
    Endpoints.consult.getAnfitriaoDetails.replace('PARAM_ID', state.id),
    ApiMethod.GET,
  );

  const loadDataAndFillForm = async () => {
    const { data } = await GetAnfitriaoDetails.callApi();
    setForm({
      ...data,
      cpf: cpfMask(data.cpf),
      celular: phoneMask(data.celular),
      email: data.email,
      novaEra: data.novaEra,
      nome: data.nome,
    });
  };
  useEffect(() => {
    loadDataAndFillForm();
  }, [state]);

  const Cf = ContainerFlex;

  const disabledInputs =
    form.status !== 'ATIVO' && (form.perfil !== 'EXTERNO' || !form.novaEra);

  const PutChangeData = useApi(
    Endpoints.consult.putChangeAnfitriaoData.replace('PARAM_ID', state.id),
    ApiMethod.PUT,
  );

  const urlChangeStatus = (status?) =>
    Endpoints.consult.putChangeAnfitriaoStatus
      ?.replace('PARAM_ID', state.id)
      .replace('PARAM_STATUS', status);

  const PutChangeStatus = useApi(
    urlChangeStatus(form.status === 'ATIVO' ? 'EXPIRADO' : 'ATIVO'),
    ApiMethod.PUT,
    {},
    true,
  );

  const PutSendEmail = useApi(
    Endpoints.consult.putSendEmailAnfitriao.replace('PARAM_ID', state.id),
    ApiMethod.PUT,
  );

  const handleChangeStatus = async () => {
    await PutChangeStatus.callApi();
    await loadDataAndFillForm();
  };

  const handleSave = async () => {
    const response = await PutChangeData.callApi({
      celular: form.celular.unmasked,
      email: form.email,
    });
    if (response.status !== 200) {
      setModalMessage(response.mensagem);
      setModalOpen(true);
    }
    await loadDataAndFillForm();
  };

  return (
    <>
      <Container>
        <Dialog
          open={modalOpen}
          message={modalMessage}
          positiveAction={() => setModalOpen(false)}
          positiveLabel="Entendi"
        />
        <ButtonsDialog
          icon={icons.warning}
          open={modalFiles}
          title="Visualizar Arquivos"
          message="Selecione uma opção de visualização"
          oneAction={() => {
            setModalFiles(false);
            window.open(form.urlQRCode);
          }}
          oneLabel="QRCode"
          twoAction={() => {
            setModalFiles(false);
            window.open(form.urlDisplay);
          }}
          twoLabel="PDF"
          closeAction={() => setModalFiles(false)}
        />
        <Loading
          status={
            GetAnfitriaoDetails.loading ||
            PutChangeData.loading ||
            PutChangeStatus.loading
          }
        />
        <St.BoxCard>
          <Cf space>
            <Cf row space>
              <Cf padding={16}>
                <Input
                  label="CPF"
                  name="cpf"
                  value={form.cpf.masked}
                  disabled
                  placeholder="000.000.000-00"
                  maxLength={14}
                  mask={MaskTypes.CPF}
                />
              </Cf>
              <Cf padding={16}>
                <Input label="Nome" name="Nome" value={form.nome} disabled />
              </Cf>
            </Cf>

            <Cf row space>
              <Cf padding={16}>
                <Input
                  label="E-mail"
                  name="email"
                  value={form.email}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  placeholder="exemplo@exemplo.com"
                  mask={MaskTypes.EMAIL}
                  disabled={disabledInputs}
                />
              </Cf>

              <Cf padding={16}>
                <Input
                  label="Celular"
                  name="celular"
                  value={form.celular.masked}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      celular: phoneMask(e.target.value.unmasked),
                    }))
                  }
                  placeholder="(99) 99999-9999"
                  maxLength={15}
                  mask={MaskTypes.PHONE}
                  disabled={disabledInputs}
                />
              </Cf>
            </Cf>
            <Cf row space>
              <Cf padding={16}>
                <Input
                  label="Código de anfitrião"
                  name="codigo"
                  value={form.codigo}
                  disabled
                />
              </Cf>

              <Cf padding={16}>
                <Input
                  label="Data do cadastro"
                  name="dataCadastro"
                  value={
                    dateMask(
                      form.dataCadastro
                        ?.split('T')[0]
                        .split('-')
                        .reverse()
                        .join('/'),
                    ).masked
                  }
                  mask={MaskTypes.DATE}
                  disabled
                />
              </Cf>
            </Cf>
            <Cf row space>
              <Cf padding={16}>
                <Input
                  label="Status"
                  name="status"
                  value={form.status}
                  disabled
                />
              </Cf>

              <Cf padding={16}>
                <Input
                  label="Data do status"
                  name="dataDoStatus"
                  value={
                    dateMask(
                      form.dataStatus
                        ?.split('T')[0]
                        .split('-')
                        .reverse()
                        .join('/'),
                    ).masked
                  }
                  mask={MaskTypes.DATE}
                  disabled
                />
              </Cf>
            </Cf>
            <Cf row space>
              <Cf padding={16}>
                {form.status === 'ATIVO' ? (
                  <Input
                    label="Data e hora da ultima utilização"
                    name="dataUltimaUtilizacao"
                    value={moment(form.dataUltimaUtilizacao).format(
                      'DD/MM/YYYY HH:mm:ss',
                    )}
                    mask={MaskTypes.DATE}
                    disabled
                  />
                ) : null}
              </Cf>

              <Cf padding={16}>
                <Input
                  label="Perfil do Anfitrião"
                  name="perfil"
                  value={form.perfil}
                  disabled
                />
              </Cf>
            </Cf>

            <Cf row space>
              <Cf row space center>
                <Cf padding={16}>
                  <span>Funcionário nova era?</span>
                </Cf>
                <Cf row end padding={16}>
                  <Cf row horizontal align="flex-end">
                    <RadioButton
                      disabled
                      id="usuarioNovaEra"
                      onClick={() =>
                        setForm((prev) => ({
                          ...prev,
                          novaEra: true,
                        }))
                      }
                      checked={form.novaEra === true}
                    />
                    Sim
                  </Cf>
                  <Cf row horizontal align="flex-end">
                    <RadioButton
                      disabled
                      id="usuarioNovaEra"
                      onClick={() =>
                        setForm((prev) => ({
                          ...prev,
                          novaEra: false,
                        }))
                      }
                      checked={form.novaEra === false}
                    />
                    Não
                  </Cf>
                </Cf>
              </Cf>

              <Cf row padding={16}>
                <Input
                  style={{ textOverflow: 'ellipsis' }}
                  label="Link"
                  name="link"
                  value={form.link}
                  disabled
                  icon={() => (
                    <Button
                      disabled={form.status !== 'ATIVO'}
                      onClick={() => navigator.clipboard.writeText(form.link)}
                      size="small"
                    >
                      Copiar
                    </Button>
                  )}
                />
              </Cf>
            </Cf>

            <Cf row gap="32" end>
              <Cf>
                {PutChangeData.status === 200 && form.status === 'ATIVO' && (
                  <span style={{ color: '#3ac13a' }}>
                    Dados salvos com sucesso!
                  </span>
                )}
                <Button
                  onClick={() => handleSave()}
                  disabled={disabledInputs}
                  size="medium"
                >
                  Salvar
                </Button>
              </Cf>
              <Button onClick={() => handleChangeStatus()}>
                {form.status === 'ATIVO' ? 'Expirar' : 'Ativar'}
              </Button>
              <Cf>
                {PutSendEmail.success && form.status === 'ATIVO' && (
                  <span style={{ color: '#3ac13a' }}>
                    E-mail enviado com sucesso!
                  </span>
                )}
                <Button
                  disabled={form.status !== 'ATIVO' || PutSendEmail.loading}
                  onClick={() => PutSendEmail.callApi()}
                  loading={PutSendEmail.loading}
                >
                  Enviar E-mail
                </Button>
              </Cf>
              <Button
                disabled={form.status !== 'ATIVO'}
                onClick={() => setModalFiles(true)}
              >
                Visualizar Arquivos
              </Button>
            </Cf>
          </Cf>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default IndicationLinkDetails;
