/* eslint-disable no-lonely-if */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import Select from '~/components/select';
import TableList from '~/components/tableList';
import { setFormState } from '~/utils/form';
import { useInvoiceReport } from './context/useInvoice';

import * as S from './styles';
import { days, month, year } from './constants';

const ReportProposal: React.FC = () => {
  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [periodOff, setPeriodOff] = useState(false);

  const [form, setForm] = useState({
    tipoFatura: { name: 'tipoFatura', value: 'EMITIDAS' },
    status: 'TODOS',
    tipoData: 'fechamento',
    tipoTransacao: 'TODOS',
    periodoInicial: moment().format('DD/MM/YYYY'),
    periodoFinal: moment().format('DD/MM/YYYY'),
    origemPagamento: 'TODAS',
    statusFatura: 'TODOS',
    month: moment().format('MM'),
    day: '01',
    year: moment().format('YYYY'),
  });

  const dataFechamento = `${form.day}/${form.month}/${form.year}`;

  const {
    getReportInvoiceDelay,
    getReportInvoiceIssued,
    getReportInvoiceReceived,
    getFutureInvoices,
    getManuallyInvoices,
    header,
    headerItems,
    items,
    totalItems,
    totalPages,
    loading,
    INVOICE_TYPES,
    clearForm,
    relatorioData,
    setRelatorioData,
  } = useInvoiceReport();

  const renderFilter = (invoiceType: string) => {
    switch (invoiceType) {
      case 'EMITIDAS':
        return (
          <>
            <Loading status={loading} text={downloadCVSProgress} />
            <ContainerFlex padding={16}>
              <Select
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'PAGA', value: 'Pagas' },
                  { id: 'ABERTA', value: 'Em aberto' },
                  { id: 'ROTATIVO', value: 'Rotativo' },
                  { id: 'ATRASO', value: 'Em atraso' },
                  { id: 'PARCIAL', value: 'Pagamento Parcial' },
                  { id: 'PARCELADA', value: 'Parcelada' },
                  { id: 'PARCIAL_INVALIDO', value: 'Parcial Inválido' },
                  { id: 'PAGA_COBMAIS', value: 'Paga Cobmais' },
                ]}
                label="Status"
                value={form.status}
                onChange={(e) => {
                  setForm({ ...form, status: e });
                }}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Select
                options={[
                  {
                    id: 'fechamento',
                    value: 'Data de fechamento',
                  },
                  {
                    id: 'vencimento',
                    value: 'Data de vencimento',
                  },
                  {
                    id: 'aprovacao',
                    value: 'Data de aprovação',
                  },
                ]}
                label="Tipo de Período"
                value={form.tipoData}
                onChange={(e) => {
                  setForm({ ...form, tipoData: e });
                }}
              />
            </ContainerFlex>

            <ContainerFlex padding={16} space center>
              {form.tipoData === 'fechamento' ? (
                <>
                  <ContainerFlex>
                    <p style={{ fontSize: '12px', color: '#757575' }}>
                      Data de Fechamento
                    </p>
                  </ContainerFlex>
                  <ContainerFlex space row>
                    <div style={{ width: '20%' }}>
                      <Select
                        value={form.day}
                        options={days}
                        onChange={(e) => setForm({ ...form, day: e })}
                      />
                    </div>

                    <div style={{ width: '40%' }}>
                      <Select
                        value={form.month}
                        options={month}
                        onChange={(e) => setForm({ ...form, month: e })}
                      />
                    </div>

                    <div style={{ width: '30%' }}>
                      <Select
                        value={form.year}
                        options={year}
                        onChange={(e) => setForm({ ...form, year: e })}
                      />
                    </div>
                  </ContainerFlex>
                </>
              ) : (
                <ContainerFlex row space>
                  <Input
                    label="De"
                    placeholder="DD/MM/YYYY"
                    maxLength={10}
                    value={form?.periodoInicial}
                    onChange={(e) => {
                      return setForm({
                        ...form,
                        periodoInicial: e.target.value.masked,
                      });
                    }}
                    mask={MaskTypes.DATE}
                  />

                  <div style={{ width: '50px' }} />
                  <Input
                    label="Até"
                    placeholder="DD/MM/YYYY"
                    maxLength={10}
                    value={form?.periodoFinal}
                    onChange={(e) => {
                      return setForm({
                        ...form,
                        periodoFinal: e.target.value.masked,
                      });
                    }}
                    mask={MaskTypes.DATE}
                  />
                </ContainerFlex>
              )}
            </ContainerFlex>
          </>
        );
      case 'TRANSACAO_MANUAL_BACKOFFICE':
        return (
          <>
            <Loading status={loading} text={downloadCVSProgress} />
            <ContainerFlex padding={16}>
              <Select
                options={[
                  {
                    id: 'TODOS',
                    value: 'Todos',
                  },
                  {
                    id: 'TRANSACAO_CARTAO_DE_CREDITO',
                    value: 'Transão de Cartão de Crédito',
                  },
                  {
                    id: 'VOUCHER',
                    value: 'Voucher',
                  },
                  {
                    id: 'REEMBOLSO',
                    value: 'Reembolso',
                  },
                ]}
                label="Tipo de Transação"
                value={form.tipoTransacao}
                onChange={(e) => {
                  setForm({ ...form, tipoTransacao: e });
                }}
              />
            </ContainerFlex>
            <ContainerFlex row space padding={16}>
              <Input
                label="De"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form?.periodoInicial}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    periodoInicial: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />

              <div style={{ width: '50px' }} />
              <Input
                label="Até"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form?.periodoFinal}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    periodoFinal: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>
          </>
        );
      case 'RECEBIDAS':
        return (
          <>
            <ContainerFlex padding={16}>
              <Select
                options={[
                  { id: 'TODAS', value: 'Todas as Origens' },
                  { id: 'PIX', value: 'Pix' },
                  { id: 'LOJA', value: 'Loja' },
                  { id: 'BANCO', value: 'Banco' },
                  { id: 'COBMAIS', value: 'Cobmais' },
                  { id: 'TOTEM', value: 'Totem' },
                ]}
                label="Origem do pagamento"
                value={form.origemPagamento}
                onChange={(e) => {
                  setForm({ ...form, origemPagamento: e });
                }}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Select
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'PAGA', value: 'Paga' },
                  { id: 'PARCELADA', value: 'Parcelada' },
                  { id: 'PARCIAL', value: 'Parcial' },
                  { id: 'PARCIAL_INVALIDO', value: 'Parcial Inválido' },
                  { id: 'PAGA_COBMAIS', value: 'Paga Cobmais' },
                ]}
                label="Status da fatura"
                value={form.statusFatura}
                onChange={(e) => {
                  setForm({ ...form, statusFatura: e });
                }}
              />
            </ContainerFlex>
            <ContainerFlex padding={16} row space>
              <Input
                label="De"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form.periodoInicial}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    periodoInicial: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />

              <div style={{ width: '50px' }} />
              <Input
                label="Até"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form.periodoFinal}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    periodoFinal: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>
          </>
        );
      default:
        return null;
    }
  };

  const payloadType = {
    EMITIDAS: {
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      tipoData: form.tipoData,
      status: form.status,
    },
    RECEBIDAS: {
      origem: form.origemPagamento,
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      status: form.statusFatura,
    },
    MANUAL: {
      tipoTransacao: form.tipoTransacao,
      dataAprovacaoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataAprovacaoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    },
  };

  const payloadTypeData = {
    fechamento: {
      periodoInicial:
        dataFechamento &&
        moment(dataFechamento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      tipoData: form.tipoData,
      status: form.status,
    },
    vencimento: {
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      tipoData: form.tipoData,
      status: form.status,
    },
  };

  useEffect(() => {
    clearForm();
  }, []);

  const getReport = async (page = 1) => {
    const payload =
      form.tipoFatura?.value === 'EMITIDAS' && form.tipoData === 'fechamento'
        ? payloadTypeData[form.tipoData]
        : payloadType[form?.tipoFatura?.value];

    const payloadTransactionManually = payloadType.MANUAL;

    switch (form?.tipoFatura?.value) {
      case 'EMITIDAS':
        await getReportInvoiceIssued(page, 100, false, payload);
        break;
      case 'ATRASO':
        getReportInvoiceDelay(page);
        break;
      case 'RECEBIDAS':
        getReportInvoiceReceived(page, 100, false, payload);
        break;
      case 'FUTURAS':
        getFutureInvoices(page);
        break;
      case 'TRANSACAO_MANUAL_BACKOFFICE':
        getManuallyInvoices(page, 30, false, payloadTransactionManually);
        break;
      default:
        break;
    }
  };

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;
    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload =
      form.tipoFatura?.value === 'EMITIDAS' && form.tipoData === 'fechamento'
        ? payloadTypeData[form.tipoData]
        : payloadType[form?.tipoFatura?.value];

    switch (form?.tipoFatura?.value) {
      case 'EMITIDAS':
        response = await getReportInvoiceIssued(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'ATRASO':
        response = await getReportInvoiceDelay(currentPage, 150, true);
        break;
      case 'RECEBIDAS':
        response = await getReportInvoiceReceived(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'FUTURAS':
        response = await getFutureInvoices(currentPage, 150, true);
        break;
      case 'TRANSACAO_MANUAL_BACKOFFICE':
        response = await getManuallyInvoices(
          currentPage,
          150,
          true,
          payloadType.MANUAL,
        );
        break;
      default:
        break;
    }

    if (response) {
      const data =
        response?.totalDePaginas ?? response?.totalPaginas
          ? response
          : response.data;
      totalPages = data?.totalDePaginas ?? response?.totalPaginas;
      const porcent = (currentPage / totalPages) * 100;
      const roundPorcent = Math.ceil(porcent);
      setDownloadCVSProgress(`${roundPorcent}%`);
      if (form?.tipoFatura?.value === 'TRANSACAO_MANUAL_BACKOFFICE') {
        if (data?.data?.length) {
          setRelatorioData((prev) => [...prev, ...data?.data]);
        }
      } else {
        if (data?.pagina?.length) {
          setRelatorioData((prev) => [...prev, ...data?.pagina]);
        }
      }
      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
    } else {
      setRelatorioData([]);
    }
  };

  return (
    <Container>
      <Loading status={loading} text={downloadCVSProgress} />
      <S.BoxCard>
        <ContainerFlex row space>
          <ContainerFlex padding={16}>
            <Select
              options={INVOICE_TYPES}
              label="Tipo Fatura"
              name={form.tipoFatura.name}
              value={form.tipoFatura.value}
              onChange={(e) => {
                setFormState(
                  { target: { name: form.tipoFatura.name, value: e } },
                  form,
                  setForm,
                );
                clearForm();
              }}
            />
          </ContainerFlex>

          {renderFilter(form.tipoFatura.value)}

          <ContainerFlex flex="0.2" padding={16}>
            <Button
              onClick={() => {
                if (form.periodoInicial && form.periodoFinal) {
                  setPeriodOff(false);
                  getReport();
                } else {
                  setPeriodOff(true);
                }
              }}
            >
              <span>Consultar</span>
            </Button>
          </ContainerFlex>
        </ContainerFlex>
        {periodOff ? (
          <S.SpanLabelFeed>
            * Insira um período de busca válido.
          </S.SpanLabelFeed>
        ) : null}
      </S.BoxCard>

      <CSVLink
        ref={refCSV}
        data={relatorioData}
        style={{
          color: 'inherit',
          textDecoration: 'none',
        }}
        headers={header}
        filename={`relatorio_faturas_${form.tipoFatura.value}.csv`}
        target="_blank"
      />

      {items?.length > 0 ? (
        <S.BoxCard>
          <ContainerFlex flex="0.2" padding={16} marginTop={10}>
            <ContainerFlex row space center padding={24}>
              <ContainerFlex>
                <p>Resultados retornados: {totalItems}</p>
              </ContainerFlex>
              <Button
                onClick={() => {
                  if (refCSV.current) {
                    onClickDownloadCSV().then(() =>
                      refCSV.current.link.click(),
                    );
                  }
                }}
              >
                Download relatório
              </Button>
            </ContainerFlex>
            <ContainerFlex>
              <TableList customerHeader={headerItems} list={items} />
              <ContainerFlex row horizontal center marginTop={20}>
                <Pagination
                  totalPages={totalPages}
                  onCurrentPageChange={getReport}
                />
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </S.BoxCard>
      ) : null}
    </Container>
  );
};

export default ReportProposal;
