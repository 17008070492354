import styled from 'styled-components';

export const Title = styled.h4`
  color: #4a4b4a;

  font-weight: 600;
  font-size: 1.3rem;
`;

export const Container = styled.div`
  padding: 40px 80px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-flow: column wrap;

  > div {
    margin-bottom: 0px;
  }
`;
