import styled from 'styled-components';

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 30px;
  padding-left: 16px;
  padding-top: 30px;
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  background-color: #ffffff;
`;
