import styled from 'styled-components';
import Button from '~/components/button';

export const Title = styled.h2`
  color: rgba(74, 75, 74, 1);
  font-size: 26px;
`;

export const Value = styled.h4`
  color: rgba(74, 75, 74, 1);
  font-size: 18px;
`;

export const ActionsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: none;
  padding: 6px;
  color: #00000052;
  border: 1px solid #00000052;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
export const TableHistory = styled.table`
  width: 100%;
  border-spacing: 0;

  td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: grey;
    padding: 10px;
  }

  th {
    padding: 10px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: grey;
  }
`;
export const ButtonMain = styled(Button)`
  cursor: pointer;
  border-radius: 10px !important;
  padding: 10px 20px !important;
`;

export const DescriptionDialog = styled.h3`
  color: #e71037;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 20px auto 0 auto;
`;
