import React from 'react';

import * as St from './styles';

interface ILoading {
  containerColor?: string;
  status?: boolean;
  text?: string;
}

const LoadingComponents: React.FC<ILoading> = ({
  status,
  text,
  containerColor,
}) => {
  return (
    <St.LoadingContainer color={containerColor} status={status}>
      <St.LoadingSpinner />
      <St.Text>{text}</St.Text>
    </St.LoadingContainer>
  );
};

export default LoadingComponents;
