import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import { icons } from '~/assets';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import Endpoints from '~/services/endpoints';

import { setFormState } from '~/utils/form';

import * as St from './styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Loading from '~/components/loading';
import ProposalReportResponse from '~/services/report/proposal/response';
import Container from '~/components/container';

const FileProposal: React.FC = () => {
  const [form, setForm] = useState({
    tipoProposta: { name: 'tipoProposta', value: '' },
    dataInicial: {
      name: 'periodoIncial',
      value: moment().format('DD/MM/YYYY'),
    },
    dataFinal: {
      name: 'dataFinal',
      value: moment().format('DD/MM/YYYY'),
    },
  });

  const [loading, setLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const PROPOSAL_TYPES = [
    {
      id: 'CADASTRADA',
      name: 'Propostas cadastradas',
      value: 'Propostas cadastradas',
    },
    {
      id: 'APROVADA',
      name: 'Propostas aprovadas',
      value: 'Propostas aprovadas',
    },
    {
      id: 'REPROVADA',
      name: 'Propostas reprovadas',
      value: 'Propostas reprovadas',
    },
    {
      id: 'PROPOSTA_P1',
      name: 'Propostas aprovadas em P1',
      value: 'Propostas aprovadas em P1',
    },
    {
      id: 'REPROVADA_P1',
      name: 'Propostas reprovadas em P1',
      value: 'Propostas reprovadas em P1',
    },
  ];

  const PostDownloadCsv = useApi<ProposalReportResponse>(
    Endpoints.files.downloadProposalReports,
    ApiMethod.POST,
  );
  const tipoProposta = PROPOSAL_TYPES.find(
    (e) => e.id === form.tipoProposta.value,
  );

  const handleSubmit = async () => {
    setLoading(true);

    const search = (obj) => obj.id === form.tipoProposta.value;
    const index = PROPOSAL_TYPES.findIndex(search);

    const payload = {
      tipoProposta: tipoProposta.id,
      dataInicial:
        form.dataInicial &&
        moment(form.dataInicial.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataFinal:
        form.dataFinal &&
        moment(form.dataFinal.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    try {
      setLoading(true);
      const response = await PostDownloadCsv.callApi(payload);

      const binary = atob(response.data.base64);
      const buffer = new ArrayBuffer(binary.length);
      const view = new Uint8Array(buffer);

      for (let i = 0; i < binary.length; i += 1) {
        view[i] = binary.charCodeAt(i);
      }

      const file = new Blob(['\ufeff', view], { type: 'text/csv' });
      const fileUrl = URL.createObjectURL(file);
      const link = document.createElement('a');
      const fileName = PROPOSAL_TYPES[index].id;
      link.href = fileUrl;
      link.setAttribute('download', fileName);
      link.click();
      window.URL.revokeObjectURL(fileUrl);
      setLoading(false);
    } catch (error) {
      setDialogText('Nenhum arquivo encontrado!');
      setDialogIsOpen(true);
      setLoading(false);
    }
  };
  return (
    <>
      <Loading status={PostDownloadCsv.loading} />
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={dialogText}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Select
                label="Tipo Proposta"
                name={form.tipoProposta.name}
                value={form.tipoProposta.value}
                options={PROPOSAL_TYPES}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.tipoProposta.name, value: e } },
                    form,
                    setForm,
                  );
                }}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="De"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                name={form.dataInicial.name}
                value={form.dataInicial.value}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    dataInicial: {
                      name: 'dataInicial',
                      value: e.target.value.masked,
                    },
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Ate"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                name={form.dataFinal.name}
                value={form.dataFinal.value}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    dataFinal: {
                      name: 'dataFinal',
                      value: e.target.value.masked,
                    },
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={handleSubmit}
                loading={loading}
                disabled={!form.tipoProposta.value}
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default FileProposal;
