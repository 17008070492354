/* eslint-disable react/jsx-one-expression-per-line */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import TableList from '~/components/tableList';
import FilterLoan from './components/FilterLoan';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import * as S from './styles';
import ResultNotFound from '~/components/resultNotFound';
import { useReportLoan } from './context/useLoan';
import { BodyReportWithDrawCSV } from '../models/modelReportWithDraw';

const ReportLoan: React.FC = () => {
  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [form, setForm] = useState({
    tipoEmprestimo: { name: 'tipoEmprestimo', value: '' },
    dataSimulacaoInicial: moment().format('YYYY-MM-DD'),
    dataSimulacaoFinal: moment().format('YYYY-MM-DD'),
    status: { name: 'status', value: '' },
    tipoPeriodo: { name: 'tipoPeriodo', value: '' },
    uf: 'TODOS',
    idOperador: 'TODOS',
  });

  let headerFlag = true;

  const {
    getReportWithDraw,
    getReportLeadsSales,
    getReportLoanSeeker,
    getReportWithdrawBilling,
    getReportIncomeDefaultWithdraw,
    getOperador,
    checkSuccess,
    loading,
    items,
    headerItems,
    header,
    totalItems,
    totalPages,
    relatorioData,
    setRelatorioData,
    clearForm,
    operador,
  } = useReportLoan();

  useEffect(() => {
    getOperador();
  }, []);

  const payloadType = {
    VENDAS_EMPRESTIMOS: {
      dataFinal: moment(form?.dataSimulacaoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form?.dataSimulacaoInicial).format('YYYY-MM-DD'),
      idOperador: form.idOperador,
    },
    VENDAS_SAQUE: {
      dataFinal: moment(form?.dataSimulacaoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form?.dataSimulacaoInicial).format('YYYY-MM-DD'),
      status: form.status.value,
      idOperador: form.idOperador,
    },
    PROPOSTAS_BUSCADOR: {
      dataFinal: moment(form?.dataSimulacaoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form?.dataSimulacaoInicial).format('YYYY-MM-DD'),
      status: form?.status?.value,
      idOperador: form.idOperador,
    },
    FATURAMENTO_SAQUE: {
      dataFinal: moment(form?.dataSimulacaoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form?.dataSimulacaoInicial).format('YYYY-MM-DD'),
      tipoPeriodo: form.tipoPeriodo.value,
    },
  };

  const getReport = async (page = 1) => {
    const payload = payloadType[form.tipoEmprestimo.value];

    switch (form.tipoEmprestimo?.value) {
      case 'VENDAS_EMPRESTIMOS':
        await getReportLeadsSales(page, 10, false, payload);
        break;
      case 'VENDAS_SAQUE':
        await getReportWithDraw(page, 10, false, payload);
        break;
      case 'PROPOSTAS_BUSCADOR':
        await getReportLoanSeeker(false, payload);
        break;
      case 'FATURAMENTO_SAQUE':
        await getReportWithdrawBilling(page, 10, false, payload);
        break;
      case 'RECEITA_INADIMPLENCIA':
        await getReportIncomeDefaultWithdraw();
        break;
      default:
        break;
    }
  };
  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload = payloadType[form?.tipoEmprestimo?.value];
    switch (form.tipoEmprestimo?.value) {
      case 'VENDAS_EMPRESTIMOS':
        response = await getReportLeadsSales(currentPage, 50, true, payload);
        break;
      case 'VENDAS_SAQUE':
        response = await getReportWithDraw(
          currentPage,
          150,
          true,
          payload,
          true,
        );
        break;
      case 'PROPOSTAS_BUSCADOR':
        response = await getReportLoanSeeker(true, payload);
        break;
      case 'FATURAMENTO_SAQUE':
        response = await getReportWithdrawBilling(
          currentPage,
          50,
          true,
          payload,
        );
        break;
      default:
        break;
    }

    if (response) {
      const data = response.totalDePaginas
        ? response
        : response.data || response.vendas || response;
      totalPages = data.totalDePaginas;

      const percentage = (currentPage / totalPages) * 100;
      const roundPercentage = Math.ceil(percentage);
      setDownloadCVSProgress(`${roundPercentage || 0}%`);

      if (data?.pagina?.length) {
        if (form.tipoEmprestimo?.value === 'VENDAS_SAQUE') {
          const allRecords = [];
          for (let pagina = 1; pagina <= totalPages; pagina++) {
            const records = BodyReportWithDrawCSV(data.pagina, headerFlag);
            headerFlag = false;

            const uniqueRecords = records.filter((newRecord) => {
              return !allRecords.some((existingRecord) => {
                return newRecord.id === existingRecord.id;
              });
            });

            allRecords.push(...uniqueRecords);
          }

          setRelatorioData((prev) => [...prev, ...allRecords]);
        } else {
          setRelatorioData((prev) => [...prev, ...data.pagina]);
        }
      } else {
        setRelatorioData((prev) => [...prev, ...data]);
      }

      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
      setDownloadCVSProgress('');
    }
  };

  useEffect(() => {
    if (form.tipoEmprestimo?.value !== '') {
      getReport();
    }
    clearForm();
  }, [form]);

  return (
    <>
      <Loading status={loading} />
      <Container>
        <FilterLoan
          operador={operador || []}
          onClick={(e) => {
            setForm({
              ...e,
              dataSimulacaoInicial: moment(
                e.dataSimulacaoInicial,
                'DD/MM/YYYY',
              ).format('YYYY-MM-DD'),
              dataSimulacaoFinal: moment(
                e.dataSimulacaoFinal,
                'DD/MM/YYYY',
              ).format('YYYY-MM-DD'),
            });
          }}
        />

        <CSVLink
          ref={refCSV}
          data={relatorioData}
          style={{
            color: 'inherit',
            textDecoration: 'none',
          }}
          headers={
            form.tipoEmprestimo?.value === 'VENDAS_SAQUE' ? null : header
          }
          filename={`relatorio_emprestimo_${form.tipoEmprestimo.value}.csv`}
          target="_blank"
        />

        {items?.length > 0 ? (
          <S.BoxCard>
            <ContainerFlex flex="0.2">
              <ContainerFlex row space center marginBottom={24}>
                <S.BoxCardTitle>Relatório</S.BoxCardTitle>
                <Button
                  onClick={() => {
                    if (refCSV.current) {
                      onClickDownloadCSV().then(() =>
                        refCSV.current.link.click(),
                      );
                      setRelatorioData([]);
                    }
                  }}
                  disabled={loading}
                >
                  Download
                </Button>
              </ContainerFlex>

              <ContainerFlex>
                <TableList customerHeader={headerItems} list={items} />
                {form.tipoEmprestimo.value !== 'PROPOSTAS_BUSCADOR' && (
                  <ContainerFlex row space center marginTop={20}>
                    <ContainerFlex>
                      <S.ShowResults>
                        Total de resultados: <strong>{totalItems}</strong>
                      </S.ShowResults>
                    </ContainerFlex>
                    <Pagination
                      totalPages={totalPages}
                      onCurrentPageChange={getReport}
                    />
                  </ContainerFlex>
                )}
              </ContainerFlex>
            </ContainerFlex>
          </S.BoxCard>
        ) : (
          <>
            {form.tipoEmprestimo.value !== 'RECEITA_INADIMPLENCIA' ? (
              <ResultNotFound />
            ) : null}
          </>
        )}
        {checkSuccess() && items.length <= 0 && (
          <ContainerFlex center>
            <TitleCategory>Nenhum resultado encontrado</TitleCategory>
          </ContainerFlex>
        )}
      </Container>
    </>
  );
};

export default ReportLoan;
