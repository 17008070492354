import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Layout from '~/components/layout';
import Loading from '~/components/loading';
import Select, { IOption } from '~/components/select';
import TitleApp from '~/components/title';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { ICompany } from '~/models/company';
import endpoints from '~/services/endpoints';
import { transformInGlobalDate } from '~/utils/date';
import { IDefaultReturn } from '~/utils/masks';
import {
  cpfValidator,
  dateValidator,
  defaultValidator,
  emailValidator,
  IValidators,
  nameValidator,
  phoneValidator,
  ValidatorsNames,
} from '~/utils/validators';
import * as St from './styles';

interface IForm<T> {
  cpf: T;
  name: string;
  phone: T;
  dateBirth: T;
  email: string;
  login: string;
  cep: T;
  address: string;
  number: string;
  complement: string;
  city: string;
  neighborhood: string;
  idCompany: string;
}

const RegisterOperator: React.FC = () => {
  const [form, setForm] = useState<IForm<IDefaultReturn>>(
    {} as IForm<IDefaultReturn>,
  );
  const [companys, setCompanys] = useState<IOption[]>([]);
  const [errors, setErrors] = useState<IValidators>({} as IValidators);
  const [openDialog, setOpenDialog] = useState(false);

  const history = useHistory();
  const { state } = useLocation<{ cpf: IDefaultReturn }>();

  const { data, callApi, status, loading } = useApi<any>(
    endpoints.register.operator,
    ApiMethod.POST,
    {
      celular: form.phone?.unmasked,
      cpf: form.cpf?.unmasked,
      dataNascimento: transformInGlobalDate(form.dateBirth?.masked),
      perfil: 'OPR',
      email: form.email,
      endereco: {
        bairro: form.neighborhood,
        cep: form.cep?.unmasked,
        cidade: form.city,
        complemento: form.complement,
        logradouro: form.address,
        numero: form.number,
        pais: 'BR',
        uf: 'AM',
      },
      genero: 'M',
      idEmpresa: form.idCompany,
      nome: form.name?.trim(),
    },
  );

  const RequestListCompany = useApi<ICompany[]>(
    `${endpoints.consult.searchCompany}`,
    ApiMethod.GET,
  );

  useEffect(() => {
    (async () => {
      if (state?.cpf) {
        setForm((prev) => ({ ...prev, cpf: state.cpf }));
      }

      const response = await RequestListCompany.callApi();
      if (response.status === 200) {
        const companys = response.data.map(({ nome, id }) => ({
          id,
          value: nome,
        }));
        setCompanys(companys);
      }
    })();
  }, []);

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (value, name) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleErrors = () => {
    const errors = [
      emailValidator(form.email),
      cpfValidator(form.cpf?.masked),
      dateValidator(form.dateBirth?.masked, ValidatorsNames.dateBirth),
      phoneValidator(form.phone?.masked),
      nameValidator(form.name),
      defaultValidator(form.cep?.masked, ValidatorsNames.cep, 9),
      defaultValidator(form.neighborhood, ValidatorsNames.neighborhood),
      defaultValidator(form.number, ValidatorsNames.number),
      defaultValidator(form.city, ValidatorsNames.city),
      defaultValidator(form.address, ValidatorsNames.address),
      defaultValidator(form.idCompany, ValidatorsNames.idCompany),
    ].reduce((memo, el) => ({ ...memo, ...el }), {});

    setErrors(errors);

    const hasErrors = Object.values(errors).filter((a) => a !== null).length;
    if (!hasErrors) handleSubmit();
  };

  const handleSubmit = async () => {
    await callApi();
    handleToggleDialog();
  };

  const goToConsult = () => history.push('/consult/employee');

  return (
    <Layout>
      <Loading status={loading} />

      <Dialog
        open={openDialog}
        message={data?.mensagem || 'Atendente cadastrado com sucesso!'}
        positiveAction={status === 201 ? goToConsult : handleToggleDialog}
        positiveLabel="Entendi"
        icon={icons.check}
      />
      <ContainerFlex padding={60}>
        <TitleApp>Atendente Comercial Cadastro</TitleApp>

        <St.TitleCategory>1. Operador</St.TitleCategory>

        <Grid xs={1} lg={2} gap={20}>
          <ContainerFlex>
            <Input
              label="CPF"
              name="cpf"
              mask={MaskTypes.CPF}
              placeholder="000.000.000-00"
              onChange={handleChange}
              value={form.cpf?.masked}
              errorMessage={errors.cpf}
              maxLength={14}
            />
            <Input
              label="Nome completo"
              name="name"
              onChange={handleChange}
              errorMessage={errors.name}
            />
            <Input
              label="Telefone"
              name="phone"
              mask={MaskTypes.PHONE}
              placeholder="(00) 00000-0000"
              onChange={handleChange}
              value={form.phone?.masked}
              maxLength={15}
              errorMessage={errors.phone}
            />
            <Input
              label="Data de nascimento"
              name="dateBirth"
              mask={MaskTypes.DATE}
              placeholder="dd/mm/aaaa"
              onChange={handleChange}
              value={form.dateBirth?.masked}
              maxLength={10}
              errorMessage={errors.dateBirth}
            />
            <Input
              label="E-mail"
              name="email"
              type="email"
              onChange={handleChange}
              errorMessage={errors.email}
            />
          </ContainerFlex>

          <ContainerFlex>
            <Input
              label="CEP"
              name="cep"
              mask={MaskTypes.CEP}
              placeholder="00000-000"
              onChange={handleChange}
              value={form.cep?.masked}
              maxLength={9}
              errorMessage={errors.cep}
            />
            <Input
              label="Endereço"
              name="address"
              onChange={handleChange}
              value={form.address}
              errorMessage={errors.address}
            />

            <Select
              label="Empresa"
              value={form.idCompany}
              onChange={(val) => handleSelect(val, 'idCompany')}
              options={companys}
              errorMessage={errors.idCompany}
            />

            <div>
              <Grid xs={1} md={2} gap={10}>
                <ContainerFlex>
                  <Input
                    label="Número"
                    name="number"
                    onChange={handleChange}
                    type="number"
                    errorMessage={errors.number}
                  />
                </ContainerFlex>

                <ContainerFlex>
                  <Input
                    label="Complemento"
                    name="complement"
                    onChange={handleChange}
                    errorMessage={errors.complement}
                  />
                </ContainerFlex>
              </Grid>

              <Grid xs={1} md={2} gap={10}>
                <ContainerFlex>
                  <Input
                    label="Cidade"
                    name="city"
                    onChange={handleChange}
                    errorMessage={errors.city}
                  />
                </ContainerFlex>

                <ContainerFlex>
                  <Input
                    label="Bairro"
                    name="neighborhood"
                    onChange={handleChange}
                    errorMessage={errors.neighborhood}
                  />
                </ContainerFlex>
              </Grid>
            </div>

            <Button disabled={loading} loading={loading} onClick={handleErrors}>
              Cadastrar
            </Button>
          </ContainerFlex>
        </Grid>
      </ContainerFlex>
    </Layout>
  );
};

export default RegisterOperator;
