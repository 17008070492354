import { useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import TextAreaComponent from '~/components/textarea';
import * as St from './styles';

export const TextArea = ({ onOpinion }) => {
  const [opinionData, setOpinionData] = useState('');

  const handleOpinion = (opinion: string) => {
    onOpinion(opinion);
  };

  return (
    <ContainerFlex marginTop={15} marginBottom={15}>
      <TextAreaComponent
        placeholder="Digitar parecer.."
        name="parecer"
        rows={10}
        value={opinionData}
        onChange={(e) => {
          setOpinionData(e.target.value);
          handleOpinion(e.target.value);
        }}
      />
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <St.TitleAbbrFooter>* Obrigatório preencher</St.TitleAbbrFooter>
      </div>
    </ContainerFlex>
  );
};
