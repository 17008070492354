import { IDefaultReturn } from '~/utils/masks';

export const BIOMETRICS_ORIGIN = [
  {
    id: 'TODAS',
    name: 'Todas',
    value: 'Todas',
  },
  {
    id: 'T_SENHA',
    name: 'Alteração de senha',
    value: 'Alteração de senha',
  },
  {
    id: 'T_EMPRESTIMO',
    name: 'Empréstimo Hotmoney',
    value: 'Empréstimo Hotmoney',
  },
];

export interface FormState {
  cpf: IDefaultReturn;
  origem: string;
  dataBiometriaInicial: string;
  dataBiometriaFinal: string;
}
