/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { BsFillFileEarmarkLock2Fill } from 'react-icons/bs';
import { MdOutlineCreditCardOff } from 'react-icons/md';
import {
  TbFileAlert,
  TbFileOff,
  TbFilePlus,
  TbReportMoney,
} from 'react-icons/tb';
import moment from 'moment';

import { FaBalanceScaleRight } from 'react-icons/fa';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import BoxIndicators from '~/components/BoxIndicators';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import SelectDate from '~/components/selectDate';
import Loading from '~/components/loading';
import Dialog from '~/components/dialog';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { icons } from '~/assets';

import * as S from '../styles';

interface InvoiceClosing {
  cartoesCobranca: number;
  dataFechamento: string;
  dataVencimento: string;
  faturasAntecipacaoCompra: number;
  faturasAntecipacaoFinanciamento: number;
  faturasAnuidadeBeneficios: number;
  faturasCobrandoEncargos: number;
  faturasCreditosDisponiveis: number;
  faturasEmAtraso: number;
  faturasFechadas: number;
  faturasLancamentoSaldoRemanescentes: number;
  faturasNaoPagas: number;
  faturasPagas: number;
  faturasSegundaVia: number;
  faturasZeradas: number;
  percentualFaturasAtraso: number;
  percentualFaturasPagas: number;
}

const month = [
  {
    id: '01',
    value: 'Janeiro',
  },
  {
    id: '02',
    value: 'Fevereiro',
  },
  {
    id: '03',
    value: 'Março',
  },
  {
    id: '04',
    value: 'Abril',
  },
  {
    id: '05',
    value: 'Maio',
  },
  {
    id: '06',
    value: 'Junho',
  },
  {
    id: '07',
    value: 'Julho',
  },
  {
    id: '08',
    value: 'Agosto',
  },
  {
    id: '09',
    value: 'Setembro',
  },
  {
    id: '10',
    value: 'Outubro',
  },
  {
    id: '11',
    value: 'Novembro',
  },
  {
    id: '12',
    value: 'Dezembro',
  },
];

const days = [
  {
    id: '01',
    value: '1',
  },
  {
    id: '04',
    value: '4',
  },
  {
    id: '09',
    value: '9',
  },
  {
    id: '14',
    value: '14',
  },
  {
    id: '21',
    value: '21',
  },
  {
    id: '25',
    value: '25',
  },
];

const year = [
  {
    id: '2019',
    value: '2019',
  },
  {
    id: '2020',
    value: '2020',
  },
  {
    id: '2021',
    value: '2021',
  },
  {
    id: '2022',
    value: '2022',
  },
  {
    id: '2023',
    value: '2023',
  },
];

const DashInvoiceClosing: React.FC = () => {
  const [form, setForm] = useState({
    month: moment().format('MM'),
    day: '01',
    year: moment().format('YYYY'),
  });
  const [invoiceClosing, setInvoiceClosing] = useState<InvoiceClosing>();
  const [closingAmounts, setClosingAmounts] = useState<any>([]);
  const [dialogInformativeIsOpen, setDialogInformativeIsOpen] = useState(false);
  const [dialogInformativeText, setInformativeText] = useState('');

  const addANewYear = () => {
    const currentYear = new Date().getFullYear();
    const currentYearStr = currentYear.toString();

    const includedYear = year.find((item) => item.id === currentYearStr);

    if (!includedYear) {
      year.push({
        id: currentYearStr,
        value: currentYearStr,
      });
    }
  };

  useEffect(() => {
    addANewYear();
  }, []);

  const history = useHistory();

  // Formatando a data do Filtro
  const date = `${form.day}/${form.month}/${form.year}`;

  const RequestInvoiceClosing = useApi(
    Endpoints.graphc.getInvoiceClosing.replace(
      'PARAM_DATA_FECHAMENTO',
      moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    ),
    ApiMethod.GET,
  );

  const InvoiceClosing = async () => {
    try {
      const response = await RequestInvoiceClosing.callApi();

      if (response.status >= 200 && response.status < 300) {
        setInvoiceClosing(response.data as InvoiceClosing);
      }
      if (response.status >= 400) {
        setDialogInformativeIsOpen(true);
        setInformativeText(
          response.mensagem ? response.mensagem : 'Erro ao buscar faturas',
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    InvoiceClosing();
  }, [form]);

  const handleSelect = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSelectDay = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSelectYear = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      <Loading status={RequestInvoiceClosing.loading} />
      <Dialog
        icon={icons.exclamation}
        open={dialogInformativeIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogInformativeIsOpen(false)}
        message={dialogInformativeText}
      />

      <Container>
        <S.Header>
          <S.LeftSide>
            <ContainerFlex row>
              <S.InputDateDisabled>{`Data de corte: ${date}`}</S.InputDateDisabled>
              <S.InputDateDisabled>
                {`Data de vencimento: ${moment(
                  invoiceClosing?.dataVencimento,
                ).format('DD/MM/YYYY')}`}
              </S.InputDateDisabled>
            </ContainerFlex>
          </S.LeftSide>
          <S.RightSide>
            <div style={{ width: '173px' }}>
              <SelectDate
                months={month}
                days={days}
                years={year}
                value={form?.month}
                valueDay={form?.day}
                valueYear={form.year}
                onChange={(val) => handleSelect(val, 'month')}
                onChangeDays={(val) => handleSelectDay(val, 'day')}
                onChangeYears={(val) => handleSelectDay(val, 'year')}
              />
            </div>
          </S.RightSide>
        </S.Header>

        <S.MonthCards>
          <BoxIndicators
            title="Faturas fechadas"
            value={invoiceClosing?.faturasFechadas}
            icon={<BsFillFileEarmarkLock2Fill />}
          />
          <BoxIndicators
            title="Faturas zeradas"
            value={invoiceClosing?.faturasZeradas}
            icon={<TbFileOff />}
          />

          <BoxIndicators
            title="Cartões em cobrança"
            value={invoiceClosing?.cartoesCobranca}
            icon={<MdOutlineCreditCardOff />}
          />
          <BoxIndicators
            title="Créditos Disponíveis"
            value={invoiceClosing?.faturasCreditosDisponiveis}
            icon={<TbFilePlus />}
            hover
            click={() =>
              history.push('/creditosDisponiveis', {
                dataHoraColeta: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              })
            }
          />
          <BoxIndicators
            title="Lançamento de saldos remanescentes"
            value={invoiceClosing?.faturasLancamentoSaldoRemanescentes}
            icon={<FaBalanceScaleRight />}
          />
          <BoxIndicators
            title="Faturas cobrando encargos"
            value={invoiceClosing?.faturasCobrandoEncargos}
            icon={<TbFileAlert />}
          />
          <BoxIndicators
            title="Reemissão de tarifa 2ª via (duplicadas)"
            value={invoiceClosing?.faturasSegundaVia}
            icon={<TbFileAlert />}
          />
          <BoxIndicators
            title="Anuidade e benefícios"
            value={invoiceClosing?.faturasAnuidadeBeneficios}
            icon={<AiOutlineFileAdd />}
          />
          <BoxIndicators
            title="Antecipação de finaciamento"
            value={invoiceClosing?.faturasAntecipacaoFinanciamento}
            icon={<TbReportMoney />}
          />
          <BoxIndicators
            title="Antecipação de compra"
            value={invoiceClosing?.faturasAntecipacaoCompra}
            icon={<TbReportMoney />}
          />
        </S.MonthCards>

        <S.Header style={{ marginTop: '60px' }}>
          <S.HeaderTitle>Posição Financeira do Fechamento</S.HeaderTitle>
        </S.Header>

        <S.MonthClosingCards>
          <BoxIndicators
            title="Fatura pagas"
            value={invoiceClosing?.faturasPagas}
            icon={<TbReportMoney />}
            hover
            percent={invoiceClosing?.percentualFaturasPagas}
            click={() =>
              history.push('/faturasPagas', {
                dataHoraColeta: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              })
            }
          />
          <BoxIndicators
            title="Faturas não pagas"
            value={invoiceClosing?.faturasNaoPagas}
            icon={<TbReportMoney />}
          />
          <BoxIndicators
            title="Faturas em atraso"
            value={invoiceClosing?.faturasEmAtraso}
            icon={<TbReportMoney />}
            hover
            percent={invoiceClosing?.percentualFaturasAtraso}
            click={() =>
              history.push('/faturasEmAtraso', {
                dataHoraColeta: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              })
            }
          />
        </S.MonthClosingCards>
      </Container>
    </>
  );
};

export default DashInvoiceClosing;
