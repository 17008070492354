import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { FaDownload } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import Container from '~/components/container';
import Loading from '~/components/loading';

import * as St from './styles';
import ContainerFlex from '~/components/containerFlex';
import FilterEvents from './components/FilterEvents';
import { useReportEvent } from './context/useReportEvent';
import Button from '~/components/button';
import TableList from '~/components/tableList';
import ResultNotFound from '~/components/resultNotFound';

const ReportEvents: React.FC = () => {
  const {
    operador,
    loading,
    getReportEventsCancellation,
    clearForm,
    items,
    headerItems,
    relatorioData,
    setRelatorioData,
    header,
    getOperator,
  } = useReportEvent();

  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [form, setForm] = useState({
    tipoEvento: { name: 'tipoEvento', value: '' },
    idOperador: 'TODOS',
    dataInicial: moment().format('YYYY-MM-DD'),
    dataFinal: moment().format('YYYY-MM-DD'),
  });

  const payloadType = {
    CANCELAMENTO_PENDENTE: {
      dataInicial: moment(form?.dataInicial).format('YYYY-MM-DD'),
      dataFinal: moment(form?.dataFinal).format('YYYY-MM-DD'),
      idOperador: form.idOperador,
    },
  };

  const getReport = async () => {
    const payload = payloadType[form.tipoEvento.value];

    switch (form.tipoEvento?.value) {
      case 'CANCELAMENTO_PENDENTE':
        await getReportEventsCancellation(payload);
        break;
      default:
        break;
    }
  };

  const onClickDownloadCSV = async (currentPage = 1) => {
    let response;
    const totalPages = 1;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload = payloadType[form?.tipoEvento?.value];
    switch (form?.tipoEvento?.value) {
      case 'CANCELAMENTO_ANULADO':
        response = await getReportEventsCancellation(payload);
        break;
      default:
        break;
    }

    if (response) {
      const { data } = response;

      const percentage = (currentPage / totalPages) * 100;

      const roundPercentage = Math.ceil(percentage);
      setDownloadCVSProgress(`${roundPercentage || 0}%`);

      if (data) {
        setRelatorioData((prev) => [...prev, ...data]);
      }

      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
      setDownloadCVSProgress('');
    }
  };

  useEffect(() => {
    if (form.tipoEvento?.value !== '') {
      getReport();
    }
    clearForm();
  }, [form]);

  useEffect(() => {
    getOperator();
  }, []);

  return (
    <>
      <Loading status={loading} />
      <Container>
        <FilterEvents
          operador={operador || []}
          onClick={(e) => {
            setForm({
              ...e,
              dataInicial: moment(e.dataInicial, 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
              ),
              dataFinal: moment(e.dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            });
          }}
        />
        <CSVLink
          ref={refCSV}
          data={relatorioData}
          style={{ color: 'inherit', textDecoration: 'none' }}
          headers={header}
          filename={`relatório_evento_${form.tipoEvento.value}.csv`}
          target="_blank"
        />
        {items?.length > 0 ? (
          <St.BoxCard>
            <ContainerFlex flex="0.2">
              <ContainerFlex row space center marginBottom={24}>
                <St.BoxCardTitle>Relatório</St.BoxCardTitle>
                <Button
                  onClick={() => {
                    if (refCSV.current) {
                      onClickDownloadCSV().then(() =>
                        refCSV.current.link.click(),
                      );
                    }
                  }}
                  disabled={loading}
                >
                  <FaDownload />
                  <span style={{ marginLeft: 8 }}>Download</span>
                </Button>
              </ContainerFlex>
              <ContainerFlex>
                <TableList customerHeader={headerItems} list={items} />
              </ContainerFlex>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <ResultNotFound />
        )}
      </Container>
    </>
  );
};

export default ReportEvents;
