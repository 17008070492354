import { SwitchInput, SwitchLabel, SwitchButton } from './styles';

interface IToggleSwitch {
  id: string;
  toggled: boolean;
  onChangeFunction: () => void;
  widthSwitchLabel?: number;
  heightSwitchLabel?: number;
  widthSwitchButton?: number;
  heightSwitchButton?: number;
}
const ToggleSwitch: React.FC<IToggleSwitch> = ({
  id,
  toggled,
  onChangeFunction,
  widthSwitchLabel,
  heightSwitchLabel,
  widthSwitchButton,
  heightSwitchButton,
}) => {
  const confirm = async () => {
    if (onChangeFunction) {
      await onChangeFunction();
    }
  };
  return (
    <>
      <SwitchInput
        className="switch-checkbox"
        id={id}
        type="checkbox"
        checked={toggled}
        onChange={confirm}
      />
      <SwitchLabel
        className="switch-label"
        htmlFor={id}
        backColor={toggled}
        widthSwitchLabel={widthSwitchLabel}
        heightSwitchLabel={heightSwitchLabel}
      >
        <SwitchButton
          className="switch-button"
          backColor={toggled}
          widthSwitchButton={widthSwitchButton}
          heightSwitchButton={heightSwitchButton}
        />
      </SwitchLabel>
    </>
  );
};

export default ToggleSwitch;
