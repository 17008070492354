import React from 'react';
import * as St from './styles';

export interface ICheckbox {
  id: any;
  name: string;
  handleClick: any;
  isChecked: boolean;
}

const Checkbox: React.FC<ICheckbox> = ({
  id,
  name,
  handleClick,
  isChecked,
}) => {
  return (
    <St.CheckBox
      id={id}
      name={name}
      onChange={handleClick}
      checked={isChecked}
    />
  );
};

export default Checkbox;
