import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

import * as S from '../../styles';

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
  subTitle?: string;
}

const BoxOperators: React.FC<IProps> = ({
  categories,
  series,
  widthGraphic = 960,
  heightGraphic = 300,
  subTitle = '',
}) => {
  const buildSeries = () => {
    let response = null;
    if (series === undefined || series.length <= 0) {
      response = [
        {
          name: 'Operators',
          data: [0],
        },
      ];
    } else {
      response = series;
    }
    return response;
  };

  const options = {
    chart: {
      width: '100%',
      height: 300,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '40%',
        distributed: true,
        borderRadius: 4,
        dataLabels: {
          position: 'top', // top, center, bottom
          maxItems: 100,
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#304758'],
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories || [0],
      labels: {
        show: true,
        rotate: -90,
        hideOverlappingLabels: true,
        showDuplicates: true,
        trim: false,
        minHeight: 100,
        maxHeight: 120,
        style: {
          colors: ['#304758'],
          fontSize: '12px',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: true,
      },
      position: 'bottom',
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      label: {
        show: true,
        formatter(val) {
          return val;
        },
      },
    },
  } as any;

  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>Operadores</h2>

          <span>
            {subTitle !== ''
              ? subTitle
              : `Última atualização: hoje às ${moment(subTitle).format(
                  'HH:mm',
                )}. Referente a data de ontem`}
          </span>
        </header>
        <S.ItemGraphic>
          <Chart
            type="bar"
            options={options}
            series={buildSeries()}
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxOperators;
