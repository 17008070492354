import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import ListRow from '~/components/listView/row';
import Loading from '~/components/loading';
import TitleApp from '~/components/title';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { IEmployee } from '~/models/employee';
import endpoints from '~/services/endpoints';
import { IDefaultReturn } from '~/utils/masks';

import * as St from './styles';

interface IForm {
  name: string;
  cpf: IDefaultReturn;
}

const USER_INFO_LABELS = [
  {
    label: 'Login',
    reference: 'email',
  },
  {
    label: 'Nome completo',
    reference: 'nome',
  },
  {
    label: 'CPF',
    reference: 'cpf',
  },
  {
    label: 'CPF',
    reference: 'cpf',
  },
  {
    label: 'Status',
    reference: 'usuarioAtivo',
  },
  {
    label: 'Perfil',
    reference: 'usuarioPerfis',
  },
];

const LABEL_SEARCH = 'usuarioPerfis';

const ConsultAttendant = () => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const [userList, setUserList] = useState([]);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const RequestCPF = useApi<any>(
    `${endpoints.consult.searchEmployeeByCpf}/${form.cpf?.unmasked}`,
    ApiMethod.GET,
  );

  const handleDataFiltering = (data) => {
    const result = USER_INFO_LABELS.find((el) => el.reference === data);
    if (result) return result.label;
    return null;
  };

  const handleMutation = (data: IEmployee) => {
    const user = Object.keys(data).map((el, index) => {
      const result = handleDataFiltering(el);
      let value;
      if (el === LABEL_SEARCH) {
        value = data[el];
      } else if (data[el] === data.usuarioAtivo) {
        if (data[el]) value = 'ATIVO';
        else value = 'INATIVO';
      } else {
        value = data[el];
      }

      if (result) {
        return {
          id: index,
          title: result,
          value,
          border: true,
          item: data,
          searchAction: LABEL_SEARCH === el ? goToDetail : null,
        };
      }

      return null;
    });

    return user.filter((a) => a !== null);
  };

  const handleSubmit = async () => {
    setLoading(true);

    let data = null;
    const dataCPF = await getUserByCPF();
    if (dataCPF) data = dataCPF;

    if (data) {
      let users;
      if (Array.isArray(data)) {
        users = data.map((el) => handleMutation(el));
        setUserList([...users]);
      } else {
        users = handleMutation(data);
        setUserList([users]);
      }
    } else {
      setUserList([]);
    }

    setLoading(false);
  };

  const getUserByCPF = async () => {
    const responseName = await RequestCPF.callApi();
    if (
      responseName.status === 200 &&
      responseName.data?.funcionarioResponse.length
    ) {
      return responseName.data.funcionarioResponse;
    }
    return null;
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const goToDetail = (_, info: IEmployee) =>
    history.push('/consult/disable/detail', { ...info });

  const handleDisableConsultButton = () =>
    form.cpf?.masked?.length !== 14 || isLoading;

  return (
    <>
      <Loading status={RequestCPF.loading} />
      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <Grid xs={2} gap={40}>
              <ContainerFlex>
                <TitleApp marginBottom={10}>
                  Consultar/Desativar usuário
                </TitleApp>

                <Input
                  label="CPF"
                  name="cpf"
                  placeholder="000.000.000-00"
                  maxLength={14}
                  mask={MaskTypes.CPF}
                  value={form.cpf?.masked}
                  onChange={handleChange}
                />
              </ContainerFlex>

              <ContainerFlex align="flex-end" row center marginBottom={20}>
                <Button
                  fitContent
                  onClick={handleSubmit}
                  disabled={handleDisableConsultButton()}
                  loading={isLoading}
                >
                  <span>Consultar</span>
                </Button>
              </ContainerFlex>
            </Grid>

            <br />
            {RequestCPF.status === 200 && !userList.length && (
              <ContainerFlex center marginTop={30}>
                <span>Nenhum usuário foi encontrado</span>
              </ContainerFlex>
            )}
            {userList && userList.map((i) => <ListRow key={i} item={i} />)}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ConsultAttendant;
