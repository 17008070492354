import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';

import Loading from '~/components/loading';
import TableList from '~/components/tableList';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { IEmployee } from '~/models/employee';
import endpoints from '~/services/endpoints';
import { cpfMask, IDefaultReturn } from '~/utils/masks';
import { IListColCampaing } from '~/views/LimitIncrease/model';

import * as St from './styles';

interface IForm {
  name: string;
  cpf: IDefaultReturn;
}

const USER_INFO_LABELS = [
  {
    label: 'Status',
    reference: 'usuarioAtivo',
  },
  {
    label: 'Nome completo',
    reference: 'nome',
  },
  {
    label: 'CPF',
    reference: 'cpf',
  },
];

const LABEL_SEARCH = 'usuarioAtivo';

const ConsultEmployee = () => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const [list, setList] = useState([]);
  const history = useHistory();

  const RequestCPF = useApi<any>(
    endpoints.consult.searchEmployeeByCpf.replace(
      'PARAM_CPF',
      form.cpf?.unmasked,
    ),
    ApiMethod.GET,
  );

  const RequestName = useApi<any>(
    `${endpoints.consult.searchEmployeeByName}/${form.name}`,
    ApiMethod.GET,
  );

  const GetUsers = useApi(endpoints.consult.getUsers, ApiMethod.GET, {}, true);
  const customerHeader = [
    {
      id: 1,
      value: 'Nome completo',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'CPF',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Status',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Perfis',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const handleSubmit = async () => {
    let data;

    const isCPF = form?.cpf?.masked?.length >= 14;

    if (isCPF) {
      data = await getUserByCPF();
    } else {
      data = await getUserByName();
    }

    if (data) {
      const newList = [];
      // let i = 0;

      data?.forEach((data) => {
        const item: IListColCampaing[] = [
          // {
          //   type: 'id',
          //   value: i++,
          //   maxChar: 5,
          //   textAlign: 'center',
          // },
          {
            border: true,
            title: 'Nome completo',
            value: data.nome,
            maxChar: 20,
          },
          {
            border: true,
            title: 'CPF',
            value: data.cpf ? cpfMask(data.cpf).masked : '',
            maxChar: 15,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Status',
            value: data.usuarioAtivo ? 'ATIVO' : 'INATIVO',
            maxChar: 15,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Perfis',
            value: data.usuarioPerfis.join(' / '),
            maxChar: 10,
            textAlign: 'center',
          },
          {
            item: data,
            searchAction: goToDetail,
          },
        ];

        newList.push(item);
      });

      setList(newList);
    } else {
      setList([]);
    }
  };

  const getUserByName = async () => {
    const responseName = await RequestName.callApi();
    if (
      responseName.status === 200 &&
      responseName.data?.funcionarioResponse.length
    ) {
      return responseName.data.funcionarioResponse;
    }
    return null;
  };

  const getUserByCPF = async () => {
    const responseName = await RequestCPF.callApi();
    if (
      responseName.status === 200 &&
      responseName.data?.funcionarioResponse.length
    ) {
      return responseName.data.funcionarioResponse;
    }
    return null;
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const goToRegister = () => {
    if (form.cpf?.masked.length === 14) {
      history.push('/consult/employee/register', { cpf: form.cpf });
    } else {
      history.push('/consult/employee/register');
    }
  };

  const goToDetail = (_, info: IEmployee) => {
    console.log(info);
    history.push('/consult/employee/detail', {
      ...info,
    });
  };

  const handleDisableConsultButton = () =>
    !form.name?.trim().length && form.cpf?.masked?.length !== 14;

  return (
    <>
      <Loading status={RequestCPF.loading || RequestName.loading} />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="Nome"
                name="name"
                value={form.name}
                onChange={handleChange}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name="cpf"
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
                value={form.cpf?.masked}
                onChange={handleChange}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <ContainerFlex row space gap="8">
                <ContainerFlex>
                  <Button
                    fitContent
                    onClick={handleSubmit}
                    disabled={handleDisableConsultButton()}
                    loading={RequestCPF.loading || RequestName.loading}
                    fullWidth
                  >
                    <span>Consultar</span>
                  </Button>
                </ContainerFlex>

                <ContainerFlex>
                  <Button fitContent onClick={goToRegister} fullWidth>
                    <span>Novo Funcionário</span>
                  </Button>
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {!list.length ? (
          <St.BoxCard>
            <ContainerFlex center>
              <span>Funcionário não encontrado</span>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex row padding={16}>
              {(RequestName.status === 200 || RequestCPF.status === 200) &&
              !list.length ? (
                <ContainerFlex center marginTop={30}>
                  <span>Funcionário não localizado</span>
                </ContainerFlex>
              ) : null}
              {/* {userList && userList.map((i) => <ListRow key={i} item={i} />)} */}
              {list.length > 0 && (
                <TableList customerHeader={customerHeader} list={list} />
              )}
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default ConsultEmployee;
