import { icons } from '~/assets';

export const EXPIRATION_DAYS = [
  {
    id: '01',
    value: '1',
  },
  {
    id: '05',
    value: '5',
  },
  {
    id: '10',
    value: '10',
  },
  {
    id: '15',
    value: '15',
  },
  {
    id: '20',
    value: '20',
  },
  {
    id: '25',
    value: '25',
  },
];

export const CREDIT_CARDS = [
  {
    name: 'RED',
    icon: icons.redCard,
  },
  {
    name: 'ROSE',
    icon: icons.roseCard,
  },
  {
    name: 'BLUE',
    icon: icons.blueCard,
  },
  {
    name: 'LUDICO',
    icon: icons.whiteCard,
  },
];
