/* eslint-disable no-param-reassign */
import { IListColumn } from '~/models/listCol';
import { cpfMask, phoneMask, currencyMask } from '~/utils/masks';

export const HeaderReportVendasLeads = [
  {
    id: 1,
    value: 'Nome do Cliente',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Celular',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Operador(a) de Venda',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Valor do Empréstimo',
    maxChar: 8,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Quantidade de Parcelas',
    maxChar: 8,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Valor da Transação',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 8,
    value: 'Status',
    maxChar: 10,
    textAlign: 'center',
  },
];

export const BodyReportVendasLeads = (data: any) => {
  // let x = 0;
  const items = [];
  data?.forEach((i) => {
    if (i.cliente !== undefined) {
      const item: IListColumn[] = [
        {
          value: i.cliente,
          maxChar: 15,
        },
        {
          value: i.cpf ? cpfMask(i.cpf).masked : '',
          maxChar: 13,
          textAlign: 'center',
        },
        {
          value: phoneMask(i.celular).masked,
          maxChar: 13,
          textAlign: 'center',
        },
        {
          value: i.operador?.nome,
          maxChar: 14,
          textAlign: 'center',
        },
        {
          value: `R$ ${currencyMask(i.valorLiberado)}`,
          maxChar: 7,
          textAlign: 'center',
        },
        {
          value: `${i.numeroParcelas}x`,
          maxChar: 13,
          textAlign: 'center',
        },
        {
          value: `R$ ${currencyMask(i.valorTransacao)}`,
          maxChar: 15,
          textAlign: 'center',
        },
        {
          value: i.statusNovoSaque_i18n,
          maxChar: 10,
          textAlign: 'center',
        },
      ];
      items.push(item);
    }
  });
  return items;
};

export const BodyReportVendasLeadsCSV = (data: any) => {
  const dataCSV = [];

  data?.forEach((csv) => {
    if (csv.uf !== undefined) {
      dataCSV.push({
        cliente: csv.cliente,
        cpf: csv.cpf,
        email: csv.email,
        celular: csv.celular,
        operador: csv.operador,
        numeroParcelas: csv.numeroParcelas,
        valorTransacao: csv.valorTransacao,
        valorEmprestimo: csv.valorLiberado,
        taxaJurosAplicada: csv.taxaJuros,
        valorParcela: csv.valorParcela,
        dataContratacao: csv.dataContratacao,
        dataDoPagamento: csv.dataPagamento,
        statusNovoSaque_i18n: csv.statusNovoSaque_i18n,
        uf: csv.endereco.uf,
      });
    }
  });
  return dataCSV;
};
