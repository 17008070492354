/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Checkbox from '~/components/checkbox';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';

import { useProposal } from '~/hooks/contexts/useProposal';
import { IProposal, IProposalT } from '~/models/proposal';
import { transformInGlobalDate, transformInNormalDate } from '~/utils/date';
import { setFormState } from '~/utils/form';
import {
  cepMask,
  cpfMask,
  currencyMask,
  currencyUnmasked,
  phoneMask,
} from '~/utils/masks';

interface IComponents {
  data: IProposal;
}

const ProposalResult: React.FC<IComponents> = ({ data }) => {
  const { proposal, addProposal } = useProposal();
  const [form, setForm] = useState<IProposalT>({} as IProposalT);

  const [hasFormalJob, setHasFormalJob] = useState(
    data.proposta?.empregoFormal,
  );

  useEffect(() => {
    if (data) {
      loadData();
      setHasFormalJob(data.proposta?.empregoFormal);
    }
  }, [data]);

  useEffect(() => {
    const rendaInformadaMask = currencyMask(form.rendaInformada);

    const newForm = {
      dataNascimento: transformInGlobalDate(form.dtNascimento?.masked),
      empregoFormal: hasFormalJob,
      empresa: form.empresa,
      endereco: {
        bairro: form.bairro,
        cep: form.cep?.unmasked,
        cidade: form.cidade,
        complemento: form.complemento,
        logradouro: form.logradouro,
        numero: form.numero,
        pais: 'BRASIL',
        uf: 'AM',
      },
      profissao: hasFormalJob ? form.profissao : '',
      nome: form.name,
      propostaId: data.idProposta,
      telefone: form.phone?.unmasked,
      rendaInformada: currencyUnmasked(rendaInformadaMask),
    };

    addProposal({ ...proposal, ...newForm });
  }, [form, hasFormalJob]);

  const loadData = () => {
    const obj = {
      name: data.proposta?.nome,
      cpf: cpfMask(data.proposta?.cpf),
      phone: phoneMask(data.proposta?.celular),
      dtNascimento: data.proposta?.dataNascimento
        ? transformInNormalDate(data.proposta?.dataNascimento)
        : { masked: '', unmasked: '' },
      empresa: data.proposta?.empresa ? data.proposta?.empresa : '',
      loja: data.proposta?.loja ? data.proposta?.loja : '',
      cep: cepMask(data.proposta?.endereco?.cep),
      logradouro: data.proposta?.endereco?.logradouro
        ? data.proposta?.endereco?.logradouro
        : '',
      numero: data.proposta?.endereco?.numero
        ? data.proposta?.endereco?.numero
        : '',
      complemento: data.proposta?.endereco?.complemento
        ? data.proposta?.endereco?.complemento
        : '',
      cidade: data.proposta?.endereco?.cidade
        ? data.proposta?.endereco?.cidade
        : '',
      bairro: data.proposta?.endereco?.bairro
        ? data.proposta?.endereco?.bairro
        : '',
      rendaInformada: currencyMask(data.proposta?.rendaInformada),
      profissao: data.proposta?.profissao ? data.proposta?.profissao : '',
    };

    setForm(obj);
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ target: { value, name } }, form, setForm);
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <ContainerFlex marginLeft={26}>
      <Input
        label="CPF"
        value={form.cpf?.masked}
        bulletColor={data.proposta?.cpf === data.spc?.cpf ? 'green' : 'red'}
      />
      <Input
        label="Nome completo"
        bulletColor={data.proposta?.nome === data.spc?.nome ? 'green' : 'red'}
        name="name"
        value={form.name}
        onChange={handleChange}
      />
      <Input
        label="Telefone"
        value={form.phone?.masked}
        name="phone"
        mask={MaskTypes.PHONE}
        maxLength={15}
        onChange={handleChange}
        placeholder="(00) 00000-0000"
        bulletColor={
          phoneMask(data.proposta?.celular).masked ===
          phoneMask(data.spc?.celular).masked
            ? 'green'
            : 'red'
        }
      />
      <Input
        label="Data de nascimento"
        value={form.dtNascimento?.masked}
        name="dtNascimento"
        placeholder="DD/MM/YYYY"
        mask={MaskTypes.DATE}
        onChange={handleChange}
        maxLength={10}
        bulletColor={
          data.proposta?.dataNascimento === data.spc?.dataNascimento
            ? 'green'
            : 'red'
        }
      />

      <Input
        label="Loja"
        value={form.empresa}
        name="loja"
        onChange={handleChange}
        bulletColor={
          data.proposta?.empresa === data.spc?.empresa ? 'green' : 'red'
        }
      />

      <Input
        label="CEP"
        value={form.cep?.masked}
        name="cep"
        mask={MaskTypes.CEP}
        maxLength={9}
        onChange={handleChange}
        placeholder="00000-000"
        bulletColor={
          data.proposta?.endereco?.cep === data.spc?.endereco?.cep
            ? 'green'
            : 'red'
        }
      />
      <Input
        label="Endereço"
        value={form.logradouro}
        name="logradouro"
        onChange={handleChange}
        bulletColor={
          data.proposta?.endereco?.logradouro === data.spc?.endereco?.logradouro
            ? 'green'
            : 'red'
        }
      />
      <div>
        <Grid xs={1} sm={2} gap={10}>
          <ContainerFlex>
            <Input
              label="Número"
              value={form.numero}
              name="numero"
              onChange={handleChange}
              bulletColor={
                data.proposta?.endereco?.numero === data.spc?.endereco?.numero
                  ? 'green'
                  : 'red'
              }
            />
          </ContainerFlex>

          <ContainerFlex>
            <Input
              label="Complemento"
              value={form.complemento}
              name="complemento"
              onChange={handleChange}
              bulletColor={
                data.proposta?.endereco?.complemento ===
                data.spc?.endereco?.complemento
                  ? 'green'
                  : 'red'
              }
            />
          </ContainerFlex>
        </Grid>

        <Grid xs={1} sm={2} gap={10}>
          <ContainerFlex>
            <Input
              label="Cidade"
              value={form.cidade}
              name="cidade"
              onChange={handleChange}
              bulletColor={
                data.proposta?.endereco?.cidade === data.spc?.endereco?.cidade
                  ? 'green'
                  : 'red'
              }
            />
          </ContainerFlex>

          <ContainerFlex>
            <Input
              label="Bairro"
              value={form.bairro}
              name="bairro"
              onChange={handleChange}
              bulletColor={
                data.proposta?.endereco?.bairro === data.spc?.endereco?.bairro
                  ? 'green'
                  : 'red'
              }
            />
          </ContainerFlex>
        </Grid>
      </div>

      <Input
        label="Renda Informada"
        name="rendaInformada"
        mask={MaskTypes.CURRENCY}
        value={String(form.rendaInformada)}
        onChange={handleChange}
        bulletColor={
          data.proposta?.rendaInformada === data.spc?.rendaPresumida
            ? 'green'
            : 'red'
        }
      />

      <Checkbox
        message="Possui emprego formal"
        readOnly
        value={hasFormalJob}
        bulletColor={
          data.proposta?.empregoFormal === data.spc?.empregoFormal
            ? 'green'
            : 'red'
        }
      />

      <br />

      <Input
        label="Informação profissional"
        name="profissao"
        value={String(form.profissao)}
        onChange={handleChange}
        readOnly={!hasFormalJob}
        bulletColor={
          data.proposta?.profissao === (data.spc?.profissao || '') &&
          data.proposta?.empregoFormal === data.spc?.empregoFormal
            ? 'green'
            : 'red'
        }
      />
    </ContainerFlex>
  );
};

export default ProposalResult;
