/* eslint-disable react/jsx-pascal-case */
import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Dialog from '~/components/dialog';
import Loading from '~/components/loading';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { ConsultFindFinancingResponse } from '~/services/consult/user/invoice/antecipationInvoice/response';
import Endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import {
  cpfMask,
  cpfUnmask,
  creditCardUnmask,
  creditCardMask,
  currencyMask,
} from '~/utils/masks';
import SelectParcelsQuantity from './components/selectParcelsQuantity';
import { icons } from '~/assets';

import * as St from './styles';
import Container from '~/components/container';

interface Form {
  cpf: FormField;
  numeroCartao: FormField;
}

interface IlistAntecipacion {
  border?: boolean;
  id?: any;
  title?: string;
  value?: any;
  item?: any;
  valueColor?: string;
  searchAction?: Function;
  maxChar?: number;
  scratched?: boolean;
  reportView?: boolean;
  icon?: string;
  origin?: string;
  fontsize?: string;
  type?: string;
  textAlign?: string;
  marginLeft?: number;
}

const AntecipationPurchase: React.FC = () => {
  const history = useHistory<any>();
  const locationState = history.location.state;
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [researched, setResearched] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessageError, setDialogMessageError] = useState('');
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogMessageSuccess, setDialogMessageSuccess] = useState('');
  const [cpfhistory, setCpfHistory] = useState('');

  const [form, setForm] = useState<Form>({
    cpf: { name: 'cpf', value: locationState?.cpf || '' },
    numeroCartao: { name: 'numeroCartao', value: '' },
  });

  const RequestFindFinancing = useApi<ConsultFindFinancingResponse>(
    Endpoints.invoices.getSearchPurchaseFinancing
      .replace('PARAM_CPF', cpfUnmask(form.cpf.value))
      .replace('cpf=&', '')
      .replace(
        'PARAM_NUMERO_CARTAO',
        creditCardUnmask(form.numeroCartao.value),
      ),
    ApiMethod.GET,
  );

  const RequestHistoryFinancing = useApi<ConsultFindFinancingResponse>(
    Endpoints.invoices.getAntecipationPurchaseHistory.replace(
      'PARAM_CPF',
      cpfhistory,
    ),
    ApiMethod.GET,
  );

  const searchFinancing = async () => {
    setData([]);
    setResearched(false);

    const response = await RequestFindFinancing.callApi();

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      console.log(data);

      setCpfHistory(data[0]?.cpf);

      const newData = data.filter((item) => item.qtdParcelasAPagar !== 0);

      setData(newData);
      setResearched(true);
    } else {
      setCpfHistory('');
      setError(true);
    }
  };

  const searchHistoryFinancing = async () => {
    const response = await RequestHistoryFinancing.callApi();

    if (response.status >= 200 && response.status < 300) {
      const { data }: any = response;

      const newData = data.filter((item) => item.tipoAntecipacao === 'COMPRA');

      const newList = [];
      let i = 0;

      newData?.forEach((data) => {
        const item: IlistAntecipacion[] = [
          {
            type: 'id',
            value: i++,
            maxChar: 5,
            textAlign: 'center',
          },
          {
            value: data.status,
            maxChar: 20,
          },
          {
            value: data.parcelasAntecipadas,
            maxChar: 20,
          },
          {
            value: `R$ ${currencyMask(data.valorAntecipacao)}`,
            maxChar: 20,
          },
          {
            value: data.dataPagamento
              ? moment(data.dataPagamento).format('DD/MM/YYYY')
              : '-',

            maxChar: 20,
          },
        ];

        newList.push(item);
      });

      if (newList.length > 0) {
        history.push('/invoice/antecipation/purchase/history', {
          data: newList,
          dataClient: data[0],
        });
      } else {
        setDialogError(true);
        setDialogMessageError('Nenhum histórico para exibir.');
      }
    } else {
      setDialogError(true);
      setDialogMessageError('Erro ao exibir o histórico.');
    }
  };

  const detailHeader = [
    {
      id: 1,
      value: 'Nome Completo',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 2,
      value: 'CPF',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 3,
      value: 'Número do cartão',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 4,
      value: 'Data da compra',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 5,
      value: 'Valor da compra',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 6,
      value: 'Valor pendente',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 7,
      value: 'Parcelas pendentes',
      maxChar: 50,
      textAlign: 'left',
    },
  ];

  useEffect(() => {
    if (locationState?.cpf) {
      searchFinancing();
    }
  }, [locationState]);

  const CollapsibleItem = ({ data, index }) => {
    const [selected, setSelected] = useState(null);
    const contentEl = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);

    const handleToggle = async (id) => {
      if (selected === index) {
        setSelected(null);
      } else {
        setSelected(index);
      }
    };

    return (
      <>
        <tr key={data.idCompra} onClick={() => handleToggle(data.idCompra)}>
          <St.bodyData maxChar={50} textAlign="left" title={data.nomeCompleto}>
            {data.nomeCompleto}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.cpf}>
            {cpfMask(data.cpf).masked}
          </St.bodyData>
          <St.bodyData
            maxChar={20}
            textAlign="left"
            title={creditCardMask(data.cartao).masked}
          >
            {creditCardMask(data.cartao).masked}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.dataCompra}>
            {moment(data.dataCompra).format('DD/MM/YYYY')}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.valorCompra}>
            {`R$ ${currencyMask(data.valorCompra)}`}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.valorPendente}>
            {`R$ ${currencyMask(data.valorPendente)}`}
          </St.bodyData>
          <St.bodyData textAlign="left" title={data.qtdParcelasAPagar}>
            {data.qtdParcelasAPagar}
          </St.bodyData>

          <St.Icon
            src={icons.arrowBlack}
            width={25}
            open={selected === index}
          />
        </tr>

        <tr style={{ height: selected === index ? 'auto' : '0px' }}>
          <td colSpan={9} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
            <St.ContentWraper
              ref={contentEl}
              style={
                selected === index
                  ? { height: contentEl?.current?.scrollHeight }
                  : { height: '0px' }
              }
            >
              <St.Content>
                {data.qtdParcelasAPagar > 0 && (
                  <>
                    <Loading status={loading} />
                    <ContainerFlex center padding={16}>
                      <SelectParcelsQuantity
                        data={data}
                        qtdParcelasAPagar={data.qtdParcelasAPagar}
                        searchFinancing={searchFinancing}
                      />
                    </ContainerFlex>
                  </>
                )}

                {data.qtdParcelasAPagar < 0 && (
                  <ContainerFlex center marginTop={20} marginBottom={30}>
                    <p>Antecipação indisponível</p>
                  </ContainerFlex>
                )}
              </St.Content>
            </St.ContentWraper>
          </td>
        </tr>
        <br />
      </>
    );
  };

  return (
    <>
      <Loading
        status={RequestFindFinancing.loading || RequestHistoryFinancing.loading}
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogSuccess}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogSuccess(false);
          searchFinancing();
        }}
        message={dialogMessageSuccess}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
        }}
        message={dialogMessageError}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                maxLength={14}
                name={form.cpf.name}
                value={form.cpf.value}
                placeholder="000.000.000-00"
                onChange={(e) => setFormState(e, form, setForm)}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Número do cartão"
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                value={form.numeroCartao.value}
                name={form.numeroCartao.name}
                onChange={(e) => setFormState(e, form, setForm)}
                mask={MaskTypes.CREDIT_CARD}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={searchFinancing}
                loading={RequestFindFinancing.loading}
                disabled={
                  (form.cpf.value.length !== 14 &&
                    form.numeroCartao.value.length !== 19) ||
                  RequestFindFinancing.loading
                }
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>

            <ContainerFlex padding={16}>
              {researched && (
                <Button
                  onClick={searchHistoryFinancing}
                  loading={RequestHistoryFinancing.loading}
                >
                  <span>Histórico de antecipação</span>
                </Button>
              )}
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {data?.length > 0 ? (
          <St.BoxCard>
            <>
              <St.MainHeader>Detalhes da compra</St.MainHeader>
              <table style={{ width: '100%', borderCollapse: 'separate' }}>
                <St.headerTable>
                  <tr>
                    {detailHeader.map((item) => (
                      <St.hederTitle
                        key={item.id}
                        maxChar={item.maxChar}
                        textAlign={item.textAlign}
                      >
                        {item.value}
                      </St.hederTitle>
                    ))}
                  </tr>
                </St.headerTable>
                <br />
                <St.bodyTable>
                  {data.map((item, index) => (
                    <>
                      <CollapsibleItem data={item} index={index} />
                    </>
                  ))}
                </St.bodyTable>
              </table>
            </>
          </St.BoxCard>
        ) : (
          <>
            {(error ||
              RequestFindFinancing.status > 300 ||
              (data?.length === 0 && researched)) && (
              <St.BoxCard>
                <ContainerFlex center>
                  <St.TitleCategory>
                    Não há resultados para mostrar aqui.
                  </St.TitleCategory>
                </ContainerFlex>
              </St.BoxCard>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default AntecipationPurchase;
