import moment from 'moment';
import React from 'react';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';
import { IReasonDisputeDetail } from '~/services/consult/dispute/detail/response';
import { cpfMask } from '~/utils/masks';

export interface ICardReason {
  item: IReasonDisputeDetail & { cpf: string };
  fullWidth?: boolean;
}

const CardReason: React.FC<ICardReason> = ({ item, fullWidth }) => (
  <CardView
    padding={20}
    marginBottom={12}
    width={fullWidth ? 80 : 0}
    widthPixel={fullWidth ? 0 : 300}
  >
    <ContainerFlex>
      <ContainerFlex row space>
        <span>CPF:</span>
        <span>{cpfMask(item?.cpf).masked}</span>
      </ContainerFlex>

      <br />

      <ContainerFlex row space>
        <span>DATA:</span>
        <span>
          {String(moment(item?.dataInclusao).format('DD/MM/YYYY HH:mm'))}
        </span>
      </ContainerFlex>

      <br />

      <ContainerFlex row space>
        <span>MOTIVO:</span>
        <span
          style={{
            wordBreak: 'break-all',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {item?.motivoDisputa}
        </span>
      </ContainerFlex>
    </ContainerFlex>
  </CardView>
);

export default CardReason;
