import React, { useState, ReactNode, useEffect } from 'react';
import { TabsContainer, TabHeader, TabButton, TabContent } from './styles';

interface TabsProps {
  children: ReactNode[];
  initialTab?: number;
}

interface TabChildProps {
  label: string;
  children: ReactNode;
  disabled?: boolean;
}

const Tabs: React.FC<TabsProps> & { Tab: React.FC<TabChildProps> } = ({
  children,
  initialTab = 0,
}) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  return (
    <TabsContainer>
      <TabHeader>
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            const isDisabled = child.props.disabled || false;
            return (
              <TabButton
                active={activeTab === index}
                disabled={isDisabled}
                onClick={() => {
                  if (!isDisabled) setActiveTab(index);
                }}
              >
                {child.props.label}
              </TabButton>
            );
          }
          return null;
        })}
      </TabHeader>
      <TabContent>{React.Children.toArray(children)[activeTab]}</TabContent>
    </TabsContainer>
  );
};

const Tab: React.FC<TabChildProps> = ({ children }) => {
  return <div>{children}</div>;
};

Tabs.Tab = Tab;

export default Tabs;
