/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { InputHTMLAttributes, useState, useEffect, useRef } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { icons } from '~/assets';
import Bullet from '~/components/bullet';
import ContainerFlex from '~/components/containerFlex';
import * as St from './styles';

export enum MaskTypes {
  CPF = 'cpfMask',
  CEP = 'cepMask',
  DATE = 'dateMask',
  HOUR_MIN_SEC = 'hourMinSecMask',
  PHONE = 'phoneMask',
  CREDIT_CARD = 'creditCardMask',
}

export interface IOption {
  readonly id?: string | number;
  value: string;
  disabled?: boolean;
}

export type InputProps = {
  name?: string;
  id?: string;
  label?: string;
  labelFor?: string;
  icon?: any;
  bulletColor?: string;
  disabled?: boolean;
  errorMessage?: string;
  error?: string;
  options: IOption[];
  value?: string;
  typeBenefit?: string;
  typeSelect?: string;
  smallSize?: boolean;
  mediumSize?: boolean;
  onChange?: (any: any) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  label,
  labelFor = '',
  value,
  typeBenefit,
  typeSelect,
  errorMessage,
  onChange,
  readOnly,
  options,
  bulletColor,
  disabled,
  smallSize,
  mediumSize,
}: InputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef<HTMLDivElement>();

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (selectRef.current) {
        if (!selectRef.current?.contains(e.target as Node)) {
          setIsFocused(false);
        }
      }
    });
  }, []);

  const handleSelect = (el: IOption): void => {
    if (typeBenefit === 'VENDAS_PROTEGE') {
      onChange(el.value);
    } else if (
      typeSelect === 'EVENTS_PRODUCT' ||
      typeSelect === 'EVENTS_TYPE'
    ) {
      onChange({ id: el.id, value: el.value });
    } else {
      onChange(el.id);
    }
    setIsFocused(false);
  };

  const handleToggleFocus = () => {
    if (!readOnly) setIsFocused((prev) => !prev);
  };

  useEffect(() => {
    setIsFocused(false);
  }, [value]);

  let valueSelected: IOption;

  if (typeBenefit === 'VENDAS_PROTEGE') {
    if (value !== 'TODOS')
      valueSelected = options?.find((el) => el?.value === value);
    else valueSelected = options?.find((el) => el?.id === value);
  } else if (typeSelect === 'EVENTS_PRODUCT') {
    valueSelected =
      options?.find((el) => el?.value === value) ||
      options?.find((el) => el?.value === 'Benefício');
  } else if (typeSelect === 'EVENTS_TYPE') {
    valueSelected =
      options?.find((el) => el?.value === value) ||
      options?.find((el) => el?.value === 'Lead');
  } else {
    valueSelected = options?.find((el) => el?.id === value);
  }

  return (
    <ContainerFlex row flex="0">
      <St.Container
        disabled={disabled}
        isFocused={isFocused}
        isSmall={smallSize}
        isMedium={mediumSize}
      >
        {!!label && <label htmlFor={labelFor}>{label}</label>}
        <ContainerFlex row space ref={selectRef} onClick={handleToggleFocus}>
          <St.Value>{valueSelected?.value}</St.Value>
          <St.ContainerOptions
            isFocused={isFocused}
            isSmall={smallSize}
            isMedium={mediumSize}
          >
            <div>
              {options?.map((el) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  style={{
                    pointerEvents: el.disabled ? 'none' : 'all',
                    opacity: el.disabled ? 0.7 : 1,
                  }}
                  className="option"
                  onClick={() => (el.disabled ? null : handleSelect(el))}
                >
                  {el.value}
                </div>
              ))}
            </div>
          </St.ContainerOptions>
          {!disabled && <FiChevronDown />}
        </ContainerFlex>
        {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
      </St.Container>
      {bulletColor && <Bullet color={bulletColor} margin={8} marginTop={27} />}
    </ContainerFlex>
  );
};

export default Input;
