export enum DisputeTypes {
  ESTORNADA = 'ESTORNADA',
  NEGADA = 'NEGADA',
}

export interface IReasonDispute {
  dataInclusao?: string;
  motivoDisputa?: string;
}

export interface IDisputeCollection extends IReasonDispute {
  id?: string;
  motivos?: IReasonDispute[];
  numeroCartao?: string;
  status?: DisputeTypes;
  transacao?: {
    data?: string;
    horario?: string;
    idTransacao?: string;
    loja?: string;
    quantidadeParcelas?: string;
    valor?: string;
  };
}
