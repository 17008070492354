/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import Checkbox from '~/components/checkbox';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Select from '~/components/select';
import Input, { MaskTypes } from '~/components/input';
import { useProposal } from '~/hooks/contexts/useProposal';
import { IProposal, IProposalT } from '~/models/proposal';
import { transformInGlobalDate, transformInNormalDate } from '~/utils/date';
import { setFormState } from '~/utils/form';
import {
  cepMask,
  cpfMask,
  currencyMask,
  currencyUnmasked,
  nameMask,
  phoneMask,
} from '~/utils/masks';
import { IValidators } from '~/utils/validators';
import STATES from '~/assets/mocks/states.json';

interface IComponents {
  data: IProposal;
  errors: IValidators;
}

const ProposalResult: React.FC<IComponents> = ({ data, errors }) => {
  const { proposal, addProposal } = useProposal();
  const [form, setForm] = useState<IProposalT>({} as IProposalT);

  const [hasFormalJob, setHasFormalJob] = useState(
    data.proposta?.empregoFormal,
  );

  const [hasAposentandoPensionista, setHasAposentadoPensionista] = useState(
    data.proposta?.aposentadoPensionista,
  );

  useEffect(() => {
    if (data) {
      loadData();
      setHasFormalJob(data.proposta?.empregoFormal);
      setHasAposentadoPensionista(data.proposta?.aposentadoPensionista);
    }
  }, [data]);

  useEffect(() => {
    const rendaInformadaMask = currencyMask(form.rendaInformada);

    const newForm = {
      dataNascimento: transformInGlobalDate(form.dtNascimento?.masked),
      empregoFormal: hasFormalJob,
      aposentadoPensionista: hasAposentandoPensionista,
      empresa: form.empresa,
      endereco: {
        bairro: form.bairro,
        cep: form.cep?.unmasked,
        cidade: form.cidade,
        complemento: form.complemento,
        logradouro: form.logradouro,
        numero: form.numero,
        pais: 'BRASIL',
        uf: form.uf,
      },
      profissao: hasFormalJob ? form.profissao : '',
      nomeEmpresa: form.nomeEmpresa,
      nome: nameMask(form.name),
      nomeSocial: form.socialName,
      propostaId: data.idProposta,
      telefone: form.phone?.unmasked,
      celular2: form.phone2?.unmasked,
      email: form.email,
      rendaInformada: currencyUnmasked(rendaInformadaMask),
    };

    addProposal({ ...proposal, ...newForm });
  }, [form, hasFormalJob]);

  const getPhoneBulletColor = () => {
    return phoneMask(data.proposta?.celular).masked ===
      phoneMask(data.spc?.celular).masked
      ? 'green'
      : 'red';
  };

  const getComplementBulletColor = () => {
    return data.proposta?.endereco?.complemento?.toLowerCase() ===
      data.spc?.endereco?.complemento?.toLowerCase()
      ? 'green'
      : 'red';
  };

  const getJobBulletColor = () => {
    return data.proposta?.profissao === (data.spc?.profissao || '') &&
      data.proposta?.empregoFormal === data.spc?.empregoFormal
      ? 'green'
      : 'red';
  };

  const loadData = () => {
    const obj = {
      name: data.proposta?.nome,
      socialName: data?.proposta?.nomeSocial ? data?.proposta?.nomeSocial : '',
      cpf: cpfMask(data.proposta?.cpf),
      email: data.proposta?.cliente?.email,
      uf: data.proposta?.endereco?.uf,
      phone: phoneMask(data.proposta?.celular),
      phone2: phoneMask(data.proposta?.celular2),
      dtNascimento: data.proposta?.dataNascimento
        ? transformInNormalDate(data.proposta?.dataNascimento)
        : { masked: '', unmasked: '' },
      empresa: data.proposta?.empresa ? data.proposta?.empresa : '',
      loja: data.proposta?.loja ? data.proposta?.loja : '',
      cep: cepMask(data.proposta?.endereco?.cep),
      logradouro: data.proposta?.endereco?.logradouro
        ? data.proposta?.endereco?.logradouro
        : '',
      numero: data.proposta?.endereco?.numero
        ? data.proposta?.endereco?.numero
        : '',
      complemento: data.proposta?.endereco?.complemento
        ? data.proposta?.endereco?.complemento
        : '',
      cidade: data.proposta?.endereco?.cidade
        ? data.proposta?.endereco?.cidade
        : '',
      bairro: data.proposta?.endereco?.bairro
        ? data.proposta?.endereco?.bairro
        : '',
      rendaInformada: currencyMask(data.proposta?.rendaInformada),
      profissao: data.proposta?.profissao ? data.proposta?.profissao : '',
      nomeEmpresa: data.proposta?.nomeEmpresa ? data.proposta?.nomeEmpresa : '',
    };

    setForm(obj);
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ target: { value, name } }, form, setForm);
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (value, name) => {
    setFormState({ target: { value, name } }, form, setForm);
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <ContainerFlex marginLeft={26}>
      <Input
        label="CPF"
        value={form.cpf?.masked}
        bulletColor={data.proposta?.cpf === data.spc?.cpf ? 'green' : 'red'}
      />
      <Input
        label="Nome completo"
        edit
        bulletColor={
          data.proposta?.nome?.toLowerCase() === data.spc?.nome?.toLowerCase()
            ? 'green'
            : 'red'
        }
        name="name"
        value={nameMask(form.name)}
        onChange={handleChange}
      />
      <Input
        label="Telefone"
        value={form.phone?.masked}
        name="phone"
        mask={MaskTypes.PHONE}
        maxLength={15}
        onChange={handleChange}
        placeholder="(00) 00000-0000"
        edit
        bulletColor={getPhoneBulletColor()}
      />
      <Input
        label="Telefone 2"
        value={form.phone2?.masked}
        name="phone2"
        mask={MaskTypes.PHONE}
        maxLength={15}
        onChange={handleChange}
        placeholder="(00) 00000-0000"
        edit
        bulletColor={getPhoneBulletColor()}
      />

      <Input
        label="E-mail"
        value={form.email?.trim()}
        name="email"
        edit
        type="email"
        required
        mask={MaskTypes.EMAIL}
        onChange={handleChange}
        bulletColor={
          data.proposta?.cliente?.email === data.spc?.email ? 'green' : 'red'
        }
        errorMessage={errors.email}
      />
      <Input
        label="Data de nascimento"
        value={form.dtNascimento?.masked}
        name="dtNascimento"
        placeholder="DD/MM/YYYY"
        mask={MaskTypes.DATE}
        onChange={handleChange}
        edit
        maxLength={10}
        bulletColor={
          data.proposta?.dataNascimento === data.spc?.dataNascimento
            ? 'green'
            : 'red'
        }
      />
      <Input
        label="CEP"
        value={form.cep?.masked}
        name="cep"
        mask={MaskTypes.CEP}
        maxLength={9}
        onChange={handleChange}
        placeholder="00000-000"
        edit
        bulletColor={
          data.proposta?.endereco?.cep === data.spc?.endereco?.cep
            ? 'green'
            : 'red'
        }
      />
      <Input
        label="Endereço"
        value={form.logradouro}
        name="logradouro"
        onChange={handleChange}
        edit
        bulletColor={
          data.proposta?.endereco?.logradouro?.toLowerCase() ===
          data.spc?.endereco?.logradouro?.toLowerCase()
            ? 'green'
            : 'red'
        }
      />
      <div>
        <Grid xs={1} md={3} gap={10}>
          <Input
            label="Bairro"
            value={form.bairro}
            name="bairro"
            onChange={handleChange}
            edit
            bulletColor={
              data.proposta?.endereco?.bairro?.toLowerCase() ===
              data.spc?.endereco?.bairro?.toLowerCase()
                ? 'green'
                : 'red'
            }
          />
          <Input
            label="Número"
            value={form.numero}
            name="numero"
            onChange={handleChange}
            edit
            bulletColor={
              data.proposta?.endereco?.numero === data.spc?.endereco?.numero
                ? 'green'
                : 'red'
            }
          />
          <Input
            label="Complemento"
            value={form.complemento}
            name="complemento"
            onChange={handleChange}
            edit
            bulletColor={getComplementBulletColor()}
          />
        </Grid>

        <Grid xs={1} lg={2} gap={10}>
          <Input
            label="Cidade"
            value={form.cidade}
            name="cidade"
            onChange={handleChange}
            edit
            bulletColor={
              data.proposta?.endereco?.cidade?.toLowerCase() ===
              data.spc?.endereco?.cidade?.toLowerCase()
                ? 'green'
                : 'red'
            }
          />
          <Select
            label="UF"
            onChange={(val) => handleSelect(val, 'uf')}
            value={form.uf}
            options={STATES}
            bulletColor={
              data.proposta?.endereco?.uf?.toLowerCase() ===
              data.spc?.endereco?.uf?.toLowerCase()
                ? 'green'
                : 'red'
            }
            errorMessage={errors.state}
          />
        </Grid>
      </div>

      <Input
        label="Renda Informada"
        name="rendaInformada"
        mask={MaskTypes.CURRENCY}
        value={String(form.rendaInformada)}
        onChange={handleChange}
        edit
        bulletColor={
          data.proposta?.rendaInformada === data.spc?.rendaPresumida
            ? 'green'
            : 'red'
        }
      />

      <Checkbox
        message="Possui emprego formal"
        value={hasFormalJob}
        onChangeStatus={(status) => setHasFormalJob(status)}
        bulletColor={
          data.proposta?.empregoFormal === data.spc?.empregoFormal
            ? 'green'
            : 'red'
        }
      />

      <br />

      <Input
        label="Informação profissional"
        name="profissao"
        value={String(form.profissao)}
        onChange={handleChange}
        readOnly={!hasFormalJob}
        edit
        bulletColor={getJobBulletColor()}
      />

      <br />

      <Input
        label="Nome da Empresa/Órgão onde trabalha"
        name="nomeEmpresa"
        value={String(form.nomeEmpresa)}
        onChange={handleChange}
        edit
        bulletColor="green"
      />

      <ContainerFlex marginBottom={20} marginTop={10}>
        <Checkbox
          message="Aposentado ou Pensionista"
          value={hasAposentandoPensionista}
          onChangeStatus={(status) => setHasAposentadoPensionista(status)}
          readonly
        />
      </ContainerFlex>
      <Input
        label="Nome Social"
        name="socialName"
        value={String(form.socialName)}
        onChange={handleChange}
        edit
        bulletColor="green"
      />
    </ContainerFlex>
  );
};

export default ProposalResult;
