import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';
import Button from '~/components/button';
import endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import * as St from './styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Container from '~/components/container';

interface Form {
  qtdFaturas?: FormField;
  limiteAlterado?: FormField;
}

const ActiveLimits: React.FC = () => {
  const [form, setForm] = useState<Form>({
    qtdFaturas: { name: 'qtdFaturas', value: '' },
    limiteAlterado: { name: 'limiteAlterado', value: '' },
  });
  const [error, setError] = useState<string | null>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const QTD_FATURAS = [
    { id: 'tresACinco', value: '03-05' },
    { id: 'seisAOito', value: '06-08' },
    { id: 'nove', value: '09' },
  ];

  const LIMITE_ALTERADO = [
    { id: 'SIM', value: 'SIM' },
    { id: 'NAO', value: 'NÃO' },
  ];

  // Request para API chamando o endpoint da criação do relatório
  const RequestFileDownload = useApi<Blob>(
    endpoints.report.getReportFileActiveLimits
      .replace('PARAM_FILTRO', `${form.qtdFaturas.value}`)
      .replace('PARAM_LIMITE', `${form.limiteAlterado.value}`),
    ApiMethod.GET,
  );

  // Método para consultar o retorno do relatório e fazer
  // o download automático do arquivo em XLS
  const onClickDownloadExcel = async () => {
    try {
      const responseReport = await RequestFileDownload.callApi();

      if (responseReport.status >= 200 && responseReport.status < 300) {
        const contentType = 'text/csv;charset=UTF-8';
        const url = window.URL.createObjectURL(
          new Blob(['\ufeff', responseReport.data], { type: contentType }),
        );
        const fileName = `LIMITES_ATIVOS_${form.qtdFaturas.value}_${form.limiteAlterado.value}.csv`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        setDialogIsOpen(true);
        setError('Não foi possível baixar o arquivo..');
      }
    } catch (error) {
      setDialogIsOpen(true);
      setError('Não há arquivos.');
    }
  };

  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={error}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Select
                label="Quantidade de últimas faturas pagas"
                name={form.qtdFaturas.name}
                value={form.qtdFaturas.value}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.qtdFaturas.name, value: e } },
                    form,
                    setForm,
                  );
                }}
                options={QTD_FATURAS}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Select
                label="Limite alterado"
                name={form.limiteAlterado.name}
                value={form.limiteAlterado.value}
                onChange={(e) => {
                  setFormState(
                    { target: { name: form.limiteAlterado.name, value: e } },
                    form,
                    setForm,
                  );
                }}
                options={LIMITE_ALTERADO}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                loading={RequestFileDownload.loading}
                onClick={onClickDownloadExcel}
                disabled={!form.qtdFaturas.value && !form.limiteAlterado.value}
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row space>
            {error && (
              <ContainerFlex center marginTop={30}>
                <St.TitleCategory>{error}</St.TitleCategory>
              </ContainerFlex>
            )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ActiveLimits;
