import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 298px;
  min-width: 298px;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 421px;
    min-width: 421px;
  }

  margin: 0 auto;
  padding: 24px 0;
  border-radius: 12px;
  background: #ecf1ff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const Inner = styled.div`
  white-space: nowrap;
  transition: transform 0.3s;
  display: flex;

  button {
    cursor: pointer;
    background: transparent;
    border: 0;
  }
`;

export const CarouselItem = styled.div`
  width: 228px;
  max-width: 228px;

  @media (max-width: 1200px) {
    width: 350px;
    max-width: 350px;
  }

  padding: 0 10px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
`;

interface DotsProps {
  active: boolean;
}

export const Dots = styled.span<DotsProps>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;

  ${(props) =>
    props.active &&
    css`
      border: 1px solid #f49432;
      background-color: #f49432;
    `}
`;
