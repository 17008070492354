import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import {
  WebSocketConfig,
  WebSocketProps,
} from '../Interfaces/webSocketInterfaces';
import Loading from '~/components/loading';
import Dialog from '~/components/dialog';
import { icons } from '~/assets/index';

const SharedServiceWebSocket: React.FC<WebSocketProps> = ({
  cpf,
  approvedEvent,
  typeEvent,
}) => {
  const history = useHistory();
  const [loadingWebSocket, setLoadingWebSocket] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const getSocketUrl = useCallback(() => {
    return new Promise<string | undefined>((resolve) => {
      if (approvedEvent) {
        setLoadingWebSocket(true);
        resolve(
          `${process.env.REACT_APP_WEBSOCKET_EVENT}/v1/websockets/clientes/alterar-tema-cartao/${cpf}`,
        );
      }
    });
  }, [approvedEvent]);

  const webSocketConfig: WebSocketConfig = {
    onOpen: () => console.log('open'),
    onClose: () => console.log('closed'),
  };

  useEffect(() => {
    let timeoutId: any;

    if (loadingWebSocket) {
      timeoutId = setTimeout(() => {
        if (!success) {
          setLoadingWebSocket(false);
          setSuccess(true);
          setMessage(
            `Aguarde mais uns instantes para finalização do processo!`,
          );
        }
      }, 30000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loadingWebSocket, success]);

  const { lastMessage } = useWebSocket(getSocketUrl, webSocketConfig);

  useEffect(() => {
    if (lastMessage) {
      try {
        const data: any = JSON.parse(lastMessage?.data);
        if (data?.status === 'SUCESSO') {
          setLoadingWebSocket(false);
          setSuccess(true);
          setMessage(data?.mensagem);
        } else {
          setLoadingWebSocket(false);
          setSuccess(true);
          setMessage(data?.mensagem);
        }
      } catch (error) {
        console.error('Erro ao fazer o parse da string JSON:', error);
      }
    }
  }, [lastMessage]);

  return (
    <>
      <Loading
        webSocket={loadingWebSocket}
        status={loadingWebSocket}
        firstMessage="Aguarde alguns segundos..."
        secondMessage="Estamos alterando a categoria do cliente..."
      />
      <Dialog
        open={success}
        positiveAction={() => {
          setSuccess(false);
          history.goBack();
        }}
        message={message}
        positiveLabel="Ok"
        icon={icons.warning}
        widthInPixel={140}
      />
    </>
  );
};

export default SharedServiceWebSocket;
