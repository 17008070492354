/* eslint-disable no-param-reassign */
import moment from 'moment';
import { cpfMask } from '~/utils/masks';
import { IListColumn } from '~/models/listCol';

export const HeaderReportCancelCuida = [
  {
    id: 1,
    value: 'Nome cliente',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Venda',
    maxChar: 16,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Data Cancelamento',
    maxChar: 16,
  },
  {
    id: 5,
    value: 'Status cartão',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'UF',
    maxChar: 6,
    textAlign: 'center',
  },
];

export const BodyReportCancelCuida = (data: any) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    if (i.dataCancelamento !== undefined) {
      const item: IListColumn[] = [
        // {
        //   type: 'id',
        //   value: x++,
        //   maxChar: 5,
        // },
        {
          value: i.nomeCliente,
          maxChar: 20,
        },
        {
          value: i.cpf ? cpfMask(i.cpf).masked : '',
          maxChar: 15,
          textAlign: 'center',
        },
        {
          value: i.dataVenda ? moment(i.dataVenda).format('DD/MM/YYYY') : '-',
          maxChar: 16,
          textAlign: 'center',
        },
        {
          value: i.dataCancelamento
            ? moment(i.dataCancelamento).format('DD/MM/YYYY')
            : '-',
          maxChar: 16,
          textAlign: 'center',
        },
        {
          value: i.statusCartao,
          maxChar: 7,
          textAlign: 'center',
        },
        {
          value: i.uf,
          maxChar: 6,
          textAlign: 'center',
        },
      ];

      items.push(item);
    }
  });
  return items;
};

export const BodyReportCancelCuidaCSV = (data: any) => {
  const dataCSV = [];

  data.forEach((csv) => {
    dataCSV.push({
      nomeCliente: csv.nomeCliente,
      cpf: csv.cpf,
      dataVenda: csv.dataVenda,
      operador: csv.operador,
      loja: csv.loja,
      dataCancelamento: csv.dataCancelamento,
      motivoCancelamento: csv.motivoCancelamento,
      statusCartao: csv.statusCartao,
      uf: csv.uf,
    });
  });

  return dataCSV;
};

export const BodyTotalCancelCuida = (data: any) => {
  const totalTitular = data.reduce((acc, cur) => {
    return acc + 1;
  }, 0);
  return totalTitular;
};
