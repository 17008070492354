/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '~/components/container';
import * as St from './styles';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Button from '~/components/button';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { cpfCnpjMask, IDefaultReturn, phoneMask } from '~/utils/masks';
import { IListCol } from '~/components/listView/row/col';
import TableList from '~/components/tableList';
import Pagination from '~/components/pagination';
import Loading from '~/components/loading';
import { IListColumn } from '~/models/listCol';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';

interface Address {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  logradouro: string;
  numero: string;
  pais: string;
  uf: string;
}

interface IEstablishment {
  cpf: string;
  cnpj: string;
  email: string;
  endereco: Address;
  id: string;
  telefone: string;
}

interface IEstablishmentResponse {
  currentPage: number;
  data: IEstablishment[];
  pageSize: number;
  totalItens: number;
  totalPages;
}

interface IForm {
  name: string;
  cpfCnpj: IDefaultReturn;
}

const TeuPagConsult = () => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const [idEstablishment, setIdEstablishment] = useState('');
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>();
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const RequestSearchEstablishments = useApi<IEstablishmentResponse>(
    `${Endpoints.establishments.getSeacrhEstablishments}?cpfCnpj=${
      form.cpfCnpj?.unmasked ? form.cpfCnpj.unmasked : ''
    }&nome=${form?.name ? form.name : ''}&page=1&size=10`,
    ApiMethod.GET,
  );

  const RequestDeleteEstablishments = useApi(
    Endpoints.establishments.deleteEstablishments.replace(
      'PARAM_ID',
      idEstablishment,
    ),
    ApiMethod.DELETE,
  );

  console.log(idEstablishment);

  const customHeader = [
    {
      id: 1,
      value: 'Nome/Razão Social',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'CPF/CNPJ',
      maxChar: 18,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'E-mail',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Celular',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Excluir',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const isButtonDisabled = useMemo(() => {
    const isCpfOrCnpj =
      form.cpfCnpj?.masked?.length === 14 ||
      form.cpfCnpj?.masked?.length === 18;

    const isNameFilled = form.name && form.name.trim().length > 0;

    return !(isCpfOrCnpj || isNameFilled);
  }, [form]);

  const getSearchEstablishments = async () => {
    const response = await RequestSearchEstablishments.callApi();
    if (response.status === 200) {
      const {
        data,
        totalPages,
        currentPage,
        pageSize,
      }: IEstablishmentResponse = response.data;
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setPageSize(pageSize);
      return data;
    }
  };

  const handleSubmit = async () => {
    let data;

    const isCpfOrCnpj =
      form.cpfCnpj?.masked?.length === 14 ||
      form.cpfCnpj?.masked?.length === 18;

    const isNameFilled = form.name && form.name.trim().length > 0;

    if (isCpfOrCnpj || isNameFilled) {
      data = await getSearchEstablishments();
    }

    if (isCpfOrCnpj && isNameFilled) {
      setList([]);
      return;
    }

    if (data) {
      const newList = [];

      data.forEach((data) => {
        const items: IListColumn[] = [
          {
            border: true,
            title: 'Nome/Razão social',
            value: data.nome,
            maxChar: 20,
            textAlign: 'center',
          },
          {
            title: 'CPF/CNPJ',
            value: data.cpf
              ? cpfCnpjMask(data.cpf).masked
              : data.cnpj
              ? cpfCnpjMask(data.cnpj).masked
              : '',
            textAlign: 'center',
          },
          {
            title: 'E-mail',
            value: data.email,
            textAlign: 'center',
            maxChar: 20,
          },
          {
            title: 'Telefone',
            value: phoneMask(data?.telefone).masked,
            textAlign: 'center',
            maxChar: 15,
          },
          {
            item: data,
            searchAction: () => goToDetail(data.id),
          },
          {
            item: data,
            maxChar: 5,
            action: (id, item) => {
              setIdEstablishment(id);
              setOpenDialogConfirmation(true);
            },
          },
        ];
        newList.push(items);
      });

      setList(newList);
    }
  };

  const goToDetail = (id) => {
    history.push('/teu-pag/detail', {
      id,
    });
  };

  const goToRegister = () => {
    history.push('/teu-pag/detail');
  };

  useEffect(() => {
    const isCpfOrCnpj =
      form.cpfCnpj?.masked?.length === 14 ||
      form.cpfCnpj?.masked?.length === 18;

    const isNameFilled = form.name && form.name.trim().length > 0;

    if (isCpfOrCnpj || isNameFilled) {
      handleSubmit();
    }
  }, []);

  const deleteEstablishment = async () => {
    setIsLoading(true);
    try {
      const response = await RequestDeleteEstablishments.callApi();
      if (response.status >= 200) {
        setOpenDialog(true);
        setOpenDialogConfirmation(false);
        setMessage('Cliente excluído com sucesso!');
        setIsLoading(false);
        handleSubmit();
      } else {
        setOpenDialog(true);
        setMessage('Erro ao excluir!');
        setIsLoading(false);
        handleSubmit();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loading status={RequestSearchEstablishments.loading} />
      <Dialog
        open={openDialog}
        icon={icons.exclamation}
        positiveAction={() => {
          setOpenDialog(false);
          getSearchEstablishments();
        }}
        message={message}
        positiveLabel="Entendi"
      />
      <Dialog
        icon={icons.warning}
        open={openDialogConfirmation}
        negativeAction={() => {
          setOpenDialogConfirmation(false);
        }}
        positiveAction={deleteEstablishment}
        Loading={isLoading}
        buttonDisabledPositive={RequestDeleteEstablishments.loading}
        positiveLabel="SIM"
        negativeLabel="NÃO"
        message="Deseja mesmo excluir o cliente?"
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex marginRight={16}>
              <Input
                label="Nome/Razão-social"
                value={form.name}
                onChange={(e) => {
                  setForm({
                    ...form,
                    name: e.target.value,
                  });
                }}
              />
            </ContainerFlex>
            <ContainerFlex marginRight={16}>
              <Input
                label="CPF/CNPJ"
                name="cpfCnpj"
                value={form.cpfCnpj?.masked}
                onChange={handleChange}
                mask={MaskTypes.CPF_CNPJ}
              />
            </ContainerFlex>
            <ContainerFlex row gap="30">
              <Button
                fitContent
                onClick={() => handleSubmit()}
                disabled={isButtonDisabled}
              >
                Consultar
              </Button>
              <Button fitContent onClick={goToRegister}>
                Novo cliente
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
        {list.length <= 0 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <span>Cliente TeuPag não encontrado</span>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex flex="0.2">
              {RequestSearchEstablishments.status === 200 && !list.length ? (
                <ContainerFlex center marginTop={30}>
                  <span>Cliente TeuPag não encontrado</span>
                </ContainerFlex>
              ) : null}
              <ContainerFlex>
                {list.length > 0 && (
                  <>
                    <ContainerFlex>
                      <TableList customerHeader={customHeader} list={list} />
                    </ContainerFlex>
                    <ContainerFlex marginTop={20} center flex={1}>
                      <Pagination
                        totalPages={totalPages}
                        initialCurrentPage={currentPage}
                        onCurrentPageChange={setCurrentPage}
                      />
                    </ContainerFlex>
                  </>
                )}
              </ContainerFlex>
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default TeuPagConsult;
