/* eslint-disable react/jsx-one-expression-per-line */

import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Collapse from '~/components/collapse';
import Container from '~/components/container';
import ClientData from '../../Components/ClientData';
import * as St from '../../Services/styles';
import { IEvent, IProduct, LeadContext } from '~/contexts/leads';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import SharedServiceContext from '../../Services/sharedServiceContext';
import {
  contractedHeader,
  notContractedHeader,
} from '../../Headers/benefitHeaders';
import TableList from '../../Components/tableList';
import { SharedBenefitServiceContext } from '../Services/sharedBenefitServiceContext';
import { currencyMask } from '~/utils/masks';

export const LeadSolicitation = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const {
    cpfGlobal,
    setCpfGlobal,
    cardInfos,
    availableProducts,
    getInfoCards,
    getClientInformation,
    getPhoto,
    getAvailableProducts,
    setEventId,
    setProduct,
    setEvent,
    loadingGlobal,
    getProfiles,
    openForwardLead,
    disabledButton,
    product,
    event,
    setModalDescriptionOpen,
    resetContext,
  } = useContext(SharedServiceContext);
  const {
    getContractedBenefitsClient,
    getNotContractedBenefitsClient,
    contractedBenefits,
    notContractedBenefits,
    loadingBenefit,
    handleSelectBenefits,
    handleRequestSale,
    totalValue,
    resetBenefitContext,
  } = useContext(SharedBenefitServiceContext);
  const { leads } = useContext(LeadContext);

  const [typeProduct, setTypeProduct] = useState<IProduct>({
    id: '',
    value: '',
  });
  const [typeEvent, setTypeEvent] = useState<IEvent>({ id: '', value: '' });
  const [typeButton, setTypeButton] = useState<string>('');

  useEffect(() => {
    return () => {
      resetContext();
      resetBenefitContext();
    };
  }, []);

  useEffect(() => {
    if (state) {
      setCpfGlobal(state?.cpfGlobal || state?.item?.cpf);
      setEvent(state?.event || state?.item?.tipoEvento);
      setEventId(state?.eventId || state?.item?.id);
      setProduct({ id: 'BENEFICIO', value: state?.item?.produto?.value });
    }
  }, [state]);

  useEffect(() => {
    if (cpfGlobal) {
      getClientInformation();
      getAvailableProducts();
      getContractedBenefitsClient();
      getNotContractedBenefitsClient();
      getInfoCards();
      getPhoto();
    }
  }, [cpfGlobal]);

  const handleSetTypeProduct = (typeProduct: IProduct) => {
    setTypeProduct(typeProduct);
  };

  const handleSetTypeEvent = (typeEvent: IEvent) => {
    setTypeEvent(typeEvent);
  };

  const finishEvent = () => {
    setModalDescriptionOpen(true);
  };

  const toFoward = () => {
    getProfiles();
  };

  const nextContact = () => {
    setTypeButton('NEXT_CONTACT');
    setEventId(state?.eventId);
  };

  useEffect(() => {
    if (typeButton === 'NEXT_CONTACT') {
      openForwardLead();
      setTypeButton('');
    }
  }, [typeButton]);

  const handleLead = () => {
    setTypeButton('LEAD');
  };

  useEffect(() => {
    if (typeButton === 'LEAD') {
      setTypeButton('');
      handleRequestSale();
    }
  }, [typeButton]);

  return (
    <Container>
      <St.BoxCard>
        <Collapse collapseHeight={70} initialState="open" title="1. Cliente">
          <ClientData
            solicitation
            cardInfos={cardInfos}
            availableProducts={availableProducts}
            eventStart
            onTypeProduct={handleSetTypeProduct}
            onTypeEvent={handleSetTypeEvent}
            product={product}
            event={event}
          />
        </Collapse>
        <Collapse title="2. Benefícios contratados" typeEvent>
          <TableList
            customerHeader={contractedHeader}
            list={contractedBenefits}
          />
        </Collapse>
        <Collapse title="3. Solicitação de Lead benefício" typeEvent>
          <TableList
            checkbox
            customerHeader={notContractedHeader}
            list={notContractedBenefits}
            onChangeSelectedRows={handleSelectBenefits}
          />
        </Collapse>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            background: '#d9dadb',
            marginBottom: 20,
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <ContainerFlex center row end gap="10">
            <span> Acréscimo que virá na fatura do cliente:</span>
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
              R$ {currencyMask(totalValue())}
            </span>
          </ContainerFlex>
        </div>
        <ContainerFlex row gap="10" end marginTop={20}>
          <Button
            outlined
            disabled={loadingGlobal.closeLead.loading}
            loading={loadingGlobal.closeLead.loading}
            onClick={() => {
              finishEvent();
            }}
          >
            Encerrar
          </Button>
          <Button
            outlined
            disabled={disabledButton || loadingGlobal.postForwardLead.loading}
            loading={loadingGlobal.postForwardLead.loading}
            onClick={() => {
              toFoward();
            }}
          >
            Encaminhar
          </Button>
          <Button
            outlined
            disabled={disabledButton || loadingGlobal.postNewContact.loading}
            loading={loadingGlobal.getContactHistory.loading}
            onClick={() => {
              nextContact();
            }}
          >
            Próximo Contato
          </Button>
          <Button
            outlined
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={!leads.benefits.length}
            loading={loadingBenefit.postRequestSale.loading}
            onClick={() => handleLead()}
          >
            Solicitar venda
          </Button>
        </ContainerFlex>
      </St.BoxCard>
    </Container>
  );
};
