/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { ISharedServiceCardContextProps } from '../../Interfaces/cardsIntefaces';

export const SharedCardServicesContext = createContext<ISharedServiceCardContextProps>(
  {
    cpf: '',
    setCpf: () => {},
    errorPhoneZeuss: false,
    setErrorPhoneZeuss: () => {},
    loading: { getThemeClientNotCategory: { loading: false } },
    phoneZeussCard: { masked: '', unmasked: '' },
    setPhoneZeussCard: () => {},
    opinionDowngrade: '',
    setOpinionDowngrade: () => {},
    cardsNotClients: [],
    setCardsNotClient: () => [],
    getNotClientCards: () => [],
    resetContextCard: () => [],
    validatePhone: () => {},
    headerInfoArray: undefined,
    getEventsDetail: () => {},
    fileName: '',
    setFileName: () => {},
  },
);
