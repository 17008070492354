import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import io from 'socket.io-client';

import Layout from '~/components/layout';
import MessageBalloon from '~/components/messageBalloon';
import Button from '~/components/button';
import ChatInput from '~/components/chatInput';
import SendButton from '~/components/sendButton';
import Avatar from '~/components/avatar';

import * as St from './styles';
import { MessageType } from '~/components/messageBalloon/styles';
import { IAllUserInfos } from '~/services/user';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';

interface IChatRoom {
  id: string;
  nome: string;
  cpf: string;
  numeroCartao: string;
}

interface InfoMessage {
  autor: string;
  chatId?: string;
  enviadaEm?: string;
  id?: string;
  texto: string;
}

interface IMessages {
  messages: InfoMessage[];
}

let socket;

const Messages: React.FC<IMessages> = memo(({ messages }) => {
  const messagesRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (messagesRef) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [messages]);

  const userInfo = JSON.parse(localStorage.getItem('@NovaEra:user'));

  const handleRenderMessageBalloon = (message: InfoMessage, index) => {
    if (message.autor === userInfo?.id)
      return (
        <MessageBalloon
          key={index + message.texto}
          text={message.texto}
          delivered
          type={MessageType.SEND}
        />
      );
    return (
      <St.ReceiveContainer key={index + message.texto}>
        <Avatar src="https://qodebrisbane.com/wp-content/uploads/2019/07/This-is-not-a-person-2-1.jpeg" />
        <MessageBalloon text={message.texto} type={MessageType.RECEIVE} />
      </St.ReceiveContainer>
    );
  };
  return (
    <St.Messages ref={messagesRef}>
      {messages.map(handleRenderMessageBalloon)}
    </St.Messages>
  );
});

const ChatRoom: React.FC<IChatRoom> = () => {
  const [messages, setMessages] = useState<InfoMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [user, setUser] = useState<IAllUserInfos>({} as IAllUserInfos);
  const inputRef = useRef<HTMLInputElement>();

  const { state } = useLocation<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    getUserInfo();
    socket = io(
      `${process.env.REACT_APP_BASE_URL_IP}${process.env.REACT_APP_MS_CHAT}`,
    );

    const userInfo = JSON.parse(localStorage.getItem('@NovaEra:user'));
    socket.emit('join', {
      room: state?.id,
      name: 'atendente',
      userID: userInfo?.id,
    });

    socket.on('message', (data) => {
      setMessages((prev) => [...prev, data]);
    });

    socket.on('previousMessage', (data) => {
      setMessages(data.reverse());
    });

    return () => {
      socket.removeAllListeners('message');
      socket.disconnect();
    };
  }, []);

  const sendMessage = () => {
    socket.emit('message', { texto: newMessage });
    inputRef.current.value = '';
  };

  const getUserByIdAutorizador = useApi<any>(
    Endpoints.account.getUserByIdClienteAutorizador,
    ApiMethod.GET,
  );

  const getUserInfo = async () => {
    try {
      const response = await getUserByIdAutorizador.callApi(state.id);

      setUser(response.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(evt.target.value);
  };

  const onClickEndService = () => {
    socket.emit('sessionClose');
    history.push('/chat');
  };

  const goToChatList = useCallback(() => {
    socket.emit('sessionRestart');
    history.push('/chat');
  }, []);

  return (
    <Layout>
      <St.Container>
        <St.WrapperChat>
          <St.Chat>
            <h4>Atendimento</h4>
            <Messages messages={messages} />
          </St.Chat>
          <St.Footer>
            <St.WrapperInput>
              <ChatInput ref={inputRef} onChange={handleChange} />
              <SendButton onClick={sendMessage} />
            </St.WrapperInput>
          </St.Footer>
        </St.WrapperChat>

        <St.Infos>
          <St.UserInfo>
            <h5>Nome Completo</h5>
            <span>{user?.nome}</span>
            <h5>CPF</h5>
            <span>{user?.cpf}</span>
            <h5>Número do cartão</h5>
            <span>
              {user?.cartoes !== undefined && user?.cartoes[0].numeroCartao}
            </span>
          </St.UserInfo>

          <St.ContainerButtons>
            <Button size="large" outlined onClick={onClickEndService}>
              Encerrar
            </Button>
            <Button size="large" onClick={goToChatList}>
              Voltar
            </Button>
          </St.ContainerButtons>
        </St.Infos>
      </St.Container>
    </Layout>
  );
};

export default ChatRoom;
