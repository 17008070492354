import { darken } from 'polished';
import styled from 'styled-components';

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-left: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;
export const DialogContent = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;
export const DescContainer = styled.div`
  border: none;
  border-radius: 0.375rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${darken(0.045, '#ffffff')};
  }

  svg {
    width: 18px;
  }
`;
export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;

export const DivGroupBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: initial;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const BtnPlus = styled.button`
  border: none;
  background-color: transparent;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;

  &:after,
  &:before {
    content: '';
    display: block;
    background-color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;

export const BtnMinus = styled.button`
  border: none;
  background-color: transparent;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;

  &:after,
  &:before {
    content: '';
    display: block;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;

export const TableTitle = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Table = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  border-spacing: 0;
  text-align: center;
  border-left: 1px solid gray;
  border-right: 1px solid gray;

  th {
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid gray;
  }

  th {
    border-top: 1px solid gray;
  }

  td a {
    text-decoration: underline;
    color: inherit;
  }

  .icon-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const TableDependents = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  border-spacing: 0;
  margin-top: 20px;
  text-align: center;
  border-left: 1px solid gray;
  border-right: 1px solid gray;

  td {
    padding: 10px 0;
  }
  th {
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid gray;
  }

  th {
    border-top: 1px solid gray;
  }

  td a {
    text-decoration: underline;
    color: inherit;
  }

  .icon-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;
