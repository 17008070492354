import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  min-width: 70px;
  z-index: 999;
`;

export const Popover = styled.div`
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  elevation: 4;
  background: #fff;
  z-index: 999;
  border-radius: 5px;
  padding: 15px;
`;

export const Triangule = styled.div`
  width: 15px;
  height: 15px;
  background: #fff;
  right: 20px;
  top: -5px;
  z-index: 1;
  position: absolute;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
`;
