// enum ProposalStatusColor {
//   ANALISE = 1,
//   ANALISADA = 2,
//   PENDENTE = 3,
//   APROVADA = 4,
//   REPROVADA = 5,
//   NENHUMA = 0,
// }

// const getStatusColor = (value: ProposalStatusColor) => {
//   switch (value) {
//     case ProposalStatusColor.ANALISE:
//       return 'grey';
//     case ProposalStatusColor.ANALISADA:
//       return 'purple';
//     case ProposalStatusColor.PENDENTE:
//       return 'yellow';
//     case ProposalStatusColor.APROVADA:
//       return 'green';
//     case ProposalStatusColor.REPROVADA:
//       return 'red';
//     default:
//       return null;
//   }
// };
export enum ProposalStatus {
  APROVADA = 'APROVADA',
  APROVADA_P1 = 'APROVADA - P1',
  APROVADA_P2 = 'APROVADA - P2',
  REPROVADA = 'REPROVADA',
  REPROVADA_P1 = 'REPROVADA - P1',
  REPROVADA_P2 = 'REPROVADA - P2',
  PENDENTE = 'PENDENTE',
  PENDENTE_MESA = 'PENDENTE - MESA_CREDITO',
  PENDENTE_ANALISE = 'PENDENTE - P2',
  ANALISE = 'ANALISE',
  ANALISE_MESA_CREDITO = 'ANALISE - MESA_CREDITO',
  ANALISADA = 'ANALISADA',
  RASCUNHO = 'RASCUNHO',
  RASCUNHO_P1 = 'RASCUNHO P1',
  ORIGEM_APP = 'APP',
  ORIGEM_WEB = 'SITE',
  ORIGEM_LOJA = 'LOJA',
  PROCESSANDO = 'PROCESSANDO',
}

enum ProposalStatusColor {
  ANALISE = 1,
  ANALISADA = 2,
  PENDENTE_ANALISE = 3,
  PENDENTE_MESA = 4,
  APROVADA = 5,
  REPROVADA = 6,
  NENHUMA = 0,
}

const getStatusColor = (value: ProposalStatusColor): string | null => {
  switch (value) {
    case ProposalStatusColor.ANALISE:
      return 'grey';
    case ProposalStatusColor.ANALISADA:
      return 'purple';
    case ProposalStatusColor.PENDENTE_ANALISE:
      return 'yellow';
    case ProposalStatusColor.PENDENTE_MESA:
      return '#e6b800';
    case ProposalStatusColor.APROVADA:
      return 'green';
    case ProposalStatusColor.REPROVADA:
      return 'red';
    default:
      return null;
  }
};

const CreditTableUtil = {
  getStatusColor,
  ProposalStatusColor,
};

export default CreditTableUtil;
