import styled from 'styled-components';

interface IData {
  marginLeft?: number;
  maxChar?: number;
  textAlign?: string;
}

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const MainHeader = styled.div`
  width: 100%;
  height: 71px;
  background-color: #e71037;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 71px;
  background-color: #fff;
  box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #4a4b4a;
  text-align: left;
  margin: 10px 0;
  padding: 0 16px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 16px;
`;

export const Collapsible = styled.div`
  width: 100%;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #4a4b4a;
  text-align: left;
  margin-bottom: 10px;
  padding: 18px;
  position: relative;
  cursor: pointer;

  div {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 16px;
  }
  svg {
    font-size: 22px;
    float: right;
    margin-left: 5px;
    position: absolute;
    top: 27px;
    right: 16px;
    cursor: pointer;
  }
`;

export const ContentWraper = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height ease 0.5s;
`;

export const ContentWraperTr = styled.tr`
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height ease 0.5s;
  margin-top: -30px;
`;

export const Content = styled.div`
  padding: 18px;
  transition: 0.5s ease-out;
  background-color: #fff;
  /* border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1; */
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #4a4b4a;
  animation: sweep 0.5s ease-in;
  text-align: center;
`;

export const Icon = styled.img<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(270deg)' : 'rotate(180deg)')};
  transition: transform 0.3s;
  position: relative;
  right: 20px;
  top: 25px;
`;

export const headerTable = styled.thead`
  height: 71px;
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 16px;
`;

export const hederTitle = styled.th<IData>`
  font-size: 14px;
  font-weight: 600;
  color: #4a4b4a;
  text-align: left;
  word-break: break-word;
  padding: 0 20px;
  width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
`;

export const bodyTable = styled.tbody`
  tr {
    cursor: pointer;
    height: 71px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 100%;
    margin: 20px 0;
    padding: 16px;
  }
`;

export const bodyData = styled.td<IData>`
  font-size: 14px;
  font-weight: 400;
  color: #4a4b4a;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px;
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
`;

export const bodyDataValue = styled.span<IData>`
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
`;

export const IconRow = styled.img`
  width: 20px;
  height: 20px;
`;

export const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const DatesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    padding: 0 20px;
  }
`;

export const DescriptionDialog = styled.h3`
  color: #e71037;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 20px auto 0 auto;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;
