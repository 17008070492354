/* eslint-disable react/jsx-curly-newline */
import moment from 'moment';
import React, { useState } from 'react';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import { setFormState } from '~/utils/form';
import { UFs } from '../../../constants';
import { BENEFIT_TYPES } from '../../models';

import * as S from '../../styles';

interface IFilterBenefit {
  operador: any[];
  onClick?: (any: any) => void;
}

const FilterBenefit: React.FC<IFilterBenefit> = ({ operador, onClick }) => {
  const [form, setForm] = useState({
    tipoBeneficio: { name: 'tipoBeneficio', value: 'VENDAS_ODONTO' },
    statusCartao: 'TODOS',
    periodoInicial: moment().format('DD/MM/YYYY'),
    periodoFinal: moment().format('DD/MM/YYYY'),
    tipoPeriodoCancelamentoOdonto: 'DT_CANCELAMENTO',
    tipoPeriodoCancelamentoProtege: 'DT_CANCELAMENTO',
    tipoPeriodoCancelamentoCuida: 'DT_CANCELAMENTO',
    periodoFaturamentoOdonto: 'DT_ADESAO',
    periodoCancelamentoPlatinum: 'CANCELAMENTO',
    tipoSeguro: 'TODOS',
    tipoAdesao: 'TODOS',
    uf: 'TODOS',
    idAtendente: 'TODOS',
    nomeAtendente: 'TODOS',
  });

  const handleChange = (el): void => {
    onClick(el);
  };

  return (
    <S.BoxCard>
      <ContainerFlex row space>
        <ContainerFlex marginRight={16}>
          <Select
            options={BENEFIT_TYPES}
            label="Tipo Benefício"
            name={form.tipoBeneficio.name}
            value={form.tipoBeneficio.value}
            onChange={(e) => {
              setFormState(
                { target: { name: form.tipoBeneficio.name, value: e } },
                form,
                setForm,
              );
            }}
          />
        </ContainerFlex>

        {form.tipoBeneficio.value !== 'FATURAMENTO_ODONTO' && (
          <ContainerFlex marginRight={16}>
            <Select
              options={[
                { id: 'TODOS', value: 'Todos' },
                { id: '6041102c1b705c0007d62e81', value: 'Web' },
                ...operador,
              ]}
              label="Operador(a) de Venda"
              value={form.idAtendente}
              onChange={(e) => {
                setForm({ ...form, idAtendente: e });
              }}
            />
          </ContainerFlex>
        )}

        {['VENDAS_ODONTO', 'CANCELAMENTO_ODONTO', 'VENDAS_CUIDA'].includes(
          form.tipoBeneficio.value,
        ) && (
          <ContainerFlex marginRight={16}>
            <Select
              label="Status Cartão"
              value={form.statusCartao}
              options={[
                { id: 'TODOS', value: 'Todos' },
                { id: 'ATIVO', value: 'Ativo' },
                { id: 'INATIVO', value: 'Inativo' },
                { id: 'CANCELADO', value: 'Cancelado' },
                { id: 'INADIMPLENTE', value: 'Inadimplente' },
                { id: 'COBRANCA', value: 'Cobrança' },
              ]}
              onChange={(e) => setForm({ ...form, statusCartao: e })}
            />
          </ContainerFlex>
        )}

        {form.tipoBeneficio.value !== 'FATURAMENTO_ODONTO' && (
          <ContainerFlex marginRight={16}>
            <Select
              options={UFs}
              label="UF"
              value={form.uf}
              onChange={(e) => {
                setForm({ ...form, uf: e });
              }}
            />
          </ContainerFlex>
        )}

        <ContainerFlex marginRight={16}>
          <Input
            label="De"
            placeholder="DD/MM/YYYY"
            maxLength={10}
            value={form.periodoInicial}
            onChange={(e) => {
              return setForm({
                ...form,
                periodoInicial: e.target.value.masked,
              });
            }}
            mask={MaskTypes.DATE}
          />
        </ContainerFlex>

        <ContainerFlex>
          <Input
            label="Até"
            placeholder="DD/MM/YYYY"
            maxLength={10}
            value={form.periodoFinal}
            onChange={(e) => {
              return setForm({
                ...form,
                periodoFinal: e.target.value.masked,
              });
            }}
            mask={MaskTypes.DATE}
          />
        </ContainerFlex>

        {['VENDAS_PROTEGE'].includes(form.tipoBeneficio.value) && (
          <ContainerFlex marginLeft={16}>
            <Select
              label="Tipo de seguro"
              value={form.tipoSeguro}
              options={[
                { id: 'TODOS', value: 'Todos' },
                {
                  id: 'PRESTAMISTA',
                  value: 'Prestamista',
                },
                { id: 'BOLSA_PROTEGIDA', value: 'Bolsa Protegida' },
                { id: 'PERDA_RENDA', value: 'Perda de Renda' },
              ]}
              onChange={(e) => setForm({ ...form, tipoSeguro: e })}
            />
          </ContainerFlex>
        )}

        {['CANCELAMENTO_PROTEGE'].includes(form.tipoBeneficio.value) && (
          <ContainerFlex marginLeft={16}>
            <Select
              label="Tipo de Período"
              value={form.tipoPeriodoCancelamentoProtege}
              options={[
                { id: 'DT_VENDA', value: 'Venda' },
                { id: 'DT_CANCELAMENTO', value: 'Cancelamento' },
              ]}
              onChange={(e) =>
                setForm({ ...form, tipoPeriodoCancelamentoProtege: e })
              }
            />
          </ContainerFlex>
        )}

        {['CANCELAMENTO_PLATINUM'].includes(form.tipoBeneficio.value) && (
          <ContainerFlex marginLeft={16}>
            <Select
              label="Tipo de Período"
              value={form.periodoCancelamentoPlatinum}
              options={[
                { id: 'ADESAO', value: 'Venda' },
                { id: 'CANCELAMENTO', value: 'Cancelamento' },
              ]}
              onChange={(e) =>
                setForm({ ...form, periodoCancelamentoPlatinum: e })
              }
            />
          </ContainerFlex>
        )}

        {['CANCELAMENTO_CUIDA'].includes(form.tipoBeneficio.value) && (
          <ContainerFlex marginLeft={16}>
            <Select
              label="Tipo de Período"
              value={form.tipoPeriodoCancelamentoCuida}
              options={[
                { id: 'DT_VENDA', value: 'Venda' },
                { id: 'DT_CANCELAMENTO', value: 'Cancelamento' },
              ]}
              onChange={(e) =>
                setForm({ ...form, tipoPeriodoCancelamentoCuida: e })
              }
            />
          </ContainerFlex>
        )}

        {['CANCELAMENTO_ODONTO'].includes(form.tipoBeneficio.value) && (
          <ContainerFlex marginLeft={16}>
            <Select
              label="Tipo de Período"
              value={form.tipoPeriodoCancelamentoOdonto}
              options={[
                { id: 'DT_VENDA', value: 'Venda' },
                { id: 'DT_CANCELAMENTO', value: 'Cancelamento' },
              ]}
              onChange={(e) =>
                setForm({ ...form, tipoPeriodoCancelamentoOdonto: e })
              }
            />
          </ContainerFlex>
        )}

        {['FATURAMENTO_ODONTO'].includes(form.tipoBeneficio.value) && (
          <ContainerFlex marginLeft={16}>
            <Select
              label="Tipo de Período"
              value={form.periodoFaturamentoOdonto}
              options={[
                { id: 'DT_ADESAO', value: 'Adesão' },
                { id: 'DT_PAGAMENTO', value: 'Pagamento' },
              ]}
              onChange={(e) =>
                setForm({ ...form, periodoFaturamentoOdonto: e })
              }
            />
          </ContainerFlex>
        )}

        {['VENDAS_PLATINUM'].includes(form.tipoBeneficio.value) && (
          <ContainerFlex marginRight={16}>
            <Select
              label="Tipo de Adesão"
              value={form.tipoAdesao}
              options={[
                { value: 'TODOS', id: 'TODOS' },
                { value: 'ASSINATURA - Novo cliente', id: 'ASSINATURA' },
                {
                  value: 'PROPOSTA - Upgrade CadProposta',
                  id: 'PROPOSTA',
                },
                { value: 'MANUAL - Upgrade Backoffice', id: 'MANUAL' },
              ]}
              onChange={(e) => setForm({ ...form, tipoAdesao: e })}
            />
          </ContainerFlex>
        )}
      </ContainerFlex>
      <ContainerFlex>
        <ContainerFlex flex="0.2" marginTop={24} maxWidth={14}>
          <Button
            onClick={() => {
              handleChange(form);
            }}
          >
            <span>Consultar</span>
          </Button>
        </ContainerFlex>
      </ContainerFlex>
    </S.BoxCard>
  );
};

export default FilterBenefit;
