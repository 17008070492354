/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import Select, { IOption } from '~/components/select';
import TitleApp from '~/components/title';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { ICompany } from '~/models/company';
import { IEmployee } from '~/models/employee';
import { UserTypes } from '~/models/user';
import endpoints from '~/services/endpoints';
import { transformInNormalDate } from '~/utils/date';
import { cepMask, cpfMask, IDefaultReturn, phoneMask } from '~/utils/masks';

import * as St from './styles';

interface IForm<T> {
  cpf: T;
  name: string;
  phone: T;
  dateBirth: T;
  email: string;
  login: string;
  cep: T;
  address: string;
  number: string;
  complement: string;
  city: string;
  neighborhood: string;
  date: T;
  hour: T;
  password: string;
  confPassword: string;
}

const STATUS_TYPE = [
  {
    id: 'ADM',
    value: UserTypes.ADMIN,
  },
  {
    id: 'OPR',
    value: UserTypes.OPERADOR,
  },
  {
    id: 'SUP',
    value: UserTypes.SUPERVISOR,
  },
  {
    id: 'MDC',
    value: UserTypes.MESA_CREDITO,
  },
  {
    id: 'RET',
    value: UserTypes.RETAGUARDA,
  },
  {
    id: 'CXA',
    value: UserTypes.CAIXA,
  },
];

const RegisterOperator: React.FC = () => {
  const [form, setForm] = useState<IForm<IDefaultReturn>>(
    {} as IForm<IDefaultReturn>,
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogQuestion, setOpenDialogQuestion] = useState(false);
  const [companys, setCompanys] = useState<IOption[]>([]);

  const { state } = useLocation<IEmployee>();
  const history = useHistory();

  const { data, callApi, status, loading } = useApi<any>(
    state?.usuarioAtivo
      ? `${endpoints.account.disableEmployee}/${state?.cpf}`
      : `${endpoints.account.enableUser}/${state?.cpf}`,
    ApiMethod.PUT,
  );

  const RequestListCompany = useApi<ICompany[]>(
    `${endpoints.consult.searchCompany}`,
    ApiMethod.GET,
  );

  useEffect(() => {
    (async () => {
      const response = await RequestListCompany.callApi();
      if (response.status === 200) {
        const companys = response.data.map(({ nome, id }) => ({
          id,
          value: nome,
        }));
        setCompanys(companys);
      }
    })();
  }, []);

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const handleToggleDialogQuestion = () => {
    setOpenDialogQuestion((prev) => !prev);
  };

  const goBack = () => history.push('/consult/disable');

  const onClickEnableOrDisable = () => {
    handleToggleDialogQuestion();
  };

  const handleEnableOrDisable = async () => {
    await callApi();
    handleToggleDialog();
    handleToggleDialogQuestion();
  };

  return (
    <>
      <Loading status={loading} />
      <Dialog
        open={openDialog}
        message={
          status === 200
            ? state?.usuarioAtivo
              ? 'Usuário desativado com sucesso!'
              : 'Usuário ativado com sucesso!'
            : data?.mensagem
        }
        positiveAction={true ? goBack : handleToggleDialog}
        positiveLabel="Entendi"
        icon={icons.check}
      />
      <Dialog
        open={openDialogQuestion}
        message={`Você tem certeza que deseja ${
          state?.usuarioAtivo ? 'desativar' : 'ativar'
        }?`}
        positiveAction={handleEnableOrDisable}
        negativeAction={handleToggleDialogQuestion}
        positiveLabel={state?.usuarioAtivo ? 'Desativar' : 'Ativar'}
        negativeLabel="Cancelar"
        icon={icons.warning}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <TitleApp marginBottom={3}>
              Consulta/Desativação de usuário
            </TitleApp>

            <Grid xs={1} lg={2} gap={20}>
              <ContainerFlex>
                <Input
                  label="CPF"
                  name="cpf"
                  readOnly
                  mask={MaskTypes.CPF}
                  placeholder="000.000.000-00"
                  value={cpfMask(state?.cpf).masked}
                />

                <Input
                  label="Nome completo"
                  name="name"
                  readOnly
                  value={state?.nome}
                />

                <Input
                  label="Telefone"
                  name="phone"
                  readOnly
                  mask={MaskTypes.PHONE}
                  placeholder="(00) 00000-0000"
                  value={phoneMask(state?.celular).masked}
                  maxLength={15}
                />
                <Input
                  label="Data de nascimento"
                  name="dateBirth"
                  readOnly
                  mask={MaskTypes.DATE}
                  placeholder="dd/mm/aaaa"
                  value={transformInNormalDate(state?.dataNascimento).masked}
                  maxLength={10}
                />

                <Input
                  label="E-mail"
                  name="email"
                  type="email"
                  value={state?.email}
                  readOnly
                />

                <Select
                  label="Perfil"
                  value={state?.usuarioPerfis[0]}
                  options={STATUS_TYPE}
                  readOnly
                />
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="CEP"
                  name="cep"
                  readOnly
                  mask={MaskTypes.CEP}
                  placeholder="00.000-000"
                  value={cepMask(state?.endereco?.cep).masked}
                  maxLength={10}
                />
                <Input
                  label="Endereço"
                  name="address"
                  readOnly
                  value={state?.endereco?.logradouro}
                />

                <Select
                  label="Empresa"
                  value={state.idEmpresa}
                  options={companys}
                  readOnly
                />

                <div>
                  <ContainerFlex row>
                    <ContainerFlex marginRight={16}>
                      <Input
                        label="Número"
                        name="number"
                        readOnly
                        value={state?.endereco?.numero}
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Complemento"
                        name="complement"
                        readOnly
                        value={state?.endereco?.complemento}
                      />
                    </ContainerFlex>
                  </ContainerFlex>

                  <ContainerFlex row>
                    <ContainerFlex marginRight={16}>
                      <Input
                        label="Cidade"
                        name="city"
                        readOnly
                        value={state?.endereco?.cidade}
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Bairro"
                        name="neighborhood"
                        readOnly
                        value={state.endereco?.bairro}
                      />
                    </ContainerFlex>
                  </ContainerFlex>

                  {/* <ContainerFlex row>
                        <ContainerFlex marginRight={16}>
                          <Input
                            label="Data"
                            name="date"
                            readOnly
                            mask={MaskTypes.DATE}
                            placeholder="dd/mm/aaaa"
                            maxLength={10}
                            value={state?.dataCriacao}
                          />
                        </ContainerFlex>

                        <ContainerFlex>
                          <Input
                            label="Horário"
                            name="hour"
                            readOnly
                            mask={MaskTypes.HOUR_MIN_SEC}
                            placeholder="hora:min:seg"
                            value={state?.horaCriacao}
                          />
                        </ContainerFlex>
                      </ContainerFlex> */}
                </div>

                <ContainerFlex row>
                  <Button fitContent fullWidth onClick={goBack}>
                    Voltar
                  </Button>
                  <Button
                    fitContent
                    marginLeft={15}
                    fullWidth
                    onClick={onClickEnableOrDisable}
                    disabled={loading}
                    loading={loading}
                  >
                    {state?.usuarioAtivo ? 'Desativar' : 'Ativar'}
                  </Button>
                </ContainerFlex>
              </ContainerFlex>
            </Grid>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default RegisterOperator;
