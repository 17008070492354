import React from 'react';
import Chart from 'react-apexcharts';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as S from '../../styles';

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  dataHoraColeta?: string;
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
  subTitle?: string;
}

const BoxInvoicePaid: React.FC<IProps> = ({
  categories,
  series,
  widthGraphic = 600,
  heightGraphic = 300,
}) => {
  const history = useHistory();
  const buildSeries = () => {
    let response = null;
    if (series === undefined || series.length <= 0) {
      response = [
        {
          name: 'Faturas pagas',
          data: [0],
        },
      ];
    } else {
      response = series;
    }
    return response;
  };

  const options = {
    series: series || [0],
    chart: {
      type: 'bar',
      width: '100%',
      height: 350,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        distributed: true,
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -25,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        formatter(val) {
          return val;
        },
      },
    },
  } as unknown;

  return (
    <>
      <S.BoxDetails>
        <S.BoxHeader>
          <FiArrowLeft onClick={() => history.goBack()} />
          <S.BoxHeaderTitle>Faturas Pagas</S.BoxHeaderTitle>
        </S.BoxHeader>
        <S.ItemGraphic>
          <Chart
            categories={categories}
            options={options}
            series={buildSeries()}
            type="bar"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxInvoicePaid;
