import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';

import { IDisputeCollection } from '~/models/dispute';
import { IDefaultReturn, creditCardMask, currencyMask } from '~/utils/masks';
import { TitleCategory } from '../ConsultDisable/Detail/styles';
import TableList from '~/components/tableList';
import { IListColCampaing } from '~/views/LimitIncrease/model';
import Container from '~/components/container';

import * as St from './styles';

interface IResponseConsultDispute extends IDisputeCollection {
  nome: string;
  idDisputa: string;
}

const ConsultDispute = (): JSX.Element => {
  const [cpf, setCpf] = useState({} as IDefaultReturn);
  const [card, setCard] = useState({} as IDefaultReturn);
  const [list, setList] = useState([]);

  const history = useHistory();

  const handleGenerateURL = useMemo(() => {
    if (cpf?.unmasked && card.unmasked) {
      return `${Endpoints.consult.searchDispute}?cpf=${cpf?.unmasked}&numeroCartao=${card.unmasked}`;
    }
    if (cpf?.unmasked && (!card.unmasked || card.unmasked === '')) {
      return `${Endpoints.consult.searchDispute}?cpf=${cpf?.unmasked}`;
    }
    if (card.unmasked && (!cpf?.unmasked || cpf?.unmasked === '')) {
      return `${Endpoints.consult.searchDispute}?numeroCartao=${card.unmasked}`;
    }
    return `${Endpoints.consult.searchDispute}`;
  }, [cpf, card.unmasked]);

  const RequestDispute = useApi<IResponseConsultDispute[]>(
    handleGenerateURL,
    ApiMethod.GET,
  );

  useEffect(() => {
    searchDispute();
  }, []);
  const customHeader = [
    {
      id: 1,
      value: 'ID da transação',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Nome Completo',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Número do Cartão',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Local da Transação',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Valor Parcela',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Valor Compra',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 7,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];
  const searchDispute = async () => {
    const response = await RequestDispute.callApi();

    if (response.status !== 200) {
      setList([]);
      return;
    }

    const newList = [];

    const disputes = response.data;

    // let id = 0;
    disputes.forEach((dispute) => {
      const item: IListColCampaing[] = [
        // {
        //   type: 'id',
        //   value: id++,
        //   maxChar: 5,
        //   textAlign: 'center',
        // },
        {
          id: 1,
          border: true,
          title: 'ID da Transação',
          value: dispute.transacao?.idTransacao,
          maxChar: 20,
        },
        {
          id: 2,
          border: true,
          title: 'Nome Completo',
          value: dispute.nome,
          maxChar: 20,
        },
        {
          id: 3,
          border: true,
          title: 'Número do Cartão',
          value: creditCardMask(dispute.numeroCartao).masked,
        },
        {
          id: 4,
          border: true,
          title: 'Local da Transação',
          value: dispute.transacao?.loja,
          maxChar: 20,
        },
        {
          id: 5,
          border: true,
          title: 'Valor da Parcela',
          value: `R$ ${currencyMask(dispute.transacao?.valor)}`,
          maxChar: 25,
          textAlign: 'center',
        },
        {
          id: 6,
          border: true,
          title: 'Valor da Compra',
          value: `R$ ${currencyMask(
            Number(dispute.transacao?.valor) *
              Number(dispute.transacao?.quantidadeParcelas),
          )}`,
          maxChar: 25,
          textAlign: 'center',
        },
        {
          id: 7,
          border: true,
          searchAction: () => goToDetail(dispute.idDisputa),
          textAlign: 'center',
        },
      ];

      newList.push(item);
    });

    setList(newList);
  };

  const goToDetail = (id) => history.push('/consult/dispute/table', { id });

  const handleDisableButton = () =>
    cpf.masked?.length !== 14 && card.masked?.length !== 19;

  return (
    <>
      <Loading status={RequestDispute.loading} />
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                value={cpf.masked}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Número do cartão"
                value={card.masked}
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                mask={MaskTypes.CREDIT_CARD}
                onChange={(e) => setCard(e.target.value)}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                loading={RequestDispute.loading}
                disabled={RequestDispute.loading || handleDisableButton()}
                fitContent
                onClick={searchDispute}
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {list.length <= 0 && (
          <St.BoxCard>
            <ContainerFlex row>
              {list.length === 0 && (
                <ContainerFlex center>
                  <TitleCategory style={{ width: 'auto' }}>
                    Nenhuma transação encontrada.
                  </TitleCategory>
                </ContainerFlex>
              )}
            </ContainerFlex>
          </St.BoxCard>
        )}

        {list.length > 0 && RequestDispute.data && (
          <St.BoxCard>
            <ContainerFlex row>
              <TableList customerHeader={customHeader} list={list} />
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default ConsultDispute;
