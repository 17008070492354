import styled from 'styled-components';

interface ITextArea {
  error: boolean;
}

export const TextArea = styled.textarea<ITextArea>`
  padding: 16px;
  font-size: 1.1em;
  font-weight: 300;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.colors.error : 'inherit')};

  outline: none;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;
