import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, creditCardMask, currencyMask } from '~/utils/masks';

export const HeaderFutureInvoices = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Cartão',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Data Compra',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Parcela Atual',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Total Parcelas',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Valor',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodyFutureInvoices = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nome,
        maxChar: 25,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: creditCardMask(i.cartao).masked,
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.dataCompra ? moment(i.dataCompra).format('DD/MM/YY') : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.parcelaAtual,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.totalParcelas,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.valor)}`,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
