import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  error?: boolean;
}

export const IconRight = styled.img`
  height: 22px;
  padding-bottom: 8px;
  align-self: flex-end;
  cursor: pointer;
`;

export const InputContainerSpace = styled.div``;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  position: relative;
  /* margin-bottom: 25px; */
  width: 100%;

  .bold {
    font-weight: bold;
  }

  #eye {
    width: 25px;
    object-fit: contain;
  }

  > div {
    border-bottom: 1px solid
      ${({ error, theme: { colors } }) => (error ? colors.error : ' #afafaf')};
    ${(props) =>
      props.isFocused &&
      css`
        border-bottom: 1px solid #e71037;
      `}
  }

  label {
    font-size: 12px;
    color: ${({ error, theme: { colors } }) =>
      error ? colors.error : ' #757575'};

    ${(props) =>
      props.isFocused &&
      css`
        color: #e71037;
      `}
  }

  input {
    border: 0;
    font-size: 16px;
    color: #4a4b4a;
    margin-top: 12px;

    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #afafaf;
    }
  }
  /* Cor do bg do autocomplete */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;
