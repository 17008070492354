/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const downloadReadableStream = async (
  readableStrem: any,
  filename: string,
): Promise<void> => {
  const blob = await readableStrem.blob();
  const newBlob = new Blob([blob]);

  const blobUrl = window.URL.createObjectURL(newBlob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', `${filename}.${'pdf'}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  window.URL.revokeObjectURL(`${blob}`);
};

export const openInNewTab = async (readableStream) => {
  const blob = await readableStream.blob();

  const blobUrl = URL.createObjectURL(blob);

  const newTab = window.open(blobUrl, '_blank');

  if (newTab) {
    newTab.addEventListener('beforeunload', () => {
      window.URL.revokeObjectURL(blobUrl);
    });
  }
};
