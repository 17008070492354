import React, { useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { MdOutlineRefresh } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { IconContext } from 'react-icons';
import ContainerFlex from '~/components/containerFlex';

import BoxStore from '../components/boxStore';
import BoxOperators from '../components/boxOperators';

import * as S from '../styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Container from '~/components/container';

interface IBoxGraphic {
  loja?: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  operador?: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
}

interface IProps {
  dataFinal: string;
  dataInicial: string;
  title: string;
  idBeneficio: string;
}

const DashDetailsBenefitsRealTime: React.FC = () => {
  const history = useHistory();
  const [dataResponse, setDataResponse] = useState<IBoxGraphic>();
  const [updateHour, setUpdateHour] = useState('');

  const { state } = useLocation<IProps>();

  const getBenefits = useApi(
    Endpoints.graphc.getBenefitsDetails,
    ApiMethod.POST,
  );

  const postDetaAdesoesPlatinumD0 = useApi(
    Endpoints.events.postDetaAdesoesPlatinumD0,
    ApiMethod.POST,
  );

  useEffect(() => {
    if (state.title !== 'Platinum') {
      populaGraphcBenefits();
    } else {
      populaGraphcBenefitsPlatinumD0();
    }
  }, []);

  const populaGraphcBenefits = async () => {
    try {
      const response = await getBenefits.callApi({
        dataFinal: state?.dataFinal,
        dataInicial: state?.dataInicial,
        idBeneficios: state?.idBeneficio,
      });

      if (response.status >= 200 && response.status < 300) {
        const { data } = response as any;

        setDataResponse(data as IBoxGraphic);
        setUpdateHour(
          `Atualizado: hoje às ${moment(data.dataHoraColeta).format('HH:mm')}`,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const populaGraphcBenefitsPlatinumD0 = async () => {
    try {
      const response = await postDetaAdesoesPlatinumD0.callApi({
        dataFinal: state?.dataFinal,
        dataInicial: state?.dataInicial,
      });

      if (response.status >= 200 && response.status < 300) {
        const { data } = response as any;

        setDataResponse(data as IBoxGraphic);
        setUpdateHour(
          `Atualizado: hoje às ${moment(data.dataHoraColeta).format('HH:mm')}`,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Loading
        status={getBenefits.loading || postDetaAdesoesPlatinumD0.loading}
      />

      <S.Header>
        <S.LeftSide>
          <FiArrowLeft onClick={() => history.goBack()} />
          <S.HeaderTitle>
            <S.Title onClick={() => history.goBack()}>
              {`Detalhes tempo real: ${state.title}`}
            </S.Title>
          </S.HeaderTitle>
        </S.LeftSide>

        <S.RightSide>
          <S.BtnDownload
            onClick={() => {
              if (state.title !== 'Platinum') {
                populaGraphcBenefits();
              } else {
                populaGraphcBenefitsPlatinumD0();
              }
            }}
          >
            <IconContext.Provider value={{ className: 'react-icons' }}>
              <MdOutlineRefresh />
            </IconContext.Provider>
          </S.BtnDownload>
        </S.RightSide>
      </S.Header>

      <ContainerFlex>
        <BoxStore
          categories={dataResponse?.loja?.categories}
          series={dataResponse?.loja?.series}
          subTitle={updateHour}
          widthGraphic={960}
        />
      </ContainerFlex>

      <ContainerFlex marginTop={20}>
        <BoxOperators
          subTitle={updateHour}
          categories={dataResponse?.operador?.categories}
          series={dataResponse?.operador?.series}
        />
      </ContainerFlex>
    </Container>
  );
};

export default DashDetailsBenefitsRealTime;
