import styled from 'styled-components';

export interface ILoadingContainer {
  status?: boolean;
  color?: string;
}

export const LoadingContainer = styled.div<ILoadingContainer>`
  height: 100%;
  width: 100%;
  background-color: ${({ color }) =>
    color ? `rgba(${color}, 0.8)` : 'rgba(255, 255, 255, 0.98)'};
  top: 0;
  left: 0;
  position: absolute;
  z-index: 99999;
  display: ${(p) => (p.status ? 'flex' : 'none')};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = styled.div`
  border: 10px solid rgba(255, 255, 255, 0.1);
  border-top: 10px solid #e71037;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Text = styled.div`
  font-weight: bold;
  position: absolute;
  margin: auto auto auto auto;
  padding-top: 150px;
  color: #e71037;
  font-size: 1.5rem;
  z-index: 10000;
`;
