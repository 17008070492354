import React from 'react';

import ButtonSearch from '~/components/buttonSearch';

import * as St from './styles';

export interface IListCol {
  border?: boolean;
  id?: any;
  title?: string;
  value?: any;
  item?: any;
  valueColor?: string;
  searchAction?: Function;
  maxChar?: number;
  scratched?: boolean;
  reportView?: boolean;
  icon?: string;
  origin?: string;
  fontsize?: string;
  textAlign?: string;
}

const ListCol: React.FC<IListCol> = ({
  border,
  id,
  title,
  value,
  item,
  searchAction,
  valueColor,
  maxChar,
  scratched,
  icon,
  reportView = false,
  fontsize,
}) => {
  return (
    <>
      {/* {valueColor && (
        <St.ListRowBulletContainer>
          <St.ListRowBullet color={valueColor} />
        </St.ListRowBulletContainer>
      )} */}

      <St.Data
        border={border}
        hasAction={searchAction != null}
        maxChar={maxChar}
        scratched={scratched}
        reportView={reportView}
        fontsize={fontsize}
      >
        {valueColor && <St.ListRowBullet color={valueColor} />}
        {icon && <St.Icon src={icon} alt="icon" />}

        <span>
          <h4>{title || '-'}</h4>

          <span>{value}</span>
        </span>
        {searchAction && (
          <St.ButtonContainer>
            <ButtonSearch onClick={() => searchAction(id, item)} />
          </St.ButtonContainer>
        )}
      </St.Data>
    </>
  );
};

export default ListCol;
