import React, { useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import ContainerFlex from '~/components/containerFlex';

import BoxStore from '../components/boxStore';
import BoxOperators from '../components/boxOperators';

import * as S from '../styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Container from '~/components/container';

interface IBoxGraphcic {
  loja?: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  operador?: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
}

interface IProps {
  dataFinal: string;
  dataInicial: string;
  subTitle: string;
  range: string;
  title: string;
  idBeneficio: string;
}

const DashDetailsAdhesions: React.FC = () => {
  const history = useHistory();
  const [dataResponse, setDataResponse] = useState<IBoxGraphcic>();

  const { state } = useLocation<IProps>();

  const getBenefits = useApi(
    Endpoints.graphc.getBenefitsDetails,
    ApiMethod.POST,
  );

  const postBenefitsPlatinumD1 = useApi(
    Endpoints.graphc.postDetaAdesoesPlatinumD1,
    ApiMethod.POST,
  );

  useEffect(() => {
    if (state.title !== 'Platinum') {
      populaGraphcBenefits();
    } else {
      populaGraphcBenefitsPlatinumD1();
    }
  }, []);

  const populaGraphcBenefits = async () => {
    try {
      const response = await getBenefits.callApi({
        dataFinal: state?.dataFinal,
        dataInicial: state?.dataInicial,
        idBeneficios: state?.idBeneficio,
      });

      if (response.status >= 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphcic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const populaGraphcBenefitsPlatinumD1 = async () => {
    try {
      const response = await postBenefitsPlatinumD1.callApi({
        dataFinal: state?.dataFinal,
        dataInicial: state?.dataInicial,
      });

      if (response.status >= 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphcic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Loading status={getBenefits.loading || postBenefitsPlatinumD1.loading} />
      <S.Header>
        <S.LeftSide>
          <FiArrowLeft onClick={() => history.goBack()} />
          <S.HeaderTitle>
            <S.Title onClick={() => history.goBack()}>{state?.title}</S.Title>
          </S.HeaderTitle>
        </S.LeftSide>
      </S.Header>
      <ContainerFlex marginBottom={24}>
        <BoxOperators
          categories={dataResponse?.operador.categories}
          series={dataResponse?.operador.series}
          subTitle={state?.subTitle}
          widthGraphic={960}
        />
      </ContainerFlex>

      <ContainerFlex>
        <BoxStore
          categories={dataResponse?.loja.categories}
          series={dataResponse?.loja.series}
          subTitle={state?.subTitle}
          widthGraphic={960}
        />
      </ContainerFlex>
    </Container>
  );
};

export default DashDetailsAdhesions;
