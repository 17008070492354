import React, { useState } from 'react';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';
import { setFormState } from '~/utils/form';
import { UFs } from '../../../constants';

import * as S from '../../styles';

interface IFilterProposal {
  operador: any[];
  onClick?: (any: any) => void;
}

const FilterProposal: React.FC<IFilterProposal> = ({ operador, onClick }) => {
  const [form, setForm] = useState({
    tipoProposta: { name: 'tipoProposta', value: 'CADASTRADA' },
    origem: 'TODOS',
    uf: 'TODOS',
    idAtendente: 'TODOS',
    primeiraCompra: 'string',
  });

  const handleChange = (el): void => {
    onClick(el);
  };

  const PROPOSAL_TYPES = [
    {
      id: 'CADASTRADA',
      name: 'Propostas cadastradas',
      value: 'Propostas cadastradas',
    },
    {
      id: 'APROVADA',
      name: 'Propostas aprovadas',
      value: 'Propostas aprovadas',
    },
    {
      id: 'REPROVADA',
      name: 'Propostas reprovadas',
      value: 'Propostas reprovadas',
    },
    {
      id: 'PROPOSTA_P1',
      name: 'Aprovadas em P1',
      value: 'Aprovadas em P1',
    },
    {
      id: 'REPROVADA_P1',
      name: 'Reprovadas em P1',
      value: 'Reprovadas em P1',
    },
  ];

  return (
    <S.BoxCard>
      <ContainerFlex row space>
        <ContainerFlex padding={16}>
          <Select
            options={PROPOSAL_TYPES}
            label="Tipo Proposta"
            name={form.tipoProposta.name}
            value={form.tipoProposta.value}
            onChange={(e) => {
              setFormState(
                { target: { name: form.tipoProposta.name, value: e } },
                form,
                setForm,
              );
            }}
          />
        </ContainerFlex>

        <ContainerFlex padding={16}>
          <Select
            options={[
              { id: 'TODOS', value: 'Todos os operadores' },
              { id: '6041102c1b705c0007d62e81', value: 'Web' },
              ...operador,
            ]}
            label="Operador(a) de Venda"
            value={form.idAtendente}
            onChange={(e) => {
              setForm({ ...form, idAtendente: e });
            }}
          />
        </ContainerFlex>

        <ContainerFlex padding={16}>
          <Select
            options={[
              { id: 'TODOS', value: 'Todas as origens' },
              { id: 'LOJA', value: 'Loja' },
              { id: 'SITE', value: 'Site' },
              { id: 'APP', value: 'App' },
              { id: 'TOTEM', value: 'Totem' },
            ]}
            label="Origem"
            value={form.origem}
            onChange={(e) => {
              setForm({ ...form, origem: e });
            }}
          />
        </ContainerFlex>

        {['CADASTRADA', 'APROVADA', 'PROPOSTA_P1'].includes(
          form.tipoProposta.value,
        ) && (
          <ContainerFlex padding={16}>
            <Select
              options={UFs}
              label="UF"
              value={form.uf}
              onChange={(e) => {
                setForm({ ...form, uf: e });
              }}
            />
          </ContainerFlex>
        )}

        <ContainerFlex flex="0.2" padding={16}>
          <Button
            onClick={() => {
              handleChange(form);
            }}
          >
            <span>Consultar</span>
          </Button>
        </ContainerFlex>
      </ContainerFlex>
    </S.BoxCard>
  );
};

export default FilterProposal;
