/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { ISharedServiceContextProps } from '../Interfaces/sharedInterfaces';

const SharedServiceContext = createContext<ISharedServiceContextProps>({
  cpfGlobal: '',
  setCpfGlobal: () => {},
  nameGlobal: '',
  setNameGlobal: () => {},
  messageText: '',
  setMessageText: () => {},
  messageActive: false,
  setMessageActive: () => {},
  eventId: '',
  setEventId: () => {},
  opinion: '',
  setOpinion: () => {},
  listArrayCancelType: [],
  setListArrayCancelType: () => {},
  product: { id: '', value: '' },
  setProduct: () => {},
  event: { id: '', value: '' },
  setEvent: () => {},
  availableProducts: [],
  setAvailableProducts: () => [],
  cardInfos: undefined,
  getClientInformation: () => {},
  getAvailableProducts: () => {},
  getInfoCards: () => {},
  getPhoto: () => {},
  postCloseLead: () => {},
  postIncludeEvent: () => {},
  clientData: undefined,
  setClientData: () => {},
  loadingGlobal: {
    getUserInfo: { loading: false },
    includeEvent: { loading: false },
    getAvailableProductTypes: { loading: false },
    getThemeClientCategory: { loading: false },
    getClientPhoto: { loading: false },
    closeLead: { loading: false },
    getRetProfiles: { loading: false },
    postForwardLead: { loading: false },
    getContactHistory: { loading: false },
    postNewContact: { loading: false },
    postExecuteEvent: { loading: false },
  },
  resetContext: () => {},
  getProfiles: () => {},
  handleForwardLead: () => {},
  openForwardLead: () => {},
  cardModelSelected: '',
  setCardModelSelected: () => {},
  phoneZeuss: { masked: '', unmasked: '' },
  setPhoneZeuss: () => {},
  executeEvent: (type: boolean, flux: boolean) => {},
  executeEventNew: () => {},

  disabledButton: false,
  disableButtonBenefit: false,
  disabledByProfile: false,
  modalDescriptionOpen: false,
  setModalDescriptionOpen: () => {},
  fileName: '',
  setFileName: () => {},
  file: undefined,
  setFile: () => {},
});

export default SharedServiceContext;
