import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  padding: 0.375rem 0.75rem;
  border: none;
  border-radius: 0.375rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${darken(0.045, '#ffffff')};
  }

  svg {
    width: 18px;
  }
`;
