import styled from 'styled-components';

interface IBullet {
  color?: string;
}

export const SubTitleBullet = styled.div<IBullet>`
  flex-shrink: 0;
  background-color: ${(p) => (p.color ? p.color : '#ccc')};
  border-radius: 50px;
  height: 22px;
  width: 22px;
  margin-right: 8px;
  margin-left: 4px;
`;

export const SubTitleIcon = styled.img`
  flex-shrink: 0;
  height: 22px;
  width: 22px;
  margin-right: 8px;
  margin-left: 4px;
`;

export const SubTitleValue = styled.span`
  align-self: center;
  font-weight: 300;
  font-size: 0.8em;
`;

export const Container = styled.div`
  :hover ${SubTitleValue} {
    text-decoration: none;
  }
`;
