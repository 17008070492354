import { useContext } from 'react';
import { IProposalContext, ProposalContext } from '~/contexts/proposal';

export function useProposal(): IProposalContext {
  const context = useContext(ProposalContext);

  if (!context) {
    throw new Error('useProposal must be used within an ProposalContext');
  }

  return context;
}
