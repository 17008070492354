import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 1rem;
  > li {
    margin: 8px 0px;
    cursor: pointer;
    :hover {
      background-color: silver;
    }
  }
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;
