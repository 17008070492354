/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Layout from '~/components/layout';
import RadioButton from '~/components/radioButton';
import TextArea from '~/components/textarea';
import TitleApp from '~/components/title';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Constants from '~/utils/constants';
import { FormField, setFormState } from '~/utils/form';
import {
  cpfMask,
  currencyMask,
  IDefaultReturn,
  currencyUnmasked,
} from '~/utils/masks';
import * as Masks from '~/utils/masks';

import * as St from './styles';
import Loading from '~/components/loading';
import Container from '~/components/container';

interface IErrors {
  parecer?: string;
  limitRoot?: string;
  limitRppt30Percent?: string;
}

interface LocationState {
  cpf: string;
  numeroCartao: string;
  nome: string;
  valorDivida: boolean;
}

interface IForm {
  cpf?: IDefaultReturn;
  nomeCliente?: string;
  totalDebt?: number;
  amountReceived?: FormField;
  operationRecipe?: FormField;
  cadastroAtualizado?: boolean;
  dividaTeuCardQuitada?: boolean;
  idUsuarioInclusao?: string;
  numeroCartao?: string;
  parecerSolicitacao?: string;
  restabeleceLimite?: boolean;
  valorDivida?: number;
  saldoFaturaAtual?: number;
}

const ReactivateUser = (): JSX.Element => {
  const [parecer, setParecer] = useState('');
  const [errors, setErrors] = useState<IErrors>({} as IErrors);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { state } = useLocation();
  const [descriptionError, setDescriptionError] = useState(null);
  const [dialogDecisionMessage, setDialogDecisionMessage] = useState('');
  const [dialogDecisionResponse, setDialogDecisionResponse] = useState(false);
  const [goBackDialog, setGoBackDialog] = useState<boolean>(false);
  const [dialogDecision, setDialogDecision] = useState(false);
  const [restoreLimit, setRestoreLimit] = useState<boolean>(true);
  const [debtsPaid, setDebtsPaid] = useState<boolean>(true);
  const [updatedRegistration, setUpdatedRegistration] = useState<boolean>(true);
  const [amountReceived, setAmountReceived] = useState(0);
  const [operationRecipe, setOperationRecipe] = useState<any>('');
  const [dialogMessageStatus, setDialogMessageStatus] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [dialogMessageGoBack, setDialogMessageGoBack] = useState(false);
  const [delayedDays, setDelayedDays] = useState(0);
  const [reducelimit, setReduceLimit] = useState({
    compras: false,
    emprestimo: false,
  });

  const [form, setForm] = useState<IForm>({
    totalDebt: 0,
    amountReceived: { name: 'aumountReceived', value: '' },
  } as IForm);

  const goBack = () => history.goBack();

  const SendReactivateDecision = useApi<any>(
    `${Endpoints.register.sendReactivateDecision}`,
    ApiMethod.POST,
  );
  const SearchTotalBalanceInvoice = useApi<any>(
    Endpoints.consult.searchTotalBalanceInvoice.replace(
      'PARAM_CPF',
      location.state.cpf,
    ),
    ApiMethod.GET,
  );

  const getUserDaysDelay = useApi<any>(
    Endpoints.consult.getUserDaysDelay.replace(
      'PARAM_NUMERO_CARTAO',
      location.state.numeroCartao,
    ),
    ApiMethod.GET,
  );

  const closeModalMessage = () => {
    setDialogMessageStatus(false);

    if (dialogMessageGoBack) goBack();
  };

  const [disabledButtonSubmit, setDisabledButtonSubmit] = useState(false);
  const loadData = (data) => {
    const obj: IForm = {
      totalDebt: data.saldoFaturaAtual,
      amountReceived: { name: 'aumountReceived', value: '' },
    };

    setForm(obj);
  };

  const sendReactivateDecision = async () => {
    if (!parecer.trim().length) {
      setDescriptionError('Preencha esse campo');
      return;
    }

    if (!amountReceived) {
      setDescriptionError('Preencha esse campo');
      return;
    }
    setDescriptionError(null);
    setDisabledButtonSubmit(true);

    try {
      const payload = {
        cpf: location.state.cpf,
        nomeCliente: location.state.nome,
        dividaTeuCardQuitada: debtsPaid,
        numeroCartao: location.state.numeroCartao,
        idUsuarioInclusao: localStorage.getItem('USER_ID'),
        cadastroAtualizado: updatedRegistration,
        parecerSolicitacao: parecer,
        restabeleceLimite: restoreLimit,
        valorDivida: form.totalDebt,
        valorPago: currencyUnmasked(amountReceived.toString()),
        reduzirlimite: reducelimit.compras,
        reduzirLimiteSaque: reducelimit.emprestimo,
        // Não sabemos o nome que será utilizado para chamar o Usuario que está incluindo
        // nomeUsuarioInclusao: localStorage.getItem('@NovaEra:user:nomeTitular'),
      };

      const response = await SendReactivateDecision.callApi(payload);

      if (response.status === 200 || response.status === 201) {
        setDialogMessage(
          'Cliente enviado com sucesso para decisão de reativação',
        );
        setDialogMessageGoBack(true);
      } else {
        setDialogMessage(response.mensagem);
        setDialogMessageGoBack(false);
      }
      // setGoBackDialog(true);
    } catch (error) {
      console.log(error);
    } finally {
      setDisabledButtonSubmit(false);
      setDialogMessageStatus(true);
      setDialogMessageGoBack(true);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await SearchTotalBalanceInvoice.callApi();

      const responseUserDaysDelay = await getUserDaysDelay.callApi();

      if (response.status === 200) {
        loadData(response.data);
      }
      const { data } = responseUserDaysDelay.data;

      setDelayedDays(data.dias_em_atraso);
      setReduceLimit({
        compras: data.dias_em_atraso >= 100,
        emprestimo: true,
      });
    }
    fetchData();
  }, []);

  const alphaRegex = /[a-zA-Z]/;

  const operation = (totalDebtValue, amountReceivedValue) => {
    setOperationRecipe(
      String(
        (parseFloat(amountReceivedValue) - parseFloat(totalDebtValue)).toFixed(
          2,
        ),
      ),
    );
  };

  return (
    <>
      <Dialog
        icon={
          SendReactivateDecision.status === 200
            ? icons.confirmation
            : icons.warning
        }
        open={dialogDecisionResponse}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogDecisionResponse(false);
          if (goBackDialog) {
            goBack();
          }
        }}
        message={dialogDecisionMessage}
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogDecision}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogDecision(false)}
        buttonDisabledPositive={disabledButtonSubmit}
        Loading={SendReactivateDecision.loading}
        positiveLabel="Enviar para decisão"
        positiveAction={sendReactivateDecision}
        message="Você tem certeza que deseja enviar cliente para decisão de reativação?"
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogMessageStatus}
        positiveLabel="Entendi"
        positiveAction={closeModalMessage}
        message={dialogMessage}
      />

      <Loading
        status={SearchTotalBalanceInvoice.loading || getUserDaysDelay.loading}
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <ContainerFlex>
              <TitleApp>Reativar Cliente - Solicitação</TitleApp>
            </ContainerFlex>

            <ContainerFlex>
              <St.TitleCategory paddingLeft={26}>
                1. Dados da solicitação
              </St.TitleCategory>
              <Grid xs={1} lg={2}>
                <ContainerFlex style={{ width: '100%' }}>
                  <Grid xs={1} lg={1} gap={30}>
                    <ContainerFlex padding={26}>
                      <Input
                        label="Nome completo"
                        name="nome"
                        value={location.state.nome}
                      />

                      <ContainerFlex
                        horizontal
                        space
                        style={{
                          boxShadow: '1px 1px 7px 0 rgba(201,201,201, 0.5)',
                        }}
                        marginTop={20}
                        padding={10}
                      >
                        <ContainerFlex
                          justifyContent="space-between"
                          row
                          horizontal
                          align="center"
                        >
                          <ContainerFlex>
                            <St.Subtitle> Devolver cartão?</St.Subtitle>
                          </ContainerFlex>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                onClick={() => setRestoreLimit(true)}
                                checked={restoreLimit === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                onClick={() => setRestoreLimit(false)}
                                checked={restoreLimit === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>

                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle>Dívidas Teucard quitadas?</St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() => setDebtsPaid(true)}
                                checked={debtsPaid === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() => setDebtsPaid(false)}
                                checked={debtsPaid === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle> Cadastro foi atualizado?</St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() => setUpdatedRegistration(true)}
                                checked={updatedRegistration === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() => setUpdatedRegistration(false)}
                                checked={updatedRegistration === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle>Reduzir limite de compras?</St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() =>
                                  setReduceLimit((prev) => ({
                                    ...prev,
                                    compras: true,
                                  }))
                                }
                                checked={reducelimit.compras === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() =>
                                  setReduceLimit((prev) => ({
                                    ...prev,
                                    compras: false,
                                  }))
                                }
                                checked={reducelimit.compras === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        {reducelimit.compras && (
                          <St.ContainerRadio>
                            <St.TitleNotice>
                              Reduzir limite em 50%
                            </St.TitleNotice>
                          </St.ContainerRadio>
                        )}
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle>
                            Reduzir limite de empréstimo?
                          </St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() =>
                                  setReduceLimit((prev) => ({
                                    ...prev,
                                    emprestimo: true,
                                  }))
                                }
                                checked={reducelimit.emprestimo === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form.idUsuarioInclusao}
                                type="radio"
                                onClick={() =>
                                  setReduceLimit((prev) => ({
                                    ...prev,
                                    emprestimo: false,
                                  }))
                                }
                                checked={reducelimit.emprestimo === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        {reducelimit.emprestimo && (
                          <St.ContainerRadio>
                            <St.TitleNotice>
                              Reduzir limite em{' '}
                              {delayedDays >= 100 ? '100%' : '50%'}
                            </St.TitleNotice>
                          </St.ContainerRadio>
                        )}
                      </ContainerFlex>
                    </ContainerFlex>
                  </Grid>
                </ContainerFlex>
                <ContainerFlex>
                  <Grid xs={1} lg={1} gap={24}>
                    <ContainerFlex padding={26}>
                      <Input
                        label="CPF"
                        name="cpf"
                        mask={MaskTypes.CPF}
                        placeholder="000.000.000-00"
                        value={Masks.cpfMask(location.state.cpf).masked}
                      />
                      <ContainerFlex>
                        <Input
                          label="Dias de atraso"
                          name="delayedDays"
                          value={delayedDays}
                          readOnly
                        />
                      </ContainerFlex>
                      <ContainerFlex>
                        <Input
                          label="Total da Dívida do cliente"
                          name="totalDebt"
                          readOnly
                          value={currencyMask(form.totalDebt)}
                        />
                      </ContainerFlex>
                      <ContainerFlex>
                        <Input
                          label="Valor total recebido no acordo"
                          name="amountReceived"
                          value={amountReceived}
                          mask={MaskTypes.CURRENCY}
                          inputMode="numeric"
                          edit
                          onChange={(e) => {
                            if (alphaRegex.test(String(e.target.value))) return;
                            setAmountReceived(e.target.value);
                            operation(
                              parseFloat(form?.totalDebt?.toString()).toFixed(
                                2,
                              ),

                              parseFloat(
                                currencyUnmasked(e?.target?.value).toString(),
                              ).toFixed(2),
                            );
                          }}
                        />
                        <div style={{ marginTop: '-20px' }}>
                          <St.TitleAbbrFooter>
                            * Obrigatório preencher
                          </St.TitleAbbrFooter>
                        </div>
                      </ContainerFlex>
                      <ContainerFlex>
                        <Input
                          style={{
                            color: operationRecipe < 0 ? 'red' : 'black',
                          }}
                          label="Receita da Operação"
                          name="operationRecipe"
                          mask={MaskTypes.CURRENCY}
                          value={operationRecipe}
                        />
                      </ContainerFlex>
                    </ContainerFlex>
                  </Grid>
                </ContainerFlex>
              </Grid>
              <St.TitleCategory paddingLeft={26}>
                2. Parecer da solicitação
                <St.TitleAbbr title="Obrigatório">*</St.TitleAbbr>
              </St.TitleCategory>
              <ContainerFlex padding={26}>
                <TextArea
                  placeholder="Digitar parecer.."
                  name="parecer"
                  rows={10}
                  value={parecer}
                  onChange={(e) => setParecer(e.target.value)}
                  errorMessage={errors.parecer}
                />
                <div style={{ marginTop: '10px' }}>
                  <St.TitleAbbrFooter>
                    * Obrigatório preencher
                  </St.TitleAbbrFooter>
                </div>
              </ContainerFlex>
            </ContainerFlex>

            <CardView>
              <ContainerFlex row horizontal padding={26} end>
                <Button outlined onClick={goBack}>
                  Voltar
                </Button>
                <Button
                  marginLeft={16}
                  onClick={() => setDialogDecision(true)}
                  disabled={amountReceived ? parecer.length <= 0 : true}
                  // disabled={
                  //   (parecer.length > 0,
                  //   amountReceived ? !showOptionsRule() : true)
                  // }
                >
                  Enviar para decisão
                </Button>
              </ContainerFlex>
            </CardView>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ReactivateUser;
