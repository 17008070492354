/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import ListRow from '~/components/listView/row';
import { IListCol } from '~/components/listView/row/col';
import Loading from '~/components/loading';

import useApi, { ApiMethod } from '~/hooks/useApi';

import Endpoints from '~/services/endpoints';
import { IResponseUploadHistory } from '~/services/files/uploadHistory/response';
import { transformInGlobalDate } from '~/utils/date';
import { FormField, setFormState } from '~/utils/form';
import { currencyMask } from '~/utils/masks';

import * as St from './styles';

interface Form {
  date: FormField;
}

const FileHistory = (): JSX.Element => {
  const [form, setForm] = useState<Form>({
    date: { name: 'date', value: '' },
  });
  const [list, setList] = useState([]);
  const [error, setError] = useState<string | null>();

  const RequestUploadHistory = useApi<IResponseUploadHistory>(
    Endpoints.files.uploadHistory,
    ApiMethod.POST,
    { data: transformInGlobalDate(form.date.value) },
  );

  const RequestUploadHistoryPerDay = useApi<IResponseUploadHistory>(
    Endpoints.files.uploadHistoryPerDay.replace('PARAM_DAYS', '5'),
    ApiMethod.GET,
  );

  useEffect(() => {
    RequestUploadHistoryPerDay.callApi();
  }, []);

  useEffect(() => {
    loadData(RequestUploadHistory);
  }, [RequestUploadHistory.loading]);

  useEffect(() => {
    loadData(RequestUploadHistoryPerDay);
  }, [RequestUploadHistoryPerDay.loading]);

  const searchUser = () => {
    if (!form.date.value.length) return RequestUploadHistoryPerDay.callApi();

    if (moment(form.date.value, 'DD/MM/YYYY', true).isValid()) {
      setError(null);
      RequestUploadHistory.callApi();
    }
  };

  const loadData = (request: ReturnType<typeof useApi>) => {
    if (request.status !== 200) {
      setError(request.data?.mensagem || request.status);
      setList([]);
      return;
    }

    setError(null);

    const dataRes = request.data as IResponseUploadHistory;

    const newList = [];

    dataRes.arquivosProcessados?.forEach((data) => {
      const item: IListCol[] = [
        {
          border: true,
          title: 'Título do Arquivo',
          value: data.filename,
        },
        {
          border: true,
          title: 'Data e Hora - Importação',
          value: data.dataHoraCriacaoArquivo,
        },
        {
          border: true,
          title: 'Quantidade Pagamento',
          value: data.totalBaixasRealizadas,
        },
        {
          border: true,
          title: 'Valor Total de Pagamento',
          value: currencyMask(data.valorTotalDePagamento),
        },
        {
          title: 'Data e Hora - Processado',
          value: `${moment(data.processamento).format('DD/MM/YYYY')} ${moment(
            data.processamento,
          ).format('HH:mm:ss')}`,
        },
      ];

      newList.push(item);
    });

    setList(newList);
  };

  return (
    <>
      <Loading
        status={
          RequestUploadHistory.loading || RequestUploadHistoryPerDay.loading
        }
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16} flex={0.2}>
              <Input
                label="Data de processamento"
                name={form.date.name}
                value={form.date.value}
                onChange={(e) => setFormState(e, form, setForm)}
                placeholder="dd/mm/aaaa"
                maxLength={10}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={searchUser}
                loading={RequestUploadHistory.loading}
                disabled={
                  (form.date.value.length !== 10 && !!form.date.value.length) ||
                  RequestUploadHistory.loading
                }
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {list.length > 0 && (
          <St.BoxCard>
            <ContainerFlex row space>
              {error && (
                <ContainerFlex center>
                  <St.TitleCategory>{error}</St.TitleCategory>
                </ContainerFlex>
              )}

              {list.map((i) => (
                <ListRow key={i} item={i} />
              ))}
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default FileHistory;
