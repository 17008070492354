import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { creditCardMask, currencyMask } from '~/utils/masks';

export const HeaderReportTransactions = [
  {
    id: 1,
    value: 'Data da Transação',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Estabelecimento',
    maxChar: 25,
  },
  {
    id: 3,
    value: 'Número do Cartão',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Parcela',
    maxChar: 8,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Valor',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Valor Compra',
    maxChar: 20,
    textAlign: 'center',
  },
];

export const BodyReportTransactions = (data: any) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.data ? moment(i.data).format('DD/MM/YYYY HH:mm') : '-',
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.estabelecimento,
        maxChar: 25,
      },
      {
        value: creditCardMask(i.numeroCartao).masked,
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.parcela_atual,
        maxChar: 8,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.valor)}`,
        maxChar: 14,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.valorCompra)}`,
        maxChar: 20,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
