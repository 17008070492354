export const contractedHeader = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Data adesão',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Parcela',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Status',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Detalhes',
    maxChar: 30,
    textAlign: 'center',
  },
];

export const notContractedHeader = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Descrição',
    maxChar: 30,
    textAlign: 'center',
  },
  { id: 3, value: '', maxChar: 10, textAlign: 'center' },
  {
    id: 4,
    value: 'Parcela',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Isenção',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Detalhes',
    maxChar: 30,
    textAlign: 'center',
  },
];

export const headerBenefitDetails = [
  {
    id: 1,
    value: 'Adesão',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Nome',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Parcela',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Data Adesão',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Status',
    maxChar: 30,
    textAlign: 'center',
  },
];

export const headerAddDependent = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Nascimento',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Telefone',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Excluir',
    maxChar: 30,
    textAlign: 'center',
  },
];

export const headerBenefitDetailsDecision = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Descrição',
    maxChar: 30,
    textAlign: 'center',
  },
  { id: 3, value: '', maxChar: 10, textAlign: 'center' },
  {
    id: 4,
    value: 'Parcela',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Isenção',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Detalhes',
    maxChar: 30,
    textAlign: 'center',
  },
];
