import { useContext } from 'react';
import { IEventReport } from '../models';
import { EventReportContext } from './EventReportContext';

export function useReportEvent(): IEventReport {
  const context = useContext(EventReportContext);

  if (!context) {
    throw new Error('useReportEvent must be used whithin an EventContext');
  }

  return context;
}
