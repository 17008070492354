/* eslint-disable array-callback-return */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import LoadingComponents from '~/components/loadingInComponents';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { currencyMask } from '~/utils/masks';

import * as S from '../../styles';

interface IBoxGraphcic {
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
}

interface IBoxDailyBilling {
  data: {
    dataColeta: string;
    percentage: number;
    series: number[];
    valorTotal: number;
  };
}

const BoxGraphic: React.FC = () => {
  const [serie, setSerie] = useState([{ name: '', data: [] }]);
  const [dailyBilling, setDailyBilling] = useState({
    dataColeta: moment().format('DD/MM/YYYY'),
    percentage: 100,
    series: [0],
    valorTotal: 0,
  });
  const [optionsDailyBilling, setoptionsDailyBilling] = useState({});
  const [optionsProposal, setoptionsProposal] = useState({});

  const history = useHistory();

  const RequestGetStatusProposta = useApi(
    Endpoints.graphc.getGraphcPropostaStatus
      .replace('PARAM_DATA', moment().subtract(1, 'days').format('YYYY-MM-DD'))
      .replace('PARAM_CAMPO_ORDENADO', 'SERIES' || 'CATEGORIES')
      .replace('PARAM_TIPO_ORDENACAO', 'ASC' || 'DESC'),
    ApiMethod.GET,
  );

  const RequestGetDailyBilling = useApi(
    Endpoints.graphc.getDailyBilling,
    ApiMethod.GET,
  );

  useEffect(() => {
    searchProposalStatus();
    searchDailyBilling();
  }, []);

  const searchProposalStatus = async () => {
    try {
      const response = await RequestGetStatusProposta.callApi();

      if (response.status >= 200 && response.status < 300) {
        const { categories, series } = response.data as IBoxGraphcic;
        setSerie(series);
        setoptionsProposal({
          chart: {
            type: 'bar',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                // evento de click no gráfico
                // pega o index do gráfico
                history.push('/dashDetailsProposal', {
                  dataFinal: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                  dataInicial: moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                  type:
                    categories[config.dataPointIndex] === 'Em Análise'
                      ? 'EM_ANALISE'
                      : categories[config.dataPointIndex],
                  subTitle: `Referente ao dia ${moment()
                    .subtract(1, 'days')
                    .format('DD/MM/YYYY')}`,
                  range: 'do dia anterior',
                });
              },
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '50%',
              distributed: true,
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -25,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter(val) {
                return val;
              },
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchDailyBilling = async () => {
    let responseData = {
      dataColeta: moment().format('DD/MM/YYYY'),
      percentage: 100,
      series: [0],
      valorTotal: 0,
    };

    try {
      const response = await RequestGetDailyBilling.callApi();

      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data as IBoxDailyBilling;
        responseData = data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDailyBilling(responseData);
      setoptionsDailyBilling({
        chart: {
          type: 'radialBar',
        },
        colors: ['#ff5722'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '84%',
            },
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: true,
                label: 'Total faturado',
                formatter(w) {
                  return `R$ ${currencyMask(responseData.valorTotal)}`;
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      });
    }
  };

  return (
    <S.BoxGraphics>
      <div className="item">
        <LoadingComponents status={RequestGetStatusProposta.loading} />
        <header>
          <h2>Propostas</h2>
          <span>
            {`Referente ao dia: ${moment()
              .subtract(1, 'days')
              .format('DD/MM/YYYY')}`}
          </span>
        </header>
        <div className="item__graphic-bar">
          <Chart
            options={optionsProposal}
            series={serie}
            type="bar"
            height={250}
            width={450}
          />
        </div>
      </div>
      <div className="item">
        <LoadingComponents status={RequestGetDailyBilling.loading} />
        <header>
          <h2>Faturamento de Compras</h2>
          <span>
            {`Referente ao dia: ${moment()
              .subtract(1, 'days')
              .format('DD/MM/YYYY')}`}
          </span>
        </header>

        <div className="item__graphic">
          <Chart
            options={optionsDailyBilling}
            series={dailyBilling?.series}
            type="radialBar"
            height={300}
            width={300}
          />
        </div>
      </div>
    </S.BoxGraphics>
  );
};

export default BoxGraphic;
