/* eslint-disable prettier/prettier */

import styled from 'styled-components';
import { IBenefitContainer, IOverlay } from './interfaces';
import THEMES from '~/themes/themes';

export const BenefitBackGroundArea = styled.div<IBenefitContainer>`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99999;
  display: ${(p) => (p.view ? 'flex' : 'none')};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const BenefitModal = styled.div`
  width: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const Header = styled.div`
  width: 100%;
  height: 20%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 25px;
  font-weight: bold;
  margin-left: 30px;
`;

export const Body = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CurrentBenefitSection = styled.div`
  width: 90%;
  height: 30%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Overlay = styled.div<IOverlay>`
  width: 100%;
  height: 80%;
  border-radius: 10px;
  border: 2px solid rgba(128, 128, 128, 0.15);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  opacity: ${({ active }) => (active ? 1 : 0)};
  z-index: ${({ active }) => (active ? 1 : -1)};
  transition: opacity 0.3s ease-in-out;
`;

export const OverlayCancel = styled.div<IOverlay>`
  width: 100%;
  height: 35%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.15);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ active }) => (active ? 1 : 0)};
  z-index: ${({ active }) => (active ? 1 : -1)};
  transition: opacity 0.3s ease-in-out;
`;

export const ViewOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
`;

export const ViewOverlayBack = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  border-left: 1px solid rgba(128, 128, 128, 0.15);
`;

export const CurrentSectionTitle = styled.h2`
  font-size: 15px;
`;

export const CurrentBenefit = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  border: 2px solid rgba(128, 128, 128, 0.15);
  align-items: center;
  margin-top: 5px;
`;

export const AccessionComp = styled.div`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Accession = styled.h2`
  font-size: 15px;
  margin-top: 25px;
`;

export const AccessionData = styled.span`
  font-size: 15px;
  margin-bottom: 30px;
`;

export const ToViewComp = styled.div`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ToView = styled.h2`
  font-size: 15px;
  margin-top: 25px;
`;

export const ToViewData = styled.span`
  margin-bottom: 25px;
`;

export const CanceledBenefitSection = styled.div`
  width: 90%;
  height: 80%;
  flex: 1;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
`;

export const CanceledSectionTitle = styled.h2`
  font-size: 15px;
`;

export const CanceledBenefitList = styled.div`
  width: 100%;
  height: 100%;
  max-height: 260px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid rgba(128, 128, 128, 0.15);
  margin-top: 5px;
  overflow-y: hidden;
`;

export const NullBenefitsCancel = styled.div`
  width: 100%;
  height: 100%;
  max-height: 260px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid rgba(128, 128, 128, 0.15);
  margin-top: 5px;
  justify-content: center;
  align-items: center; 
`;

export const NullBenefitText = styled.span`
  font-size: 15px;
  margin-top: 10px;
`;

export const CanceledBenefitItem = styled.div`
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid rgba(128, 128, 128, 0.1);
  align-items: center;
`;

export const Footer = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: end;
`;
