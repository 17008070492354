import styled from 'styled-components';

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;

export const Results = styled.p`
  font-weight: 400;
  font-size: 14px;
`;

export const Subtitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #d3d3d3;
`;
