import moment from 'moment';
import React from 'react';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';
import ProposalReason from '~/models/proposal-reason';
import { cpfMask } from '~/utils/masks';

export interface IReasonDialogItem {
  item: ProposalReason;
  fullWidth?: boolean;
}

const ReasonDialogItem: React.FC<IReasonDialogItem> = ({ item, fullWidth }) => (
  <>
    <ContainerFlex row>
      <CardView
        marginRight={10}
        padding={20}
        marginBottom={12}
        width={fullWidth ? 80 : 0}
        widthPixel={fullWidth ? 0 : 300}
      >
        <ContainerFlex>
          <ContainerFlex row space>
            <span>Data e Hora</span>
          </ContainerFlex>

          <br />

          <ContainerFlex row space>
            <span>
              {String(moment(item.dataInclusao).format('DD/MM/YYYY HH:mm'))}
            </span>
          </ContainerFlex>
        </ContainerFlex>
      </CardView>
      <CardView
        marginRight={10}
        padding={20}
        marginBottom={12}
        width={fullWidth ? 80 : 0}
        widthPixel={fullWidth ? 0 : 300}
      >
        <ContainerFlex>
          <ContainerFlex row space>
            <span>Usuário</span>
          </ContainerFlex>

          <br />

          <ContainerFlex row space>
            <span>{item.operador}</span>
          </ContainerFlex>
        </ContainerFlex>
      </CardView>
      <CardView padding={20} marginRight={20} marginBottom={12} width={100}>
        <ContainerFlex>
          <ContainerFlex row space>
            <span>Parecer de Solicitação</span>
          </ContainerFlex>

          <br />

          <ContainerFlex row space>
            <span>{item.justificativa}</span>
          </ContainerFlex>
        </ContainerFlex>
      </CardView>
    </ContainerFlex>
  </>
);

export default ReasonDialogItem;
