import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Collapse from '~/components/collapse';
import Container from '~/components/container';
import ClientData from '../../Components/ClientData';
import * as St from '../../Services/styles';
import CardTableList from '../../Components/CardTableList';
import { headerDowngrade, headerListPlan } from '../../Headers/cardHeaders';
import { SharedCardServicesContext } from '../Services/sharedCardServiceContext';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import SharedServiceContext from '../../Services/sharedServiceContext';

export const DowngradeDecision = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const {
    cpfGlobal,
    setCpfGlobal,
    cardInfos,
    availableProducts,
    getInfoCards,
    getClientInformation,
    getPhoto,
    getAvailableProducts,
    setOpinion,
    setEventId,
    setProduct,
    setEvent,
    loadingGlobal,
    opinion,
    eventId,
    getProfiles,
    openForwardLead,
    setCardModelSelected,
    executeEvent,
    executeEventNew,
    cardModelSelected,
    disabledButton,
    setModalDescriptionOpen,
    resetContext,
  } = useContext(SharedServiceContext);
  const {
    cpf,
    setCpf,
    cardsNotClients,
    opinionDowngrade,
    getNotClientCards,
    loading,
    setOpinionDowngrade,
    resetContextCard,
  } = useContext(SharedCardServicesContext);
  const [typeButton, setTypeButton] = useState<string>('');
  const [validateDowngradeButton, setValidateDowngradeButton] = useState(true);

  useEffect(() => {
    return () => {
      resetContext();
      resetContextCard();
    };
  }, []);

  useEffect(() => {
    if (opinionDowngrade?.length <= 0 || !cardModelSelected) {
      setValidateDowngradeButton(true);
    } else {
      setValidateDowngradeButton(false);
    }
  }, [opinionDowngrade, cardModelSelected]);

  useEffect(() => {
    if (state) {
      setCpf(state?.cpfGlobal || state?.item?.cpf);
      setCpfGlobal(state?.cpfGlobal || state?.item?.cpf);
      setProduct(
        state?.product || { id: 'CARTAO', value: state?.item?.produto.value },
      );
      setEvent(state?.event || state?.item?.tipoEvento);
      setEventId(state?.eventId || state?.item?.id);
    }
  }, [state]);

  useEffect(() => {
    if (cpf && cpfGlobal) {
      getClientInformation();
      getAvailableProducts();
      getInfoCards();
      getPhoto();
      getNotClientCards();
    }
  }, [cpf, cpfGlobal]);

  const finishEvent = () => {
    setModalDescriptionOpen(true);
  };

  const toFoward = () => {
    getProfiles();
  };

  const nextContact = () => {
    setTypeButton('NEXT_CONTACT');
    setEventId(state?.eventId || state?.item?.id);
  };

  useEffect(() => {
    if (eventId && typeButton === 'NEXT_CONTACT') {
      openForwardLead();
      setTypeButton('');
    }
  }, [eventId, typeButton]);

  const handleStartDowngrade = () => {
    setTypeButton('DOWNGRADE');
    setEventId(state?.eventId || state?.item?.id);
    setOpinion(opinionDowngrade);
  };

  useEffect(() => {
    if (opinion && eventId && typeButton === 'DOWNGRADE') {
      setTypeButton('');
      // executeEvent(true, false);
      executeEventNew({
        hasFile: false,
        websocket: true,
        recusar: false,
      });
    }
  }, [opinion, eventId]);

  return (
    <Container>
      <St.BoxCard>
        <Collapse collapseHeight={70} initialState="open" title="1. Cliente">
          <ClientData
            solicitation
            cardInfos={cardInfos}
            availableProducts={availableProducts}
            eventStart
            product={
              state?.product || {
                id: 'CARTAO',
                value: state?.item?.produto.value,
              }
            }
            event={state?.event || state?.item?.tipoEvento}
          />
        </Collapse>
        <Collapse title="2. Plano Atual" typeEvent>
          <CardTableList
            customerHeader={headerListPlan}
            item={cardInfos}
            type="current"
          />
        </Collapse>
        <Collapse
          // collapseHeight={notContractedBenefits.length * 20}
          callFunctionOnExpand={getNotClientCards}
          waitRequest={loading.getThemeClientNotCategory.loading}
          title="3. Alteração do plano"
        >
          <CardTableList
            isOpinion
            customerHeader={headerDowngrade}
            item={cardsNotClients}
            onSelectModel={(e: string) => setCardModelSelected(e)}
            typeEvent
            onOpinion={(e: string) => setOpinionDowngrade(e)}
          />
        </Collapse>
        <ContainerFlex row gap="10" end marginTop={20}>
          <Button
            outlined
            disabled={loadingGlobal.closeLead.loading}
            loading={loadingGlobal.closeLead.loading}
            onClick={() => {
              finishEvent();
            }}
          >
            Encerrar
          </Button>
          <Button
            outlined
            disabled={disabledButton || loadingGlobal.postForwardLead.loading}
            loading={loadingGlobal.postForwardLead.loading}
            onClick={() => {
              toFoward();
            }}
          >
            Encaminhar
          </Button>
          <Button
            outlined
            disabled={disabledButton || loadingGlobal.postNewContact.loading}
            loading={loadingGlobal.getContactHistory.loading}
            onClick={() => {
              nextContact();
            }}
          >
            Próximo Contato
          </Button>
          <Button
            outlined
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={validateDowngradeButton || disabledButton}
            loading={loadingGlobal.postExecuteEvent.loading}
            onClick={() => handleStartDowngrade()}
          >
            Downgrade
          </Button>
        </ContainerFlex>
      </St.BoxCard>
    </Container>
  );
};
