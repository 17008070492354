export const headerItems = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 10,
    textAlign: 'left',
  },
  {
    id: 2,
    value: 'Descrição',
    maxChar: 35,
    textAlign: 'left',
  },
];
export const enumsBackEndCancelamento = [
  'CANCELAMENTO_TOTAL',
  'CANCELAMENTO_TEUCARD',
  'CANCELAMENTO_CARTEIRA',
  'CANCELAMENTO_EMPRESTIMO',
  'CANCELAMENTO_APLICATIVO',
];

export const items = [
  [
    {
      value: 'Cancelar Totalmente',
      maxChar: 10,
      keyEnum: enumsBackEndCancelamento[0],
    },
    {
      value:
        'Cancelamento de todos os produtos e perda do acesso do cliente no aplicativo e web',
      maxChar: 35,
    },
  ],
  [
    {
      value: 'Cancelar apenas o TeuCard',
      maxChar: 10,
      keyEnum: enumsBackEndCancelamento[1],
    },
    {
      value: 'Cancelamento do Produto TeuCard, mas mantendo os demais.',
      maxChar: 35,
    },
  ],
  [
    {
      value: 'Cancelar apenas a Wallet',
      maxChar: 10,
      keyEnum: enumsBackEndCancelamento[2],
    },
    {
      value: 'Cancelamento do Produto Wallet, mas mantendo os demais.',
      maxChar: 35,
    },
  ],
  [
    {
      value: 'Cancelar apenas os Empréstimos',
      maxChar: 10,
      keyEnum: enumsBackEndCancelamento[3],
    },
    {
      value: 'Cancelamento do Produto Empréstimo, mas mantendo os demais.',
      maxChar: 35,
    },
  ],
  [
    {
      value: 'Cancelar o acesso ao aplicativo',
      maxChar: 10,
      keyEnum: enumsBackEndCancelamento[4],
    },
    {
      value:
        'Cancelamento do acesso do cliente ao aplicativo e web, mas mantendo os produtos.',
      maxChar: 35,
    },
  ],
];
