import styled from 'styled-components';

export interface ITitleApp {
  marginBottom?: number;
}

const TitleApp = styled.span<ITitleApp>`
  font-size: 1.5em;
  font-weight: 600;
  color: #4a4b4a;
  margin: 0 0 ${(p) => (p.marginBottom ? `${p.marginBottom}%` : '0px')} 0;
`;

export default TitleApp;
