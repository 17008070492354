import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask, phoneMask } from '~/utils/masks';

export const HeaderReportAproved = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 11,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 11,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Proposta',
    maxChar: 11,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Aprovação',
    maxChar: 11,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Operador(a) de Venda',
    maxChar: 13,
  },
  {
    id: 6,
    value: 'Usuário',
    maxChar: 8,
  },
  {
    id: 7,
    value: 'Celular',
    maxChar: 11,
    textAlign: 'center',
  },
  {
    id: 8,
    value: 'Origem',
    maxChar: 9,
    textAlign: 'center',
  },
  {
    id: 9,
    value: 'Valor limite',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 10,
    value: 'Motivo',
    maxChar: 10,
  },
];

export const BodyReportApproved = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nome,
        maxChar: 14,
      },
      {
        value: cpfMask(i.cpf).masked,
        maxChar: 11,
        textAlign: 'center',
      },

      {
        value: i.dataProposta
          ? moment(i.dataProposta).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 13,
        textAlign: 'center',
      },
      {
        value: i.dataAprovacao
          ? moment(i.dataAprovacao).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 13,
        textAlign: 'center',
      },

      {
        value: i.operador,
        maxChar: 12,
      },
      {
        value: i.nomeUsuarioMesa,
        maxChar: 8,
      },
      {
        value: phoneMask(i.celular).masked,
        maxChar: 12,
        textAlign: 'center',
      },
      {
        value: i.origem,
        maxChar: 9,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.valorLimite)}`,
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.motivoReprovacao,
        maxChar: 10,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
