/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input from '~/components/input';
import { getParameters } from '../model';

import * as St from '../style';

interface IProps {
  readonly parameters: getParameters[];
  generateCampaign: any;
}

const PointsStatus: React.FC<IProps> = ({ parameters, generateCampaign }) => {
  const [valorParcelada, setValorParcelada] = useState('');
  const [valorPaga, setValorPaga] = useState('');
  const [valorParcial, setValorParcial] = useState('');

  useEffect(() => {
    parameters?.filter((paran) => {
      switch (paran.descricao) {
        case 'PONTO_FATURA_PARCELADA':
          setValorParcelada(paran.valor);
          break;
        case 'PONTO_FATURA_PAGA':
          setValorPaga(paran.valor);
          break;
        case 'PONTO_FATURA_PARCIAL':
          setValorParcial(paran.valor);
          break;
      }
    });
  }, [parameters]);
  return (
    <ContainerFlex marginTop={15}>
      <ContainerFlex row wrap marginBottom={10}>
        <St.TitleLevel>Pontos por status da fatura</St.TitleLevel>
      </ContainerFlex>

      <ContainerFlex row space marginBottom={15}>
        <Grid xs={1} lg={2} xl={7} gap={30}>
          <ContainerFlex>
            <Input label="Status" value="PAGO" readOnly />
          </ContainerFlex>

          <ContainerFlex>
            <Input label="Pontuação" value={valorPaga} readOnly />
          </ContainerFlex>

          <ContainerFlex>
            <Input label="Status" value="PARCIAL" readOnly />
          </ContainerFlex>

          <ContainerFlex>
            <Input label="Pontuação" value={valorParcial} readOnly />
          </ContainerFlex>

          <ContainerFlex>
            <Input label="Status" value="PARCELADA" readOnly />
          </ContainerFlex>

          <ContainerFlex>
            <Input label="Pontuação" value={valorParcelada} readOnly />
          </ContainerFlex>
        </Grid>
        <Button borderRadius={10} fitContent onClick={() => generateCampaign()}>
          <span>Gerar campanha</span>
        </Button>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default PointsStatus;
