import React, { useEffect, useState } from 'react';

import * as St from './styles';

interface ILoading {
  status?: boolean;
  text?: string;
  webSocket?: boolean;
  firstMessage?: string;
  secondMessage?: string;
}

const Loading: React.FC<ILoading> = ({
  status,
  text,
  webSocket,
  firstMessage,
  secondMessage,
}) => {
  const [textWebsocket, setTextWebSocket] = useState(firstMessage);
  const [initEvent, setInitEvent] = useState(true);

  useEffect(() => {
    if (webSocket) {
      const intervalId = setInterval(() => {
        setInitEvent((prevInitEvent) => !prevInitEvent);
      }, 1000);

      const textIntervalId = setInterval(() => {
        setTextWebSocket((prevText) =>
          prevText === firstMessage ? secondMessage : firstMessage,
        );
      }, 2000);

      return () => {
        clearInterval(intervalId);
        clearInterval(textIntervalId);
      };
    }
  }, [webSocket]);

  return (
    <St.LoadingContainer status={status}>
      <St.LoadingSpinner />
      {webSocket && (
        <St.FadeAndSlideContainer active={initEvent}>
          <St.TextLoadingWebSocket>{textWebsocket}</St.TextLoadingWebSocket>
        </St.FadeAndSlideContainer>
      )}
      <St.Text>{text}</St.Text>
    </St.LoadingContainer>
  );
};

export default Loading;
