import styled, { css, DefaultTheme } from 'styled-components';

export enum MessageType {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
}

interface IContainer {
  type: MessageType;
  delivered: boolean;
}

const TYPES = {
  [MessageType.RECEIVE]: () => css`
    border-radius: 15px 15px 15px 0;
    background-color: #f1f0f0;
    color: #4a4b4a;
    align-self: flex-start;
  `,
  [MessageType.SEND]: () => css`
    border-radius: 15px 15px 0 15px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #ffffff;
    align-self: flex-end;
  `,
};

export const Container = styled.div<IContainer>`
  padding: 14px;
  position: relative;
  ${({ type }) => TYPES[type]}

  ${({ delivered }) =>
    delivered &&
    css`
      ::after {
        content: 'Entregue';
        color: #858e99;
        font-size: 0.8rem;
        position: absolute;
        top: 47px;
        right: 0;
      }
    `}
`;
