import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/newInput';
import Loading from '~/components/loading';
import Select, { IOption } from '~/components/select';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { ICompany } from '~/models/company';
import { UserTypes } from '~/models/user';
import endpoints from '~/services/endpoints';
import { transformInGlobalDate } from '~/utils/date';
import {
  IDefaultReturn,
  cepMask,
  cpfMask,
  dateMask,
  phoneMask,
} from '~/utils/masks';
import {
  cpfValidator,
  dateValidator,
  defaultValidator,
  emailValidator,
  IValidators,
  nameValidator,
  phoneValidator,
  ValidatorsNames,
} from '~/utils/validators';

import * as St from './styles';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';
import { IUser } from '../Consult/Employee/Detail';

interface IForm<T> {
  cpf?: T;
  name: string;
  phone: T;
  dateBirth: T;
  email: string;
  login?: string;
  cep: T;
  profile?: string;
  address: string;
  number: string;
  complement: string;
  city: string;
  neighborhood: string;
  idCompany?: string;
  perfilCliente: boolean;
}

const STATUS_TYPE = [
  {
    id: 'ADM',
    value: UserTypes.ADMIN,
  },
  {
    id: 'OPR',
    value: UserTypes.OPERADOR,
  },
  {
    id: 'SUP',
    value: UserTypes.SUPERVISOR,
  },
  {
    id: 'MDC',
    value: UserTypes.MESA_CREDITO,
  },
  {
    id: 'RET',
    value: UserTypes.RETAGUARDA,
  },
  {
    id: 'CXA',
    value: UserTypes.CAIXA,
  },
  {
    id: 'ASSESSORIA',
    value: UserTypes.ASSESSORIA,
  },
];

const RegisterEmployee: React.FC = () => {
  const [form, setForm] = useState<IForm<IDefaultReturn>>(
    {} as IForm<IDefaultReturn>,
  );
  const [companys, setCompanys] = useState<IOption[]>([]);
  const [disabledFields, setDisabledFields] = useState({
    base: true,
    employee: true,
  });
  const isDisabledEmployeeFields = disabledFields.employee;
  const isDisabledBaseFields = disabledFields.base;
  const [errors, setErrors] = useState<IValidators>({} as IValidators);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(
    'Usuário cadastrado com sucesso! Link para criação da senha enviada para o e-mail cadastrado.',
  );
  const history = useHistory();
  const { state } = useLocation<{ cpf: IDefaultReturn }>();
  const GetUsers = useApi<[]>(
    endpoints.consult.getUsers,
    ApiMethod.GET,
    {},
    true,
  );
  const GetUserDetail = useApi<IUser>(
    endpoints.consult.getUserDetail.replace('PARAM_CPF', form.cpf?.unmasked),
    ApiMethod.GET,
  );

  const validateExistingCpf = async () => {
    const { data, status } = await GetUsers.callApi({
      cpf: form?.cpf?.unmasked,
    });
    // nao tem cadastro
    if (!data.length || status === 404) {
      setForm((prevForm) => ({
        ...prevForm,
        address: '',
        cep: cepMask(''),
        city: '',
        complement: '',
        dateBirth: dateMask(''),
        email: '',
        name: '',
        neighborhood: '',
        number: '',
        phone: phoneMask(''),
        perfilCliente: false,
      }));
      setDisabledFields({ base: false, employee: false });
      return;
    }
    // tem cadastro apenas de usuario
    if (data?.[0]?.perfis?.every((e) => e === 'USUARIO')) {
      setDisabledFields({ base: true, employee: false });
      const { data: user, status } = await GetUserDetail.callApi();
      if (status === 404) {
        setForm({
          address: '',
          cep: cepMask(''),
          city: '',
          complement: '',
          dateBirth: dateMask(''),
          email: '',
          name: '',
          neighborhood: '',
          number: '',
          phone: phoneMask(''),
          perfilCliente: false,
        });
      } else {
        setForm({
          ...form,
          address: user.endereco.logradouro,
          cep: cepMask(user.endereco.cep),
          city: user.endereco.cidade,
          complement: user.endereco.complemento,
          dateBirth: dateMask(
            user.dataNascimento?.split('-').reverse().join('/'),
          ),
          email: user.email,
          name: user.nome,
          neighborhood: user.endereco.bairro,
          number: user.endereco.numero,
          phone: phoneMask(user.celular),
          perfilCliente: true,
        });
      }

      return;
    }
    setDialogMessage('Usuário ja existente');
    setOpenDialog(true);
  };

  useEffect(() => {
    if (form.cpf?.unmasked?.length === 11) {
      validateExistingCpf();
    }
  }, [form.cpf]);

  const { data, callApi, status, loading } = useApi<any>(
    endpoints.register.operator,
    ApiMethod.POST,
    {
      celular: form.phone?.unmasked,
      cpf: form?.cpf?.unmasked,
      dataNascimento: transformInGlobalDate(form.dateBirth?.masked),
      perfil: form.profile,
      email: form.email,
      endereco: {
        bairro: form.neighborhood,
        cep: form.cep?.unmasked,
        cidade: form.city,
        complemento: form.complement,
        logradouro: form.address,
        numero: form.number,
        pais: 'BR',
        uf: 'AM',
      },
      genero: 'M',
      idEmpresa: form.idCompany,
      nome: form.name?.trim(),
    },
  );

  const GetAddressByCep = useApi(
    endpoints.consult.getAddressByCep.replace(
      'PARAM_CEP',
      form.cep?.masked?.replace('-', ''),
    ),
    ApiMethod.GET,
  );
  const handleGetCep = async () => {
    const response = await GetAddressByCep.callApi();
    if (response.status >= 200 && response.status <= 300) {
      setForm((prev) => ({
        ...prev,
        address: response.data?.logradouro ?? '',
        neighborhood: response.data?.bairro ?? '',
        city: response.data?.localidade ?? '',
      }));
    }
  };

  useEffect(() => {
    if (form?.cep?.unmasked?.length === 8) {
      handleGetCep();
    }
  }, [form.cep]);
  const RequestListCompany = useApi<ICompany[]>(
    `${endpoints.consult.searchCompany}`,
    ApiMethod.GET,
  );

  useEffect(() => {
    (async () => {
      if (state?.cpf) {
        setForm((prev) => ({ ...prev, cpf: state.cpf }));
      }

      const response = await RequestListCompany.callApi();
      if (response.status === 200) {
        const companys = response.data.map(({ nome, id }) => ({
          id,
          value: nome,
        }));
        setCompanys(companys);
      }
    })();
  }, []);

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (value, name) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrors = () => {
    const errors = [
      emailValidator(form.email),
      dateValidator(form.dateBirth?.masked, ValidatorsNames.dateBirth),
      phoneValidator(form.phone?.masked),
      nameValidator(form.name),
      defaultValidator(form.cep?.masked, ValidatorsNames.cep, 9),
      defaultValidator(form.neighborhood, ValidatorsNames.neighborhood),
      defaultValidator(form.number, ValidatorsNames.number),
      // defaultValidator(form.complement, ValidatorsNames.complement),
      defaultValidator(form.city, ValidatorsNames.city),
      defaultValidator(form.address, ValidatorsNames.address),
      defaultValidator(form.profile, ValidatorsNames.profile),
      defaultValidator(form.idCompany, ValidatorsNames.idCompany),
    ].reduce((memo, el) => ({ ...memo, ...el }), {});

    setErrors(errors);

    const hasErrors = Object.values(errors).filter((a) => a !== null).length;
    if (!hasErrors) handleSubmit();
  };

  const handleSubmit = async () => {
    const response = await callApi();
    setDialogMessage(
      response?.mensagem ||
        'Usuário cadastrado com sucesso! Link para criação da senha enviada para o e-mail cadastrado.',
    );
    handleToggleDialog();
  };

  const goToConsult = () => history.push('/consult/employee');

  return (
    <>
      <Loading status={loading || GetUsers.loading} />

      <Container title="Cadastro">
        <Dialog
          open={openDialog}
          message={dialogMessage}
          positiveAction={goToConsult}
          positiveLabel="Entendi"
          icon={icons.check}
        />

        <St.BoxCard>
          <ContainerFlex>
            <Grid xs={1} lg={2} gap={20}>
              <ContainerFlex>
                <Input
                  label="CPF"
                  name="cpf"
                  mask={MaskTypes.CPF}
                  placeholder="000.000.000-00"
                  onChange={handleChange}
                  value={form.cpf?.masked}
                  errorMessage={errors.cpf}
                  maxLength={14}
                />
                <Input
                  label="Nome completo"
                  name="name"
                  onChange={handleChange}
                  value={form.name}
                  errorMessage={errors.name}
                  disabled={isDisabledBaseFields}
                />
                <Input
                  label="Telefone"
                  name="phone"
                  mask={MaskTypes.PHONE}
                  placeholder="(00) 00000-0000"
                  onChange={handleChange}
                  value={form.phone?.masked}
                  maxLength={15}
                  errorMessage={errors.phone}
                  disabled={isDisabledBaseFields}
                />
                <Input
                  label="Data de nascimento"
                  name="dateBirth"
                  mask={MaskTypes.DATE}
                  placeholder="dd/mm/aaaa"
                  onChange={handleChange}
                  value={form.dateBirth?.masked}
                  maxLength={10}
                  errorMessage={errors.dateBirth}
                  disabled={isDisabledBaseFields}
                />
                <Input
                  label="E-mail"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  errorMessage={errors.email}
                  value={form.email}
                  disabled={isDisabledBaseFields}
                />

                <Select
                  label="Perfil"
                  value={form.profile}
                  options={STATUS_TYPE}
                  onChange={(val) => handleSelect(val, 'profile')}
                  errorMessage={errors.profile}
                  disabled={isDisabledEmployeeFields}
                />
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="CEP"
                  name="cep"
                  mask={MaskTypes.CEP}
                  placeholder="00000-000"
                  onChange={handleChange}
                  value={form.cep?.masked}
                  maxLength={9}
                  errorMessage={errors.cep}
                  disabled={isDisabledBaseFields}
                />
                <Input
                  label="Endereço"
                  name="address"
                  onChange={handleChange}
                  value={form.address}
                  errorMessage={errors.address}
                  disabled={isDisabledBaseFields}
                />

                <div>
                  <Grid xs={1} sm={2} gap={10}>
                    <ContainerFlex>
                      <Input
                        label="Número"
                        name="number"
                        onChange={handleChange}
                        value={form.number}
                        type="number"
                        errorMessage={errors.number}
                        disabled={isDisabledBaseFields}
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Complemento"
                        name="complement"
                        value={form.complement}
                        onChange={handleChange}
                        errorMessage={errors.complement}
                        disabled={isDisabledBaseFields}
                      />
                    </ContainerFlex>
                  </Grid>

                  <Grid xs={1} sm={2} gap={10}>
                    <ContainerFlex>
                      <Input
                        label="Cidade"
                        name="city"
                        value={form.city}
                        onChange={handleChange}
                        errorMessage={errors.city}
                        disabled={isDisabledBaseFields}
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Bairro"
                        name="neighborhood"
                        value={form.neighborhood}
                        onChange={handleChange}
                        errorMessage={errors.neighborhood}
                        disabled={isDisabledBaseFields}
                      />
                    </ContainerFlex>
                  </Grid>
                </div>
                <Input
                  label="Perfil de Cliente"
                  name="perfilDeCliente"
                  onChange={handleChange}
                  value={form?.perfilCliente ? 'SIM' : 'NÃO'}
                  errorMessage={errors.idCompany}
                  disabled={isDisabledBaseFields}
                />
                <AutoCompleteSelect
                  label="Empresa"
                  value={form.idCompany}
                  onChange={(val) => handleSelect(val.id, 'idCompany')}
                  options={companys}
                  errorMessage={errors.idCompany}
                  disabled={isDisabledEmployeeFields}
                />
              </ContainerFlex>
            </Grid>
            <ContainerFlex marginTop={16} row end gap="8">
              <Button outlined onClick={() => history.goBack()}>
                Voltar
              </Button>
              <Button
                disabled={loading}
                loading={loading}
                onClick={handleErrors}
              >
                Cadastrar
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default RegisterEmployee;
