import React from 'react';
import CardView from '../../cartView';
import ListCol, { IListCol } from './col';

import * as St from './styles';

export interface IListRow {
  title?: string;
  item: IListCol[];
  reportView?: boolean;
}

const ListRow: React.FC<IListRow> = ({ title, item, reportView = false }) => {
  return (
    <St.Container>
      {title && <St.ListTitle>{title}</St.ListTitle>}
      <CardView space>
        {item.map((i) => (
          <ListCol
            key={i.id + i.title}
            id={i.id}
            icon={i.icon}
            item={i.item}
            maxChar={i.maxChar}
            title={i.title}
            value={i.value}
            border={i.border}
            searchAction={i.searchAction}
            valueColor={i.valueColor}
            scratched={i.scratched}
            fontsize={i.fontsize}
            reportView={reportView}
          />
        ))}
      </CardView>
    </St.Container>
  );
};

export default ListRow;
