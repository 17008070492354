/* eslint-disable react/jsx-curly-newline */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import { setFormState } from '~/utils/form';
import { FINANCIAL_TYPES } from '../../models';

import * as S from '../../styles';

interface IFilterFinancial {
  onClick?: (any: any) => void;
}

const FilterFinancial: React.FC<IFilterFinancial> = ({ onClick }) => {
  const [form, setForm] = useState({
    tipoRelatorio: { name: 'tipoRelatorio', value: 'RELATORIO_IOF' },
    dataInicial: moment().format('DD/MM/YYYY'),
    dataFinal: moment().format('DD/MM/YYYY'),
    tipoDeData: 'DATA_INCLUSAO',
    tipoDeEncargo: '',
  });
  const [flag, setFlag] = useState(false);

  const handleChange = (el): void => {
    onClick(el);
  };

  useEffect(() => {
    if (form?.tipoRelatorio) {
      setFlag(false);
    }
  }, [form.tipoRelatorio]);

  useEffect(() => {
    if (form.tipoRelatorio?.value === 'CASHBACK_ZEUSS' && flag === false) {
      setForm({ ...form, tipoDeData: 'DATA_VENCIMENTO_FATURA' });
    }
  }, [form.tipoRelatorio?.value]);

  return (
    <S.BoxCard>
      <ContainerFlex row space marginLeft={16} marginRight={16} marginTop={10}>
        <ContainerFlex marginRight={15}>
          <Select
            options={FINANCIAL_TYPES}
            label="Tipo Financeiro"
            name={form.tipoRelatorio.name}
            value={form.tipoRelatorio.value}
            onChange={(e) => {
              setFormState(
                { target: { name: form.tipoRelatorio.name, value: e } },
                form,
                setForm,
              );
            }}
          />
        </ContainerFlex>

        {['RECEITAS_FINANCEIRAS_RECEBIDAS'].includes(
          form.tipoRelatorio.value,
        ) && (
          <ContainerFlex marginRight={16}>
            <Select
              label="Tipo de Encargo"
              value={form.tipoDeEncargo}
              options={[
                { id: '', value: 'Todos' },
                { id: '1', value: 'Anuidade' },
                { id: '2', value: 'Juros' },
                { id: '3', value: 'Multa' },
                { id: '4', value: 'Rotativo' },
                { id: '5', value: 'Juros de Financiamento de Faturas' },
                { id: '6', value: 'Tarifa de Cobrança' },
                { id: '7', value: 'Seguros' },
                { id: '8', value: 'Odonto' },
                { id: '9', value: 'Cuida' },
                { id: '10', value: 'TAC de saque a vista' },
                { id: '11', value: 'Juros de saque a vista' },
                { id: '12', value: 'Receita de acordo' },
                { id: '13', value: 'Taxa de serviço pgto pelo TOTEM' },
                { id: '14', value: 'Juros pgto pelo TOTEM' },
                { id: '15', value: 'Recuperação de Perda' },
                { id: '16', value: 'Reemissão de Cartão' },
                { id: '17', value: 'Juros parcelamento de compras' },
                { id: '18', value: 'Encargos de Inad. Saque a vista' },
              ]}
              onChange={(e) => setForm({ ...form, tipoDeEncargo: e })}
            />
          </ContainerFlex>
        )}

        {['RECEITAS_ACORDO'].includes(form.tipoRelatorio.value) && (
          <ContainerFlex marginRight={16}>
            <Select
              label="Tipo de Data"
              value={form.tipoDeData}
              options={[
                { id: 'DATA_INCLUSAO', value: 'Data Inclusão' },
                { id: 'DATA_DECISAO', value: 'Data Decisão' },
              ]}
              onChange={(e) => setForm({ ...form, tipoDeData: e })}
            />
          </ContainerFlex>
        )}

        {['CASHBACK_ZEUSS'].includes(form.tipoRelatorio.value) && (
          <ContainerFlex marginRight={16}>
            <Select
              label="Tipo de Data"
              value={form.tipoDeData}
              options={[
                {
                  id: 'DATA_VENCIMENTO_FATURA',
                  value: 'Data Vencimento Fatura',
                },
                { id: 'DATA_PAGAMENTO_FATURA', value: 'Data Pagamento Fatura' },
              ]}
              onChange={(e) => setForm({ ...form, tipoDeData: e })}
            />
          </ContainerFlex>
        )}

        <ContainerFlex flex="0.2" maxWidth={15}>
          <Button
            onClick={() => {
              handleChange(form);
            }}
          >
            <span>Consultar</span>
          </Button>
        </ContainerFlex>
      </ContainerFlex>
      <ContainerFlex marginLeft={16} marginRight={16}>
        <S.SpanLabel>Período</S.SpanLabel>

        <ContainerFlex row space maxWidth={50}>
          <ContainerFlex marginRight={15}>
            <Input
              label="De"
              placeholder="DD/MM/YYYY"
              maxLength={10}
              value={form.dataInicial}
              onChange={(e) => {
                return setForm({
                  ...form,
                  dataInicial: e.target.value.masked,
                });
              }}
              mask={MaskTypes.DATE}
            />
          </ContainerFlex>

          <ContainerFlex marginRight={15}>
            <Input
              label="Até"
              placeholder="DD/MM/YYYY"
              maxLength={10}
              value={form.dataFinal}
              onChange={(e) => {
                return setForm({
                  ...form,
                  dataFinal: e.target.value.masked,
                });
              }}
              mask={MaskTypes.DATE}
            />
          </ContainerFlex>
        </ContainerFlex>
      </ContainerFlex>
    </S.BoxCard>
  );
};

export default FilterFinancial;
