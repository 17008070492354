import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

import * as S from '../../styles';

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
  subTitle?: string;
}

const BoxStore: React.FC<IProps> = ({
  categories,
  series,
  widthGraphic = 760,
  heightGraphic = 300,
  subTitle = '',
}) => {
  const buildCategories = () => {
    let response = null;
    if (categories === undefined || categories.length <= 0) {
      response = {
        chart: {
          width: '100%',
          height: 300,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            distributed: true,
            borderRadius: 4,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: categories || [0],
          labels: {
            show: true,
            rotate: -90,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: 100,
            maxHeight: 120,
            style: {
              colors: ['#304758'],
              fontSize: '12px',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: false,
            formatter(val) {
              return val;
            },
          },
        },
        theme: {
          monochrome: {
            enabled: true,
            shadeTo: 'light',
            color: '#2d1ba7',
            shadeIntensity: 0.6,
          },
        },
      } as any;
    } else {
      response = {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            distributed: true,
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories,
          labels: {
            show: true,
            rotate: -90,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: 100,
            maxHeight: 120,
            style: {
              colors: ['#304758'],
              fontSize: '12px',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: false,
            formatter(val) {
              return val;
            },
          },
        },
        theme: {
          monochrome: {
            enabled: true,
            shadeTo: 'light',
            color: '#2d1ba7',
            shadeIntensity: 0.6,
          },
        },
      } as any;
    }
    return response;
  };

  const buildSeries = () => {
    let response = null;
    if (series === undefined || series.length <= 0) {
      response = [
        {
          name: 'Operators',
          data: [0],
        },
      ];
    } else {
      response = series;
    }
    return response;
  };

  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>Lojas</h2>
          <span>
            {subTitle !== ''
              ? subTitle
              : `Última atualização: hoje às ${moment().format(
                  'hh:mm',
                )}pm. Referente a data de ontem`}
          </span>
        </header>
        <S.ItemGraphic>
          <Chart
            options={buildCategories()}
            series={buildSeries()}
            type="bar"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxStore;
