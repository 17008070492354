/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { GetOperatorsResponse } from '~/services/report/requestReport/response';
import { CSV_HEADERS } from '../../constants';
import Models from '../../models';
import {
  LOAN_TYPES,
  ILoanReport,
  ReportResponseLeadsSales,
  ReportResponseWithDraw,
  ReportResponseLoanSeeker,
  ReportResponseWithdrawBilling,
  ReportDefaultWithdrawReceiptsResponse,
} from '../models';

export const LoanReportContext = createContext<ILoanReport>({} as ILoanReport);

const LoanReportProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [relatorioData, setRelatorioData] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [operador, setOperador] = useState([]);

  const [form, setForm] = useState({
    tipoEmprestimo: { name: 'tipoEmprestimo', value: 'VENDAS_SAQUE' },
    dataSimulacaoInicial: moment().format('DD/MM/YYYY'),
    dataSimulacaoFinal: moment().format('DD/MM/YYYY'),
    tipoPeriodo: { name: 'tipoPeriodo', value: 'DESEMBOLSO' },
    status: { name: 'status', value: 'TODOS' },
    idOperador: 'TODOS',
    mes: { name: 'mes', value: '01' },
    ano: { name: 'ano', value: '2024' },
  });

  const RequestGetOperators = useApi(
    Endpoints.report.getOperators,
    ApiMethod.GET,
  );

  const RequestGetReportLeadsSales = useApi<ReportResponseLeadsSales>(
    Endpoints.loan.getLoan,
    ApiMethod.POST,
  );

  const RequestGetReportWithDraw = useApi<ReportResponseWithDraw>(
    Endpoints.loan.getWithDraw,
    ApiMethod.POST,
  );

  const RequestGetReportLoanSeeker = useApi<ReportResponseLoanSeeker>(
    Endpoints.report.getReportLoanSeeker,
    ApiMethod.POST,
  );

  const RequestGetReportWithdrawBilling = useApi<ReportResponseWithdrawBilling>(
    Endpoints.report.getReportWithdrawBilling,
    ApiMethod.POST,
  );

  const RequestGetDefaultWithdrawReceipts = useApi<ReportDefaultWithdrawReceiptsResponse>(
    Endpoints.report.getReportIncomeDefaultWithdraw
      .replace('PARAM_ANO', form?.ano?.value)
      .replace('PARAM_MES', form?.mes?.value),
    ApiMethod.GET,
  );

  const clearForm = () => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
  };

  useEffect(() => {
    if (
      RequestGetOperators.loading ||
      RequestGetReportLeadsSales.loading ||
      RequestGetReportWithDraw.loading ||
      RequestGetReportLoanSeeker.loading ||
      RequestGetReportWithdrawBilling.loading ||
      RequestGetDefaultWithdrawReceipts.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    RequestGetOperators.loading,
    RequestGetReportLeadsSales.loading,
    RequestGetReportWithDraw.loading,
    RequestGetReportLoanSeeker.loading,
    RequestGetReportWithdrawBilling.loading,
    RequestGetDefaultWithdrawReceipts.loading,
  ]);

  const checkSuccess = () => {
    if (
      RequestGetReportLeadsSales.error ||
      RequestGetReportLeadsSales.status === 404 ||
      RequestGetReportLeadsSales.status === 500 ||
      RequestGetReportWithDraw.error ||
      RequestGetReportWithDraw.status === 404 ||
      RequestGetReportWithDraw.status === 500 ||
      RequestGetReportLoanSeeker.error ||
      RequestGetReportLoanSeeker.status === 404 ||
      RequestGetReportLoanSeeker.status === 500 ||
      RequestGetReportWithdrawBilling.error ||
      RequestGetReportWithdrawBilling.status === 404 ||
      RequestGetReportWithdrawBilling.status === 500 ||
      RequestGetDefaultWithdrawReceipts.error ||
      RequestGetDefaultWithdrawReceipts.status === 404 ||
      RequestGetDefaultWithdrawReceipts.status === 500
    ) {
      return true;
    }
    return false;
  };

  const getReportLeadsSales = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestGetReportLeadsSales.callApi(newPayload);

    setHeader(CSV_HEADERS.REPORT_LEADS_SALES);

    if (returnResponse) return response.data;

    const {
      pagina,
      totalItens,
      totalDePaginas,
    } = response.data as ReportResponseLeadsSales;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportVendasLeads);
      setItems(Models.BodyReportVendasLeads(pagina));
      setTotalItems(totalItens);
      setRelatorioData(Models.BodyReportVendasLeadsCSV(pagina));
      setTotalPages(totalDePaginas);
    }
  };

  const getReportWithDraw = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
    isDoc,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestGetReportWithDraw.callApi(newPayload);

    if (isDoc) {
      setHeader(CSV_HEADERS.REPORT_WITHDRAW_LOAN);
    }

    if (returnResponse) return response.data;

    const {
      pagina,
      totalItens,
      totalDePaginas,
    } = response.data as ReportResponseWithDraw;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportWithDraw);
      setItems(Models.BodyReportWithDraw(pagina));
      setTotalItems(totalItens);
      if (isDoc) setRelatorioData(Models.BodyReportWithDrawCSV(pagina));
      setTotalPages(totalDePaginas);
    }
  };

  const getReportLoanSeeker = async (returnResponse = false, payload) => {
    const response = await RequestGetReportLoanSeeker.callApi(payload);

    setHeader(CSV_HEADERS.REPORT_LOAN_SEEKER);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportLoanSeeker);
      setItems(Models.BodyReportLoanSeeker(data));
      setRelatorioData(Models.BodyReportLoanSeekerCSV(data));
    }
  };

  const getReportWithdrawBilling = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestGetReportWithdrawBilling.callApi(newPayload);

    setHeader(CSV_HEADERS.REPORT_WITHDRAW_BILLING);

    if (returnResponse) return response.data;

    const {
      pagina,
      totalDePaginas,
      totalItens,
    } = response.data as ReportResponseWithdrawBilling;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportWithdrawBilling);
      setItems(Models.BodyReportWithdrawBilling(pagina));
      setRelatorioData(Models.BodyReportWithdrawBillingCSV(pagina));
      setTotalItems(totalItens);
      setTotalPages(totalDePaginas);
    }
  };

  const getReportIncomeDefaultWithdraw = async () => {
    try {
      const response = await RequestGetDefaultWithdrawReceipts.callApi();

      const binary = atob(response.data.base64);
      const buffer = new ArrayBuffer(binary.length);
      const view = new Uint8Array(buffer);

      for (let i = 0; i < binary.length; i += 1) {
        view[i] = binary.charCodeAt(i);
      }

      const file = new Blob(['\ufeff', view], { type: 'text/csv' });
      const fileUrl = URL.createObjectURL(file);
      const link = document.createElement('a');
      const { filename } = response.data;
      link.href = fileUrl;
      link.setAttribute('download', filename);
      link.click();
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const getOperators = useCallback(async () => {
    const { data, status, mensagem } = await RequestGetOperators.callApi();

    return { data, status, mensagem };
  }, [RequestGetOperators]);

  const getOperador = async () => {
    const response = await getOperators();

    if (response.status === 200) {
      const data = response.data as GetOperatorsResponse[];

      const getOperadores = data.map(({ nome, id }) => ({
        id,
        value: nome,
      }));

      setOperador(getOperadores);
    }
  };

  return (
    <LoanReportContext.Provider
      value={{
        getReportWithDraw,
        getReportLeadsSales,
        getReportLoanSeeker,
        getReportWithdrawBilling,
        getReportIncomeDefaultWithdraw,
        checkSuccess,
        getOperador,
        loading,
        items,
        headerItems,
        header,
        totalItems,
        totalPages,
        relatorioData,
        setRelatorioData,
        operador,
        clearForm,
        LOAN_TYPES,
        setForm,
        form,
      }}
    >
      {children}
    </LoanReportContext.Provider>
  );
};
export default LoanReportProvider;
