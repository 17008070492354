export const TOKEN_KEY = '@NovaEra:tokenUser';
export const PERFIL = '@NovaEra:perfil';

export const isAuthenticated = (): boolean =>
  localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = (): string => localStorage.getItem(TOKEN_KEY);

export const login = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const perfil = (perfis): void => {
  localStorage.setItem(PERFIL, perfis);
};
export const logout = (): void => {
  localStorage.clear();
  window.location.href = '/';
};
