import { useContext, useEffect, useState } from 'react';
import Collapse from '~/components/collapse';
import { enumsBackEndCancelamento, headerItems, items } from '../../Mock';
import { TextArea } from '../TextArea';
import SharedServiceContext from '../../../Services/sharedServiceContext';
import TableListCheckbox from '~/components/tableListCheckbox';

export const SolicitationAccount = () => {
  const [listCancelType, setListCancelType] = useState<any>([]);

  const { setOpinion, setListArrayCancelType } = useContext(
    SharedServiceContext,
  );

  useEffect(() => {
    const list = [];
    if (listCancelType.length >= 1) {
      listCancelType.forEach((e) => {
        list.push(enumsBackEndCancelamento[e]);
      });
    }
    setListArrayCancelType(list);
  }, [listCancelType]);

  return (
    <>
      <Collapse title="2. Escolha abaixo o tipo de cancelamento " typeEvent>
        <TableListCheckbox
          customerHeader={headerItems}
          list={items}
          checkbox
          onListCheckBox={(list) => {
            setListCancelType(list);
          }}
        />
        <TextArea onOpinion={(e: string) => setOpinion(e)} />
      </Collapse>
    </>
  );
};
export const SolicitationAccountDetails = ({ data }) => {
  const [listCancelType, setListCancelType] = useState<any>([]);
  const { setOpinion } = useContext(SharedServiceContext);

  useEffect(() => {
    const list = [];
    if (data?.length >= 1) {
      data.forEach((e, index) => {
        list.push(items.find((i) => i[1].keyEnum === e)?.[0]?.value);
      });
    }
    setListCancelType(list);
  }, [data]);

  return (
    <>
      <Collapse title="2. Tipos de cancelamento escolhidos " typeEvent>
        <TableListCheckbox
          customerHeader={headerItems}
          list={items}
          checkbox
          defaultChecked={listCancelType}
          disableCheckbox
        />
        <TextArea onOpinion={(e: string) => setOpinion(e)} />
      </Collapse>
    </>
  );
};
