/* eslint-disable @typescript-eslint/no-explicit-any */
export const REPORT_TYPES = [
  {
    id: 'ADESOES',
    name: 'Adesões',
    value: 'Adesões',
  },
  {
    id: 'LEADS_WALLET_ATIVADA',
    name: 'Leads Wallet Ativada',
    value: 'Leads Wallet Ativada',
  },
  {
    id: 'TRANSACAO',
    name: 'Vendas Transações',
    value: 'Vendas Transações',
  },
];

export interface IAccessionSaleReport {
  postReportAccession: Function;
  postReportSale: Function;
  postReportTransactions: Function;
  checkSuccess: Function;
  getOperador: Function;
  getEstablishment: Function;
  loading: boolean;
  items: any;
  headerItems: any;
  header: any;
  totalPages: number;
  totalItems: number | null;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  operador: any[];
  establishment: any[];
  clearForm: () => React.SetStateAction<any>;
  REPORT_TYPES: Array<{ id: string; name: string; value: string }>;
  request: boolean;
}
export interface ReportResponseAdesoes {
  totalItens: number;
  totalPaginas: number;
  paginaAtual: number;
  data: {
    nome?: string;
    nomeOperador?: string;
    cpf?: string;
    email?: string;
    celular?: string;
    dataAdesao?: string;
    cartaoTeuCard?: boolean;
    uf?: string;
  }[];
}

export interface ReportResponseVendas {
  totalItens: number;
  totalPaginas: number;
  paginaAtual: number;
  data: {
    celular: string;
    cpf: string;
    dataEnvio: string;
    dataLeitura: string;
    email: string;
    nome: string;
    operador: {
      idOperador: string;
      nome: string;
    };
    parcelasTransacao: number;
    produto: string;
    status: string;
    tipoAcao: string;
    uf: string;
    valorCupomTransacao: number;
    valorTransacao: number;
  }[];
}

export interface ReportResponseTransactions {
  totalItens: number;
  totalPaginas: number;
  paginaAtual: number;
  data: {
    bandeira: string;
    dataHora: string;
    estabelecimento: number;
    idAutorizador: string;
    idTransacao: string;
    nome: string;
    nomeEstabelecimento: string;
    nsuAutorizador: string;
    nsuSitef: string;
    operacao: string;
    parcela: number;
    tipo: string;
    uf: string;
    valorTransacao: number;
  }[];
}
