import styled from 'styled-components';

export const BoxFileContainer = styled.div`
  max-width: 50%;
  padding: 16px;
  padding-right: 32px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  margin-bottom: 22px;
`;

export const BoxFileLabel = styled.span`
  color: #7a7a7a;
  font-weight: 300;
`;

export const BoxFileIcon = styled.img`
  height: 26px;
  cursor: pointer;
`;

export const BoxFileContent = styled.img`
  width: 100%;
`;

export const BoxFileDelete = styled.img`
  height: 26px;
  width: 26px;
  right: 44.5%;
  bottom: 75px;
  position: absolute;
`;
