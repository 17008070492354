import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import ProjectionMeter from '~/components/ProjectionMeter';
import BoxHistoric from '../components/boxHistoric';
import BoxProjection from '../components/BoxProjection';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { returnMonth } from '~/utils/date';
import Loading from '~/components/loading';

interface IProps {
  title: string;
  dataAprovacao: string;
  type: string;
}

interface IBoxGraphic {
  dataHoraColeta: string;
  estados: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  geral: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  origens: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
}

interface IProjection {
  diasRestantes: number;
  diasTotais: number;
  diasTrabalhados: number;
  estados: {
    name: string;
    totalAtual: number;
    totalEsperado: number;
  };
  geral: {
    totalAtual: number;
    totalEsperado: number;
  };
  origens: {
    name: string;
    totalAtual: number;
    totalEsperado: number;
  };
}

interface IProjectionItem {
  estados?: {
    map: any;
    name: string;
    totalAtual: number;
    totalEsperado: number;
  };
  geral?: {
    totalAtual: number;
    totalEsperado: number;
  };
  origens?: {
    map: any;
    name: string;
    totalAtual: number;
    totalEsperado: number;
  };
}

const DashProjection: React.FC = () => {
  const [dataResponse, setDataResponse] = useState({
    dataHoraColeta: '',
    estados: {
      categories: [],
      series: [
        {
          name: '',
          data: [],
        },
      ],
    },
    geral: {
      categories: [],
      series: [
        {
          name: '',
          data: [],
        },
      ],
    },
    origens: {
      categories: [],
      series: [
        {
          name: '',
          data: [],
        },
      ],
    },
  });

  const [dataProjection, setDataProjection] = useState<IProjection>({
    diasRestantes: 0,
    diasTotais: 0,
    diasTrabalhados: 0,
    estados: {
      name: '',
      totalAtual: 0,
      totalEsperado: 0,
    },
    geral: {
      totalAtual: 0,
      totalEsperado: 0,
    },
    origens: {
      name: '',
      totalAtual: 0,
      totalEsperado: 0,
    },
  });

  const [stateProjectionData, setStateProjectionData] = useState([]);
  const [originProjectionData, setOriginProjectionData] = useState([]);

  const { state } = useLocation<IProps>();

  const RequestProposalHistoricApproved = useApi(
    Endpoints.graphc.getProposalHistoric.replace(
      'PARAM_DATA_APROVACAO',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestProposalProjectionApproved = useApi(
    Endpoints.graphc.getProposalProjection.replace(
      'PARAM_DATA_APROVACAO',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestPurchaseBillingHistoric = useApi(
    Endpoints.graphc.getPurchaseBillingHistoric.replace(
      'PARAM_DATA_COMPRA',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestPurchaseBillingProjection = useApi(
    Endpoints.graphc.getPurchaseBillingProjection.replace(
      'PARAM_DATA_COMPRA',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestInvoicePaymentHistoric = useApi(
    Endpoints.graphc.getInvoicePaymentHistoric.replace(
      'PARAM_DATA_PAGAMENTO',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestInvoicePaymentProjection = useApi(
    Endpoints.graphc.getInvoicePaymentProjection.replace(
      'PARAM_DATA_PAGAMENTO',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestSalesOdontoHistoric = useApi(
    Endpoints.graphc.getSalesOdontoHistoric.replace(
      'PARAM_DATA_VENDA',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestSalesOdontoProjection = useApi(
    Endpoints.graphc.getSalesOdontoProjection.replace(
      'PARAM_DATA_VENDA',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestClientsActiveHistoric = useApi(
    Endpoints.graphc.getClientsActiveHistoric.replace(
      'PARAM_DATA_APROVACAO',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const RequestClientsActiveProjection = useApi(
    Endpoints.graphc.getClientsActiveProjection.replace(
      'PARAM_DATA_APROVACAO',
      state?.dataAprovacao,
    ),
    ApiMethod.GET,
  );

  const searchProposalApprovedHistoric = async () => {
    try {
      const response = await RequestProposalHistoricApproved.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchProposalProjectionApproved = async () => {
    try {
      const response = await RequestProposalProjectionApproved.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataProjection(response.data as IProjection);

        const { estados, origens }: IProjectionItem = response.data;

        const stateProjection = estados.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        const originProjection = origens.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        setStateProjectionData(stateProjection);
        setOriginProjectionData(originProjection);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchPurchaseBillingHistoric = async () => {
    try {
      const response = await RequestPurchaseBillingHistoric.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchPurchaseBillingProjection = async () => {
    try {
      const response = await RequestPurchaseBillingProjection.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataProjection(response.data as IProjection);

        const { estados, origens }: IProjectionItem = response.data;

        const stateProjection = estados.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        const originProjection = origens.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        setStateProjectionData(stateProjection);
        setOriginProjectionData(originProjection);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchInvoicePaymentHistoric = async () => {
    try {
      const response = await RequestInvoicePaymentHistoric.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchInvoicePaymentProjection = async () => {
    try {
      const response = await RequestInvoicePaymentProjection.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataProjection(response.data as IProjection);

        const { estados, origens }: IProjectionItem = response.data;

        const stateProjection = estados.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        const originProjection = origens.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        setStateProjectionData(stateProjection);
        setOriginProjectionData(originProjection);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchSalesOdontoHistoric = async () => {
    try {
      const response = await RequestSalesOdontoHistoric.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchSalesOdontoProjection = async () => {
    try {
      const response = await RequestSalesOdontoProjection.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataProjection(response.data as IProjection);

        const { estados, origens }: IProjectionItem = response.data;

        const stateProjection = estados.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        const originProjection = origens.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        setStateProjectionData(stateProjection);
        setOriginProjectionData(originProjection);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchClientsActiveHistoric = async () => {
    try {
      const response = await RequestClientsActiveHistoric.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchClientsActiveProjection = async () => {
    try {
      const response = await RequestClientsActiveProjection.callApi();
      if (response.status === 200 && response.status < 300) {
        setDataProjection(response.data as IProjection);

        const { estados, origens }: IProjectionItem = response.data;

        const stateProjection = estados.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        const originProjection = origens.map((item) => ({
          x: item.name,
          y: item.totalAtual,
          goals: [
            {
              name: 'Esperado',
              value: item.totalEsperado,
              strokeWidth: 5,
              strokeHeight: 10,
              strokeColor: '#775DD0',
            },
          ],
        }));

        setStateProjectionData(stateProjection);
        setOriginProjectionData(originProjection);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (state?.type) {
      case 'APROVADA':
        searchProposalApprovedHistoric();
        searchProposalProjectionApproved();
        break;
      case 'FATURAMENTO_COMPRAS':
        searchPurchaseBillingHistoric();
        searchPurchaseBillingProjection();
        break;
      case 'PAGAMENTO_FATURAS':
        searchInvoicePaymentHistoric();
        searchInvoicePaymentProjection();
        break;
      case 'ODONTO':
        searchSalesOdontoHistoric();
        searchSalesOdontoProjection();
        break;
      case 'ATIVOS':
        searchClientsActiveHistoric();
        searchClientsActiveProjection();
        break;
      default:
        searchProposalApprovedHistoric();
        searchProposalProjectionApproved();
    }
  }, []);

  return (
    <Container>
      <Loading
        status={
          RequestProposalHistoricApproved.loading ||
          RequestProposalProjectionApproved.loading ||
          RequestPurchaseBillingHistoric.loading ||
          RequestPurchaseBillingProjection.loading ||
          RequestInvoicePaymentHistoric.loading ||
          RequestInvoicePaymentProjection.loading ||
          RequestSalesOdontoHistoric.loading ||
          RequestSalesOdontoProjection.loading ||
          RequestClientsActiveHistoric.loading ||
          RequestClientsActiveProjection.loading
        }
      />

      <ContainerFlex row marginTop={10}>
        <ContainerFlex padding={12}>
          <BoxHistoric
            type={state.type}
            title={state?.title}
            categories={dataResponse.geral?.categories}
            series={dataResponse.geral?.series}
            heightGraphic={254}
            widthGraphic={550}
            line
          />
        </ContainerFlex>

        <ContainerFlex padding={12}>
          <ProjectionMeter
            type={state.type}
            title={`Projeção ${returnMonth(moment().month())}`}
            projection={dataProjection.geral?.totalEsperado}
            available={dataProjection.geral?.totalAtual}
            totalDays={dataProjection.diasTotais}
            workDays={dataProjection.diasTrabalhados}
            daysRemaining={dataProjection?.diasRestantes}
          />
        </ContainerFlex>
      </ContainerFlex>

      <ContainerFlex row marginTop={10}>
        <ContainerFlex padding={12}>
          <BoxHistoric
            type={state.type}
            title={state?.title}
            categories={dataResponse.estados?.categories}
            series={dataResponse.estados?.series}
            heightGraphic={254}
            widthGraphic={550}
            graphicType="Estado"
          />
        </ContainerFlex>

        <ContainerFlex padding={12}>
          <BoxProjection
            type={state.type}
            graphicType="Estado"
            month={returnMonth(moment().month())}
            data={stateProjectionData}
            heightGraphic={200}
            widthGraphic={550}
          />
        </ContainerFlex>
      </ContainerFlex>

      <ContainerFlex row marginTop={10}>
        <ContainerFlex padding={12}>
          <BoxHistoric
            type={state.type}
            title={state?.title}
            categories={dataResponse.origens?.categories}
            series={dataResponse.origens?.series}
            heightGraphic={254}
            widthGraphic={550}
            graphicType="Origem"
          />
        </ContainerFlex>

        <ContainerFlex padding={12}>
          <BoxProjection
            type={state.type}
            graphicType="Origem"
            month={returnMonth(moment().month())}
            data={originProjectionData}
            heightGraphic={200}
            widthGraphic={550}
          />
        </ContainerFlex>
      </ContainerFlex>
    </Container>
  );
};

export default DashProjection;
