/* eslint-disable prefer-template */
import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask, phoneMask } from '~/utils/masks';

export const HeaderReportWithDraw = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 17,
    textAlign: 'center',
  },

  {
    id: 3,
    value: 'Celular',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Operador(a) de Venda',
    maxChar: 15,
  },
  {
    id: 5,
    value: 'Valor do saque',
    maxChar: 15,
  },
  {
    id: 6,
    value: 'Valor da parcela',
    maxChar: 15,
  },
  {
    id: 7,
    value: 'Status',
    maxChar: 20,
    textAlign: 'center',
  },
];

export const BodyReportWithDraw = (data: any) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        id: 1,
        value: i.cliente.nome,
        maxChar: 25,
      },
      {
        id: 2,
        value: i.cliente.cpf ? cpfMask(i.cliente.cpf).masked : '',
        maxChar: 17,
        textAlign: 'center',
      },
      {
        id: 3,
        value: phoneMask(i.cliente.celular).masked,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 4,
        value: i.operador?.nome,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 5,
        value: `R$ ${currencyMask(i?.valorLiberado)}`,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 6,
        value: `R$ ${currencyMask(i?.valorParcela)}`,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 7,
        value: i?.descricaoStatusEmprestimo,
        maxChar: 20,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportWithDrawCSV = (data: any, headerFlag?: boolean) => {
  const dataCSV = [];

  if (headerFlag) {
    const header = [
      'Nome do cliente',
      'CPF',
      'Email',
      'Celular',
      'UF',
      'Operador',
      'Valor do saque',
      'Valor da parcela',
      'Tac',
      'Taxa de juros aplicada',
      'Data de contratacao',
      'Data de desembolso',
      'Data de vencimento',
      'Status',
      'Motivo',
      'ID',
      'Sequencial Solicitacao',
    ];
    dataCSV.push(header);
  }

  data?.forEach((csv) => {
    const rowData = [
      csv.cliente?.nome,
      csv.cliente?.cpf,
      csv.cliente?.email,
      csv.cliente?.celular,
      csv.cliente.endereco?.uf,
      csv.operador?.nome,
      csv?.valorLiberado,
      csv?.valorParcela,
      csv.tacAmount?.toFixed(2),
      (csv.taxaJuros * 100)?.toFixed(2),
      csv?.dataEnvio,
      csv?.dataPagamento,
      csv?.dataPrimeiroPagamento,
      csv?.descricaoStatusEmprestimo,
      csv?.motivoStatusEmprestimo,
      csv?.id,
      csv?.idSequencial,
    ];
    dataCSV.push(rowData);
  });
  return dataCSV;
};
