import React, { useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import { IReducers } from '~/contexts/proposal';
import { useProposal } from '~/hooks/contexts/useProposal';
import { IReference } from '~/models/proposal';
import { RELATION_TYPES } from '~/models/proposta-contatos-ref';

const ProposalReference = ({ hasReference }): JSX.Element => {
  const [form, setForm] = useState<IReference>({
    comentarioRef: '',
    nomeRefe: '',
    phoneRefe: { masked: '', unmasked: '' },
    relacaoCli: '',
  });

  const { reference, addReference } = useProposal();

  useEffect(() => {
    if (reference) {
      const r = reference as IReducers;
      setForm(r?.reference);
    } else {
      setForm(getEmptyObj());
    }
  }, [reference]);

  const getEmptyObj = () => ({
    comentarioRef: '',
    nomeRefe: '',
    phoneRefe: { masked: '', unmasked: '' },
    relacaoCli: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
    addReference({ ...reference, reference: { ...form, [name]: value } });
  };

  const handleSelect = (value, name) => {
    setForm({ ...form, [name]: value });
    addReference({ ...reference, reference: { ...form, [name]: value } });
  };

  return (
    <ContainerFlex marginRight={20}>
      <Input
        label="Nome completo"
        name="nomeRefe"
        value={form?.nomeRefe}
        onChange={handleChange}
      />
      <Input
        label="Telefone"
        name="phoneRefe"
        value={form?.phoneRefe?.masked}
        maxLength={15}
        mask={MaskTypes.PHONE}
        onChange={handleChange}
      />
      <Select
        label="Relação com o cliente"
        value={form?.relacaoCli}
        name="relacaoCli"
        onChange={(val) => handleSelect(val, 'relacaoCli')}
        options={RELATION_TYPES}
      />
      <Input
        label="Comentário"
        name="comentarioRef"
        value={form?.comentarioRef}
        onChange={handleChange}
      />
    </ContainerFlex>
  );
};

export default ProposalReference;
