/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { MdOutlineFileDownload } from 'react-icons/md';
import ContainerFlex from '~/components/containerFlex';

import BoxStatus from '../components/boxStatus';
import BoxBlockingReasons from '../components/boxBlockingReasons';
import BoxStore from '../components/boxStore';

import * as S from '../styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Container from '~/components/container';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Select from '~/components/select';

interface IBoxGraphcic {
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
}

interface IBoxGraphcicData {
  loja: IBoxGraphcic;
  motivoBloqueio: IBoxGraphcic;
}

interface IProps {
  subTitle: string;
  range: string;
}

interface IStatusList {
  status: string;
  url: string;
}

interface PositivesReportResponse {
  base64: string;
  filename: string;
}

const DashDetailsClientBase: React.FC = () => {
  const [seriesStatus, setSeriesStatus] = useState([
    {
      name: 'Clientes',
      data: [0],
    },
  ]);
  const [categoriesStatus, setCategoriesStatus] = useState(['']);
  const [seriesBlockingReasons, setSeriesBlockingReasons] = useState([
    {
      name: 'Clientes',
      data: [0],
    },
  ]);
  const [categoriesBlockingReasons, setCategoriesBlockingReasons] = useState([
    '',
  ]);
  const [seriesStore, setSeriesStore] = useState([
    {
      name: 'Clientes',
      data: [0],
    },
  ]);
  const [categoriesStore, setCategoriesStore] = useState(['']);
  const [openDialog, setOpenDialog] = useState(false);
  const [listStatus, setListStatus] = useState<IStatusList[]>();
  const [selectStatus, setSelectStatus] = useState<IStatusList>({
    status: '',
    url: '',
  });

  const [form, setForm] = useState({
    periodoUltimaCompra: '',
    positivados: false,
    limitesAtivos: null,
    label: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const [openDialogError, setOpenDialogError] = useState(false);

  const { state } = useLocation<IProps>();

  const ResquestGetStatusClient = useApi(
    Endpoints.graphc.getClientStatus
      .replace('PARAM_CAMPO_ORDENADO', 'SERIES' || 'CATEGORIES')
      .replace('PARAM_TIPO_ORDENACAO', 'ASC' || 'DESC'),
    ApiMethod.GET,
  );

  const RequestGetBlockingReasonsStore = useApi(
    Endpoints.graphc.getBlockedReasonStore,
    ApiMethod.POST,
  );

  const RequestGetDownloadClient = useApi<Blob>(
    Endpoints.graphc.getDownloadClientBase,
    ApiMethod.GET,
  );

  const RequestGetPositiveCustomers = useApi<PositivesReportResponse>(
    Endpoints.graphc.getPositiveCustomers
      .replace('PARAM_QTD_DIAS', form.label)
      .replace('PARAM_POSITIVADOS', String(form.positivados)),
    ApiMethod.GET,
  );

  const RequestActiveLimits = useApi<PositivesReportResponse>(
    Endpoints.graphc.getActiveLimits.replace(
      'PARAM_LIM_ATIVO',
      form.limitesAtivos === 1 ? 'false' : form.limitesAtivos === 2 && 'true',
    ),
    ApiMethod.GET,
  );

  useEffect(() => {
    getStatusClient();
    getBlockingReasonsStore();
    getListStatusDownload();
  }, []);

  const resetRadioState = () => {
    setSelectStatus({
      status: '',
      url: '',
    });
    setForm({
      periodoUltimaCompra: '',
      positivados: false,
      limitesAtivos: null,
      label: '',
    });
  };

  const getStatusClient = async () => {
    try {
      const response = await ResquestGetStatusClient.callApi();

      if (response.status >= 200 && response.status < 300) {
        const { series, categories } = response.data as IBoxGraphcic;
        setSeriesStatus(series);
        setCategoriesStatus(categories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBlockingReasonsStore = async () => {
    try {
      const response = await RequestGetBlockingReasonsStore.callApi();

      if (response.status >= 200 && response.status < 300) {
        const { loja, motivoBloqueio } = response.data as IBoxGraphcicData;
        setSeriesStore(loja.series);
        setCategoriesStore(loja.categories);
        setSeriesBlockingReasons(motivoBloqueio.series);
        setCategoriesBlockingReasons(motivoBloqueio.categories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getListStatusDownload = async () => {
    try {
      const response = await RequestGetDownloadClient.callApi();

      if (response.status >= 200 && response.status < 300) {
        const data = response.data as IStatusList[];
        setListStatus(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePeriodoUltimaCompra = (e) => {
    const notPositive = !e.includes('np');

    setForm({
      ...form,
      periodoUltimaCompra: null,
      positivados: null,
      limitesAtivos: null,
      label: null,
    });

    switch (e) {
      case '30p':
        setForm((prev) => ({
          ...prev,
          periodoUltimaCompra: e,
          positivados: notPositive,
          label: 'TRINTA_DIAS',
        }));
        break;

      case '60p':
        setForm((prev) => ({
          ...prev,
          periodoUltimaCompra: e,
          positivados: notPositive,
          label: 'SESSENTA_DIAS',
        }));
        break;

      case '90p':
        setForm((prev) => ({
          ...prev,
          periodoUltimaCompra: e,
          positivados: notPositive,
          label: 'NOVENTA_DIAS',
        }));
        break;

      case '30np':
        setForm((prev) => ({
          ...prev,
          periodoUltimaCompra: e,
          positivados: notPositive,
          label: 'TRINTA_DIAS',
        }));
        break;

      case '60np':
        setForm((prev) => ({
          ...prev,
          periodoUltimaCompra: e,
          positivados: notPositive,
          label: 'SESSENTA_DIAS',
        }));
        break;

      case '90np':
        setForm((prev) => ({
          ...prev,
          periodoUltimaCompra: e,
          positivados: notPositive,
          label: 'NOVENTA_DIAS',
        }));
        break;

      default:
        break;
    }
  };

  const handlePositivadosDownload = async () => {
    try {
      const response = await RequestGetPositiveCustomers.callApi();

      if (response.status >= 200 && response.status < 300) {
        const binary = atob(response.data.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        const file = new Blob(['\ufeff', view], { type: 'text/csv' });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        const fileName = response.data.filename;
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(fileUrl);
      } else {
        setErrorMessage(response.mensagem);
        setOpenDialogError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusClienteDownload = (data) => {
    try {
      const link = document.createElement('a');
      const fileName = `${moment().format('YYYY-MM-DD')}-${
        data.status
      }-clientes.csv`;
      link.href = data.url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialog(false);
    }
  };

  const handleActiveLimitDownload = async () => {
    try {
      const response = await RequestActiveLimits.callApi();

      if (response.status >= 200 && response.status < 300) {
        const binary = atob(response.data.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        const file = new Blob(['\ufeff', view], { type: 'text/csv' });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        const fileName = response.data.filename;
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(fileUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Loading
        status={
          ResquestGetStatusClient.loading ||
          RequestGetBlockingReasonsStore.loading ||
          RequestGetDownloadClient.loading ||
          RequestActiveLimits.loading
        }
      />
      <Dialog
        open={openDialog}
        widthInPercent={35}
        positiveLabel="Fazer Download"
        positiveAction={() => {
          if (selectStatus.status) {
            handleStatusClienteDownload(selectStatus);
          }
          if (form.periodoUltimaCompra) {
            handlePositivadosDownload();
          }
          if (form.limitesAtivos) {
            handleActiveLimitDownload();
          }
        }}
        title="Escolha um tipo"
        negativeLabel="Cancelar"
        negativeAction={() => {
          setOpenDialog(false);
          resetRadioState();
        }}
      >
        <S.Header>
          <S.DialogTitle>Escolha um tipo de relatório</S.DialogTitle>
        </S.Header>
        <main>
          <S.DialogSection>
            <ContainerFlex row>
              <ContainerFlex padding={16}>
                <Select
                  label="Base de clientes"
                  value={selectStatus.status}
                  options={listStatus?.map((e) => ({
                    id: e.status,
                    value: e.status,
                    url: e.url,
                  }))}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      periodoUltimaCompra: null,
                      positivados: null,
                      limitesAtivos: null,
                    });
                    setSelectStatus((prev) => ({
                      ...prev,
                      url: listStatus?.find((d) => d.status === e).url,
                      status: e,
                    }));
                  }}
                />
              </ContainerFlex>
            </ContainerFlex>
          </S.DialogSection>

          <S.DialogSection>
            <ContainerFlex row>
              <ContainerFlex padding={16}>
                <Select
                  label="Positivados"
                  value={form.periodoUltimaCompra}
                  options={[
                    { id: '30p', value: 'Positivados com 30' },
                    { id: '60p', value: 'Positivados com 60' },
                    { id: '90p', value: 'Positivados com 90' },
                    { id: '30np', value: 'Não-positivados com 30' },
                    {
                      id: '60np',
                      value: 'Não-positivados com 60',
                    },
                    { id: '90np', value: 'Não-positivados com 90' },
                  ]}
                  onChange={(e) => {
                    setSelectStatus({ status: '', url: '' });

                    handleChangePeriodoUltimaCompra(e);
                  }}
                />
              </ContainerFlex>
            </ContainerFlex>
          </S.DialogSection>

          <S.DialogSection>
            <ContainerFlex row>
              <ContainerFlex padding={16}>
                <Select
                  label="Limites Ativos"
                  value={form.limitesAtivos}
                  options={[
                    { id: 2, value: 'Com limite alterado' },
                    { id: 1, value: 'Sem limite alterado' },
                  ]}
                  onChange={(e) => {
                    setSelectStatus({ status: '', url: '' });
                    setForm({
                      ...form,
                      periodoUltimaCompra: null,
                      positivados: null,
                      limitesAtivos: e,
                    });
                  }}
                />
              </ContainerFlex>
            </ContainerFlex>
          </S.DialogSection>
        </main>
      </Dialog>

      <Dialog
        icon={icons.warning}
        open={openDialogError}
        positiveLabel="Entendi"
        positiveAction={() => setOpenDialogError(false)}
        message={errorMessage}
      />

      <Container>
        <ContainerFlex row end marginBottom={5}>
          <S.BtnDownload
            onClick={() => {
              resetRadioState();
              setOpenDialog(true);
            }}
          >
            <IconContext.Provider value={{ className: 'react-icons' }}>
              <MdOutlineFileDownload />
            </IconContext.Provider>
            Download
          </S.BtnDownload>
        </ContainerFlex>

        <ContainerFlex row>
          <ContainerFlex padding={12}>
            <BoxStatus
              categories={categoriesStatus}
              series={seriesStatus}
              heightGraphic={290}
              widthGraphic={450}
              subTitle={state?.subTitle}
            />
          </ContainerFlex>

          <ContainerFlex padding={12}>
            <BoxBlockingReasons
              categories={categoriesBlockingReasons}
              series={seriesBlockingReasons}
              heightGraphic={290}
              widthGraphic={450}
              subTitle={state?.subTitle}
            />
          </ContainerFlex>
        </ContainerFlex>

        <ContainerFlex marginTop={12} marginLeft={12}>
          <BoxStore
            categories={
              categoriesStore.filter((categorie) => categorie !== null)
                ? categoriesStore.filter((categorie) => categorie !== null)
                : categoriesStore
            }
            series={
              seriesStore.filter((serie) => serie !== null)
                ? seriesStore.filter((serie) => serie !== null)
                : seriesStore
            }
            heightGraphic={290}
            widthGraphic={960}
            subTitle={state?.subTitle}
          />
        </ContainerFlex>
      </Container>
    </>
  );
};

export default DashDetailsClientBase;
