import { useContext } from 'react';
import { ILoanReport } from '../models';
import { LoanReportContext } from './LoanReportContext';

export function useReportLoan(): ILoanReport {
  const context = useContext(LoanReportContext);

  if (!context) {
    throw new Error('useLoan must be used within an LoanContext');
  }

  return context;
}
