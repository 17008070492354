import React, { useEffect, useRef, useState } from 'react';
import { icons } from '~/assets';
import ContainerFlex from '../containerFlex';

import * as St from './styles';
import Spinner from '../spinner';
import THEMES from '~/themes/themes';

interface IProps {
  title: string | JSX.Element;
  titlePadding?: number;
  titleColor?: string;
  initialState?: 'open' | 'close';
  onCollapse?: any;
  collapseHeight?: number;
  actionOnOpen?: Function;
  callFunctionOnExpand?: Function;
  onBackEvent?: boolean;
  waitRequest?: boolean;
  disable?: boolean;
  typeEvent?: boolean;
  changeProduct?: boolean;
}

const Collapse: React.FC<IProps> = ({
  children,
  title,
  titlePadding,
  titleColor,
  initialState,
  onCollapse,
  collapseHeight,
  actionOnOpen,
  callFunctionOnExpand,
  onBackEvent = true,
  waitRequest,
  disable,
  typeEvent,
  changeProduct,
}) => {
  const [contentIsOpen, setContentIsOpen] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);

  useEffect(() => {
    if (initialState === 'open' && !disable) {
      setContentIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (contentIsOpen) {
      toggleContent();
    }
  }, [changeProduct]);

  useEffect(() => {
    if (!onBackEvent && typeEvent) {
      setContentIsOpen(false);
    }
  }, [onBackEvent]);

  const contentRef = useRef<HTMLDivElement>();
  const wrapperChildrenRef = useRef<HTMLDivElement>();
  const toggleContent = () => setContentIsOpen((prev) => !prev);

  useEffect(() => {
    if (!typeEvent) {
      if (contentIsOpen && !disable) {
        const wrapperChildrenHeight = wrapperChildrenRef.current.clientHeight;

        collapseHeight
          ? (contentRef.current.style.height = `${
              wrapperChildrenHeight + collapseHeight
            }px`)
          : (contentRef.current.style.height = `${wrapperChildrenHeight}px`);
        actionOnOpen && actionOnOpen();
      } else {
        contentRef.current.style.height = '0px';
      }
      onCollapse && onCollapse(contentIsOpen);
    }
  }, [contentIsOpen, collapseHeight]);

  useEffect(() => {
    if (typeEvent) {
      if (contentIsOpen) {
        contentRef.current.style.height = '0px';
      }
      if (contentIsOpen && !disable && !waitRequest) {
        const wrapperChildrenHeight = wrapperChildrenRef.current.clientHeight;

        collapseHeight
          ? (contentRef.current.style.height = `${
              wrapperChildrenHeight + collapseHeight
            }px`)
          : (contentRef.current.style.height = `${wrapperChildrenHeight}px`);
        actionOnOpen && actionOnOpen();
      } else {
        contentRef.current.style.height = '0px';
      }
      onCollapse && onCollapse(contentIsOpen);
    }
  }, [contentIsOpen, collapseHeight, waitRequest]);

  return (
    <St.Container>
      <ContainerFlex
        color={titleColor || '#fbfbfb'}
        padding={titlePadding || 26}
        onClick={() => {
          !disable
            ? (toggleContent(),
              !contentIsOpen &&
                typeof callFunctionOnExpand === 'function' &&
                callFunctionOnExpand())
            : null;
        }}
        style={{ cursor: !disable ? 'pointer' : 'default' }}
        row
        horizontal
        center
        space
      >
        <St.TitleCategory>{title}</St.TitleCategory>
        {waitRequest ? (
          <Spinner color="black" />
        ) : (
          <St.Icon
            src={icons.arrowBlack}
            alt="arrow black"
            width={35}
            open={contentIsOpen}
          />
        )}
      </ContainerFlex>
      <St.Content ref={contentRef} open={contentIsOpen}>
        <div ref={wrapperChildrenRef}>{children}</div>
      </St.Content>
    </St.Container>
  );
};

export default Collapse;
