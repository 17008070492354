import styled from 'styled-components';
import { darken } from 'polished';

export const Div = styled.div`
  border: 1px solid #eee;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;

export const BtnTrashCan = styled.div`
  width: 138px;

  border: 0.5px solid #e8e8e8;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 7px 0 rgba(201, 201, 201, 0.5);
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;

  :hover {
    background-color: ${darken(0.045, '#ffffff')};
  }

  svg {
    width: 24px;
  }
`;

export const BtnOrder = styled.div`
  width: 28px;

  border: 0.5px solid #e8e8e8;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 7px 0 rgba(201, 201, 201, 0.5);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;

  :hover {
    background-color: ${darken(0.045, '#ffffff')};
  }

  svg {
    width: 24px;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;
