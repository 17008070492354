import styled from 'styled-components';

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1.5em;
  font-weight: 600;
  padding-left: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const IconCard = styled.img`
  height: 25px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: grey;
    padding: 10px;
  }

  th {
    padding: 10px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: grey;
  }
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;
