import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import TableList from '~/components/tableList';
import useApi, { ApiMethod } from '~/hooks/useApi';
import ConsultReactivateResponse from '~/services/credit-table/reactivate/response';
import Endpoints from '~/services/endpoints';
import { cpfMask, IDefaultReturn } from '~/utils/masks';
import { IListColCampaing } from '~/views/LimitIncrease/model';

import * as St from './styles';

interface IForm {
  nome: string;
  cpf: IDefaultReturn;
}

const ConsultReactivateDecision = (): JSX.Element => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const [list, setList] = useState([]);
  const history = useHistory();

  const handleGenerateURL = useMemo(() => {
    if (form.cpf?.unmasked && form.nome) {
      return `${Endpoints.consult.searchReactivate}?cpf=${form.cpf?.unmasked}&nome=${form.nome}`;
    }
    if (form.cpf?.unmasked && (!form.nome || form.nome === '')) {
      return `${Endpoints.consult.searchReactivate}?cpf=${form.cpf?.unmasked}`;
    }
    if (form.nome && (!form.cpf?.unmasked || form.cpf?.unmasked === '')) {
      return `${Endpoints.consult.searchReactivate}?nome=${form.nome}`;
    }
    return `${Endpoints.consult.searchReactivate}`;
  }, [form.cpf, form.nome]);

  const RequestReactivate = useApi<ConsultReactivateResponse>(
    `${handleGenerateURL}`,
    ApiMethod.GET,
  );

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const goToDetail = (id, cpf, numeroCartao) =>
    history.push('/credit-table/reactivate/decision/detail', {
      id,
      cpf,
      numeroCartao,
    });

  const customerHeader = [
    {
      id: 1,
      value: 'ID da proposta',
      maxChar: 30,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Nome completo',
      maxChar: 35,
    },
    {
      id: 3,
      value: 'CPF',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Status',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Data e Horário',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const search = async () => {
    const newList = [];
    const response = await RequestReactivate.callApi();

    // let i = 0;

    if (response.status === 200) {
      const data = response.data as ConsultReactivateResponse[];

      data.forEach((data) => {
        const item: IListColCampaing[] = [
          // {
          //   type: 'id',
          //   value: i++,
          //   maxChar: 5,
          //   textAlign: 'center',
          // },
          {
            border: true,
            title: 'ID da proposta',
            value: data.id,
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Nome completo',
            value: data.nomeCliente,
            maxChar: 35,
          },
          {
            border: true,
            title: 'CPF',
            value: data.cpf ? cpfMask(data.cpf).masked : '',
            maxChar: 25,
            textAlign: 'center',
          },
          {
            title: 'Status',
            value: data.status,
            maxChar: 25,
            textAlign: 'center',
          },
          {
            title: 'Data e Horário',
            value: data.dataInclusao
              ? moment(data.dataInclusao).format('DD/MM/YYYY HH:mm')
              : '',
            maxChar: 20,
            textAlign: 'center',
          },
          {
            item: data,
            searchAction: () =>
              goToDetail(data.id, data.cpf, data.numeroCartao),
          },
        ];
        newList.push(item);
      });
    }
    setList(newList);
  };

  useEffect(() => {
    search();
  }, []);

  return (
    <Container>
      <St.BoxCard>
        <ContainerFlex row space>
          <ContainerFlex padding={16}>
            <Input
              label="Nome"
              name="nome"
              value={form.nome}
              onChange={handleChange}
            />
          </ContainerFlex>

          <ContainerFlex padding={16}>
            <Input
              label="CPF"
              name="cpf"
              placeholder="000.000.000-00"
              maxLength={14}
              mask={MaskTypes.CPF}
              value={form.cpf?.masked}
              onChange={handleChange}
            />
          </ContainerFlex>

          <ContainerFlex padding={16}>
            <Button
              loading={RequestReactivate.loading}
              fitContent
              onClick={() => search()}
            >
              <span>Consultar</span>
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </St.BoxCard>

      {list.length > 0 && (
        <St.BoxCard>
          <ContainerFlex row>
            {RequestReactivate.data && !list.length && (
              <ContainerFlex center marginTop={30}>
                <St.TitleCategory>Nenhuma proposta encontrada</St.TitleCategory>
              </ContainerFlex>
            )}

            {RequestReactivate.status === 404 && (
              <ContainerFlex center marginTop={30}>
                {/* <St.TitleCategory>{RequestReactivate.data.mensagem}</St.TitleCategory> */}
              </ContainerFlex>
            )}

            {RequestReactivate.status !== 404 && list.length > 0 && (
              <TableList customerHeader={customerHeader} list={list} />
            )}
          </ContainerFlex>
        </St.BoxCard>
      )}
    </Container>
  );
};

export default ConsultReactivateDecision;
