export const validateDate = (date: Date) => {
  const isValid = date instanceof Date && !Number.isNaN(date.valueOf());
  return isValid;
};

export const eventType = [
  { value: 'Todos', id: 'TODOS' },
  {
    id: 'LEAD',
    value: 'Lead',
  },
  {
    id: 'DOWNGRADE',
    value: 'Downgrade',
  },
  {
    id: 'UPGRADE',
    value: 'Upgrade',
  },
  {
    id: 'CANCELAMENTO',
    value: 'Cancelamento',
  },
];

export const productType = [
  { value: 'Todos', id: 'TODOS' },
  {
    id: 'BENEFICIO',
    value: 'Benefício',
  },
  {
    id: 'CARTAO',
    value: 'Cartão',
  },
  {
    id: 'CONTA',
    value: 'Conta',
  },
  // {
  //   id: 'EMPRESTIMO',
  //   value: 'Empréstimo',
  // },
];

export const status = [
  { value: 'Todos', id: 'TODOS' },
  {
    id: 'PENDENTE',
    value: 'Pendente',
  },
  {
    id: 'ANDAMENTO',
    value: 'Andamento',
  },
  {
    id: 'SUCESSO',
    value: 'Sucesso',
  },
  {
    id: 'INSUCESSO',
    value: 'Insucesso',
  },
];

export const UFs = [
  { value: 'Todos', id: 'TODOS' },
  { value: 'Acre', id: 'AC' },
  { value: 'Alagoas', id: 'AL' },
  { value: 'Amapá', id: 'AP' },
  { value: 'Amazonas', id: 'AM' },
  { value: 'Bahia', id: 'BA' },
  { value: 'Ceará', id: 'CE' },
  { value: 'Distrito Federal', id: 'DF' },
  { value: 'Espírito Santo', id: 'ES' },
  { value: 'Goiás', id: 'GO' },
  { value: 'Maranhão', id: 'MA' },
  { value: 'Mato Grosso', id: 'MT' },
  { value: 'Mato Grosso do Sul', id: 'MS' },
  { value: 'Minas Gerais', id: 'MG' },
  { value: 'Pará', id: 'PA' },
  { value: 'Paraíba', id: 'PB' },
  { value: 'Paraná', id: 'PR' },
  { value: 'Pernambuco', id: 'PE' },
  { value: 'Piauí', id: 'PI' },
  { value: 'Rio de Janeiro', id: 'RJ' },
  { value: 'Rio Grande do Norte', id: 'RN' },
  { value: 'Rio Grande do Sul', id: 'RS' },
  { value: 'Rondônia', id: 'RO' },
  { value: 'Roraima', id: 'RR' },
  { value: 'Santa Catarina', id: 'SC' },
  { value: 'São Paulo', id: 'SP' },
  { value: 'Sergipe', id: 'SE' },
  { value: 'Tocantins', id: 'TO' },
];
