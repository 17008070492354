import styled from 'styled-components';

export const MonthCards = styled.button`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 3fr));
  gap: 24px;
  margin: 24px 12px;
`;

export const MonthCardItem = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;

  h2 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #677383;
  }

  span {
    font-size: 1.8rem;
    font-weight: 700;
    color: #2e3958;
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;

    &.red {
      color: #e4003a;
    }

    &.green {
      color: #00b285;
    }

    &.blue {
      color: #3c77ff;
    }

    &.orange {
      color: #fab73b;
    }

    &.gray {
      color: #b4b4b4;
    }
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0 8px 20px 0 rgb(0 0 0 / 13%);
    -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.125);
    -webkit-box-shadow: 0 8px 20px 0 rgb(0 0 0 / 13%);
  }
`;

export const MonthCardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StatusPercent = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 3px 8px;
  background-color: #f8a72d;
  border-radius: 20px;
`;

export const MonthCardItemNoHover = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;

  h2 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #677383;
  }

  span {
    font-size: 1.8rem;
    font-weight: 700;
    color: #2e3958;
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;

    &.red {
      color: #e4003a;
    }

    &.green {
      color: #00b285;
    }

    &.blue {
      color: #3c77ff;
    }

    &.orange {
      color: #fab73b;
    }

    &.gray {
      color: #b4b4b4;
    }
  }
`;
