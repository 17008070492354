import React from 'react';

import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';

import { IProposal } from '~/models/proposal';
import { cepMask, cpfMask, currencyMask, phoneMask } from '~/utils/masks';
import { transformInNormalDate } from '~/utils/date';
import Checkbox from '~/components/checkbox';
import Grid from '~/components/grid';

interface IComponents {
  data: IProposal;
}

const ProposalResultSPC: React.FC<IComponents> = ({ data }) => {
  const retCPF = cpfMask(data.spc?.cpf);
  const retCEP = cepMask(data.spc?.endereco?.cep);
  const dataNascimento = data.spc?.dataNascimento
    ? transformInNormalDate(data.spc?.dataNascimento.substring(0, 10))
    : { masked: '', unmasked: '' };
  const retRenda = currencyMask(data.spc?.rendaPresumida);

  return (
    <ContainerFlex marginLeft={16} marginRight={26}>
      <Input label="CPF" value={retCPF?.masked} readOnly bulletColor="white" />
      <Input
        label="Nome completo"
        value={data.spc?.nome}
        readOnly
        bulletColor="white"
      />
      <Input
        label="Telefone"
        value={phoneMask(data.spc?.celular).masked}
        readOnly
        bulletColor="white"
      />
      <Input
        label="Telefone 2"
        value={phoneMask(data.spc?.celular2).masked}
        readOnly
        bulletColor="white"
      />
      <Input
        label="E-mail"
        value={data.spc?.email}
        readOnly
        bulletColor="white"
      />
      <Input
        label="Data de nascimento"
        value={dataNascimento.masked}
        readOnly
        bulletColor="white"
      />
      <Input
        label="CEP"
        name="cep"
        mask={MaskTypes.CEP}
        maxLength={9}
        placeholder="00000-000"
        value={retCEP?.masked}
        readOnly
        bulletColor="white"
      />
      <Input
        label="Endereço"
        value={data.spc?.endereco?.logradouro}
        readOnly
        bulletColor="white"
      />
      <div>
        <Grid xs={1} sm={2} md={3} gap={10}>
          <Input
            label="Bairro"
            value={data.spc?.endereco?.bairro}
            readOnly
            bulletColor="white"
          />
          <Input
            label="Número"
            value={data.spc?.endereco?.numero}
            readOnly
            bulletColor="white"
          />
          <Input
            label="Complemento"
            value={data.spc?.endereco?.complemento}
            readOnly
            bulletColor="white"
          />
        </Grid>

        <Grid xs={1} lg={2} gap={10}>
          <Input
            label="Cidade"
            value={data.spc?.endereco?.cidade}
            readOnly
            bulletColor="white"
          />
          <Input
            label="UF"
            value={data.spc?.endereco?.uf}
            readOnly
            bulletColor="white"
          />
        </Grid>
      </div>
      <Input
        label="Renda estimada"
        value={retRenda}
        readOnly
        bulletColor="white"
      />
      <Checkbox
        message="Possui emprego formal"
        value={data.spc?.empregoFormal}
        readonly
        bulletColor="white"
      />
      <br />
      <Input
        label="Informação profissional"
        value={data.spc?.profissao}
        readOnly
        bulletColor="white"
      />
      <br />
      <br />
      <br />
      <br />
      <ContainerFlex />
    </ContainerFlex>
  );
};

export default ProposalResultSPC;
