/* eslint-disable react/require-default-props */
import { useState } from 'react';
import Dialog, { IDialog } from '../dialog';
import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';
import { IDefaultReturn, cpfMask } from '~/utils/masks';
import Input from '../input';
import { icons } from '~/assets';

type IPasswordDialogProps = {
  onSuccessValidation: Function;
  // eslint-disable-next-line react/require-default-props
  onErrorValidation?: Function;
  onClose?: Function;
} & IDialog;
const PasswordModal = ({
  positiveAction,
  onSuccessValidation,
  onErrorValidation,
  negativeAction,
  onClose,
  ...props
}: IPasswordDialogProps) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [modalError, setModalError] = useState({ open: false, message: '' });
  const user = JSON.parse(localStorage.getItem('@NovaEra:user'));
  const [cpf] = useState<IDefaultReturn>(cpfMask(user?.cpf) as IDefaultReturn);
  const [senha, setSenha] = useState('');

  const AuthValidatePassword = useApi<any>(
    endpoints.account.validateUserPassword,
    ApiMethod.POST,
  );
  const hiddeCPFNumber = (value) => {
    const valueSplited = value.split('');

    return valueSplited
      .map((el, index) => {
        if (index > 3 && index < 11) return '*';
        return el;
      })
      .join('');
  };
  const handlePasswordValidate = async () => {
    try {
      const { status, mensagem } = await AuthValidatePassword.callApi({
        cpf: cpf.unmasked,
        senha,
      });
      if (status >= 200 && status <= 300) {
        onSuccessValidation();
        setModalOpen(false);
      } else {
        onErrorValidation && onErrorValidation();
        setModalOpen(false);
        setModalError({ open: true, message: mensagem });
      }
    } catch (err) {
      setModalError({ open: true, message: err.mensagem });
      console.log(err);
    }
  };
  return (
    <>
      <Dialog
        widthInPercent={30}
        open={modalOpen}
        positiveLabel="Confirmar"
        negativeLabel="Voltar"
        buttonDisabledPositive={senha.length < 6}
        negativeAction={() => {
          setModalOpen(false);
          onClose();
        }}
        positiveAction={handlePasswordValidate}
        icon={icons.warning}
        title="Insira sua senha"
        {...props}
      >
        <Input
          id="cpf"
          name="cpf"
          type="text"
          label="CPF"
          maxLength={14}
          value={hiddeCPFNumber(cpf.masked)}
        />
        <Input
          type="password"
          label="Senha"
          autoComplete="new-password"
          autoCorrect="none"
          maxLength={6}
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
      </Dialog>
      <Dialog
        open={modalError.open}
        title={modalError.message}
        positiveAction={() => {
          setModalError({ open: false, message: '' });
          onClose();
        }}
        positiveLabel="Entendi"
        icon={icons.warning}
      />
    </>
  );
};

export default PasswordModal;
