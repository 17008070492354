import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, phoneMask } from '~/utils/masks';

export const HeaderReportAnfitriao = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Celular',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Código Anfitrião',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Data Cadastro',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Status',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 8,
    value: 'Data Utilização',
    maxChar: 10,
    textAlign: 'center',
  },
];

export const BodyReportAnfitriao = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome,
        maxChar: 25,
      },
      {
        value: cpfMask(i.cpf).masked,
        maxChar: 14,
        textAlign: 'center',
      },
      {
        value: i.celular ? phoneMask(i.celular).masked : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.codigo,
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.dataCadastro
          ? moment(i.dataCadastro).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.status,
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.dataUltimaUtilizacao
          ? moment(i.dataUltimaUtilizacao).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 20,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
