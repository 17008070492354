/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import { IListColumn } from '~/models/listCol';
import { currencyMask } from '~/utils/masks';

import Container from '~/components/container';
import Pagination from '~/components/pagination';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import ContainerFlex from '~/components/containerFlex';
import TitleApp from '~/components/title';
import TableList from '~/components/tableList';
import Button from '~/components/button';
import Select from '~/components/select';

import * as St from './styles';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import { FormField } from '~/utils/form';
import { ITransactionsWithdrawResponse } from '~/services/consult/transaction/withdraw/response';
import FilterDate from './components/FilterDate';

interface IState {
  celcoin: boolean;
  cpf: string;
}

interface IHistoryLoanCelcoin {
  limitePorPagina: number;
  pagina: {
    id: string;
    dataCadastro: string;
    status: string;
    valorLiberado: number;
    valorParcela: number;
    motivoStatusEmprestimo: string;
    dataFechamento: string;
    dataPrimeiroPagamento: string;
  }[];
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}

interface FormWithdraw {
  tIdEmprestimo?: FormField;
  tValorSaque?: FormField;
  contratoBase64?: string;
  comprovanteBase64?: string;
  documentType?: string;
}

interface Payload {
  cpf: string;
  limitePorPagina: number;
  pagina: number;
  dataFinal?: string;
  dataInicial?: string;
}

const DOCUMENTS_TYPE = [
  { id: 'COMPROVANTE', value: 'Comprovante Transferência' },
  { id: 'CONTRATO', value: 'Contrato Assinado' },
];

const HistoryLoan = (): JSX.Element => {
  const [list, setList] = useState([]);
  const [header, setHeader] = useState([]);
  const [idEmprestimo, setIdEmprestimo] = useState('');
  const [idTransacaoSaque, setIdTransacaoSaque] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogDocument, setOpenDialogDocument] = useState(false);

  const [formWithdraw, setFormWithdraw] = useState<FormWithdraw>({
    tIdEmprestimo: { name: 'tIdEmprestimo', value: '' },
    tValorSaque: { name: 'tValorSaque', value: '' },
    contratoBase64: '',
    comprovanteBase64: '',
  });

  const [form, setForm] = useState({
    documentType: 'CONTRATO',
    periodoInicial: '',
    periodoFinal: '',
  });

  const [message, setMessage] = useState('');

  const history = useHistory();

  const { state } = useLocation<IState>();

  useEffect(() => {
    getHistoryLoanCelcoin();
  }, [state?.cpf]);

  const RequestHistoryLoanCelcoin = useApi<IHistoryLoanCelcoin>(
    Endpoints.loan.postHistoryLoanCelcoin,
    ApiMethod.POST,
  );

  const RequestCancelWithdraw = useApi(
    Endpoints.loan.putCancelWithdraw.replace('PARAM_ID_LOAN', idEmprestimo),
    ApiMethod.PUT,
  );

  const RequestGetDetailsLoanDocuments = useApi<ITransactionsWithdrawResponse>(
    Endpoints.loan.getDetailsTransactionLoan.replace(
      'PARAM_ID',
      idTransacaoSaque,
    ),
    ApiMethod.GET,
  );

  function base64ToBlob(base64, type = 'application/octet-stream') {
    const base64File = base64?.replace(/\s+/g, '');
    console.log('base64', base64File);

    const binStr = atob(base64File);
    console.log('bin', binStr);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type });
  }

  const handlePdfNewTab = (base64: string) => {
    if (!base64) {
      console.error('Base64 string is undefined or empty');
      return;
    }

    const blob = base64ToBlob(base64, 'application/pdf');
    const url = URL.createObjectURL(blob);
    const pdfWindow = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${url}'></iframe>`,
    );
  };

  const getDetailsDocumentsLoan = async () => {
    try {
      const response = await RequestGetDetailsLoanDocuments.callApi();
      const { data } = response;

      const obj: FormWithdraw = {
        tIdEmprestimo: { ...formWithdraw.tIdEmprestimo, value: data.id },
        tValorSaque: {
          ...formWithdraw.tValorSaque,
          value: String(data.valorLiberado),
        },
        contratoBase64: data.contratoBase64,
        comprovanteBase64: data.comprovanteBase64,
      };
      setFormWithdraw(obj);
    } catch (error) {
      console.log(error);
    }
  };

  const putCancelLoanCelcoin = async () => {
    try {
      const response = await RequestCancelWithdraw.callApi({});

      if (response.status >= 200) {
        setOpenDialog(true);
        setOpenDialogConfirmation(false);
        setMessage('Solicitação de saque cancelada com sucesso!');
        getHistoryLoanCelcoin();
      }

      if (response.status >= 400) {
        setOpenDialogConfirmation(false);
        setOpenDialog(true);
        setMessage(response?.data?.mensagem);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogConfirmation(false);
    }
  };

  const getHistoryLoanCelcoin = async (page = 1, limit = 10, dates = false) => {
    const customerHeader = [
      {
        id: 1,
        value: 'Data contratação',
        maxChar: 15,
      },
      { id: 2, value: 'Fechamento', maxChar: 13 },
      { id: 3, value: 'Vencimento', maxChar: 13 },
      { id: 4, value: 'Status', maxChar: 14 },
      { id: 5, value: 'Valor Saque', maxChar: 13, textAlign: 'center' },
      { id: 6, value: 'Valor Fatura', maxChar: 13, textAlign: 'center' },
      { id: 7, value: 'Motivo do cancelamento', maxChar: 20 },
      { id: 8, value: 'Ação', textAlign: 'center', maxChar: 5 },
    ];

    let payload: Payload = {
      cpf: state?.cpf,
      limitePorPagina: limit,
      pagina: page,
    };

    if (dates) {
      payload = {
        ...payload,
        dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
        dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      };
    }

    try {
      const response = await RequestHistoryLoanCelcoin.callApi(payload);

      const { pagina } = response.data;

      const items = [];

      pagina?.forEach((i) => {
        const item: IListColumn[] = [
          {
            value: moment(i.dataCadastro).format('DD/MM/YYYY'),
            maxChar: 15,
            textAlign: 'center',
          },
          {
            value: moment(i.dataFechamento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            maxChar: 13,
            textAlign: 'center',
          },
          {
            value: moment(i.dataPrimeiroPagamento, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            ),
            maxChar: 13,
            textAlign: 'center',
          },
          {
            value: i.status,
            maxChar: 8,
            textAlign: 'center',
          },
          {
            value: `R$ ${currencyMask(i.valorLiberado)}`,
            maxChar: 13,
            textAlign: 'center',
          },
          {
            value: `R$ ${currencyMask(i.valorParcela)}`,
            maxChar: 13,
            textAlign: 'center',
          },
          {
            value: i.motivoStatusEmprestimo ? i.motivoStatusEmprestimo : '-',
            maxChar: 20,
            textAlign: 'center',
          },
          {
            item: i,
            maxChar: 5,
            action: (id, item) => {
              if (i.status === 'PENDENTE ASSINATURA') {
                setIdEmprestimo(id);
                setOpenDialogConfirmation(true);
              } else {
                setIdTransacaoSaque(id);
                setOpenDialogDocument(true);
              }
            },
            status: i.status,
          },
        ];
        items.push(item);
      });

      if (response.status >= 200 && response.status < 300) {
        setHeader(customerHeader);
        setList(items);
        setTotalItems(response.data.totalItens);
        setTotalPages(response.data.totalDePaginas);
        setOpenDialogConfirmation(false);
      }
    } catch (error) {
      console.log(error);
      setOpenDialogConfirmation(false);
    } finally {
      setOpenDialogConfirmation(false);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (form.periodoInicial === '' && form.periodoFinal === '') {
      getHistoryLoanCelcoin();
    } else if (form.periodoFinal && form.periodoFinal) {
      getHistoryLoanCelcoin(1, 10, true);
    }
  }, [form.periodoFinal, form.periodoInicial]);

  useEffect(() => {
    if (idTransacaoSaque) {
      getDetailsDocumentsLoan();
    }
  }, [idTransacaoSaque]);

  const handleDocument = (document) => {
    if (document === 'COMPROVANTE') {
      handlePdfNewTab(formWithdraw?.comprovanteBase64);
    } else if (document === 'CONTRATO') {
      handlePdfNewTab(formWithdraw?.contratoBase64);
    }
  };

  const handleFilterDateClick = (e) => {
    const periodoInicial = e.periodoInicial
      ? moment(e.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : '';
    const periodoFinal = e.periodoFinal
      ? moment(e.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : '';

    setForm((prevForm) => ({ ...prevForm, periodoFinal, periodoInicial }));
  };

  return (
    <>
      <Loading
        status={
          RequestHistoryLoanCelcoin.loading ||
          RequestGetDetailsLoanDocuments.loading
        }
      />
      <Dialog
        open={openDialog}
        icon={icons.exclamation}
        positiveAction={() => setOpenDialog(false)}
        message={message}
        positiveLabel="Entendi"
      />
      <Dialog
        icon={icons.warning}
        open={openDialogDocument}
        negativeAction={() => setOpenDialogDocument(false)}
        positiveAction={() => handleDocument(form?.documentType)}
        positiveLabel="Abrir"
        negativeLabel="Cancelar"
        message="Qual documento que deseja visualizar?"
      >
        <ContainerFlex marginTop={20}>
          <Select
            options={DOCUMENTS_TYPE}
            value={form?.documentType}
            onChange={(e) => {
              setForm({ ...form, documentType: e });
            }}
          />
        </ContainerFlex>
      </Dialog>
      <Dialog
        icon={icons.warning}
        open={openDialogConfirmation}
        negativeAction={() => setOpenDialogConfirmation(false)}
        positiveAction={putCancelLoanCelcoin}
        Loading={RequestCancelWithdraw.loading}
        buttonDisabledPositive={RequestCancelWithdraw.loading}
        positiveLabel="SIM"
        negativeLabel="NÃO"
        message="Deseja mesmo cancelar a solicitação de saque?"
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <ContainerFlex>
              <TitleApp>Empréstimos com cartão TeuCard</TitleApp>
            </ContainerFlex>

            <ContainerFlex marginTop={30}>
              {list.length > 0 ? (
                <>
                  <ContainerFlex width={80} marginBottom={45}>
                    <FilterDate onClick={handleFilterDateClick} />
                  </ContainerFlex>

                  <TableList customerHeader={header} list={list} />
                  <ContainerFlex row center marginBottom={20} marginTop={20}>
                    {totalItems > 0 && (
                      <St.Results>
                        Total de resultados:&nbsp;
                        <span style={{ fontWeight: 'bold' }}>{totalItems}</span>
                      </St.Results>
                    )}
                  </ContainerFlex>
                  {list.length > 0 && (
                    <ContainerFlex row horizontal center marginTop={20}>
                      <Pagination
                        totalPages={totalPages}
                        onCurrentPageChange={getHistoryLoanCelcoin}
                      />
                    </ContainerFlex>
                  )}
                </>
              ) : (
                <ContainerFlex center>
                  <St.Subtitle>O Cliente não possui empréstimos</St.Subtitle>
                </ContainerFlex>
              )}
              <ContainerFlex end marginTop={20}>
                <Button onClick={goBack}>Voltar</Button>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default HistoryLoan;
