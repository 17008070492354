import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask } from '~/utils/masks';

export const HeaderRepostIOF = [
  {
    id: 1,
    value: 'Nome do cliente',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data pagamento',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Valor Base',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Prazo Apurado',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Valor do IOF cobrado',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodyRepostIOF = (data: any) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        id: 1,
        value: i.nome,
        maxChar: 25,
      },
      {
        id: 2,
        value: cpfMask(i.cpf).masked,
        maxChar: 14,
        textAlign: 'center',
      },
      {
        id: 3,
        value: i.pagamento
          ? moment(i.pagamento).format('DD/MM/YYYY HH:mm')
          : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 4,
        value: `R$ ${currencyMask(i.valorBase)}`,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 5,
        value: i.prazo,
        maxChar: 10,
        textAlign: 'center',
      },
      {
        id: 6,
        value: `R$ ${currencyMask(i.iofCobrado)}`,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
