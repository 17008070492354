import React, { memo } from 'react';

import { icons } from '~/assets';
import * as St from './styles';

interface ISendButton {
  onClick: () => void;
}

const SendButton: React.FC<ISendButton> = ({ onClick }) => {
  return <St.Container onClick={onClick} src={icons.sendButton} />;
};

export default memo(SendButton);
