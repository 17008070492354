/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Input, { MaskTypes } from '~/components/input';
import Button from '~/components/button';

import ContainerFlex from '~/components/containerFlex';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import TeucardCardPayload from '~/services/transaction-consult/teucard-card/payload';

import { TitleCategory } from './styles';
import TeucardCard from '~/models/teucard-card';
import Loading from '~/components/loading';
import { currencyMask } from '~/utils/masks';
import TableList from '~/components/tableList';
import { IListColumn } from '~/models/listCol';
import Container from '~/components/container';

import * as St from './styles';

interface Form {
  cpf: FormField;
}

const DigitalWallet: React.FC = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState('1');
  const [qtdRegistros, setQtdRegistros] = useState('600');
  const [form, setForm] = useState<Form>({
    cpf: { name: 'cpf', value: '' },
  });

  const api = useApi<TeucardCard>(
    Endpoints.consult.walletPaymentList
      .replace('PARAM_CPF', form.cpf?.unmasked)
      .replace('PARAM_PAGINA', currentPage)
      .replace('PARAM_QTD_REGISTROS', qtdRegistros),
    ApiMethod.GET,
  );

  // Recarregar para enviar a pagina que está
  useEffect(() => {
    if (currentPage > '1') searchTransaction(Number(currentPage));
  }, [currentPage]);

  const customerHeader = [
    {
      id: 1,
      value: 'Local da transação',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Valor da parcela',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Valor da Transação',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Tipo de transação',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Bandeira',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Data e hora transação',
      maxChar: 22,
      textAlign: 'center',
    },
    {
      id: 7,
      value: 'Status',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 8,
      value: 'Ação',
      maxChar: 8,
      textAlign: 'center',
    },
  ];

  useEffect(() => {
    loadData();
  }, [api.loading]);

  const loadData = () => {
    if (api.status !== 200) {
      setList([]);
      return;
    }

    const newList = [];

    const response = api.data.transacoes as TeucardCard[];
    // let i = 0;
    setTotalPages(api.data.totalPaginas);
    response.forEach((d) => {
      const item: IListColumn[] = [
        // {
        //   type: 'id',
        //   value: i++,
        //   maxChar: 5,
        // },
        {
          title: 'Local da transação',
          value:
            d?.localTransacao === 'PAGAMENTO_FATURA'
              ? d.localTransacao
              : d.localTransacao?.replace(
                  'Transação Digital',
                  'Trans. Digital',
                ),
          maxChar: 19,
          // textAlign: 'center',
        },
        {
          border: true,
          title: 'Valor da Parcela',
          value: `R$ ${currencyMask(d.valorParcela)}`,
          maxChar: 10,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Valor da transação',
          value: `R$ ${currencyMask(Number(d.valorCompra))}`,
          maxChar: 10,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Tipo',
          value: d.tipoTransacao,
          maxChar: 30,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Bandeira',
          value: d.bandeira,
          maxChar: 30,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Data e hora transação',
          value: `${moment(d.data).format('DD/MM/YYYY')} | ${moment(
            d.data,
          ).format('HH:mm:ss')}`,
          maxChar: 22,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Status',
          value: d.status,
          maxChar: 15,
          textAlign: 'center',
        },
        {
          searchAction: () => goToDetail(d.id),
          textAlign: 'center',
          maxChar: 5,
        },
      ];

      newList.push(item);
    });

    setList(newList);
  };

  const getPaginacao = async (page = 1, qtdRegistros = 10) => {
    setCurrentPage(String(page));
  };

  const searchTransaction = async (pagina = 1) => {
    const payload: TeucardCardPayload = {
      cpf: form.cpf.unmasked?.length !== 11 ? null : form.cpf.unmasked,
    };
    setList([]);
    setCurrentPage('1');
    setTotalPages(0);
    getPaginacao();

    api.callApi(payload);
  };

  const goToDetail = (id) => {
    history.push('/consult-transaction/digital-wallet/detail', {
      cpf: form.cpf,
      idTransacao: id,
    });
  };

  return (
    <>
      <Loading status={api.loading} />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name={form.cpf.name}
                value={form.cpf.value}
                onChange={(e) => setFormState(e, form, setForm)}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={() => searchTransaction()}
                loading={api.loading}
                disabled={form.cpf.value.length !== 14 || api.loading}
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row padding={16}>
            {list.length === 0 && api.data && (
              <ContainerFlex center marginTop={30}>
                <TitleCategory>Nenhuma transação encontrada.</TitleCategory>
              </ContainerFlex>
            )}

            {list.length > 0 && (
              <TableList customerHeader={customerHeader} list={list} />
            )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default DigitalWallet;
