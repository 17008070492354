import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderReportCuida = [
  {
    id: 1,
    value: 'Nome cliente',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Venda',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Operador(a) de Venda',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Status',
    maxChar: 7,
  },
  {
    id: 9,
    value: 'UF',
    maxChar: 9,
    textAlign: 'center',
  },
];

export const BodyReportCuida = (data: any) => {
  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        id: 1,
        value: i.nomeCliente,
        maxChar: 15,
      },
      {
        id: 2,
        value: i.cpf ? cpfMask(i.cpf).masked : '-',
        maxChar: 13,
        textAlign: 'center',
      },
      {
        id: 3,
        value: i.dataVenda ? moment(i.dataVenda).format('DD/MM/YYYY') : '-',
        maxChar: 13,
        textAlign: 'center',
      },
      {
        id: 4,
        value: i.operador,
        textAlign: 'center',
        maxChar: 14,
      },
      {
        id: 5,
        value: i.statusBeneficio,
        maxChar: 7,
        textAlign: 'center',
      },
      {
        id: 6,
        value: i.uf,
        maxChar: 9,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportCuidaCSV = (data: any) => {
  const dataCSV = [];
  data.forEach((csv) => {
    dataCSV.push({
      nomeCliente: csv.nomeCliente,
      cpf: csv.cpf,
      email: csv.email,
      celular: csv.celular,
      celular2: csv.celular2,
      dataVenda: csv.dataVenda,
      operador: csv.operador,
      loja: csv.loja,
      statusCartao: csv.statusCartao,
      statusBeneficio: csv.statusBeneficio,
      uf: csv.uf,
      ufLoja: csv.ufLoja,
    });
  });

  return dataCSV;
};

export const BodyTotalCuida = (data: any) => {
  const totalTitular = data.reduce((acc, cur) => {
    return acc + 1;
  }, 0);
  return totalTitular;
};
