import styled from 'styled-components';

export const TitleCategory = styled.span`
  font-size: 1.2em;
  font-weight: 700;
  color: #2e3958;
  height: min-content;
`;

export const Container = styled.div`
  margin-bottom: 10px;
`;

export const Content = styled.div<{ open: boolean }>`
  transition: height 0.7s linear;
  overflow: hidden;
  padding-top: ${({ open }) => (open ? '8px' : '0px')};
  padding-bottom: ${({ open }) => (open ? '30px' : '0px')};
`;

export const Icon = styled.img<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(270deg)' : 'rotate(180deg)')};
  transition: transform 0.7s;
`;
