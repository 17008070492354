export const BENEFIT_TYPES = [
  {
    id: 'VENDAS_ODONTO',
    name: 'Vendas Odonto',
    value: 'Vendas Odonto',
  },
  {
    id: 'CANCELAMENTO_ODONTO',
    name: 'Cancelamento Odonto',
    value: 'Cancelamento Odonto',
  },
  {
    id: 'FATURAMENTO_ODONTO',
    name: 'Faturamento Odonto',
    value: 'Faturamento Odonto',
  },
  {
    id: 'VENDAS_PROTEGE',
    name: 'Vendas Protege',
    value: 'Vendas Protege',
  },
  {
    id: 'CANCELAMENTO_PROTEGE',
    name: 'Cancelamento Protege',
    value: 'Cancelamento Protege',
  },
  {
    id: 'VENDAS_CUIDA',
    name: 'Vendas TeuCard Cuida',
    value: 'Vendas TeuCard Cuida',
  },
  {
    id: 'CANCELAMENTO_CUIDA',
    name: 'Cancelamento TeuCard Cuida',
    value: 'Cancelamento TeuCard Cuida',
  },
  {
    id: 'VENDAS_PLATINUM',
    name: 'Vendas Adesões Platinum',
    value: 'Vendas Adesões Platinum',
  },
  {
    id: 'CANCELAMENTO_PLATINUM',
    name: 'Cancelamento Platinum',
    value: 'Cancelamento Platinum',
  },
];

export interface IBenefitReport {
  postReportSalesOdont: Function;
  postReportCancelOdont: Function;
  postReportCancelSafe: Function;
  postReportInvoceOdont: Function;
  postReportSalesSafe: Function;
  postReportSalesCuida: Function;
  postReportCancelCuida: Function;
  postReportSalesPlatinumCard: Function;
  postReportCancellationPlatinum: Function;
  checkSuccess: Function;
  getOperador: Function;
  loading: boolean;
  items: any;
  headerItems: any;
  header: any;
  totalPages: number;
  totalItems: number | null;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  operador: any[];
  clearForm: () => React.SetStateAction<any>;
  BENEFIT_TYPES: Array<{ id: string; name: string; value: string }>;
}

export interface ReportResponseOdonto {
  totalAdesao: number;
  vendas: {
    celular?: string;
    celular2?: string;
    cpf?: string;
    email?: string;
    nomeCliente?: string;
    nomeOperador?: string;
    statusCartao?: string;
    statusBeneficio?: string;
    qtdAdesao?: string;
    uf?: string;
    dataVenda?: string;
    tipoUsuario?: string;
    dependenteList?: ReportResponseDependent[];
  }[];
}

export interface ReportResponseCuida {
  nomeCliente?: string;
  cpf?: string;
  dataVenda?: string;
  operador?: string;
  loja?: string;
  email?: string;
  celular?: string;
  celular2?: string;
  uf?: string;
  statusCartao?: string;
  statusBeneficio?: string;
  tipoSeguro?: string;
  numeroBilhete?: string;
  numeroSorte?: string;
  idSegurado?: string;
  idSolicitacao?: string;
  dataEnvioApis?: string;
}

export interface ReportResponseCancelCuida {
  cpf: string;
  dataCancelamento: string;
  dataVenda: string;
  loja: string;
  nomeCliente: string;
  operador: string;
  statusCartao: string;
  uf: string;
}
[];

export interface ReportResponseDependent {
  cpfDependente?: string;
  dataNascimento?: string;
  dataVenda?: string;
  extraido?: boolean;
  nomeOperadorDependente?: string;
  nome?: string;
  tipoUsuariobeneficio?: string;
  nomeTitular?: string;
  celularTitular?: string;
  celular2Titular?: string;
  cpfTitular?: string;
  emailTitular?: string;
  nomeOperadorTitular?: string;
  statusCartao?: string;
}

export interface ReportResponseCancelOdonto {
  data: {
    cpf: string;
    dataCancelamento: string;
    dataVenda: string;
    idAutorizador: string;
    motivoCancelamento: string;
    nomeDependente: string;
    nomeTitular: string;
    operador: string;
    origemCancelamento: string;
    statusCartao: string;
    tipo: string;
    uf: string;
  }[];
}

export interface ReportResponseCancelSafe {
  data: {
    nameClient: string;
    cpf: string;
    saleData: string;
    operator: string;
    saleShop: string;
    typeOfInsurance: string;
    cancellationDate: string;
    cardStatus: string;
    UF: string;
    reasonForCancellation: string;
    ticketNumber: string;
    insuredIDAPI: string;
    cancellationRequestIDAPI: string;
  }[];
}
