import React, { memo } from 'react';

import * as St from './styles';

interface IMessageBalloon {
  type: St.MessageType;
  text: string;
  delivered?: boolean;
}

const MessageBalloon: React.FC<IMessageBalloon> = ({
  type,
  text,
  delivered,
}) => {
  return (
    <St.Container delivered={delivered} type={type}>
      {text}
    </St.Container>
  );
};

export default memo(MessageBalloon);
