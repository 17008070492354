export const headerDowngrade = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Anuidade',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Escolha o tema',
    maxChar: 60,
    textAlign: 'center',
  },
];

export const headerUpgrade = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Anuidade',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Escolha o tema',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Celular Zeuss',
    maxChar: 30,
    textAlign: 'center',
  },
];

export const headerListPlan = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Adesão desde',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Tema',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Anuidade',
    maxChar: 30,
    textAlign: 'center',
  },
];

export const headerCardDecision = [
  {
    id: 1,
    value: 'Plano Atual',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Novo Plano',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Novo Tema',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Anuidade',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Celular Zeuss',
    maxChar: 30,
    textAlign: 'center',
  },
];
