import styled from 'styled-components';

interface IData {
  border: boolean;
  hasAction?: boolean;
  marginLeft?: number;
  maxChar?: number;
  scratched?: boolean;
  reportView?: boolean;
  fontsize?: string;
}

export const Data = styled.div<IData>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: ${(p) => (p.hasAction ? '1.15' : '1')};
  /* max-width: 300px; */
  padding: 18px;
  white-space: inherit;
  text-overflow: nowrap;
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};

  position: relative;

  color: #4a4b4a;
  font-size: ${(p) => (p.fontsize ? p.fontsize : '1rem')};
  text-align: start;
  border-right: ${({ border }) => (border ? '1px' : '0')} solid #e8e8e8;

  h4 {
    font-weight: 400;
    margin: 0 0 14px 0;
    font-size: ${(p) => p.reportView && '13px'};
  }

  span {
    text-decoration: ${({ scratched }) =>
      scratched ? 'line-through' : 'none'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
    font-size: ${(p) => p.reportView && '13px'};

    /* :hover {
      ${({ maxChar }) => maxChar && `width:100%`};
    } */
  }

  div {
    position: absolute;
    right: ${(p) => (p.hasAction ? '8px' : '0px')};
    margin-top: ${(p) => (p.hasAction ? '2px' : '0px')};
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  height: 100%;
`;

interface IListRowBullet {
  color?: string;
}

export const ListRowBullet = styled.div<IListRowBullet>`
  background-color: ${(p) => (p.color ? p.color : 'transparent')};
  border-radius: 50px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: -10px;
`;

export const ListRowBulletContainer = styled.div`
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  padding: 2px;
  margin-top: 30px;
  margin-left: -14px;
  position: absolute;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #cccc;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: flex;
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  left: -15px;
  top: 45px;
`;
