/* eslint-disable react/jsx-curly-newline */
import { useCallback, useContext, useEffect, useState } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { BsPersonPlusFill, BsTrash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Endpoints from '~/services/endpoints';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { icons, components } from '~/assets/index';
import Dialog from '~/components/dialog';
import { SharedBenefitServiceContext } from './sharedBenefitServiceContext';
import {
  ContractedBenefits,
  IClientBenefit,
  ILoadingBenefitTypes,
  LeadBenefitsRoot,
  LeadHistoric,
  OdontoDetails,
} from '../../Interfaces/benefitInterfaces';
import SharedServiceContext from '../../Services/sharedServiceContext';
import { LeadContext } from '~/contexts/leads';
import { cpfMask, currencyMask, dateMask, phoneMask } from '~/utils/masks';
import TableList from '../../Components/tableList';
import ContainerFlex from '~/components/containerFlex';
import Button from '~/components/button';
import Input, { MaskTypes } from '~/components/input';
import * as St from '../../Services/styles';
import {
  headerAddDependent,
  headerBenefitDetails,
} from '../../Headers/benefitHeaders';
import { ValidatorsNames, dateValidator } from '~/utils/validators';
import Constants from '~/utils/constants';

const SharedBenefitServiceProvider = ({ children }) => {
  const history = useHistory();
  const {
    leads,
    removeDependent,
    addDependent,
    isencao,
    setIsencao,
    setLeads,
    handleBenefitIsencao,
    addLeadData,
  } = useContext(LeadContext);
  const { leadDetail } = leads;
  const operator = JSON.parse(localStorage.getItem('@NovaEra:user'));
  const [contracted, setContracted] = useState<any>([]);
  const [notContracted, setNotContracted] = useState<any>([]);
  const [odontoDetails, setOdontoDetails] = useState([]);
  const [contractedBenefits, setContractedBenefits] = useState([]);
  const [notContractedBenefits, setNotContractedBenefits] = useState([]);
  const [hasContractedOdonto, setHasContractedOdonto] = useState(false);
  const [odontoValue, setOdontoValue] = useState(0);
  const [messageActive, setMessageActive] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenDependent, setModalOpenDependent] = useState(false);
  const [modalDescriptionOpen, setModalDescriptionOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loadingDecisionBenefit, setLoadingDecisionBenefit] = useState(false);
  const [dependentList, setDependentList] = useState([]);
  const [formDependent, setFormDependent] = useState({
    nome: '',
    cpf: cpfMask(''),
    phone: phoneMask(''),
    dtNasc: dateMask(''),
  });
  const {
    cpfGlobal,
    product,
    event,
    clientData,
    eventId,
    file,
    fileName,
    opinion,
  } = useContext(SharedServiceContext);

  const addOdontoDependent = async () => {
    await mountBenefitDetails('dependent');
    setModalOpenDependent(true);
  };

  const removeDependentFromList = useCallback(
    (cpf) => {
      removeDependent(cpf);

      setDependentList((prev) => {
        return prev.filter((subarray) => {
          return !subarray.some((obj) => obj.unmasked === cpf);
        });
      });
    },
    [dependentList],
  );

  const addDependentToList = () => {
    const { cpf, dtNasc, nome, phone } = formDependent;
    const textAlign = 'center';
    const maxChar = 30;
    addDependent({
      cpf: cpf?.unmasked,
      dataNascimento: dtNasc.masked.split('/').reverse().join('-'),
      nome,
      telefone: phone.unmasked,
    });

    const item = [
      {
        value: nome,
        textAlign,
        maxChar,
      },
      {
        value: cpf?.masked,
        textAlign,
        maxChar,
        type: 'cpf',
        unmasked: cpf?.unmasked,
      },
      {
        value: dtNasc?.masked,
        textAlign,
        maxChar,
      },
      {
        value: phone?.masked,
        textAlign,
        maxChar,
      },
      {
        value: cpf?.unmasked,
        textAlign,
        maxChar,
        searchAction: true,
        icon: <BsTrash onClick={() => removeDependentFromList(cpf.unmasked)} />,
      },
    ];
    setDependentList((prev) => [...prev, item]);
    setFormDependent({
      cpf: cpfMask(''),
      dtNasc: dateMask(''),
      nome: '',
      phone: phoneMask(''),
    });
  };

  const getEventUniqueness = useApi<LeadHistoric[]>(
    Endpoints.events.getLeadUniqueness,
    ApiMethod.POST,
  );

  const getContractedBenefits = useApi<ContractedBenefits[]>(
    Endpoints.events.getClientContractedBenefits.replace(
      'PARAM_CPF',
      cpfGlobal,
    ),
    ApiMethod.GET,
  );
  const getNotContractedBenefits = useApi<ContractedBenefits[]>(
    Endpoints.events.getClientNotContractedBenefits.replace(
      'PARAM_CPF',
      cpfGlobal,
    ),
    ApiMethod.GET,
  );

  const getLeadBenefits = useApi<LeadBenefitsRoot>(
    Endpoints.events.getLeadBenefits.replace('PARAM_ID', eventId),
    ApiMethod.GET,
  );

  const getOdontoDetails = useApi<OdontoDetails[]>(
    Endpoints.events.getOdontoDetails
      .replace('PARAM_CPF', cpfGlobal)
      .replace('PARAM_NOME', clientData.nome ?? clientData.cliente),
    ApiMethod.GET,
  );

  const requestSale = useApi(Endpoints.events.postEvent, ApiMethod.POST);

  const getContractedBenefitsClient = async () => {
    try {
      const response = await getContractedBenefits.callApi();
      if (response.status >= 200 && response.status < 300) {
        if (response.data?.length === 0) setContracted([]);
        else setContracted(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getNotContractedBenefitsClient = async () => {
    try {
      const response = await getNotContractedBenefits.callApi();
      if (response.status >= 200 && response.status < 300) {
        setNotContracted(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const searchUser = async () => {
    try {
      const response = await getEventUniqueness.callApi({
        cpf: cpfGlobal,
        produto: product?.id,
        tipoEvento: event?.id,
      });
      if (response.status >= 200 && response.status < 300) {
        history.push('/sales/eventCenter/leadSolicitation', {
          cpfGlobal,
          product,
          event,
        });
      } else {
        setMessageActive(true);
        setMessageText(response.mensagem);
      }
    } catch (error) {
      console.log(error);
      null;
    }
  };

  const mountBenefitDetails = async (flux: string) => {
    const { status, data } = await getOdontoDetails.callApi();
    if (status === 200) {
      const newList = [];
      data.forEach((row) => {
        const item = [
          {
            border: true,
            value: row?.tipoAdesao,
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            value: row?.nome,
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            value: `R$ ${currencyMask(row?.valorParcela)}`,
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            value: row?.dataAdesao.split('-').reverse().join('/'),
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            value: row?.status,
            maxChar: 30,
            textAlign: 'center',
          },
        ];
        newList.push(item);
      });
      setOdontoDetails(newList);
    }
    if (flux !== 'dependent') setModalOpen(true);
  };

  useEffect(() => {
    if (leadDetail) {
      if (contracted && notContracted) {
        let contractedOdonto = false;
        if (contracted && contracted?.length) {
          const newList = [];

          contracted.forEach((benefit) => {
            const isOdonto = benefit.beneficio === 'TeuCard Odonto';
            if (isOdonto) {
              setHasContractedOdonto(true);
              contractedOdonto = true;
            }
            const item = [
              {
                border: true,
                value: benefit?.beneficio,
                maxChar: 30,
                textAlign: 'center',
              },
              {
                border: true,
                value: benefit?.dataAdesao.split('-').reverse().join('/'),
                maxChar: 15,
                textAlign: 'center',
              },
              {
                border: true,
                value: `R$ ${currencyMask(benefit?.valorParcela)}`,
                maxChar: 20,
                textAlign: 'center',
              },
              {
                border: true,
                value: benefit?.statusBeneficio,
                maxChar: 20,
                textAlign: 'center',
              },

              isOdonto && {
                item: benefit,
                maxChar: 8,
                textAlign: 'right',
                searchAction: mountBenefitDetails,
              },
            ];
            newList.push(item);
          });
          setContractedBenefits(newList);
        }
        if (notContracted && notContracted?.length) {
          const newList = [];

          notContracted.forEach((benefit) => {
            const id = benefit?.id;
            const isOdonto =
              id === '60e79f33140dc50008243302' ||
              benefit.beneficio === 'TeuCard Odonto';
            if (isOdonto) {
              setOdontoValue(benefit?.valorParcela);
            }
            const item = [
              {
                checkbox: true,
                value: benefit?.id,
                textAlign: 'center',
                id: benefit?.id,
              },
              {
                border: true,
                value: benefit?.beneficio,
                maxChar: 30,
                textAlign: 'center',
              },
              {
                border: true,
                value: benefit?.descricao,
                maxChar: 15,
                textAlign: 'center',
                render: () => {
                  return (
                    <St.DescContainer
                      onClick={() => {
                        setModalDescriptionOpen(true);
                        setModalMessage(benefit?.descricao);
                      }}
                    >
                      <components.Warning fontSize={12} />
                    </St.DescContainer>
                  );
                },
              },
              {
                border: true,
                value: benefit?.valorParcela,
                maxChar: 20,
                textAlign: 'center',
                type: 'money',
                contractedOdonto: isOdonto && contractedOdonto,
              },
              {
                border: true,
                maxChar: 20,
                textAlign: 'center',
                value: (
                  <>
                    {isOdonto ? (
                      <input
                        id={`checkbox-${benefit.id}`}
                        type="checkbox"
                        checked={isencao?.[benefit?.id]}
                        onChange={(e) => {
                          setIsencao((prev) => ({
                            ...prev,
                            [id]: e.target.checked,
                          }));
                          handleBenefitIsencao(id, e.target.checked);
                        }}
                      />
                    ) : null}
                  </>
                ),
              },

              isOdonto && {
                item: benefit,
                maxChar: 8,
                textAlign: 'right',
                icon: (
                  <BsPersonPlusFill
                    style={{ cursor: 'pointer' }}
                    onClick={addOdontoDependent}
                    color="#1EAB35"
                    size={25}
                  />
                ),
                searchAction: addOdontoDependent,
              },
            ];
            newList.push(item);
          });
          setNotContractedBenefits([...newList]);
        }
      }
    }
  }, [leadDetail, contracted, notContracted]);

  const mountBenefitDecision = useCallback((benefit) => {
    setModalOpen(false);

    const newList = [];
    benefit?.dependentes?.forEach((row) => {
      const item = [
        {
          border: true,
          value: row?.nome,
          maxChar: 30,
          textAlign: 'center',
        },
        {
          border: true,
          value: row?.dataNascimento.split('-').reverse().join('/'),
          maxChar: 30,
          textAlign: 'center',
        },
        {
          border: true,
          value: row.cpf,
          maxChar: 30,
          textAlign: 'center',
        },
        {
          border: true,
          value: row?.telefone,
          maxChar: 30,
          textAlign: 'center',
        },
      ];
      newList.push(item);
    });
    setOdontoDetails(newList);
    setModalOpen(true);
  }, []);

  const totalValue = () => {
    let value = leads?.totalValue;
    if (dependentList.length > 0) {
      if (leads.benefits.some((e) => e.nome === 'TeuCard Odonto')) {
        value += dependentList.length * odontoValue;
      } else {
        value = leads?.totalValue;
      }
    }

    return value;
  };

  const decisionService = async () => {
    const eventBenefits = await getLeadBenefits.callApi();

    if (eventBenefits.status === 200) {
      addLeadData('leadDetail', {
        ...leadDetail,
        ...clientData,
        ...eventBenefits.data,
      });
      if (eventBenefits.data.beneficios) {
        const newList = [];
        let newTotalValue = 0;
        eventBenefits.data.beneficios.forEach((benefit) => {
          const isOdonto = benefit.nome === 'TeuCard Odonto';
          newTotalValue += benefit?.valorParcela ?? 0;
          const item = [
            {
              checkbox: true,
              value: benefit?.idBeneficio,
              textAlign: 'center',
              id: benefit?.idBeneficio,
            },
            {
              border: true,
              value: benefit?.nome,
              maxChar: 30,
              textAlign: 'center',
            },
            {
              border: true,
              value: benefit?.descricao,
              maxChar: 15,
              textAlign: 'center',
              render: () => {
                return (
                  <St.DescContainer
                    onClick={() => {
                      setModalDescriptionOpen(true);
                      setModalMessage(benefit?.descricao);
                    }}
                  >
                    <components.Warning fontSize={12} />
                  </St.DescContainer>
                );
              },
            },
            {
              border: true,
              value: `R$ ${currencyMask(benefit?.valorParcela)}`,
              maxChar: 20,
              textAlign: 'center',
              type: 'money',
            },
            {
              border: true,
              maxChar: 20,
              textAlign: 'center',
              value: isOdonto ? (
                <input
                  id={`checkbox-${benefit.idBeneficio}`}
                  type="checkbox"
                  checked={benefit?.isencao}
                  readOnly
                />
              ) : null,
            },

            benefit?.nome === 'TeuCard Odonto' && {
              item: benefit,
              maxChar: 8,
              textAlign: 'right',
              searchAction: () => mountBenefitDecision(benefit),
            },
          ];
          newList.push(item);
        });
        setContractedBenefits(newList);
      }
    }
  };

  const validateForm = () => {
    const selectedOdonto = leads.benefits.some(
      (e) => e.nome === 'TeuCard Odonto',
    );
    if (hasContractedOdonto && selectedOdonto && !dependentList.length) {
      setModalDescriptionOpen(true);
      setModalMessage(
        'Por favor, adicione um dependente ou remova o benefício TeuCard Odonto da lista de benefícios selecionados',
      );
      return false;
    }

    return true;
  };

  const handleSelectBenefits = useCallback(
    async (selectedBenefits) => {
      let value = 0;
      selectedBenefits.forEach((item) => {
        if (item.contractedOdonto) return;
        value += item.valor;
      });
      const benefits = selectedBenefits.map((item) => {
        const { idBeneficio } = item;
        const isencaoBenefit = isencao[idBeneficio] ?? false;

        return { ...item, isencao: isencaoBenefit };
      });
      setLeads((prev) => ({
        ...prev,
        benefits,
        totalValue: value,
      }));
    },
    [isencao, dependentList],
  );

  const handleRequestSale = async () => {
    if (!validateForm()) return;

    const beneficios = leads.benefits.map((benefit) => ({
      ...benefit,
      adicionaApenasDependente:
        hasContractedOdonto &&
        benefit.idBeneficio === '60e79f33140dc50008243302',
      dependentes:
        benefit.idBeneficio === '60e79f33140dc50008243302' ||
        benefit.nome === 'TeuCard Odonto'
          ? leads.dependents
          : null,
    }));
    const reqBody = {
      beneficios,
      cpf: clientData?.cpf,
      idEvento: eventId,
      nome: clientData?.cliente,
      operador: {
        idOperador: localStorage.getItem(Constants.USER_ID),
        nome: operator?.nomeTitular,
      },
      origem: 'MANUAL',
      produto: product.id,
      tipoEvento: event.id,
    };

    const response = await requestSale.callApi(reqBody);
    if (response.status === 201) {
      setMessageText('Lead salvo com sucesso!');
      setMessageActive(true);
    } else {
      setMessageActive(true);
      setMessageText(response.mensagem);
    }
  };

  const validateDate = (date: Date) => {
    const isValid = date instanceof Date && !Number.isNaN(date.valueOf());
    return isValid;
  };

  const handlePostLeadDecision = async (aprovar: boolean) => {
    const token = localStorage.getItem(Constants.TOKEN_KEY);
    const tokenBearer = localStorage.getItem(Constants.TOKEN_BEARER);
    setLoadingDecisionBenefit(true);

    const formData = new FormData();
    formData.append('aprovar', String(aprovar));
    formData.append('idEvento', leadDetail.id ?? eventId);
    formData.append('motivo', opinion);
    formData.append('arquivo', file);

    const fileType = file?.type?.split('/')[1];

    const newNomeArquivo =
      fileType.length > 0
        ? `${fileName}.${file.type.split('/')[1]}`
        : `${fileName}.wav`;

    formData.append('nomeArquivo', newNomeArquivo);

    const config = {
      headers: {
        apikey: `token ${token}`,
        Authorization: `Bearer ${tokenBearer}`,
      },
    };
    try {
      const response = await Axios.post(
        Endpoints.events.postLeadDecision,
        formData,
        config,
      );
      if (response.status === 200) {
        setLoadingDecisionBenefit(false);
        if (aprovar) {
          setMessageText('Venda aprovada com sucesso!');
          setMessageActive(true);
        } else {
          setMessageText('Venda recusada com sucesso!');
          setMessageActive(true);
        }
      }
    } catch (err) {
      setMessageActive(true);
      setMessageText(
        err?.response?.data?.mensagem ?? 'Houve um erro, tente novamente!',
      );
      setLoadingDecisionBenefit(false);
    }
  };

  const resetBenefitContext = () => {
    setContracted([]);
    setNotContracted([]);
    setFormDependent({
      nome: '',
      cpf: cpfMask(''),
      phone: phoneMask(''),
      dtNasc: dateMask(''),
    });
    setDependentList([]);
    setContractedBenefits([]);
    setNotContractedBenefits([]);
    setHasContractedOdonto(false);
    setOdontoDetails([]);
  };

  const loadingBenefit: ILoadingBenefitTypes = {
    getContractedBenefitsClient: { loading: getContractedBenefits.loading },
    getNotContractedBenefitsClient: {
      loading: getNotContractedBenefits.loading,
    },
    postRequestSale: {
      loading: requestSale.loading,
    },
    loadingDecisionBenefit: {
      loading: loadingDecisionBenefit,
    },
  };

  return (
    <SharedBenefitServiceContext.Provider
      value={{
        searchUser,
        getContractedBenefitsClient,
        getNotContractedBenefitsClient,
        contractedBenefits,
        contracted,
        notContracted,
        notContractedBenefits,
        loadingBenefit,
        handleSelectBenefits,
        handleRequestSale,
        decisionService,
        handlePostLeadDecision,
        resetBenefitContext,
        totalValue,
      }}
    >
      {children}
      <Dialog widthInPercent={60} open={modalOpen}>
        <ContainerFlex center padding={10}>
          <St.DialogContent>
            <St.Title style={{ textAlign: 'center' }}>
              Detalhes do benefício
            </St.Title>
            <TableList
              list={odontoDetails}
              customerHeader={headerBenefitDetails}
            />
            <ContainerFlex
              row
              gap="10"
              end
              style={{ bottom: 0, right: 40, position: 'absolute' }}
            >
              <Button outlined onClick={() => setModalOpen(false)}>
                Voltar
              </Button>
            </ContainerFlex>
          </St.DialogContent>
        </ContainerFlex>
      </Dialog>
      {/* odonto add dependent */}
      <Dialog widthInPercent={60} open={modalOpenDependent}>
        <ContainerFlex center padding={10}>
          <St.DialogContent>
            <St.Title style={{ textAlign: 'center' }}>
              Solicitação de dependente
            </St.Title>
            {hasContractedOdonto && (
              <>
                <span>Detalhes do benefício</span>
                <TableList
                  list={odontoDetails}
                  customerHeader={headerBenefitDetails}
                />
              </>
            )}

            <ContainerFlex space row gap="20">
              <ContainerFlex>
                <Input
                  label="Nome"
                  name="name"
                  value={formDependent.nome}
                  onChange={(e) =>
                    setFormDependent((prev) => ({
                      ...prev,
                      nome: e.target.value,
                    }))
                  }
                />
                <Input
                  label="CPF"
                  name="cpf"
                  maxLength={14}
                  value={formDependent.cpf?.masked}
                  mask={MaskTypes.CPF}
                  onChange={(e) =>
                    setFormDependent((prev) => ({
                      ...prev,
                      cpf: e.target.value,
                    }))
                  }
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Data nascimento"
                  name="dtNasc"
                  maxLength={10}
                  value={formDependent.dtNasc?.masked}
                  mask={MaskTypes.DATE}
                  errorMessage={
                    formDependent.dtNasc?.masked?.length > 0 &&
                    !validateDate(
                      new Date(
                        formDependent.dtNasc.masked
                          ?.split('/')
                          .reverse()
                          .join('-'),
                      ),
                    ) &&
                    'Digite uma data válida'
                  }
                  onChange={(e) => {
                    dateValidator(
                      formDependent.dtNasc?.masked,
                      ValidatorsNames.dateBirth,
                    );
                    setFormDependent((prev) => ({
                      ...prev,
                      dtNasc: e.target.value,
                    }));
                  }}
                />
                <Input
                  label="Telefone"
                  name="phone"
                  maxLength={15}
                  value={formDependent.phone?.masked}
                  mask={MaskTypes.PHONE}
                  onChange={(e) =>
                    setFormDependent((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }))
                  }
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex end marginTop={10}>
              <Button
                disabled={
                  (formDependent.dtNasc?.masked?.length !== 8 &&
                    !validateDate(
                      new Date(
                        formDependent.dtNasc.masked
                          ?.split('/')
                          .reverse()
                          .join('-'),
                      ),
                    )) ||
                  !formDependent.nome?.length
                }
                onClick={() => addDependentToList()}
              >
                Incluir
              </Button>
            </ContainerFlex>
            <TableList
              list={dependentList}
              customerHeader={headerAddDependent}
            />
            <ContainerFlex row gap="10" end marginTop={10}>
              <Button outlined onClick={() => setModalOpenDependent(false)}>
                Voltar
              </Button>
              <Button onClick={() => setModalOpenDependent(false)}>
                Solicitar
              </Button>
            </ContainerFlex>
          </St.DialogContent>
        </ContainerFlex>
      </Dialog>
      <Dialog widthInPercent={60} open={modalDescriptionOpen}>
        <ContainerFlex center end padding={10}>
          <St.DialogContent>
            <St.Title style={{ textAlign: 'center', fontSize: 20 }}>
              {modalMessage}
            </St.Title>
            <ContainerFlex
              end
              row
              marginTop={60}
              style={{ bottom: 0, right: 40, position: 'absolute' }}
            >
              <Button
                onClick={() => {
                  setModalDescriptionOpen(false);
                }}
              >
                Fechar
              </Button>
            </ContainerFlex>
          </St.DialogContent>
        </ContainerFlex>
      </Dialog>
      <Dialog
        open={messageActive}
        positiveAction={() => {
          setMessageActive(false);
          history.push('/sales/eventCenter');
        }}
        message={messageText}
        positiveLabel="Ok"
        icon={icons.warning}
        widthInPixel={140}
      />
    </SharedBenefitServiceContext.Provider>
  );
};

export default SharedBenefitServiceProvider;
