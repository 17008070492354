import styled from 'styled-components';

interface IData {
  marginLeft?: number;
  maxChar?: number;
  textAlign?: string;
}

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 1px;
  width: 100%;
`;

export const headerTable = styled.thead`
  border-radius: 4px;
  line-height: 30px;
  background: #f9f9f9;
`;

export const bodyRow = styled.tr`
  box-shadow: 0px 1px 1px -1px #999;
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #a1a1a1;
  width: 1.05em;
  height: 1.05em;
  border: 0.15em solid #a1a1a1;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &:before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #e71037;
    transform-origin: center center;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }
  &:checked:before {
    transform: scale(1);
  }
`;

export const hederTitle = styled.th<IData>`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 4px;
  color: #495057;
  word-break: break-word;
  width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  line-height: 30px;
  border-bottom: 1px solid #eee;
`;

export const bodyTable = styled.tbody`
  line-height: 50px;
`;

export const bodyData = styled.td<IData>`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  color: #4a4b4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  padding-left: 2px;
`;

export const bodyDataValue = styled.span<IData>`
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
`;

export const IconRow = styled.img`
  width: 20px;
  height: 20px;
`;

export const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

interface IListRowBullet {
  color?: string;
}

export const ListRowBullet = styled.div<IListRowBullet>`
  background-color: ${(p) => (p.color ? p.color : 'transparent')};
  border-radius: 50px;
  height: 15px;
  width: 15px;
  margin: 0 0 0 3px;
`;
