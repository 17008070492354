import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  padding: 20px 37px;
  background-color: #fff;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 27px;
`;

export const Line = styled.div`
  background-color: #cccccc;
  height: 5px;
  border-radius: 4px;
`;

export const Percentage = styled.div`
  height: 100%;
  border-radius: 4px;
  max-width: 100%;
  background-color: #a186bd;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 14px 0;
  gap: 11px;
`;

export const Label = styled.h6`
  font-size: 13px;
  color: #000;
  font-weight: 400;
`;

export const Capsule = styled.div`
  background-color: #f8a72d;
  color: #fff;
  padding: 3px 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 30px;
`;
