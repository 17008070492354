/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { currencyMask } from '~/utils/masks';

export const HeaderReportWalletTransactions = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'Loja',
    maxChar: 8,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Venda',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Bandeira',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Val. cupom',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Parcelas',
    maxChar: 8,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Val. transação',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 8,
    value: 'Val. Parcela',
    maxChar: 10,
    textAlign: 'center',
  },
];

export const BodyReportWalletTransactions = (transactions: any) => {
  const items = [];

  transactions.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome ? i.nome : '-',
        maxChar: 15,
      },
      {
        value: i.nomeEstabelecimento ? i.nomeEstabelecimento : '-',
        maxChar: 12,
        textAlign: 'center',
      },
      {
        value: i.dataHora ? moment(i.dataHora).format('DD/MM/YYYY') : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.bandeira ? i.bandeira : '-',
        maxChar: 8,
        textAlign: 'center',
      },
      {
        value: i.valorCupom ? `R$ ${currencyMask(i.valorCupom)}` : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.parcela ? i.parcela : '-',
        maxChar: 9,
        textAlign: 'center',
      },
      {
        value: i.valorTransacao ? `R$ ${currencyMask(i.valorTransacao)}` : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.valorParcela ? `R$ ${currencyMask(i.valorParcela)}` : '-',
        maxChar: 10,
        textAlign: 'center',
      },
    ];

    items.push(item);
  });

  return items;
};

export const BodyReportWalletTransactionsCSV = (transactions: any) => {
  const dataCSV = [];

  transactions.forEach((csv) => {
    dataCSV.push({
      nome: csv.nome,
      operacao: csv.operacao,
      tipo: csv.tipo,
      bandeira: csv.bandeira,
      cpf: csv.cpf,
      dataHora: csv.dataHora,
      valorCupom: csv.valorCupom,
      valorParcela: csv.valorParcela,
      numeroCartao: csv.numeroCartao,
      parcela: csv.parcela,
      valorTransacao: csv.valorTransacao,
      idTransacao: csv.idTransacao,
      idAutorizador: csv.idAutorizador,
      nsuAutorizador: csv.nsuAutorizador,
      nsuSitef: csv.nsuSitef,
      nsuTef: csv.nsuTef,
      nsuREDECaptura: csv.nsuREDECaptura,
      terminal: csv.terminal,
      estabelecimento: csv.estabelecimento,
      nomeEstabelecimento: csv.nomeEstabelecimento,
      uf: csv.uf,
    });
  });
  return dataCSV;
};
