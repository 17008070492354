import logoMedium from './icons/logo-medium.png';
import logoLarge from './icons/logo-large.png';
import IconTick from './icons/tick.png';
import warning from './icons/warning.png';
import arrowLeft from './icons/arrow-left.png';
import arrowRight from './icons/arrow-right.png';
import sendButton from './icons/send-button.png';
import blind from './icons/blind.png';
import check from './icons/check.png';
import checkGreen from './icons/check-green.png';
import checkYellow from './icons/check-yellow.png';
import noBlind from './icons/no-blind.png';
import arrowDown from './icons/arrow-down.png';
import userAvatar from './icons/user-avatar.png';
import document from './icons/document.png';
import card from './icons/card.png';
import inputEdit from './icons/input_edit.png';
import exclamation from './icons/exclamation.png';
import confirmation from './icons/confirmation.png';
import tableDocumentFront from './icons/table_document_front.png';
import tableDocumentBack from './icons/table_document_back.png';
import printer from './icons/printer.png';
import contract from './icons/contract.png';
import searchIcon from './icons/search.png';
import fileDelete from './icons/file-delete.png';
import shop from './icons/shop.png';
import web from './icons/web.png';
import filesIcon from './icons/files.png';
import uploadFileIcon from './icons/fileUpload.png';
import handshake from './icons/handshake.png';
import cancel from './icons/cancel.png';
import arrowBlack from './icons/arrow-black.png';
import blueCard from './icons/blue-card.png';
import redCard from './icons/red-card.png';
import roseCard from './icons/rose-card.png';
import whiteCard from './icons/white-card.png';
// eslint-disable-next-line import/no-duplicates
import trashCan from './svg/delete.svg';
import trashCanRed from './svg/delete-red.svg';
import reload from './icons/reload.png';
import home from './icons/home.png';
import goldMedal from './svg/gold-medal.svg';
import silverMedal from './svg/silver-medal.svg';
import bronzeMedal from './svg/bronze-medal.svg';

import platinum0 from './svg/platinum0.svg';
import platinum1 from './svg/platinum1.svg';
import platinum2 from './svg/platinum2.svg';
import platinum3 from './svg/platinum3.svg';

import emojiAtivo from './svg/ativo.svg';
import emojiCancelado from './svg/cancelado.svg';
import emojiPendente from './svg/pendente.svg';
import emojiSuspenso from './svg/suspenso.svg';
import emojiSemAdesao from './svg/sem-adesao.svg';
import emojiNovaAdesao from './svg/nova-adesao.svg';

import allCards from './svg/allCards.svg';

import { ReactComponent as ArrowDown } from './svg/arrow-down.svg';
import { ReactComponent as ArrowDownWhite } from './svg/arrow-down-white.svg';
import { ReactComponent as Menu } from './svg/menu.svg';
import { ReactComponent as Close } from './svg/close.svg';
import { ReactComponent as Warning } from './svg/warning.svg';
import { ReactComponent as Search } from './svg/search.svg';
// eslint-disable-next-line import/no-duplicates
import { ReactComponent as TrashCan } from './svg/delete.svg';
import { ReactComponent as PosTerminal } from './svg/pos-terminal.svg';

export const icons = {
  card,
  inputEdit,
  exclamation,
  confirmation,
  tableDocumentFront,
  tableDocumentBack,
  document,
  printer,
  fileDelete,
  searchIcon,
  filesIcon,
  uploadFileIcon,
  logoMedium,
  logoLarge,
  IconTick,
  warning,
  arrowLeft,
  arrowRight,
  check,
  sendButton,
  blind,
  noBlind,
  arrowDown,
  userAvatar,
  contract,
  shop,
  web,
  handshake,
  checkGreen,
  checkYellow,
  cancel,
  arrowBlack,
  blueCard,
  redCard,
  whiteCard,
  roseCard,
  trashCan,
  trashCanRed,
  reload,
  home,
  goldMedal,
  silverMedal,
  bronzeMedal,
  emojiAtivo,
  emojiCancelado,
  emojiNovaAdesao,
  emojiPendente,
  emojiSemAdesao,
  emojiSuspenso,
  platinum0,
  platinum1,
  platinum2,
  platinum3,
  allCards,
};

export const components = {
  ArrowDown,
  ArrowDownWhite,
  Menu,
  Close,
  Warning,
  Search,
  TrashCan,
  PosTerminal,
};
