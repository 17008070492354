import {
  MdAssessment,
  MdNoteAdd,
  MdOutlineAdUnits,
  MdOutlineAutoGraph,
  MdOutlinePeopleAlt,
  MdOutlineRequestQuote,
  MdOutlineTableView,
  MdOutlineTopic,
  MdPointOfSale,
} from 'react-icons/md';
import Constants from '~/utils/constants';
import { components, icons } from '~/assets';
import * as St from './style';

export const routesConfig = [
  {
    label: 'Dashboard B.I.',
    icon: <MdOutlineAutoGraph />,
    route: '/homeDash',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_OPERATOR,
    ],
    subMenu: [
      {
        label: 'Home Dashboard',
        route: '/homeDash',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_OPERATOR,
        ],
      },
      {
        label: 'Fechamento Fatura',
        route: '/fechamentoFatura',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_OPERATOR,
        ],
      },
      {
        label: 'Propostas',
        route: '/dashDetailsProposal',
        roles: [],
      },
      {
        label: 'Base de Clientes',
        route: '/dashDetailsClientBase',
        roles: [],
      },
      {
        label: 'Benefícios',
        route: '/dashDetailsBenefitsRealTime',
        roles: [],
      },
      {
        label: 'Propostas',
        route: '/dashDetailsProposalRealTime',
        roles: [],
      },
      {
        label: 'Odonto',
        route: '/dashDetailsAdhesions',
        roles: [],
      },
      {
        label: 'Faturas Pagas',
        route: '/faturasPagas',
        roles: [],
      },
      {
        label: 'Histórico/Projeção',
        route: '/dashProjection',
        roles: [],
      },
      {
        label: 'Faturas em Atraso',
        route: '/faturasEmAtraso',
        roles: [],
      },
      {
        label: 'Créditos Disponíveis',
        route: '/creditosDisponiveis',
        roles: [],
      },
    ],
  },
  {
    label: 'Consulta Transação',
    icon: <MdOutlineRequestQuote />,
    route: '',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
    subMenu: [
      {
        label: 'Carteira Digital',
        route: '/consult-transaction/digital-wallet',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_RETAGUARDA,
          Constants.ROLE_MESA_CREDITO,
        ],
      },
      {
        label: 'Carteira Digital',
        route: '/consult-transaction/digital-wallet/detail',
        roles: [],
      },
      {
        label: 'Cartão Teucard',
        route: '/consult/transaction',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_RETAGUARDA,
          Constants.ROLE_MESA_CREDITO,
        ],
      },
      {
        label: 'Cartão Teucard',
        route: '/consult/transaction/detail',
        roles: [],
      },
    ],
  },

  {
    label: 'Consulta',
    icon: <MdOutlineRequestQuote />,
    route: '',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
    subMenu: [
      {
        label: 'Cliente',
        route: '/consult/user',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_RETAGUARDA,
        ],
      },
      {
        label: 'Cliente',
        route: '/consult/user/detail',
        roles: [],
      },
      {
        label: 'Histórico empréstimo',
        route: '/consult/user/history-loan',
        roles: [],
      },
      {
        label: 'Disputa',
        route: '/consult/dispute',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Biometria Facial',
        route: '/consult/user/biometrics',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_RETAGUARDA,
        ],
      },
      {
        label: 'Bometria Facial',
        route: '/consult/user/biometrics/detail',
        roles: [],
      },
      {
        label: 'Disputa',
        route: '/consult/dispute/table',
        roles: [],
      },
      {
        label: 'Inclusão de Compra',
        route: '/consult/disable/detail',
        roles: [],
      },
    ],
  },
  {
    label: 'Mesa de Crédito',
    icon: <MdOutlineTableView />,
    route: '',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_OPERATOR,
    ],
    subMenu: [
      {
        label: 'Análise de Propostas',
        route: '/credit-table/proposal',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_OPERATOR,
        ],
      },
      {
        label: 'Análise de Propostas',
        route: '/credit-table/proposal/detail',
        roles: [],
      },
      {
        label: 'Resumo da proposta',
        route: '/credit-table/proposal/proposal-resume',
        roles: [],
      },
      {
        label: 'Relatório',
        route: '/credit-table/report',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Relatório',
        route: '/credit-table/report/detail',
        roles: [],
      },
      {
        label: 'Reanálise de Limite - Solicitação',
        route: '/credit-table/limit/solicitation',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_RETAGUARDA,
        ],
      },
      {
        label: 'Reanálise de Limite - Solicitação',
        route: '/credit-table/limit/solicitation/detail',
        roles: [],
      },
      {
        label: 'Reanálise de Limite - Decisão',
        route: '/credit-table/limit/decision',
        roles: [Constants.ROLE_ADMIN],
      },
      {
        label: 'Reanálise de Limite - Decisão',
        route: '/credit-table/limit/decision/detail',
        roles: [],
      },
      {
        label: 'Reativar - Decisão',
        route: '/credit-table/reactivate/decision',
        roles: [Constants.ROLE_ADMIN],
      },
      {
        label: 'Reativar - Decisão',
        route: '/credit-table/reactivate/decision/detail',
        roles: [],
      },
    ],
  },
  {
    label: 'Fatura',
    icon: <MdOutlineTopic />,
    route: '',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_ASSESSORIA,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_OPERATOR,
      Constants.ROLE_MESA_CREDITO,
    ],
    subMenu: [
      {
        label: 'Consultar Fatura',
        route: '/invoice',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_ASSESSORIA,
          Constants.ROLE_OPERATOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_RETAGUARDA,
        ],
      },
      {
        label: 'Consultar Fatura',
        route: '/invoice/detail',
        roles: [],
      },
      {
        label: 'Antecipação de financiamento',
        route: '/invoice/antecipation',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_RETAGUARDA,
          Constants.ROLE_MESA_CREDITO,
        ],
      },
      {
        label: 'Antecipação de compra',
        route: '/invoice/antecipation/purchase',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_RETAGUARDA,
          Constants.ROLE_MESA_CREDITO,
        ],
      },
      {
        label: 'Antecipação de compra',
        route: '/invoice/antecipation/purchase/history',
        roles: [],
      },
      {
        label: 'Inclusão de Transação',
        route: '/consult/disable',
        roles: [],
      },
      {
        label: 'Transação - Solicitação',
        route: '/invoice/purchase-inclusion',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_MESA_CREDITO,
        ],
      },
      {
        label: 'Transação - Solicitação',
        route: '/invoice/purchase-inclusion/detail',
        roles: [],
      },
      {
        label: 'Transação - Decisão',
        route: '/invoice/purchase-decision',
        roles: [Constants.ROLE_ADMIN],
      },
      {
        label: 'Transação - Decisão',
        route: '/invoice/purchase-decision/detail',
        roles: [],
      },
    ],
  },
  {
    label: 'Vendas',
    icon: <MdPointOfSale />,
    route: '',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_RETAGUARDA,
      Constants.ROLE_MESA_CREDITO,
    ],
    subMenu: [
      {
        label: 'Vendas',
        route: '/sales',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_ASSESSORIA,
          Constants.ROLE_OPERATOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_RETAGUARDA,
        ],
      },
      {
        label: 'Link de indicação',
        route: '/sales/indicationLink',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_ASSESSORIA,
          Constants.ROLE_OPERATOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_RETAGUARDA,
        ],
      },
      {
        label: 'Cadastro de anfitrião',
        route: '/sales/indicationLinkCreate',
        roles: [],
      },
      {
        label: 'Detalhes do anfitrião',
        route: '/sales/indicationLinkEdit',
        roles: [],
      },
      {
        label: 'Meus Eventos',
        route: '/sales/eventCenter',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_ASSESSORIA,
          Constants.ROLE_OPERATOR,
          Constants.ROLE_MESA_CREDITO,
          Constants.ROLE_RETAGUARDA,
        ],
      },
      {
        label: 'Solicitação de Evento',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/requestEvent',
        roles: [],
      },
      {
        label: 'Detalhes da Solicitação',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/SolicitationDetails',
        roles: [],
      },
      {
        label: 'Detalhes de lead conta',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/accountLead/details',
        roles: [],
      },
      {
        label: 'Downgrade',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/downgradeDecision',
        roles: [],
      },
      {
        label: 'Upgrade',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/upgradeDecision',
        roles: [],
      },
      {
        label: 'Solicitação de Lead',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/leadSolicitation',
        roles: [],
      },
      {
        label: 'Solicitação de Upgrade',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/upgradeSolicitation',
        roles: [],
      },
      {
        label: 'Decisão',
        icon: <MdPointOfSale />,
        route: '/sales/eventCenter/leadDecision',
        roles: [],
      },
    ],
  },
  {
    label: 'TeuPag',
    icon: <components.PosTerminal />,
    route: '/teu-pag',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_MESA_CREDITO,
      Constants.ROLE_RETAGUARDA,
    ],
  },
  {
    label: 'TeuPag',
    route: '/teu-pag/detail',
    roles: [],
  },
  {
    label: 'Vendas',
    icon: <MdPointOfSale />,
    route: '/sales/detail',
    roles: [],
  },
  {
    label: 'Usuário',
    icon: <MdOutlinePeopleAlt />,
    route: '',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
    subMenu: [
      {
        label: 'Funcionário',
        route: '/consult/employee',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Cliente',
        route: '/consult/costumer',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
    ],
  },

  {
    label: 'Usuario',
    icon: <MdOutlinePeopleAlt />,
    route: '/consult/employee/register',
    roles: [],
    bread: [
      { to: '/consult/employee', label: 'Funcionário' },
      {
        to: '/consult/employee/register',
        label: 'Registrar',
        goBack: true,
      },
    ],
  },
  {
    label: 'Usuário',
    route: '/consult/employee/detail',
    roles: [],
    bread: [
      { to: '/consult/employee', label: 'Usuário' },
      { to: '/consult/employee', label: 'Funcionário', goBack: true },
      { to: '/consult/employee/detail', label: 'Detalhes', disabled: true },
    ],
  },
  {
    label: 'Usuário',
    route: '/consult/costumer/detail',
    roles: [],
    bread: [
      { to: '/consult/costumer', label: 'Usuário' },
      { to: '/consult/costumer', label: 'Cliente', goBack: true },
      { to: '/consult/costumer/detail', label: 'Detalhes', disabled: true },
    ],
  },
  {
    label: 'Acesso ao App',
    icon: <MdOutlinePeopleAlt />,
    route: '/consult/costumer/appAccess/detail',
    roles: [],
    bread: [
      { to: '/consult/costumer', label: 'Usuário' },
      { to: '/consult/costumer', label: 'Cliente', goBack: true },
      {
        to: '/consult/employee/detail',
        label: 'Acesso ao app',
        disabled: true,
      },
    ],
  },
  {
    label: 'Usuário',
    icon: <MdOutlinePeopleAlt />,
    route: '/consult/reactivate-user/detail',
    roles: [],
  },
  {
    label: 'Relatório',
    icon: <MdAssessment />,
    route: '',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
    subMenu: [
      {
        label: 'Benefícios',
        route: '/report/benefit',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Carteira Digital',
        route: '/report/wallet',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Faturas',
        route: '/report/invoice',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Financeiro',
        route: '/report/financial',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Proposta',
        route: '/report/proposal',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Cartões',
        route: '/report/card',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Emprestimos',
        route: '/report/loan',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Eventos',
        route: '/report/events',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Outros',
        route: '/report/others',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
    ],
  },
  {
    label: 'Arquivos',
    icon: <MdNoteAdd />,
    route: '',
    roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
    subMenu: [
      {
        label: 'Importação',
        route: '/file/upload',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Histórico',
        route: '/file/history',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Cobrança',
        route: '/file/charge',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Positivados',
        route: '/file/positive',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Propostas',
        route: '/file/proposal',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Limites ativos',
        route: '/file/activeLimits',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Base de clientes',
        route: '/file/customer-base',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Contas a receber',
        route: '/file/invoice-file',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
      {
        label: 'Cancelamento Inatividade',
        route: '/file/cancel-inactivity',
        roles: [Constants.ROLE_ADMIN, Constants.ROLE_SUPERVISOR],
      },
    ],
  },
  {
    label: 'Recursos',
    icon: <MdOutlineTableView />,
    route: '',
    roles: [
      Constants.ROLE_ADMIN,
      Constants.ROLE_SUPERVISOR,
      Constants.ROLE_OPERATOR,
    ],
    subMenu: [
      {
        label: 'Campanha',
        route: '/campanha',
        roles: [
          Constants.ROLE_ADMIN,
          Constants.ROLE_SUPERVISOR,
          Constants.ROLE_OPERATOR,
        ],
      },
      {
        label: 'Detalhes da campanha',
        route: '/lista-cliente-campanha',
        roles: [],
      },
      {
        label: 'Nova campanha',
        route: '/criar-campanha',
        roles: [],
      },
      {
        label: 'Produtos',
        route: '/management-tools/products',
        roles: [Constants.ROLE_ADMIN],
      },
      {
        route: '/management-tools/personalize-app-home',
        label: 'Personalizar home app',
        roles: [Constants.ROLE_ADMIN],
      },
    ],
  },
];
