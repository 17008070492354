import styled from 'styled-components';

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-left: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const IconCard = styled.img`
  height: 25px;
`;
