import styled, { css } from 'styled-components';

const DefaultTabStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin: 0 2px 0 2px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
`;

export const Tabs = styled.div<{ selected: boolean }>`
  ${DefaultTabStyle}
  border: 1px solid primary;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  background-color: ${({
    selected,
    theme: {
      colors: { primary },
    },
  }) => (selected ? primary : 'white')};

  :hover {
    background-color: ${({ selected }) =>
      selected ? 'filter: brightness(3);' : '#e6e6e6'};
  }
`;

export const CustomTabs = styled.div`
  border: 1px solid #e6e6e6;
  ${DefaultTabStyle}

  :hover {
    background-color: ${({
      theme: {
        colors: { primary },
      },
    }) => primary};
    color: #fff;
  }
`;

export const FirstTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 36px;
  margin-right: 2px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  border: 1px solid #e6e6e6;

  :hover {
    background-color: ${({
      theme: {
        colors: { primary },
      },
    }) => primary};
    color: #fff;
  }
`;

export const LastTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 36px;
  margin-left: 2px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  border: 1px solid #e6e6e6;

  :hover {
    background-color: ${({
      theme: {
        colors: { primary },
      },
    }) => primary};
    color: #fff;
  }
`;
