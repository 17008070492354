import styled from 'styled-components';

interface showSidebar {
  isOpen: boolean;
}

export const Sidebar = styled.div<showSidebar>`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '-180px' : '0')};
  width: 260px;
  height: 100%;
  background: #e4003a;
  transition: all 0.5s;
`;

export const Logo = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Menu = styled.div<showSidebar>`
  position: absolute;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '180px' : '0px')};
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  background: #e4003a;
  box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 5%);

  svg {
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    margin-left: 30px;
    width: 24px;
    height: 24px;
  }
`;

export const Navbar = styled.ul`
  height: calc(100% - 60px);
  margin-top: 60px;
  overflow: auto;
  box-shadow: 0px 4px 16px 16px rgb(0 0 0 / 5%);

  /* width */
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #e4003a;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid #e4003a;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      div {
        min-width: 80px;
        height: 50px;
        text-align: center;
        line-height: 60px;

        svg {
          font-size: 24px;
          color: #fff;
        }
      }

      span {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
      }
    }

    div {
      min-width: 48px;
      height: 50px;
      text-align: center;
      line-height: 60px;

      svg {
        font-size: 24px;
        color: #fff;
      }
    }

    &:hover {
      background: #bc0030;
    }
  }
`;

export const IconItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    div {
      min-width: 48px;
      height: 50px;
      text-align: center;
      line-height: 60px;

      svg {
        font-size: 24px;
        color: #fff;
      }

      span {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
      }
    }
  }

  div {
    min-width: 48px;
    height: 50px;
    text-align: center;
    line-height: 60px;

    svg {
      font-size: 24px;
      color: #fff;
    }
  }
`;

export const SubMenu = styled.ul`
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #bc0030;
  display: flex;
  flex-direction: column;

  a {
    font-size: 15px;
    color: #fff;
    padding: 8px 0;
  }
`;

export const Profile = styled.div<showSidebar>`
  width: 260px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? 'flex-end' : '')};
  position: fixed;
  bottom: 0;
  background: #e4003a;
  box-shadow: 4px 0px 16px 0px rgb(0 0 0 / 5%);

  main {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: #fff;
      margin-bottom: 5px;
    }

    span {
      font-size: 0.75rem;
      font-weight: 600;
      color: #fff;
    }
  }
`;

export const Logout = styled.div`
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
  cursor: pointer;
  box-shadow: 0 1px 4px -2px black, inset 0 2px 1px -1px rgb(255 255 255 / 10%);

  svg {
    font-size: 24px;
    color: #e4003a;
  }
`;
