import styled from 'styled-components';

export interface IGrid {
  gap?: number;
  xs?: number;
  md?: number;
  sm?: number;
  lg?: number;
  xl?: number;
  backgroundColor?: string;
}

const Grid = styled.div<IGrid>`
  height: auto;
  max-width: 100%;
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : 'white')};

  display: grid;
  grid-template-columns: repeat(${(p) => (p.xs ? p.xs : 1)}, 1fr);
  grid-gap: ${(p) => (p.gap ? `${p.gap}px` : '0px')};

  @media (min-width: 1141px) {
    grid-template-columns: repeat(
      ${(p) => (p.xl ? p.xl : p.lg || p.md || p.sm || p.xs || 1)},
      1fr
    );
  }

  @media (max-width: 1140px) {
    grid-template-columns: repeat(
      ${(p) => (p.lg ? p.lg : p.md || p.sm || p.xs || 1)},
      1fr
    );
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(
      ${(p) => (p.md ? p.md : p.sm || p.xs || 1)},
      1fr
    );
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(${(p) => (p.sm ? p.sm : p.xs || 1)}, 1fr);
  }

  @media (max-width: 540px) {
    grid-template-columns: repeat(${(p) => (p.xs ? p.xs : 1)}, 1fr);
  }
`;

export default Grid;
