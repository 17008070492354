import { darken } from 'polished';
import styled from 'styled-components';

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;

export const DialogContent = styled.div`
  width: 100%;
  height: 550px;
  padding: 24px;
  border-radius: 12px;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;

export const DescContainer = styled.div`
  border: none;
  border-radius: 0.375rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${darken(0.045, '#ffffff')};
  }

  svg {
    width: 18px;
  }
`;

export const Message = styled.div`
  width: 100%;
  flex: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;
