import React from 'react';
import Chart from 'react-apexcharts';
import { abbreviateNumber, currencyMask } from '~/utils/masks';

import * as S from '../../styles';

interface IProps {
  title?: string;
  widthGraphic?: number;
  heightGraphic?: number;
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
  graphicType?: string;
  type?: string;
  line?: boolean;
}

const BoxHistoric: React.FC<IProps> = ({
  title,
  categories,
  series,
  widthGraphic = 760,
  heightGraphic = 200,
  graphicType,
  type,
  line,
}) => {
  const buildSeries = () => {
    let response = null;
    console.log(series);
    if (series === undefined || series.length <= 0) {
      response = [
        {
          name: '',
          data: [0],
        },
      ];
    } else {
      response = series;
    }
    return response;
  };

  const options = {
    series: series || [0],
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter(val) {
          return type === 'PAGAMENTO_FATURAS' || type === 'FATURAMENTO_COMPRAS'
            ? `R$ ${currencyMask(val)}`
            : Math.floor(val);
        },
      },
    },
    colors: line
      ? ['#000', '#F3C2A9', '#FFD966', '#AAB9E9']
      : ['#C3E6CB', '#F3C2A9', '#FFD966', '#AAB9E9'],
    labels: [0],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontWeight: 400,
      labels: {
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: undefined,
      width: 2.5,
      dashArray: 0,
    },
    xaxis: {
      categories: categories || ['0'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: true,
      },
      position: 'bottom',
    },
    yaxis: {
      series,
      min: 1,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        formatter(val) {
          return type === 'PAGAMENTO_FATURAS' || type === 'FATURAMENTO_COMPRAS'
            ? `R$ ${abbreviateNumber(val)}`
            : Math.floor(val);
        },
      },
    },
    theme: {
      monochrome: {
        enabled: false,
        color: '#E697FF',
        shadeTo: 'light',
        shadeIntensity: 0.65,
      },
    },
    responsive: [
      {
        breakpoint: 900,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  } as any;

  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>
            {`Histórico de
            ${title}
            ${graphicType ? ` por ${graphicType}` : ''}`}
          </h2>
        </header>
        <S.ItemGraphic>
          <Chart
            categories={categories}
            options={options}
            series={buildSeries()}
            type="line"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxHistoric;
