import { useContext } from 'react';
import {
  IProposalReport,
  ProposalReportContext,
} from './ProposalReportContext';

export function useReportProposal(): IProposalReport {
  const context = useContext(ProposalReportContext);

  if (!context) {
    throw new Error('useProposal must be used within an ProposalContext');
  }

  return context;
}
