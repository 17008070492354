import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ContainerFlex from '~/components/containerFlex';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Container from '~/components/container';
import BoxLateInvoice from '../components/boxLateInvoiceGraphic';

interface IProps {
  type: string;
  dataFinal: string;
  dataInicial: string;
  subTitle: string;
  range: string;
  campoOrdenado: string;
  tipoOrdenacao: string;
  dataHoraColeta: string;
}

interface IBoxGraphcic {
  categories: string[];
  dataHoraColeta: string;
  series: {
    name: string;
    data: number[];
  }[];
}

const DashDetailsLateInvoice: React.FC = () => {
  const [dataResponse, setDataResponse] = useState<IBoxGraphcic>({
    dataHoraColeta: '',
    categories: [],
    series: [
      {
        name: '',
        data: [],
      },
    ],
  });

  const { state } = useLocation<IProps>();

  const RequestGetStatusLateInvoice = useApi(
    Endpoints.graphc.getLateInvoiceDetails.replace(
      'PARAM_DATA_ATRASO',
      state.dataHoraColeta,
    ),
    ApiMethod.GET,
  );

  useEffect(() => {
    populateGraphic();
  }, []);

  const populateGraphic = async () => {
    try {
      const response = await RequestGetStatusLateInvoice.callApi();

      if (response.status >= 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphcic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Loading status={RequestGetStatusLateInvoice.loading} />

      <ContainerFlex>
        <BoxLateInvoice
          categories={dataResponse?.categories}
          series={dataResponse?.series}
          subTitle={state?.subTitle}
        />
      </ContainerFlex>
    </Container>
  );
};

export default DashDetailsLateInvoice;
