import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderUpgradePlatinumCard = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 20,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data adesão',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Operador(a) de venda',
    maxChar: 13,
  },
  {
    id: 5,
    value: 'Tipo Adesão',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Loja',
    maxChar: 13,
    textAlign: 'center',
  },
];

export const BodyUpgradePlatinumCard = (data: any) => {
  const items = [];

  data?.data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: cpfMask(i.cpf).masked,
        maxChar: 14,
        textAlign: 'center',
      },
      {
        value: moment(i.dataAdesao, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        maxChar: 15,
        textAlign: 'center',
      },
      { value: i.operadorVenda, maxChar: 20, textAlign: 'center' },
      {
        value: i.tipoAdesao,
        maxChar: 12,
        textAlign: 'center',
      },
      { value: i.loja ? i.loja : '-', maxChar: 12, textAlign: 'center' },
    ];
    items.push(item);
  });
  return items;
};

export const BodyUpgradePlatinumCardCSV = (data: any) => {
  const dataCSV = [];

  data?.forEach((csv) => {
    dataCSV.push({
      nome: csv.nome,
      cpf: csv.cpf,
      celular: csv.celular,
      email: csv.email,
      dataAdesao: csv.dataAdesao,
      operadorVenda: csv.operadorVenda,
      tipoAdesao: csv.tipoAdesao,
      loja: csv.loja,
      lojaUF: csv.lojaUF,
    });
  });

  return dataCSV;
};
