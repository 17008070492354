import { useContext } from 'react';
import { IFinancialReport } from '../models';
import { FinancialReportContext } from './FinancialReportContext';

export function useReportFinancial(): IFinancialReport {
  const context = useContext(FinancialReportContext);

  if (!context) {
    throw new Error('useFinancial must be used within an FinancialContext');
  }

  return context;
}
