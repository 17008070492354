import styled from 'styled-components';

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  margin-bottom: 24px;
`;

export const BoxCardTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 700;
  color: #2e3958;
`;

export const ShowResults = styled.p`
  font-size: 0.875em;
  font-weight: 400;
  color: #495057;
`;

export const SpanLabel = styled.span`
  font-size: 0.9em;
  font-weight: 300;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const DivDivider = styled.div`
  width: 50px;
`;

export const SpanLabelFeed = styled.span`
  font-size: 0.8em;
  font-weight: 300;
  margin-top: 5px;
  color: red;
`;
