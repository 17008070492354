/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdOutlineFileDownload } from 'react-icons/md';
import moment from 'moment';
import { FiArrowLeft } from 'react-icons/fi';
import ContainerFlex from '~/components/containerFlex';
import BoxOperators from '../components/boxOperators';
import BoxStore from '../components/boxStore';
import BoxOrigin from '../components/boxOrigin';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Container from '~/components/container';
import * as S from '../styles';
import ProposalReportResponse from '~/services/report/proposal/response';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';

interface IProps {
  type: string;
  dataFinal: string;
  dataInicial: string;
  subTitle: string;
  range: string;
  campoOrdenado: string;
  tipoOrdenacao: string;
}

interface IBoxGraphcic {
  loja: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  operador: {
    categories: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
  origem: {
    categories: [];
    series: {
      name: string;
      data: number[];
    }[];
  };
}

const DashDetailsProposal: React.FC = () => {
  const history = useHistory();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dataResponse, setDataResponse] = useState<IBoxGraphcic>({
    loja: {
      categories: [],
      series: [],
    },
    operador: {
      categories: [],
      series: [],
    },
    origem: {
      categories: [],
      series: [
        {
          name: '',
          data: [],
        },
      ],
    },
  });

  const { state } = useLocation<IProps>();

  const RequestGetStatusProposal = useApi(
    Endpoints.graphc.getProposalDetails
      .replace('PARAM_CAMPO_ORDENADO', 'SERIES')
      .replace('PARAM_TYPE', 'DESC')
      .replace('PARAM_DATA_FINAL', state?.dataFinal)
      .replace('PARAM_DATA_INICIAL', state?.dataInicial)
      .replace(
        'PARAM_STATUS',
        state?.type
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replaceAll('-', '_') || 'CADASTRADAS',
      ),
    ApiMethod.POST,
  );

  const RequestDownloadProposalCSV = useApi<ProposalReportResponse>(
    Endpoints.files.downloadProposalReports,
    ApiMethod.POST,
  );

  useEffect(() => {
    populaGraphc();
  }, []);

  const labelTitle = () => {
    if (state?.type) {
      switch (state?.type) {
        case 'Cadastradas':
          return 'Cadastradas';
        case 'Pré-aprovadas':
          return 'Pré-Aprovadas';
        case 'Aprovadas':
          return 'Aprovadas';
        case 'Reprovadas':
          return 'Reprovadas';
        case 'EM_ANALISE':
          return 'Em análise';
        default:
          break;
      }
    }
    return 'CADASTRADAS';
  };

  const handleDownloadProposalCSV = async () => {
    const dataInicial = state?.dataInicial;
    const dataFinal = state?.dataFinal;

    const getType = () => {
      if (state?.type === 'Aprovadas') {
        return 'APROVADA';
      }
      if (state?.type === 'Reprovadas') {
        return 'REPROVADA';
      }
      if (state?.type === 'Cadastradas') {
        return 'CADASTRADA';
      }
      if (state?.type === 'Pré-aprovadas') {
        return 'PROPOSTA_P1';
      }
    };

    const tipoProposta = getType();

    try {
      const response = await RequestDownloadProposalCSV.callApi({
        tipoProposta,
        dataInicial,
        dataFinal,
      });

      if (response.status >= 200 && response.status < 300) {
        const binary = atob(response.data.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        const file = new Blob(['\ufeff', view], { type: 'text/csv' });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        const fileName = state?.type;

        link.href = fileUrl;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(fileUrl);
      } else {
        setDialogText(response.mensagem || 'Erro download CSV');
        setDialogIsOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const populaGraphc = async () => {
    try {
      const payload = {
        ordenacaoGraficoLoja: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'ASC',
        },
        ordenacaoGraficoMotivoBloqueio: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'ASC',
        },
        ordenacaoGraficoOperador: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'ASC',
        },
        ordenacaoGraficoOrigem: {
          campoOrdenacaoGraficoBar: 'SERIES',
          tipoOrdenacao: 'DESC',
        },
      };
      const response = await RequestGetStatusProposal.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setDataResponse(response.data as IBoxGraphcic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={dialogText}
      />
      <Loading
        status={
          RequestGetStatusProposal.loading || RequestDownloadProposalCSV.loading
        }
      />

      <S.Header>
        <S.LeftSide>
          <FiArrowLeft onClick={() => history.goBack()} />
          <S.HeaderTitle>
            <S.Title onClick={() => history.goBack()}>
              {state?.range === 'mensal'
                ? `Detalhes mensal: ${labelTitle()}`
                : `Detalhes dia anterior: ${labelTitle()}`}
            </S.Title>
          </S.HeaderTitle>
        </S.LeftSide>
      </S.Header>

      {state.type !== 'EM_ANALISE' && (
        <ContainerFlex row end marginRight={12}>
          <S.BtnDownload onClick={handleDownloadProposalCSV}>
            <MdOutlineFileDownload />
            Download
          </S.BtnDownload>
        </ContainerFlex>
      )}

      <ContainerFlex padding={12} marginBottom={-12}>
        <BoxOperators
          categories={
            dataResponse.origem?.categories.filter(
              (categories) => categories !== null,
            )
              ? dataResponse.operador?.categories.filter(
                  (categorie) => categorie !== null,
                )
              : dataResponse.operador?.categories
          }
          series={
            dataResponse.operador?.series.filter(
              (categories) => categories !== null,
            )
              ? dataResponse.operador?.series.filter((serie) => serie !== null)
              : dataResponse.operador?.series
          }
          subTitle={state?.subTitle}
        />
      </ContainerFlex>

      <ContainerFlex row marginTop={10}>
        <ContainerFlex padding={12}>
          <BoxOrigin
            labels={
              dataResponse.origem?.categories.filter(
                (categories) => categories !== null,
              )
                ? dataResponse.origem?.categories.filter(
                    (categories) => categories !== null,
                  )
                : dataResponse?.origem?.categories
            }
            series={dataResponse.origem?.series}
            subTitle={state?.subTitle}
          />
        </ContainerFlex>

        <ContainerFlex padding={12}>
          <BoxStore
            categories={dataResponse.loja?.categories}
            series={dataResponse.loja?.series}
            heightGraphic={254}
            widthGraphic={500}
            subTitle={state?.subTitle}
          />
        </ContainerFlex>
      </ContainerFlex>
    </Container>
  );
};

export default DashDetailsProposal;
