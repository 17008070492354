import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, phoneMask } from '~/utils/masks';

export const HeaderReportLoanSeeker = [
  {
    id: 1,
    value: 'Nome do cliente',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 17,
    textAling: 'center',
  },
  {
    id: 3,
    value: 'Celular',
    maxChar: 13,
    textAling: 'center',
  },
  {
    id: 4,
    value: 'Operador(a)',
    maxChar: 15,
  },
  {
    id: 5,
    value: 'Proposta',
    maxChar: 15,
  },
  {
    id: 6,
    value: 'Data',
    maxChar: 15,
  },
  {
    id: 7,
    value: 'Status',
    maxChar: 20,
    textAling: 'center',
  },
];

export const BodyReportLoanSeeker = (data: any) => {
  const items = [];

  data.forEach((i) => {
    const item: IListColumn[] = [
      {
        id: 1,
        value: i.nome,
        maxChar: 25,
      },
      {
        id: 2,
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 17,
        textAlign: 'center',
      },
      {
        id: 3,
        value: phoneMask(i.celular).masked,
        maxChar: 13,
        textAlign: 'center',
      },
      {
        id: 4,
        value: i.operador,
        maxChar: 14,
        textAlign: 'center',
      },
      {
        id: 5,
        value: i.produto,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 6,
        value: moment(i.dataCriacao, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        maxChar: 15,
        textAlign: 'center',
      },
      { id: 7, value: i.status, maxChar: 20, textAlign: 'center' },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportLoanSeekerCSV = (data: any) => {
  const dataCSV = [];

  data?.forEach((csv) => {
    dataCSV.push({
      nome: csv.nome,
      cpf: csv.cpf,
      email: csv.email,
      celular: csv.celular,
      operador: csv.operador,
      propostas: csv.produto,
      dataCriacao: csv.dataCriacao,
      valor: csv.valor,
      taxaMensal: csv.taxaMensal,
      qtdParcela: csv.qtdParcela,
      status: csv.status,
    });
  });

  return dataCSV;
};
