/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import { components } from '~/assets';
import * as St from './styles';
import { currencyMask, phoneMask } from '~/utils/masks';
import { formatterDataEvents } from '~/utils/daysRemaining';
import RadioButton from '~/components/radioButton';
import TextArea from '~/components/textarea';
import Input, { MaskTypes } from '~/components/input';

interface IProps {
  readonly customerHeader: any;
  item: any;
  ordenationField?: string;
  onSubmitOrder?: Function;
  onSelectModel?: Function;
  onSelectPhone?: Function;
  readonly orderBy?: string;
  isOpinion?: boolean;
  type?: string;
  zeussPhone?: boolean;
  typeEvent?: boolean;
  onOpinion?: Function;
}

const CardTableList: React.FC<IProps> = ({
  customerHeader,
  item,
  ordenationField,
  onSubmitOrder,
  onSelectModel,
  onSelectPhone,
  orderBy,
  isOpinion,
  type,
  zeussPhone,
  typeEvent,
  onOpinion,
}) => {
  const [header, setHeader] = useState([]);
  const [dataItemUpgrade, setDataItemUpgrade] = useState<any>([]);
  const [dataItemCurrent, setDataItemCurrent] = useState<any>([]);
  const [zeussInputPhone, setZeussInputPhone] = useState<any>();
  const [selectedRadio, setSelectedRadio] = useState<string>('');
  const [opinionData, setOpinionData] = useState('');

  useEffect(() => {
    if (customerHeader.includes((e) => e.type === 'id')) return;
    customerHeader?.unshift({ id: 0, value: '', hidden: true });
    setHeader(customerHeader);
  }, [customerHeader]);

  const handleOpinion = (opinion: string) => {
    onOpinion(opinion);
  };

  const handleSubmitOrder = (column: String) => {
    if (orderBy === 'ASC') {
      onSubmitOrder({
        order: 'DESC',
        column,
      });
    } else {
      onSubmitOrder({
        order: 'ASC',
        column,
      });
    }
  };

  const handleSelectModelCard = (theme) => {
    setSelectedRadio(theme);
    onSelectModel(theme);
  };

  useEffect(() => {
    if (item && type === 'current') {
      const newArray = [item].map((item) => ({
        nome: `TeuCard ${item.data[0].categoria}`,
        adesaoDesde: formatterDataEvents(item.dataEmissao),
        tema: item.data[0].descricaoTema,
        anuidade: `R$ ${currencyMask(Number(item.data[0].valor))}`,
      }));
      setDataItemCurrent(newArray[0]);
    }
  }, [item]);

  useEffect(() => {
    if (item && type !== 'current' && type !== 'viewable') {
      let idCounter = 0;

      const temas = item?.data?.map((dataItem) => ({
        id: idCounter++,
        modeloTema: dataItem.modeloTema,
        urlImagem: dataItem.urlImagem,
      }));
      const newArray = [item].map((item) => ({
        nome: `TeuCard ${item.data[0]?.categoria}`,
        anuidade: `R$ ${currencyMask(Number(item.data[0].valor))}`,
        tema: temas,
      }));

      setDataItemUpgrade(newArray[0]);
    }
  }, [item]);

  useEffect(() => {
    if (type === 'viewable') {
      setDataItemUpgrade(item);
    }
  }, [item]);

  return (
    <ContainerFlex>
      <St.Table>
        <St.HeaderTable>
          <tr>
            {header.map((item) => {
              if (item.hidden) return;
              return (
                <St.HeaderTitle
                  key={item.id}
                  maxChar={item.maxChar}
                  textAlign={item.textAlign}
                  onClick={() => item.column && handleSubmitOrder(item.column)}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.value}
                    {ordenationField && (
                      <div style={{ marginLeft: '10px' }}>
                        {item.column === ordenationField &&
                          orderBy === 'ASC' && (
                            <components.ArrowDown
                              style={{ transform: 'rotate(180deg)' }}
                            />
                          )}
                        {item.column === ordenationField &&
                          orderBy === 'DESC' && <components.ArrowDown />}
                      </div>
                    )}
                  </div>
                </St.HeaderTitle>
              );
            })}
          </tr>
        </St.HeaderTable>
        <St.BodyRow>
          {type === 'current' &&
            Object.entries(dataItemCurrent).map(([key, item]) => (
              <>
                <St.bodyData maxChar={30} textAlign="center">
                  {item}
                </St.bodyData>
              </>
            ))}
          {type !== 'current' &&
            Object.entries(dataItemUpgrade).map(([key, item]) => (
              <>
                {key !== 'tema' && (
                  <St.bodyData maxChar={30} textAlign="center">
                    {item}
                  </St.bodyData>
                )}

                {key === 'tema' && (
                  <St.BodyDivData>
                    {Object.entries(item).map(([innerKey, item]) => (
                      <div
                        key={key}
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginRight: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <RadioButton
                            id={item.id}
                            checked={selectedRadio === item.modeloTema}
                            onClick={() => {
                              setSelectedRadio(item.modeloTema);
                              handleSelectModelCard(item.modeloTema);
                            }}
                          />
                          <St.Icon src={item.urlImagem} />
                        </div>
                      </div>
                    ))}
                  </St.BodyDivData>
                )}
              </>
            ))}
          {zeussPhone && !typeEvent && (
            <St.bodyData>
              <div
                style={{
                  width: '55%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 70,
                }}
              >
                <Input
                  value={zeussInputPhone?.target?.value?.masked}
                  onChange={(e) => {
                    setZeussInputPhone(e);
                    onSelectPhone(e);
                  }}
                  placeholder="(00) 00000-0000"
                  maxLength={15}
                  mask={MaskTypes.PHONE}
                />
              </div>
            </St.bodyData>
          )}
        </St.BodyRow>
      </St.Table>
      {isOpinion && (
        <ContainerFlex padding={26}>
          <TextArea
            placeholder="Digitar parecer.."
            name="parecer"
            rows={10}
            value={opinionData}
            onChange={(e) => {
              setOpinionData(e.target.value);
              handleOpinion(e.target.value);
            }}
          />
          <div style={{ marginTop: '10px' }}>
            <St.TitleAbbrFooter>* Obrigatório preencher</St.TitleAbbrFooter>
          </div>
        </ContainerFlex>
      )}
    </ContainerFlex>
  );
};

export default CardTableList;
