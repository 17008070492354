import React from 'react';

import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Grid from '~/components/grid';

import { IProposal } from '~/models/proposal';
import { cepMask, cpfMask, currencyMask, phoneMask } from '~/utils/masks';
import { transformInNormalDate } from '~/utils/date';
import Checkbox from '~/components/checkbox';

interface IComponents {
  data: IProposal;
}

const ProposalResultSPC: React.FC<IComponents> = ({ data }) => {
  const retCPF = cpfMask(data.spc?.cpf);
  const retCEP = cepMask(data.spc?.endereco.cep);
  const dataNascimento = data.spc?.dataNascimento
    ? transformInNormalDate(data.spc?.dataNascimento.substring(0, 10))
    : { masked: '', unmasked: '' };
  const retRenda = currencyMask(data.spc?.rendaPresumida);

  return (
    <ContainerFlex marginLeft={16} marginRight={26}>
      <Input label="CPF" value={retCPF?.masked} readOnly />
      <Input label="Nome completo" value={data.spc?.nome} readOnly />
      <Input
        label="Telefone"
        value={phoneMask(data.spc?.celular).masked}
        readOnly
      />
      <Input
        label="Data de nascimento"
        value={dataNascimento.masked}
        readOnly
      />
      {/* <Input label="Empresa" value="" /> */}
      <Input label="Loja" value={data.spc?.empresa} readOnly />
      <Input
        label="CEP"
        name="cep"
        mask={MaskTypes.CEP}
        maxLength={9}
        placeholder="00000-000"
        value={retCEP?.masked}
        readOnly
      />
      <Input label="Endereço" value={data.spc?.endereco.logradouro} readOnly />
      <div>
        <Grid xs={1} sm={2} gap={10}>
          <ContainerFlex>
            <Input label="Número" value={data.spc?.endereco.numero} readOnly />
          </ContainerFlex>

          <ContainerFlex>
            <Input
              label="Complemento"
              value={data.spc?.endereco.complemento}
              readOnly
            />
          </ContainerFlex>
        </Grid>

        <Grid xs={1} sm={2} gap={10}>
          <ContainerFlex>
            <Input label="Cidade" value={data.spc?.endereco.cidade} readOnly />
          </ContainerFlex>

          <ContainerFlex>
            <Input label="Bairro" value={data.spc?.endereco.bairro} readOnly />
          </ContainerFlex>
        </Grid>
      </div>
      <Input label="Renda estimada" value={retRenda} readOnly />

      <Checkbox
        message="Possui emprego formal"
        value={data.spc?.empregoFormal}
        readonly
      />

      <br />

      <Input
        label="Informação profissional"
        value={data.spc?.profissao}
        readOnly
      />
    </ContainerFlex>
  );
};

export default ProposalResultSPC;
