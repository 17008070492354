import moment from 'moment';
import React from 'react';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';
import ProposalReason from '~/models/proposal-reason';
import { cpfMask } from '~/utils/masks';

export interface IReasonDialogItem {
  item: ProposalReason;
  fullWidth?: boolean;
}

const ReasonDialogItem: React.FC<IReasonDialogItem> = ({
  item = {},
  fullWidth,
}) => (
  <CardView
    padding={20}
    marginBottom={12}
    width={fullWidth ? 80 : 0}
    widthPixel={fullWidth ? 0 : 300}
  >
    <ContainerFlex>
      <ContainerFlex row space>
        <span>CPF:</span>
        <span>{cpfMask(item.cpf)?.masked}</span>
      </ContainerFlex>

      <br />

      <ContainerFlex row space>
        <span>STATUS:</span>
        <span>{item.status}</span>
      </ContainerFlex>

      <br />

      <ContainerFlex row space>
        <span>DATA:</span>
        <span>
          {String(moment(item.dataInclusao).format('DD/MM/YYYY HH:mm'))}
        </span>
      </ContainerFlex>

      <br />

      <ContainerFlex row space>
        <span>MOTIVO:</span>
        <span>{item.motivo}</span>
      </ContainerFlex>
    </ContainerFlex>
  </CardView>
);

export default ReasonDialogItem;
