import { useContext } from 'react';
import { IAccessionSaleReport } from '../models';
import { AccessionSaleReportContext } from './AccessionSaleReportContext';

export function useReportAccessionSale(): IAccessionSaleReport {
  const context = useContext(AccessionSaleReportContext);

  if (!context) {
    throw new Error(
      'useAccessionSale must be used within an AccessionSaleContext',
    );
  }

  return context;
}
