import styled from 'styled-components';

interface showSidebar {
  isOpen: boolean;
}
export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fafbfe;
`;

export const Sidebar = styled.div`
  width: 260px;
  background-color: #e4003a;
  position: fixed;
`;

export const Content = styled.div<showSidebar>`
  /* width: calc(100% - 260px); */
  width: ${({ isOpen }) =>
    isOpen ? 'calc(100% - 80px)' : 'calc(100% - 260px)'};
  height: 100%;
  /* margin-left: 260px; */
  margin-left: ${({ isOpen }) => (isOpen ? '80px' : '260px')};
  transition: all 0.5s;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  main {
    padding-top: 80px;
    margin: 20px 30px;
  }
`;
