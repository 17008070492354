/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import CardView from '~/components/cartView';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Layout from '~/components/layout';
import Loading from '~/components/loading';
import RadioButton from '~/components/radioButton';
import TextArea from '~/components/textarea';
import TitleApp from '~/components/title';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { ConsultUserDetailReactivateResponse } from '~/services/consult/reactivate/response';
import Endpoints from '~/services/endpoints';
import Constants from '~/utils/constants';
import { FormField } from '~/utils/form';

import * as Masks from '~/utils/masks';

import { currencyMask } from '~/utils/masks';

import * as St from './styles';

interface IErrors {
  parecer?: string;
  limitRoot?: string;
  limitRppt30Percent?: string;
}

interface IForm {
  idUsuarioInclusao?: string;
  idUsuarioDecisao?: string;
  cpf?: string;
  id?: string;
  nomeCliente: FormField;
  totalDebt?: FormField;
  amountReceived?: FormField;
  dataInclusao: FormField;
  parecerSolicitacao?: FormField;
  usuarioSolicitacao?: string;
  operationRecipe?: string;
  restabeleceLimite?: boolean;
  dividaTeuCardQuitada?: boolean;
  cadastroAtualizado?: boolean;
  reduzirlimite?: boolean;
  reduzirlimiteSaque?: boolean;
}

interface IReactivate {
  idUsuarioDecisao?: string;
  parecerDecisao?: string;
  valorRecebidoAcordo?: number;
  diasAtraso?: number;
}

const DetailReactivate = (): JSX.Element => {
  const [dialogMessageStatus, setDialogMessageStatus] = useState(false);
  const [dialogApproveStatus, setDialogApproveStatus] = useState(false);
  const [dialogMessageGoBack, setDialogMessageGoBack] = useState(false);
  const [dialogRefuseStatus, setDialogRefuseStatus] = useState(false);
  const [amountReceived, setAmountReceived] = useState(0);
  const [totalDebt, setTotalDebt] = useState(0);
  const [dialogMessage, setDialogMessage] = useState('');
  const [parecer, setParecer] = useState('');
  const [errors, setErrors] = useState<IErrors>({} as IErrors);
  const [delayedDays, setDelayedDays] = useState(0);
  const history = useHistory();
  const { state } = useLocation<{
    id: string;
    cpf: string;
    numeroCartao: string;
  }>();

  const [form, setForm] = useState<IForm>({
    nomeCliente: { name: 'nomeCliente', value: '' },
    dataInclusao: { name: 'dataInclusao', value: '' },
    totalDebt: { name: 'totalDebt', value: '' },
    parecerSolicitacao: { name: 'parecerSolicitacao', value: '' },
    amountReceived: { name: 'aumontReceived', value: '' },
  });

  const goBack = () => history.goBack();

  const RequestUserInfo = useApi<ConsultUserDetailReactivateResponse>(
    Endpoints.consult.detailUserReactivate.replace(
      'PARAM_ID_REACTIVATE',
      state?.id,
    ),
    ApiMethod.GET,
  );

  const apiReactivateApro = useApi<any>(
    Endpoints.register.reactivateDecisionApro.replace(
      'PARAM_ID_REATIVACAO',
      state?.id,
    ),
    ApiMethod.PUT,
  );

  const apiReactivateRepro = useApi<any>(
    Endpoints.register.reactivateDecisionRepro.replace(
      'PARAM_ID_REATIVACAO',
      state?.id,
    ),
    ApiMethod.PUT,
  );

  const getUserDaysDelay = useApi<any>(
    Endpoints.consult.getUserDaysDelay.replace(
      'PARAM_NUMERO_CARTAO',
      state?.numeroCartao,
    ),
    ApiMethod.GET,
  );

  // const apiCancelaCartaoExpNeg = useApi<any>(
  //   Endpoints.consult.cancelCard.replace(
  //     'PARAM_TIPO',
  //     'CANCELAMENTO_EXPERIENCIA',
  //   ),
  //   ApiMethod.POST,
  // );

  const showOptionsRule = () => {
    const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
    const result = userRoles.includes('ADM');
    return result;
  };

  useEffect(() => {
    async function fetchData() {
      const response = await RequestUserInfo.callApi();

      const responseUserDaysDelay = await getUserDaysDelay.callApi();
      if (response.status === 200) {
        loadData(response.data);

        const { data } = responseUserDaysDelay.data;
        setDelayedDays(data.dias_em_atraso);
      }
    }
    fetchData();
  }, []);

  const closeModalMessage = () => {
    setDialogMessageStatus(false);
    if (dialogMessageGoBack) goBack();
  };

  const isParecerValid = () => {
    if (!parecer.trim().length) {
      setErrors((prev) => ({
        ...prev,
        parecer: 'Por favor informe o parecer.',
      }));

      return false;
    }
    setErrors((prev) => ({
      ...prev,
      parecer: null,
    }));
    return true;
  };

  const getReactivatePayload = (): IReactivate => {
    const payload: IReactivate = {
      idUsuarioDecisao: form.idUsuarioDecisao,
      parecerDecisao: parecer,
      valorRecebidoAcordo: Number(form.amountReceived.value),
      diasAtraso: delayedDays,
    };

    return payload;
  };

  // const postCancelarCartaoExpNeg = () => {
  //   const payload = { cpf: state.cpf };

  //   return payload;
  // };

  const loadData = async (data: ConsultUserDetailReactivateResponse) => {
    const obj: IForm = {
      ...form,
      idUsuarioDecisao: localStorage.getItem(Constants.USER_ID),
      idUsuarioInclusao: data.idUsuarioInclusao,
      nomeCliente: { ...form.nomeCliente, value: data.nomeCliente },
      cpf: data.cpf,
      totalDebt: { ...form.totalDebt, value: data.valorDivida.toString() },
      id: data.id,
      restabeleceLimite: data.restabeleceLimite,
      reduzirlimite: data.reduzirlimite,
      reduzirlimiteSaque: data.reduzirLimiteSaque,
      dataInclusao: {
        ...form.dataInclusao,
        value: data.dataInclusao,
        unmasked: data.dataInclusao,
      },
      parecerSolicitacao: {
        ...form.parecerSolicitacao,
        value: data.parecerSolicitacao,
      },
      dividaTeuCardQuitada: data.dividaTeuCardQuitada,
      cadastroAtualizado: data.cadastroAtualizado,
      usuarioSolicitacao: data.usuarioSolicitacao,
      amountReceived: { ...form.amountReceived, value: String(data.valorPago) },
    };

    setForm(obj);
    setAmountReceived(data.valorPago);
    setTotalDebt(data.valorDivida);
  };

  const onClickApprove = async () => {
    setDialogApproveStatus(false);
    if (!isParecerValid()) {
      return;
    }

    try {
      // if (form?.restabeleceLimite === false) {
      //   const payload = postCancelarCartaoExpNeg();
      //   const response = await apiCancelaCartaoExpNeg.callApi(payload);

      //   if (response.status === 200 || response.status === 204) {
      //     setDialogMessage('Cartão cancelado por motivo de EXP. NEGATIVA.');
      //     setDialogMessageGoBack(true);
      //   } else {
      //     setDialogMessage(response.mensagem);
      //     setDialogMessageGoBack(false);
      //   }
      // }

      const payload = getReactivatePayload();

      const response = await apiReactivateApro.callApi(payload);

      if (response.status === 200 || response.status === 204) {
        setDialogMessage('Reativação aprovada.');
        setDialogMessageGoBack(true);
      } else {
        setDialogMessage(response.mensagem);
        setDialogMessageGoBack(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDialogMessageStatus(true);
    }
  };

  const onClickRefuse = async () => {
    setDialogRefuseStatus(false);
    if (!isParecerValid()) {
      return;
    }

    try {
      const payload = getReactivatePayload();

      const response = await apiReactivateRepro.callApi(payload);

      if (response.status === 200 || response.status === 204) {
        setDialogMessage('Reativação recusada.');
        setDialogMessageGoBack(true);
      } else {
        setDialogMessage(response.mensagem);
        setDialogMessageGoBack(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDialogMessageStatus(true);
    }
  };

  const operation =
    Number(form.amountReceived.value) - Number(form.totalDebt.value);

  return (
    <>
      <Loading
        status={
          apiReactivateApro.loading ||
          apiReactivateRepro.loading ||
          RequestUserInfo.loading ||
          getUserDaysDelay.loading
        }
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogMessageStatus}
        positiveLabel="Entendi"
        positiveAction={closeModalMessage}
        message={dialogMessage}
      />
      {/* Dialog de Confirmação - Aprovação */}
      <Dialog
        icon={icons.confirmation}
        open={dialogApproveStatus}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogApproveStatus(false)}
        positiveLabel="Aprovar"
        positiveAction={onClickApprove}
        message="Você tem certeza que deseja aprovar a reativação?"
      />
      {/* Dialog de Confirmação - Reprovação */}
      <Dialog
        icon={icons.confirmation}
        open={dialogRefuseStatus}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogRefuseStatus(false)}
        positiveLabel="Recusar"
        positiveAction={onClickRefuse}
        message="Você tem certeza que deseja recusar a reativação?"
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <TitleApp>Reativar Cliente - Decisão</TitleApp>
          </ContainerFlex>

          <ContainerFlex>
            <St.TitleCategory paddingLeft={26}>
              1. Dados da Solicitação
            </St.TitleCategory>
            <Grid xs={1} lg={2}>
              <ContainerFlex>
                <Grid xs={1} lg={1} gap={24}>
                  <ContainerFlex padding={26}>
                    <Input
                      label="Nome completo"
                      name={form.nomeCliente.name}
                      readOnly
                      value={form.nomeCliente.value}
                    />

                    <ContainerFlex
                      row
                      horizontal
                      style={{
                        boxShadow: '1px 1px 7px 0 rgba(201,201,201, 0.5',
                      }}
                      padding={10}
                      marginTop={20}
                    >
                      <ContainerFlex>
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle> Devolver cartão?</St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.restabeleceLimite === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.restabeleceLimite === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle> Dívidas Teucard quitadas?</St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.dividaTeuCardQuitada === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.dividaTeuCardQuitada === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle> Cadastro foi atualizado?</St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.cadastroAtualizado === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.cadastroAtualizado === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle>
                            Reduzir o limite de compras?
                          </St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.reduzirlimite === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.reduzirlimite === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>
                        {form?.reduzirlimite && (
                          <St.ContainerRadio>
                            <St.TitleNotice>
                              Reduzir limite em 50%
                            </St.TitleNotice>
                          </St.ContainerRadio>
                        )}
                        <ContainerFlex row horizontal align="center">
                          <St.Subtitle>
                            Reduzir o limite de empréstimo?
                          </St.Subtitle>
                          <St.ContainerRadio>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.reduzirlimiteSaque === true}
                              />
                              SIM
                            </ContainerFlex>
                            <ContainerFlex row horizontal align="center">
                              <RadioButton
                                id={form?.id}
                                checked={form?.reduzirlimiteSaque === false}
                              />
                              NÃO
                            </ContainerFlex>
                          </St.ContainerRadio>
                        </ContainerFlex>

                        {form?.reduzirlimiteSaque && (
                          <St.ContainerRadio>
                            <St.TitleNotice>
                              Reduzir limite em{' '}
                              {delayedDays >= 100 ? '100%' : '50%'}
                            </St.TitleNotice>
                          </St.ContainerRadio>
                        )}
                      </ContainerFlex>
                    </ContainerFlex>
                  </ContainerFlex>
                </Grid>
              </ContainerFlex>
              <ContainerFlex>
                <Grid xs={1} lg={1} gap={24}>
                  <ContainerFlex padding={26}>
                    <ContainerFlex>
                      <Input
                        label="CPF"
                        name="cpf"
                        mask={MaskTypes.CPF}
                        maxLength={14}
                        placeholder="000.000.000-00"
                        value={Masks.cpfMask(state?.cpf).masked}
                      />
                    </ContainerFlex>
                    <ContainerFlex>
                      <Input
                        label="Dias de atraso"
                        name="cpf"
                        value={delayedDays}
                        readOnly
                      />
                    </ContainerFlex>
                    <ContainerFlex>
                      <Input
                        label="Total da Dívida do cliente"
                        name="totalDebt"
                        readOnly
                        value={currencyMask(totalDebt)}
                      />
                    </ContainerFlex>
                    <ContainerFlex>
                      <Input
                        label="Valor total recebido no acordo"
                        name="amountReceived"
                        readOnly
                        value={currencyMask(amountReceived)}
                      />
                    </ContainerFlex>
                    <ContainerFlex>
                      <Input
                        style={{ color: operation < 0 ? 'red' : 'black' }}
                        label="Receita da Operação"
                        name="operationRecipe"
                        readOnly
                        value={currencyMask(operation)}
                      />
                    </ContainerFlex>
                  </ContainerFlex>
                </Grid>
              </ContainerFlex>
            </Grid>

            <St.TitleCategory paddingLeft={26}>
              2. Histórico de Parecer
            </St.TitleCategory>

            <ContainerFlex row horizontal marginLeft={26} marginTop={20}>
              <ContainerFlex
                style={{ boxShadow: '1px 1px 7px 0 rgba(201,201,201, 0.5' }}
                padding={20}
              >
                <St.TitleParecer>Data e Hora</St.TitleParecer>
                <ContainerFlex>
                  <St.TitleParecer>
                    {form.dataInclusao
                      ? moment(form?.dataInclusao.value).format(
                          'DD/MM/YYYY HH:mm',
                        )
                      : ''}
                  </St.TitleParecer>
                </ContainerFlex>
              </ContainerFlex>

              <ContainerFlex
                marginLeft={20}
                style={{ boxShadow: '1px 1px 7px 0 rgba(201,201,201, 0.5' }}
                padding={20}
              >
                <ContainerFlex>
                  <St.TitleParecer>Usuário</St.TitleParecer>
                  <St.TitleParecer>
                    {form.usuarioSolicitacao ? form.usuarioSolicitacao : '--'}
                  </St.TitleParecer>
                </ContainerFlex>
              </ContainerFlex>

              <ContainerFlex
                marginLeft={20}
                style={{ boxShadow: '1px 1px 7px 0 rgba(201,201,201, 0.5' }}
                padding={20}
                marginRight={20}
              >
                <St.TitleParecer>Parecer de Solicitação</St.TitleParecer>
                <ContainerFlex>
                  <St.TitleParecer>
                    {form.parecerSolicitacao.value
                      ? form.parecerSolicitacao.value
                      : '--'}
                  </St.TitleParecer>
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
            <br />
            <St.TitleCategory paddingLeft={26}>
              3. Decisão
              <St.TitleAbbr title="Obrigatório">*</St.TitleAbbr>
            </St.TitleCategory>
            <ContainerFlex padding={26}>
              <TextArea
                placeholder="Digitar parecer.."
                name="parecer"
                rows={10}
                value={parecer}
                onChange={(e) => setParecer(e.target.value)}
                errorMessage={errors.parecer}
                disabled={
                  form.idUsuarioInclusao !==
                  localStorage.getItem(Constants.USER_ID)
                    ? !showOptionsRule()
                    : true
                }
              />
              <div style={{ marginTop: '10px' }}>
                <St.TitleAbbrFooter>* Obrigatório preencher</St.TitleAbbrFooter>
              </div>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row horizontal padding={26} end>
            <Button outlined onClick={goBack}>
              Voltar
            </Button>
            <Button
              outlined
              marginLeft={16}
              disabled={
                parecer.length > 0 &&
                form.idUsuarioInclusao !==
                  localStorage.getItem(Constants.USER_ID)
                  ? !showOptionsRule()
                  : true
              }
              onClick={() => setDialogRefuseStatus(true)}
            >
              Recusar
            </Button>

            <Button
              marginLeft={16}
              disabled={
                parecer.length > 0 &&
                form.idUsuarioInclusao !==
                  localStorage.getItem(Constants.USER_ID)
                  ? !showOptionsRule()
                  : true
              }
              onClick={() => setDialogApproveStatus(true)}
            >
              Aprovar
            </Button>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default DetailReactivate;
