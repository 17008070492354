import styled from 'styled-components';

interface toggleProps {
  backColor?: boolean;
  widthSwitchLabel?: number;
  heightSwitchLabel?: number;
  widthSwitchButton?: number;
  heightSwitchButton?: number;
}

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const SwitchLabel = styled.label<toggleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: ${(p) => (p.widthSwitchLabel ? `${p.widthSwitchLabel}px` : '70px')};
  height: ${(p) => (p.heightSwitchLabel ? `${p.heightSwitchLabel}px` : '20px')};
  border-radius: 100px;
  border: 0.2px solid gray;
  background: ${({ backColor }) => (backColor ? '#f08080' : '#dcdcdc')};
  position: relative;
  transition: background-color 0.2s;
`;

export const SwitchButton = styled.span<toggleProps>`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: ${(p) => (p.widthSwitchButton ? `${p.widthSwitchButton}px` : '15px')};
  height: ${(p) =>
    p.heightSwitchButton ? `${p.heightSwitchButton}px` : '15px'};
  border-radius: 45px;
  transition: 0.2s;
  background: ${({ backColor }) => (backColor ? '#a52a2a' : 'black')};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 45px;
  }
`;
