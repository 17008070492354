import { useContext } from 'react';
import Collapse from '~/components/collapse';
import CardTableList from '../../Components/CardTableList';
import { headerListPlan, headerUpgrade } from '../../Headers/cardHeaders';
import { SharedCardServicesContext } from '../Services/sharedCardServiceContext';
import SharedServiceContext from '../../Services/sharedServiceContext';

export const UpgradeSolicitation = () => {
  const {
    cardInfos,
    setOpinion,
    setCardModelSelected,
    setPhoneZeuss,
  } = useContext(SharedServiceContext);
  const {
    cardsNotClients,
    getNotClientCards,
    loading,
    setPhoneZeussCard,
  } = useContext(SharedCardServicesContext);

  return (
    <>
      <Collapse title="2. Plano Atual" typeEvent>
        <CardTableList
          customerHeader={headerListPlan}
          item={cardInfos}
          type="current"
        />
      </Collapse>
      <Collapse
        // collapseHeight={notContractedBenefits.length * 20}
        callFunctionOnExpand={getNotClientCards}
        waitRequest={loading.getThemeClientNotCategory.loading}
        title="3. Alteração do plano"
      >
        <CardTableList
          isOpinion
          customerHeader={headerUpgrade}
          item={cardsNotClients}
          onSelectModel={(e: string) => setCardModelSelected(e)}
          zeussPhone
          onSelectPhone={(e) => {
            setPhoneZeussCard(e.target.value);
            setPhoneZeuss(e.target.value);
          }}
          onOpinion={(e: string) => setOpinion(e)}
        />
      </Collapse>
    </>
  );
};
