import styled from 'styled-components';

export interface ITitleCategory {
  paddingLeft?: number;
  paddingRight?: number;
}

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;

export const TitleCategory = styled.span<ITitleCategory>`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  margin-top: 8px;
  background-color: #fbfbfb;

  padding-top: 22px;
  padding-bottom: 22px;
  padding-right: ${(p) => (p.paddingRight ? `${p.paddingRight}px` : '0px')};
  padding-left: ${(p) => (p.paddingLeft ? `${p.paddingLeft}px` : '0px')};
`;

export const TitleParecer = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-top: 22px;
  padding-bottom: 22px;
`;

export const TitleAbbr = styled.abbr`
  color: red;
  text-decoration: none;
`;

export const TitleAbbrFooter = styled.span`
  color: red;
  font-weight: 600;
`;
