import { useContext } from 'react';
import { CardContext, ICardReport } from './CardContext';

export function useCardReport(): ICardReport {
  const context = useContext(CardContext);

  if (!context) {
    throw new Error('useCardReport must be used within an ReportContext');
  }

  return context;
}
