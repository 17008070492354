import styled from 'styled-components';

export const SubTitle = styled.span`
  padding: 16px;
  color: #505150;
`;

export const SelectLabel = styled.span`
  margin-left: 6px;
  color: #e71037;
  cursor: pointer;
`;

export const InputFileHidden = styled.input.attrs({ type: 'file' })`
  display: none;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cccc;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const BoxDrag = styled.div`
  padding: 16px;
  border: 1px dashed #d6d6d6;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const BoxDragIcon = styled.img`
  height: 35px;
  width: 35px;
  margin-top: 6px;
  margin-bottom: 16px;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;

export const Title = styled.h1`
  font-size: 1.3rem;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;
