import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, creditCardMask, currencyMask } from '~/utils/masks';

export const HeaderFinancialIncome = [
  {
    id: 1,
    value: 'CPF',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Número do Cartão',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Nome completo',
    maxChar: 20,
  },
  {
    id: 4,
    value: 'Tipo de Encargo',
    maxChar: 15,
  },
  {
    id: 5,
    value: 'Data Vencimento',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Data Pagamento',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Valor',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodyFinancialIncome = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 14,
        textAlign: 'center',
      },
      {
        value: creditCardMask(i.cartao).masked,
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: i.tipo,
        maxChar: 15,
      },
      {
        value: i.vencimento ? moment(i.vencimento).format('DD/MM/YYYY') : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.pagamento ? moment(i.pagamento).format('DD/MM/YYYY') : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: `R$ ${currencyMask(i.valor)}`,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
