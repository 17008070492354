/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable react/react-in-jsx-scope */
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import classnames from 'classnames';
import './MultiRangeSlider.css';

interface MultiRangeSliderProps {
  min: number;
  max: number;
  editValue: any;
  defaultValues?: {
    min?: number;
    max?: number;
  };
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({
  min,
  max,
  editValue,
  defaultValues,
}) => {
  const [minVal, setMinVal] = useState(defaultValues.min);
  const [maxVal, setMaxVal] = useState(defaultValues.max);
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);
  function generateMarkersNumbers(start, end) {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  }
  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  const ruleMarker = [];
  for (let i = 0; i < ruleMarker.length; i++) {
    ruleMarker.push(<div className="marker">{i}</div>);
  }

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  return (
    <div className="container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const targetValue = +event.target.value;
          let value;
          if (targetValue === max && maxVal === max) {
            value = Math.min(+event.target.value, maxVal);
          } else {
            value = Math.min(+event.target.value, maxVal - 1);
          }
          setMinVal(value);
          event.target.value = value.toString();
          editValue(value, maxVal);
        }}
        className={classnames('thumb first-thumb thumb--zindex-3', {
          'thumb--zindex-5': minVal > max - 100,
        })}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          event.target.value = value.toString();
          editValue(minVal, value);
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />

        <div className="wrapper">
          <div className="container">
            {generateMarkersNumbers(min, max).map((e, index, arr) => (
              <div className="marker">
                {arr.at(-1) === e ? <sup>{e}+</sup> : <sup>{e}</sup>}
              </div>
            ))}
          </div>
        </div>

        <div className="slider__left-value">{min}</div>
        <div className="slider__right-value">{max}</div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
