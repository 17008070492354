/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-curly-newline */

import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { MdCropFree } from 'react-icons/md';
import { IoCloseOutline } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { FiXCircle } from 'react-icons/fi';
import styled from 'styled-components';
import moment from 'moment';
import Button from '~/components/button';
import * as St from './styles';
import { Contract } from '~/services/consult/user/search/response';

type IconProps = {
  disabled?: boolean;
};

const DownloadIcon = styled(FaDownload)<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

const ViewIcon = styled(MdCropFree)<IconProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

const ArrowBack = styled(IoIosArrowBack)<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

const EmptyIcon = styled(FiXCircle)<IconProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

interface IBenefits {
  show: boolean;
  benefits: Partial<Contract[]>;
  platinum: Partial<any>;
  closeModal: Function;
  authorizingBenefitId: number;
  viewPlatinum: Function;
  downloadPlatinum: Function;
}

const BenefitModal: React.FC<IBenefits> = ({
  show,
  benefits,
  platinum,
  closeModal,
  authorizingBenefitId,
  viewPlatinum,
  downloadPlatinum,
}) => {
  const closeModalBenefit = () => {
    closeModal(false);
  };

  const [canceledBenefitOverlay, setCanceleBenefitOverlay] = useState(false);
  const [currentBenefitOverlay, setCurrentBenefitOverlay] = useState(false);

  const filteredBenefit = benefits?.find(
    (item) =>
      item.contratoAtual.idBeneficioAutorizador === authorizingBenefitId,
  );

  const fixDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR');
  };

  const viewContract = (status, flux) => {
    if (status === 'ATIVO' || status === 'PENDENTE')
      window.open(filteredBenefit?.contratoAtual.linkContrato);

    if (status === 'CANCELADO') {
      if (flux === 'CURRENT') setCurrentBenefitOverlay(true);

      if (flux === 'CANCEL') setCanceleBenefitOverlay(true);
    }

    return window.open(filteredBenefit?.contratoAtual.linkContrato);
   
  };

  const currentBenefitActive = () => {
    return (
      <>
        <St.CurrentBenefit>
          <St.AccessionComp>
            <St.Accession>Adesão</St.Accession>
            <St.AccessionData>
              {moment(
                filteredBenefit?.contratoAtual.dataAdesao ||  platinum?.dataEmissao,
                 'YYYY-MM-DD'
              ).format("DD/MM/YYYY")}
            </St.AccessionData>
          </St.AccessionComp>
          {filteredBenefit?.contratoAtual.statusBeneficio === 'CANCELADO' && (
            <St.AccessionComp>
              <St.Accession>Cancelamento</St.Accession>
              <St.AccessionData>
                {moment(filteredBenefit?.contratoAtual.dataCancelamento, 'YYYY-MM-DD').format("DD/MM/YYYY")}
              </St.AccessionData>
            </St.AccessionComp>
          )}
          {!platinum && (
            <St.AccessionComp>
              <St.Accession>Status</St.Accession>
              <St.AccessionData>
                {filteredBenefit?.contratoAtual.statusBeneficio}
              </St.AccessionData>
            </St.AccessionComp>
          )}
          {platinum && !filteredBenefit && (
            <St.ToViewComp>
              <St.ToView>Baixar</St.ToView>
              <St.ToViewData>
                <ViewIcon disabled={false} onClick={() => downloadPlatinum()} />
              </St.ToViewData>
            </St.ToViewComp>
          )}
          <St.ToViewComp>
            <St.ToView>Visualizar</St.ToView>
            <St.ToViewData>
              <ViewIcon
                disabled={false}
                onClick={() =>
                  platinum && !filteredBenefit
                    ? viewPlatinum()
                    : viewContract(
                        filteredBenefit?.contratoAtual.statusBeneficio,
                        'CURRENT',
                      )
                }
              />
            </St.ToViewData>
          </St.ToViewComp>
        </St.CurrentBenefit>
      </>
    );
  };

  const overlayCancelContract = () => {
    return (
      <St.Overlay active={currentBenefitOverlay}>
        <St.ViewOverlay>
          <St.ToViewComp style={{ width: '100%' }}>
            <St.ToView>Termo adesão</St.ToView>
            <St.ToViewData>
              <ViewIcon
                disabled={false}
                onClick={() =>
                  window.open(filteredBenefit?.contratoAtual.linkContrato)
                }
              />
            </St.ToViewData>
          </St.ToViewComp>
          <St.ToViewComp>
            <St.ToView>Termo cancelamento</St.ToView>
            <St.ToViewData>
              <ViewIcon
                disabled={false}
                onClick={() =>
                  window.open(
                    filteredBenefit?.contratoAtual.linkTermoCancelamento,
                  )
                }
              />
            </St.ToViewData>
          </St.ToViewComp>
          <St.ViewOverlayBack onClick={() => setCurrentBenefitOverlay(false)}>
            <ArrowBack
              style={{ width: 20, height: 20 }}
              disabled={false}
              onClick={() => null}
            />
          </St.ViewOverlayBack>
        </St.ViewOverlay>
      </St.Overlay>
    );
  };

  return (
    <St.BenefitBackGroundArea view={show}>
      <St.BenefitModal>
        <St.Header>
          <St.Title>
            {filteredBenefit?.contratoAtual.nome ||
              `Contrato ${platinum?.data?.[0]?.categoria}`}
          </St.Title>
          <IoCloseOutline
            size={30}
            style={{ marginRight: 30, cursor: 'pointer' }}
            onClick={() => {
              closeModalBenefit();
              setCanceleBenefitOverlay(false);
              setCurrentBenefitOverlay(false);
            }}
          />
        </St.Header>
        <St.Body>
          <St.CurrentBenefitSection>
            <St.CurrentSectionTitle>Atual</St.CurrentSectionTitle>

            {!currentBenefitOverlay && currentBenefitActive()}
            {currentBenefitOverlay && overlayCancelContract()}
          </St.CurrentBenefitSection>
          <St.CanceledBenefitSection>
            <St.CanceledSectionTitle>Cancelado(s)</St.CanceledSectionTitle>
            {!filteredBenefit?.contratosCancelados && (
              <St.NullBenefitsCancel>
                <EmptyIcon style={{ width: 50, height: 50 }} />
                <St.NullBenefitText>
                  Não há contratos anteriores
                </St.NullBenefitText>
              </St.NullBenefitsCancel>
            )}
            {filteredBenefit?.contratosCancelados && (
              <St.CanceledBenefitList>
                {filteredBenefit?.contratosCancelados?.map((item, index) =>
                  !canceledBenefitOverlay ? (
                    <St.CanceledBenefitItem>
                      <St.AccessionComp>
                        <St.Accession>Adesão</St.Accession>
                        <St.AccessionData style={{ marginTop: 5 }}>
                          {moment(item?.dataAdesao, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                        </St.AccessionData>
                      </St.AccessionComp>
                      <St.AccessionComp>
                        <St.Accession>Cancelamento</St.Accession>
                        <St.AccessionData style={{ marginTop: 5 }}>
                          {moment(item?.dataCancelamento, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                        </St.AccessionData>
                      </St.AccessionComp>
                      <St.ToViewComp>
                        <St.ToView>Visualizar</St.ToView>
                        <St.ToViewData style={{ marginTop: 8 }}>
                          <ViewIcon
                            disabled={false}
                            onClick={() =>
                              viewContract(item?.statusBeneficio, 'CANCEL')
                            }
                          />
                        </St.ToViewData>
                      </St.ToViewComp>
                    </St.CanceledBenefitItem>
                  ) : (
                    <St.OverlayCancel active={canceledBenefitOverlay}>
                      <St.ViewOverlay>
                        <St.ToViewComp style={{ width: '100%' }}>
                          <St.ToView>Termo adesão</St.ToView>
                          <St.ToViewData>
                            <ViewIcon
                              disabled={false}
                              onClick={() => window.open(item.linkContrato)}
                            />
                          </St.ToViewData>
                        </St.ToViewComp>
                        <St.ToViewComp>
                          <St.ToView>Termo cancelamento</St.ToView>
                          <St.ToViewData>
                            <ViewIcon
                              disabled={false}
                              onClick={() =>
                                window.open(item.linkTermoCancelamento)
                              }
                            />
                          </St.ToViewData>
                        </St.ToViewComp>
                        <St.ViewOverlayBack
                          onClick={() => setCanceleBenefitOverlay(false)}
                        >
                          <ArrowBack
                            style={{ width: 20, height: 20 }}
                            disabled={false}
                            onClick={() => null}
                          />
                        </St.ViewOverlayBack>
                      </St.ViewOverlay>
                    </St.OverlayCancel>
                  ),
                )}
              </St.CanceledBenefitList>
            )}
          </St.CanceledBenefitSection>
        </St.Body>
        <St.Footer>
          <Button
            style={{ marginRight: 30, marginBottom: 20 }}
            outlined
            fitContent
            onClick={() => {
              closeModalBenefit();
              setCanceleBenefitOverlay(false);
              setCurrentBenefitOverlay(false);
            }}
          >
            Voltar
          </Button>
        </St.Footer>
      </St.BenefitModal>
    </St.BenefitBackGroundArea>
  );
};

export default BenefitModal;
