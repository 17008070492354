import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input from '~/components/input';
import TitleApp from '~/components/title';
import Button from '~/components/button';
import Dialog from '~/components/dialog';
import PictureCard from '~/components/pictureCard';
import TextArea from '~/components/textarea';
import Loading from '~/components/loading';
import CardReason from './components/CardReason';

import { IProposal } from '~/models/proposal';
import { DisputeTypes } from '~/models/dispute';

import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';

import { icons } from '~/assets';
import * as St from './styles';
import {
  cpfMask,
  creditCardMask,
  currencyMask,
  dateMask,
  IDefaultReturn,
  phoneMask,
} from '~/utils/masks';
import IResponseDisputeDetail, {
  IReasonDisputeDetail,
} from '~/services/consult/dispute/detail/response';
import Container from '~/components/container';

interface IForm {
  nome: string;
  numeroCartao: IDefaultReturn;
  cpf: IDefaultReturn;
  dataVencimento: IDefaultReturn;
  telefone: IDefaultReturn;
  quantidadeContestacao: number;
  idTransacao: string;
  valorTransacao: string;
  dataTransacao: string;
  horarioTransacao: string;
  localTransacao: string;
  statusTransacao: string;
  tipoTransacao: string;
  quantidadeParcela: number;
  idAtendimento: string;
}

const ConsultDisputeTable = (): JSX.Element => {
  const [dialogApproveMessageOpen, setApproveDialogMessageOpen] = useState(
    false,
  );
  const [
    dialogDisapproveMessageOpen,
    setDisapproveDialogMessageOpen,
  ] = useState(false);
  const [dialogConfirmationIsOpen, setDialogConfirmationIsOpen] = useState(
    false,
  );
  const [dialogConfirmationText, setDialogConfirmationText] = useState('');
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState(null);
  const [proposalId, setProposalId] = useState(null);
  const [form, setForm] = useState({} as IForm);
  const [dialogReasonShow, setDialogReasonShow] = useState(false);

  const history = useHistory();
  const { state } = useLocation<{ id: string }>();

  const RequestUserDocuments = useApi<IProposal>(
    `${Endpoints.consult.searchUserDocuments}${proposalId}`,
    ApiMethod.GET,
  );

  const RequestProposalDetail = useApi<IResponseDisputeDetail>(
    `${Endpoints.consult.searchDisputeDetail}${state?.id}`,
    ApiMethod.GET,
  );

  const RequestEndDispute = useApi<any>(
    `${Endpoints.update.endDispute}${state?.id}`,
    ApiMethod.PUT,
  );

  useEffect(() => {
    if (proposalId) RequestUserDocuments.callApi();
  }, [proposalId]);

  useEffect(() => {
    (async () => {
      const responseProposalDetail = await RequestProposalDetail.callApi();

      if (responseProposalDetail.status === 200) {
        setForm({
          nome: responseProposalDetail.data.nome,
          cpf: cpfMask(responseProposalDetail.data.cpf),
          numeroCartao: creditCardMask(
            responseProposalDetail.data.numeroCartao,
          ),
          dataVencimento: dateMask(responseProposalDetail.data.diaVencimento),
          telefone: phoneMask(responseProposalDetail.data.celular),
          quantidadeContestacao:
            responseProposalDetail.data.quantidadeContestacoes,
          idTransacao: responseProposalDetail.data.idTransacao,
          valorTransacao: responseProposalDetail.data.transacao?.valor,
          dataTransacao: responseProposalDetail.data.transacao?.data,
          horarioTransacao: responseProposalDetail.data.transacao?.horario,
          localTransacao: responseProposalDetail.data.transacao?.loja,
          statusTransacao: responseProposalDetail.data.status,
          tipoTransacao: responseProposalDetail.data.transacao?.tipoTransacao,
          quantidadeParcela:
            responseProposalDetail.data.transacao?.quantidadeParcelas,
          idAtendimento: responseProposalDetail.data.idTransacao,
        });
        setProposalId(responseProposalDetail.data.propostaId);
      }
    })();
  }, []);

  const goBack = () => history.goBack();

  const showDialog = (type: string) => {
    if (!reason.trim().length)
      return setReasonError('Prencha o campo corretamente.');

    setReasonError(null);

    switch (type) {
      case 'approve':
        setApproveDialogMessageOpen(true);
        break;
      case 'disapprove':
        setDisapproveDialogMessageOpen(true);
        break;
      default:
        break;
    }
  };

  const handleApproveChargeback = async () => {
    const response = await RequestEndDispute.callApi({
      idOperador: localStorage.getItem('USER_ID'),
      motivoDisputa: reason,
      status: DisputeTypes.ESTORNADA,
    });

    if (response.status === 204) {
      setDialogConfirmationText('Aprovação de estorno feita com sucesso!');
    } else {
      setDialogConfirmationText(response.data.mensagem);
    }

    setApproveDialogMessageOpen(false);
    setDialogConfirmationIsOpen(true);
  };

  const handleDisapproveChargeback = async () => {
    const response = await RequestEndDispute.callApi({
      idOperador: localStorage.getItem('USER_ID'),
      motivoDisputa: reason,
      status: DisputeTypes.NEGADA,
    });

    if (response.status === 204) {
      setDialogConfirmationText('Negação de estorno feita com sucesso!');
    } else {
      setDialogConfirmationText(response.mensagem);
    }

    setDisapproveDialogMessageOpen(false);
    setDialogConfirmationIsOpen(true);
  };

  return (
    <>
      <Loading
        status={
          RequestUserDocuments.loading ||
          RequestEndDispute.loading ||
          RequestProposalDetail.loading
        }
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogApproveMessageOpen}
        positiveLabel="Confirmar"
        negativeLabel="Cancelar"
        negativeAction={() => setApproveDialogMessageOpen(false)}
        positiveAction={handleApproveChargeback}
        message="Deseja aprovar o estorno?"
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogDisapproveMessageOpen}
        positiveLabel="Confirmar"
        negativeLabel="Cancelar"
        negativeAction={() => setDisapproveDialogMessageOpen(false)}
        positiveAction={handleDisapproveChargeback}
        message="Deseja negar o estorno?"
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogConfirmationIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => history.push('/consult/dispute')}
        message={dialogConfirmationText}
      />

      <Dialog
        open={dialogReasonShow}
        positiveLabel="Fechar"
        positiveAction={() => setDialogReasonShow(false)}
      >
        <ContainerFlex center>
          <h2>Motivos</h2>
          <ContainerFlex center>
            <St.TableHistory>
              <tr>
                <th>CPF</th>
                <th>DATA</th>
                <th style={{ borderRight: '1px solid grey' }}>MOTIVO</th>
              </tr>
              {RequestProposalDetail.data?.motivos.map(
                (item: IReasonDisputeDetail) => (
                  <tr>
                    <td>{form.cpf?.masked}</td>
                    <td>
                      {String(
                        moment(item.dataInclusao).format('DD/MM/YYYY HH:mm'),
                      ).trim()}
                    </td>
                    <td
                      style={{ borderRight: '1px solid grey', maxWidth: 300 }}
                    >
                      {item.motivoDisputa.trim()}
                    </td>
                  </tr>
                ),
              )}
            </St.TableHistory>
          </ContainerFlex>
        </ContainerFlex>
      </Dialog>
      <Container>
        <St.BoxCard>
          <ContainerFlex padding={26}>
            <TitleApp>Mesa de Disputa</TitleApp>
          </ContainerFlex>

          <ContainerFlex color="#fbfbfb">
            <St.TitleCategory>1. Cliente</St.TitleCategory>
          </ContainerFlex>

          <ContainerFlex padding={26}>
            <Grid xs={1} lg={2} gap={30}>
              <ContainerFlex>
                <Input label="Nome" value={form.nome} readOnly />
                <Input
                  label="Número do cartão"
                  value={form.numeroCartao?.masked}
                  readOnly
                />

                <Input
                  label="Quantidade de contestação"
                  value={String(form.quantidadeContestacao)}
                  readOnly
                />

                <ContainerFlex horizontal>
                  <ContainerFlex row horizontal>
                    <St.TitleFileChoose>CNH ou RG</St.TitleFileChoose>
                    <St.TitleFileChooseSmall>
                      (Frente e verso)
                    </St.TitleFileChooseSmall>
                  </ContainerFlex>

                  <ContainerFlex row horizontal>
                    <PictureCard
                      width="150"
                      height="100"
                      showOptions={!!RequestUserDocuments.data?.documentoFrente}
                      src={
                        RequestUserDocuments.data?.documentoFrente
                          ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoFrente}`
                          : icons.tableDocumentFront
                      }
                    />
                    <PictureCard
                      width="150"
                      height="100"
                      showOptions={!!RequestUserDocuments.data?.documentoVerso}
                      src={
                        RequestUserDocuments.data?.documentoVerso
                          ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoVerso}`
                          : icons.tableDocumentBack
                      }
                    />
                  </ContainerFlex>

                  <St.TitleFileChooseSmall>
                    Clique no ícone para selecionar a imagem
                  </St.TitleFileChooseSmall>
                </ContainerFlex>
              </ContainerFlex>

              <ContainerFlex>
                <Input label="CPF" value={form.cpf?.masked} />
                <Input
                  label="Data de vencimento da fatura do cartão"
                  value={form.dataVencimento?.masked}
                  readOnly
                />
                <Input
                  label="Telefone"
                  value={form.telefone?.masked}
                  readOnly
                />
              </ContainerFlex>
            </Grid>
          </ContainerFlex>

          <ContainerFlex row center color="#fbfbfb">
            <St.TitleCategory>2. Transação</St.TitleCategory>
          </ContainerFlex>

          <ContainerFlex padding={26}>
            <Grid xs={1} lg={2} gap={20}>
              <ContainerFlex>
                <Input
                  label="ID da transação"
                  value={form.idTransacao}
                  readOnly
                />

                <div>
                  <Grid xs={1} sm={2} gap={10}>
                    <ContainerFlex>
                      <Input
                        label="Valor da Parcela"
                        value={`R$ ${currencyMask(form.valorTransacao)}`}
                        readOnly
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Valor da Compra"
                        value={`R$ ${currencyMask(
                          form.quantidadeParcela *
                            parseFloat(form.valorTransacao),
                        )}`}
                        readOnly
                      />
                    </ContainerFlex>
                  </Grid>
                </div>

                <div>
                  <Grid xs={1} sm={2} gap={10}>
                    <ContainerFlex>
                      <Input
                        label="Data da transação"
                        value={form.dataTransacao}
                        readOnly
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Horário da transação"
                        value={form.horarioTransacao}
                        readOnly
                      />
                    </ContainerFlex>
                  </Grid>
                </div>

                <Input
                  label="Local da transação (loja)"
                  value={form.localTransacao}
                  readOnly
                />
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="Status da transação"
                  value={form.statusTransacao}
                  readOnly
                />
                <Input
                  label="Tipo de transação"
                  value={form.tipoTransacao}
                  readOnly
                />
                <Input
                  label="Quantidade de parcelas"
                  value={String(form.quantidadeParcela)}
                  readOnly
                />
                <Input
                  label="ID do atendimento"
                  value={form.idAtendimento}
                  readOnly
                />
              </ContainerFlex>
            </Grid>
          </ContainerFlex>

          <ContainerFlex row center color="#fbfbfb" marginBottom={15}>
            <St.TitleCategory>3. Motivo da disputa</St.TitleCategory>
          </ContainerFlex>
          <ContainerFlex
            row
            center
            wrap
            color="#fbfbfb"
            marginBottom={15}
            marginLeft={26}
          >
            <CardReason
              item={{
                ...RequestProposalDetail.data?.motivos[0],
                cpf: form.cpf?.unmasked,
              }}
            />
            <Button
              marginLeft={22}
              fitContent
              onClick={() => setDialogReasonShow(true)}
            >
              Ver mais
            </Button>
          </ContainerFlex>

          <ContainerFlex padding={26} marginTop={15}>
            <TextArea
              placeholder="Informe o motivo.."
              rows={10}
              onChange={(e) => setReason(e.target.value)}
              errorMessage={reasonError}
            />
          </ContainerFlex>

          <ContainerFlex row wrap end padding={24}>
            <Button onClick={goBack} outlined>
              Voltar
            </Button>

            <Button marginLeft={16} onClick={() => showDialog('approve')}>
              Estorno Aprovado
            </Button>

            <Button marginLeft={16} onClick={() => showDialog('disapprove')}>
              Estorno Negado
            </Button>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ConsultDisputeTable;
