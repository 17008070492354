export const formatterDataEvents = (stringData: string) => {
  const date = new Date(stringData);
  const now = new Date();

  const differenceMillisecond = now.getTime() - date.getTime();
  const differenceSeconds = Math.floor(differenceMillisecond / 1000);
  const differenceMinutes = Math.floor(differenceSeconds / 60);
  const differenceHours = Math.floor(differenceMinutes / 60);
  const differenceDays = Math.floor(differenceHours / 24);
  const differenceMonths = Math.floor(differenceDays / 30); // Aproximação, pode ser ajustada conforme necessário
  const differenceYears = Math.floor(differenceDays / 365);

  if (differenceYears > 0) {
    return `${date.toLocaleDateString('pt-BR')} há ${differenceYears} ano${
      differenceYears !== 1 ? 's' : ''
    }`;
  }
  if (differenceMonths > 0) {
    return `${date.toLocaleDateString('pt-BR')} há ${differenceMonths} mês${
      differenceMonths !== 1 ? 'es' : ''
    }`;
  }
  if (differenceDays > 0) {
    return `${date.toLocaleDateString('pt-BR')} há ${differenceDays} dia${
      differenceDays !== 1 ? 's' : ''
    }`;
  }
  if (differenceHours > 0) {
    return `${date.toLocaleDateString('pt-BR')} há ${differenceHours} hora${
      differenceHours !== 1 ? 's' : ''
    }`;
  }
  if (differenceMinutes > 0) {
    return `${date.toLocaleDateString('pt-BR')} há ${differenceMinutes} minuto${
      differenceMinutes !== 1 ? 's' : ''
    }`;
  }

  return 'Agora mesmo';
};
