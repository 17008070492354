import React from 'react';

import BoxGraphic from './components/boxGraphic';
import BoxMonth from './components/boxMonth';
import BoxEnd from './components/boxEnd';

import Constants from '~/utils/constants';
import Container from '~/components/container';

const Home: React.FC = () => {
  const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));

  const validateRoles = () => {
    if (userRoles.includes(Constants.ROLE_ADMIN)) {
      return true;
    }
    if (userRoles.includes(Constants.ROLE_SUPERVISOR)) {
      return true;
    }
    return false;
  };

  return (
    <Container title="Dashboard B.I.">
      {validateRoles() && (
        <>
          <BoxEnd />
          <BoxGraphic />
          <BoxMonth />
        </>
      )}
    </Container>
  );
};

export default Home;
