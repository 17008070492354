/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { InputHTMLAttributes, useState, useEffect, useRef } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { icons } from '~/assets';
import Bullet from '../bullet';
import ContainerFlex from '../containerFlex';
import * as St from './styles';

export enum MaskTypes {
  CPF = 'cpfMask',
  CEP = 'cepMask',
  DATE = 'dateMask',
  HOUR_MIN_SEC = 'hourMinSecMask',
  PHONE = 'phoneMask',
  CREDIT_CARD = 'creditCardMask',
}

export interface IOption {
  readonly id?: string | number;
  value: string;
}

export type InputProps = {
  name?: string;
  id?: string;
  label?: string;
  labelFor?: string;
  icon?: any;
  bulletColor?: string;
  disabled?: boolean;
  errorMessage?: string;
  error?: string;
  options: IOption[];
  value?: string;
  typeBenefit?: string;
  onChange?: (any: any) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  label,
  labelFor = '',
  value,
  typeBenefit,
  errorMessage,
  onChange,
  readOnly,
  options,
  bulletColor,
  disabled,
}: InputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef<HTMLDivElement>();

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (selectRef.current) {
        if (!selectRef.current?.contains(e.target as Node)) {
          setIsFocused(false);
        }
      }
    });
  }, []);

  const handleSelect = (el: IOption): void => {
    if (typeBenefit === 'VENDAS_PROTEGE') {
      onChange(el.value);
    } else {
      onChange(el.id);
    }
    setIsFocused(false);
  };

  const handleToggleFocus = () => {
    if (!readOnly) setIsFocused((prev) => !prev);
  };

  useEffect(() => {
    setIsFocused(false);
  }, [value]);

  let valueSelected: IOption;

  if (typeBenefit === 'VENDAS_PROTEGE') {
    if (value !== 'TODOS')
      valueSelected = options?.find((el) => el?.value === value);
    else valueSelected = options?.find((el) => el?.id === value);
  } else {
    valueSelected = options?.find((el) => el?.id === value);
  }

  return (
    <ContainerFlex row flex="0">
      <St.Container disabled={disabled} isFocused={isFocused}>
        {!!label && <label htmlFor={labelFor}>{label}</label>}
        <ContainerFlex row space ref={selectRef} onClick={handleToggleFocus}>
          <St.Value>{valueSelected?.value}</St.Value>
          <St.ContainerOptions isFocused={isFocused}>
            <div>
              {options?.map((el) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div className="option" onClick={() => handleSelect(el)}>
                  {el.value}
                </div>
              ))}
            </div>
          </St.ContainerOptions>
          <FiChevronDown />
        </ContainerFlex>
        {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
      </St.Container>
      {bulletColor && <Bullet color={bulletColor} margin={8} marginTop={27} />}
    </ContainerFlex>
  );
};

export default Input;
