import styled from 'styled-components';
import Popup from 'reactjs-popup';

export interface ITitleCategory {
  paddingLeft?: number;
  paddingRight?: number;
}

export const TitleCategory = styled.span<ITitleCategory>`
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 8px;
  background-color: #ffffff;

  padding-top: 22px;
  padding-bottom: 22px;
  padding-right: ${(p) => (p.paddingRight ? `${p.paddingRight}px` : '0px')};
  padding-left: ${(p) => (p.paddingLeft ? `${p.paddingLeft}px` : '0px')};
`;

export const IconCard = styled.img`
  height: 25px;
`;

export const ImgDocument = styled.img`
  height: 80px;
  margin-right: 16px;
`;

export const TitleFileChoose = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const TitleFileChooseSmall = styled.span`
  justify-content: center;
  font-size: 0.8em;
  font-weight: 300;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 16px;
  text-align: center;
  position: relative;
  top: 20px;
`;

export const ViewTerms = styled.span`
  color: #000;
  margin-right: 3px;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;

export const CustomPopup = styled(Popup)`
  &-content {
    border-radius: 10px;
    .containerModal {
      padding: 1.5rem;
      text-align: center;

      p {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      span {
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .counterContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const Loader2 = styled.div`
  .lds-default {
    display: inline-block;
    position: relative;
    width: 150px;
    height: 150px;
  }
  .lds-default div {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #e71037;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 74px;
    left: 132px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 44px;
    left: 124px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 22px;
    left: 104px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 14px;
    left: 74px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 22px;
    left: 44px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 44px;
    left: 22px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 74px;
    left: 14px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 104px;
    left: 22px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 124px;
    left: 44px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 132px;
    left: 74px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 124px;
    left: 104px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 104px;
    left: 124px;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
`;
