import styled from 'styled-components';

export const BoxNotFound = styled.div`
  width: 100%;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResultsNotFound = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding-top: 30px;

  img {
    width: 120px;
  }
`;

export const ResultsNotFoundTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  padding-top: 24px;
  padding-bottom: 5px;
  color: #444;
  text-align: center;
`;

export const ResultsNotFoundTagline = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #ccc;
  text-align: center;
`;
