import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Collapse from '~/components/collapse';
import Container from '~/components/container';
import ClientData from '../../Components/ClientData';
import * as St from '../../Services/styles';
import { SharedCardServicesContext } from '../../Card/Services/sharedCardServiceContext';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import SharedServiceContext from '../../Services/sharedServiceContext';
import { SolicitationAccountDetails } from '../Components/Solicitation';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Constants from '~/utils/constants';

export const AccountLeadDetails = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const {
    cpfGlobal,
    setCpfGlobal,
    cardInfos,
    availableProducts,
    getInfoCards,
    getClientInformation,
    getPhoto,
    getAvailableProducts,

    setEventId,
    setProduct,
    setEvent,
    product,
    loadingGlobal,
    opinion,
    eventId,

    executeEventNew,

    resetContext,
  } = useContext(SharedServiceContext);
  const {
    cpf,
    setCpf,
    getNotClientCards,

    resetContextCard,
  } = useContext(SharedCardServicesContext);

  useEffect(() => {
    return () => {
      resetContext();
      resetContextCard();
    };
  }, []);

  useEffect(() => {
    if (state) {
      setCpf(state?.cpfGlobal || state?.item?.cpf);
      setCpfGlobal(state?.cpfGlobal || state?.item?.cpf);
      setEvent(state?.event || state?.item?.tipoEvento);
      setEventId(state?.eventId || state?.item?.id);
      const aux = state?.item?.produto.value as string;
      setProduct(
        state?.product || {
          id: aux
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase(),
          value: state?.item?.produto.value,
        },
      );
    }
  }, [state]);

  useEffect(() => {
    if (cpf && cpfGlobal) {
      getClientInformation();
      getAvailableProducts();
      getInfoCards();
      getPhoto();
      getNotClientCards();
    }
  }, [cpf, cpfGlobal]);

  const GetLeadDetail = useApi(
    Endpoints.useCases.getCancelLeadDetails.replace('PARAM_ID', eventId),
    ApiMethod.GET,
  );
  useEffect(() => {
    if (eventId) {
      (async () => {
        await GetLeadDetail.callApi();
      })();
    }
  }, [eventId]);

  const handleStartCancellation = () => {
    const operator = JSON.parse(localStorage.getItem('@NovaEra:user'));

    const payload = {
      conta: {
        parecer: opinion,
        desistir: true,
        operador: {
          idOperador: localStorage.getItem(Constants.USER_ID),
          nome: operator?.nomeTitular,
        },
      },
    };

    executeEventNew({ hasFile: false, websocket: false, payload });
  };

  return (
    <Container>
      <St.BoxCard>
        <Collapse collapseHeight={70} initialState="open" title="1. Cliente">
          <ClientData
            solicitation
            cardInfos={cardInfos}
            availableProducts={availableProducts}
            eventStart
            product={
              state?.product || {
                id: product?.id,
                value: state?.item?.produto.value,
              }
            }
            event={state?.event || state?.item?.tipoEvento}
          />
        </Collapse>
        <SolicitationAccountDetails
          data={GetLeadDetail?.data?.tipoCancelamento}
        />
        <ContainerFlex row gap="10" end marginTop={20}>
          <Button
            outlined
            onClick={() => {
              history.goBack();
            }}
          >
            Voltar
          </Button>
          <Button
            loading={loadingGlobal.postExecuteEvent.loading}
            onClick={() => {
              handleStartCancellation();
            }}
            disabled={!opinion.length}
          >
            Desistir
          </Button>
        </ContainerFlex>
      </St.BoxCard>
    </Container>
  );
};
