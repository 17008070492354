import { dateMask } from './masks';

export const transformInGlobalDate = (value = '') => {
  const [day, month, year] = value.split('/');

  return `${year}-${month}-${day}`;
};

export const transformInNormalDate = (value = '') => {
  const [year, month, day] = value.split('-');

  return dateMask(`${day}${month}}${year}`);
};

export const returnMonth = (month: string | number) => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return months[month];
};
