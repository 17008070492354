import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderReportEmployee = [
  {
    id: 1,
    value: 'Nome completo',
    maxChar: 20,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
  },
  {
    id: 3,
    value: 'Status',
    maxChar: 20,
  },
  {
    id: 4,
    value: 'Bloqueado',
    maxChar: 10,
  },
  {
    id: 5,
    value: 'Perfil1',
    maxChar: 10,
  },
  {
    id: 6,
    value: 'Perfil2',
    maxChar: 10,
  },
];

export const BodyReportEmployee = (data: any) => {
  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: cpfMask(i.cpf).masked ? cpfMask(i.cpf).masked : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.ativo ? 'ATIVO' : 'INATIVO',
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.bloqueado ? 'SIM' : 'NÃO',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.perfis[0],
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.perfis[1] ? i.perfis[1] : '-',
        maxChar: 20,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });

  return items;
};

export const BodyReportEmployeeCSV = (data: any) => {
  console.log(data, 'Data');

  const dataCSV = [];
  data.forEach((csv) => {
    const [perfil1, perfil2] = csv.perfis;

    dataCSV.push({
      nome: csv.nome,
      cpf: csv.cpf,
      ativo: csv.ativo ? 'ATIVO' : 'INATIVO',
      bloqueado: csv.bloqueado ? 'SIM' : 'NÃO',
      perfil1: perfil1 || '-',
      perfil2: perfil2 || '-',
    });
  });
  return dataCSV;
};
