import React, { TextareaHTMLAttributes } from 'react';

import * as St from './styles';
import ContainerFlex from '~/components/containerFlex';

export type TextAreaProps = {
  errorMessage?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea: React.FC<TextAreaProps> = ({ errorMessage, ...props }) => {
  return (
    <ContainerFlex>
      <St.TextArea {...props} error={!!errorMessage} />
      {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
    </ContainerFlex>
  );
};

export default TextArea;
