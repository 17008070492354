/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { icons } from '~/assets';
import Button from '~/components/button';
import CardView from '~/components/cartView';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input from '~/components/input';
import Loading from '~/components/loading';
import PictureCard from '~/components/pictureCard';
import TextArea from '~/components/textarea';
import TitleApp from '~/components/title';
import { IReducers } from '~/contexts/proposal';
import { useProposal } from '~/hooks/contexts/useProposal';
import useApi, { ApiMethod } from '~/hooks/useApi';
import {
  IProposal,
  IProposalResult,
  IProposalT,
  IReference,
  IReferenceII,
} from '~/models/proposal';
import ProposalReason from '~/models/proposal-reason';
import PropostaContatoReferencia from '~/models/proposta-contatos-ref';
import ProposalComplPayload from '~/services/credit-table/proposal/compl/payload';
import Termos from '~/services/terms';
import endpoints from '~/services/endpoints';
import Constants from '~/utils/constants';
import { cpfMask, currencyMask, phoneMask } from '~/utils/masks';
import { ProposalStatus } from '~/utils/proposal-status';
import {
  emailValidator,
  IValidators,
  socialNameValidator,
  ValidatorsNames,
} from '~/utils/validators';
import {
  CertifaceMessage,
  CertifaceTitle,
} from './components/CertifaceMessage';
import { ItemDialogReasonDivider } from './components/DialogReasonComponents';
import ProposalReference from './components/ProposalReference';
import ProposalReferenceII from './components/ProposalReferenceII';
import ProposalResult from './components/ProposalResult';
import ProposalResultSPC from './components/ProposalResultSPC';
import ReasonDialogItem from './components/ReasonDialogItem';
import * as St from './styles';
import STATES from '~/assets/mocks/states.json';
import IResponseDefaultError from '~/services/default-error/response';
import Checkbox from '~/components/checkbox';
import Container from '~/components/container';

interface IErrors {
  parecer: string;
}

interface IRemakeProposalRanson {
  listaMotivosRefazer: IRemakeProposalRansonItem[];
}

interface IRemakeProposalRansonItem {
  descricao?: string;
  id?: string;
  idMotivo?: number;
}

const DetailProposalCT = (): JSX.Element => {
  const [dialogMessageStatus, setDialogMessageStatus] = useState(false);
  const [dialogApproveStatus, setDialogApproveStatus] = useState(false);
  const [dialogRefuseStatus, setDialogRefuseStatus] = useState(false);
  const [dialogIsOpenSave, setDialogIsOpenSave] = useState(false);
  const [dialogIsOpenSaveR, setDialogIsOpenSaveR] = useState(false);
  const [dialogReasonShow, setDialogReasonShow] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogClientSolicitation, setDialogClientSolicitation] = useState(
    false,
  );
  const [dialogRemakeProposalIsOpen, setDialogRemakeProposal] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState('');
  const [dialogCancelProposal, setDialogCancelProposal] = useState(false);
  const [dialogCancelProposalSucess, setDialogCancelProposalSucess] = useState(
    false,
  );

  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogMessageGoBack, setDialogMessageGoBack] = useState(false);
  const [reasonRemakeProposal, setReasonRemakeProposal] = useState(-1);
  const [errorReasonRemakeProposal, setErrorReasonRemakeProposal] = useState(
    '',
  );
  const [othersReasonRemakeProposal, setOthersReasonRemakeProposal] = useState(
    '',
  );
  const [parecer, setParecer] = useState('');
  const [proposalSPC, setProposalSPC] = useState<IProposal>({} as IProposal);
  const [diaVenc, setDiaVenc] = useState('');
  const [errors, setErrors] = useState<IErrors>({} as IErrors);
  const [validationErrors, setValidationErrors] = useState({} as IValidators);

  const { state } = useLocation<{ id: string; status: string }>();

  const history = useHistory();
  const {
    proposal,
    proposalResult,
    reference,
    referenceII,
    addReference,
    addReferenceII,
  } = useProposal();

  const [hasReference, setHasReference] = useState(false);
  const [hasReferenceII, setHasReferenceII] = useState(false);

  const [proposalT, setProposal] = useState<IProposalT>({
    proposal,
  } as IProposalT);

  const [, setProposalResult] = useState<IProposalResult>({
    proposalResult,
  } as IProposalResult);

  const [referenceT, setReference] = useState<IReference>({
    reference,
  } as IReference);

  const [referenceIIT, setReferenceII] = useState<IReferenceII>({
    referenceII,
  } as IReferenceII);

  const [reasons, setReasons] = useState<ProposalReason[]>([]);

  const [remakeRanson, setRemakeRanson] = useState<IRemakeProposalRansonItem[]>(
    [],
  );

  const RequestUserDocuments = useApi(
    `${endpoints.consult.searchUserDocuments}${state?.id}`,
    ApiMethod.GET,
  );

  const RequestProposalSPC = useApi<IProposal>(
    endpoints.consult.searchProposalSPC.replace('PARAM_ID_PROPOSTA', state?.id),
    ApiMethod.GET,
  );

  const RequestRemakeProposalRanson = useApi<IRemakeProposalRanson>(
    endpoints.consult.searchRemakeProposalRanson,
    ApiMethod.GET,
  );

  const RequestRemakeProposal = useApi<any>(
    endpoints.consult.searchRemakeProposal.replace(
      'PARAM_ID_PROPOSTA',
      state?.id,
    ),
    ApiMethod.POST,
  );

  const apiToAssign = useApi<IResponseDefaultError>(
    endpoints.consult.toAssign.replace('PARAM_ID_PROPOSTA', state?.id),
    ApiMethod.PUT,
  );

  const apiToUnassign = useApi<IResponseDefaultError>(
    endpoints.consult.toUnassign.replace('PARAM_ID_PROPOSTA', state?.id),
    ApiMethod.PUT,
  );

  const apiRefuse = useApi<PropostaContatoReferencia[]>(
    endpoints.register.proposalRefuse.replace('PARAM_ID_PROPOSTA', state?.id),
    ApiMethod.PUT,
    {
      motivoStatus: 1,
    },
  );

  const POSTReferenceIIAPI = useApi<IProposal>(
    `${endpoints.register.proposalReference}/${proposalSPC?.idProposta}`,
    ApiMethod.PUT,
  );

  const POSTReferenceAPI = useApi<IProposal>(
    `${endpoints.register.proposalReference}/${proposalSPC?.idProposta}`,
    ApiMethod.PUT,
  );

  const POSTProposalAPI = useApi<IProposal>(
    `${endpoints.update.proposal}`,
    ApiMethod.PUT,
  );

  const PUTCancelProposal = useApi(
    endpoints.register.cancelProposal.replace('PARAM_ID_PROPOSTA', state?.id),
    ApiMethod.PUT,
  );

  const apiKeepProposal = useApi(
    endpoints.register.proposalKeepAnalysis.replace(
      'PARAM_ID_PROPOSTA',
      state?.id,
    ),
    ApiMethod.PUT,
  );

  const apiApproveProposal = useApi<any>(
    `${endpoints.register.proposalApprove}`,
    ApiMethod.POST,
  );

  useEffect(() => {
    setProposal(proposal);
    setProposalResult(proposalResult);
    setReference(reference?.reference);
    setReferenceII(referenceII?.referenceII);
  }, [proposal, proposalResult, reference, referenceII]);

  useEffect(() => {
    RequestUserDocuments.callApi();
  }, []);

  useEffect(() => {
    if (reasonRemakeProposal !== 7) {
      setOthersReasonRemakeProposal('');
    }
  }, [reasonRemakeProposal]);

  useEffect(() => {
    if (!dialogRemakeProposalIsOpen) {
      setReasonRemakeProposal(-1);
      setErrorReasonRemakeProposal('');
    }
  }, [dialogRemakeProposalIsOpen]);

  useEffect(() => {
    popula();
  }, [POSTReferenceIIAPI.loading]);

  const handleValidation = () => {
    let errors = [
      emailValidator(proposalT.email),
      socialNameValidator(proposalT.nomeSocial, proposalT.nome),
      // dateValidator(form.dtNascimento?.masked, ValidatorsNames.dateBirth),
      // phoneValidator(form.phone?.masked),
      // defaultValidator(form.cep?.masked, ValidatorsNames.cep, 9),
      // testePipe
    ].reduce((memo, el) => ({ ...memo, ...el }), {});

    if (errors?.socialName) {
      setDialogError(true);
      setDialogErrorMessage(String(errors?.socialName));
      return;
    }

    setValidationErrors(errors);

    const hasErrors = Object.values(errors).filter((a) => a !== null).length;

    if (hasErrors) {
      return false;
    }
    return true;
  };

  const getProposalBySPC = async () => {
    const responseSPC = await RequestProposalSPC.callApi();

    const UF = responseSPC.data.proposta?.endereco?.uf;

    if (responseSPC.status === 200) {
      if (!UF || UF === '' || !STATES.some((a) => a.value === UF)) {
        setValidationErrors((prev) => ({
          ...prev,
          state: 'Selecione um estado',
        }));
      }
      return responseSPC.data;
    }
    return null;
  };

  const popula = async () => {
    const responseRemakeRanson = await RequestRemakeProposalRanson.callApi();

    if (responseRemakeRanson) {
      const orderedReasons = responseRemakeRanson?.data?.listaMotivosRefazer.sort(
        (a, b) => a.idMotivo - b.idMotivo,
      );
      setRemakeRanson(orderedReasons || []);
    }

    const dataProposalSPC = await getProposalBySPC();

    if (dataProposalSPC) {
      setDiaVenc(dataProposalSPC?.proposta?.diaVencimento);
      setReasons(dataProposalSPC?.proposta?.motivos || []);
      setProposalSPC(dataProposalSPC);
      loadProposalContacts(dataProposalSPC.proposta.contatos || []);
    }
  };

  const isParecerValid = () => {
    if (!parecer.trim().length) {
      setErrors((prev) => ({
        ...prev,
        parecer: 'Por favor informe o parecer.',
      }));

      return false;
    }
    setErrors((prev) => ({
      ...prev,
      parecer: null,
    }));
    return true;
  };

  const keepAnalysis = async () => {
    if (!isParecerValid()) return;

    const response = await apiKeepProposal.callApi({ motivo: parecer });

    if (response.status >= 200 && response.status < 300) {
      setDialogMessageStatus(true);
      setDialogMessage('Proposta mantida em análise!');
      setDialogMessageGoBack(true);
    } else if (response.status > 400) {
      setDialogError(true);
      setDialogErrorMessage(
        response.mensagem
          ? response.mensagem
          : 'Não foi possível atender a solicitação',
      );
    }
  };

  const getProposalPayload = (): ProposalComplPayload => {
    const payload: ProposalComplPayload = {
      cliente: {
        celular: proposalSPC.proposta?.celular,
        celular2: proposalSPC.proposta?.celular2,
        cpf: proposalSPC.proposta?.cpf,
        dataNascimento: proposalSPC.proposta?.dataNascimento,
        diaVencimento: diaVenc,
        email: proposalSPC.proposta?.cliente.email,
        endereco: {
          bairro: proposalSPC.proposta?.endereco.bairro,
          cep: proposalSPC.proposta?.endereco.cep,
          cidade: proposalSPC.proposta?.endereco.cidade,
          complemento: proposalSPC.proposta?.endereco.complemento,
          logradouro: proposalSPC.proposta?.endereco.logradouro,
          numero: proposalSPC.proposta?.endereco.numero,
          uf: proposalSPC.proposta?.endereco.uf,
          pais: '',
        },
        genero: proposalSPC.proposta?.cliente.genero,
        limiteCredito: proposalSPC.proposta?.limiteSugerido
          ? Number(proposalSPC.proposta?.limiteSugerido)
          : 0,
        nome: proposalSPC.proposta?.nome,
        nomeSocial: proposalT?.nomeSocial ? proposalT.nomeSocial : '',
        profissao: proposalSPC.proposta?.profissao || '',
        nomeEmpresa: proposalSPC.proposta?.nomeEmpresa || '',
        rendaInformada: proposalSPC.spc?.rendaPresumida
          ? Number(proposalSPC.spc?.rendaPresumida)
          : 0,
      },
      motivo: parecer,
      contaConsumo: proposalSPC?.spc?.contaConsumo
        ? Number(proposalSPC?.spc?.contaConsumo)
        : 0,
      empregoFormal: Boolean(proposalSPC?.proposta?.empregoFormal),
      profissao: proposalSPC?.proposta?.profissao,
      nomeEmpresa: proposalSPC?.proposta?.nomeEmpresa,
      propostaId: state?.id,
      negativacao: Boolean(proposalSPC?.spc?.negativacao),
      rendaPresumida: proposalSPC?.spc?.rendaPresumida
        ? Number(proposalSPC?.spc?.rendaPresumida)
        : 0,
      score: proposalSPC?.spc?.score ? Number(proposalSPC?.spc?.score) : 0,
      rendaInformada: proposalSPC?.spc?.rendaPresumida
        ? Number(proposalSPC?.spc?.rendaPresumida)
        : 0,
      cpfAnalisadoMenosNoventaDias: false,
      certiface: proposalSPC.proposta?.certiface,
    };

    return payload;
  };

  const setProposalAPI = async () => {
    proposalT.email = proposalT.email.trim();
    const response = await POSTProposalAPI.callApi(proposalT);

    if (response.status === 200 || response.status === 204) {
      return response;
    }
    return response;
  };

  const setReferenceAPI = async () => {
    const payloadRefI = {
      idContato: referenceT?.id ? referenceT.id : undefined,
      comentario: referenceT?.comentarioRef,
      nome: referenceT?.nomeRefe,
      relacao: referenceT?.relacaoCli,
      telefone: referenceT?.phoneRefe?.unmasked,
    };

    const payloadRefII = {
      idContato: referenceIIT?.id ? referenceIIT.id : undefined,
      comentario: referenceIIT?.comentarioRefII,
      nome: referenceIIT?.nomeRefeII,
      relacao: referenceIIT?.relacaoCliII,
      telefone: referenceIIT?.phoneRefeII?.unmasked,
    };

    if (payloadRefI.nome || payloadRefI.relacao || payloadRefI.telefone) {
      await POSTReferenceAPI.callApi([payloadRefI]);
    }

    if (payloadRefII.nome || payloadRefII.relacao || payloadRefII.telefone) {
      await POSTReferenceIIAPI.callApi([payloadRefII]);
    }
  };
  const [webSocketTimeout, setWebsocketTimeout] = useState(59);

  const [socketConnectionOpen, setSocketConnectionOpen] = useState(false);
  const [waitingSocketResponseModal, setWaitingSocketResponseModal] = useState(
    false,
  );

  const { readyState, lastJsonMessage } = useWebSocket(
    endpoints.register.postWebsocketProposal.replace(
      'PARAM_ID_PROPOSTA',
      state?.id,
    ),
    {},
    socketConnectionOpen,
  );
  useEffect(() => {
    let timer;
    if (socketConnectionOpen) {
      if (webSocketTimeout === 0) {
        history.push('/credit-table/proposal/proposal-resume', {
          status: 'ANALISE',
        });

        return;
      }
      timer = setTimeout(() => {
        setWebsocketTimeout((prev) => prev - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [socketConnectionOpen, webSocketTimeout]);

  useEffect(() => {
    if (readyState === 1 && !!lastJsonMessage) {
      if (lastJsonMessage?.aprovado === 'APROVADA') {
        setWaitingSocketResponseModal(false);

        history.push('/credit-table/proposal/proposal-resume', {
          nome: lastJsonMessage?.nomeCliente,
          email: lastJsonMessage?.email,
          vencimento: lastJsonMessage?.diaVencimento,
          limiteCompras: lastJsonMessage?.limiteCredito,
          limiteSaque: lastJsonMessage?.limiteSaqueConcedido,
          status: 'APROVADA',
        });
      } else {
        setWaitingSocketResponseModal(false);
        setDialogMessage('Proposta reprovada.');
        setDialogMessageStatus(true);
        setDialogMessageGoBack(true);
      }
    }
  }, [lastJsonMessage, readyState]);
  const approveProposal = async () => {
    if (!isParecerValid()) {
      setDialogApproveStatus(false);
      return;
    }

    const payload = getProposalPayload();

    // aqui
    const response = await apiApproveProposal.callApi(payload);

    if (response.data?.aprovado === 'PROCESSANDO') {
      setSocketConnectionOpen(true);
      setWaitingSocketResponseModal(true);
      setDialogApproveStatus(false);
    } else {
      setDialogMessage(response?.data?.mensagem ?? 'Proposta reprovada.');
      setDialogMessageStatus(true);
      setDialogMessageGoBack(true);

      setDialogApproveStatus(false);
    }
  };

  async function refuseProposal() {
    const response = await apiRefuse.callApi({
      motivoStatus: parecer,
    });

    if (response.status === 200 || response.status === 201) {
      setDialogMessage('Proposta recusada com sucesso!');

      setDialogRefuseStatus(false);
      setDialogMessageStatus(true);
      setDialogMessageGoBack(true);
      popula();
    } else if (response.status > 400) {
      setDialogRefuseStatus(false);
      setDialogError(true);
      setDialogErrorMessage(
        response.mensagem
          ? response.mensagem
          : 'Não foi possível atender a solicitação',
      );
    }
  }

  const onClickRefuse = () => {
    if (!isParecerValid()) return;
    setDialogRefuseStatus(true);
  };

  const onClickAprove = () => {
    if (!isParecerValid()) return;
    if (!handleValidation()) {
      setDialogError(true);
      setDialogErrorMessage('O Nome social não pode ser igual ao nome');
    } else {
      setDialogApproveStatus(true);
    }
  };

  const onClickSaveProposal = () => {
    if (!handleValidation()) return;

    if (!handleValidation()) {
      setDialogError(true);
      setDialogErrorMessage(ValidatorsNames.socialName);
      return;
    }

    setDialogIsOpenSave(true);
  };

  const loadProposalContacts = (items: PropostaContatoReferencia[] = []) => {
    let idx = 1;

    addReference(null);
    addReferenceII(null);
    setHasReference(false);

    if (items.length === 0) return;

    items.forEach((i) => {
      if (idx === 1) {
        const newRef: IReference = {
          id: i.id,
          comentarioRef: i.comentario,
          nomeRefe: i.nome,
          phoneRefe: phoneMask(i.telefone),
          relacaoCli: i.relacao,
        };

        const field: IReducers = {
          reference: newRef,
        };

        addReference(field);
        setHasReference(true);
      } else {
        const newRef: IReferenceII = {
          id: i.id,
          comentarioRefII: i.comentario,
          nomeRefeII: i.nome,
          phoneRefeII: phoneMask(i.telefone),
          relacaoCliII: i.relacao,
        };

        const field: IReducers = { referenceII: newRef };

        addReferenceII(field);
        setHasReferenceII(true);
      }

      idx += 1;
    });
  };

  const goBack = () => history.goBack();

  const addProposalAPI = async () => {
    const response = await setProposalAPI();

    if (response.status === 200 || response.status === 204) {
      setDialogMessage('Proposta alterada com sucesso!');

      setDialogIsOpenSave(false);
      setDialogMessageStatus(true);
      popula();
    } else {
      setDialogMessage(response?.mensagem || 'Não foi possível salvar!');
      setDialogIsOpenSave(false);
      setDialogMessageStatus(true);
    }
  };

  const addReferenceAPI = async () => {
    await setReferenceAPI();

    setDialogMessage('Referências alterada com sucesso!');

    setDialogIsOpenSaveR(false);
    setDialogMessageStatus(true);
  };

  const closeModalMessage = () => {
    if (dialogMessageGoBack) goBack();
    setDialogMessageStatus(false);
  };

  const certifaceMessage = () => {
    if (!proposalSPC?.proposta?.certiface) return '';

    const approved = proposalSPC?.proposta?.certiface?.resultado === 'APROVADO';

    return (
      <>
        <CertifaceTitle>CERTIFACE: </CertifaceTitle>
        <CertifaceMessage approved={approved}>
          {proposalSPC?.proposta?.certiface?.resultado} -&nbsp;
          {proposalSPC?.proposta?.certiface?.status}
        </CertifaceMessage>
      </>
    );
  };

  const toAssign = async () => {
    const payload = {
      idUsuario: localStorage.getItem(Constants.USER_ID),
    };

    const response = await apiToAssign.callApi(payload);

    if (response.status === 204) {
      setDialogMessageStatus(true);
      setDialogMessage('Proposta atribuída com sucesso!');
      popula();
    } else {
      setDialogErrorMessage(
        response.data.mensagem || 'Ocorreu um erro ao atribuir a proposta!',
      );
      setDialogError(true);
    }
  };

  const toUnassign = async () => {
    const response = await apiToUnassign.callApi();

    if (response.status === 204) {
      setDialogMessageStatus(true);
      setDialogMessage('Proposta desatribuída com sucesso!');
      popula();
    } else {
      setDialogErrorMessage(
        response.data.mensagem || 'Ocorreu um erro ao desatribuir a proposta!',
      );
      setDialogError(true);
    }
  };

  const onClickRemakeProposal = async () => {
    if (
      reasonRemakeProposal === -1 ||
      (reasonRemakeProposal === 7 && othersReasonRemakeProposal === '')
    )
      return setErrorReasonRemakeProposal('Preencha os campos corretamente!');

    const payload = {
      descricaoMotivo: `${
        remakeRanson[reasonRemakeProposal - 1].descricao
      } ${othersReasonRemakeProposal}`,
      idMotivo: remakeRanson[reasonRemakeProposal - 1].idMotivo,
    };

    setErrorReasonRemakeProposal('');

    try {
      const response = await RequestRemakeProposal.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        setDialogClientSolicitation(true);
        toggleRemakeProposalDialog();
      } else if (response.status > 400) {
        toggleRemakeProposalDialog();
        setDialogError(true);
        setDialogErrorMessage(
          response.mensagem
            ? response.mensagem
            : 'Não foi possivel refazer a proposta',
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelProposal = async (id) => {
    if (!isParecerValid()) return;

    const payload = {
      motivoStatus: parecer,
    };

    try {
      const response = await PUTCancelProposal.callApi(payload);
      if (response.status === 200) {
        setDialogCancelProposalSucess(true);
        setDialogCancelProposal(!dialogCancelProposal);
      } else if (response.status >= 400) {
        setDialogCancelProposal(!dialogCancelProposal);
        setDialogError(true);
        setDialogErrorMessage(
          response.mensagem
            ? response.mensagem
            : 'Não foi possivel cancelar a proposta',
        );
      }
    } catch (error) {
      setDialogMessageStatus(false);
      setDialogError(true);
      setDialogErrorMessage(error.message);
    }
  };

  const toggleRemakeProposalDialog = () =>
    setDialogRemakeProposal((prev) => !prev);

  const showOptionsRule = () => {
    const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
    let show = false;

    const idUser = localStorage.getItem(Constants.USER_ID);
    const idOper = proposalSPC.proposta?.operador?.idOperador ?? '';

    userRoles.forEach((i) => {
      if (
        i === Constants.ROLE_ADMIN ||
        i === Constants.ROLE_SUPERVISOR ||
        idUser === idOper
      ) {
        show = true;
      }
    });

    return show;
  };

  const handleTermsConditions = async () => {
    const filePath = Termos.termosCondicoes;

    window.open(filePath);
  };

  const handleTermsPrivacy = async () => {
    const filePath = Termos.termosPrivacidade;

    window.open(filePath);
  };

  return (
    <>
      <Loading
        status={
          apiRefuse.loading ||
          apiApproveProposal.loading ||
          POSTProposalAPI.loading ||
          POSTReferenceAPI.loading ||
          apiKeepProposal.loading ||
          apiToAssign.loading ||
          apiToUnassign.loading ||
          RequestProposalSPC.loading ||
          RequestRemakeProposal.loading ||
          PUTCancelProposal.loading
        }
      />

      <St.CustomPopup
        nested
        modal
        onClose={() => setWaitingSocketResponseModal(false)}
        open={waitingSocketResponseModal}
        position="center center"
        overlayStyle={{
          background: 'rgba(0, 0, 0, 0.7)',
        }}
        closeOnDocumentClick={false}
        contentStyle={{ background: 'white', width: '30%' }}
      >
        <div className="containerModal">
          <p>Informações foram salvas com sucesso!</p>
          <span>
            Aguarde alguns segundos, estamos finalizando a tua proposta!
          </span>
          <div className="counterContainer">
            <p style={{ position: 'absolute', top: '35%' }}>
              00:
              {webSocketTimeout < 10
                ? `0${webSocketTimeout}`
                : webSocketTimeout}
            </p>
            <St.Loader2>
              <div className="lds-default">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </St.Loader2>
          </div>
        </div>
      </St.CustomPopup>
      <Dialog
        icon={icons.confirmation}
        open={dialogMessageStatus}
        positiveLabel="Entendi"
        positiveAction={closeModalMessage}
        message={dialogMessage}
      />

      <Dialog
        icon={icons.exclamation}
        open={dialogCancelProposal}
        positiveLabel="SIM"
        positiveAction={() => handleCancelProposal(state.id)}
        message="Deseja mesmo cancelar a proposta?"
        negativeLabel="NÃO"
        negativeAction={() => history.push('/credit-table/proposal')}
      />

      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => setDialogError(false)}
        message={dialogErrorMessage}
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogCancelProposalSucess}
        positiveLabel="Entendi"
        positiveAction={goBack}
        message="Proposta Cancelada com sucesso"
      />

      <Dialog
        icon={icons.exclamation}
        open={dialogClientSolicitation}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogClientSolicitation(false);
          goBack();
        }}
        message="Solicitação enviada ao cliente! Obrigado."
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogApproveStatus}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogApproveStatus(false)}
        positiveLabel="Enviar"
        positiveAction={approveProposal}
        message="Enviar proposta para análise ?"
      />

      <Dialog
        icon={icons.confirmation}
        open={dialogRefuseStatus}
        negativeLabel="Cancelar"
        negativeAction={() => setDialogRefuseStatus(false)}
        positiveLabel="Recusar"
        positiveAction={refuseProposal}
        message="Você tem certeza que deseja recusar a proposta?"
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogIsOpenSave}
        message="Deseja salvar as alterações, feitas em seus dados pessoais?"
        negativeLabel="Cancelar"
        negativeAction={() => setDialogIsOpenSave(false)}
        positiveLabel="Continuar"
        positiveAction={addProposalAPI}
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogIsOpenSaveR}
        message="Deseja salvar as alterações, das suas referências?"
        negativeLabel="Cancelar"
        negativeAction={() => setDialogIsOpenSaveR(false)}
        positiveLabel="Continuar"
        positiveAction={addReferenceAPI}
      />

      <Dialog
        open={dialogRemakeProposalIsOpen}
        icon={icons.exclamation}
        negativeLabel="Voltar"
        negativeAction={toggleRemakeProposalDialog}
        positiveLabel="Confirmar"
        positiveAction={onClickRemakeProposal}
        title={<span style={{ color: 'red' }}>Refazer proposta</span>}
        widthInPercent={40}
      >
        <>
          <h4>Motivos:</h4>
          {remakeRanson.map((item: IRemakeProposalRansonItem) => (
            <>
              {item.idMotivo !== 7 ? (
                <Checkbox
                  message={item.descricao}
                  onChangeStatus={() => setReasonRemakeProposal(item.idMotivo)}
                  value={reasonRemakeProposal === item.idMotivo}
                  auto={false}
                />
              ) : (
                <ContainerFlex row style={{ maxWidth: 400 }}>
                  <Checkbox
                    message={item.descricao}
                    onChangeStatus={() =>
                      setReasonRemakeProposal(item.idMotivo)
                    }
                    value={reasonRemakeProposal === item.idMotivo}
                    auto={false}
                  />{' '}
                  &nbsp;
                  <Input
                    style={{ marginTop: 25 }}
                    disabled={reasonRemakeProposal !== 7}
                    value={othersReasonRemakeProposal}
                    onChange={(e) => {
                      setOthersReasonRemakeProposal(
                        e.target.value
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/[^\w\s]/gi, ''),
                      );
                    }}
                    maxLength={30}
                  />
                </ContainerFlex>
              )}
            </>
          ))}

          <St.ErrorMessage>{errorReasonRemakeProposal}</St.ErrorMessage>
        </>
      </Dialog>

      <Dialog
        open={dialogReasonShow}
        positiveLabel="Fechar"
        widthInPercent={60}
        positiveAction={() => setDialogReasonShow(false)}
      >
        <ContainerFlex center>
          <h2>Motivos</h2>
          <br />
          {reasons.map((item: ProposalReason) => (
            <ContainerFlex row marginBottom={18}>
              <div>
                <strong>CPF: </strong>
                {cpfMask(item.cpf).masked.trim()}
              </div>

              <ItemDialogReasonDivider />

              <div>
                <strong>STATUS: </strong>
                {item.status.trim()}
              </div>

              <ItemDialogReasonDivider />

              <div>
                <strong>DATA: </strong>
                {String(
                  moment(item.dataInclusao).format('DD/MM/YYYY HH:mm'),
                ).trim()}
              </div>

              <ItemDialogReasonDivider />

              <div>
                <strong>MOTIVO: </strong>
                {item.motivoStatus?.trim()}
              </div>
            </ContainerFlex>
          ))}
        </ContainerFlex>
      </Dialog>

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <ContainerFlex padding={26}>
              <TitleApp>Análise de Propostas</TitleApp>
            </ContainerFlex>

            <ContainerFlex>
              <Grid xs={1} lg={2}>
                <ContainerFlex>
                  <St.TitleCategory paddingLeft={26}>
                    1. Dados do Cadastro / Proposta
                  </St.TitleCategory>
                  <ProposalResult
                    data={proposalSPC}
                    errors={validationErrors}
                  />
                </ContainerFlex>

                <ContainerFlex>
                  <St.TitleCategory paddingLeft={20}>
                    2. Resultado
                  </St.TitleCategory>
                  <ProposalResultSPC data={proposalSPC} />
                </ContainerFlex>
                <ContainerFlex marginLeft={30} row horizontal wrap>
                  <div>
                    <ContainerFlex row horizontal marginLeft={60}>
                      <St.TitleFileChoose>CNH ou RG</St.TitleFileChoose>
                      <St.TitleFileChooseSmall>
                        (Frente e verso)
                      </St.TitleFileChooseSmall>
                    </ContainerFlex>

                    <ContainerFlex row horizontal>
                      <PictureCard
                        width="150"
                        height="100"
                        showOptions={
                          !!RequestUserDocuments.data?.documentoFrente
                        }
                        src={
                          RequestUserDocuments.data?.documentoFrente
                            ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoFrente}`
                            : icons.tableDocumentFront
                        }
                      />
                      <PictureCard
                        width="150"
                        height="100"
                        showOptions={
                          !!RequestUserDocuments.data?.documentoVerso
                        }
                        src={
                          RequestUserDocuments.data?.documentoVerso
                            ? `data:image/png;base64, ${RequestUserDocuments.data?.documentoVerso}`
                            : icons.tableDocumentBack
                        }
                      />
                    </ContainerFlex>
                    <St.TitleFileChooseSmall>
                      Clique no ícone para selecionar a imagem
                    </St.TitleFileChooseSmall>
                  </div>

                  <ContainerFlex marginLeft={20}>
                    <ContainerFlex row horizontal marginLeft={40}>
                      <St.TitleFileChoose>Certiface</St.TitleFileChoose>
                    </ContainerFlex>

                    <ContainerFlex row horizontal>
                      <PictureCard
                        width="150"
                        height="100"
                        showOptions={!!RequestUserDocuments.data?.fotoCertiface}
                        src={
                          RequestUserDocuments.data?.fotoCertiface
                            ? `data:image/png;base64, ${RequestUserDocuments.data?.fotoCertiface}`
                            : icons.userAvatar
                        }
                      />
                    </ContainerFlex>

                    <St.TitleFileChooseSmall>
                      Clique no ícone para selecionar a imagem
                    </St.TitleFileChooseSmall>
                  </ContainerFlex>
                </ContainerFlex>
              </Grid>
            </ContainerFlex>
            <CardView>
              <ContainerFlex row end padding={15}>
                {proposalSPC.status?.includes(ProposalStatus.ANALISE) && (
                  <Button
                    marginLeft={16}
                    onClick={onClickSaveProposal}
                    loading={
                      apiRefuse.loading ||
                      POSTProposalAPI.loading ||
                      POSTReferenceAPI.loading
                    }
                  >
                    Salvar
                  </Button>
                )}
              </ContainerFlex>
            </CardView>

            <St.TitleCategory paddingLeft={26}>
              3. Referências Pessoais
            </St.TitleCategory>

            <ContainerFlex padding={26}>
              <Grid xs={1} lg={2}>
                <ContainerFlex>
                  <St.TitleCategory>Referência I</St.TitleCategory>
                  <br />
                  <ProposalReference hasReference={hasReference} />
                </ContainerFlex>

                <ContainerFlex>
                  <St.TitleCategory paddingLeft={10}>
                    Referência II
                  </St.TitleCategory>
                  <br />
                  <ProposalReferenceII hasReference={hasReferenceII} />
                </ContainerFlex>
              </Grid>
            </ContainerFlex>
            <CardView>
              <ContainerFlex row end padding={15}>
                {proposalSPC.status?.includes(ProposalStatus.ANALISE) && (
                  <Button
                    marginLeft={16}
                    onClick={() => setDialogIsOpenSaveR(true)}
                    loading={
                      apiRefuse.loading ||
                      POSTProposalAPI.loading ||
                      POSTReferenceAPI.loading
                    }
                  >
                    Salvar
                  </Button>
                )}
              </ContainerFlex>
            </CardView>

            <St.TitleCategory paddingLeft={26}>
              4. Proposta de Crédito
            </St.TitleCategory>

            <ContainerFlex padding={26}>
              <Grid xs={1} lg={2} gap={30}>
                <ContainerFlex>
                  <Input
                    label="ID da proposta"
                    value={proposalSPC?.spc ? proposalSPC?.idProposta : ''}
                  />
                  <Input
                    label="Restrição"
                    value={
                      // eslint-disable-next-line no-nested-ternary
                      proposalSPC?.spc
                        ? proposalSPC?.spc.negativacao
                          ? 'Sim'
                          : 'Não'
                        : ''
                    }
                  />
                  <Input
                    label="Score"
                    value={
                      proposalSPC?.spc?.score
                        ? String(proposalSPC?.spc.score)
                        : ''
                    }
                  />
                  <Input
                    label="Limite sugerido pelo motor"
                    value={currencyMask(proposalSPC?.proposta?.limiteSugerido)}
                  />
                  <ContainerFlex>
                    <span style={{ fontSize: '12px', color: '#757575' }}>
                      Optin
                    </span>
                    <Checkbox
                      value={proposalSPC?.proposta?.optinTermoDeUso}
                      terms={
                        <>
                          Li e concordo com os
                          <St.ViewTerms onClick={handleTermsConditions}>
                            Termos e condições de uso
                          </St.ViewTerms>
                          e a
                          <St.ViewTerms onClick={handleTermsPrivacy}>
                            Política de privacidade
                          </St.ViewTerms>
                        </>
                      }
                      readonly
                    />
                    <Checkbox
                      terms="Desejo receber comunicação do meu interesse por WhatsApp"
                      value={proposalSPC?.proposta?.checkWhatsApp}
                      readonly
                    />
                  </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex>
                  <Input
                    label="Dia vencimento da fatura"
                    value={diaVenc}
                    maxLength={2}
                    onChange={(e) => setDiaVenc(e.target.value)}
                  />
                  <Input
                    label="Valor da restrição"
                    value={
                      proposalSPC?.spc
                        ? currencyMask(proposalSPC?.spc.valorRestricao)
                        : ''
                    }
                  />
                  <Input
                    label="Origem do Cadastro/Proposta"
                    value={
                      proposalSPC?.spc ? proposalSPC?.proposta?.origem : ''
                    }
                  />

                  <div>
                    <Grid xs={1} lg={2} gap={10}>
                      <ContainerFlex>
                        <Input
                          label="Loja"
                          value={
                            proposalSPC?.spc
                              ? proposalSPC?.proposta.origem !== 'LOJA'
                                ? proposalSPC?.proposta.origem
                                : proposalSPC?.proposta.empresa
                              : ''
                          }
                        />
                      </ContainerFlex>

                      <ContainerFlex>
                        <Input
                          label="Operador(a) de Venda"
                          value={
                            proposalSPC?.spc
                              ? proposalSPC?.proposta.origem !== 'LOJA'
                                ? proposalSPC?.proposta.origem
                                : proposalSPC.proposta?.atendente?.nomeOperador
                              : ''
                          }
                        />
                      </ContainerFlex>
                    </Grid>

                    <Grid xs={1} lg={2} gap={10}>
                      <ContainerFlex>
                        <Input
                          label="Origem Optin"
                          value={
                            proposalSPC?.spc
                              ? proposalSPC?.proposta.origem !== 'LOJA'
                                ? proposalSPC?.proposta.origem
                                : proposalSPC?.proposta.empresa
                              : ''
                          }
                        />
                      </ContainerFlex>

                      <ContainerFlex>
                        <Input
                          label="Data e Horário Optin"
                          value={
                            proposalSPC?.spc
                              ? moment(
                                  proposalSPC.proposta?.dataInclusao,
                                ).format('DD/MM/YYYY HH:mm:ss')
                              : ''
                          }
                        />
                      </ContainerFlex>
                    </Grid>
                  </div>
                </ContainerFlex>
              </Grid>
            </ContainerFlex>

            <St.TitleCategory paddingLeft={26}>5. Decisão</St.TitleCategory>

            {reasons && reasons.length > 0 && (
              <ContainerFlex marginLeft={22}>
                {reasons.length > 1 ? (
                  <ContainerFlex row center>
                    <ReasonDialogItem item={reasons[reasons.length - 1]} />

                    <Button
                      marginLeft={22}
                      fitContent
                      onClick={() => setDialogReasonShow(true)}
                    >
                      Ver mais
                    </Button>
                  </ContainerFlex>
                ) : (
                  reasons.length === 1 && (
                    <ReasonDialogItem item={reasons[reasons.length - 1]} />
                  )
                )}
              </ContainerFlex>
            )}

            <ContainerFlex>
              <ContainerFlex row marginLeft={22}>
                {certifaceMessage()}
              </ContainerFlex>

              <ContainerFlex row marginLeft={22}>
                <CertifaceTitle>SCORE B2E:</CertifaceTitle>
                <CertifaceMessage approved>
                  {proposalSPC.proposta?.prevencaoFraudeResponse?.pontuacao}
                </CertifaceMessage>
              </ContainerFlex>

              <ContainerFlex row marginLeft={22}>
                <CertifaceTitle>SUGESTÃO B2E:</CertifaceTitle>
                <CertifaceMessage approved>
                  {proposalSPC.proposta?.prevencaoFraudeResponse?.sugestao}
                </CertifaceMessage>
              </ContainerFlex>
              <ContainerFlex row marginLeft={22}>
                <CertifaceTitle>SITUAÇÃO SPC:</CertifaceTitle>
                <CertifaceMessage
                  approved={proposalSPC.spc?.validadoSpc === true}
                >
                  {proposalSPC.spc?.validadoSpc === true
                    ? 'CPF validado'
                    : 'CPF não foi validado'}
                </CertifaceMessage>
              </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex padding={26}>
              <TextArea
                placeholder="Digitar parecer.."
                rows={10}
                value={parecer}
                onChange={(e) => setParecer(e.target.value)}
                errorMessage={errors.parecer}
              />
            </ContainerFlex>

            {proposalSPC.status?.includes(ProposalStatus.PROCESSANDO) ? (
              <ContainerFlex row end wrap padding={24}>
                <Button marginLeft={16} marginBottom={15} onClick={goBack}>
                  Voltar
                </Button>
              </ContainerFlex>
            ) : (
              <ContainerFlex row end wrap padding={24}>
                {proposalSPC.status?.includes(
                  ProposalStatus.PENDENTE ||
                    ProposalStatus.PENDENTE_ANALISE ||
                    ProposalStatus.PENDENTE_MESA,
                ) && (
                  <Button
                    outlined
                    marginBottom={15}
                    onClick={toAssign}
                    loading={apiToAssign.loading}
                    disabled={apiToAssign.loading}
                  >
                    Atribuir
                  </Button>
                )}

                {proposalSPC.status?.includes(
                  ProposalStatus.ANALISE || ProposalStatus.ANALISE_MESA_CREDITO,
                ) &&
                  showOptionsRule() && (
                    <Button
                      outlined
                      marginBottom={15}
                      onClick={toUnassign}
                      loading={apiToUnassign.loading}
                      disabled={apiToUnassign.loading}
                    >
                      Desatribuir
                    </Button>
                  )}

                {proposalSPC.status?.includes(ProposalStatus.ANALISE) &&
                  showOptionsRule() && (
                    <Button
                      outlined
                      marginBottom={15}
                      marginLeft={16}
                      onClick={onClickRefuse}
                      loading={apiRefuse.loading}
                      disabled={apiRefuse.loading}
                    >
                      Recusar
                    </Button>
                  )}

                {proposalSPC.status?.includes(ProposalStatus.ANALISE) &&
                  showOptionsRule() && (
                    <Button
                      marginLeft={16}
                      marginBottom={15}
                      onClick={toggleRemakeProposalDialog}
                      loading={RequestRemakeProposal.loading}
                      disabled={RequestRemakeProposal.loading}
                    >
                      Refazer Proposta
                    </Button>
                  )}
                <br />

                {proposalSPC.status?.includes(ProposalStatus.ANALISE) &&
                  showOptionsRule() && (
                    <Button
                      marginLeft={16}
                      marginBottom={15}
                      onClick={keepAnalysis}
                      loading={apiKeepProposal.loading}
                      disabled={apiKeepProposal.loading}
                    >
                      Manter em Análise
                    </Button>
                  )}
                <br />

                {proposalSPC.status?.includes(ProposalStatus.ANALISE) &&
                  showOptionsRule() && (
                    <Button
                      marginLeft={16}
                      marginBottom={15}
                      onClick={onClickAprove}
                      loading={apiApproveProposal.loading}
                      disabled={apiApproveProposal.loading}
                    >
                      Analisar
                    </Button>
                  )}

                <Button
                  outlined
                  marginLeft={16}
                  marginBottom={15}
                  onClick={() => setDialogCancelProposal(true)}
                  disabled={!parecer}
                >
                  Cancelar Proposta
                </Button>

                <Button marginLeft={16} marginBottom={15} onClick={goBack}>
                  Voltar
                </Button>
              </ContainerFlex>
            )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default DetailProposalCT;
