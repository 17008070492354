import React, { createContext, useCallback, useState } from 'react';
import {
  IProposalResult,
  IProposalT,
  IReference,
  IReferenceII,
} from '~/models/proposal';

export interface IProposalContext {
  proposal: any;
  proposalResult: object;
  reference: any;
  referenceII: any;
  addProposal(proposal: IReducers): Promise<void>;
  addProposalResult(proposalResult: IReducers): Promise<void>;
  addReference(reference: IReducers): Promise<void>;
  addReferenceII(referenceII: IReducers): Promise<void>;
}

export interface IReducers {
  proposal?: IProposalT;
  proposalResult?: IProposalResult;
  reference?: IReference;
  referenceII?: IReferenceII;
}

const ProposalContext = createContext<IProposalContext>({} as IProposalContext);

const ProposalProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IReducers>({} as IReducers);

  const addProposal = useCallback(async (proposal) => {
    setData((prev) => ({ ...prev, proposal }));
  }, []);

  const addProposalResult = useCallback(async (proposalResult) => {
    setData((prev) => ({ ...prev, proposalResult }));
  }, []);

  const addReference = useCallback(async (reference) => {
    setData((prev) => ({ ...prev, reference }));
  }, []);

  const addReferenceII = useCallback(async (referenceII) => {
    setData((prev) => ({ ...prev, referenceII }));
  }, []);

  return (
    <ProposalContext.Provider
      value={{
        proposal: data.proposal,
        proposalResult: data.proposalResult,
        reference: data.reference,
        referenceII: data.referenceII,
        addProposal,
        addProposalResult,
        addReference,
        addReferenceII,
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
};

export { ProposalProvider, ProposalContext };
