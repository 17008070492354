/* eslint-disable no-param-reassign */
import moment from 'moment';
import { cpfMask } from '~/utils/masks';
import { IListColumn } from '~/models/listCol';

export const HeaderReportCancelOdonto = [
  {
    id: 1,
    value: 'Nome titular',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Venda',
    maxChar: 16,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Data Cancelamento',
    maxChar: 16,
  },
  {
    id: 5,
    value: 'Status cartão',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Dependente',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Tipo cancelamento',
    maxChar: 15,
  },
  {
    id: 8,
    value: 'UF',
    maxChar: 6,
    textAlign: 'center',
  },
];

export const BodyReportCancelOdonto = (data: any) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    if (i.dataCancelamento !== undefined) {
      const item: IListColumn[] = [
        // {
        //   type: 'id',
        //   value: x++,
        //   maxChar: 5,
        // },
        {
          value: i.nomeTitular,
          maxChar: 15,
        },
        {
          value: i.cpf ? cpfMask(i.cpf).masked : '',
          maxChar: 13,
          textAlign: 'center',
        },
        {
          value: i.dataVenda ? moment(i.dataVenda).format('DD/MM/YYYY') : '-',
          maxChar: 16,
          textAlign: 'center',
        },
        {
          value: i.dataCancelamento
            ? moment(i.dataCancelamento).format('DD/MM/YYYY')
            : '-',
          maxChar: 16,
        },
        {
          value: i.statusCartao,
          maxChar: 7,
          textAlign: 'center',
        },
        {
          value: i.dependente ? i.dependente : '-',
          maxChar: 15,
          textAlign: 'center',
        },
        {
          value: i.tipo,
          maxChar: 15,
        },
        {
          value: i.uf,
          maxChar: 6,
          textAlign: 'center',
        },
      ];

      items.push(item);
    }
  });
  return items;
};

export const BodyReportCancelOdontoCSV = (data: any) => {
  const dataCSV = [];

  data.forEach((csv) => {
    dataCSV.push({
      nomeCliente: csv.nomeTitular,
      cpf: csv.cpf,
      dataVenda: csv.dataVenda,
      nomeOperador: csv.operador,
      dataCancelamento: csv.dataCancelamento,
      statusCartao: csv.statusCartao,
      nomeDependente: csv.nomeDependente,
      origemCancelamento: csv.origemCancelamento,
      motivoCancelamento: csv.motivoCancelamento,
      tipoCancelamento: csv.tipo,
      uf: csv.uf,
      dataConciliacao: csv.dataConciliacao,
      idAutorizador: csv.idAutorizador,
    });
  });

  return dataCSV;
};

export const BodyTotalCancelOdonto = (data: any) => {
  const totalTitular = data.reduce((acc, cur) => {
    return acc + 1;
  }, 0);
  return totalTitular;
};
