import React from 'react';
import nofFound from '~/assets/icons/loupe.png';
import * as S from './styles';

const ResultNotFound: React.FC = () => {
  return (
    <S.BoxNotFound>
      <S.ResultsNotFound>
        <img src={nofFound} alt="Nenhum resultado encontrado" />
        <S.ResultsNotFoundTitle>
          Nenhum resultado encontrado!
        </S.ResultsNotFoundTitle>
        <S.ResultsNotFoundTagline>
          Por favor, tente consultar com parâmetros diferentes.
        </S.ResultsNotFoundTagline>
      </S.ResultsNotFound>
    </S.BoxNotFound>
  );
};

export default ResultNotFound;
