/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Container from '~/components/container';
import * as St from '../styles';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Checkbox from '~/components/checkbox';
import Button from '~/components/button';
import {
  cepMask,
  cnpjMask,
  cpfMask,
  dateMask,
  IDefaultReturn,
  phoneMask,
} from '~/utils/masks';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Tabs from '~/components/tab';

interface Address {
  cep?: string;
  logradouro?: string;
  numero?: string;
  cidade?: string;
  bairro?: string;
  complemento?: string;
  uf?: string;
  localidade?: string;
  pais?: string;
}

interface IForm {
  nome?: string;
  cpf?: IDefaultReturn;
  cnpj?: IDefaultReturn;
  telefone?: IDefaultReturn;
  dataNascimento?: IDefaultReturn;
  razaoSocial?: string;
  email?: string;
  nomeFantasia?: string;
  cep?: IDefaultReturn;
  logradouro?: string;
  numero?: string;
  cidade?: string;
  bairro?: string;
  complemento?: string;
  uf?: string;
  localidade?: string;
  pais?: string;
  representanteLegal?: string;
  cargoRepresentante?: string;
  cpfRepresentante?: IDefaultReturn;
  dataNascimentoRepresentante?: IDefaultReturn;
}

interface IEstablishment {
  nome?: string;
  cpf?: string;
  cnpj?: string;
  telefone?: string;
  dataNascimento?: string;
  email?: string;
  nomeFantasia?: string;
  endereco?: Address;
  representanteLegal?: string;
  cargoRepresentante?: string;
  cpfRepresentanteLegal?: string;
  dataNascimentoRepresentante?: string;
}

interface IState {
  id: string;
}

type TypeOfPerson = 'fisica' | 'juridica' | null;

const CPFEstablishment = ({ form, setForm }) => {
  const [dialogError, setDialogError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const history = useHistory();

  const { state } = useLocation<IState>();

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const RequestCreateEstablishment = useApi(
    Endpoints.establishments.postCreateEstablishment,
    ApiMethod.POST,
  );

  const RequestEditEstablishment = useApi(
    Endpoints.establishments.putEditEstablishment.replace(
      'PARAM_ID',
      state?.id,
    ),
    ApiMethod.PUT,
  );

  const isButtonDisabled = () => {
    const isCPF = form?.cpf?.masked?.length === 14;
    const phoneFilled = form?.telefone?.masked?.length === 15;
    const emailFilled = form?.email;

    return !(isCPF && phoneFilled && emailFilled);
  };

  const handleErrors = () => {
    const requiredFields = [
      'cpf',
      'email',
      'nome',
      'dataNascimento',
      'telefone',
      'cep',
      'logradouro',
      'cidade',
      'bairro',
      'numero',
    ];

    const newErrors: { [key: string]: string } = {};

    const fieldsWithUnmasked = ['cpf', 'telefone', 'cep', 'dataNascimento'];

    requiredFields.forEach((field) => {
      const value = (form as any)[field];

      if (fieldsWithUnmasked.includes(field)) {
        if (!value || !value.unmasked) {
          newErrors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } é obrigatório.`;
        }
      } else if (!value || (typeof value === 'string' && value.trim() === '')) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } é obrigatório.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (handleErrors()) {
      const payload = {
        cpf: form?.cpf?.unmasked,
        email: form?.email,
        nomeCompleto: form?.nome,
        dataNascimento: moment(
          form?.dataNascimento?.masked,
          'DD/MM/YYYY',
        ).format('YYYY-MM-DD'),
        telefone: form?.telefone?.unmasked,
        nomeFantasia: form?.nomeFantasia,
        endereco: {
          cep: form?.cep?.unmasked,
          logradouro: form?.logradouro,
          cidade: form?.cidade,
          uf: form?.uf,
          numero: form?.numero,
          complemento: form?.complemento,
          pais: 'BR',
        },
      };

      try {
        const response = state?.id
          ? await RequestEditEstablishment.callApi(payload)
          : await RequestCreateEstablishment.callApi(payload);

        if (response.status === 200) {
          setDialogError(true);
          setMessageError(
            state?.id
              ? 'Cliente editado com sucesso'
              : 'Cliente criado com sucesso',
          );
        } else if (response.status >= 404) {
          setDialogError(true);
          setMessageError(response.data.mensagem);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
          history.push('/teu-pag');
        }}
        message={messageError}
      />
      <Loading
        status={
          RequestCreateEstablishment.loading || RequestEditEstablishment.loading
        }
      />
      <ContainerFlex>
        <Grid xs={1} sm={2} lg={2}>
          <ContainerFlex>
            <ContainerFlex>
              <Input
                label="CPF"
                name="cpf"
                maxLength={14}
                placeholder="000.000.000-00"
                value={form?.cpf?.masked}
                mask={MaskTypes.CPF}
                onChange={handleChange}
                errorMessage={errors.cpf}
                autoComplete="off"
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Nome completo"
                name="nome"
                value={form?.nome}
                onChange={handleChange}
                errorMessage={errors.nome}
                autoComplete="off"
              />
            </ContainerFlex>

            <ContainerFlex>
              <Input
                label="Telefone"
                name="telefone"
                value={form?.telefone?.masked}
                placeholder="(00) 00000-0000"
                maxLength={15}
                onChange={handleChange}
                mask={MaskTypes.PHONE}
                errorMessage={errors.telefone}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Data de nascimento"
                name="dataNascimento"
                value={form?.dataNascimento?.masked}
                maxLength={10}
                onChange={handleChange}
                mask={MaskTypes.DATE}
                errorMessage={errors.dataNascimento}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="E-mail"
                name="email"
                value={form?.email}
                onChange={handleChange}
                errorMessage={errors?.email}
              />
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex marginLeft={16}>
            <ContainerFlex>
              <Input
                label="CEP"
                name="cep"
                placeholder="00000-000"
                value={form?.cep?.masked}
                onChange={handleChange}
                maxLength={9}
                mask={MaskTypes.CEP}
                errorMessage={errors.cep}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Endereço"
                name="logradouro"
                value={form?.logradouro}
                onChange={handleChange}
                errorMessage={errors.logradouro}
              />
            </ContainerFlex>
            <ContainerFlex row space gap="24">
              <ContainerFlex>
                <Input
                  label="Número"
                  name="numero"
                  value={form?.numero}
                  onChange={handleChange}
                  errorMessage={errors.numero}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Complemento"
                  name="complemento"
                  value={form?.complemento}
                  onChange={handleChange}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex row space gap="24">
              <ContainerFlex>
                <Input
                  label="Cidade"
                  name="cidade"
                  value={form?.cidade}
                  onChange={handleChange}
                  errorMessage={errors.cidade}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Bairro"
                  name="bairro"
                  value={form?.bairro}
                  onChange={handleChange}
                  errorMessage={errors.bairro}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Nome fantasia"
                name="nomeFantasia"
                value={form?.nomeFantasia}
                onChange={handleChange}
                errorMessage={errors?.nomeFantasia}
              />
            </ContainerFlex>
          </ContainerFlex>
        </Grid>
        <ContainerFlex row end marginTop={30} gap="30">
          <Button outlined fitContent onClick={() => history.goBack()}>
            Voltar
          </Button>
          <Button
            fitContent
            onClick={handleSubmit}
            disabled={isButtonDisabled()}
          >
            {state?.id ? <span>Salvar</span> : <span>Cadastrar</span>}
          </Button>
        </ContainerFlex>
      </ContainerFlex>
    </>
  );
};

const CNPJEstablishment = ({ form, setForm }) => {
  const [dialogError, setDialogError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const history = useHistory();

  const { state } = useLocation<IState>();

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const RequestCreateEstablishment = useApi(
    Endpoints.establishments.postCreateEstablishment,
    ApiMethod.POST,
  );

  const RequestEditEstablishment = useApi(
    Endpoints.establishments.putEditEstablishment.replace(
      'PARAM_ID',
      state?.id,
    ),
    ApiMethod.PUT,
  );

  const isButtonDisabled = () => {
    const isCNPJ = form?.cnpj?.masked?.length === 18;
    const representanteLegalFilled = form?.representanteLegal;

    return !(isCNPJ && representanteLegalFilled);
  };

  const handleErrors = () => {
    const requiredFields = [
      'cnpj',
      'email',
      'telefone',
      'razaoSocial',
      'nomeFantasia',
      'representanteLegal',
      'cpfRepresentante',
      'dataNascimentoRepresentante',
      'cargoRepresentante',
      'nomeFantasia',
      'cep',
    ];

    const newErrors: { [key: string]: string } = {};

    const fieldsWithUnmasked = [
      'cnpj',
      'cpfRepresentante',
      'telefone',
      'cep',
      'dataNascimentoRepresentante',
    ];

    requiredFields.forEach((field) => {
      const value = (form as any)[field];

      if (fieldsWithUnmasked.includes(field)) {
        if (!value || !value.unmasked) {
          newErrors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } é obrigatório.`;
        }
      } else if (!value || (typeof value === 'string' && value.trim() === '')) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } é obrigatório.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (handleErrors()) {
      const payload = {
        cnpj: form?.cnpj?.unmasked,
        email: form?.email,
        telefone: form?.telefone?.unmasked,
        nomeFantasia: form?.nomeFantasia,
        cpfRepresentanteLegal: form?.cpfRepresentante?.unmasked,
        representanteLegal: form?.representanteLegal,
        cargoRepresentante: form?.cargoRepresentante,
        nomeCompleto: form?.razaoSocial,
        dataNascimentoRepresentante: form.dataNascimentoRepresentante
          ? moment(
              form.dataNascimentoRepresentante.masked,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD')
          : '',
        endereco: {
          cep: form?.cep?.unmasked,
          logradouro: form?.logradouro,
          cidade: form?.cidade,
          uf: form?.uf,
          numero: form?.numero,
          complemento: form?.complemento,
          pais: 'BR',
        },
      };

      try {
        const response = state?.id
          ? await RequestEditEstablishment.callApi(payload)
          : await RequestCreateEstablishment.callApi(payload);

        if (response.status === 200) {
          setDialogError(true);
          setMessageError(
            state?.id
              ? 'Cliente editado com sucesso!'
              : 'Cliente criado com sucesso!',
          );
        } else if (response.status >= 404) {
          setDialogError(true);
          setMessageError(response.data.mensagem);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Loading
        status={
          RequestCreateEstablishment.loading || RequestEditEstablishment.loading
        }
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
          history.push('/teu-pag');
        }}
        message={messageError}
      />
      <ContainerFlex>
        <Grid xs={1} sm={2} lg={2}>
          <ContainerFlex>
            <ContainerFlex>
              <Input
                label="CNPJ"
                name="cnpj"
                placeholder="00.000.000/0000-00"
                maxLength={18}
                value={form?.cnpj?.masked}
                onChange={handleChange}
                mask={MaskTypes.CNPJ}
                errorMessage={errors.cnpj}
                autoComplete="off"
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Razão Social"
                name="razaoSocial"
                value={form?.razaoSocial}
                onChange={handleChange}
                errorMessage={errors.razaoSocial}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Nome fantasia"
                name="nomeFantasia"
                value={form?.nomeFantasia}
                onChange={handleChange}
                errorMessage={errors.nomeFantasia}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Telefone"
                name="telefone"
                value={form?.telefone?.masked}
                placeholder="(00) 00000-0000"
                maxLength={15}
                onChange={handleChange}
                mask={MaskTypes.PHONE}
                errorMessage={errors.telefone}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="E-mail"
                name="email"
                value={form?.email}
                onChange={handleChange}
                errorMessage={errors?.email}
              />
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex marginLeft={16}>
            <ContainerFlex>
              <Input
                label="CEP"
                name="cep"
                placeholder="00000-000"
                value={form?.cep?.masked}
                onChange={handleChange}
                maxLength={9}
                mask={MaskTypes.CEP}
                errorMessage={errors.cep}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Endereço"
                name="logradouro"
                value={form?.logradouro}
                onChange={handleChange}
                errorMessage={errors.logradouro}
              />
            </ContainerFlex>
            <ContainerFlex row space gap="24">
              <ContainerFlex>
                <Input
                  label="Número"
                  name="numero"
                  value={form?.numero}
                  onChange={handleChange}
                  errorMessage={errors.numero}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Complemento"
                  name="complemento"
                  value={form?.complemento}
                  onChange={handleChange}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex row space gap="24">
              <ContainerFlex>
                <Input
                  label="Cidade"
                  name="cidade"
                  value={form?.cidade}
                  onChange={handleChange}
                  errorMessage={errors.cidade}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Bairro"
                  name="bairro"
                  value={form?.bairro}
                  onChange={handleChange}
                  errorMessage={errors.bairro}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex />
            <ContainerFlex />
          </ContainerFlex>
        </Grid>
        <ContainerFlex marginTop={40} marginBottom={30}>
          <h3>Representante Legal</h3>
        </ContainerFlex>
        <Grid xs={1} sm={2} lg={2}>
          <ContainerFlex>
            <ContainerFlex>
              <Input
                label="Nome completo"
                name="representanteLegal"
                value={form?.representanteLegal}
                onChange={handleChange}
                errorMessage={errors.representanteLegal}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Data de nascimento"
                name="dataNascimentoRepresentante"
                value={form?.dataNascimentoRepresentante?.masked}
                onChange={handleChange}
                maxLength={10}
                mask={MaskTypes.DATE}
                errorMessage={errors.dataNascimentoRepresentante}
              />
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex marginLeft={16}>
            <ContainerFlex>
              <Input
                label="CPF"
                name="cpfRepresentante"
                maxLength={14}
                placeholder="000.000.000-00"
                value={form?.cpfRepresentante?.masked}
                onChange={handleChange}
                mask={MaskTypes.CPF}
                errorMessage={errors.cpfRepresentante}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Cargo"
                name="cargoRepresentante"
                value={form?.cargoRepresentante}
                onChange={handleChange}
                errorMessage={errors.cargoRepresentante}
              />
            </ContainerFlex>
          </ContainerFlex>
        </Grid>
        <ContainerFlex row end marginTop={30} gap="30">
          <Button outlined fitContent onClick={() => history.goBack()}>
            Voltar
          </Button>
          <Button
            fitContent
            onClick={handleSubmit}
            disabled={isButtonDisabled()}
          >
            {state?.id && form?.cnpj ? (
              <span>Salvar</span>
            ) : (
              <span>Cadastrar</span>
            )}
          </Button>
        </ContainerFlex>
      </ContainerFlex>
    </>
  );
};

const RegisterCustomerTeuPag = () => {
  const [form, setForm] = useState<IForm>();
  const [typeOfPerson, setTypeOfPerson] = useState<TypeOfPerson>(null);
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [messageError, setMessageError] = useState('');

  const history = useHistory();

  const { state } = useLocation<IState>();

  console.log(state?.id);

  const RequestGetDetailsEstablishment = useApi<IEstablishment>(
    Endpoints.establishments.getDetailEstablishments.replace(
      'PARAM_ID',
      state?.id,
    ),
    ApiMethod.GET,
  );

  const GetAddressByCep = useApi<Address>(
    Endpoints.consult.getAddressByCep.replace('PARAM_CEP', form?.cep?.unmasked),
    ApiMethod.GET,
  );

  const getDetailsEstablishments = async () => {
    try {
      const response = await RequestGetDetailsEstablishment.callApi();
      const { data } = response;
      if (response.status === 200) {
        setForm({
          cpf: cpfMask(data.cpf),
          cnpj: cnpjMask(data.cnpj),
          nome: data?.nome,
          email: data.email,
          telefone: phoneMask(data.telefone),
          dataNascimento: {
            masked: moment(data?.dataNascimento, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            ),
            unmasked: data?.dataNascimento,
          },
          cep: cepMask(data.endereco.cep),
          logradouro: data.endereco?.logradouro,
          numero: data.endereco?.numero,
          complemento: data.endereco?.complemento,
          cidade: data.endereco?.cidade,
          bairro: data.endereco?.bairro,
          uf: data.endereco?.uf,
          nomeFantasia: data?.nomeFantasia,
          razaoSocial: data?.nome,
          cpfRepresentante: cpfMask(data.cpfRepresentanteLegal),
          representanteLegal: data.representanteLegal,
          cargoRepresentante: data.cargoRepresentante,
          dataNascimentoRepresentante: {
            masked: moment(
              data?.dataNascimentoRepresentante,
              'YYYY-MM-DD',
            ).format('DD/MM/YYYY'),
            unmasked: data?.dataNascimentoRepresentante,
          },
        });
        setTypeOfPerson(data?.cnpj ? 'juridica' : 'fisica');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCep = async () => {
    const response = await GetAddressByCep.callApi();
    if (response.status >= 200 && response.status <= 300) {
      setForm((prev) => ({
        ...prev,
        logradouro: response?.data?.logradouro,
        bairro: response?.data?.bairro,
        cidade: response?.data?.localidade,
        uf: response?.data?.uf,
      }));
    }
  };

  useEffect(() => {
    if (form?.cep?.unmasked?.length === 8) {
      handleGetCep();
    }
  }, [form?.cep]);

  useEffect(() => {
    if (state?.id) {
      getDetailsEstablishments();
    }
  }, [state?.id]);

  const handleInitialTab = (person) => {
    if (person === 'juridica') {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <Loading status={RequestGetDetailsEstablishment.loading} />
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
          history.push('/teu-pag');
        }}
        message={messageError}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogMessage}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogMessage(false);
        }}
        message={messageError}
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <Tabs initialTab={handleInitialTab(typeOfPerson)}>
              <Tabs.Tab
                label="Pessoa Física"
                disabled={typeOfPerson === 'juridica' && Boolean(state?.id)}
              >
                <CPFEstablishment form={form} setForm={setForm} />
              </Tabs.Tab>
              <Tabs.Tab
                label="Pessoa Jurídica"
                disabled={typeOfPerson === 'fisica' && Boolean(state?.id)}
              >
                <CNPJEstablishment form={form} setForm={setForm} />
              </Tabs.Tab>
            </Tabs>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default RegisterCustomerTeuPag;
