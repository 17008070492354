import styled from 'styled-components';

export interface ICertifaceMessame {
  approved?: boolean;
}

export const CertifaceTitle = styled.span<ICertifaceMessame>`
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 12px;
`;

export const CertifaceMessage = styled.span<ICertifaceMessame>`
  margin-left: 8px;
  font-weight: 400;
  margin-top: 12px;
  color: ${(props) => (props.approved ? 'green' : 'red')};
`;
