import React, { useEffect, memo } from 'react';

import * as St from './styles';

const Popover = ({ children, open = false }) => {
  return (
    open && (
      <St.Container>
        <St.Popover>{children}</St.Popover>
      </St.Container>
    )
  );
};

export default memo(Popover);
