/* eslint-disable no-param-reassign */
import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, phoneMask } from '~/utils/masks';

export const HeaderReportOdonto = [
  {
    id: 1,
    value: 'Nome titular',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Venda',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Operador(a) de Venda',
    maxChar: 14,
  },
  {
    id: 5,
    value: 'Status',
    maxChar: 7,
  },
  {
    id: 6,
    value: 'Celular',
    maxChar: 13,
  },
  {
    id: 7,
    value: 'Dependente',
    maxChar: 15,
  },
  {
    id: 8,
    value: 'Tipo Venda',
    maxChar: 10,
  },
  {
    id: 9,
    value: 'UF',
    maxChar: 9,
    textAlign: 'center',
  },
];

export const BodyReportOdonto = (vendas: any) => {
  // let x = 0;

  const items = [];
  vendas.forEach((i) => {
    const dependenteList = i.dependenteList ? i.dependenteList : [];
    if (i.dataVenda !== undefined) {
      const item: IListColumn[] = [
        // {
        //   type: 'id',
        //   value: x++,
        //   maxChar: 5,
        // },
        {
          value: i.nomeCliente,
          maxChar: 15,
        },
        {
          value: i.cpf ? cpfMask(i.cpf).masked : '',
          maxChar: 13,
          textAlign: 'center',
        },
        {
          value: i.dataVenda ? moment(i.dataVenda).format('DD/MM/YYYY') : '-',
          maxChar: 13,
          textAlign: 'center',
        },
        {
          value: i.nomeOperador,
          maxChar: 14,
        },
        {
          value: i.statusCartao,
          maxChar: 7,
        },
        {
          value: phoneMask(i.celular).masked,
          maxChar: 13,
          textAlign: 'center',
        },
        {
          value: '-',
          maxChar: 15,
          textAlign: 'center',
        },
        {
          value: i.tipoUsuario,
          maxChar: 10,
        },
        {
          value: i.uf,
          maxChar: 13,
          textAlign: 'center',
        },
      ];

      items.push(item);
    }

    if (dependenteList.length > 0) {
      dependenteList.forEach((dependent) => {
        if (dependent.dataVenda !== undefined) {
          const item: IListColumn[] = [
            // {
            //   type: 'id',
            //   value: x++,
            //   maxChar: 5,
            // },
            {
              value: i.nomeCliente,
              maxChar: 15,
            },
            {
              value: i.cpf ? cpfMask(i.cpf).masked : '',
              maxChar: 13,
              textAlign: 'center',
            },
            {
              value: dependent.dataVenda ? dependent.dataVenda : '-',
              maxChar: 13,
              textAlign: 'center',
            },
            {
              value: dependent.nomeOperadorDependente,
              maxChar: 14,
            },
            {
              value: i.statusCartao,
              maxChar: 7,
            },
            {
              value: phoneMask(i.celular).masked,
              maxChar: 13,
              textAlign: 'center',
            },
            {
              value: dependent.nome,
              maxChar: 15,
            },
            {
              value:
                i.dataVenda === dependent.dataVenda
                  ? 'TITULAR/DEPENDENTE'
                  : dependent.tipoUsuariobeneficio,
              maxChar: 10,
              textAlign: 'center',
            },
            {
              value: i.uf,
              maxChar: 9,
              textAlign: 'center',
            },
          ];

          items.push(item);
        }
      });
    }
  });

  return items;
};

export const BodyReportOdontoCSV = (vendas: any) => {
  const dataCSV = [];

  vendas?.forEach((csv) => {
    const dependenteList = csv.dependenteList ? csv.dependenteList : [];
    if (dependenteList.length > 0) {
      if (csv.dataVenda !== undefined) {
        dataCSV.push({
          nomeCliente: csv.nomeCliente,
          cpf: csv.cpf,
          dataVenda: csv.dataVenda,
          nomeOperador: csv.nomeOperador,
          statusCartao: csv.statusCartao,
          statusBeneficio: csv.statusBeneficio,
          email: csv.email,
          celular: csv.celular,
          celular2: csv.celular2,
          tipoUsuario: csv.tipoUsuario,
          uf: csv.uf,
          loja: csv.loja,
          ufLoja: csv.ufLoja,
          isencao: csv.isencao ? 'SIM' : 'NÂO',
        });
      }

      dependenteList.forEach((csvDependente) => {
        if (csvDependente.dataVenda !== undefined) {
          dataCSV.push({
            nomeCliente: csv.nomeCliente,
            cpf: csv.cpf,
            dataVenda: csvDependente.dataVenda,
            nomeOperador: csvDependente.nomeOperadorDependente,
            statusCartao: csv.statusCartao,
            statusBeneficio: csv.statusBeneficio,
            email: csv.email,
            celular: csv.celular,
            celular2: csv.celular2,
            tipoUsuario: csvDependente.tipoUsuariobeneficio,
            nomeDependente: csvDependente.nome,
            dataNascimento: csvDependente.dataNascimento,
            uf: csv.uf,
            loja: csv.loja,
            ufLoja: csv.ufLoja,
            isencao: csv.isencao ? 'SIM' : 'NÂO',
          });
        }
      });
    } else {
      dataCSV.push({
        nomeCliente: csv.nomeCliente,
        cpf: csv.cpf,
        dataVenda: csv.dataVenda,
        nomeOperador: csv.nomeOperador,
        statusCartao: csv.statusCartao,
        statusBeneficio: csv.statusBeneficio,
        email: csv.email,
        celular: csv.celular,
        celular2: csv.celular2,
        tipoUsuario: csv.tipoUsuario,
        uf: csv.uf,
        loja: csv.loja,
        ufLoja: csv.ufLoja,
        isencao: csv.isencao ? 'SIM' : 'NÂO',
      });
    }
  });
  return dataCSV;
};

export const BodyTotalResponseOdonto = (vendas: any) => {
  const totalTitular = vendas.reduce((acc, cur) => {
    if (cur.dataVenda !== undefined) {
      return acc + 1;
    }
    return acc;
  }, 0);
  const totalDependente = vendas.reduce(
    (acc: number, cur: { dependenteList: any[] }) => {
      if (cur.dependenteList?.length > 0) {
        cur.dependenteList?.forEach((dependent) => {
          if (dependent.dataVenda !== undefined) {
            acc++;
          }
        });
      }
      return acc;
    },
    0,
  );
  return totalTitular + totalDependente;
};
