/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Dialog from '~/components/dialog';
import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';
import { PAYMENT_METHODS, TIPO_TRANSACAO } from '~/utils/payment-methods';
import Select from '~/components/select';
import Loading from '~/components/loading';
import { icons } from '~/assets';
import {
  currencyUnmasked,
  creditCardMask,
  currencyMask,
  IDefaultReturn,
  cpfMask,
} from '~/utils/masks';

import { creditCardValidation } from '~/utils/validators';

import * as St from './styles';
import Container from '~/components/container';
import TextArea from '~/components/textarea';
import Constants from '~/utils/constants';
import GetDetailTransactionSolicitationResponse from '~/services/invoice/getDetailTransactionSolicitation/response';

interface IForm {
  idTransacao?: string;
  parecerSolicitacao?: string;
  idUsuarioSolicitacao?: string;
  nomeUsuarioSolicitacao?: string;
  dataSolicitacao?: string;
  numeroCartao?: string;
  nome?: string;
  cpf?: string;
  paymentMethod?: string;
  checkout?: string;
  listCompany?: string;
  valor?: string;
  valorParcela?: string;
  valorTotal?: string;
  tipoTransacao?: string;
  descricao?: null | string;
  parcela?: null | string;
  statusTransaction?: string;
}

interface IErrors {
  parecer?: string;
}

const TransactionDecisionDetail = () => {
  const [form, setForm] = useState<IForm>({
    idTransacao: '',
    parecerSolicitacao: '',
    idUsuarioSolicitacao: '',
    nomeUsuarioSolicitacao: '',
    dataSolicitacao: '',
    numeroCartao: '',
    nome: '',
    cpf: '',
    paymentMethod: null,
    checkout: '',
    listCompany: '',
    valor: '',
    tipoTransacao: '',
    descricao: null,
    parcela: null,
    statusTransaction: '',
  });

  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [parecer, setParecer] = useState('');
  const [dialogApproveStatus, setDialogApproveStatus] = useState(false);
  const [dialogRefuseStatus, setDialogRefuseStatus] = useState(false);
  const [dialogApproveMessageOpen, setApproveDialogMessageOpen] = useState(
    false,
  );
  const [statusDecision, setStatusDecision] = useState('');
  const [popUpLoginModal, setPopUpLoginModal] = useState(false);
  const [cpf, setCpf] = useState<IDefaultReturn>({} as IDefaultReturn);
  const [senha, setSenha] = useState('');
  const [errors, setErrors] = useState<IErrors>({} as IErrors);
  const user = JSON.parse(localStorage.getItem(Constants.USER));
  const history = useHistory();
  const location = useLocation<IForm>();

  const RequestGetTransactionDetail = useApi<any[]>(
    `${endpoints.invoices.getTransactionDetail}`.replace(
      'PARAM_ID',
      location.state?.idTransacao,
    ),
    ApiMethod.GET,
  );

  const PutTransactionDecision = useApi<any[]>(
    `${endpoints.invoices.putTransactionDecision}`,
    ApiMethod.PUT,
  );

  const AuthValidatePassword = useApi<any>(
    endpoints.account.validateUserPassword,
    ApiMethod.POST,
  );

  useEffect(() => {
    const getBusiness = async () => {
      const response = await RequestGetTransactionDetail.callApi();

      if (response.status >= 200 && response.status < 300) {
        const data = (response.data as unknown) as GetDetailTransactionSolicitationResponse;
        setForm({
          ...form,
          listCompany: data.loja.nome,
          idUsuarioSolicitacao: data.usuarioSolicitacao.id,
          numeroCartao: data.numeroCartao,
          cpf: data.cliente.cpf,
          nome: data.cliente.nome,
          dataSolicitacao: data.dataSolicitacao,
          tipoTransacao: data.tipoTransacao,
          nomeUsuarioSolicitacao: data.usuarioSolicitacao.nome,
          parecerSolicitacao: location.state?.parecerSolicitacao,
          valor: data.valor,
          parcela: data.parcelas,
          valorParcela: data?.valorParcela,
          valorTotal: data?.valorTotal,
          statusTransaction: data.status,
          idTransacao: location.state?.idTransacao,
        });
      }
    };
    getBusiness();
  }, []);

  const submitDecision = async () => {
    try {
      const payload = {
        idTransacaoSolicitada: form.idTransacao,
        motivo: parecer,
        status: statusDecision,
        usuario: {
          id: user.id,
          nome: user.nomeTitular,
        },
      };
      const response = await PutTransactionDecision.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        setPopUpMessage(response.mensagem);
        setOpenDialog(true);
      } else {
        setPopUpMessage(response.mensagem);
        setOpenDialog(true);
      }
    } catch (err) {
      setPopUpMessage(err.mensagem);
      console.log(err);
    }
  };

  const handlePasswordValidate = async () => {
    try {
      const { status, mensagem } = await AuthValidatePassword.callApi({
        cpf: user.cpf,
        senha,
      });
      if (status >= 200 && status <= 300) {
        submitDecision();
        setPopUpLoginModal(false);
      } else {
        setPopUpLoginModal(false);
        setPopUpErrorMessage(mensagem);
        setPopUpError(true);
      }
    } catch (err) {
      setPopUpMessage(err.mensagem);
      console.log(err);
    }
  };

  const goBack = () => history.goBack();

  const showOptionsRule = () => {
    const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
    const result = userRoles.includes('ADM');
    return result;
  };
  const hiddeCPFNumber = (value) => {
    const valueSplited = value.split('');

    return valueSplited
      .map((el, index) => {
        if (index > 3 && index < 11) return '*';
        return el;
      })
      .join('');
  };
  return (
    <>
      <Loading
        status={
          RequestGetTransactionDetail.loading || AuthValidatePassword.loading
        }
      />
      <Dialog
        icon={icons.confirmation}
        open={dialogApproveMessageOpen}
        positiveLabel="Sim"
        negativeLabel="Não"
        negativeAction={() => {
          setApproveDialogMessageOpen(false);
          history.push('/homeDash');
        }}
        positiveAction={() => setPopUpLoginModal(true)}
        message="Você já realizou e confirmou o estorno desta transação?"
      />
      <Dialog
        open={openDialog}
        message={popUpMessage}
        positiveAction={() => {
          setOpenDialog(false);
          history.push('/invoice/purchase-decision');
        }}
        positiveLabel="Entendi"
        icon={icons.check}
      />
      <Dialog
        open={popUpError}
        message={popUpErrorMessage}
        positiveAction={() => {
          setPopUpError(false);
          setApproveDialogMessageOpen(false);
        }}
        positiveLabel="Entendi"
        icon={icons.check}
      />
      <Dialog
        open={popUpLoginModal}
        positiveLabel="Sim"
        negativeLabel="Não"
        negativeAction={() => {
          setApproveDialogMessageOpen(false);
          history.push('/invoice/purchase-decision');
        }}
        positiveAction={handlePasswordValidate}
        message="Insira sua senha"
        buttonDisabledPositive={
          RequestGetTransactionDetail.loading || AuthValidatePassword.loading
        }
      >
        <Input
          name="cpf"
          id="cpf"
          type="text"
          label="CPF"
          maxLength={14}
          value={hiddeCPFNumber(cpfMask(user.cpf).masked)}
        />
        <Input
          name="senha"
          id="senha"
          type="password"
          label="Senha"
          maxLength={6}
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
      </Dialog>

      <Container>
        <St.BoxCard>
          <ContainerFlex row maxWidth={100} padding={26}>
            <St.TitleCategory style={{ width: '100%' }} paddingLeft={26}>
              1. Dados da transação
            </St.TitleCategory>
          </ContainerFlex>
          <ContainerFlex row space padding={26}>
            <ContainerFlex padding={14}>
              <Select
                label="Tipo transação"
                value={form.tipoTransacao}
                options={TIPO_TRANSACAO}
                disabled
              />
            </ContainerFlex>

            <ContainerFlex padding={14}>
              <Input
                label="Valor"
                placeholder="0,00"
                name="valor"
                mask={MaskTypes.CURRENCY}
                value={currencyMask(form.valor)}
              />
            </ContainerFlex>

            {form.tipoTransacao === 'TRANSACAO_CARTAO_DE_CREDITO' && (
              <>
                <ContainerFlex padding={16}>
                  <Input label="Parcelas" value={form.parcela} disabled />
                </ContainerFlex>
                <ContainerFlex padding={16}>
                  <Input
                    label="Valor Parcela"
                    value={currencyMask(form.valorParcela)}
                    disabled
                  />
                </ContainerFlex>
                <ContainerFlex padding={16}>
                  <Input
                    label="Valor Total"
                    value={currencyMask(form.valorTotal)}
                    disabled
                  />
                </ContainerFlex>
              </>
            )}
          </ContainerFlex>

          <ContainerFlex row space padding={26}>
            <ContainerFlex padding={14}>
              <Input
                label="Nome"
                placeholder=""
                maxLength={100}
                name="nome"
                value={form.nome}
              />
            </ContainerFlex>
            <ContainerFlex padding={14}>
              <Input
                label="CPF"
                placeholder=""
                maxLength={14}
                name="cpf"
                value={cpfMask(form.cpf).masked}
              />
            </ContainerFlex>
            <ContainerFlex padding={14}>
              <Input
                label="Número do cartão"
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                mask={MaskTypes.CREDIT_CARD}
                name="numeroCartao"
                value={creditCardMask(form.numeroCartao).masked}
              />
            </ContainerFlex>

            <ContainerFlex padding={18}>
              <Input
                label="Loja"
                placeholder=""
                maxLength={14}
                name="cpf"
                value={form.listCompany}
              />
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex padding={26}>
            <St.TitleCategory paddingLeft={26}>
              2. Histórico de Parecer
            </St.TitleCategory>
            <br />
            <ContainerFlex row horizontal marginLeft={20} marginRight={20}>
              <ContainerFlex
                style={{ boxShadow: '1px 1px 7px 0 rgba(201,201,201, 0.5' }}
                padding={25}
              >
                <St.TitleParecer>Data e Hora</St.TitleParecer>
                <ContainerFlex>
                  <St.TitleParecer>
                    {form.dataSolicitacao
                      ? moment(form?.dataSolicitacao).format('DD/MM/YYYY HH:mm')
                      : ''}
                  </St.TitleParecer>
                </ContainerFlex>
              </ContainerFlex>

              <ContainerFlex
                marginRight={20}
                marginLeft={20}
                style={{ boxShadow: '1px 1px 7px 0 rgba(201,201,201, 0.5' }}
                padding={25}
              >
                <ContainerFlex>
                  <St.TitleParecer>Usuário</St.TitleParecer>
                  <St.TitleParecer>
                    {form.nomeUsuarioSolicitacao
                      ? form.nomeUsuarioSolicitacao
                      : '--'}
                  </St.TitleParecer>
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex padding={26}>
            <St.TitleCategory paddingLeft={26}>
              3. Decisão
              <St.TitleAbbr title="Obrigatório">*</St.TitleAbbr>
            </St.TitleCategory>
            <ContainerFlex padding={26}>
              <TextArea
                placeholder="Digitar parecer.."
                name="parecer"
                rows={10}
                value={parecer}
                onChange={(e) => setParecer(e.target.value)}
                errorMessage={errors.parecer}
                disabled={
                  form.idTransacao !== localStorage.getItem(Constants.USER_ID)
                    ? !showOptionsRule()
                    : true
                }
              />
              <div style={{ marginTop: '10px' }}>
                <St.TitleAbbrFooter>* Obrigatório preencher</St.TitleAbbrFooter>
              </div>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row horizontal padding={26} end>
            <Button outlined onClick={goBack}>
              Voltar
            </Button>
            <Button
              outlined
              marginLeft={16}
              disabled={
                parecer.length > 0 &&
                form.idTransacao !== localStorage.getItem(Constants.USER_ID)
                  ? !showOptionsRule()
                  : true
              }
              onClick={() => {
                setPopUpLoginModal(true);
                setStatusDecision('NEGADO');
              }}
            >
              Recusar
            </Button>

            <Button
              marginLeft={16}
              disabled={
                parecer.length > 0 &&
                form.idTransacao !== localStorage.getItem(Constants.USER_ID)
                  ? !showOptionsRule()
                  : true
              }
              onClick={() => {
                setPopUpLoginModal(true);
                setStatusDecision('APROVADO');
              }}
            >
              Aprovar
            </Button>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default TransactionDecisionDetail;
