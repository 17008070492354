/* eslint-disable react/jsx-one-expression-per-line */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FaDownload } from 'react-icons/fa';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import TableList from '~/components/tableList';
import FilterBenefit from './components/FilterBenefit';
import { useReportBenefit } from './context/useBenefit';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';

import * as S from './styles';
import ResultNotFound from '~/components/resultNotFound';

const ReportBenefit: React.FC = () => {
  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [form, setForm] = useState({
    tipoBeneficio: { name: 'tipoBeneficio', value: '' },
    statusCartao: 'TODOS',
    periodoInicial: moment().format('YYYY-MM-DD'),
    periodoFinal: moment().format('YYYY-MM-DD'),
    tipoPeriodoCancelamentoOdonto: 'DT_CANCELAMENTO',
    tipoPeriodoCancelamentoProtege: 'DT_CANCELAMENTO',
    tipoPeriodoCancelamentoCuida: 'DT_CANCELAMENTO',
    periodoFaturamentoOdonto: 'DT_ADESAO',
    periodoCancelamentoPlatinum: 'CANCELAMENTO',
    tipoSeguro: 'TODOS',
    tipoAdesao: 'TODOS',
    uf: 'TODOS',
    idAtendente: 'TODOS',
    nomeAtendente: 'TODOS',
  });

  const {
    postReportInvoceOdont,
    postReportCancelOdont,
    postReportCancelSafe,
    postReportSalesOdont,
    postReportSalesSafe,
    postReportSalesCuida,
    postReportCancelCuida,
    postReportSalesPlatinumCard,
    postReportCancellationPlatinum,
    getOperador,
    items,
    totalItems,
    totalPages,
    headerItems,
    header,
    relatorioData,
    setRelatorioData,
    clearForm,
    checkSuccess,
    loading,
    operador,
  } = useReportBenefit();

  useEffect(() => {
    getOperador();
  }, []);

  const payloadType = {
    VENDAS_ODONTO: {
      dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      idOperador: form.idAtendente,
      statusCartao: form.statusCartao,
      uf: form.uf,
    },
    CANCELAMENTO_ODONTO: {
      dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      idOperador: form.idAtendente,
      statusCartao: form.statusCartao,
      tipoPeriodo: form.tipoPeriodoCancelamentoOdonto,
      uf: form.uf,
    },
    FATURAMENTO_ODONTO: {
      periodoInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      periodoFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      periodoFaturamentoOdonto: form.periodoFaturamentoOdonto,
    },
    VENDAS_PROTEGE: {
      dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      operador: form.idAtendente,
      tipoSeguro: form.tipoSeguro,
      uf: form.uf,
    },
    CANCELAMENTO_PROTEGE: {
      dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      idOperador: form.idAtendente,
      statusCartao: form.statusCartao,
      tipoPeriodo: form.tipoPeriodoCancelamentoProtege,
      uf: form.uf,
    },
    VENDAS_CUIDA: {
      dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      operador: form.idAtendente,
      uf: form.uf,
      statusCartao: form.statusCartao,
    },
    CANCELAMENTO_CUIDA: {
      dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      operador: form.idAtendente,
      tipoPeriodo: form.tipoPeriodoCancelamentoCuida,
      uf: form.uf,
    },
    VENDAS_PLATINUM: {
      dataAdesaoFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      dataAdesaoInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      operador: form.idAtendente,
      tipoAdesao: form.tipoAdesao,
    },
    CANCELAMENTO_PLATINUM: {
      dataFinal: moment(form.periodoFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.periodoInicial).format('YYYY-MM-DD'),
      operador: form.idAtendente,
      tipoData: form.periodoCancelamentoPlatinum,
      uf: form.uf,
    },
  };

  const getReport = async (page = 1) => {
    const payload = payloadType[form.tipoBeneficio.value];

    switch (form.tipoBeneficio?.value) {
      case 'VENDAS_ODONTO':
        await postReportSalesOdont(page, 30, false, payload);
        break;
      case 'CANCELAMENTO_ODONTO':
        await postReportCancelOdont(page, 30, false, payload);
        break;
      case 'FATURAMENTO_ODONTO':
        await postReportInvoceOdont(page, 30, false, payload);
        break;
      case 'VENDAS_PROTEGE':
        await postReportSalesSafe(page, 30, false, payload);
        break;
      case 'CANCELAMENTO_PROTEGE':
        await postReportCancelSafe(page, 30, false, payload);
        break;
      case 'VENDAS_CUIDA':
        await postReportSalesCuida(page, 30, false, payload);
        break;
      case 'CANCELAMENTO_CUIDA':
        await postReportCancelCuida(page, 30, false, payload);
        break;
      case 'VENDAS_PLATINUM':
        await postReportSalesPlatinumCard(page, 30, false, payload);
        break;
      case 'CANCELAMENTO_PLATINUM':
        await postReportCancellationPlatinum(page, 30, false, payload);
        break;
      default:
        break;
    }
  };

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload = payloadType[form?.tipoBeneficio?.value];

    switch (form.tipoBeneficio?.value) {
      case 'VENDAS_ODONTO':
        response = await postReportSalesOdont(currentPage, 150, true, payload);
        break;
      case 'CANCELAMENTO_ODONTO':
        response = await postReportCancelOdont(currentPage, 150, true, payload);
        break;
      case 'FATURAMENTO_ODONTO':
        response = await postReportInvoceOdont(currentPage, 100, true, payload);
        break;
      case 'VENDAS_PROTEGE':
        response = await postReportSalesSafe(currentPage, 150, true, payload);
        break;
      case 'CANCELAMENTO_PROTEGE':
        await postReportCancelSafe(currentPage, 30, false, payload);
        break;
      case 'VENDAS_CUIDA':
        response = await postReportSalesCuida(currentPage, 150, true, payload);
        break;
      case 'CANCELAMENTO_CUIDA':
        response = await postReportCancelCuida(currentPage, 150, true, payload);
        break;
      case 'VENDAS_PLATINUM':
        response = await postReportSalesPlatinumCard(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'CANCELAMENTO_PLATINUM':
        response = await postReportCancellationPlatinum(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      default:
        break;
    }

    if (response) {
      let data;

      if (response.totalDePaginas || response.totalPaginas) {
        data = response;
      }
      if (!response.totalDePaginas) {
        data = response.data || response.vendas;
      }

      totalPages = data?.totalDePaginas || response?.totalPaginas;

      const percentage = (currentPage / totalPages) * 100;

      const roundPercentage = Math.ceil(percentage);
      setDownloadCVSProgress(`${roundPercentage || 0}%`);

      if (data?.pagina?.length || response?.data) {
        const newData = data.pagina || data;
        setRelatorioData((prev) => {
          const existingItems = prev.map((item) => item.cpf);
          const filteredData = newData.filter(
            (item) => !existingItems.includes(item.cpf),
          );
          return [...prev, ...filteredData];
        });
      }

      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
      setDownloadCVSProgress('');
    }
  };

  useEffect(() => {
    if (form.tipoBeneficio?.value !== '') {
      getReport();
    }
    clearForm();
  }, [form]);

  return (
    <>
      <Loading status={loading} />
      <Container>
        <FilterBenefit
          operador={operador || []}
          onClick={(e) => {
            setForm({
              ...e,
              periodoInicial: moment(e.periodoInicial, 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
              ),
              periodoFinal: moment(e.periodoFinal, 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
              ),
            });
          }}
        />

        <CSVLink
          ref={refCSV}
          data={relatorioData}
          style={{
            color: 'inherit',
            textDecoration: 'none',
          }}
          headers={header}
          filename={`relatorio_beneficio_${form.tipoBeneficio.value}.csv`}
          target="_blank"
        />

        {items?.length > 0 ? (
          <S.BoxCard>
            <ContainerFlex flex="0.2">
              <ContainerFlex row space center marginBottom={24}>
                <S.BoxCardTitle>Relatório</S.BoxCardTitle>

                <Button
                  onClick={() => {
                    if (refCSV.current) {
                      onClickDownloadCSV().then(() =>
                        refCSV.current.link.click(),
                      );
                    }
                  }}
                  disabled={loading}
                >
                  <FaDownload />
                  <span style={{ marginLeft: 8 }}>Download</span>
                </Button>
              </ContainerFlex>

              <ContainerFlex>
                <TableList customerHeader={headerItems} list={items} />
                <ContainerFlex row space center marginTop={20}>
                  <ContainerFlex>
                    <S.ShowResults>
                      Total de resultados: <strong>{totalItems}</strong>
                    </S.ShowResults>
                  </ContainerFlex>
                  {form?.tipoBeneficio?.value !== 'VENDAS_ODONTO' &&
                    form?.tipoBeneficio?.value !== 'CANCELAMENTO_ODONTO' &&
                    form?.tipoBeneficio?.value !== 'VENDAS_PROTEGE' &&
                    form.tipoBeneficio?.value !== 'CANCELAMENTO_PROTEGE' &&
                    form.tipoBeneficio?.value !== 'VENDAS_CUIDA' &&
                    form?.tipoBeneficio?.value !== 'CANCELAMENTO_CUIDA' &&
                    form?.tipoBeneficio?.value !== 'CANCELAMENTO_PLATINUM' && (
                      <Pagination
                        totalPages={totalPages}
                        onCurrentPageChange={getReport}
                      />
                    )}
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </S.BoxCard>
        ) : (
          <ResultNotFound />
        )}
        {checkSuccess() && items.length <= 0 && (
          <ContainerFlex center>
            <TitleCategory>Nenhum resultado encontrado</TitleCategory>
          </ContainerFlex>
        )}
      </Container>
    </>
  );
};

export default ReportBenefit;
