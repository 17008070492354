import React from 'react';
import { currencyMask } from '~/utils/masks';
import * as S from './styles';

const ProjectionMeter = ({
  title,
  projection = 0,
  available = 0,
  totalDays,
  workDays,
  daysRemaining,
  type,
}) => {
  const calculatePercentage = () => (available * 100) / projection;

  return (
    <S.Container>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.Title>
          {type === 'PAGAMENTO_FATURAS' || type === 'FATURAMENTO_COMPRAS'
            ? `R$ ${currencyMask(projection)}`
            : projection}
        </S.Title>
      </S.Header>
      <S.Line>
        <S.Percentage style={{ width: `${calculatePercentage()}%` }} />
      </S.Line>
      <S.Infos>
        <S.Label>
          {type === 'PAGAMENTO_FATURAS' || type === 'FATURAMENTO_COMPRAS'
            ? `R$ ${currencyMask(available)}`
            : available}
        </S.Label>
      </S.Infos>
      <S.Infos>
        <S.Capsule>{`Dias totais: ${totalDays}`}</S.Capsule>
        <S.Capsule>{`Dias trabalhados: ${workDays}`}</S.Capsule>
        <S.Capsule>{`Dias restantes: ${daysRemaining}`}</S.Capsule>
      </S.Infos>
    </S.Container>
  );
};

export default ProjectionMeter;
