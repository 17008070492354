/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Input, { MaskTypes } from '~/components/input';
import Button from '~/components/button';

import ContainerFlex from '~/components/containerFlex';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import ConsultTransactionSearchPayload from '~/services/consult/transaction/search/payload';

import { TitleCategory } from '../User/Detail/styles';
import ConsultTransactionSearchResponse from '~/services/consult/transaction/search/response';
import Transacao from '~/models/transacao';
import Loading from '~/components/loading';
import { currencyMask } from '~/utils/masks';
import TableList from '~/components/tableList';
import { IListColumn } from '~/models/listCol';
import Container from '~/components/container';

import * as St from './styles';

interface Form {
  cpf: FormField;
  numeroCartao: FormField;
}

const ConsultTransaction: React.FC = () => {
  const history = useHistory();
  const [list, setList] = useState([]);

  const api = useApi<ConsultTransactionSearchResponse>(
    Endpoints.consult.searchTransaction.replace('PARAM_QUANTITY', '600'),
    ApiMethod.POST,
  );

  const [form, setForm] = useState<Form>({
    cpf: { name: 'cpf', value: '' },
    numeroCartao: { name: 'numeroCartao', value: '' },
  });
  const customerHeader = [
    {
      id: 1,
      value: 'Local da transação',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Valor da parcela',
      maxChar: 16,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Valor da compra',
      maxChar: 16,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Tipo de transação',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Fatura',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Data e hora transação',
      maxChar: 22,
      textAlign: 'center',
    },
    {
      id: 7,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  useEffect(() => {
    loadData();
  }, [api.loading]);

  const loadData = () => {
    if (api.status !== 200) {
      setList([]);
      return;
    }

    const newList = [];

    const response = api.data as Transacao[];
    // let i = 0;

    response.forEach((d) => {
      const isReversal = !!d.dataEstorno && d.tipo !== 'ESTORNO';
      const item: IListColumn[] = [
        // {
        //   type: 'id',
        //   value: i++,
        //   maxChar: 5,
        // },
        {
          title: 'Local da transação',
          value:
            d.tipo === 'PAGAMENTO_FATURA'
              ? d.localPagamento
              : d.localTransacao.replace('Transação Digital', 'Trans. Digital'),
          scratched: isReversal,
          maxChar: 19,
          // textAlign: 'center',
        },
        {
          border: true,
          title: 'Valor da Parcela',
          value: `R$ ${currencyMask(d.valorTransacao)}`,
          scratched: isReversal,
          maxChar: 10,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Valor da compra',
          value: `R$ ${currencyMask(Number(d.valorCompra))}`,
          scratched: isReversal,
          maxChar: 10,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Tipo',
          value: montaTipo(
            d.descricao.replace('Transação Digital', 'Trans. Digital'),
            d.parcelaAtual,
            d.quantidadeParcelas,
          ),
          scratched: isReversal,
          maxChar: 30,
        },
        {
          border: true,
          title: 'Fatura',
          value: getStatusData(d.aberta),
          scratched: isReversal,
          maxChar: 15,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Data e hora transação',
          value: `${d.dataTransacao.split(' ')[0]} ${d.horarioTransacao}`,
          scratched: isReversal,
          maxChar: 22,
          textAlign: 'center',
        },
        {
          searchAction:
            d.tipo === 'ESTORNO' ? null : () => goToDetail(d.idTransacao, d),
          textAlign: 'center',
          maxChar: 5,
        },
      ];

      newList.push(item);
    });

    setList(newList);
  };

  const getStatusData = (value: string) => {
    return value === '1' ? 'Aberta' : 'Fechada';
  };

  function montaTipo(
    tipo: string,
    parcelaAtual: string,
    quantidadeParcelas: string,
  ) {
    if (quantidadeParcelas > '1') {
      return `${tipo} parcelas ${parcelaAtual}/${quantidadeParcelas} `;
    }
    return tipo;
  }

  const searchTransaction = () => {
    const payload: ConsultTransactionSearchPayload = {
      cpf: form.cpf.unmasked?.length !== 11 ? null : form.cpf.unmasked,
      numeroCartao:
        form.numeroCartao.unmasked?.length !== 16
          ? null
          : form.numeroCartao.unmasked,
    };

    api.callApi(payload);
  };

  const goToDetail = (id, d) => {
    history.push('/consult/transaction/detail', {
      cpf: form.cpf,
      numeroCartao: form.numeroCartao,
      idTransacao: id,
      ...d,
    });
  };

  return (
    <>
      <Loading status={api.loading} />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name={form.cpf.name}
                value={form.cpf.value}
                onChange={(e) => setFormState(e, form, setForm)}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Input
                label="Número do cartão"
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                mask={MaskTypes.CREDIT_CARD}
                onChange={(e) => setFormState(e, form, setForm)}
                name={form.numeroCartao.name}
                value={form.numeroCartao.value}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={searchTransaction}
                loading={api.loading}
                disabled={
                  (form.cpf.value.length !== 14 &&
                    form.numeroCartao.value.length !== 19) ||
                  api.loading
                }
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
        <St.BoxCard>
          <ContainerFlex row padding={16}>
            {list.length <= 0 ? (
              <ContainerFlex center marginTop={30}>
                <TitleCategory>Nenhuma transação encontrada.</TitleCategory>
              </ContainerFlex>
            ) : (
              <TableList customerHeader={customerHeader} list={list} />
            )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ConsultTransaction;
