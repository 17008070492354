/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderReportVendas = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Operador(a)',
    maxChar: 14,
  },
  {
    id: 4,
    value: 'Data Lead',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Data Adesão',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Data Transação',
    maxChar: 8,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Status',
    maxChar: 10,
    textAlign: 'center',
  },
];

export const BodyReportVendas = (vendas: any) => {
  // let x = 0;

  const items = [];

  vendas?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 3,
      // },
      {
        value: i.nome ? i.nome : '-',
        maxChar: 15,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.nomeOperador ? i.nomeOperador : '-',
        maxChar: 15,
      },
      {
        value: i.dataEnvio ? moment(i.dataEnvio).format('DD/MM/YYYY') : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.dataAdesao ? moment(i.dataAdesao).format('DD/MM/YYYY') : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.dataTransacao
          ? moment(i.dataTransacao).format('DD/MM/YYYY')
          : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.status ? i.status : '-',
        maxChar: 10,
        textAlign: 'center',
      },
    ];

    items.push(item);
  });

  return items;
};

export const BodyReportVendasCSV = (vendas: any) => {
  const dataCSV = [];

  vendas?.forEach((csv) => {
    if (csv.dataCompra) {
      dataCSV.push({
        nome: csv.nome,
        cpf: csv.cpf,
        email: csv.email,
        celular: csv.celular,
        nomeOperador: csv.nomeOperador,
        dataCompra: csv.dataCompra,
        dataPrimeiraCompra: csv.dataPrimeiraCompra,
        loja: csv.loja,
        cartaoTeuCard: csv.cartaoTeuCard ? 'SIM' : 'NÃO',
        uf: csv.uf,
        bandeira: csv.bandeira,
        cartaoQuatroUltimoDigitos: csv.cartaoQuatroUltimoDigitos,
      });
    }
  });
  return dataCSV;
};
