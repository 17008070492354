import { useContext } from 'react';
import { IBenefitReport } from '../models';
import { BenefitReportContext } from './BenefitReportContext';

export function useReportBenefit(): IBenefitReport {
  const context = useContext(BenefitReportContext);

  if (!context) {
    throw new Error('useBenefit must be used within an BenefitContext');
  }

  return context;
}
