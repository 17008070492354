import React from 'react';

import { HiTrendingUp, HiTrendingDown } from 'react-icons/hi';
import * as S from './styles';

const Badge = ({ onClick, percentage, evolution }) => {
  return (
    <S.Container
      onClick={onClick}
      style={{
        backgroundColor: `${
          evolution === 'EM_CRESCIMENTO' ? '#008000' : '#FF0000'
        }`,
      }}
    >
      {evolution === 'EM_CRESCIMENTO' ? (
        <HiTrendingUp color="#fff" size={16.67} style={{ marginRight: 8.67 }} />
      ) : (
        <HiTrendingDown
          color="#fff"
          size={16.67}
          style={{ marginRight: 8.67 }}
        />
      )}
      <span>{`${percentage}%`}</span>
    </S.Container>
  );
};

export default Badge;
