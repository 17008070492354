/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ContainerFlex from '~/components/containerFlex';
import { icons } from '~/assets';

import * as St from './styles';

interface IProps {
  readonly customerHeader: any;
  readonly list: any;
  cancel?: any;
}

const TableInvoice: React.FC<IProps> = ({ customerHeader, list, cancel }) => {
  return (
    <>
      {list.length !== 0 && (
        <ContainerFlex marginBottom={40}>
          <table>
            <St.hederTable>
              <tr>
                {customerHeader.map((item) => (
                  <St.hederTitle
                    key={item.id}
                    maxChar={item.maxChar}
                    textAlign={item.textAlign}
                  >
                    {item.value}
                  </St.hederTitle>
                ))}
              </tr>
            </St.hederTable>
            <br />
            <St.bodyTable>
              {list.map((item) => (
                <tr>
                  {item.map((item) => (
                    <>
                      {item.value === 'CANCELAR' ? (
                        <St.bodyData
                          key={item.id}
                          maxChar={item.maxChar}
                          marginLeft={item.marginLeft}
                          textAlign={item.textAlign}
                          title={item.value}
                        >
                          <img
                            width="18"
                            src={icons.trashCanRed}
                            alt="trash"
                            onClick={() => cancel()}
                          />
                        </St.bodyData>
                      ) : (
                        <St.bodyData
                          key={item.id}
                          maxChar={item.maxChar}
                          marginLeft={item.marginLeft}
                          textAlign={item.textAlign}
                          title={item.value}
                        >
                          {item.value}
                        </St.bodyData>
                      )}
                    </>
                  ))}
                </tr>
              ))}
            </St.bodyTable>
          </table>
        </ContainerFlex>
      )}
    </>
  );
};

export default TableInvoice;
