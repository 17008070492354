/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import {
  GetOperatorsResponse,
  ReportProposalsRegisteredResponse,
  ReportResponse,
} from '~/services/report/requestReport/response';
import { CSV_HEADERS } from '../../constants';
import Models from '../../models';

export const ProposalReportContext = createContext<IProposalReport>(
  {} as IProposalReport,
);

export interface IProposalReport {
  getReportProposalsRegistered: Function;
  getReportProposalsApproved: Function;
  getReportProposalsDisapproved: Function;
  getReportProposalP1Approved: Function;
  getReportProposalP1Disapproved: Function;
  checkSuccess: Function;
  getOperador: Function;
  loading: boolean;
  items: any;
  headerItems: any;
  header: any;
  totalPages: number;
  totalItems: number | null;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  operador: any[];
  clearForm: () => React.SetStateAction<any>;
  PROPOSAL_TYPES: Array<{ id: string; name: string; value: string }>;
}

const ProposalReportProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [relatorioData, setRelatorioData] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [operador, setOperador] = useState([]);

  const RequestGetOperators = useApi(
    Endpoints.report.getOperators,
    ApiMethod.GET,
  );

  const RequestReportProposalsRegistered = useApi<ReportProposalsRegisteredResponse>(
    Endpoints.report.getReportProposalsRegistered,
    ApiMethod.POST,
  );

  const RequestReportProposalDisapproved = useApi<ReportResponse>(
    Endpoints.report.getReportDisapprove,
    ApiMethod.POST,
  );

  const RequestReportProposalApproved = useApi<ReportResponse>(
    Endpoints.report.getReportApproved,
    ApiMethod.POST,
  );

  const RequestReportProposalP1 = useApi<ReportResponse>(
    Endpoints.report.getReportProposalP1,
    ApiMethod.POST,
  );

  const clearForm = () => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
  };

  const PROPOSAL_TYPES = [
    {
      id: 'CADASTRADA',
      name: 'Propostas cadastradas',
      value: 'Propostas cadastradas',
    },
    {
      id: 'APROVADA',
      name: 'Propostas aprovadas',
      value: 'Propostas aprovadas',
    },
    {
      id: 'REPROVADA',
      name: 'Propostas reprovadas',
      value: 'Propostas reprovadas',
    },
    {
      id: 'PROPOSTA_P1',
      name: 'Aprovadas em P1',
      value: 'Aprovadas em P1',
    },
    {
      id: 'REPROVADA_P1',
      name: 'Reprovadas em P1',
      value: 'Reprovadas em P1',
    },
  ];

  useEffect(() => {
    if (
      RequestGetOperators.loading ||
      RequestReportProposalsRegistered.loading ||
      RequestReportProposalDisapproved.loading ||
      RequestReportProposalApproved.loading ||
      RequestReportProposalP1.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    RequestGetOperators.loading,
    RequestReportProposalsRegistered.loading,
    RequestReportProposalApproved.loading,
    RequestReportProposalDisapproved.loading,
    RequestReportProposalP1.loading,
  ]);

  const checkSuccess = () => {
    if (
      RequestReportProposalsRegistered.error ||
      RequestReportProposalsRegistered.status === 404 ||
      RequestReportProposalsRegistered.status === 500 ||
      RequestReportProposalApproved.error ||
      RequestReportProposalApproved.status === 404 ||
      RequestReportProposalApproved.status === 500 ||
      RequestReportProposalDisapproved.error ||
      RequestReportProposalDisapproved.status === 404 ||
      RequestReportProposalDisapproved.status === 500 ||
      RequestReportProposalP1.error ||
      RequestReportProposalP1.status === 404 ||
      RequestReportProposalP1.status === 500
    ) {
      return true;
    }
    return false;
  };

  const getReportProposalsRegistered = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportProposalsRegistered.callApi(newPayload);

    setHeader(CSV_HEADERS.PROPOSALS_REGISTERED);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderProposalsRegistered);
      setItems(Models.BodyProposalsRegistered(data));
    }
  };

  const getReportProposalsApproved = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportProposalApproved.callApi(newPayload);

    setHeader(CSV_HEADERS.REPORT_APPROVED);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportAproved);
      setItems(Models.BodyReportApproved(data));
    }
  };

  const getReportProposalsDisapproved = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      pagina: page,
      limitePorPagina: limit,
    };

    const response = await RequestReportProposalDisapproved.callApi(newPayload);

    setHeader(CSV_HEADERS.REPORT_DISAPPROVED);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportDisapproved);
      setItems(Models.BodyReportDisapproved(data));
    }
  };

  const getReportProposalP1Approved = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportProposalP1.callApi(newPayload);

    setHeader(CSV_HEADERS.APPROVED_P1);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportProposalP1);
      setItems(Models.BodyReportProposalP1(data));
    }
  };

  const getReportProposalP1Disapproved = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportProposalP1.callApi(newPayload);

    setHeader(CSV_HEADERS.DISAPPROVED_P1);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderDisapprovedP1);
      setItems(Models.BodyDisapprovedP1(data));
    }
  };

  const getOperators = useCallback(async () => {
    const { data, status, mensagem } = await RequestGetOperators.callApi();

    return { data, status, mensagem };
  }, [RequestGetOperators]);

  const getOperador = async () => {
    const response = await getOperators();

    if (response.status === 200) {
      const data = response.data as GetOperatorsResponse[];

      const getOperadores = data.map(({ nome, id }) => ({
        id,
        value: nome,
      }));

      setOperador(getOperadores);
    }
  };

  return (
    <ProposalReportContext.Provider
      value={{
        getReportProposalsRegistered,
        getReportProposalsApproved,
        getReportProposalsDisapproved,
        getReportProposalP1Approved,
        getReportProposalP1Disapproved,
        getOperador,
        checkSuccess,
        loading,
        items,
        headerItems,
        header,
        totalItems,
        totalPages,
        relatorioData,
        setRelatorioData,
        clearForm,
        operador,
        PROPOSAL_TYPES,
      }}
    >
      {children}
    </ProposalReportContext.Provider>
  );
};
export default ProposalReportProvider;
