import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderReportCancellationPlatinum = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 20,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data Venda',
    maxChar: 10,
  },
  { id: 4, value: 'Data do Cancelamento', maxChar: 10, textAlign: 'center' },
  { id: 5, value: 'UF', maxChar: 3, textAlign: 'center' },
];

export const BodyReportCancellationPlatinum = (data: any) => {
  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.dataAdesao ? moment(i.dataAdesao).format('DD/MM/YY') : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.dataCancelamento
          ? moment(i.dataCancelamento).format('DD/MM/YY')
          : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      { value: i.lojaUF, maxChar: 3, textAlign: 'center' },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportCancellationPlatinumCSV = (data: any) => {
  const dataCSV = [];

  data.forEach((csv) => {
    dataCSV.push({
      nome: csv.nome,
      cpf: csv.cpf,
      dataAdesao: csv.dataAdesao,
      operadorVenda: csv.operadorVenda,
      loja: csv.loja,
      lojaUF: csv.lojaUF,
      dataCancelamento: csv.dataCancelamento,
      motivoCancelamento: csv.motivoCancelamento,
    });
  });

  return dataCSV;
};
