import React from 'react';
import Chart from 'react-apexcharts';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as S from '../../styles';

interface IProps {
  widthGraphic?: number;
  heightGraphic?: number;
  dataHoraColeta?: string;
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
  subTitle?: string;
}

const BoxLateInvoice: React.FC<IProps> = ({
  categories,
  series,
  widthGraphic = 1000,
  heightGraphic = 300,
}) => {
  const history = useHistory();
  const buildSeries = () => {
    let response = null;
    if (series === undefined || series.length <= 0) {
      response = [
        {
          name: series[0].name,
          data: [0],
        },
      ];
    } else {
      response = series;
    }
    return response;
  };

  const options = {
    series: series[0].data,
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    xaxis: {
      categories: categories || ['0'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: true,
      },
      position: 'bottom',
    },
    yaxis: {
      min: 1,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        formatter(val) {
          return Math.floor(val);
        },
      },
    },
  } as unknown;

  return (
    <>
      <S.BoxDetails>
        <S.BoxHeader>
          <FiArrowLeft onClick={() => history.goBack()} />
          <S.BoxHeaderTitle>Faturas por dia de Atraso</S.BoxHeaderTitle>
        </S.BoxHeader>
        <S.ItemGraphic>
          <Chart
            categories={categories}
            options={options}
            series={buildSeries()}
            type="line"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default BoxLateInvoice;
