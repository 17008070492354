import { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from '~/components/layout';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import TitleApp from '~/components/title';
import Collapse from '~/components/collapse';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { AllPosts, PostInfo } from '~/services/postApp/response';
import Endpoints from '~/services/endpoints';
import Constants from '~/utils/constants';
import * as St from './styles';
import ToggleSwitch from '~/components/toggleSwitch';
import Dialog from '~/components/dialog';
import LocalDropzone from '~/components/dropZone';
import { S3BucketInfo } from '~/services/product/response';
import Loading from '~/components/loading';

const ManagementAppHome = (): JSX.Element => {
  const token = localStorage.getItem(Constants.TOKEN_KEY);
  const tokenBearer = localStorage.getItem(Constants.TOKEN_BEARER);

  const [postsSystem, setPostSystem] = useState([]);
  const [singlePost, setSinglePost] = useState({} as PostInfo);
  const [delePostDialog, setDeletePostDialog] = useState(false);
  const [dialoMsgDeletePost, setDialoMsgDeletePost] = useState('');
  const [dialogResponse, setDialogResponse] = useState(false);
  const [dialoMSG, setDialogMSG] = useState('');
  const [dialoMsgActDeact, setDialoMsgActDeact] = useState('');
  const [taggled, setTaggled] = useState(false);
  const [actAndDeactDialog, setActAndDeactDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [creatingPostDialog, setCreatingPostDialog] = useState(false);
  const [dialoMsgCreatPost, setDialoMsgCreatPost] = useState('');
  const [clearFileStatus, setClearFileStatus] = useState(false);
  const fileSizeInformations =
    'Dimensões das imagens: 1564 x 3201px. Size menor ou igual a 2MB';
  const [uploading, setUploading] = useState(false);

  const allPostsApp = useApi<AllPosts>(
    Endpoints.postApp.getAllPosts,
    ApiMethod.GET,
  );

  const createNewPost = useApi(Endpoints.postApp.createPost, ApiMethod.POST);

  const s3UpLoad = useApi<S3BucketInfo>(
    Endpoints.s3Bucket.uploadFile,
    ApiMethod.POST,
    {},
    false,
    {},
    true,
  );

  const ativarPost = useApi(
    `${Endpoints.postApp.activatePost.replace('ID_POST', singlePost?.id)}`,
    ApiMethod.PUT,
  );

  const desativarPost = useApi(
    `${Endpoints.postApp.deactivatePost.replace('ID_POST', singlePost?.id)}`,
    ApiMethod.PUT,
  );

  const deletarPost = useApi(
    `${Endpoints.postApp.deletePost.replace('ID_POST', singlePost?.id)}`,
    ApiMethod.DELETE,
  );

  // const deleteFileInS3Bucket = useApi(
  //   `${Endpoints.s3Bucket.removeFile.replace('KEY', singlePost.s3Key)}`,
  //   ApiMethod.DELETE,
  // );

  const getAllPosts = async () => {
    const response = await allPostsApp.callApi();
    if (response.status === 200) {
      const { data } = response;
      setPostSystem(data.posts);
    } else {
      setPostSystem([]);
    }
  };

  const openDeletePostDialod = (post: PostInfo) => {
    setSinglePost(post);
    setDialoMsgDeletePost(`Deseja deletar o post ${post.nome} ?`);
    setDeletePostDialog(true);
  };

  const openActAndDeactDialog = (post: PostInfo) => {
    setSinglePost(post);
    if (post.ativo) {
      setDialoMsgActDeact(`Deseja desativar o post ${post.nome} ?`);
    } else {
      setDialoMsgActDeact(`Deseja ativar o post ${post.nome} ?`);
    }
    setActAndDeactDialog(true);
  };

  const openCreatindPostDialog = () => {
    setDialoMsgCreatPost('Confirmar o envio da imagem ?');
    setCreatingPostDialog(true);
  };

  const cancelUpload = () => {
    setClearFileStatus(true);
    setSelectedFile(null);
  };

  const uploadFile = async () => {
    setUploading(true);
    setCreatingPostDialog(false);
    const img = new Image();
    img.src = window.URL.createObjectURL(selectedFile);
    const fileSize = selectedFile.size / 1024 ** 2;
    img.onload = async () => {
      if (img.width === 1564 && img.height === 3201 && fileSize <= 2) {
        const fileName = selectedFile.name.split('.').slice(0, -1).join('.');

        const formData = new FormData();

        formData.append('file', selectedFile);
        formData.append('filename', fileName);
        formData.append('mimetype', selectedFile.type);

        const config = {
          headers: {
            apikey: `token ${token}`,
            Authorization: `Bearer ${tokenBearer}`,
          },
        };

        const response: any = await axios.post(
          Endpoints.s3Bucket.uploadFile,
          formData,
          config,
        );

        if (response.status === 201) {
          creatingPost({
            nome: response.data.key,
            descricao: '',
            imagem: response.data.url,
            s3Key: response.data.key,
          });
          setUploading(false);
        } else {
          setDialogMSG(response.mensagem);
          setDialogResponse(true);
          setUploading(false);
        }
        setSelectedFile(null);
      } else {
        setDialogMSG(
          'Ação não concluída. Verifique se as dimensões ou o tamanho da imagem são válidos',
        );
        setDialogResponse(true);
        cancelUpload();
        setUploading(false);
      }
    };
  };
  const creatingPost = async (reqBody) => {
    console.log(reqBody);
    const response = await createNewPost.callApi(reqBody);
    if (response.status === 201) {
      setClearFileStatus(true);
      setDialogMSG('A ação foi realizada com sucesso !!!!');
      setDialogResponse(true);
    } else {
      setDialogMSG('A ação não foi realizada com sucesso !!!!!');
      setDialogResponse(true);
    }
  };
  const actAndDeactPost = async () => {
    if (singlePost.ativo) {
      const response = await desativarPost.callApi();
      if (response.status === 204) {
        setDialogMSG('A ação foi realizada com sucesso');
        setTaggled(true);
      } else {
        setDialogMSG(response.mensagem);
      }
    } else {
      const response = await ativarPost.callApi();
      if (response.status === 204) {
        setDialogMSG('A ação foi realizada com sucesso');
        setTaggled(true);
      } else {
        setDialogMSG(response.mensagem);
      }
    }
    setDialogResponse(true);
    setActAndDeactDialog(false);
    setTaggled(false);
  };
  const deletePost = async () => {
    setDeletePostDialog(false);
    const response = await deletarPost.callApi();
    if (response.status === 204) {
      setDialogMSG('A ação foi realizada com sucesso');
    } else {
      setDialogMSG('A ação não foi realizada com sucesso');
    }
    setDialogResponse(true);
  };
  // const deleteImageInBucket = async () => {
  //   setDeletePostDialog(false);
  //   const response = await deleteFileInS3Bucket.callApi();
  //   if (response.status === 204) {
  //     deletePost();
  //   } else {
  //     setDialogMSG('Erro ao deletar a imagem no bucket S3');
  //   }
  //   setDialogResponse(true);
  //   setTaggled(false);
  // };
  useEffect(() => {
    if (clearFileStatus) {
      setClearFileStatus(false);
    }
    if (!clearFileStatus && !selectedFile) {
      getAllPosts();
    }
  }, [taggled, dialogResponse, selectedFile]);

  return (
    <>
      <Loading
        status={
          allPostsApp.loading ||
          ativarPost.loading ||
          desativarPost.loading ||
          deletarPost.loading ||
          createNewPost.loading ||
          s3UpLoad.loading ||
          uploading
        }
      />
      <Dialog
        open={creatingPostDialog}
        message={dialoMsgCreatPost}
        positiveAction={() => uploadFile()}
        negativeAction={() => setCreatingPostDialog(false)}
        positiveLabel="SIM"
        negativeLabel="NÃO"
        closeButton
        widthInPixel={2}
      />
      <Dialog
        open={delePostDialog}
        message={dialoMsgDeletePost}
        positiveAction={() => deletePost()}
        negativeAction={() => setDeletePostDialog(false)}
        positiveLabel="SIM"
        negativeLabel="NÃO"
        closeButton
        widthInPixel={2}
      />
      <Dialog
        open={actAndDeactDialog}
        message={dialoMsgActDeact}
        positiveAction={() => actAndDeactPost()}
        negativeAction={() => setActAndDeactDialog(false)}
        positiveLabel="SIM"
        negativeLabel="NÃO"
        closeButton
        widthInPixel={2}
      />
      <Dialog
        open={dialogResponse}
        message={dialoMSG}
        positiveAction={() => setDialogResponse(false)}
        positiveLabel="OK"
      />
      <Container>
        <ContainerFlex padding={10}>
          <TitleApp marginBottom={2}>Posts</TitleApp>
          <ContainerFlex>
            <Collapse
              title="Personalizar posts"
              initialState="close"
              titlePadding={5}
            >
              <TitleApp marginBottom={4}>Home - app</TitleApp>
              <ContainerFlex row wrap>
                {postsSystem.map((postApp) => (
                  <St.Container imageUrl={postApp.imagem} key={postApp.id}>
                    <St.CloseIcon onClick={() => openDeletePostDialod(postApp)}>
                      X
                    </St.CloseIcon>
                    <ToggleSwitch
                      toggled={postApp.ativo}
                      id={postApp.id}
                      onChangeFunction={() => openActAndDeactDialog(postApp)}
                      widthSwitchLabel={45}
                    />
                  </St.Container>
                ))}
                <St.ContainerDropButton>
                  <LocalDropzone
                    clearFileStatus={clearFileStatus}
                    onFileUploaded={setSelectedFile}
                  />
                  {selectedFile && (
                    <St.ContainerButtons>
                      <St.Button onClick={() => cancelUpload()}>
                        Cancelar
                      </St.Button>
                      <St.Button
                        principal
                        onClick={() => openCreatindPostDialog()}
                      >
                        Enviar
                      </St.Button>
                    </St.ContainerButtons>
                  )}
                </St.ContainerDropButton>
              </ContainerFlex>
              <ContainerFlex padding={10}>
                <St.SpanDimensoes>{fileSizeInformations}</St.SpanDimensoes>
              </ContainerFlex>
            </Collapse>
          </ContainerFlex>
        </ContainerFlex>
      </Container>
    </>
  );
};

export default ManagementAppHome;
