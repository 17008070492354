/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';

import { FiUser, FiSettings, FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AuthContext } from '~/contexts/authentication';
import logo from '../../assets/svg/logo-color.svg';
import avatar from '../../assets/icons/profile-user.png';

import * as St from './style';
import { cpfMask, IDefaultReturn } from '~/utils/masks';
import { useAuth } from '~/hooks/contexts/useAuth';
import Breadcrumbs from '../Breadcrumbs';

interface Title {
  title?: string;
}

const Header: React.FC<Title> = ({ title }) => {
  const user = JSON.parse(localStorage.getItem('@NovaEra:user'));
  const [cpf] = useState<IDefaultReturn>(cpfMask(user?.cpf) as IDefaultReturn);
  const [open, setOpen] = useState(false);
  const { sidebarOpen } = useContext(AuthContext);
  const { signOut } = useAuth();
  const handleLogout = () => signOut();

  const hiddeCPFNumber = (value) => {
    const valueSplited = value.split('');

    return valueSplited.map((el, index) => {
      if (index > 3 && index < 11) return '*';
      return el;
    });
  };

  return (
    <St.Header isOpen={sidebarOpen}>
      <St.Logo>
        <img src={logo} alt="Logo TeuCard" />
        <span>
          Versão: &nbsp;
          {process.env.REACT_APP_VERSAO_APP}
        </span>
      </St.Logo>
      <St.Profile>
        <img
          src={avatar}
          alt="Imagem de Perfil"
          onClick={() => {
            setOpen(!open);
          }}
        />
      </St.Profile>

      <St.DropdownMenu isActive={open}>
        <St.DropdownMenuHeader>
          <h3>{user?.nomeTitular}</h3>
          <p>{hiddeCPFNumber(cpf.masked)}</p>
        </St.DropdownMenuHeader>
        <St.ListItem>
          <li>
            <Link to="/homeDash">
              <FiUser />
              Meu perfil
            </Link>
          </li>
          <li>
            <Link to="/homeDash">
              <FiSettings />
              Configurações
            </Link>
          </li>
          <li onClick={handleLogout}>
            <a>
              <FiLogOut />
              Sair
            </a>
          </li>
        </St.ListItem>
      </St.DropdownMenu>
    </St.Header>
  );
};

export default Header;
