/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '~/hooks/contexts/useAuth';
import Constants from '~/utils/constants';
import { routesConfig } from './routeRoles';

export default ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  const validateRoles = () => {
    const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));

    const routesFinded = routesConfig.filter(
      (route) => route.route === rest.path,
    );

    if (!routesFinded.length) return true;

    return routesFinded.some((route) => {
      return route.roles.some((role) => userRoles.includes(role));
    });
  };

  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          validateRoles() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/homeDash', state: { from: props.location } }}
            />
          )
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};
