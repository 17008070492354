export const headerContactHistory = [
  {
    id: 1,
    value: 'Data contato',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 2,
    value: 'Descrição',
    maxChar: 30,
    textAlign: 'center',
  },
  { id: 3, value: '', maxChar: 10, textAlign: 'center' },

  {
    id: 4,
    value: 'Tipo contato',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Operador(a)',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Data próximo contato',
    maxChar: 30,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Horário',
    maxChar: 30,
    textAlign: 'center',
  },
];

export const headerHistoric = [
  {
    id: 0,
    value: 'Data Ação',
    maxChar: 28,
    textAlign: 'left',
  },
  {
    id: 1,
    value: 'Descrição',
    maxChar: 28,
    textAlign: 'left',
  },
  {
    id: 2,
    value: '',
    maxChar: 10,
    textAlign: 'left',
  },
  {
    id: 3,
    value: 'Responsável',
    maxChar: 28,
  },
  {
    id: 4,
    value: 'Status',
    maxChar: 28,
    textAlign: 'left',
  },
];

export const headerGeneralEvents = [
  { id: 0, maxChar: 10 },
  {
    id: 1,
    value: 'Nome cliente',
    maxChar: 28,
  },
  {
    id: 2,
    value: 'Evento',
    maxChar: 28,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Produto',
    maxChar: 28,
  },
  {
    id: 4,
    value: 'Data ação',
    maxChar: 28,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Data limite',
    maxChar: 28,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Status',
    maxChar: 28,
    textAlign: 'center',
  },
  {
    id: 8,
    value: 'Origem',
    maxChar: 28,
    textAlign: 'center',
  },
  {
    id: 10,
    value: 'Ação',
    maxChar: 10,
    textAlign: 'center',
  },
];
