/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { createContext, useState } from 'react';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import {
  ReportDelayResponse,
  ReportFutureInvoiceResponse,
  ReportInvoiceIssuedResponse,
  ReportInvoicesReceivedResponse,
  ReportManuallyInvoiceResponse,
} from '~/services/report/requestReport/response';
import { CSV_HEADERS } from '../../constants';
import Models from '../../models';

const InvoiceContext = createContext<IInvoiceReport>({} as IInvoiceReport);

export interface IInvoiceReport {
  getReportInvoiceDelay: Function;
  getReportInvoiceIssued: Function;
  getReportInvoiceReceived: Function;
  getFutureInvoices: Function;
  getManuallyInvoices: Function;
  items: any;
  headerItems: any;
  header: any;
  totalPages: number;
  totalItems: number | null;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  loading: boolean;
  clearForm: () => React.SetStateAction<any>;
  INVOICE_TYPES: Array<{ id: string; name: string; value: string }>;
}

const InvoiceProvider: React.FC = ({ children }) => {
  const [items, setItems] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [relatorioData, setRelatorioData] = useState([]);

  const RequestReportInvoiceIssued = useApi<ReportInvoiceIssuedResponse>(
    Endpoints.report.getReportInvoiceIssued,
    ApiMethod.POST,
  );
  const RequestReportDelay = useApi<ReportDelayResponse>(
    Endpoints.report.getReportDelay,
    ApiMethod.POST,
  );

  const RequestReportInvoicesReceived = useApi<ReportInvoicesReceivedResponse>(
    Endpoints.report.getReportInvoicesReceived,
    ApiMethod.POST,
  );

  const RequestReportFutureInvoices = useApi<ReportFutureInvoiceResponse>(
    Endpoints.report.getReportFutureInvoices,
    ApiMethod.POST,
  );

  const RequestReportManuallyInvoices = useApi<ReportManuallyInvoiceResponse>(
    Endpoints.report.getReportManuallyInvoice,
    ApiMethod.POST,
  );

  const clearForm = () => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
  };

  const loading =
    RequestReportInvoiceIssued.loading ||
    RequestReportDelay.loading ||
    RequestReportInvoicesReceived.loading ||
    RequestReportFutureInvoices.loading ||
    RequestReportManuallyInvoices.loading;

  const INVOICE_TYPES = [
    {
      id: 'EMITIDAS',
      name: 'Faturas Emitidas',
      value: 'Faturas Emitidas',
    },
    {
      id: 'RECEBIDAS',
      name: 'Faturas Recebidas',
      value: 'Faturas Recebidas',
    },
    {
      id: 'TRANSACAO_MANUAL_BACKOFFICE',
      name: 'Transação Manual BackOffice',
      value: 'Transação Manual BackOffice',
    },
  ];

  const getReportInvoiceDelay = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportDelay.callApi(payload);

    setHeader(CSV_HEADERS.INVOICE_DELAY);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderInvoiceDelay);
      setItems(Models.BodyInvoiceDelay(data));
    }
  };

  const getReportInvoiceIssued = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportInvoiceIssued.callApi(newPayload);

    setHeader(CSV_HEADERS.INVOICE_ISSUED);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportInvoiceIssued);
      setItems(Models.BodyReportInvoiceIssued(data));
    }
  };

  const getReportInvoiceReceived = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = { ...payload, limitePorPagina: limit, pagina: page };

    const response = await RequestReportInvoicesReceived.callApi(newPayload);

    setHeader(CSV_HEADERS.INVOICES_RECEIVED);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderInvoiceReceived);
      setItems(Models.BodyInvoiceReceived(data));
    }
  };

  const getFutureInvoices = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportFutureInvoices.callApi(payload);

    setHeader(CSV_HEADERS.FUTURE_INVOICE);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.data.totalDePaginas);
      setTotalItems(data.data.totalItens);
      setHeaderItems(Models.HeaderFutureInvoices);
      setItems(Models.BodyFutureInvoices(data.data));
    }
  };

  const getManuallyInvoices = async (
    page = 1,
    limit,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = { ...payload, qtdRegistros: limit, pagina: page };

    const response = await RequestReportManuallyInvoices.callApi(newPayload);

    setHeader(CSV_HEADERS.MANUALLY_INVOICE);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalPaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderManuallyInvoices);
      setItems(Models.BodyManuallyInvoices(data.data));
      // setRelatorioData(Models.BodyReportManuallyCSV(data));
    }
  };

  return (
    <InvoiceContext.Provider
      value={{
        getReportInvoiceDelay,
        getReportInvoiceIssued,
        getReportInvoiceReceived,
        getFutureInvoices,
        getManuallyInvoices,
        items,
        headerItems,
        header,
        totalPages,
        totalItems,
        loading,
        INVOICE_TYPES,
        relatorioData,
        setRelatorioData,
        clearForm,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};
export { InvoiceProvider, InvoiceContext };
