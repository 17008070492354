import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderReportEventAccountCancellation = [
  {
    id: 1,
    value: 'Nome do Cliente',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 13,
    textAlign: 'center',
  },
  { id: 3, value: 'Data do evento', maxChar: 13, textAlign: 'center' },
  { id: 4, value: 'Data validação', maxChar: 10, textAlign: 'center' },
  {
    id: 5,
    value: 'Operador(a) de Venda',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Tipo de cancelamento',
    maxChar: 14,
    textAlign: 'center',
  },
];

export const BodyReportEventAccountCancellation = (data: any) => {
  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome,
        maxChar: 15,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 13,
        textAlign: 'center',
      },
      {
        value: i.dataInclusaoEvento
          ? moment(i.dataInclusaoEvento, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.dataValidacao
          ? moment(i.dataValidacao, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-',
        maxChar: 10,
        textAlign: 'center',
      },
      {
        value: i.operador?.nome,
        maxChar: 14,
        textAlign: 'center',
      },
      {
        value: i.tipoCancelamento,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportEventAccountCancellationCSV = (data: any) => {
  const dataCSV = [];

  data.forEach((csv) => {
    dataCSV.push({
      nome: csv.nome,
      cpf: csv.cpf,
      email: csv.email,
      celular: csv.celular,
      dataInclusaoEvento: csv.dataInclusaoEvento,
      dataValidacao: csv.dataValidacao,
      operador: csv.operador,
      tipoCancelamento: csv.tipoCancelamento,
      motivo: csv.motivo ? csv.motivo.replace('api-autorizador-net:', '') : '',
    });
  });

  return dataCSV;
};
