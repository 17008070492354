/* eslint-disable react/jsx-curly-newline */

import { useContext, useEffect, useState } from 'react';
import Endpoints from '~/services/endpoints';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { IPhoneZeuss, IThemeCard } from '../../Interfaces/sharedInterfaces';
import { SharedCardServicesContext } from './sharedCardServiceContext';
import { icons } from '~/assets/index';
import Dialog from '~/components/dialog';
import {
  ICardDecisionDetailInfo,
  ILoadingCardTypes,
} from '../../Interfaces/cardsIntefaces';
import SharedServiceContext from '../../Services/sharedServiceContext';
import { currencyMask, phoneMask } from '~/utils/masks';

const SharedCardServicesProvider = ({ children }) => {
  const [cpf, setCpf] = useState<string>('');
  const [errorPhoneZeuss, setErrorPhoneZeuss] = useState(false);
  const [phoneZeussCard, setPhoneZeussCard] = useState<IPhoneZeuss>({
    masked: '',
    unmasked: '',
  });
  const [cardsNotClients, setCardsNotClient] = useState<IThemeCard[]>();
  const [opinionDowngrade, setOpinionDowngrade] = useState<string>('');
  const [messageActive, setMessageActive] = useState(false);
  const [messageText, setMessageText] = useState('');
  const {
    eventId,
    event,
    product,
    clientData,
    setCardModelSelected,
    setPhoneZeuss,
  } = useContext(SharedServiceContext);
  const [
    headerInfoArray,
    setHeaderInfoArray,
  ] = useState<ICardDecisionDetailInfo>({
    planoAtual: '',
    novoPlano: '',
    novoTema: '',
    anuidade: '',
    celularZeuss: '',
    dataInclusao: '',
    solicitadorVenda: '',
    tipoEvento: '',
    tipoProduto: '',
  });

  useEffect(() => {
    if (clientData) {
      setHeaderInfoArray((prevState) => ({
        ...prevState,
        planoAtual: `TeuCard ${clientData?.categoriaCartao
          ?.charAt(0)
          .toUpperCase()}${clientData?.categoriaCartao
          ?.toLowerCase()
          .slice(1)}`,
      }));
    }
  }, [clientData]);

  const getThemeClientNotCategory = useApi<IThemeCard[]>(
    `${Endpoints.card.getClientCategoryNotHave.replace('PARAM_CPF', cpf)}`,
    ApiMethod.GET,
  );

  const postValidateZeuss = useApi<any>(
    `${Endpoints.consult.postValidatePhoneZeuss
      .replace('PARAM_CPF', cpf)
      .replace('PARAM_PHONE', phoneZeussCard.unmasked)}`,
    ApiMethod.POST,
  );

  const getCardEventInfos = useApi<any>(
    `${Endpoints.useCases.getCardInfoEvent.replace('PARAM_ID', eventId)}`,
    ApiMethod.GET,
  );

  const getNotClientCards = async () => {
    try {
      const response = await getThemeClientNotCategory.callApi();
      if (response.status >= 200) {
        setCardsNotClient(response.data);
        setHeaderInfoArray((prevState) => ({
          ...prevState,
          anuidade: `R$ ${currencyMask(response.data?.data?.[0]?.valor)}`,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const validatePhone = async () => {
    try {
      const response = await postValidateZeuss.callApi();
      if (response.status >= 200 && response.status < 300) {
        console.log('Ok');
        setErrorPhoneZeuss(false);
      } else {
        setErrorPhoneZeuss(true);
        setMessageActive(true);
        setMessageText(response.mensagem);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const loading: ILoadingCardTypes = {
    getThemeClientNotCategory: { loading: getThemeClientNotCategory.loading },
  };

  const getEventsDetail = async () => {
    try {
      const response = await getCardEventInfos.callApi();

      if (response.status >= 200 && response.status < 300) {
        setCardModelSelected(response.data.cartao?.tema);
        setPhoneZeuss(phoneMask(response.data.cartao?.celularZeuss));
        setHeaderInfoArray((prevState) => ({
          ...prevState,
          novoPlano: `TeuCard ${response.data.cartao?.tema
            ?.charAt(0)
            .toUpperCase()}${response.data.cartao?.tema
            ?.toLowerCase()
            .slice(1)}`,
          novoTema: `${response.data.cartao?.tema
            ?.charAt(0)
            .toUpperCase()}${response.data.cartao?.tema
            ?.toLowerCase()
            .slice(1)}`,
          celularZeuss: phoneMask(response.data.cartao?.celularZeuss).masked,
          solicitadorVenda: response.data?.solicitadorVenda?.nome,
          dataInclusao: response.data?.dataInclusao,
          tipoEvento: event?.value,
          tipoProduto: product?.value,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const resetContextCard = () => {
    setCpf('');
    setPhoneZeussCard({ masked: '', unmasked: '' });
    setCardsNotClient(undefined);
    setOpinionDowngrade('');
    setMessageActive(false);
  };

  return (
    <SharedCardServicesContext.Provider
      value={{
        cpf,
        setCpf,
        phoneZeussCard,
        setPhoneZeussCard,
        cardsNotClients,
        setCardsNotClient,
        loading,
        opinionDowngrade,
        getNotClientCards,
        setOpinionDowngrade,
        resetContextCard,
        validatePhone,
        errorPhoneZeuss,
        setErrorPhoneZeuss,
        headerInfoArray,
        getEventsDetail,
      }}
    >
      {children}
      <Dialog
        open={messageActive}
        positiveAction={() => {
          setMessageActive(false);
        }}
        message={messageText}
        positiveLabel="Ok"
        icon={icons.warning}
        widthInPixel={140}
      />
    </SharedCardServicesContext.Provider>
  );
};

export default SharedCardServicesProvider;
