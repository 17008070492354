import styled from 'styled-components';

interface IData {
  marginLeft?: number;
  maxChar?: number;
  textAlign?: string;
}

export const hederTable = styled.thead`
  height: 71px;
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 16px;
`;

export const hederTitle = styled.th<IData>`
  font-size: 14px;
  font-weight: 600;
  color: #4a4b4a;
  text-align: left;
  letter-spacing: 0.02em;
  word-break: break-word;
  padding: 0 20px;
  width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
`;

export const bodyTable = styled.tbody`
  line-height: 40px;

  tr {
    height: 71px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px 0;
    padding: 16px;
  }
`;

export const bodyData = styled.td<IData>`
  font-size: 14px;
  font-weight: 400;
  color: #4a4b4a;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px;
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  img {
    margin-top: 20px;
  }
`;

export const bodyDataValue = styled.span<IData>`
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
`;

export const IconRow = styled.img`
  width: 20px;
  height: 20px;
`;

export const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;
