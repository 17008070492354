import styled from 'styled-components';

export const Input = styled.input`
  height: 42px;
  width: 100%;
  border: 1px solid rgba(13, 9, 37, 0.2);
  border-radius: 17px;
  background-color: #ffffff;
  color: #0d0925;
  outline: none;

  padding-left: 28px;

  & :focus ::-webkit-input-placeholder {
    color: transparent;
  }
  & ::-webkit-input-placeholder {
    font-size: 3rem;
    text-align: center;
    color: #0d0925;
  }
`;
