import { components } from '~/assets/index';
import * as St from './styles';

interface IButtonAction {
  onClick: () => void;
  icon: React.ReactNode;
}

const ButtonAction: React.FC<IButtonAction> = ({ onClick, icon }) => {
  return <St.Container onClick={onClick}>{icon}</St.Container>;
};

export default ButtonAction;
