/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface FormField {
  name?: string;
  value?: string;
  unmasked?: string;
  status?: string;
}

export const setFormState = (
  e,
  currentState: any,
  currentSetState: Function,
) => {
  const {
    target: { name, value },
  } = e || { target: { name: '', value: '' } };

  if (!name) return;

  const newState = {
    ...currentState,
    [name]: {
      name,
      value: value?.masked ?? value,
      unmasked: value.unmasked,
    },
  };

  if (currentSetState) currentSetState(newState);
};

export const removeAccents = (string: string) => {
  return string?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
