import styled from 'styled-components';

const RadioButton = styled.input.attrs({
  type: 'radio',
})`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-clip: content-box;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px gray inset;
  margin-right: 5px;
  cursor: pointer;

  :before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    margin: 2px auto;
    border-radius: 50%;
  }

  :checked:before {
    background-color: ${({ theme: { colors } }) => colors.primary};
  }
`;

export default RadioButton;
