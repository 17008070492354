import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import TableList from '~/components/tableList';
import { useReportFinancial } from './context/useFinancial';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import FilterFinancial from './components/FilterFinancial';

import * as S from './styles';

const ReportFinancial: React.FC = () => {
  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [form, setForm] = useState({
    tipoRelatorio: { name: 'tipoBeneficio', value: '' },
    dataInicial: moment().format('DD/MM/YYYY'),
    dataFinal: moment().format('DD/MM/YYYY'),
    tipoDeEncargo: '',
    tipoDeData: '',
  });

  const {
    postReportIOF,
    postReportFinancialIncome,
    postReportRecipeAgreement,
    postReportCashBackZeuss,
    items,
    totalItems,
    totalPages,
    headerItems,
    header,
    relatorioData,
    setRelatorioData,
    clearForm,
    checkSuccess,
    loading,
  } = useReportFinancial();

  const payloadType = {
    RELATORIO_IOF: {
      dataFinal: moment(form.dataFinal).format('DD/MM/YYYY'),
      dataInicial: moment(form.dataInicial).format('DD/MM/YYYY'),
    },
    RECEITAS_FINANCEIRAS_RECEBIDAS: {
      periodoFinal: moment(form.dataFinal).format('DD/MM/YYYY'),
      periodoInicial: moment(form.dataInicial).format('DD/MM/YYYY'),
      tipo: Number(form.tipoDeEncargo),
    },
    RECEITAS_ACORDO: {
      periodoFinal: moment(form.dataFinal).format('YYYY-MM-DD'),
      periodoInicial: moment(form.dataInicial).format('YYYY-MM-DD'),
      tipoData: form.tipoDeData,
    },
    CASHBACK_ZEUSS: {
      dataFinal: moment(form.dataFinal).format('YYYY-MM-DD'),
      dataInicial: moment(form.dataInicial).format('YYYY-MM-DD'),
      tipoPeriodo: form.tipoDeData,
    },
  };

  const getReport = async (page = 1) => {
    const payload = payloadType[form.tipoRelatorio.value];

    switch (form.tipoRelatorio?.value) {
      case 'RELATORIO_IOF':
        await postReportIOF(page, 10, false, payload);
        break;
      case 'RECEITAS_FINANCEIRAS_RECEBIDAS':
        await postReportFinancialIncome(page, 30, false, payload);
        break;
      case 'RECEITAS_ACORDO':
        await postReportRecipeAgreement(page, 30, false, payload);
        break;
      case 'CASHBACK_ZEUSS':
        await postReportCashBackZeuss(page, 30, false, payload);
        break;
      default:
        break;
    }
  };

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload = payloadType[form?.tipoRelatorio?.value];

    switch (form.tipoRelatorio?.value) {
      case 'RELATORIO_IOF':
        response = await postReportIOF(currentPage, 150, true, payload);
        break;
      case 'RECEITAS_FINANCEIRAS_RECEBIDAS':
        response = await postReportFinancialIncome(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'RECEITAS_ACORDO':
        response = await postReportRecipeAgreement(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'CASHBACK_ZEUSS':
        response = await postReportCashBackZeuss(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      default:
        break;
    }

    if (response || response.pagina.length || response.data) {
      let data;

      if (response.totalDePaginas || response.totalPaginas) {
        data = response;
      }
      if (!response.totalDePaginas) {
        data = response.data || response.vendas;
      }

      totalPages = data.totalDePaginas || response.totalPaginas;

      const percentage = (currentPage / totalPages) * 100;
      const roundPercentage = Math.ceil(percentage);
      setDownloadCVSProgress(`${roundPercentage || 0}%`);

      if (data?.pagina?.length) {
        setRelatorioData((prev) => [...prev, ...data.pagina]);
      }

      if (data?.length && form.tipoRelatorio?.value === 'CASHBACK_ZEUSS') {
        setRelatorioData((prev) => [...prev, ...data]);
      }

      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
    } else {
      setRelatorioData([]);
    }
  };

  useEffect(() => {
    if (form.tipoRelatorio?.value !== '') {
      getReport();
    }
    clearForm();
  }, [form]);

  return (
    <>
      <Loading status={loading} text={downloadCVSProgress} />
      <Container>
        <FilterFinancial
          onClick={(e) => {
            setForm(e);
            setForm({
              ...e,
              dataInicial: moment(e.dataInicial, 'DD-MM-YYYY').format(
                'YYYY-MM-DD',
              ),
              dataFinal: moment(e.dataFinal, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            });
          }}
        />

        <CSVLink
          ref={refCSV}
          data={relatorioData}
          style={{
            color: 'inherit',
            textDecoration: 'none',
          }}
          headers={header}
          filename={`relatorio_${form.tipoRelatorio.value}.csv`}
          target="_blank"
        />

        {items?.length > 0 ? (
          <S.BoxCard>
            <ContainerFlex flex="0.2" padding={16} marginTop={10}>
              <ContainerFlex row space center padding={24}>
                <ContainerFlex>
                  <p>
                    Resultados retornados:
                    <span style={{ marginLeft: '5px' }}>{totalItems}</span>
                  </p>
                </ContainerFlex>
                <Button
                  onClick={() => {
                    if (refCSV.current) {
                      onClickDownloadCSV().then(() =>
                        refCSV.current.link.click(),
                      );
                    }
                  }}
                  disabled={loading}
                >
                  Download relatório
                </Button>
              </ContainerFlex>
              <ContainerFlex>
                <TableList customerHeader={headerItems} list={items} />
                <ContainerFlex row horizontal center marginTop={20}>
                  {form?.tipoRelatorio?.value !== 'RELATORIO_IOF' &&
                    form?.tipoRelatorio?.value !==
                      'RECEITAS_FINANCEIRAS_RECEBIDAS' &&
                    form?.tipoRelatorio?.value !== 'RECEITAS_ACORDO' && (
                      <Pagination
                        totalPages={totalPages}
                        onCurrentPageChange={getReport}
                      />
                    )}
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </S.BoxCard>
        ) : null}
        {checkSuccess() && items.length <= 0 && (
          <ContainerFlex center>
            <TitleCategory>Nenhum resultado encontrado</TitleCategory>
          </ContainerFlex>
        )}
      </Container>
    </>
  );
};

export default ReportFinancial;
