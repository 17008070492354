import styled from 'styled-components';

export const DivDivider = styled.div`
  width: 50px;
`;

export const SpanLabel = styled.span`
  font-size: 0.9em;
  font-weight: 300;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const SpanLabelFeed = styled.span`
  font-size: 0.8em;
  font-weight: 300;
  margin-top: 5px;
  color: red;
`;

export const Results = styled.p`
  font-weight: 600;
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin: 0 0 24px 0;
`;

export const BoxCardHeader = styled.div`
  width: 100%;
`;

export const TitleApp = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #4f525a;
  margin-bottom: 30px;
`;

export const SectionReport = styled.div`
  width: 400px;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;
