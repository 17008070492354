import React, { useContext } from 'react';
import { AuthContext } from '~/contexts/authentication';
import Breadcrumbs from '../Breadcrumbs';
import Header from '../header';
import Sidebar from '../sidebar';
import * as St from './style';

interface Title {
  title?: string;
  children?: any;
}

const Container: React.FC<Title> = ({ children, title }) => {
  const { sidebarOpen } = useContext(AuthContext);
  return (
    <>
      <St.Container>
        <Sidebar />
        <St.Content isOpen={sidebarOpen}>
          <Header />
          <main>
            <Breadcrumbs />
            {children}
          </main>
        </St.Content>
      </St.Container>
    </>
  );
};

export default Container;
