/* eslint-disable react/jsx-curly-newline */
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import { setFormState } from '~/utils/form';
import { UFs } from '../../../constants';
import {
  LOAN_TYPES,
  STATUS_LOAN_SEEKER,
  STATUS_WITHDRAW,
  PERIOD_WITHDRAW_BILLING,
} from '../../models';

import * as S from '../../styles';
import { LoanReportContext } from '../../context/LoanReportContext';

interface IFilterLoan {
  operador: any[];
  onClick?: (any: any) => void;
}

const FilterLoan: React.FC<IFilterLoan> = ({ operador, onClick }) => {
  const { form, setForm } = useContext(LoanReportContext);

  const handleChange = (el): void => {
    onClick(el);
  };

  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: 4 }, (_, index) => {
    const year = (currentYear - 3 + index).toString();
    return {
      id: year,
      name: year,
      value: year,
    };
  });

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const months = monthNames.map((monthName, index) => ({
    id: `${index + 1 < 10 ? '0' : ''}${index + 1}`,
    name: monthName,
    value: monthName,
  }));

  return (
    <S.BoxCard>
      <ContainerFlex row space>
        <ContainerFlex marginRight={16}>
          <Select
            options={LOAN_TYPES}
            label="Tipo Emprestimo"
            name={form.tipoEmprestimo.name}
            value={form.tipoEmprestimo.value}
            onChange={(e) => {
              setFormState(
                { target: { name: form.tipoEmprestimo.name, value: e } },
                form,
                setForm,
              );
            }}
          />
        </ContainerFlex>

        {form.tipoEmprestimo.value !== 'PROPOSTAS_BUSCADOR' ||
          ('FATURAMENTO_SAQUE' && (
            <ContainerFlex marginRight={16}>
              <Select
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: '6041102c1b705c0007d62e81', value: 'Web' },
                  ...operador,
                ]}
                label="Operador(a)"
                value={form.idOperador}
                onChange={(e) => {
                  setForm({ ...form, idOperador: e });
                }}
              />
            </ContainerFlex>
          ))}
        {form.tipoEmprestimo.value === 'PROPOSTAS_BUSCADOR' && (
          <ContainerFlex marginRight={16}>
            <Select
              options={STATUS_LOAN_SEEKER}
              label="Status"
              value={form.status.value}
              name={form.status.name}
              onChange={(e) =>
                setFormState(
                  { target: { name: form.status.name, value: e } },
                  form,
                  setForm,
                )
              }
            />
          </ContainerFlex>
        )}

        {form.tipoEmprestimo.value === 'FATURAMENTO_SAQUE' && (
          <ContainerFlex marginRight={16}>
            <Select
              options={PERIOD_WITHDRAW_BILLING}
              label="Tipo de período"
              value={form.tipoPeriodo.value}
              name={form.tipoPeriodo.name}
              onChange={(e) =>
                setFormState(
                  { target: { name: form.tipoPeriodo.name, value: e } },
                  form,
                  setForm,
                )
              }
            />
          </ContainerFlex>
        )}
        {form.tipoEmprestimo.value === 'VENDAS_SAQUE' && (
          <ContainerFlex marginRight={16}>
            <Select
              options={STATUS_WITHDRAW}
              label="Status"
              value={form.status.value}
              name={form.status.name}
              onChange={(e) =>
                setFormState(
                  { target: { name: form.status.name, value: e } },
                  form,
                  setForm,
                )
              }
            />
          </ContainerFlex>
        )}

        {form.tipoEmprestimo.value !== 'RECEITA_INADIMPLENCIA' && (
          <>
            <ContainerFlex marginRight={16}>
              <Input
                label="Data inicial"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form.dataSimulacaoInicial}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    dataSimulacaoInicial: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>
            <ContainerFlex marginRight={16}>
              <Input
                label="Data final"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form.dataSimulacaoFinal}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    dataSimulacaoFinal: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>
          </>
        )}
        {form.tipoEmprestimo.value === 'RECEITA_INADIMPLENCIA' && (
          <>
            <ContainerFlex marginRight={16}>
              <Select
                options={months}
                label="Mês"
                value={form.mes.value}
                name={form.mes.name}
                onChange={(e) =>
                  setFormState(
                    { target: { name: form.mes.name, value: e } },
                    form,
                    setForm,
                  )
                }
              />
            </ContainerFlex>
            <ContainerFlex marginRight={16}>
              <Select
                options={years}
                label="Ano"
                value={form.ano.value}
                name={form.ano.name}
                onChange={(e) =>
                  setFormState(
                    { target: { name: form.ano.name, value: e } },
                    form,
                    setForm,
                  )
                }
              />
            </ContainerFlex>
          </>
        )}
      </ContainerFlex>
      <ContainerFlex>
        <ContainerFlex flex="0.2" marginTop={24} maxWidth={14}>
          {form.tipoEmprestimo.value === 'RECEITA_INADIMPLENCIA' ? (
            <Button
              fitContent
              onClick={() => {
                handleChange(form);
              }}
            >
              <FaDownload />
              <span style={{ marginLeft: '8px' }}>Download</span>
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleChange(form);
              }}
            >
              <span>Consultar</span>
            </Button>
          )}
        </ContainerFlex>
      </ContainerFlex>
    </S.BoxCard>
  );
};

export default FilterLoan;
