/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import TableList from '~/components/tableList';

import useApi, { ApiMethod } from '~/hooks/useApi';

import ConsultUserSearchResponse from '~/services/consult/user/search/response';
import Endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import { IListColCampaing } from '../LimitIncrease/model';

import * as St from './styles';

interface Form {
  cpf: FormField;
  numeroCartao: FormField;
  nome: string;
}

const ConsultUser = (): JSX.Element => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [researched, setResearched] = useState(false);

  const [form, setForm] = useState<Form>({
    cpf: { name: 'cpf', value: '' },
    numeroCartao: { name: 'numeroCartao', value: '' },
    nome: '',
  });

  const handleGenerateURL = useMemo(() => {
    if (form.cpf?.unmasked && form.numeroCartao?.unmasked && form.nome) {
      return `${Endpoints.consult.searchUser}?cpf=${form.cpf?.unmasked}&numeroCartao=${form.numeroCartao?.unmasked}&nome=${form.nome}`;
    }

    if (!form.cpf?.unmasked && form.numeroCartao?.unmasked && form.nome) {
      return `${Endpoints.consult.searchUser}?numeroCartao=${form.numeroCartao?.unmasked}&nome=${form.nome}`;
    }

    if (form.cpf?.unmasked && !form.numeroCartao?.unmasked && form.nome) {
      return `${Endpoints.consult.searchUser}?cpf=${form.cpf?.unmasked}&nome=${form.nome}`;
    }

    if (form.cpf?.unmasked && form.numeroCartao?.unmasked && !form.nome) {
      return `${Endpoints.consult.searchUser}?cpf=${form.cpf?.unmasked}&numeroCartao=${form.numeroCartao?.unmasked}`;
    }

    if (!form.cpf?.unmasked && !form.numeroCartao?.unmasked && form.nome) {
      return `${Endpoints.consult.searchUser}?nome=${form.nome}`;
    }

    if (form.cpf?.unmasked && !form.numeroCartao?.unmasked && !form.nome) {
      return `${Endpoints.consult.searchUser}?cpf=${form.cpf?.unmasked}`;
    }

    return `${Endpoints.consult.searchUser}?numeroCartao=${form.numeroCartao?.unmasked}`;
  }, [form]);

  const api = useApi<ConsultUserSearchResponse>(
    handleGenerateURL,
    ApiMethod.GET,
  );

  const customerHeader = [
    {
      id: 1,
      value: 'Nome completo',
      maxChar: 28,
    },
    {
      id: 2,
      value: 'Data do cadastro',
      maxChar: 28,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Origem do cadastro',
      maxChar: 28,
    },
    {
      id: 4,
      value: 'Status do cartão',
      maxChar: 28,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  useEffect(() => {
    loadData();
  }, [api.loading]);

  const searchUser = () => api.callApi();

  const goToDetail = (a: any, item: ConsultUserSearchResponse) => {
    history.push('/sales/detail', item);
  };

  const loadData = () => {
    if (api.status !== 200) {
      setList([]);
      return;
    }

    const dataRes = api.data as ConsultUserSearchResponse[];

    const newList = [];
    // let i = 0;

    if (api.status >= 200 && api.status < 300) {
      setResearched(true);
    }

    dataRes.forEach((data) => {
      const item: IListColCampaing[] = [
        // {
        //   type: 'id',
        //   value: i++,
        //   maxChar: 5,
        //   textAlign: 'center',
        // },
        {
          border: true,
          title: 'Nome completo',
          value: data.nome,
          maxChar: 28,
        },
        {
          border: true,
          title: 'Data do cadastro',
          value: data.dataCadastro,
          maxChar: 28,
          textAlign: 'center',
        },
        {
          border: true,
          title: 'Origem do cadastro',
          value: data.origem,
          maxChar: 28,
          textAlign: 'center',
        },
        {
          title: 'Status do cartão',
          maxChar: 28,
          value: data.cartaoAtual?.status,
          textAlign: 'center',
        },
        {
          item: data,
          searchAction: goToDetail,
        },
      ];

      newList.push(item);
    });

    setList(newList);
  };

  return (
    <>
      <Loading status={api.loading} />

      <Container>
        <St.BoxCard>
          {/* <St.Title>Vendas</St.Title> */}
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name={form.cpf.name}
                value={form.cpf.value}
                onChange={(e) => setFormState(e, form, setForm)}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Input
                label="Número do cartão"
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                mask={MaskTypes.CREDIT_CARD}
                onChange={(e) => setFormState(e, form, setForm)}
                name={form.numeroCartao.name}
                value={form.numeroCartao.value}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Input
                label="Nome"
                name="Nome"
                value={form.nome}
                onChange={(e) => setForm({ ...form, nome: e.target.value })}
              />
            </ContainerFlex>

            <ContainerFlex end padding={30} flex={1.5}>
              <Button
                fitContent
                onClick={searchUser}
                loading={api.loading}
                disabled={
                  (form.cpf.value.length !== 14 &&
                    form.numeroCartao.value.length !== 19 &&
                    !form.nome) ||
                  api.loading
                }
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {list.length <= 0 || api.status === 404 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <St.TitleCategory>Cliente não encontrado</St.TitleCategory>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex row>
              {(api.status === 404 || list.length <= 0) && (
                <ContainerFlex center>
                  <St.TitleCategory>
                    {api.status === 404
                      ? api.data.mensagem
                      : 'Nenhum resultado encontrado'}
                  </St.TitleCategory>
                </ContainerFlex>
              )}
            </ContainerFlex>

            <ContainerFlex>
              {list.length > 0 && (
                <TableList customerHeader={customerHeader} list={list} />
              )}

              <ContainerFlex center>
                {(api.status > 300 || (list.length === 0 && researched)) && (
                  <St.TitleCategory>
                    Não há resultados para mostrar aqui
                  </St.TitleCategory>
                )}
              </ContainerFlex>
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default ConsultUser;
