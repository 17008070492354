import { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from '~/components/layout';
import ContainerFlex from '~/components/containerFlex';
import TitleApp from '~/components/title';
import Collapse from '~/components/collapse';
import ToggleSwitch from '~/components/toggleSwitch';
import Dialog from '~/components/dialog';
import * as St from './styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Constants from '~/utils/constants';
import Container from '~/components/container';
import {
  AllProducts,
  ProductsInfo,
  S3BucketInfo,
} from '~/services/product/response';
import Endpoints from '~/services/endpoints';
import { icons } from '~/assets';
import Loading from '~/components/loading';

export interface FormData {
  file: File;
  filename: string;
  mimetype: string;
}

const ManagementProducts = (): JSX.Element => {
  const token = localStorage.getItem(Constants.TOKEN_KEY);
  const tokenBearer = localStorage.getItem(Constants.TOKEN_BEARER);

  const [productsOfSystem, setProductOfSystem] = useState([]);
  const [singleProduct, setSingleProduct] = useState({} as ProductsInfo);
  const [taggled, setTaggled] = useState(false);
  const [openFeed, setOpenFeed] = useState(false);
  const [dialogResponse, setDialogResponse] = useState(false);
  const [dialoMSG, setDialogMSG] = useState('');
  const [dialoMsgAtiDesa, setDialoMsgAtiDesa] = useState('');
  const [uploading, setUploading] = useState(false);

  const allProducts = useApi<AllProducts>(
    Endpoints.product.getAllProducts,
    ApiMethod.GET,
  );

  const ativarProduto = useApi(
    `${Endpoints.product.activateProduct.replace(
      'ID_PRODUTO',
      singleProduct?.id,
    )}`,
    ApiMethod.PUT,
  );

  const desativarProduto = useApi(
    `${Endpoints.product.deactivateProduct.replace(
      'ID_PRODUTO',
      singleProduct?.id,
    )}`,
    ApiMethod.PUT,
  );

  const changeProductImageUrl = useApi(
    `${Endpoints.product.changeImaUrl.replace(
      'ID_PRODUTO',
      singleProduct?.id,
    )}`,
    ApiMethod.PUT,
  );

  const s3UpLoad = useApi<S3BucketInfo>(
    Endpoints.s3Bucket.uploadFile,
    ApiMethod.POST,
    {},
    false,
    {},
    true,
  );

  const getAllProducts = async () => {
    const response = await allProducts.callApi();
    if (response.status === 200) {
      const { data } = response;
      setProductOfSystem(data.produtos);
    } else {
      setProductOfSystem([]);
    }
  };

  const readFile = async (event) => {
    const fileUploaded = event.target.files[0];
    const fileSize = fileUploaded.size / 1024 ** 2;
    const img = new Image();
    img.src = window.URL.createObjectURL(fileUploaded);
    img.onload = async () => {
      if (img.width === 334 && img.height === 146 && fileSize <= 2) {
        const fileName = fileUploaded.name.split('.').slice(0, -1).join('.');

        const formData = new FormData();

        formData.append('file', fileUploaded);
        formData.append('filename', fileName);
        formData.append('mimetype', fileUploaded.type);

        await uploadFile(formData);
      } else {
        setDialogMSG(
          'Ação não concluída. Verifique se as dimensões ou o tamanho da imagem são válidos',
        );
        setDialogResponse(true);
      }
      (document.getElementById(singleProduct.nome) as HTMLInputElement).value =
        '';
    };
  };
  const openFileManger = (product: ProductsInfo) => {
    setSingleProduct(product);
    document.getElementById(product.nome).click();
  };

  const uploadFile = async (formRequest) => {
    setUploading(true);

    const config = {
      headers: {
        apikey: `token ${token}`,
        Authorization: `Bearer ${tokenBearer}`,
      },
    };

    const response = await axios.post(
      Endpoints.s3Bucket.uploadFile,
      formRequest,
      config,
    );

    if (response.status === 201) {
      updateProductImage(response.data.url);
      setUploading(false);
    } else if (response.status === 403) {
      setDialogMSG('Você não possui permissão para prosseguir');
      setUploading(false);
    } else {
      setUploading(false);
      setDialogMSG('Erro ao fazer upload de image');
      setDialogResponse(true);
    }
  };

  const showDialog = (produ: ProductsInfo) => {
    setSingleProduct(produ);
    if (produ.ativo) {
      setDialoMsgAtiDesa(
        `Deseja desativar o produto ${produ.nome.replaceAll(' ', '')} ?`,
      );
    } else {
      setDialoMsgAtiDesa(
        `Deseja ativar o produto ${produ.nome.replaceAll(' ', '')} ?`,
      );
    }
    setOpenFeed(true);
  };
  const updateProductImage = async (urlImage: string) => {
    const response = await changeProductImageUrl.callApi({
      urlImagem: urlImage,
    });
    if (response.status === 204) {
      setDialogMSG('Produto atualizado com sucesso');
      setTaggled(true);
    } else if (response.status === 403) {
      setDialogMSG('Você não possui permissão para prosseguir');
    } else {
      setDialogMSG('A ação não foi realizada com sucesso');
    }
    setDialogResponse(true);
  };

  const ativarOuDesativarProduto = async () => {
    setOpenFeed(false);
    if (singleProduct.ativo) {
      const response = await desativarProduto.callApi();
      if (response.status === 204) {
        setDialogMSG('A ação foi realizada com sucesso');
        setTaggled(true);
      } else if (response.status === 403) {
        setDialogMSG('Você não possui permissão para prosseguir');
      } else {
        setDialogMSG('Ação não realizada com secesso');
      }
    } else {
      const response = await ativarProduto.callApi();
      if (response.status === 204) {
        setDialogMSG('A ação foi realizada com sucesso');
        setTaggled(true);
      } else {
        setDialogMSG(response.mensagem);
      }
    }
    setDialogResponse(true);
  };

  useEffect(() => {
    getAllProducts();
  }, [taggled, dialogResponse, changeProductImageUrl.loading]);
  return (
    <>
      <Loading
        status={
          allProducts.loading ||
          ativarProduto.loading ||
          desativarProduto.loading ||
          changeProductImageUrl.loading ||
          s3UpLoad.loading ||
          uploading
        }
      />
      <Dialog
        open={openFeed}
        message={dialoMsgAtiDesa}
        positiveAction={() => ativarOuDesativarProduto()}
        negativeAction={() => setOpenFeed(false)}
        positiveLabel="SIM"
        negativeLabel="NÃO"
        closeButton
        widthInPixel={2}
      />
      <Dialog
        open={dialogResponse}
        message={dialoMSG}
        positiveAction={() => setDialogResponse(false)}
        positiveLabel="OK"
      />
      <Container>
        <ContainerFlex>
          <TitleApp marginBottom={2}>Produtos</TitleApp>
          <ContainerFlex>
            <Collapse
              title="Produtos disponíveis"
              initialState="close"
              titlePadding={5}
            >
              <ContainerFlex row wrap>
                {productsOfSystem.map((product) => (
                  <St.Container key={product.id}>
                    <St.ImageDive imageUrl={product.imagem}>
                      <St.DiveFile onClick={() => openFileManger(product)}>
                        <St.InputFile
                          type="file"
                          id={product.nome}
                          onChange={(event) => readFile(event)}
                        />
                        <St.FileSpan>
                          <St.Icon src={icons.uploadFileIcon} />
                          Substituir imagem
                        </St.FileSpan>
                      </St.DiveFile>
                    </St.ImageDive>
                    <ContainerFlex padding={5}>
                      <ToggleSwitch
                        id={product.id}
                        toggled={product.ativo}
                        onChangeFunction={() => showDialog(product)}
                      />
                      <St.SpanStatus>
                        status:
                        {product.ativo ? ' Ativo' : ' Desativado'}
                      </St.SpanStatus>
                    </ContainerFlex>
                  </St.Container>
                ))}
              </ContainerFlex>
              <ContainerFlex padding={10}>
                <St.SpanDimensoes>
                  Dimensões das imagens: 334 x 146px. Size menor ou igual a 2MB
                </St.SpanDimensoes>
              </ContainerFlex>
            </Collapse>
          </ContainerFlex>
        </ContainerFlex>
      </Container>
    </>
  );
};

export default ManagementProducts;
