import { CONNREFUSED } from 'dns';
import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask, dateMask, phoneMask } from '~/utils/masks';

export const HeaderCashBackZeuss = [
  {
    id: 1,
    value: 'Nome do cliente',
    textAlign: 'center',
    maxChar: 14,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 20,
  },
  {
    id: 3,
    value: 'Celular Zeuss',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 4,
    value: 'Valor Compra Paga',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 5,
    value: 'Valor CashBack',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 6,
    value: 'Data Vencimento Fatura',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 7,
    value: 'Data Pagamento Fatura',
    textAlign: 'center',
    maxChar: 15,
  },
];

export const BodyCashBackZeuss = (data: any) => {
  const items = [];

  data?.data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        textAlign: 'center',
        maxChar: 14,
      },
      {
        value: i.celularZeuss ? phoneMask(i.celularZeuss).masked : '',
        textAlign: 'center',
        maxChar: 14,
      },
      {
        value: `R$ ${currencyMask(i.valorCompras)}`,
        textAlign: 'center',
        maxChar: 14,
      },
      {
        value: `R$ ${currencyMask(i.valorCashBack)}`,
        textAlign: 'center',
        maxChar: 14,
      },
      {
        value: i.dataVencimentoFatura
          ? moment(i.dataVencimentoFatura).format('DD/MM/YYYY')
          : '',
        textAlign: 'center',
        maxChar: 14,
      },
      {
        value: i.dataPagamento
          ? moment(i.dataPagamento).format('DD/MM/YYYY')
          : '',
        textAlign: 'center',
        maxChar: 14,
      },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportCashBackZeussCSV = (data: any) => {
  const dataCSV = [];

  data?.data?.forEach((csv) => {
    if (csv.celularZeuss !== undefined) {
      dataCSV.push({
        nomeCliente: csv.nome,
        cpf: csv.cpf,
        celularZeuss: csv.celularZeuss,
        valorCompras: csv.valorCompras,
        valorCashBack: csv.valorCashBack,
        dataVencimentoFatura: csv.dataVencimentoFatura,
        dataPagamentoFatura: csv.dataPagamento,
      });
    }
  });

  return dataCSV;
};
