import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  border: none;
  border-radius: 0.375rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 30px;
  width: 30px;

  &:hover {
    background-color: ${darken(0.045, '#ffffff')};
  }

  svg {
    width: 18px;
    height: 18px;

    g {
      fill: #e4003a;
    }
  }
`;
