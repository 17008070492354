import styled, { css } from 'styled-components';

export const TitleLevel = styled.span`
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 700;
  color: #4f545c;
  margin-bottom: 8px;
`;

export const TitleLevelSmall = styled.span`
  text-align: left;
  font-size: 1em;
  color: #4f545c;
  font-weight: 300;
  padding-bottom: 12px;
  margin-bottom: 8px;
  margin-right: 16px;
  white-space: nowrap;
`;
