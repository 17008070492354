import React, { forwardRef, memo } from 'react';

import * as St from './styles';

interface IChatInput {
  onChange: (e: React.ChangeEvent) => void;
}

const ChatInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IChatInput
> = ({ onChange }, ref) => {
  return (
    <St.Input
      ref={ref}
      placeholder="Digite sua mensagem…"
      onChange={onChange}
    />
  );
};

export default memo(forwardRef(ChatInput));
