/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderReportAdesoes = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
  },
  {
    id: 3,
    value: 'Operador(a) de Venda',
    maxChar: 15,
  },
  {
    id: 4,
    value: 'Data Adesão',
    maxChar: 13,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Possui TeuCard?',
    maxChar: 9,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'UF',
    maxChar: 9,
    textAlign: 'center',
  },
];

export const BodyReportAdesoes = (adesoes: any) => {
  const items = [];
  adesoes.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome ? i.nome.toUpperCase() : '-',
        maxChar: 15,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.nomeOperador ? i.nomeOperador.toUpperCase() : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.dataAdesao ? moment(i.dataAdesao).format('DD/MM/YYYY') : '-',
        maxChar: 13,
        textAlign: 'center',
      },
      {
        value: i.cartaoTeuCard ? 'Sim' : 'Não',
        maxChar: 5,
        textAlign: 'center',
      },
      {
        value: i.uf ? i.uf : '-',
        maxChar: 5,
        textAlign: 'center',
      },
    ];

    items.push(item);
  });

  return items;
};

export const BodyReportAdesoesCSV = (adesoes: any) => {
  const dataCSV = [];

  adesoes.forEach((csv) => {
    if (csv.dataAdesao) {
      dataCSV.push({
        nome: csv.nome,
        cpf: csv.cpf,
        email: csv.email,
        celular: csv.celular,
        nomeOperador: csv.nomeOperador,
        dataAdesao: csv.dataAdesao,
        loja: csv.loja,
        cartaoTeuCard: csv.cartaoTeuCard ? 'SIM' : 'NÃO',
        uf: csv.uf,
      });
    }
  });
  return dataCSV;
};
