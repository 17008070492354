import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import { icons } from '~/assets';

import Select from '~/components/select';
import Dialog from '~/components/dialog';
import TableList from '~/components/tableList';
import ReportSearchPayload from '~/services/credit-table/report/search.ts/payload';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import ReportSearchResponse, {
  ReportSearchItemResponse,
} from '~/services/credit-table/report/search.ts/response';
import { cpfMask } from '~/utils/masks';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import { IListColCampaing } from '~/views/LimitIncrease/model';
import Container from '~/components/container';

import * as St from './styles';

interface LocationState {
  proposalIni?: string;
  proposalEnd?: string;
  status?: string;
}

const ConsultRepostCT = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [list, setList] = useState([]);

  const [status, setStatus] = useState(location.state?.status || 'TODOS');
  const [proposalIni, setProposalIni] = useState(
    location.state?.proposalIni || String(moment().format('DD/MM/YYYY')),
  );
  const [proposalEnd, setProposalEnd] = useState(
    location.state?.proposalEnd || String(moment().format('DD/MM/YYYY')),
  );
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const apiSearch = useApi<ReportSearchResponse>(
    Endpoints.consult.searchProposalReport,
    ApiMethod.POST,
  );

  useEffect(() => {
    if (location.state) searchUser();
  }, []);

  function validDate() {
    const a = moment(proposalIni, 'DD/MM/YYYY');
    const b = moment(proposalEnd, 'DD/MM/YYYY');
    const diffDays = b.diff(a, 'days');

    if (diffDays <= 7) {
      return true;
    }
    setDialogIsOpen(true);
    return false;
  }

  const customerHeader = [
    {
      id: 1,
      value: 'ID da proposta',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Nome completo',
      maxChar: 35,
    },
    {
      id: 3,
      value: 'CPF',
      maxChar: 25,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Status',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Data e Horário',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const searchUser = async () => {
    if (!isFormValid()) return;
    if (!validDate()) return;

    const ini = proposalIni.split('/');
    const end = proposalEnd.split('/');

    const payload: ReportSearchPayload = {
      propostaDe: `${ini[2]}-${ini[1]}-${ini[0]}`,
      propostaAte: `${end[2]}-${end[1]}-${end[0]}`,
      status,
    };

    const response = await apiSearch.callApi(payload);

    const newList = [];
    // let i = 0;

    if (response.status === 200) {
      const data = response.data as ReportSearchItemResponse[];

      data.forEach((data) => {
        const item: IListColCampaing[] = [
          // {
          //   type: 'id',
          //   value: i++,
          //   maxChar: 5,
          // },
          {
            border: true,
            title: 'ID da proposta',
            value: data.id,
            maxChar: 25,
            textAlign: 'center',
          },
          {
            border: true,
            title: 'Nome completo',
            value: data.nome,
            maxChar: 35,
          },
          {
            border: true,
            title: 'CPF',
            value: data.cpf ? cpfMask(data.cpf).masked : '',
            maxChar: 25,
            textAlign: 'center',
          },
          {
            title: 'Status',
            value: data.status,
            maxChar: 15,
            textAlign: 'center',
          },
          {
            title: 'Data e Horário',
            value: data.dataInclusao
              ? moment(data.dataInclusao).format('DD/MM/YYYY HH:ss')
              : '',
            maxChar: 20,
            textAlign: 'center',
          },
          {
            item: data,
            searchAction: (id) => goToDetail(data.id),
          },
        ];

        newList.push(item);
      });

      setList(newList);
    }
  };

  const isFormValid = () => {
    if (proposalIni === '' || proposalEnd === '') return false;
    if (!proposalIni || !proposalEnd) return false;

    const ini = proposalIni.split('/');
    const end = proposalEnd.split('/');

    if (ini.length < 3 || end.length < 3) {
      return false;
    }

    return true;
  };

  const goToDetail = (id) =>
    history.push('/credit-table/report/detail', {
      idProposta: id,
      proposalIni,
      proposalEnd,
      status,
    });

  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message="Período informado maior que 7 (sete) dias."
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Select
                label="Status"
                value={status}
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'ANALISE', value: 'Em análise' },
                  { id: 'REPROVADA', value: 'Reprovada' },
                  { id: 'APROVADA', value: 'Aprovada' },
                  { id: 'CANCELADA', value: 'Cancelada' },
                  { id: 'RASCUNHO', value: 'Rascunho' },
                ]}
                onChange={(e) => setStatus(e)}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="De"
                value={proposalIni}
                placeholder="DD/MM/YYYY"
                maxLength={10}
                onChange={(e) => setProposalIni(e.target.value.masked)}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Até"
                value={proposalEnd}
                placeholder="DD/MM/YYYY"
                maxLength={10}
                onChange={(e) => setProposalEnd(e.target.value.masked)}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                loading={apiSearch.loading}
                disabled={apiSearch.loading}
                onClick={searchUser}
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex marginLeft={16}>
            <St.SpanLabelFeed>
              * O período de busca deve ser menor ou igual a 7 (sete) dias.
            </St.SpanLabelFeed>
          </ContainerFlex>
        </St.BoxCard>

        {list.length > 0 && (
          <St.BoxCard>
            <ContainerFlex row>
              {(apiSearch.error ||
                apiSearch.status === 404 ||
                apiSearch.status === 500) && (
                <ContainerFlex center marginTop={30}>
                  <TitleCategory>Nenhuma proposta encontrada</TitleCategory>
                </ContainerFlex>
              )}
            </ContainerFlex>

            <ContainerFlex>
              <TableList customerHeader={customerHeader} list={list} />
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default ConsultRepostCT;
