import React from 'react';
import { IconContext } from 'react-icons';

import * as S from './styles';

interface IProps {
  title: string;
  value: string | number;
  icon?: any;
  percent?: string | number;
  colorIcon?:
    | 'react-icons green'
    | 'react-icons blue'
    | 'react-icons orange'
    | 'react-icons red'
    | 'react-icons gray'
    | 'none';
  hover?: boolean;
  hasPercent?: boolean;
  click?: () => void;
}

const BoxIndicators: React.FC<IProps> = ({
  title = '',
  value = '',
  colorIcon = 'none',
  hover = false,
  icon = null,
  percent = '',
  click = null,
  hasPercent = false,
}) => {
  return (
    <>
      {hover ? (
        <S.MonthCardItem onClick={click}>
          <S.MonthCardTitle>
            <h2>{title}</h2>
            <IconContext.Provider
              value={{
                className: colorIcon,
                size: '20px',
              }}
            >
              {icon}
            </IconContext.Provider>
          </S.MonthCardTitle>
          {hasPercent ? <S.StatusPercent>{`${percent}%`}</S.StatusPercent> : ''}
          <span>{value}</span>
        </S.MonthCardItem>
      ) : (
        <S.MonthCardItemNoHover>
          <S.MonthCardTitle>
            <h2>{title}</h2>
            <IconContext.Provider
              value={{ className: colorIcon, size: '24px' }}
            >
              {icon}
            </IconContext.Provider>
          </S.MonthCardTitle>
          <span>{value}</span>
        </S.MonthCardItemNoHover>
      )}
    </>
  );
};

export default BoxIndicators;
