import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Input from '~/components/input';
import Layout from '~/components/layoutSignIn';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { IEmployee } from '~/models/employee';
import endpoints from '~/services/endpoints';
import { hideEmailMask } from '~/utils/masks';
import * as St from './styles';

const Forgot: React.FC = () => {
  const [email, setEmail] = useState('');
  const [testDialog, setTextDialog] = useState('');
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialogFailed, setOpenDialogFailed] = useState(false);
  const [idUser, setIdUser] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const { state } = useLocation<IEmployee>();

  const RequestEmail = useApi<IEmployee>(
    `${endpoints.consult.searchEmailForgot}/${state.cpf}`,
    ApiMethod.GET,
  );

  const RequestSendEmail = useApi<IEmployee>(
    `${endpoints.register.sendEmailForgot}/${email}?idUsuario=${idUser}`,
    ApiMethod.POST,
  );

  useEffect(() => {
    async function getEmail() {
      const response = await RequestEmail.callApi();

      if (response.status === 200 || response.status === 201) {
        setEmail(response.data?.email);
        setIdUser(response.data?.id);
      } else {
        setOpenDialogFailed(true);
        setTextDialog(response.data?.mensagem);
      }
    }
    getEmail();
  }, []);

  const confirmSuccess = () => {
    history.push('/');
    setOpenDialogSuccess(false);
  };

  const confirmFailed = () => {
    setOpenDialogFailed(false);
  };

  async function handleSubmit(evt) {
    evt.preventDefault();
    setLoading(true);

    try {
      const response = await RequestSendEmail.callApi();

      if (response.status === 204) {
        setOpenDialogSuccess(true);
      } else {
        setOpenDialogFailed(true);
        setTextDialog(response.data?.mensagem);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        icon={icons.IconTick}
        message="E-mail enviado com sucesso!"
        open={openDialogSuccess}
        positiveLabel="Confirmar"
        positiveAction={confirmSuccess}
      />
      <Dialog
        icon={icons.warning}
        message={testDialog}
        open={openDialogFailed}
        positiveLabel="Entendi"
        positiveAction={confirmFailed}
      />
      <Layout>
        <ContainerFlex center>
          <St.Title>Esqueci minha senha</St.Title>
          <St.Text>
            Orientações serão enviadas para o e-mail de cadastro abaixo:
          </St.Text>
          <St.Form>
            <Input
              name="email"
              id="email"
              type="text"
              label="E-mail"
              value={hideEmailMask(email)}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </St.Form>
          <St.ViewButton>
            <Button
              size="large"
              type="submit"
              fullWidth
              onClick={handleSubmit}
              loading={isLoading}
            >
              Continuar
            </Button>
          </St.ViewButton>
        </ContainerFlex>
      </Layout>
    </>
  );
};

export default Forgot;
