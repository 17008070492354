/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
import React, { ChangeEvent, useEffect, useState } from 'react';
import ButtonSearch from '~/components/buttonSearch';
import { IListCol } from '~/components/listView/row/col';
import ContainerFlex from '~/components/containerFlex';
import { components } from '~/assets';
import * as St from './styles';

interface IProps {
  readonly customerHeader: any;
  list: Array<Array<any>>;
  readonly checkbox?: boolean;
  readonly orderBy?: string;
  onSubmitOrder?: Function;
  onSubmitSelectedRows?: Function;
  onChangeSelectedRows?: Function;
  ordenationField?: string;
  defaultAllChecked?: boolean;
  handleButtonClick?: Function;
}

interface ICheckbox {
  id?: number;
  isChecked?: boolean;
  data?: any;
}

const TableList: React.FC<IProps> = ({
  customerHeader,
  list,
  checkbox = false,
  onSubmitOrder,
  orderBy,
  onChangeSelectedRows,
  defaultAllChecked,
  ordenationField,
  handleButtonClick,
}) => {
  const [selected, setSelected] = useState<ICheckbox[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);

  useEffect(() => {
    if (checkbox) {
      const newList = [];
      list.map((item: IListCol[]) => {
        newList.push({
          id: item[0].value,
          isChecked: defaultAllChecked ?? false,
          data: item,
        });
      });

      if (defaultAllChecked) {
        setSelectedAll(true);
      }

      setSelected(newList);
    }
  }, [list]);

  const isSelected = (id: number) => {
    const found = selected.find((item) => item.id === id);
    if (found?.isChecked) {
      return true;
    }
  };

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const newChecked = [...selected];
    setSelected(() => {
      newChecked.find((e) => e.id === id).isChecked = e.target.checked;
      return newChecked;
    });
  };
  const handleCheckAll = () => {
    setSelectedAll(!selectedAll);
    setSelected((prev) =>
      prev.map((item) => ({ ...item, isChecked: !selectedAll })),
    );
  };

  const handleSubmitOrder = (column: String) => {
    if (orderBy === 'ASC') {
      onSubmitOrder({
        order: 'DESC',
        column,
      });
    } else {
      onSubmitOrder({
        order: 'ASC',
        column,
      });
    }
  };

  useEffect(() => {
    if (checkbox && onChangeSelectedRows) {
      const filteredRows = selected
        .filter((e) => e.isChecked)
        .map((item) => {
          const idBeneficio = item.data[1].value;
          const nome = item.data[2].value;
          const descricao = item.data[3].value;
          const valor = item.data[4].value;
          const { contractedOdonto } = item.data[4];
          return { idBeneficio, nome, descricao, valor, contractedOdonto };
        });
      onChangeSelectedRows(filteredRows);
    }
  }, [selected, checkbox, onChangeSelectedRows]);

  useEffect(() => {
    if (customerHeader.includes((e) => e.type === 'id')) return;
    customerHeader?.unshift({ id: 0, value: '', hidden: true });
    setHeader(customerHeader);
  }, [customerHeader]);

  useEffect(() => {
    let id = 0;
    list?.forEach((subArray) => {
      id++;
      if (subArray?.some((e) => e?.type === 'id')) return;
      return subArray?.unshift({ type: 'id', value: id });
    });
    setData(list);
  }, [list]);

  return (
    <ContainerFlex>
      <St.Table>
        <St.headerTable>
          <tr>
            {checkbox ? (
              <St.hederTitle maxChar={5} marginLeft={5}>
                <St.CheckBox
                  type="checkbox"
                  name="select-all"
                  id="select-all"
                  checked={selectedAll}
                  onChange={() => {
                    handleCheckAll();
                  }}
                />
              </St.hederTitle>
            ) : null}
            {header.map((item) => {
              if (item.hidden) return;
              return (
                <St.hederTitle
                  key={item.id}
                  maxChar={item.maxChar}
                  textAlign={item.textAlign}
                  onClick={() => item.column && handleSubmitOrder(item.column)}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.value}
                    {ordenationField && (
                      <div style={{ marginLeft: '10px' }}>
                        {item.column === ordenationField &&
                          orderBy === 'ASC' && (
                            <components.ArrowDown
                              style={{ transform: 'rotate(180deg)' }}
                            />
                          )}
                        {item.column === ordenationField &&
                          orderBy === 'DESC' && <components.ArrowDown />}
                      </div>
                    )}
                  </div>
                </St.hederTitle>
              );
            })}
          </tr>
        </St.headerTable>
        <St.bodyTable>
          {data?.map((item) => {
            return (
              <St.bodyRow key={item[0].value}>
                {item.map((item) => {
                  if (item.type === 'id') return;
                  return (
                    <>
                      {item.type === 'icon' ? (
                        <St.bodyData
                          key={item.id}
                          maxChar={item.maxChar}
                          marginLeft={item.marginLeft}
                          textAlign={item.textAlign}
                        >
                          {item.value ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <St.bodyDataValue maxChar={item.maxChar}>
                                  {item.value}
                                </St.bodyDataValue>
                                {item.valueColor && (
                                  <St.ListRowBullet color={item.valueColor} />
                                )}
                                <St.IconRow src={item.icon} alt="icon" />
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                {item.valueColor && (
                                  <St.ListRowBullet color={item.valueColor} />
                                )}
                                <St.IconRow src={item.icon} alt="icon" />
                              </div>
                            </>
                          )}
                        </St.bodyData>
                      ) : item.searchAction ? (
                        <St.bodyData
                          key={item.id}
                          maxChar={item.maxChar}
                          marginLeft={item.marginLeft}
                          textAlign={item.textAlign}
                        >
                          <St.ButtonContainer>
                            {item?.icon ? (
                              item.icon
                            ) : (
                              <ButtonSearch
                                onClick={() => {
                                  item.searchAction(item.id, item.item);
                                  handleButtonClick && handleButtonClick(item);
                                }}
                              />
                            )}
                          </St.ButtonContainer>
                        </St.bodyData>
                      ) : item?.checkbox ? (
                        <St.bodyData
                          key={item.id}
                          maxChar={item.maxChar}
                          marginLeft={item.marginLeft}
                          textAlign={item.textAlign}
                        >
                          <St.CheckBox
                            type="checkbox"
                            checked={isSelected(item?.id)}
                            onChange={(e) => {
                              handleCheckbox(e, item?.id);
                            }}
                            style={{ marginLeft: 2 }}
                          />
                        </St.bodyData>
                      ) : item?.type === 'color' ? (
                        <St.bodyData maxChar={0}>
                          {item.icon ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: 'white',
                                height: 50,
                                width: 20,

                                borderRadius: 5,
                              }}
                            >
                              {item.icon}
                            </div>
                          ) : (
                            <div
                              style={{
                                left: -10,
                                background: item?.color,
                                height: 50,
                                width: 20,

                                borderRadius: 5,
                              }}
                            />
                          )}
                        </St.bodyData>
                      ) : (
                        <St.bodyData
                          key={item.id}
                          maxChar={item.maxChar}
                          marginLeft={item.marginLeft}
                          textAlign={item.textAlign}
                          title={item.value}
                        >
                          {item.value}
                        </St.bodyData>
                      )}
                      {item.render && (
                        <St.bodyDataValue>{item.render()}</St.bodyDataValue>
                      )}
                    </>
                  );
                })}
              </St.bodyRow>
            );
          })}
        </St.bodyTable>
      </St.Table>
    </ContainerFlex>
  );
};

export default TableList;
