export enum TiposRelacao {
  CONJUGE = 'Cônjuge',
  PAI_MAE = 'Pai ou mãe',
  IRMAO = 'Irmão(a)',
  FILHO = 'Filho(a)',
  TIO = 'Tio(a)',
  PRIMO = 'Primo(a)',
  SOGRO = 'Sogro(a)',
  GENRO_NORA = 'Genro ou nora',
  NETO = 'Neto(a)',
  AVO = 'Avô(ó)',
  SOBRINHO = 'Sobrinho(a)',
  AMIGO = 'Amigo(a)',
  VIZINHO = 'Vizinho(a)',
}

interface PropostaContatoReferencia {
  id: string;
  comentario: string;
  data: string;
  nome: string;
  relacao: TiposRelacao;
  telefone: string;
}

export const RELATION_TYPES = [
  {
    id: TiposRelacao.CONJUGE,
    value: TiposRelacao.CONJUGE,
  },
  {
    id: TiposRelacao.PAI_MAE,
    value: TiposRelacao.PAI_MAE,
  },
  {
    id: TiposRelacao.IRMAO,
    value: TiposRelacao.IRMAO,
  },
  {
    id: TiposRelacao.FILHO,
    value: TiposRelacao.FILHO,
  },
  {
    id: TiposRelacao.TIO,
    value: TiposRelacao.TIO,
  },
  {
    id: TiposRelacao.PRIMO,
    value: TiposRelacao.PRIMO,
  },
  {
    id: TiposRelacao.SOGRO,
    value: TiposRelacao.SOGRO,
  },
  {
    id: TiposRelacao.GENRO_NORA,
    value: TiposRelacao.GENRO_NORA,
  },
  {
    id: TiposRelacao.NETO,
    value: TiposRelacao.NETO,
  },
  {
    id: TiposRelacao.AVO,
    value: TiposRelacao.AVO,
  },
  {
    id: TiposRelacao.SOBRINHO,
    value: TiposRelacao.SOBRINHO,
  },
  {
    id: TiposRelacao.AMIGO,
    value: TiposRelacao.AMIGO,
  },
  {
    id: TiposRelacao.VIZINHO,
    value: TiposRelacao.VIZINHO,
  },
];

export default PropostaContatoReferencia;
