/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { Container, Inner, CarouselItem, Buttons, Dots } from './styles';

export const Item = ({ children }) => {
  return <CarouselItem>{children}</CarouselItem>;
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    const childCount = React.Children.count(children);
    if (newIndex > childCount && newIndex > activeIndex) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = childCount - 1;
    } else if (newIndex >= childCount) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  return (
    <Container>
      <Inner style={{ transform: `translateX(-${activeIndex * 97}%)` }}>
        <>
          {React.Children.map(children, (child, index) => {
            return (
              <>
                <button
                  style={{ paddingLeft: 12 }}
                  onClick={() => {
                    updateIndex(activeIndex - 1);
                  }}
                >
                  <FiChevronLeft size={24} />
                </button>
                <Item children={child} />
                <button
                  // style={{ paddingLeft: 12 }}
                  onClick={() => {
                    updateIndex(activeIndex + 1);
                  }}
                >
                  <FiChevronRight size={24} />
                </button>
              </>
            );
          })}
        </>
      </Inner>
      <Buttons>
        {React.Children.map(children, (child, index) => {
          return (
            <>
              <Dots
                onClick={() => {
                  updateIndex(index);
                }}
                active={index === activeIndex}
              />
            </>
          );
        })}
      </Buttons>
    </Container>
  );
};

export default Carousel;
