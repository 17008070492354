import React, { createContext, useCallback, useState } from 'react';

export interface Root {
  leadDetail?: LeadDetail;
  eventsRouterName?: EventsRouterName;
  dependents?: Dependent[];
  benefits?: Benefit[];
  totalValue?: number;
  produto?: IProduct;
  key?: any;
  photo?: string;
}

export interface Dependent {
  cpf: string;
  dataNascimento: string;
  nome: string;
  telefone: string;
}

export interface IEvent {
  id: string;
  value: string;
}

export interface IProduct {
  id: string;
  value: string;
}

export interface LeadDetail {
  celular: string;
  celular2: string;
  cliente: string;
  cpf: string;
  email: string;
  numeroCartaoAtual: string;
  statusCartao: string;
  categoriaCartao: string;
  dataCriacao: string;
  dataLimiteEvento: string;
  nome: string;
  operador: Operador;
  origem: string;
  posicaoMesa: string;
  produto: IProduct;
  sinalizador: string;
  status: string;
  tipoEvento: IEvent;
  id: string;
  nivelAtual: string;
  dataCadastro: string;
  beneficios: Benefit[];
  dataInclusao: string;
  responsavel: Operador;
  solicitadorVenda: Operador;
}

export interface EventsRouterName {
  name: string;
}

export interface Operador {
  idOperador: string;
  nome: string;
}

export interface Benefit {
  idBeneficio: string;
  valor: number;
  isencao: boolean;
  descricao: string;
  nome: string;
  dependentes: Dependent[];
}

interface ILeadContext {
  leads: Root;
  addLeadData: (key: string, value: any) => void;
  addEventName: (key: string, value: any) => void;
  errors: { benefit: string; product: string };
  setErrors: React.Dispatch<React.SetStateAction<{}>>;
  setIsencao: React.Dispatch<React.SetStateAction<{}>>;
  setLeads: React.Dispatch<React.SetStateAction<Root>>;
  addDependent: React.Dispatch<React.SetStateAction<{}>>;
  removeDependent: React.Dispatch<React.SetStateAction<{}>>;
  handleBenefitIsencao: (id: string, checked: boolean) => void;
  isencao: {};
  clearLeadContext: () => void;
}

const LeadContext = createContext<ILeadContext>({} as ILeadContext);

const LeadProvider: React.FC = ({ children }) => {
  const [leads, setLeads] = useState<Root>({
    dependents: [],
    benefits: [],
    produto: { id: '', value: '' },
    totalValue: 0,
  } as Root);
  const [errors, setErrors] = useState({
    benefit: '',
    product: '',
  });
  const [isencao, setIsencao] = useState({});

  const clearLeadContext = () => {
    setLeads({
      dependents: [],
      benefits: [],
      produto: { id: 'BENEFICIO', value: 'Benefício' },
      totalValue: 0,
    });
    setIsencao({});
    setErrors({ benefit: '', product: '' });
  };

  const handleBenefitIsencao = useCallback(
    (id, checked) => {
      const found = leads?.benefits?.find((e) => e.idBeneficio === id);
      if (found) {
        found.isencao = checked;
      }
      setLeads((prev) => ({
        ...prev,
        benefits: [...prev.benefits, { ...found }],
      }));
    },
    [leads, isencao],
  );

  const addLeadData = useCallback((key, value) => {
    setLeads((prev) => ({ ...prev, [key]: value }));
  }, []);

  const addEventName = useCallback((key, value) => {
    setLeads((prev) => ({ ...prev, [key]: value }));
  }, []);

  const removeDependent = useCallback(
    (cpf) => {
      setLeads((prev) => ({
        ...prev,
        dependents: prev.dependents.filter((e) => e.cpf !== cpf),
      }));
    },
    [leads],
  );
  const addDependent = useCallback(
    (value) => {
      setLeads((prev) => ({
        ...prev,
        dependents: [...prev.dependents, value],
      }));
    },
    [leads],
  );

  return (
    <LeadContext.Provider
      value={{
        leads,
        addLeadData,
        addEventName,
        errors,
        setErrors,
        setIsencao,
        setLeads,
        addDependent,
        removeDependent,
        handleBenefitIsencao,
        isencao,
        clearLeadContext,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};

export { LeadContext, LeadProvider };
