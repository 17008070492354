import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Layout from '~/components/layout';
import ListRow from '~/components/listView/row';

import { getAllSessions } from '~/services/chat';

import * as St from './styles';
import { IListCol } from '~/components/listView/row/col';

const USER_INFO_LABELS = [
  {
    label: 'Status',
    reference: 'status',
  },
  {
    label: 'Nome completo',
    reference: 'nomeCliente',
  },
  {
    label: 'CPF',
    reference: 'cpf',
  },
  {
    label: 'Número do cartão',
    reference: 'numeroCartao',
  },
];

const LABEL_SEARCH = 'numeroCartao';

const Chat: React.FC = () => {
  const [sessions, setSessions] = useState<IListCol[][]>([]);

  const history = useHistory();

  useEffect(() => {
    handleFetchData();
  }, []);

  const goToDetail = (id: string) => history.push('/chat/room', { id });

  const handleDataFiltering = (data) => {
    const result = USER_INFO_LABELS.find((el) => el.reference === data);
    if (result) return result.label;
    return null;
  };

  const handleFetchData = async () => {
    try {
      const { data } = await getAllSessions();
      const newSessions = data.map((session) => {
        const item = Object.keys(session).map((el) => {
          const result = handleDataFiltering(el);
          if (result) {
            return {
              id: session.tokenIdCliente,
              title: result,
              value: session[el],
              border: true,
              searchAction: LABEL_SEARCH === el ? goToDetail : null,
            };
          }
          return null;
        });

        const idxNumeroCartao = item.find(
          (a) => a !== null && a.searchAction !== null,
        );

        const idxStatus = item.find(
          (a) =>
            a !== null &&
            a.title.toLocaleLowerCase() === 'Status'.toLocaleLowerCase(),
        );
        const itemChanged = item.filter(
          (a) => a !== null && a.searchAction == null,
        );

        if (idxNumeroCartao) {
          itemChanged.push(idxNumeroCartao);
          itemChanged.unshift(idxStatus);
        }
        return itemChanged;
      });
      setSessions(newSessions);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Layout>
      <St.Container>
        <St.Title>Chat</St.Title>
        <St.CardContainer>
          {sessions.map((user) => (
            <ListRow item={user} />
          ))}
        </St.CardContainer>
      </St.Container>
    </Layout>
  );
};

export default Chat;
