import styled from 'styled-components';

import Themes from '~/themes/themes';

interface ContainerProps {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 80%;
  margin: auto;
`;

export const ViewButton = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 15%;
`;

export const Title = styled.h3`
  font-size: 20px;
  color: #4a4b4a;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15%;

  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

export const Text = styled.span`
  font-size: 12px;
  color: #4a4b4a;
  font-weight: 400;
  margin-bottom: 7%;
  align-self: flex-start;
  margin-left: 10%;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const CheckForm = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: start;
  margin-left: 2%;
  margin-right: 2%;

  div {
    display: flex;

    background: ${(props) =>
      props.active ? Themes.colors.primary : Themes.colors.gray};
    height: 18px;
    width: 18px;
    border-radius: 4px;
    align-self: center;

    svg {
      width: 12px;
      height: auto;
      color: #fff;
      margin: auto;
    }
  }
  p {
    margin-left: 8px;
    color: #4a4b4a;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;
