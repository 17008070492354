import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import {
  cpfMask,
  creditCardMask,
  currencyMask,
  phoneMask,
} from '~/utils/masks';

export const HeaderManuallyInvoices = [
  {
    id: 1,
    value: 'Nome Cliente',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Celular',
    maxChar: 20,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Data Aprovação',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Valor',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Parcela',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Tipo de Transação',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodyManuallyInvoices = (data: any) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.nome,
        maxChar: 25,
      },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.celular ? phoneMask(i.celular).masked : '',
        maxChar: 20,
        textAlign: 'center',
      },
      {
        value: i.dataAprovacao
          ? moment(i.dataAprovacao).format('DD/MM/YYYY')
          : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.valor ? `R$ ${currencyMask(i.valor)}` : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.parcela,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.tipoTransacao,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};

export const BodyReportManuallyCSV = (data: any) => {
  const dataCSV = [];

  data?.data?.forEach((csv) => {
    dataCSV.push({
      nome: csv.nome,
      cpf: csv.cpf,
      celular: csv.celular,
      email: csv.email,
      dataAprovacao: csv.dataAprovacao,
      valor: csv.valor,
      parcela: csv.parcela,
      solicitante: csv.solicitante,
      aprovador: csv.aprovador,
      parecer: csv.parecer,
      tipoTransacao: csv.tipoTransacao,
    });
  });

  return dataCSV;
};
