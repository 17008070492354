/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FaRegFileAlt } from 'react-icons/fa';
import ContainerFlex from '../containerFlex';
import Bullet from '../bullet';

import * as St from './styles';

export interface ICheckbox {
  bulletColor?: string;
  message?: string;
  value?: boolean;
  readOnly?: boolean;
  onChangeStatus?: Function;
  invoiceIcon?: boolean;
  readonly?: boolean;
  auto?: boolean;
  terms?: React.ReactChild;
}

const Checkbox: React.FC<ICheckbox> = ({
  bulletColor = null,
  value = false,
  invoiceIcon = false,
  message,
  onChangeStatus,
  readonly,
  auto = true,
  terms,
}) => {
  const [status, setStatus] = useState(value);

  useEffect(() => {
    setStatus(value);
  }, [value]);

  const onChange = () => {
    const newStatus = !status;

    if (onChangeStatus) onChangeStatus(newStatus);

    if (!readonly) setStatus(newStatus);
  };

  return (
    <St.ContainerCheckBox>
      <St.CheckboxContainer onClick={onChange}>
        <St.HiddenCheckbox checked={status} />
        <St.StyledCheckbox checked={auto ? status : value}>
          <St.Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </St.Icon>
        </St.StyledCheckbox>
      </St.CheckboxContainer>

      <St.StCheckboxMessage>
        {invoiceIcon ? (
          <St.InvoiceIcon>
            <FaRegFileAlt />
          </St.InvoiceIcon>
        ) : (
          ''
        )}
        {message || ''}
      </St.StCheckboxMessage>

      <St.StCheckboxTermsMessage>{terms || ''}</St.StCheckboxTermsMessage>

      {bulletColor && (
        <Bullet
          style={{ marginLeft: 'auto' }}
          color={bulletColor}
          margin={8}
          marginTop={14}
          marginBottom={22}
        />
      )}
    </St.ContainerCheckBox>
  );
};

export default Checkbox;
