import styled from 'styled-components';
import Themes from '~/themes/themes';

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1.5em;
  font-weight: 600;
  padding-left: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const InvoiceCheckBoxTitle = styled.span`
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Themes.colors.primary};
`;

export const InvoiceCheckBox = styled.span`
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;

  color: #6d7278;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
`;

export const ContainerTitleInvoiceButton = styled.div`
  margin-top: 20px;
`;

export const WrapperPopover = styled.div`
  position: absolute;
  z-index: 10000000;
  right: 17px;
  top: 50px;
`;
export const TitleInvoiceButton = styled.span`
  margin-right: 20px;
  height: 22px;
  width: 47px;
  color: ${Themes.colors.primary};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline ${Themes.colors.primary};
  cursor: pointer;
`;

export const Hr = styled.hr`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.01);
`;

export const EmailContainer = styled.span`
  margin-top: 40px;
`;

export const InputRadioLabel = styled.label`
  margin-left: 15px;
`;

export const InputRadioButton = styled.span`
  position: relative;
  font-size: 24px;
  right: 5px;
  top: 5px;
  color: ${Themes.colors.primary};
  cursor: pointer;
`;

export const InputEmailContainer = styled.div`
  margin-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const ErrorMessage = styled.p`
  color: ${Themes.colors.error};
`;

export const TableHistory = styled.table`
  width: 100%;
  border-spacing: 0;

  td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: grey;
    padding: 10px;
  }

  th {
    padding: 10px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: grey;
  }
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;
