/* eslint-disable react/jsx-curly-newline */
/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ContainerFlex from '~/components/containerFlex';
import Loading from '~/components/loading';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import Pagination from '~/components/pagination';
import Select from '~/components/select';

import TableList from '~/components/tableList';

import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { IListColCampaing } from './model';
import Container from '~/components/container';

import * as St from './style';
import Button from '~/components/button';

const campaignTypes = [
  { value: 'Aumento de limite', id: 'AUMENTO_LIMITE' },
  { value: 'Todos', id: 'TODOS' },
];
export interface ICustomer {
  limitePorPagina: number;
  pagina: {
    id: string | number;
    dataCriacao: string;
    dataValidade: string;
    nome: string;
    status: string;
    tipoCampanha: string;
    totalAtingidos: number;
  }[];
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}

interface Form {
  totalAumento?: string;
  bronzeValue?: string | number;
  silverValue?: string | number;
  goldValue?: string | number;
  tipo?: string;
  status?: string;
}

const LimitIncrease: React.FC = () => {
  const [list, setList] = useState([]);
  const [form, setForm] = useState<Form>({
    tipo: 'TODOS',
    status: 'TODOS',
  });
  const [totalPages, setTotalPages] = useState(0);
  const history = useHistory();

  const postObterCampaing = useApi<ICustomer>(
    Endpoints.fidelizacao.postObterCampanha,
    ApiMethod.POST,
  );

  const customerHeader = [
    {
      id: 1,
      value: 'Nome Campanha',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Tipo',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Data campanha',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Data validade',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Total atingido',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Status',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 7,
      value: 'Detalhes',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const searchCampaign = async (page = 1, limit = 10) => {
    const response = await postObterCampaing.callApi({
      limitePorPagina: limit,
      pagina: page,
      status: form.status,
      tipoCampanha: form.tipo,
    });

    const { data } = response;

    const newList = [];
    let i = 0;

    if (response.status >= 200 && response.status < 300) {
      setTotalPages(data.totalDePaginas);
      data.pagina?.forEach((data) => {
        const item: IListColCampaing[] = [
          {
            type: 'id',
            value: data.id || i++,
            maxChar: 5,
          },
          { value: data.nome, maxChar: 15, textAlign: 'center' },
          {
            value: data.tipoCampanha.replace('_', ' '),
            maxChar: 15,
            textAlign: 'center',
          },
          {
            value: data.dataCriacao
              ? moment(data.dataCriacao).format('DD/MM/YY')
              : '-',
            maxChar: 25,
            textAlign: 'center',
          },
          {
            value: data.dataValidade
              ? moment(data.dataValidade).format('DD/MM/YY')
              : '-',
            maxChar: 15,
            textAlign: 'center',
          },
          {
            value: `${data.totalAtingidos} cliente(s)`,
            maxChar: 10,
            textAlign: 'center',
          },
          {
            value: data.status,
            maxChar: 15,
          },
          {
            maxChar: 10,
            item: data,
            textAlign: 'center',
            searchAction: goToDetailCampaign,
          },
        ];

        newList.push(item);
      });
    }

    setList(newList);
  };

  const goToDetailCampaign = (a: any, item: any) => {
    history.push('/lista-cliente-campanha', {
      idCampaing: item.id,
      nameCampaing: item.nome,
      statusCampaing: item.status,
    });
  };

  const checkSuccess = () => {
    if (
      postObterCampaing.error ||
      postObterCampaing.status === 404 ||
      postObterCampaing.status === 500 ||
      list.length === 0
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Loading status={postObterCampaing.loading} />

      <Container>
        <St.BoxCard>
          <ContainerFlex padding={16} row space gap="32">
            <ContainerFlex gap="8" row>
              <ContainerFlex>
                <Select
                  value={form.tipo}
                  onChange={(e) => setForm((prev) => ({ ...prev, tipo: e }))}
                  label="Tipo de campanha"
                  options={campaignTypes}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Select
                  value={form.status}
                  onChange={(e) => setForm((prev) => ({ ...prev, status: e }))}
                  label="Status"
                  options={[
                    { value: 'Todos', id: 'TODOS' },
                    { value: 'Preview', id: 'PREVIEW' },
                    { value: 'Cancelada', id: 'CANCELADA' },
                    { value: 'Finalizada', id: 'FINALIZADA' },
                  ]}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex gap="8" row end>
              <Button fitContent onClick={() => searchCampaign()}>
                Consultar
              </Button>
              <Button
                disabled={!form.tipo}
                onClick={() =>
                  history.push('/criar-campanha', {
                    tipo: campaignTypes.find((e) => e.id === form.tipo)?.value,
                  })
                }
                fitContent
              >
                Nova campanha
              </Button>
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex row padding={16} wrap>
            <ContainerFlex>
              <ContainerFlex>
                <ContainerFlex padding={10}>
                  {list.length > 0 && (
                    <>
                      <TableList customerHeader={customerHeader} list={list} />

                      {totalPages > 1 && (
                        <ContainerFlex row horizontal center marginTop={20}>
                          <Pagination
                            totalPages={totalPages}
                            onCurrentPageChange={searchCampaign}
                          />
                        </ContainerFlex>
                      )}
                    </>
                  )}
                  {checkSuccess() && (
                    <ContainerFlex center marginTop={30}>
                      <TitleCategory>Nenhum resultado encontrado</TitleCategory>
                    </ContainerFlex>
                  )}
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default LimitIncrease;
