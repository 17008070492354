/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ContainerFlex from '~/components/containerFlex';
import Collapse from '~/components/collapse';
import Loading from '~/components/loading';
import { icons } from '~/assets';
import Dialog from '~/components/dialog';

import PointsStatus from '../components/PointsStatus';

import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { getParameters, getSettings } from '../model';
import Container from '~/components/container';

import * as St from '../style';
import Button from '~/components/button';
import RangePicker from '../components/RangePicker';
import Grid from '~/components/grid';

export interface ICustomer {
  limitePorPagina: number;
  pagina: {
    id: string | number;
    dataCriacao: string;
    dataValidade: string;
    nome: string;
    status: string;
    tipoCampanha: string;
    totalAtingidos: number;
  }[];
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}

export interface CreateCamapignForm {
  padrao: getSettings[];
  platinum: getSettings[];
}

const medalsIcons = {
  BRONZE: icons.bronzeMedal,
  PRATA: icons.silverMedal,
  OURO: icons.goldMedal,
  PLATINUM: icons.platinum0,
  PLATINUM_1: icons.platinum1,
  PLATINUM_2: icons.platinum2,
  PLATINUM_3: icons.platinum3,
};
const LimitIncreaseCreate: React.FC = () => {
  const state = useLocation();

  const [parameters, setParameters] = useState<getParameters[]>(null);
  const [settings, setSettings] = useState<CreateCamapignForm>({
    padrao: [],
    platinum: [],
  });
  const [form, setForm] = useState<CreateCamapignForm>(settings);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [msgDialog, setMsgDialog] = useState('');
  const [config, setConfig] = useState<'padrao' | 'platinum'>(null);
  const [infoDialog, setInfoDialog] = useState({
    open: false,
    message: '',
    disabled: false,
  });
  const [isOpendialog, setIsOpenDialog] = useState(false);
  const history = useHistory();

  const getParameters = useApi(
    Endpoints.fidelizacao.getParameters,
    ApiMethod.GET,
  );

  const getSettings = useApi(Endpoints.fidelizacao.getSettings, ApiMethod.GET);

  const postCreateCampaign = useApi(
    Endpoints.fidelizacao.postCreateCampaign,
    ApiMethod.POST,
  );

  const postObterCampaing = useApi<ICustomer>(
    Endpoints.fidelizacao.postObterCampanha,
    ApiMethod.POST,
  );

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = () => {
    (async () => {
      const response = await getParameters.callApi();

      if (response.status === 200) {
        const data = response.data as getParameters[];

        setParameters(data);
      }
    })();

    (async () => {
      const response = await getSettings.callApi();

      if (response.status === 200) {
        const data = response.data as getSettings[];
        setForm(data);
        setSettings(data);
      }
    })();
  };
  const putAtualizarConfiguracao = useApi(
    Endpoints.fidelizacao.putAtualizarConfig,
    ApiMethod.PUT,
  );
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const generateCampaign = async () => {
    const response = await postCreateCampaign.callApi();

    if (response.status >= 200 && response.status < 300) {
      setMsgDialog('Campanha gerada com sucesso!');
      setShouldGoBack(true);
    } else {
      setMsgDialog(response.mensagem || 'Erro ao gerar campanha!');
    }
    setIsOpenDialog(true);
  };
  const handleClickLevel = async () => {
    let payload = {};
    if (config === 'padrao') {
      payload = form.padrao;
    } else {
      payload = form.platinum;
    }
    const response = await putAtualizarConfiguracao.callApi({
      configuracoes: payload,
    });

    if (response.status >= 200 && response.status < 300) {
      setMsgDialog('Configuração atualizada com sucesso!');
    } else {
      setMsgDialog(response.mensagem || 'Erro ao atualizar configuração!');
    }
    setIsOpenDialog(true);
  };

  return (
    <>
      <Loading
        status={
          getParameters.loading ||
          getSettings.loading ||
          postObterCampaing.loading ||
          postCreateCampaign.loading ||
          putAtualizarConfiguracao.loading
        }
      />

      <Dialog
        icon={icons.exclamation}
        message={`Deseja realmente alterar o nível de pontuação ${
          config === 'padrao' ? 'padrão' : 'Platinum'
        }?`}
        open={dialogIsOpen}
        positiveLabel="Sim"
        positiveAction={() => {
          setDialogIsOpen(false);
          handleClickLevel();
        }}
        negativeLabel="Não"
        negativeAction={() => setDialogIsOpen(false)}
      />
      <Dialog
        icon={icons.exclamation}
        open={isOpendialog}
        positiveLabel="Entendi"
        positiveAction={() => {
          setIsOpenDialog(false);
          if (shouldGoBack) {
            history.push('/campanha');
          }
        }}
        message={msgDialog}
      />
      <Dialog
        icon={icons.exclamation}
        open={infoDialog?.open}
        title="Detalhes"
        widthInPercent={40}
        positiveLabel="Entendi"
        positiveAction={() =>
          setInfoDialog({ message: '', open: false, disabled: false })
        }
        message={infoDialog?.message}
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex padding={8} row space gap="32">
            <St.Title>Tipo de campanha: {state.state?.tipo}</St.Title>
          </ContainerFlex>
          <ContainerFlex row padding={8} wrap>
            <ContainerFlex>
              <ContainerFlex marginBottom={10}>
                <Collapse
                  disable={infoDialog.disabled}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <span>Parâmetros: Nível bronze, prata e ouro</span>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setInfoDialog({
                            message:
                              'Até nível ouro, mantem mesmas regras de aumento para todas as categorias de cartão.',
                            open: true,
                            disabled: false,
                          })
                        }
                        onMouseEnter={() =>
                          setInfoDialog((prev) => ({ ...prev, disabled: true }))
                        }
                        onMouseOut={() =>
                          setInfoDialog((prev) => ({
                            ...prev,
                            disabled: false,
                          }))
                        }
                      >
                        <img
                          src={icons.exclamation}
                          alt="ex"
                          width={16}
                          height={16}
                        />
                      </div>
                    </div>
                  }
                  initialState="close"
                >
                  <ContainerFlex marginLeft={26} marginRight={26}>
                    <Grid xs={1} lg={2} gap={30}>
                      {settings.padrao.map((s) => (
                        <RangePicker
                          type="padrao"
                          icon={medalsIcons[s.nivel]}
                          onChange={setForm}
                          settings={s}
                          min={1}
                          max={10}
                        />
                      ))}
                    </Grid>
                    <ContainerFlex row marginTop={38} end>
                      <Button
                        borderRadius={10}
                        marginBottom={12}
                        fitContent
                        onClick={() => {
                          setConfig('padrao');
                          setDialogIsOpen(true);
                        }}
                        //   disabled={!showOptionsRule()}
                      >
                        <span>Salvar</span>
                      </Button>
                    </ContainerFlex>
                  </ContainerFlex>
                </Collapse>
                <Collapse
                  disable={infoDialog.disabled}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <span>Parâmetros: Nível platinum</span>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setInfoDialog({
                            message:
                              'A partir do nível platinum, inicia-se uma nova classificação plus (+) apenas para a categoria de cartão platinum.',
                            open: true,
                            disabled: false,
                          })
                        }
                        onMouseEnter={() =>
                          setInfoDialog((prev) => ({ ...prev, disabled: true }))
                        }
                        onMouseOut={() =>
                          setInfoDialog((prev) => ({
                            ...prev,
                            disabled: false,
                          }))
                        }
                      >
                        <img
                          src={icons.exclamation}
                          alt="ex"
                          width={16}
                          height={16}
                        />
                      </div>
                    </div>
                  }
                  initialState="close"
                >
                  <ContainerFlex marginLeft={26} marginRight={26}>
                    <Grid xs={1} lg={2} gap={30}>
                      {settings.platinum.map((s) => (
                        <RangePicker
                          type="platinum"
                          icon={medalsIcons[s.nivel]}
                          onChange={setForm}
                          settings={s}
                          min={11}
                          max={24}
                        />
                      ))}
                    </Grid>
                    <ContainerFlex row marginTop={38} end>
                      <Button
                        borderRadius={10}
                        marginBottom={12}
                        fitContent
                        onClick={() => {
                          setConfig('platinum');
                          setDialogIsOpen(true);
                        }}
                        //   disabled={!showOptionsRule()}
                      >
                        <span>Salvar</span>
                      </Button>
                    </ContainerFlex>
                  </ContainerFlex>
                </Collapse>
                <PointsStatus
                  parameters={parameters}
                  generateCampaign={generateCampaign}
                />
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default LimitIncreaseCreate;
