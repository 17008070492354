import styled from 'styled-components';

interface IData {
  marginLeft?: number;
  maxChar?: number;
  textAlign?: string;
}

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 1px 1em;
  background: #fafbfe;
  width: 100%;
`;

export const HeaderTable = styled.thead`
  border-radius: 4px;
  line-height: 30px;
  background: #f9f9f9;
`;

export const BodyRow = styled.tr`
  box-shadow: 0px 1px 1px -1px #999;
  background: #fff;
`;

export const HeaderTitle = styled.th<IData>`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 4px;
  color: #495057;
  word-break: break-word;
  width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  line-height: 30px;
  border-bottom: 1px solid #eee;
`;

export const bodyData = styled.td<IData>`
  height: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  color: #4a4b4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : '100%')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0px')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'left')};
  padding-left: 2px;
`;

export const BodyDivData = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  height: 50px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
`;

export const TitleAbbrFooter = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: red;
`;
