/* eslint-disable default-case */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import moment from 'moment';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Loading from '~/components/loading';
import TitleApp from '~/components/title';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import TableList from '~/components/tableList';
import Button from '~/components/button';
import Pagination from '~/components/pagination';

import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { IListColCampaing } from '../model';

import { icons } from '~/assets';
import {
  cpfMask,
  phoneMask,
  creditCardMask,
  currencyMask,
} from '~/utils/masks';
import Constants from '~/utils/constants';
import Container from '~/components/container';

import * as St from '../style';

export interface ICustomer {
  limitePorPagina: number;
  pagina: {
    id: string | number;
    cpf: string;
    limiteAtual: number;
    limiteFuturo: number;
    nivel: string;
    nomeCompleto: string;
    numeroCartao: string;
    quantidadeFaturasPagas: number;
    telefone: string;
    pontuacao: number;
  }[];
  paginaAtual: number;
  totalDePaginas: number;
  totalItens: number;
}
const medalsIcons = {
  BRONZE: icons.bronzeMedal,
  PRATA: icons.silverMedal,
  OURO: icons.goldMedal,
  PLATINUM: icons.platinum0,
  PLATINUM_1: icons.platinum1,
  PLATINUM_2: icons.platinum2,
  PLATINUM_3: icons.platinum3,
};
const ListClientCampaing: React.FC = () => {
  const [list, setList] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [msgDialog, setMsgDialog] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [header, setHeader] = useState([]);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [campaingData, setCampaingData] = useState([]);
  const [actionFunction, setActionFunction] = useState('');
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { state } = useLocation<{
    idCampaing: string;
    nameCampaing: string;
    statusCampaing: string;
  }>();
  const history = useHistory();

  const refCSV = useRef<any>(null);

  const postObterClient = useApi<ICustomer>(
    `${Endpoints.fidelizacao.postObterClientes.replace(
      'PARAM_ID_CAMPANHA',
      state?.idCampaing,
    )}`,
    ApiMethod.POST,
  );

  const putCancelCampaing = useApi(
    `${Endpoints.fidelizacao.putCancelCampaign.replace(
      'PARAM_ID_CAMPANHA',
      state?.idCampaing,
    )}`,
    ApiMethod.PUT,
  );

  const putExecuteCampaing = useApi(
    `${Endpoints.fidelizacao.putExecuteCampaign.replace(
      'PARAM_ID_CAMPANHA',
      state?.idCampaing,
    )}`,
    ApiMethod.PUT,
  );

  useEffect(() => {
    if (state?.idCampaing) {
      searchClientList();
    }
  }, [state]);

  const customerHeader = [
    {
      id: 1,
      value: 'CPF',
      maxChar: 15,
    },
    {
      id: 2,
      value: 'Nome',
      maxChar: 20,
    },
    {
      id: 3,
      value: 'Telefone',
      maxChar: 15,
    },
    {
      id: 4,
      value: 'Número do Cartão',
      maxChar: 20,
    },
    {
      id: 5,
      value: 'Limite Atual',
      maxChar: 18,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Fatura(s) Paga(s)',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 7,
      value: 'Nível Atingido',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 8,
      value: 'Limite Atingido',
      maxChar: 18,
      textAlign: 'center',
    },
  ];

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    totalItems >= 2000
      ? (response = (await searchClientList(currentPage, 350, true)) as any)
      : (response = (await searchClientList(currentPage, 150, true)) as any);

    if (response) {
      const data = response.totalDePaginas ? response : response.data;
      totalPages = data.totalDePaginas;

      const porcent = (currentPage / totalPages) * 100;
      const roundPorcent = Math.ceil(porcent);
      setDownloadCVSProgress(`${roundPorcent}%`);

      if (data.pagina.length) {
        if (currentPage === 1) {
          setCampaingData(data.pagina);
        } else {
          setCampaingData((prev) => [...prev, ...data.pagina]);
        }
      }
      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }

      setDownloadCVSProgress('');
    }
  };

  const searchClientList = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const response = await postObterClient.callApi({
      limitePorPagina: limit,
      pagina: page,
    });

    const formatHeader = [
      { label: 'CPF', key: 'cpf' },
      { label: 'Nome completo', key: 'nomeCompleto' },
      { label: 'Telefone', key: 'telefone' },
      { label: 'E-mail', key: 'email' },
      { label: 'Numero Cartão', key: 'numeroCartao' },
      { label: 'Limite Atual', key: 'limiteAtual' },
      { label: 'Limite Atingido', key: 'limiteFuturo' },
      { label: 'Limite Saque Atual', key: 'limiteSaqueAtual' },
      { label: 'Limite Saque Atingido', key: 'limiteSaqueFuturo' },
      { label: 'Nível', key: 'nivel' },
      { label: 'Categoria', key: 'categoriaCartao' },
      { label: 'Pontuação', key: 'pontuacao' },
      { label: 'Fatura(s) Paga(s)', key: 'quantidadeFaturasPagas' },
      { label: 'Fatura(s) Parcial(s)', key: 'quantidadeFaturasParciais' },
      { label: 'Fatura(s) Parcelada(s)', key: 'quantidadeFaturasParceladas' },
    ];

    setHeader(formatHeader);

    const { data } = response;

    if (returnResponse) return response.data;

    const newList = [];
    let i = 0;

    if (response.status >= 200 && response.status < 300) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      data.pagina?.forEach((data) => {
        const item: IListColCampaing[] = [
          {
            type: 'id',
            value: data.id || i++,
            maxChar: 5,
          },
          {
            value: cpfMask(data.cpf).masked,
            maxChar: 15,
          },
          { value: data.nomeCompleto, maxChar: 20 },
          {
            value: phoneMask(data.telefone).masked,
            maxChar: 15,
          },
          {
            value: creditCardMask(data.numeroCartao).masked,
            maxChar: 20,
          },
          {
            value: `R$ ${currencyMask(data.limiteAtual)}`,
            maxChar: 18,
            textAlign: 'center',
          },
          {
            value: `${data.quantidadeFaturasPagas} Fatura(s)`,
            maxChar: 15,
            textAlign: 'center',
          },
          {
            value: `${data.pontuacao || '0'} pts`,
            maxChar: 15,
            type: 'icon',
            icon: medalsIcons[data.nivel],
            textAlign: 'center',
            marginLeft: 5,
          },
          {
            value: `R$ ${currencyMask(data.limiteFuturo)}`,
            maxChar: 18,
            textAlign: 'center',
          },
        ];

        newList.push(item);
      });
    }

    setList(newList);
  };

  const onClickExecuteCampaing = async () => {
    const response = await putExecuteCampaing.callApi();

    if (response.status >= 200 && response.status < 300) {
      setMsgDialog(`Campanha executada com sucesso para: ${totalItems}`);
      goBack();
    } else {
      setMsgDialog(response.mensagem || 'Erro ao executar campanha');
    }

    setDialogIsOpen(true);
  };

  const onClickCancelCampaing = async () => {
    const response = await putCancelCampaing.callApi();

    if (response.status >= 200 && response.status < 300) {
      setMsgDialog('Campanha cancelada com sucesso!');
      goBack();
    } else {
      setMsgDialog(response.mensagem || 'Erro ao cancelar campanha');
    }

    setDialogIsOpen(true);
  };

  const checkSuccess = () => {
    if (
      postObterClient.error ||
      postObterClient.status === 404 ||
      postObterClient.status === 500 ||
      putCancelCampaing.error ||
      putCancelCampaing.status === 404 ||
      putCancelCampaing.status === 500 ||
      putExecuteCampaing.error ||
      putExecuteCampaing.status === 404 ||
      putExecuteCampaing.status === 500 ||
      list.length === 0
    ) {
      return true;
    }

    return false;
  };

  const goBack = () => history.goBack();

  const showOptionsRule = () => {
    const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
    const result = userRoles.includes('ADM');
    return result && state.statusCampaing === 'PREVIEW';
  };

  return (
    <>
      <Loading
        status={
          postObterClient.loading ||
          putCancelCampaing.loading ||
          putExecuteCampaing.loading
        }
        text={downloadCVSProgress}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={msgDialog}
      />
      <Dialog
        icon={icons.exclamation}
        open={isOpenDialog}
        positiveLabel="Sim"
        negativeLabel="Não"
        positiveAction={() => {
          setIsOpenDialog(false);
          switch (actionFunction) {
            case 'SALVAR':
              onClickExecuteCampaing();
              break;
            case 'CANCELAR':
              onClickCancelCampaing();
              break;
          }
        }}
        negativeAction={() => setIsOpenDialog(false)}
        message={msgDialog}
      />
      <Container>
        <St.BoxCard>
          <St.Title>Detalhes da campanha</St.Title>
          <ContainerFlex>
            <ContainerFlex row wrap>
              <ContainerFlex>
                <ContainerFlex marginBottom={10}>
                  {list.length > 0 && (
                    <>
                      <ContainerFlex row center space-between marginBottom={30}>
                        <ContainerFlex>
                          <span>
                            Clientes atingidos na&nbsp;
                            <strong>{state.nameCampaing}</strong>
                          </span>
                          <span>
                            Resultados retornados&nbsp;
                            <strong>{totalItems}</strong>
                          </span>
                        </ContainerFlex>

                        <ContainerFlex row end>
                          <Button
                            borderRadius={10}
                            fitContent
                            onClick={() => {
                              if (refCSV.current) {
                                onClickDownloadCSV().then(() =>
                                  refCSV.current.link.click(),
                                );
                              }
                            }}
                          >
                            <span>Download</span>
                          </Button>
                          <Button
                            borderRadius={10}
                            fitContent
                            marginLeft={10}
                            onClick={() => {
                              setMsgDialog(
                                'Deseja realmente executar a campanha?',
                              );
                              setActionFunction('SALVAR');
                              setIsOpenDialog(true);
                            }}
                            disabled={!showOptionsRule()}
                          >
                            <span>Salvar</span>
                          </Button>
                        </ContainerFlex>
                      </ContainerFlex>

                      <TableList customerHeader={customerHeader} list={list} />

                      {totalPages > 1 && (
                        <ContainerFlex row horizontal center marginTop={20}>
                          <Pagination
                            totalPages={totalPages}
                            onCurrentPageChange={searchClientList}
                          />
                        </ContainerFlex>
                      )}

                      <ContainerFlex row end>
                        <Button borderRadius={10} fitContent onClick={goBack}>
                          <span>Voltar</span>
                        </Button>
                        <Button
                          borderRadius={10}
                          fitContent
                          marginLeft={10}
                          onClick={() => {
                            setMsgDialog(
                              'Deseja realmente cancelar a campanha?',
                            );
                            setActionFunction('CANCELAR');
                            setIsOpenDialog(true);
                          }}
                          disabled={!showOptionsRule()}
                        >
                          <span>Cancelar</span>
                        </Button>
                      </ContainerFlex>
                    </>
                  )}
                  {checkSuccess() && (
                    <ContainerFlex center marginTop={30}>
                      <TitleCategory>Nenhum resultado encontrado</TitleCategory>
                    </ContainerFlex>
                  )}
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>

          <CSVLink
            ref={refCSV}
            data={campaingData}
            style={{
              color: 'inherit',
              textDecoration: 'none',
            }}
            headers={header}
            filename={`relatorio-${state?.nameCampaing}-${moment(
              new Date(),
            ).format('DD-MM-YY')}.csv`}
            target="_blank"
          />
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ListClientCampaing;
