import styled from 'styled-components';

export const Container = styled.div`
  width: 160px;
  height: 290px;
  margin-top: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  border: 3px dashed #009578;
  border-radius: 10px;
`;

export const DropSpan = styled.span`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Icon = styled.img`
  width: 21px;
  height: 25px;
  font-weight: bold;
  padding: 5px;
`;

export const PreLoadImage = styled.img`
  width: 155px;
  height: 290px;
  border-radius: 10px;
`;

export const DropInput = styled.input`
  width: 100%;
  height: 100%;
`;
