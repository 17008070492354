/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import TableList from '~/components/tableList';

import useApi, { ApiMethod } from '~/hooks/useApi';

import Endpoints from '~/services/endpoints';

import * as St from './styles';
import { IDefaultReturn, cpfMask } from '~/utils/masks';

interface Form {
  cpf: IDefaultReturn;
  nome: string;
}

export type AnfitriaoResponse = {
  codigo: string;
  cpf: string;
  dataCadastro: string;
  id: string;
  nome: string;
  status: string;
};

const IndicationLinkList: React.FC = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [researched, setResearched] = useState(false);

  const [form, setForm] = useState<Form>({
    cpf: cpfMask(''),
    nome: '',
  });

  const api = useApi<AnfitriaoResponse>(
    Endpoints.consult.getAnfitriao,
    ApiMethod.GET,
    {},
    true,
  );

  const handleConsult = async () => {
    await api.callApi({ cpf: form.cpf.unmasked, nome: form.nome });
  };
  const customerHeader = [
    {
      id: 1,
      value: 'Nome completo',
      maxChar: 28,
    },
    {
      id: 2,
      value: 'CPF',
      maxChar: 14,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Data do cadastro',
      maxChar: 28,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Código Anfitrião',
      maxChar: 28,
    },
    {
      id: 5,
      value: 'Status',
      maxChar: 28,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  useEffect(() => {
    loadData();
  }, [api.loading]);

  const goToDetail = (a: any, item: AnfitriaoResponse) => {
    history.push('/sales/indicationLinkEdit', item);
  };

  const loadData = () => {
    if (api.status !== 200) {
      setList([]);
      return;
    }

    const dataRes = api.data as AnfitriaoResponse[];

    const newList = [];
    // let i = 0;

    if (api.status >= 200 && api.status < 300) {
      setResearched(true);
    }

    dataRes.forEach((data) => {
      const item = [
        // {
        //   type: 'id',
        //   value: i++,
        //   maxChar: 5,
        //   textAlign: 'center',
        // },
        {
          border: true,
          title: 'Nome completo',
          value: data.nome,
          maxChar: 28,
        },
        {
          border: true,
          title: 'CPF',
          value: cpfMask(data?.cpf).masked,
          maxChar: 28,
        },
        {
          border: true,
          title: 'Data do cadastro',
          value: data.dataCadastro
            ?.split('T')[0]
            .split('-')
            .reverse()
            .join('/'),
          maxChar: 28,
          textAlign: 'center',
        },
        {
          title: 'Código Anfitrião',
          maxChar: 28,
          value: data.codigo,
          textAlign: 'center',
        },
        {
          title: 'Status',
          maxChar: 28,
          value: data.status,
          textAlign: 'center',
        },
        {
          item: data,
          searchAction: goToDetail,
        },
      ];

      newList.push(item);
    });

    setList(newList);
  };
  console.log(form);
  const Cf = ContainerFlex;
  return (
    <>
      <Loading status={api.loading} />

      <Container>
        <St.BoxCard>
          <Cf row space>
            <Cf padding={16}>
              <Input
                label="CPF"
                name="cpf"
                value={form.cpf.masked}
                onChange={(e) => {
                  console.log(e.target.value);
                  setForm((prev) => ({
                    ...prev,
                    cpf: e.target.value,
                  }));
                }}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </Cf>

            <Cf padding={16}>
              <Input
                label="Nome"
                name="Nome"
                value={form.nome}
                onChange={(e) => setForm({ ...form, nome: e.target.value })}
              />
            </Cf>
            <Cf gap="20" space end style={{ padding: '0px 20px' }} flex={1.5}>
              <Button fitContent onClick={() => handleConsult()}>
                <span>Consultar</span>
              </Button>

              <p onClick={() => history.push('/sales/indicationLinkCreate')}>
                Ainda não é anfitrião?
                <b
                  style={{ marginLeft: 4, color: '#E71037', cursor: 'pointer' }}
                >
                  Clique aqui
                </b>
              </p>
            </Cf>
          </Cf>
        </St.BoxCard>

        {list.length <= 0 || api.status === 404 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <St.TitleCategory>Cliente não encontrado</St.TitleCategory>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex row>
              {(api.status === 404 || list.length <= 0) && (
                <ContainerFlex center>
                  <St.TitleCategory>
                    {api.status === 404
                      ? api.data.mensagem
                      : 'Nenhum resultado encontrado'}
                  </St.TitleCategory>
                </ContainerFlex>
              )}
            </ContainerFlex>

            <ContainerFlex>
              {list.length > 0 && (
                <TableList customerHeader={customerHeader} list={list} />
              )}

              <ContainerFlex center>
                {(api.status > 300 || (list.length === 0 && researched)) && (
                  <St.TitleCategory>
                    Não há resultados para mostrar aqui
                  </St.TitleCategory>
                )}
              </ContainerFlex>
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default IndicationLinkList;
