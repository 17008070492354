/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import {
  GetOperatorsResponse,
  ReportCancellationPlatinumResponse,
  ReportResponse,
  ReportSalesPlatinumCardResponse,
} from '~/services/report/requestReport/response';
import { CSV_HEADERS } from '../../constants';
import Models from '../../models';
import {
  BENEFIT_TYPES,
  IBenefitReport,
  ReportResponseCancelOdonto,
  ReportResponseOdonto,
  ReportResponseCancelSafe,
  ReportResponseCuida,
  ReportResponseCancelCuida,
} from '../models';

export const BenefitReportContext = createContext<IBenefitReport>(
  {} as IBenefitReport,
);

const BenefitReportProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [relatorioData, setRelatorioData] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [operador, setOperador] = useState([]);

  const RequestGetOperators = useApi(
    Endpoints.report.getOperators,
    ApiMethod.GET,
  );

  const RequestReportSalesOdont = useApi<ReportResponseOdonto>(
    Endpoints.report.getReportOdonto,
    ApiMethod.POST,
  );

  const RequestReportSalesCuida = useApi<ReportResponseCancelCuida>(
    Endpoints.report.getReportCuida,
    ApiMethod.POST,
  );

  const RequestReportCancelCuida = useApi<ReportResponseCancelOdonto>(
    Endpoints.report.getReportCancelCuida,
    ApiMethod.POST,
  );

  const RequestReportCancelOdont = useApi<ReportResponseCancelOdonto>(
    Endpoints.report.getReportCancelOdonto,
    ApiMethod.POST,
  );

  const RequestReportInvoceOdont = useApi<ReportResponse>(
    Endpoints.report.getReportBillingOdonto,
    ApiMethod.POST,
  );

  const RequestReportSalesSafe = useApi<ReportResponse>(
    Endpoints.report.getInsuranceReport,
    ApiMethod.POST,
  );

  const RequestReportCancelSafe = useApi<ReportResponseCancelSafe>(
    Endpoints.report.getReportCancelProtection,
    ApiMethod.POST,
  );

  const RequestReportSalesPlatinumCard = useApi<ReportSalesPlatinumCardResponse>(
    Endpoints.events.postReportSalesPlatinum,
    ApiMethod.POST,
  );

  const RequestReportCancellationPlatinum = useApi<ReportCancellationPlatinumResponse>(
    Endpoints.events.postReportCancellationPlatinum,
    ApiMethod.POST,
  );

  const clearForm = () => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
  };

  useEffect(() => {
    if (
      RequestGetOperators.loading ||
      RequestReportSalesOdont.loading ||
      RequestReportCancelOdont.loading ||
      RequestReportInvoceOdont.loading ||
      RequestReportSalesSafe.loading ||
      RequestReportCancelSafe.loading ||
      RequestReportSalesCuida.loading ||
      RequestReportCancelCuida.loading ||
      RequestReportSalesPlatinumCard.loading ||
      RequestReportCancellationPlatinum.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    RequestGetOperators.loading,
    RequestReportSalesOdont.loading,
    RequestReportCancelOdont.loading,
    RequestReportInvoceOdont.loading,
    RequestReportSalesSafe.loading,
    RequestReportCancelSafe.loading,
    RequestReportSalesCuida.loading,
    RequestReportCancelCuida.loading,
    RequestReportSalesPlatinumCard.loading,
    RequestReportCancellationPlatinum.loading,
  ]);

  const checkSuccess = () => {
    if (
      RequestReportSalesOdont.error ||
      RequestReportSalesOdont.status === 404 ||
      RequestReportSalesOdont.status === 500 ||
      RequestReportCancelOdont.error ||
      RequestReportCancelOdont.status === 404 ||
      RequestReportCancelOdont.status === 500 ||
      RequestReportInvoceOdont.error ||
      RequestReportInvoceOdont.status === 404 ||
      RequestReportInvoceOdont.status === 500 ||
      RequestReportSalesSafe.error ||
      RequestReportSalesSafe.status === 404 ||
      RequestReportSalesSafe.status === 500 ||
      RequestReportCancelSafe.error ||
      RequestReportCancelSafe.status === 404 ||
      RequestReportCancelSafe.status === 500 ||
      RequestReportSalesCuida.error ||
      RequestReportSalesCuida.status === 404 ||
      RequestReportSalesCuida.status === 500 ||
      RequestReportCancelCuida.error ||
      RequestReportCancelCuida.status === 404 ||
      RequestReportCancelCuida.status === 500 ||
      RequestReportSalesPlatinumCard.status === 404 ||
      RequestReportSalesPlatinumCard.status === 500 ||
      RequestReportCancellationPlatinum.error ||
      RequestReportCancellationPlatinum.status === 404 ||
      RequestReportCancellationPlatinum.status === 500
    ) {
      return true;
    }
    return false;
  };

  const postReportSalesOdont = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
    };

    const response = await RequestReportSalesOdont.callApi(newPayload);

    setHeader(CSV_HEADERS.ODONTO);

    if (returnResponse) return response.data;

    const { vendas, totalAdesao } = response.data as ReportResponseOdonto;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportOdonto);
      setItems(vendas ? Models.BodyReportOdonto(vendas) : []);
      setTotalItems(totalAdesao);
      setRelatorioData(Models.BodyReportOdontoCSV(vendas));
    }
  };

  const postReportSalesCuida = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
    };

    const response = await RequestReportSalesCuida.callApi(newPayload);
    setHeader(CSV_HEADERS.CUIDA);

    if (returnResponse) return response.data;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportCuida);
      setItems(Models.BodyReportCuida(response.data));
      setTotalItems(Models.BodyTotalCuida(response.data));
      setRelatorioData(Models.BodyReportCuidaCSV(response.data));
    }
  };

  const postReportCancelCuida = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
    };

    const response = await RequestReportCancelCuida.callApi(newPayload);

    setHeader(CSV_HEADERS.CANCEL_CUIDA);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportCancelCuida);
      setItems(Models.BodyReportCancelCuida(data));
      setTotalItems(Models.BodyTotalCancelCuida(data));
      setRelatorioData(Models.BodyReportCancelCuidaCSV(data));
    }
  };

  const postReportCancelOdont = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
    };

    const response = await RequestReportCancelOdont.callApi(newPayload);

    setHeader(CSV_HEADERS.CANCEL_ODONTO);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportCancelOdonto);
      setItems(Models.BodyReportCancelOdonto(data));
      setTotalItems(Models.BodyTotalCancelOdonto(data));
      setRelatorioData(Models.BodyReportCancelOdontoCSV(data));
    }
  };

  const postReportInvoceOdont = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportInvoceOdont.callApi(newPayload);

    setHeader(CSV_HEADERS.BILLING_ODONTO);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderBillingOdonto);
      setItems(Models.BodyBillingOdonto(data));
    }
  };

  const postReportSalesSafe = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
    };

    const response = await RequestReportSalesSafe.callApi(newPayload);

    setHeader(CSV_HEADERS.TEUCARD_PROTEGE);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderTeucardProtege);
      setItems(Models.BodyTeucardProtege(data));
      setTotalItems(Models.BodyTotalTeucardProtege(data));
      setRelatorioData(Models.BodyTeucardProtegeCSV(data));
    }
  };

  const postReportCancelSafe = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
    };

    const response = await RequestReportCancelSafe.callApi(newPayload);

    setHeader(CSV_HEADERS.CANCEL_PROTEGE);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportCancelSafe);
      setItems(Models.BodyReportCancelSafe(data));
      setTotalItems(Models.BodyTotalCancelSafe(data));
      setRelatorioData(Models.BodyReportCancelSafeCSV(data));
    }
  };

  const postReportSalesPlatinumCard = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = { ...payload, qtdRegistros: limit, pagina: page };

    const response = await RequestReportSalesPlatinumCard.callApi(newPayload);

    setHeader(CSV_HEADERS.SALES_PLATINUM_CARD);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalPaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderUpgradePlatinumCard);
      setItems(Models.BodyUpgradePlatinumCard(data));
    }
  };

  const postReportCancellationPlatinum = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      pagina: page,
      qtdRegistros: limit,
    };

    try {
      const response = await RequestReportCancellationPlatinum.callApi(
        newPayload,
      );

      setHeader(CSV_HEADERS.CANCELLATION_PLATINUM);

      if (returnResponse) return response.data;

      const { data } = response;

      if (response.status === 200) {
        setHeaderItems(Models.HeaderReportCancellationPlatinum);
        setItems(Models.BodyReportCancellationPlatinum(data.data));
        setTotalItems(data.totalItens);
        setRelatorioData(Models.BodyReportCancellationPlatinumCSV(data.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getOperators = useCallback(async () => {
    const { data, status, mensagem } = await RequestGetOperators.callApi();

    return { data, status, mensagem };
  }, [RequestGetOperators]);

  const getOperador = async () => {
    const response = await getOperators();

    if (response.status === 200) {
      const data = response.data as GetOperatorsResponse[];

      const getOperadores = data.map(({ nome, id }) => ({
        id,
        value: nome,
      }));

      setOperador(getOperadores);
    }
  };

  return (
    <BenefitReportContext.Provider
      value={{
        postReportSalesOdont,
        postReportCancelOdont,
        postReportInvoceOdont,
        postReportSalesSafe,
        postReportCancelSafe,
        postReportSalesCuida,
        postReportCancelCuida,
        postReportSalesPlatinumCard,
        postReportCancellationPlatinum,
        getOperador,
        checkSuccess,
        loading,
        items,
        headerItems,
        header,
        totalItems,
        totalPages,
        relatorioData,
        setRelatorioData,
        clearForm,
        operador,
        BENEFIT_TYPES,
      }}
    >
      {children}
    </BenefitReportContext.Provider>
  );
};
export default BenefitReportProvider;
