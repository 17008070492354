import styled, { keyframes } from 'styled-components';

export interface ILoadingContainer {
  status?: boolean;
}

interface FadeAndSlideContainerProps {
  active: boolean;
}

export const LoadingContainer = styled.div<ILoadingContainer>`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99999;
  display: ${(p) => (p.status ? 'flex' : 'none')};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = styled.div`
  border: 10px solid rgba(255, 255, 255, 0.1);
  border-top: 10px solid #e71037;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  flex-direction: column;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Text = styled.div`
  font-weight: bold;
  position: absolute;
  margin: auto auto auto auto;
  padding-top: 150px;
  color: #e71037;
  font-size: 1.5rem;
  z-index: 10000;
`;

export const TextLoadingWebSocket = styled.div`
  font-weight: bold;
  margin: auto auto auto auto;
  padding-top: 150px;
  color: #e71037;
  font-size: 1.2rem;
  z-index: 10000;
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FadeAndSlideContainer = styled.div<FadeAndSlideContainerProps>`
  opacity: ${(props) => (props.active ? 1 : 0)};
  transform: translateY(${(props) => (props.active ? 0 : -10)}px);
  transition: opacity 0.5s, transform 0.5s;
  animation: ${fadeInOut} 1s ease-in-out;
  position: absolute;
  margin: auto auto auto auto;
`;
