import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;

  padding: 30px min(80px, 10%);
`;

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-flow: row wrap;

  div:nth-child(1) {
    width: min(448px, 100%);
  }

  button {
    margin-left: 50px;
    width: 212px;
    bottom: 15px;
  }
`;

export const TransactionsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;

  margin-top: 20px;
  > div {
    margin-bottom: 0px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;
`;

export const TitleCategory = styled.span`
  justify-content: center;
  font-size: 1em;
  font-weight: 300;
  padding-left: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #2e3958;
  margin-bottom: 24px;
`;
