import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  width: 230px;
  max-width: 230px;
  margin: 0 auto;
`;

export const Inner = styled.div`
  white-space: nowrap;
  transition: transform 0.3s;
  height: 90px;

  button {
    cursor: pointer;
    background: transparent;
    border: 0;
  }
`;

export const CarouselItem = styled.div`
  width: 170px;
  max-width: 170px;
  height: 200px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  top: -10px;
`;

interface DotsProps {
  active: boolean;
}

export const Dots = styled.span<DotsProps>`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;

  ${(props) =>
    props.active &&
    css`
      border: 1px solid #f49432;
      background-color: #f49432;
    `}
`;
