/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Dialog from '~/components/dialog';
import Loading from '~/components/loading';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { ConsultFindFinancingResponse } from '~/services/consult/user/invoice/antecipationInvoice/response';
import Endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import {
  cpfMask,
  cpfUnmask,
  creditCardUnmask,
  currencyMask,
} from '~/utils/masks';
import TableInvoice from '~/components/tableInvoice';
import SelectParcelsQuantity from './components/selectParcelsQuantity';
import { icons } from '~/assets';

import * as St from './styles';
import Container from '~/components/container';

interface Form {
  cpf: FormField;
  numeroCartao: FormField;
}

interface IlistAntecipacion {
  border?: boolean;
  id?: any;
  title?: string;
  value?: any;
  item?: any;
  valueColor?: string;
  searchAction?: Function;
  maxChar?: number;
  scratched?: boolean;
  reportView?: boolean;
  icon?: string;
  origin?: string;
  fontsize?: string;
  type?: string;
  textAlign?: string;
  marginLeft?: number;
}

const AntecipationInvoice: React.FC = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [researched, setResearched] = useState(false);
  const [dialogIsCancel, setDialogIsCancel] = useState(false);
  const [idParcel, setIdParcel] = useState('');
  const [valueParcel, setValueParcel] = useState(0);
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessageError, setDialogMessageError] = useState('');
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogMessageSuccess, setDialogMessageSuccess] = useState('');

  const [form, setForm] = useState<Form>({
    cpf: { name: 'cpf', value: '' },
    numeroCartao: { name: 'numeroCartao', value: '' },
  });

  const RequestFindFinancing = useApi<ConsultFindFinancingResponse>(
    Endpoints.invoices.getSearchFinancing
      .replace('PARAM_CPF', cpfUnmask(form.cpf.value))
      .replace('cpf=&', '')
      .replace(
        'PARAM_NUMERO_CARTAO',
        creditCardUnmask(form.numeroCartao.value),
      ),
    ApiMethod.GET,
  );

  const antecipationCancel = useApi(
    Endpoints.invoices.putAntecipationCancel.replace(
      'PARAM_ID_PARCELAMENTO',
      idParcel,
    ),
    ApiMethod.PUT,
  );

  const searchFinancing = async () => {
    setData([]);

    const response = await RequestFindFinancing.callApi();

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      setData(data);
      setResearched(true);
    } else {
      setError(true);
    }
  };

  const antecipationCancelDialog = (idParcel, valueParcel) => {
    setDialogIsCancel(true);
    setIdParcel(idParcel);
    setValueParcel(valueParcel);
  };

  const handleAntecipationCancel = async () => {
    const response = await antecipationCancel.callApi();
    if (response.status >= 200 && response.status < 300) {
      setDialogIsCancel(false);
      setDialogMessageSuccess('Cancelamento realizado com sucesso!');
      setDialogSuccess(true);
    } else {
      setDialogIsCancel(false);
      setDialogMessageError('Não foi possível realilzar o cancelamento!');
      setDialogError(true);
    }
  };

  const financialHeader = [
    {
      id: 1,
      value: 'Quantidade de parcelas',
      maxChar: 20,
    },
    {
      id: 2,
      value: 'Valor financiado',
      maxChar: 20,
    },
    {
      id: 3,
      value: 'Status',
      maxChar: 20,
    },
    {
      id: 4,
      value: 'Data de vencimento',
      maxChar: 20,
    },
    {
      id: 5,
      value: 'Data de corte',
      maxChar: 20,
    },
  ];

  const detailHeader = [
    {
      id: 1,
      value: 'Nome Completo',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 2,
      value: 'CPF',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 3,
      value: 'Status',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 4,
      value: 'Data financiamento',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 5,
      value: 'Valor pendente financiado',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 6,
      value: 'Qtd. parcelas pagas',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 7,
      value: 'Valor da parcela',
      maxChar: 50,
      textAlign: 'left',
    },
    {
      id: 8,
      value: 'Qtd. parcelas a pagar',
      maxChar: 50,
      textAlign: 'left',
    },
  ];

  const CollapsibleItem = ({ data, index }) => {
    const [selected, setSelected] = useState(null);
    const contentEl = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const [dataFinancial, setDataFinancial] = useState([]);
    const [list, setList] = useState([]);
    const [idParcel, setIdParcel] = useState(null);

    const getFinancial = useApi(
      Endpoints.invoices.getFinancial.replace(
        'PARAM_ID_PARCELAMENTO',
        idParcel,
      ),
      ApiMethod.GET,
    );

    useEffect(() => {
      if (selected === index) {
        searchFinancial();
      }
    }, [selected, idParcel]);

    const searchFinancial = async () => {
      setLoading(true);
      const response = await getFinancial.callApi();

      const { data }: any = response;
      const newList = [];
      const i = 0;

      if (response.status >= 200 && response.status < 300) {
        data?.forEach((data) => {
          const item: IlistAntecipacion[] = [
            { value: data.parcelasAntecipadas, maxChar: 30 },
            {
              value: `R$ ${currencyMask(data.valorAntecipacao)}`,
              maxChar: 20,
            },
            {
              value: data.status,
              maxChar: 20,
            },
            {
              value: moment(data.dataVencimento).format('DD/MM/YYYY'),
              maxChar: 20,
            },
            {
              value: moment(data.dataFechamento).format('DD/MM/YYYY'),
              maxChar: 20,
            },
            data.status === 'FATURAR' && {
              value: 'CANCELAR',
              maxChar: 5,
              textAlign: 'right',
            },
          ];

          newList.push(item);
        });
        setDataFinancial(data);
        setList(newList);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    const handleToggle = async (id) => {
      if (selected === index) {
        setSelected(null);
      } else {
        setSelected(index);

        setIdParcel(id);
      }
    };

    return (
      <>
        <tr
          key={data.idFinanciamento}
          onClick={() => handleToggle(data.idFinanciamento)}
        >
          <St.bodyData maxChar={50} textAlign="left" title={data.nomeCompleto}>
            {data.nomeCompleto}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.cpf}>
            {cpfMask(data.cpf).masked}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.status}>
            {data.status}
          </St.bodyData>
          <St.bodyData
            maxChar={50}
            textAlign="left"
            title={data.dataFinanciamento}
          >
            {moment(data.dataFinanciamento).format('DD/MM/YYYY')}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.valorpendente}>
            {`R$ ${currencyMask(data.valorpendente)}`}
          </St.bodyData>
          <St.bodyData
            maxChar={50}
            textAlign="left"
            title={data.qtdParcelasPagas}
          >
            {data.qtdParcelasPagas}
          </St.bodyData>
          <St.bodyData maxChar={50} textAlign="left" title={data.valorParcela}>
            {`R$ ${currencyMask(data.valorParcela)}`}
          </St.bodyData>
          <St.bodyData
            maxChar={50}
            textAlign="left"
            title={data.qtdParcelasAPagar}
          >
            {data.qtdParcelasAPagar}
          </St.bodyData>
          <St.Icon
            src={icons.arrowBlack}
            width={25}
            open={selected === index}
          />
        </tr>

        <tr style={{ height: selected === index ? 'auto' : '0px' }}>
          <td colSpan={9} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
            <St.ContentWraper
              ref={contentEl}
              style={
                selected === index
                  ? { height: contentEl?.current?.scrollHeight }
                  : { height: '0px' }
              }
            >
              <St.Content>
                <h3>Detalhes da Solicitação de Antecipação</h3>

                <>
                  <Loading status={loading} />
                  <TableInvoice
                    cancel={() => {
                      antecipationCancelDialog(
                        data.idFinanciamento,
                        dataFinancial[dataFinancial.length - 1]
                          ?.valorAntecipacao,
                      );
                    }}
                    list={list}
                    customerHeader={financialHeader}
                  />

                  {data.qtdParcelasAPagar >= 2 &&
                    data.status === 'ANDAMENTO' &&
                    dataFinancial[dataFinancial.length - 1]?.status !==
                      'FATURAR' && (
                      <>
                        <St.DatesContainer>
                          <h3>
                            Data de corte da fatura:
                            {` ${moment(data.dataCorte).format('DD/MM/YYYY')}`}
                          </h3>
                          <h3>
                            Vencimento da fatura:
                            {` ${moment(data.dataVencimento).format(
                              'DD/MM/YYYY',
                            )}`}
                          </h3>
                        </St.DatesContainer>
                        <ContainerFlex center padding={16}>
                          <SelectParcelsQuantity
                            data={data}
                            dataFinancial={
                              dataFinancial[dataFinancial.length - 1]
                            }
                            idFinanciamento={data.idFinanciamento}
                            qtdParcelasAPagar={data.qtdParcelasAPagar}
                            searchFinancing={searchFinancing}
                            dataCorte={data.dataCorte}
                            dataVencimento={data.dataVencimento}
                          />
                        </ContainerFlex>
                      </>
                    )}
                </>

                {(data.status === 'QUITADO' ||
                  data.status === 'INDISPONÍVEL') && (
                  <ContainerFlex center marginTop={20} marginBottom={30}>
                    <p>Antecipação indisponível</p>
                  </ContainerFlex>
                )}
              </St.Content>
            </St.ContentWraper>
          </td>
        </tr>
        <br />
      </>
    );
  };

  return (
    <>
      <Loading
        status={RequestFindFinancing.loading || antecipationCancel.loading}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogIsCancel}
        title={<span style={{ color: 'red' }}> Cancelar </span>}
        positiveLabel="SIM"
        positiveAction={handleAntecipationCancel}
        negativeLabel="NÃO"
        negativeAction={() => setDialogIsCancel(false)}
      >
        <St.DescriptionDialog>
          {`As parcelas do financiamento original serão mantidas. Comunique o
          cliente que o valor R$ ${currencyMask(
            valueParcel,
          )} da antecipação será cancelado. Deseja
          confirmar ?`}
        </St.DescriptionDialog>
      </Dialog>
      <Dialog
        icon={icons.confirmation}
        open={dialogSuccess}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogSuccess(false);
          searchFinancing();
        }}
        message={dialogMessageSuccess}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
          searchFinancing();
        }}
        message={dialogMessageError}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                maxLength={14}
                name={form.cpf.name}
                value={form.cpf.value}
                placeholder="000.000.000-00"
                onChange={(e) => setFormState(e, form, setForm)}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Número do cartão"
                placeholder="0000.0000.0000.0000"
                maxLength={19}
                value={form.numeroCartao.value}
                name={form.numeroCartao.name}
                onChange={(e) => setFormState(e, form, setForm)}
                mask={MaskTypes.CREDIT_CARD}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                onClick={searchFinancing}
                loading={RequestFindFinancing.loading}
                disabled={
                  (form.cpf.value.length !== 14 &&
                    form.numeroCartao.value.length !== 19) ||
                  RequestFindFinancing.loading
                }
              >
                <span>Consultar</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {data?.length > 0 ? (
          <St.BoxCard>
            <>
              <St.MainHeader>Detalhes do financiamento</St.MainHeader>
              <table style={{ width: '100%', borderCollapse: 'separate' }}>
                <St.headerTable>
                  <tr>
                    {detailHeader.map((item) => (
                      <St.hederTitle
                        key={item.id}
                        maxChar={item.maxChar}
                        textAlign={item.textAlign}
                      >
                        {item.value}
                      </St.hederTitle>
                    ))}
                  </tr>
                </St.headerTable>
                <br />
                <St.bodyTable>
                  {data.map((item, index) => (
                    <>
                      <CollapsibleItem data={item} index={index} />
                    </>
                  ))}
                </St.bodyTable>
              </table>
            </>
          </St.BoxCard>
        ) : (
          <>
            {(error ||
              RequestFindFinancing.status > 300 ||
              (data?.length === 0 && researched)) && (
              <St.BoxCard>
                <ContainerFlex center>
                  <St.TitleCategory>
                    Não há resultados para mostrar aqui.
                  </St.TitleCategory>
                </ContainerFlex>
              </St.BoxCard>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default AntecipationInvoice;
