/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { icons } from '~/assets';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Input, { MaskTypes } from '~/components/input';
import { FormField, setFormState } from '~/utils/form';
import { cpfMask, dateMask, phoneMask } from '~/utils/masks';
import {
  dateValidator,
  isDateValid,
  IValidators,
  nameValidator,
  ValidatorsNames,
} from '~/utils/validators';

import * as St from '../styles';

interface IProps {
  readonly isOpenDependent: boolean;
  readonly setDependent: any;
  readonly setIsOpenDependent: any;
}

interface Form {
  cpf?: FormField;
  nome: string;
  dataNascimento?: FormField;
  telefone: FormField;
}

const dependent: React.FC<IProps> = ({
  isOpenDependent,
  setDependent,
  setIsOpenDependent,
}) => {
  const [form, setForm] = useState<Form>({
    cpf: { name: 'cpf', value: '' },
    nome: '',
    dataNascimento: { name: 'dataNascimento', value: '' },
    telefone: { name: 'telefone', value: '' },
  });
  const [errorValidate, setErrors] = useState({
    nome: {
      status: false,
      mensagem: '',
    },
    dataNascimento: {
      status: false,
      mensagem: '',
    },
  });
  const [listDepentent, setListDepentent] = useState([]);
  const [dialogIsOpenDependent, setDialogIsOpenDependent] = useState(
    isOpenDependent,
  );
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  async function handleClick() {
    let error = {
      nome: {
        status: form.nome.trim().length <= 0,
        mensagem: !form.nome.trim().length ? 'Campo obrigatório' : '',
      },
      dataNascimento: {
        status:
          form.dataNascimento.value.length <= 0 ||
          !isDateValid(form.dataNascimento.value),
        mensagem: !form.dataNascimento.value.length ? 'Campo obrigatório' : '',
      },
    };

    setErrors(error);
    if (error.nome.status) return;
    if (error.dataNascimento.status) return;

    const payload = {
      id: listDepentent.length + 1,
      cpf: form.cpf.unmasked,
      nome: form.nome,
      dataNascimento: form.dataNascimento.unmasked,
      telefone: form.telefone.unmasked,
    };

    await setListDepentent([...listDepentent, payload]);
    setForm({
      cpf: { name: 'cpf', value: '' },
      nome: '',
      dataNascimento: { name: 'dataNascimento', value: '' },
      telefone: { name: 'telefone', value: '' },
    });
  }

  const handleRemove = (data: any) => {
    const ind = listDepentent.indexOf(data);
    if (ind > -1) {
      const a = listDepentent.filter((e) => {
        return e !== data;
      });
      setListDepentent(a);
    }
    return listDepentent;
  };
  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpenDependent}
        widthInPercent={60}
        negativeLabel="Voltar"
        negativeAction={() => {
          setDialogIsOpenDependent(false);
          setIsOpenDependent(false);
        }}
        positiveLabel="Solicitar"
        buttonDisabledPositive={listDepentent.length <= 0}
        positiveAction={() => setDialogIsOpen(true)}
        title={<span style={{ color: 'red' }}>Solicitação de dependente</span>}
      >
        <ContainerFlex padding={3}>
          <St.DivGroupBox>
            <ContainerFlex marginTop={10} marginLeft={10} maxWidth={30}>
              <Input
                label="Nome"
                name="Nome"
                value={form.nome}
                onChange={(e) => setForm({ ...form, nome: e.target.value })}
                errorDiv={errorValidate.nome.status}
              />
            </ContainerFlex>
            <ContainerFlex marginTop={10} marginLeft={10} maxWidth={30}>
              <Input
                label="Data de nascimento"
                name={form.dataNascimento.name}
                value={form.dataNascimento.value}
                onChange={(e) => setFormState(e, form, setForm)}
                placeholder="DD/MM/YYYY"
                maxLength={10}
                mask={MaskTypes.DATE}
                errorDiv={errorValidate.dataNascimento.status}
              />
            </ContainerFlex>
            <ContainerFlex marginTop={10} marginLeft={10} maxWidth={30}>
              <Input
                label="CPF"
                name={form.cpf.name}
                value={form.cpf.value}
                onChange={(e) => setFormState(e, form, setForm)}
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>
            <ContainerFlex marginTop={10} marginLeft={10} maxWidth={30}>
              <Input
                label="Telefone"
                name={form.telefone.name}
                value={form.telefone.value}
                onChange={(e) => setFormState(e, form, setForm)}
                placeholder="(99) 99999-9999"
                maxLength={15}
                mask={MaskTypes.PHONE}
              />
            </ContainerFlex>
            <ContainerFlex end marginTop={10} marginLeft={10} maxWidth={10}>
              <St.BtnPlus onClick={handleClick} />
            </ContainerFlex>
          </St.DivGroupBox>
        </ContainerFlex>
        <ContainerFlex padding={3}>
          <St.TableTitle>Dependentes incluídos</St.TableTitle>
          <St.Table>
            <tr>
              <th>Nome</th>
              <th>Data Nascimento</th>
              <th>CPF</th>
              <th>Telefone</th>
              <th />
            </tr>
            {listDepentent.map((dependents) => (
              <tr key={dependents.id}>
                <td>{dependents.nome}</td>
                <td>{dateMask(dependents.dataNascimento).masked}</td>
                <td>{cpfMask(dependents.cpf).masked}</td>
                <td>{phoneMask(dependents.telefone).masked}</td>
                <td>
                  <St.BtnMinus onClick={() => handleRemove(dependents)} />
                </td>
              </tr>
            ))}
          </St.Table>
        </ContainerFlex>
      </Dialog>
      <Dialog
        icon={icons.exclamation}
        title={
          <span style={{ color: 'red' }}>
            Confirme abaixo a inclusão dos dependentes!
          </span>
        }
        message="Deseja solicitar dependentes?"
        widthInPercent={40}
        open={dialogIsOpen}
        negativeLabel="Não"
        negativeAction={() => {
          setDialogIsOpen(false);
        }}
        positiveLabel="Sim"
        positiveAction={() => {
          setDependent(listDepentent);
        }}
      >
        <St.TableDependents>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Data Nascimento</th>
          </tr>
          {listDepentent.map((dependents) => (
            <tr key={dependents.id}>
              <td>{dependents.nome}</td>
              <td>{cpfMask(dependents.cpf).masked}</td>
              <td>{dateMask(dependents.dataNascimento).masked}</td>
            </tr>
          ))}
        </St.TableDependents>
      </Dialog>
    </>
  );
};

export default dependent;
