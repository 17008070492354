/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdOutlineRefresh } from 'react-icons/md';
import moment from 'moment';
import { IconContext } from 'react-icons';
import Carousel from '../Carousel';

import * as S from '../../styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import LoadingComponents from '~/components/loadingInComponents';

interface IBoxUnblockedCard {
  cartoes: {
    dataHoraColeta: string;
    total: number;
  };
}

interface IBoxAdhesionsOdontic {
  data: {
    dataColeta: string;
    beneficioOdonto: number;
    beneficioSeguroPrestamista: number;
    beneficioSeguroBolsaProtegida: number;
    beneficioSeguroPerdaRenda: number;
    beneficioTeucardCuida: number;
    quantidadeTotal: number;
    adesoesPlatinum: number;
    beneficioSeguros: number;
  };
}

interface IBoxProposal {
  dataHoraColeta: string;
  analise: number;
  aprovadas: number;
  preAprovadas: number;
  reprovadas: number;
  cadastradas: number;
}

const BoxEnd: React.FC = () => {
  const [cartoes, setCartoes] = useState({ dataHoraColeta: '', total: 0 });
  const [adesoes, setAdesoes] = useState({
    dataColeta: '',
    beneficioOdonto: 0,
    beneficioSeguroPrestamista: 0,
    beneficioSeguroBolsaProtegida: 0,
    beneficioSeguroPerdaRenda: 0,
    beneficioTeucardCuida: 0,
    quantidadeTotal: 0,
    beneficioSeguros: 0,
    adesoesPlatinum: 0,
  });
  const [proposal, setProposal] = useState({
    dataHoraColeta: moment().format('DD/MM/YYYY'),
    analise: 0,
    aprovadas: 0,
    preAprovadas: 0,
    reprovadas: 0,
    cadastradas: 0,
  });
  const [currentSlide, setCurrentSlide] = useState({ title: '', id: [] });
  const history = useHistory();
  const RequestGetUnblockedCard = useApi(
    Endpoints.graphc.getUnblockedCard,
    ApiMethod.GET,
  );

  const RequestGetAdhesionsOdontic = useApi(
    Endpoints.graphc.getAdhesionsOdontic,
    ApiMethod.GET,
  );

  const RequestGetProposal = useApi(
    Endpoints.graphc.getConsultProposal,
    ApiMethod.GET,
  );

  useEffect(() => {
    searchUnblockedCard();
    searchAdhesionsOdontic();
    searchProposal();
  }, []);

  const searchUnblockedCard = async () => {
    try {
      const response = await RequestGetUnblockedCard.callApi();

      if (response.status >= 200 && response.status < 300) {
        const { cartoes } = response.data as IBoxUnblockedCard;

        setCartoes(cartoes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchAdhesionsOdontic = async () => {
    try {
      const response = await RequestGetAdhesionsOdontic.callApi();

      if (response.status >= 200 && response.status < 300) {
        const { data } = response as IBoxAdhesionsOdontic;

        setAdesoes(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchProposal = async () => {
    try {
      const response = await RequestGetProposal.callApi();

      if (response.status >= 200 && response.status < 300) {
        setProposal(response.data as IBoxProposal);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <S.EndCards>
      <S.EndCardsItems>
        <LoadingComponents status={RequestGetProposal.loading} />
        <header>
          <div className="title">
            <h1>Propostas</h1>
            <span className="title__update">
              {`Atualizado: hoje às ${moment(proposal.dataHoraColeta).format(
                'HH:mm',
              )}`}
            </span>
          </div>
        </header>

        <IconContext.Provider value={{ className: 'react-icons' }}>
          <MdOutlineRefresh onClick={() => searchProposal()} />
        </IconContext.Provider>

        <section>
          <S.ItemsDetails
            onClick={() => {
              history.push('/dashDetailsProposalRealTime', {
                type: 'CADASTRADAS',
                dataFinal: moment().format('YYYY-MM-DD'),
                dataInicial: moment().format('YYYY-MM-DD'),
              });
            }}
          >
            <article>
              <h2>{proposal?.cadastradas}</h2>
              <span>Cadastradas</span>
            </article>
          </S.ItemsDetails>

          <S.ItemsDetails
            onClick={() => {
              history.push('/dashDetailsProposalRealTime', {
                type: 'PRE_APROVADAS',
                dataFinal: moment().format('YYYY-MM-DD'),
                dataInicial: moment().format('YYYY-MM-DD'),
              });
            }}
          >
            <article>
              <h2>{proposal?.preAprovadas}</h2>
              <span>Pré Aprovadas</span>
            </article>
          </S.ItemsDetails>

          <S.ItemsDetails
            onClick={() => {
              history.push('/dashDetailsProposalRealTime', {
                type: 'APROVADAS',
                dataFinal: moment().format('YYYY-MM-DD'),
                dataInicial: moment().format('YYYY-MM-DD'),
              });
            }}
          >
            <article>
              <h2>{proposal?.aprovadas}</h2>
              <span>Aprovadas</span>
            </article>
          </S.ItemsDetails>

          <S.ItemsDetails
            onClick={() => {
              history.push('/dashDetailsProposalRealTime', {
                type: 'REPROVADAS',
                dataFinal: moment().format('YYYY-MM-DD'),
                dataInicial: moment().format('YYYY-MM-DD'),
              });
            }}
          >
            <article>
              <h2>{proposal?.reprovadas}</h2>
              <span>Reprovadas</span>
            </article>
          </S.ItemsDetails>

          <S.ItemsDetails
            onClick={() => {
              history.push('/dashDetailsProposalRealTime', {
                type: 'EM_ANALISE',
                dataFinal: moment().format('YYYY-MM-DD'),
                dataInicial: moment().format('YYYY-MM-DD'),
              });
            }}
          >
            <article>
              <h2>{proposal?.analise}</h2>
              <span>Em análise</span>
            </article>
          </S.ItemsDetails>
        </section>
      </S.EndCardsItems>

      <S.EndCardsItemsNoHover>
        <LoadingComponents status={RequestGetUnblockedCard.loading} />
        <header>
          <div className="title">
            <h1>Cartões</h1>
            <span className="title__update">
              {`Atualizado: hoje às ${moment(cartoes.dataHoraColeta).format(
                'HH:mm',
              )}`}
            </span>
          </div>
        </header>

        <IconContext.Provider value={{ className: 'react-icons' }}>
          <MdOutlineRefresh
            onClick={(e) => {
              e.stopPropagation();
              searchUnblockedCard();
            }}
          />
        </IconContext.Provider>

        <section>
          <article>
            <h2>{cartoes.total}</h2>
            <span>Ativo</span>
          </article>
        </section>
      </S.EndCardsItemsNoHover>

      <S.EndCardsItemsCarousel
        style={{ cursor: 'pointer' }}
        onClick={() => {
          history.push('/dashDetailsBenefitsRealTime', {
            dataFinal: moment().format('YYYY-MM-DD'),
            dataInicial: moment().format('YYYY-MM-DD'),
            title: currentSlide.title,
            idBeneficio: currentSlide.id,
          });
        }}
      >
        <LoadingComponents status={RequestGetAdhesionsOdontic.loading} />
        <header>
          <div className="title">
            <h1>Adesões</h1>
            <span className="title__update">
              {`Atualizado: hoje às ${moment(adesoes.dataColeta).format(
                'HH:mm',
              )}`}
            </span>
          </div>
        </header>

        <IconContext.Provider value={{ className: 'react-icons' }}>
          <MdOutlineRefresh
            onClick={(e) => {
              e.stopPropagation();
              searchAdhesionsOdontic();
            }}
          />
        </IconContext.Provider>

        <Carousel
          onChange={(data) => {
            setCurrentSlide({
              id: data.props?.['data-id'],
              title: data?.props?.['data-title'],
            });
          }}
        >
          <S.Items data-title="Odonto" data-id={['60e79f33140dc50008243302']}>
            <article>
              <h2>{adesoes.beneficioOdonto}</h2>
              <span>Odonto</span>
            </article>
          </S.Items>
          <S.Items
            data-title="Protege"
            data-id={[
              '633f11e39cda3f1b65227d44',
              '633f11e39cda3f1b65227d45',
              '633f11e39cda3f1b65227d46',
            ]}
          >
            <article>
              <h2>{adesoes.beneficioSeguros}</h2>
              <span>Protege</span>
            </article>
          </S.Items>

          <S.Items
            data-title="TeuCard Cuida"
            data-id={['6478d1c891d2c1eb00dd3b7f']}
          >
            <article>
              <h2>{adesoes.beneficioTeucardCuida}</h2>
              <span>Cuida</span>
            </article>
          </S.Items>

          <S.Items data-title="Platinum">
            <article>
              <h2>{adesoes.adesoesPlatinum}</h2>
              <span>Platinum</span>
            </article>
          </S.Items>
        </Carousel>
      </S.EndCardsItemsCarousel>
    </S.EndCards>
  );
};

export default BoxEnd;
